export interface ISearchVinResult {
	data: IVinData;
}

export interface IVinData {
	year: number;
	makeId: number;
	modelId: number;
	trims?: IVinTrimsEntity[] | null;
}
export interface IVinTrimsEntity {
	id: number;
	trimName: string;
}

export interface IVehicleYearsResult {
	data?: number[] | null;
}

export interface IVehicleMakesResult {
	data?: IVehicleMakesDataEntity[] | null;
}
export interface IVehicleMakesDataEntity {
	id: number;
	makeName: string;
}

export interface IVehicleModelsResult {
	data?: IVehicleModelsDataEntity[] | null;
}
export interface IVehicleModelsDataEntity {
	kbbModelId: number;
	make: IVehicleMake;
	id: number;
	modelName: string;
}
export interface IVehicleMake {
	id: number;
	makeName: string;
}

export interface IVehicleTrimsResult {
	data?: IVehicleTrimsDataEntity[] | null;
}

export interface IVehicleTrimsDataEntity {
	kbbModelId: number;
	trimName: string;
	id: number;
	year: number;
	modelId: number;
	vehicleClass: string;
}

export interface IVehicle {
	year?: number;
	makeId?: number;
	vin?: string;
	modelId?: number;
	trimId?: number;
	odometer?: string;
	makeStr?: string;
	modelStr?: string;
	trimStr?: string;
	vehicleOwnershipStatus?: string;
	isFinalVehicle?: boolean;
}

export interface IIneligibleVehicleResult {
	inValidVehicleTrimIds?: number[];
	inValidVehicleModelIds?: number[];
	inValidVehicleMakeIds?: number[];
}

export enum TitleTypeEnum {
	paper = 'PAPER',
	electronic = 'ELECTRONIC'
}

export const ElectronicTitleEnabledStates = ['FL', 'AZ'];
