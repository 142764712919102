export interface ILoanAppEvent {
	applicantId?: number;
	eventType?: string;
	clientId?: number;
	category?: string;
	loanApplicationId?: number;
	value1?: string;
	value2?: string;
}

export enum LoanAppEventTypeEnum {
	loginInitiated = 'PLAID_LOG_IN_INITIATED',
	loginCompleted = 'PLAID_LOG_IN_COMPLETED',
	mfaPresented = 'MFA_PRESENTED'
}
