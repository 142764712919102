import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';

import { ConfigApiService } from '../services/mobile-api';
import { RoutingPathsEnum, RoutingService } from '../services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from '../services/storage/session-storage.service';

export interface IAgentStoreResolver {
	code?: string;
	enabled: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class AgentStoreResolver implements Resolve<void> {
	constructor(
		private configApiService: ConfigApiService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
		return this.configApiService.configStoreAgentReferralEnabled().pipe(
			map((result) => {
				this.sessionStorageService.set(STORAGE_KEYS.REMOVE_LOCATION_LINK, true);
				const extra = {
					code: route.paramMap.get('id'),
					enabled: result?.result === 'success' && result?.value === true
				};
				return this.routingService.route(RoutingPathsEnum.home, { state: extra, queryParams: route.queryParams });
			})
		);
	}
}
