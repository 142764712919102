import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { some } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PdfDialogComponent } from '../../../shared/components/dialogs/pdf-dialog/pdf-dialog.component';
import { AddressUtils } from '../../utils/address-utils';
import { OrganizationUtils } from '../../utils/organization-utils';
import { EnvironmentService } from '../environment/environment.service';
import { IAddressResult } from '../mobile-api/mobile-api.model';
import { IssuingPartnerService } from '../partner/issuing-partner.service';

@Injectable({
	providedIn: 'root'
})
export class LoanExamplesService implements OnDestroy {
	private readonly ENABLED_EXAMPLE_STATES = [
		'AZ',
		'IL',
		'CA',
		'UT',
		'TX',
		'MO',
		'NJ',
		'NM',
		'NV',
		'WI',
		'ID',
		'FL',
		'VA',
		'SC',
		'KY',
		'MS',
		'OR',
		'AR',
		'DE',
		'MT',
		'SD',
		'ND',
		'NH',
		'IN'
	];
	private readonly SAMPLE_RATE_PDF_SUFFIX = '/personal-loans/sample-rates/sample-rates-and-fees';

	private subscription: Subscription = new Subscription();

	private language: string;
	private issuingOrganization: string;

	constructor(
		private environmentService: EnvironmentService,
		private translocoService: TranslocoService,
		private issuingPartnerService: IssuingPartnerService,
		public dialog: MatDialog
	) {
		const translocoSub = this.translocoService.langChanges$.pipe(tap((lang) => (this.language = lang))).subscribe();
		const issuingOrgSub = this.issuingPartnerService.issuingOrganization$
			.pipe(tap((issuingOrg) => (this.issuingOrganization = issuingOrg)))
			.subscribe();
		this.subscription.add(translocoSub);
		this.subscription.add(issuingOrgSub);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private getFullS3PDFUrl(region: string) {
		const urlPrefix = this.environmentService.isProduction
			? 'https://oportun-s3-prod-usw1-public-all.s3-us-west-1.amazonaws.com'
			: 'https://oportun-s3-dev-usw1-public-all.s3-us-west-1.amazonaws.com';

		return `${urlPrefix}${this.SAMPLE_RATE_PDF_SUFFIX}-${region}-${this.language.toUpperCase()}.pdf`;
	}

	getShowLoanExamplesLink(addresses: IAddressResult[] | IAddressResult | string): boolean {
		let state: string;
		if (Array.isArray(addresses)) {
			state = AddressUtils.getHomeAddress(addresses)?.state;
		} else if (typeof addresses === 'string') {
			state = addresses;
		} else {
			state = (addresses || {})?.state;
		}
		return (
			OrganizationUtils.isMetaBank(this.issuingOrganization) ||
			some(this.ENABLED_EXAMPLE_STATES, (s) => s === state?.toUpperCase())
		);
	}

	openLoanExamplesModal(state: string): Observable<any> {
		const data = {
			title: this.translocoService.translate('VIEW_DOCUMENTS.loanExamples', { state: state }),
			url: this.loanExampleURL(OrganizationUtils.isMetaBank(this.issuingOrganization), state)
		};
		const dialogRef = this.dialog.open(PdfDialogComponent, { data });
		return dialogRef.afterClosed();
	}

	loanExampleURL(isMetaBank: boolean, state: string): string {
		let loanExampleURL = '';
		const metaBankStateList = ['GA', 'HI', 'CA', 'TX', 'FL', 'NJ', 'AZ', 'IL'];
		/* modify url only for metabank loans in above states */
		loanExampleURL = isMetaBank
			? metaBankStateList.includes(state)
				? this.getFullS3PDFUrl(`META-${state}`)
				: this.getFullS3PDFUrl('META')
			: this.getFullS3PDFUrl(state);
		return loanExampleURL;
	}
}
