<div class="plaid-refresh" *transloco="let t">
	<div *ngIf="connectedInstitutions">
		<op-plaid-refresh-initiate
			id="plaid_refresh_initiate"
			(confirm)="confirmRefresh()"
			(declineRefresh)="declineRefresh()"
			(openPlaid)="openPlaid()"
			[connectedInstitutions]="connectedInstitutions"
			[accountList]="accountList"
			*ngIf="!showRefreshReconnectScreen; else refresh_reconnect"
		></op-plaid-refresh-initiate>
		<ng-template #refresh_reconnect
			><op-plaid-refresh-reconnect
				id="plaid_refresh_reconnect"
				[connectedInstitutions]="connectedInstitutions"
				[accountList]="accountList"
				(openPlaid)="openPlaid()"
			></op-plaid-refresh-reconnect
		></ng-template>
	</div>
</div>
