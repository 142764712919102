import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { IConfigResult } from 'src/app/core/services/mobile-api';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';

@Component({
	selector: 'op-loan-disclosures',
	templateUrl: './loan-disclosures.component.html',
	styleUrls: ['./loan-disclosures.component.scss']
})
export class LoanDisclosuresComponent implements OnInit, OnDestroy {
	isSoftPullEnabled = false;
	issuingOrganization: string;
	private subscription = new Subscription();
	constructor(private softPullService: SoftPullService, private issuingPartnerService: IssuingPartnerService) {}

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$.pipe(filter(Boolean)).subscribe({
			next: (softPull: IConfigResult) => {
				this.isSoftPullEnabled = softPull.value;
			}
		});
		this.subscription.add(softPullSub);

		const issuingOrganizationSub = this.issuingPartnerService.issuingOrganization$
			.pipe(
				tap((issuingOrg) => {
					this.issuingOrganization = issuingOrg;
				})
			)
			.subscribe();
		this.subscription.add(issuingOrganizationSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
