import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isFunction, noop } from 'lodash';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductCategoriesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { ConfigApiService, ISetAchBankAccountResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { IAutoVerifyScenario, SectionTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.model';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import {
	BankVerificationDialogComponent,
	IBankVerificationDialogData
} from 'src/app/shared/components/dialogs/bank-verification-dialog/bank-verification-dialog.component';
import { CheckDeliveryNoticeComponent } from 'src/app/shared/components/dialogs/check-delivery-notice/check-delivery-notice.component';

import { DocumentSubmitService } from '../document-submit/document-submit.service';
import { DisbursementChannelEnum } from 'src/app/core/services/loan-application/disbursement/disbursement.model';

@Component({
	selector: 'op-receive-funds',
	templateUrl: './receive-funds.component.html',
	styleUrls: ['./receive-funds.component.scss']
})
export class ReceiveFundsComponent implements OnInit, OnDestroy {
	constructor(
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private originationPartnerService: OriginationPartnerService,
		private translocoService: TranslocoService,
		private bankAccountService: AchBankAccountsService,
		private routingService: RoutingService,
		private dialogService: DialogService,
		private plaidLinkService: PlaidLinkService,
		private documentSubmitService: DocumentSubmitService,
		private sessionStorageService: SessionStorageService,
		private tagDataService: TagDataService,
		private configService: ConfigApiService
	) {}

	private subscription = new Subscription();

	alternateFundingKey: string;
	partnerBrand: string;
	isPlaidBankAccountExists: boolean;
	expandFlag: boolean;
	accountList: IAchBankAccount[];
	previousLoanAccountList: IAchBankAccount[];
	showPreviousLoanBank: boolean;
	autoVerifyScenario: IAutoVerifyScenario;
	selectAccount: boolean;
	bankConnectEvent: BankConnectEventTypeEnum;

	connectAccountError: string;
	showNoAccountsError: boolean;

	incomeError: string;
	showIcon: boolean = false;
	fileType = SectionTypeEnum.bank;
	isGCPOrReturn: boolean = true;
	applicant: ApplicantUtils;
	showNewDesign = undefined;
	loanApp: ILoanApplication;

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				switchMap((loanApp: ILoanApplication) => {
					this.loanApp = loanApp;
					this.applicant = ApplicantUtils.fromLoanApp(loanApp);
					this.autoVerifyScenario = this.documentSubmitService.getAutoVerifyScenario();
					this.isPlaidBankAccountExists = this.loanAppService.isVerifiedBankAccountExists();
					this.expandFlag = this.isPlaidBankAccountExists;
					const fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
					this.isGCPOrReturn = this.loanAppService.isGCP() || this.applicant.isReturning();
					return forkJoin({
						brand: this.originationPartnerService.getPartnerBrand(fundingInfo.getPartnerDisbursementChannel()),
						loanApp: of(loanApp)
					});
				})
			)
			.subscribe({
				next: ({ brand, loanApp }) => {
					this.partnerBrand = brand;
					this.alternateFundingKey = this.getAlternateFundingTranslationKey(loanApp);
				}
			});
		this.subscription.add(loanAppSub);

		this.mobileService.updateDisbursementAch(true, this.loanAppService.loanApplicationId).subscribe();
		const bankSub = this.bankAccountService.bankAccounts$.pipe(filter((r) => Boolean(r))).subscribe({
			next: (rsp) => {
				if (this.accountList) {
					this.accountList.length = 0;
				}
				this.accountList = rsp;
			}
		});

		this.subscription.add(bankSub);

		if (this.isGCPOrReturn && !this.isPlaidBankAccountExists) {
			const previousLoanBankSub = this.mobileService
				.getAchBankAccountsForApplicant(this.loanAppService.loanApplicationId)
				.subscribe({
					next: (rsp) => {
						if (rsp?.length > 0) {
							this.previousLoanAccountList = rsp;
							this.showPreviousLoanBank = true;
						}
					}
				});
			this.subscription.add(previousLoanBankSub);
		}

		if (!this.isPlaidBankAccountExists) {
			const plaidSub = this.plaidLinkService.plaid$.subscribe({
				next: (rsp) => {
					this.onBankConnect(rsp);
				}
			});
			this.subscription.add(plaidSub);
		}
		this.getConfigValue();
	}

	getConfigValue(): void {
		this.configService
			.showNewFundingDesign(this.loanAppService.loanApplicationId)
			.pipe(
				map((res) => res.result),
				catchError((err) => {
					return of(false);
				})
			)
			.subscribe((value) => {
				this.showNewDesign = value;
				const tagEvent = {
					event_action: 'on_page_load',
					applicant_id: this.loanAppService.loanApplicationId,
					issuing_org: this.loanAppService.getLoanApp()?.issuingOrganization,
					clientId: this.loanAppService.getCurrentApplicant()?.clientId,
					show_new_design: String(this.showNewDesign)
				};
				this.tagDataService.view({}, tagEvent);
			});
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent = event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		if (event?.section === FileUploadType.bank) {
			this.showNoAccountsError = event.data.responseStatus !== BankConnectResponseStatusEnum.accountsFound;
			const accountsFound: boolean = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;

			if (!accountsFound) {
				this.showNoAccountsDialog(accountsFound);
			} else {
				this.refreshBankAccountList(event);
			}
		}
	}

	showNoAccountsDialog(accountsFound: boolean): void {
		const msgData = this.plaidLinkService.getBankDialogMessage(accountsFound);
		if (msgData) {
			this.dialogService
				.openMessageDialog(
					msgData,
					() => of(),
					() => of()
				)
				.subscribe({
					next: () => {
						this.tagDataService.link(
							{},
							{
								tealium_event: 'bank_not_found'
							}
						);
					}
				});
		}
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = true;
	}

	refreshBankAccountList(event: IBankConnectEvent): void {
		this.bankAccountService.refreshBankAccountList().subscribe({
			next: (rsp) => {
				if (event.data?.responseStatus !== BankConnectResponseStatusEnum.emptyAccountList && rsp.length) {
					this.updateBankAccount(rsp[0]?.id, rsp[0]?.verificationStatus === AccountStatusEnum.verified);
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	openDialog(
		data: IBankVerificationDialogData,
		confirm: () => Observable<any>,
		cancel: () => Observable<any>
	): Observable<any> {
		return this.dialogService.openDialog(BankVerificationDialogComponent, { data }, confirm, cancel);
	}

	filterBankAccount(id: number, type: boolean = false, gcp: boolean = false): IBankVerificationDialogData {
		const filteredAccount = !gcp
			? this.accountList?.filter((account) => account.id === id)
			: this.previousLoanAccountList?.filter((account) => account.id === id);
		const accountType =
			filteredAccount?.find(Boolean)?.accountType === 'CHECKING'
				? this.translocoService.translate('MANUAL_ADD_BANK.checking')
				: this.translocoService.translate('MANUAL_ADD_BANK.savings');
		return {
			title: this.translocoService.translate(
				type ? 'BANK_VERIFICATION_DIALOG.success' : 'BANK_VERIFICATION_DIALOG.verificationSuccess'
			),
			message: this.translocoService.translate(
				type ? 'BANK_VERIFICATION_DIALOG.depositMessage' : 'BANK_VERIFICATION_DIALOG.verficationMessage',
				{
					bankName: filteredAccount?.[0].bankName,
					accountNumber: filteredAccount?.[0].accountNumber,
					accountType: accountType
				}
			),
			confirmText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.okay')
		};
	}

	onBankVerificationComplete(event, gcp: boolean = false): void {
		this.openDialog(
			this.filterBankAccount(event?.id, true, gcp),
			() => of(true),
			() => of(false)
		).subscribe({
			next: (resp) => resp && this.routingService.route(RoutingPathsEnum.documentSubmit)
		});
	}

	getErrorDialogData(): IBankVerificationDialogData {
		return {
			title: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailed'),
			message: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailMessage'),
			retryText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.retry'),
			manualText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verifyManually'),
			manualDisclaimer: this.translocoService.translate('BANK_VERIFICATION_DIALOG.submitDocuments')
		};
	}

	notVerified(emptyAccountList: boolean = false): void {
		const removeDisclaimer = {
			manualDisclaimer: null,
			manualText: null
		};
		this.openDialog(
			Object.assign({}, this.getErrorDialogData(), emptyAccountList ? removeDisclaimer : {}),
			() => of(true),
			() => of(false)
		).subscribe({
			next: (resp) => resp && this.routingService.route(RoutingPathsEnum.documentSubmit)
		});
	}

	notSaved(): void {
		this.notVerified();
	}

	saved(event: ISetAchBankAccountResult): void {
		if (event?.result) {
			this.updateBankAccount(event?.id, event?.verified);
		} else {
			this.notSaved();
		}
	}

	updateBankAccount(bankAccountId: number, verified: boolean): void {
		const bank = {
			bankAccountId: bankAccountId,
			customerConfirms: true
		};
		const updateBankAccount$ = this.bankAccountService.selectBankAccount(bank).pipe(take(1));

		updateBankAccount$.subscribe({
			next: () => {
				this.openDialog(
					verified ? this.filterBankAccount(bankAccountId) : this.getErrorDialogData(),
					() => of(true),
					() => of(false)
				).subscribe({
					next: (resp) => resp && this.routingService.route(RoutingPathsEnum.documentSubmit)
				});
			}
		});
	}

	rejectsAchAction(): void {
		this.openDenyAchDisbursementDialog()
			.pipe(
				switchMap((result) =>
					result ? this.mobileService.updateDisbursementAch(false, this.loanAppService.loanApplicationId) : of(null)
				)
			)
			.subscribe({
				next: (resp) => resp && this.routingService.route(RoutingPathsEnum.documentSubmit)
			});
	}

	getAlternateFundingTranslationKey(loanApp: ILoanApplication): string {
		const fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
		const isCashDisbursementAvail = fundingInfo.hasPartnerCashOption();
		const issuingOrganization = loanApp.issuingOrganization;
		const isCheckAtStoreDisbursementAvail =
			fundingInfo.isCoverageAreaWithinRadius() && !OrganizationUtils.isMetaBank(issuingOrganization);

		const isSPL =
			this.sessionStorageService.get('productCategorySelection') === ProductCategoriesEnum.securedPersonalLoan;

		if ((!isCashDisbursementAvail && isCheckAtStoreDisbursementAvail) || isSPL) {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCheckAtOportun';
		} else if (isCashDisbursementAvail && isCheckAtStoreDisbursementAvail) {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCashAtPartnerOrByCheckAtOportun';
		} else if (isCheckAtStoreDisbursementAvail) {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCashAtPartner';
		} else {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCheckByMail';
		}
	}

	private openDenyAchDisbursementDialog(): Observable<any> {
		if (OrganizationUtils.isMetaBank(this.loanAppService.getLoanApp().issuingOrganization)) {
			return this.dialogService.openSimpleDialog(CheckDeliveryNoticeComponent);
		} else {
			return of(true);
		}
	}
}
