import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { groupBy } from 'lodash';
import { IAchBankAccount } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { IInstitution, IPlaidConnectedInstitutions } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-plaid-refresh-initiate',
	templateUrl: './plaid-refresh-initiate.component.html',
	encapsulation: ViewEncapsulation.None
})
export class PlaidRefreshInitiateComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	bankOnly: boolean = true;

	@Output()
	confirm = new EventEmitter();

	@Output()
	declineRefresh = new EventEmitter();

	@Output()
	openPlaid = new EventEmitter();

	@Input()
	connectedInstitutions: IPlaidConnectedInstitutions;

	@Input()
	set accountList(accountList: IAchBankAccount[]) {
		this._accountList = accountList;
		this.accountListByBank = groupBy(this._accountList, 'bankName');
	}

	_accountList: IAchBankAccount[] = [];
	accountListByBank: { [key: string]: IAchBankAccount[] };
	constructor() {}

	onAccountSelectionChange(institution: IInstitution, event: MatCheckboxChange): void {
		institution.select = event.checked;
	}
}
