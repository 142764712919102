<div class="product-offer-card" *transloco="let t">
	<mat-card id="uplApproved">
		<mat-card-content>
			<div class="op-header-2-font">{{ t('ADJUST_TERMS.LOAN_TYPES.personalLoan') }}</div>
			<div class="op-body-1-bold-font productStatus op-ptb-8">{{ t('PRODUCT.PRODUCT_STATUSES.approved') }}</div>
			<div class="loan-amount">
				<div class="op-header-4-font">{{ t('OFFER_STATUS.upTo') }}</div>
				<div class="op-display-2-font">
					{{ product.maxApprovedAmount | currency: 'USD':'symbol':'0.0' }}
					<sup>*</sup>
				</div>
			</div>
			<div>
				<button
					mat-flat-button
					color="primary-small"
					id="uplApproveContinueBtn"
					class="continue-button op-full-width"
					(click)="onContinue('adjustTerms')"
					[innerHTML]="t('GLOBAL.continue')"
				></button>
			</div>

			<div
				id="plaidIncentiveAppliedFlag"
				class="op-icon-align-body-1 op-mt-20 bank-connected"
				*ngIf="plaidIncentiveApplied"
			>
				<mat-icon svgIcon="op-tagsDouble"></mat-icon>
				<span [innerHTML]="t('PRODUCT.PRODUCT_STATUSES.bankAccountConnected')"></span>
			</div>
		</mat-card-content>
	</mat-card>
</div>
