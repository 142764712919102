import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

import { opRequired } from '../../decorators/required.decorator';

@Component({
	selector: 'op-back-link',
	templateUrl: './back-link.component.html',
	styleUrls: ['./back-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackLinkComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	opRoute: string;

	@Input()
	green: boolean = true;

	@Output()
	back = new EventEmitter<void>();

	isBackUsed = false;
	constructor(private routingService: RoutingService) {}

	ngOnInit(): void {
		this.isBackUsed = this.back.observed;
	}

	goBack(): void {
		if (this.opRoute) {
			this.routingService.route(RoutingPathsEnum[this.opRoute]);
		} else if (this.isBackUsed) {
			this.back.emit();
		} else {
			this.routingService.back();
		}
	}
}
