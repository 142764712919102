import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { ISearchVinResult } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.model';
import { VehicleApiService } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.service';
import { vinValidator } from 'src/app/shared/validators/form-validators';

export interface IVinInformation {
	vin: string;
	vinResults: ISearchVinResult;
}

@Component({
	selector: 'op-vin-search',
	templateUrl: './vin-search.component.html',
	styleUrls: ['./vin-search.component.scss']
})
export class VinSearchComponent {
	private _enableVinSearch: boolean;
	@Input()
	get enableVinSearch(): boolean {
		return this._enableVinSearch;
	}
	set enableVinSearch(enableVinSearch: boolean) {
		this._enableVinSearch = Boolean(enableVinSearch);
		if (enableVinSearch) {
			this.formGroup.get('vin').enable();
		} else {
			this.formGroup.get('vin').disable();
			this.formGroup.get('vin').reset();
		}
	}

	@Output()
	vinResults = new EventEmitter<IVinInformation>();

	constructor(
		private formBuilder: FormBuilder,
		private vehicleApiService: VehicleApiService,
		private translocoService: TranslocoService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			vin: [null, [Validators.minLength(17), Validators.maxLength(17), vinValidator()]]
		});
	}

	eraseVin(): void {
		this.formGroup.get('vin').setValue(null);
		this.formGroup.get('vin').markAsUntouched();
		this.formGroup.get('vin').setErrors(null);
	}

	onSubmit(post: any): void {
		this.vehicleApiService.getVehicleVin(post.vin).subscribe({
			next: (rsp) => {
				this.vinResults.emit({ vin: post.vin, vinResults: rsp });
			},
			error: (err) => {
				this.formGroup.get('vin').setAsyncValidators([this.vinInvalidValidator(true)]);
				this.formGroup.get('vin').updateValueAndValidity();
			}
		});
	}

	getVinError(): string {
		const formControl: FormControl = this.formGroup.get('vin') as FormControl;
		formControl.clearAsyncValidators();
		return formControl.hasError('minlength')
			? this.translocoService.translate('VEHICLE.vinLengthInvalidError')
			: formControl.hasError('pattern')
			? this.translocoService.translate('VEHICLE.vinInvalidError')
			: '';
	}

	vinInvalidValidator(setError: boolean): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			if (setError) {
				return of({ vinInvalid: true });
			}
			return null;
		};
	}
}
