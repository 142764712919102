import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { AddressComponent } from './address/address.component';
import { IdentificationComponent } from './identification/identification.component';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';
import { IncomeSourceComponent } from './income-source/income-source.component';
import { PreQualifyComponent } from './pre-qualify/pre-qualify.component';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { DebtSourcesComponent } from './debt-sources/debt-sources.component';
import { PreQualifyResolver } from './pre-qualify.resolver';
import { LandingComponent } from './landing/landing.component';
import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { PreQualifyGuard } from './pre-qualify.guard';

const routes: Routes = [
	{
		path: RoutingPathsEnum.preQualifyEntry,
		component: LandingComponent,
		resolve: { loanApp: PreQualifyResolver }
	},
	{
		path: RoutingPathsEnum.preQualify,
		component: PreQualifyComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver },
		children: [
			{
				path: RoutingPathsEnum.address,
				component: AddressComponent,
				canActivate: [PreQualifyGuard],
				data: { step: 1 }
			},
			{
				path: RoutingPathsEnum.identification,
				component: IdentificationComponent,
				canActivate: [PreQualifyGuard],
				data: { step: 2 }
			},
			{
				path: RoutingPathsEnum.additionalInformation,
				component: AdditionalInformationComponent,
				canActivate: [PreQualifyGuard],
				data: { step: 3 }
			},
			{
				path: RoutingPathsEnum.income,
				component: IncomeSourceComponent,
				canActivate: [PreQualifyGuard],
				data: { step: 4 }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PreQualifyRoutingModule {}
