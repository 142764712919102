export function opRequired(message?: string) {
	return function (target: Object, propertyKey: PropertyKey) {
		Object.defineProperty(target, propertyKey, {
			get() {
				throw new Error(message || `Attribute ${String(propertyKey)} is required`);
			},
			set(value) {
				Object.defineProperty(this, propertyKey, {
					value,
					writable: true
				});
			}
		});
	};
}
