import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IContactUsDialogData {
	phoneNumber: string;
	fullHours: string;
}

/**
 * Dialog with information on how to contact oportun.
 *
 * @export
 * @class ContactUsDialogComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-contact-us-dialog',
	templateUrl: './contact-us-dialog.component.html',
	styleUrls: ['./contact-us-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<ContactUsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IContactUsDialogData
	) {}

	onSubmit(): void {
		this.dialogRef.close(true);
	}
}
