<div class="loan-disclosures" *transloco="let t">
	<op-back-link class="op-mt-20" id="loandisclosureBck">{{ t('LOAN_DISCLOSURES.back') }}</op-back-link>
	<op-masthead id="creditTitle" *ngIf="!isSoftPullEnabled" [title]="t('LOAN_DISCLOSURES.creditTitle')"></op-masthead>
	<op-masthead id="softPullTitle" *ngIf="isSoftPullEnabled" [title]="t('LOAN_DISCLOSURES.softPullTitle')"></op-masthead>
	<op-content-container class="content">
		<div
			id="creditLoanDisclosures"
			*ngIf="!isSoftPullEnabled && issuingOrganization"
			[innerHtml]="t('LOAN_DISCLOSURES.creditLoanDisclosures.' + issuingOrganization) | opSafeHtml"
		></div>
		<div
			id="softPullLoanDisclosures"
			*ngIf="isSoftPullEnabled && issuingOrganization"
			[innerHtml]="t('LOAN_DISCLOSURES.softPullLoanDisclosures.' + issuingOrganization) | opSafeHtml"
		></div>
	</op-content-container>
</div>
