<div class="plaid-account-list" *transloco="let t">
	<op-masthead [title]="t('PLAID_CONNECT.ACCOUNT_LIST.greatNews')"></op-masthead>
	<op-content-container class="content">
		<div *ngFor="let account of accountList; let i = index">
			<div class="plaid-account-group op-mt-10">
				<div>{{ account.bankName }}</div>
				<div class="op-gray op-mt-10">
					{{ account.accountType | titlecase }}: {{ t('PLAID_CONNECT.ACCOUNT_LIST.endingIn') }}
					{{ account.accountNumber | slice: -4 }}
				</div>
				<div class="op-icon-align-body-1 op-mt-10">
					<mat-icon svgIcon="op-checkMark-outline"></mat-icon>
					{{ t('PLAID_CONNECT.ACCOUNT_LIST.verifiedAccount') }}
				</div>
			</div>
		</div>
		<div class="op-mt-20">
			<button class="op-full-width" mat-flat-button color="primary" id="plaidAccountListNext" (click)="onNext($event)">
				{{ t('PLAID_CONNECT.ACCOUNT_LIST.next') }}
			</button>
		</div>

		<div class="op-label-font op-mt-20 op-mb-20">
			<p class="policy" [innerHTML]="t('PLAID_CONNECT.youAgreePlaidAccess')"></p>
			<p class="policy" [innerHTML]="t('PLAID_CONNECT.reviewPlaidTerms')"></p>
		</div>

		<div class="op-mt-20">
			<button
				class="op-full-width"
				mat-flat-button
				color="secondary"
				id="plaidAccountListAddAnotherBank"
				(click)="onAddBank($event)"
			>
				{{ t('PLAID_CONNECT.ACCOUNT_LIST.addAnotherBank') }}
				<mat-icon svgIcon="op-popout"> </mat-icon>
			</button>
		</div>
	</op-content-container>
</div>
