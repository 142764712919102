import { Component } from '@angular/core';

import { ProductOfferCardComponent } from '../product-offer-card/product-offer-card.component';

@Component({
	selector: 'op-secured-personal-loan-approved',
	templateUrl: './secured-personal-loan-approved.component.html',
	styleUrls: ['./secured-personal-loan-approved.component.scss']
})
export class SecuredPersonalLoanApprovedComponent extends ProductOfferCardComponent {
	constructor() {
		super();
	}

	onContinue(method: string): void {
		this.continue.emit({ method, productCategory: this.product.productCategory });
	}
}
