import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';

import { OrganizationEnum } from '../../utils/organization-utils';

@Injectable({
	providedIn: 'root'
})
export class IssuingPartnerService implements OnDestroy {
	private readonly issuingOrganizationSource = new BehaviorSubject<string>(OrganizationEnum.OPORTUN.toLowerCase());
	readonly issuingOrganization$ = this.issuingOrganizationSource.asObservable();

	private subscription = new Subscription();

	constructor(private landingService: LandingService, private loanAppService: LoanApplicationService) {
		const issuingOrgSub = this.loanAppService.loanApplication$
			.pipe(
				map((rsp) => rsp?.issuingOrganization),
				filter((issuingOrg) => Boolean(issuingOrg)),
				distinctUntilChanged(),
				tap((issuingOrg) => {
					this.issuingOrganizationSource.next(issuingOrg.toLocaleLowerCase());
				})
			)
			.subscribe();
		this.subscription.add(issuingOrgSub);

		const issuingOrgLandingSub = this.landingService.landing$
			.pipe(
				map((rsp) => rsp?.findApplication?.issuingOrganization),
				filter((issuingOrg) => Boolean(issuingOrg)),
				distinctUntilChanged(),
				tap((issuingOrg) => {
					this.issuingOrganizationSource.next(issuingOrg.toLocaleLowerCase());
				})
			)
			.subscribe();
		this.subscription.add(issuingOrgLandingSub);

		const issuingOrganizationZipLookupSub = this.landingService.zipCodeLookup$
			.pipe(
				map((rsp) => rsp?.issuingOrganization),
				tap((issuingOrg) => {
					this.issuingOrganizationSource.next(
						issuingOrg?.toLocaleLowerCase() || OrganizationEnum.OPORTUN.toLocaleLowerCase()
					);
				})
			)
			.subscribe();
		this.subscription.add(issuingOrganizationZipLookupSub);

		const issuingOrganizationLandingSource = this.landingService.landing$
			.pipe(
				map((landingDetails) => landingDetails?.specialOfferLeadInformation),
				map((rsp) => rsp?.issuingOrganization),
				tap((issuingOrg: string) => {
					if (issuingOrg) {
						this.issuingOrganizationSource.next(
							issuingOrg?.toLocaleLowerCase() || OrganizationEnum.OPORTUN.toLocaleLowerCase()
						);
					}
				})
			)
			.subscribe();
		this.subscription.add(issuingOrganizationLandingSource);
	}

	get lender(): string {
		return this.issuingOrganizationSource.getValue();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
