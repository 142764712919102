<div class="proof-of-income" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead
		*ngIf="proofState !== proofStatusEnum.upload"
		[customClass]="'head-bp-4'"
		[title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.incomeTitle')"
	>
	</op-masthead>
	<op-masthead
		*ngIf="proofState === proofStatusEnum.upload"
		[customClass]="'head'"
		[title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.incomeTitleFor')"
	></op-masthead>
	<div class="op-mt-16"></div>
	<op-content-container class="content" *ngIf="documentClassificationList$ | async as classificationList">
		<op-document-classification
			*ngIf="proofState === proofStatusEnum.classificationSelection"
			id="incomeClassification"
			[documentClassificationList]="classificationList"
			(documentClassification)="saveDocumentClassification($event)"
			(cancel)="onCancel($event)"
		>
		</op-document-classification>

		<div *ngIf="proofState === proofStatusEnum.upload">
			<div class="op-dark-grey income-source">
				<div class="op-mb-4 income-item op-body-2-font" *ngFor="let source of incomeSources">
					<span>{{ source.source }}</span>
					<span>
						<span>{{ source.amount }}</span>
						<span>{{ source.frequency }}</span>
					</span>
				</div>
			</div>
			<div
				*ngIf="
					stateStorage?.selectedDocumentClassification?.key === 'PAY_STUB' ||
					(!showMonthLabeling && stateStorage?.selectedDocumentClassification?.key === 'BANK_STATEMENT')
				"
				class="icon-align op-mb-20"
				id="criteria0_30"
			>
				<mat-icon svgIcon="op-info-green"></mat-icon>
				<span
					*ngIf="stateStorage?.selectedDocumentClassification?.key === 'PAY_STUB'"
					class="icon-text"
					[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.providePayStub')"
				></span>
				<span
					*ngIf="stateStorage?.selectedDocumentClassification?.key === 'BANK_STATEMENT'"
					class="icon-text"
					[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.provideLast2Months')"
				></span>
			</div>

			<div>
				<div class="op-header-3-font op-mb-8">{{ stateStorage?.selectedDocumentClassification?.text }}</div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								);
								index as i
							"
						>
							<div [innerHTML]="value"></div>
						</li>
					</ul>
				</div>
			</div>

			<op-document-notification *ngIf="bounceReasons?.length" id="incomeBounceReasons" class="attention op-mtb-20">
				<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification>

			<div
				*ngIf="
					showMonthLabeling && stateStorage?.selectedDocumentClassification?.key === 'BANK_STATEMENT';
					else no_labelling
				"
			>
				<div class="bank-pdf op-mb-32">
					<mat-icon svgIcon="op-info-blue-fill"></mat-icon>
					<div class="icon-text">
						<div class="op-body-1-font op-light-black">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.originalPdf') }}
						</div>
					</div>
				</div>

				<op-bank-criteria-met *ngIf="showCriteria" [criteria]="criteria"></op-bank-criteria-met>

				<op-document-upload
					id="bankAccountUpload31Days"
					[categoryType]="categoryType"
					[documentClassification]="stateStorage?.selectedDocumentClassification"
					[selectedClassificationFiles]="selectedClassificationFiles"
					[showSingleFile]="true"
					(displayedSingleFile)="displayedSingleFile31Days($event)"
					[criteria]="criteriaEnum.zeroToThirtyDays"
					[collateUploads]="'true'"
					#bankAccountUpload31Days
				>
					<div uploadTitle>
						<div class="upload-title">
							<div class="op-body-1-bold-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.month1') }}</div>
							<div class="title-badge">
								<op-document-badge
									*ngIf="singleFile31Days?.badge === docBadgeEnum.verified"
									id="{{ 'income_month1_verified' }}"
									class="ready badge"
									icon="op-checkMark-green"
									[small]="true"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.verified') }}
								</op-document-badge>
								<op-document-badge
									*ngIf="singleFile31Days?.badge === docBadgeEnum.attention"
									id="{{ 'income_month1_attention' }}"
									class="attention badge"
									icon="op-attention"
									[small]="true"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
								</op-document-badge>
							</div>
						</div>
						<div class="op-body-2 op-light-black">
							<span>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.statementEndDate') }}</span>
							<span class="op-body-2-bold-font"> {{ day31String }} </span>
						</div>
					</div>
					<div uploadIdleInfo>
						<div
							class="op-dark-grey op-mt-8 op-label-font"
							[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
						></div>
					</div>
					<div uploadIdleButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
					</div>
					<div reUploadPendingButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reUploadDocument') }}</div>
					</div>
				</op-document-upload>

				<div class="op-mb-32"></div>

				<op-document-upload
					id="bankAccountUpload65Days"
					[categoryType]="categoryType"
					[documentClassification]="stateStorage?.selectedDocumentClassification"
					[selectedClassificationFiles]="selectedClassificationFiles"
					[showSingleFile]="true"
					(displayedSingleFile)="displayedSingleFile65Days($event)"
					[criteria]="criteriaEnum.thirtyOneToSixtyFiveDays"
					[collateUploads]="'true'"
					#bankAccountUpload65Days
				>
					<div uploadTitle>
						<div class="upload-title">
							<div class="op-body-1-bold-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.month2') }}</div>
							<div class="title-badge">
								<op-document-badge
									*ngIf="singleFile65Days?.badge === docBadgeEnum.verified"
									id="{{ 'income_month2_verified' }}"
									class="ready badge"
									icon="op-checkMark-green"
									[small]="true"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.verified') }}
								</op-document-badge>
								<op-document-badge
									*ngIf="singleFile65Days?.badge === docBadgeEnum.attention"
									id="{{ 'income_month2_attention' }}"
									class="attention badge"
									icon="op-attention"
									[small]="true"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
								</op-document-badge>
							</div>
						</div>
						<div class="op-body-2 op-light-black">
							<span>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.statementEndDate') }}</span>
							<span class="op-body-2-bold-font"> {{ day65String }} </span>
						</div>
					</div>
					<div uploadIdleInfo>
						<div
							class="op-dark-grey op-mt-8 op-label-font"
							[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
						></div>
					</div>
					<div uploadIdleButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
					</div>
					<div reUploadPendingButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reUploadDocument') }}</div>
					</div>
				</op-document-upload>
			</div>

			<ng-template #no_labelling>
				<op-document-upload
					id="incomeUpload"
					[categoryType]="categoryType"
					[documentClassification]="stateStorage?.selectedDocumentClassification"
					[selectedClassificationFiles]="selectedClassificationFiles"
					[showSingleFile]="false"
					[attentionOverRide]="attention"
				>
					<div uploadIdleInfo>
						<div
							class="op-dark-grey op-mt-8 op-body-2-font"
							[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
						></div>
					</div>
					<div uploadIdleButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
					</div>

					<div criteriaMet>
						<div
							*ngIf="showCriteria"
							class="criteria-wrapper"
							[ngClass]="{
								attention: !criteria[criteriaEnum.zeroToThirtyDays] && !criteria[criteriaEnum.thirtyOneToSixtyFiveDays],
								attention2:
									(!criteria[criteriaEnum.zeroToThirtyDays] || !criteria[criteriaEnum.thirtyOneToSixtyFiveDays]) &&
									(criteria[criteriaEnum.zeroToThirtyDays] || criteria[criteriaEnum.thirtyOneToSixtyFiveDays]),
								met: criteria[criteriaEnum.zeroToThirtyDays] && criteria[criteriaEnum.thirtyOneToSixtyFiveDays]
							}"
						>
							<div class="icon-align" [ngClass]="{ met: criteria[criteriaEnum.zeroToThirtyDays] }" id="criteria0_30">
								<mat-icon *ngIf="!criteria[criteriaEnum.zeroToThirtyDays]" svgIcon="op-attention"></mat-icon>
								<mat-icon
									class="op-white"
									*ngIf="criteria[criteriaEnum.zeroToThirtyDays]"
									svgIcon="op-checkMark-dark-green"
								></mat-icon>
								<span *ngIf="!criteria[criteriaEnum.zeroToThirtyDays]" id="criteriaNotMet0_30" class="icon-text">
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteria.0_30_DAYS') }}
								</span>
								<span *ngIf="criteria[criteriaEnum.zeroToThirtyDays]" id="criteriaMet0_30" class="icon-text">
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteriaMet.0_30_DAYS') }}
								</span>
							</div>
							<div
								class="icon-align op-mt-8"
								[ngClass]="{ met: criteria[criteriaEnum.thirtyOneToSixtyFiveDays] }"
								id="criteria31_65"
							>
								<mat-icon *ngIf="!criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" svgIcon="op-attention"></mat-icon>
								<mat-icon
									class="op-white"
									*ngIf="criteria[criteriaEnum.thirtyOneToSixtyFiveDays]"
									svgIcon="op-checkMark-dark-green"
								></mat-icon>
								<span
									*ngIf="!criteria[criteriaEnum.thirtyOneToSixtyFiveDays]"
									id="criteriaNotMet31_65"
									class="icon-text"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteria.31_65_DAYS') }}
								</span>
								<span *ngIf="criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" id="criteriaMet31_65" class="icon-text">
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteriaMet.31_65_DAYS') }}
								</span>
							</div>
						</div>
					</div>
				</op-document-upload>
			</ng-template>

			<op-document-notification *ngIf="showContinueMessage" id="incomeContinueApplication" class="processing op-mtb-20">
				<div class="icon-align">
					<mat-icon svgIcon="op-processingInfo"></mat-icon>
					<div class="icon-text">
						<div class="op-body-2-bold-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.continueYourApplication') }}</div>
					</div>
				</div>
				<div class="op-body-2 op-light-black op-mt-5">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.continueWeWillLetYouKnow') }}
				</div>
			</op-document-notification>

			<div class="op-mt-40">
				<button
					mat-flat-button
					color="primary"
					name="incomeContinueBtn"
					id="incomeContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="incomeCancelBtn"
					id="incomeCancelBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Cancel'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>

		<div *ngIf="proofState === proofStatusEnum.status">
			<op-document-status
				id="incomeStatus"
				[categoryType]="categoryType"
				[classificationVerification]="autoVerificationMap"
				(done)="onDocStatus($event)"
				[documentClassificationList]="classificationList"
			>
				<div [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.additionDocInfo')"></div>

				<op-document-notification *ngIf="bounceReasons?.length" id="incomeBounceReasons" class="attention op-mtb-20">
					<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
						<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
					</ul>
				</op-document-notification>
			</op-document-status>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="doneBtn"
					id="incomeStatusBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</div>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
