import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { startCase } from 'lodash';
import { combineLatest, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { formatDate } from 'src/app/core/services/date-utils/date-utils';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata, MobileApiService } from 'src/app/core/services/mobile-api';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

import { STATE_CA } from '../../shared/constants/states-const';
import { LoanPurposeComponent } from '../loan-purpose/loan-purpose.component';
import { FTRSectionModeEnum, FTRSectionNameEnum, IFTRSection, IFTRSectionFields } from './fast-track-step.model';
import { FastTrackService } from './fast-track.service';
import { LegalDisclosuresComponent } from './legal-disclosures/legal-disclosures.component';
import { RoutingPathsEnum } from '../../core/services/routing/routing.service';

@Component({
	selector: 'op-app-fast-track',
	templateUrl: './fast-track.component.html',
	styleUrls: ['./fast-track.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [FastTrackService]
})
export class FastTrackComponent implements OnInit, OnDestroy {
	@ViewChild('stepper') stepper: MatStepper;
	@ViewChild(LoanPurposeComponent) loanPurposeComponent: LoanPurposeComponent;
	@ViewChild(LegalDisclosuresComponent) LegalDisclosuresComponent: LegalDisclosuresComponent;

	fastTackSteps: IFTRSection[];
	stepMode = FTRSectionModeEnum;
	fTRSectionName = FTRSectionNameEnum;
	private subscription = new Subscription();

	yes: string;
	no: string;
	readonly JOB = 1700; // Value for "job" in the IncomeSourceType enum
	isHomeAddressCA: boolean;
	isMetaOrganization: boolean;
	hasOtherDebts: any;
	currentState: string;
	lastStepIndex: number = 0;

	constructor(
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private metadataService: MetadataService,
		private fastTrackService: FastTrackService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private tagDataService: TagDataService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {
		const sub = combineLatest([
			this.mobileService.getApplicant(this.loanAppService.loanApplicationId).pipe(catchError((error) => of(error))),
			zip(
				this.metadataService.select(MetadataEnum.IdentificationType),
				this.metadataService.select(MetadataEnum.USState),
				this.metadataService.select(MetadataEnum.Country),
				this.metadataService.select(MetadataEnum.DebtTypeWithCreditHit),
				this.metadataService.select(MetadataEnum.DebtType),
				this.metadataService.select(MetadataEnum.IncomeSourceType),
				this.metadataService.select(MetadataEnum.IncomeFrequency),
				this.metadataService.select(MetadataEnum.CheckCashingFee),
				this.metadataService.select(MetadataEnum.DateRange)
			)
		])
			.pipe(
				tap(() => this.checkForQueryParam()),
				map(
					([
						application,
						[
							idType,
							usStates,
							country,
							getDebtTypeWithCreditHit,
							getDebtType,
							getIncomeSourceType,
							getIncomeFrequency,
							checkCashingFee,
							getDataRange
						]
					]) => {
						this.isMetaOrganization = OrganizationUtils.isMetaBank(
							this.loanAppService.getLoanApp().issuingOrganization
						);
						const debtType = Boolean(application.creditRunSuccess) ? getDebtTypeWithCreditHit : getDebtType;
						const sameAddress = AddressUtils.addressesAreEqual(application.homeAddress, application.mailingAddress);
						this.yes = startCase(this.translocoService.translate('GLOBAL.yes'));
						this.no = startCase(this.translocoService.translate('GLOBAL.no'));
						this.currentState = application.homeAddress?.state;
						this.isHomeAddressCA = this.currentState === STATE_CA;
						this.hasOtherDebts = application.finances?.haveOtherDebt;
						this.updateFastTrack(this.isHomeAddressCA, [
							this.getFtrYourInformationStep(application, getDataRange),
							this.getFtrLoanPurpose(),
							this.getFtrAddress(application, sameAddress),
							this.getFtrIdentification(application, idType, country, usStates),
							this.getFtrFinances(application, checkCashingFee),
							this.getFtrDebts(application, debtType),
							this.getFtrIncome(application, getIncomeSourceType, getIncomeFrequency)
						]);
						this.setLastStepIndex();
					}
				),
				switchMap(() => this.getFastTrackCurrentStep()),
				filter((stepCount) => stepCount !== this.stepper.selectedIndex),
				map((stepCount) => {
					setTimeout(() => {
						Array.from(Array(stepCount)).forEach(() => this.stepper.next());
					}, 25);
				})
			)
			.subscribe({
				error: (err) => {
					this.showErrorMessage(Array.isArray(err?.error) ? err.error.find(Boolean).msg : err.error);
				}
			});
		this.subscription.add(sub);
	}

	private setLastStepIndex() {
		this.lastStepIndex = this.fastTackSteps.length - 1;
	}

	private updateFastTackSteps(steps: IFTRSection[]): void {
		if (!this.fastTackSteps) {
			this.fastTackSteps = steps;
		} else {
			this.fastTackSteps.forEach((item, i) => {
				const newStep = steps[i];
				for (const property in item) {
					item[property] = newStep[property];
				}
			});
		}
	}

	private updateFastTrack(isCa: boolean, steps: IFTRSection[]): void {
		if (isCa) {
			this.updateFastTackSteps(steps);
		} else {
			const debtIndex = Object.keys(FTRSectionNameEnum).indexOf(FTRSectionNameEnum.debt);
			this.updateFastTackSteps(steps.filter((elem, i) => i !== debtIndex));
		}
	}

	/**
	 *
	 *
	 * @param {*} application
	 * @param {*} debtType
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrDebts(application: IApplicant, debtType: IMetadata[]): IFTRSection {
		return {
			sectionTitle: 'FAST_TRACK.FTR_DEBT.title',
			sectionSubTitle: 'FAST_TRACK.FTR_DEBT.subTitle',
			sectionNote: '',
			section: FTRSectionNameEnum.debt,
			header: 'FAST_TRACK.FTR_DEBT.header',
			route: '/debt',
			hide: false,
			fields: this.getApplicantDebts(debtType, application.debts, application.finances?.haveOtherDebt),
			mode: FTRSectionModeEnum.editable
		};
	}

	/**
	 *
	 *
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrLegalDisclosure(): IFTRSection {
		return {
			sectionTitle: 'FAST_TRACK.FTR_LEGAL_DISCLOSURE.title',
			sectionSubTitle: '',
			sectionNote: '',
			section: FTRSectionNameEnum.legalDisclosure,
			header: 'SUMMARY.purpose',
			route: '',
			fields: [],
			mode: FTRSectionModeEnum.newAddition
		};
	}

	/**
	 *
	 *
	 * @param {*} application
	 * @param {*} getIncomeSourceType
	 * @param {*} getIncomeFrequency
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrIncome(
		application: IApplicant,
		getIncomeSourceType: IMetadata[],
		getIncomeFrequency: IMetadata[]
	): IFTRSection {
		return {
			sectionTitle: 'FAST_TRACK.FTR_INCOME.title',
			sectionSubTitle: 'FAST_TRACK.FTR_INCOME.subTitle',
			sectionNote: 'FAST_TRACK.FTR_INCOME.infoContent',
			section: FTRSectionNameEnum.income,
			header: 'FAST_TRACK.FTR_INCOME.header',
			route: '/income',
			fields: this.getApplicantIncome(getIncomeSourceType, getIncomeFrequency, application.incomes),
			mode: FTRSectionModeEnum.editable
		};
	}

	/**
	 *
	 *
	 * @param {*} application
	 * @param {*} checkCashingFee
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrFinances(application: IApplicant, checkCashingFee: IMetadata[]): IFTRSection {
		return {
			sectionTitle: this.translocoService.translate('FAST_TRACK.FTR_FINANCES.title', {
				firstName: application.firstName
			}),
			sectionSubTitle: 'FAST_TRACK.FTR_FINANCES.subTitle',
			sectionNote: '',
			section: FTRSectionNameEnum.finances,
			header: 'FAST_TRACK.FTR_FINANCES.header',
			route: '/finances',
			fields: [
				{
					title: 'FAST_TRACK.FTR_FINANCES.FIELDS.monthlyHousing',
					value: `$${application.finances.monthlyPayment}`
				},
				{
					title: 'FAST_TRACK.FTR_FINANCES.FIELDS.doYouHaveBank',
					value: application.finances.hasBankAccount ? this.yes : this.no
				},
				...(this.isHomeAddressCA
					? [
							{
								title: 'FAST_TRACK.FTR_FINANCES.FIELDS.doYouPayCheckFee',
								value:
									application.finances.checkCashingFee === null
										? this.no
										: checkCashingFee.find((item) => item.key === application.finances.checkCashingFee)?.text
							},
							{
								title: 'FAST_TRACK.FTR_FINANCES.FIELDS.usedPaydayService',
								value:
									application.finances.usedPaydayService === null
										? this.no
										: application.finances.usedPaydayService
										? this.yes
										: this.no
							}
					  ]
					: [])
			],
			mode: FTRSectionModeEnum.editable
		};
	}

	/**
	 *
	 *
	 * @param {*} application
	 * @param {*} idType
	 * @param {*} country
	 * @param {*} usStates
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrIdentification(
		application: IApplicant,
		idType: IMetadata[],
		country: IMetadata[],
		usStates: IMetadata[]
	): IFTRSection {
		return {
			sectionTitle: 'FAST_TRACK.FTR_IDENTIFICATION.title',
			sectionNote: '',
			sectionSubTitle: 'FAST_TRACK.FTR_IDENTIFICATION.subTitle',
			section: FTRSectionNameEnum.identification,
			header: 'FAST_TRACK.FTR_IDENTIFICATION.incomeSectionHeader',
			route: '/identification',
			fields: [
				{
					title: 'FAST_TRACK.FTR_IDENTIFICATION.FIELDS.takeHomePay',
					value: '$' + application?.initialTakeHomePay
				},
				{
					title: 'FAST_TRACK.FTR_IDENTIFICATION.FIELDS.idType',
					value: idType.find((item) => item.key === application.identificationDocument.type)?.text,
					header: 'FAST_TRACK.FTR_IDENTIFICATION.identitySectionHeader'
				},
				{
					title: 'FAST_TRACK.FTR_IDENTIFICATION.FIELDS.homeCountry',
					value: country.find((item) => item.key === application.identificationDocument.issuingCountry)?.text
				},
				{
					title: 'FAST_TRACK.FTR_IDENTIFICATION.FIELDS.issueState',
					value: usStates.find((item) => item.key === application.identificationDocument.issuingState)?.text
				},
				{
					title: application.last4Ssn
						? 'IDENTIFICATION.ssn'
						: application.last4Itin
						? 'IDENTIFICATION.itin'
						: 'SSN / ITIN',
					titleValue: application.last4Ssn || application.last4Itin ? false : true,
					value: application.last4Ssn
						? '***-**-' + application.last4Ssn
						: application.last4Itin
						? '***-**-' + application.last4Itin
						: this.translocoService.translate('IDENTIFICATION.dontHave')
				}
			],
			mode: FTRSectionModeEnum.editable
		};
	}

	/**
	 *
	 *
	 * @param {IApplicant} application
	 * @param {boolean} sameAddress
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrAddress(application: IApplicant, sameAddress: boolean): IFTRSection {
		return {
			sectionTitle: 'FAST_TRACK.FTR_ADDRESS.title',
			sectionSubTitle: 'FAST_TRACK.FTR_ADDRESS.subTitle',
			sectionNote: '',
			section: FTRSectionNameEnum.address,
			header: 'FAST_TRACK.FTR_ADDRESS.header',
			route: '/address',
			fields: [
				{
					title: 'FAST_TRACK.FTR_ADDRESS.FIELDS.address',
					value: application.homeAddress
						? ['streetAddress1', 'streetAddress2', 'city'].map((key) => application.homeAddress[key]).join(' ') +
						  `, ${application.homeAddress.state} ${application.homeAddress.postalCode}`
						: ' '
				},
				{
					title: 'FAST_TRACK.FTR_ADDRESS.FIELDS.movedToAddress',
					value: application.homeAddress ? formatDate(application.homeAddress.moveInDate, 'MMM yyyy') : ' '
				},
				{
					title: 'FAST_TRACK.FTR_ADDRESS.FIELDS.mailingAddress',
					value: sameAddress
						? this.translocoService.translate('SUMMARY.sameAsAbove')
						: application.mailingAddress
						? ['streetAddress1', 'streetAddress2', 'city'].map((key) => application.mailingAddress[key]).join(' ') +
						  `, ${application.mailingAddress.state} ${application.mailingAddress.postalCode}`
						: ' '
				}
			],
			mode: FTRSectionModeEnum.editable
		};
	}

	/**
	 *
	 *
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrLoanPurpose(): IFTRSection {
		return {
			sectionTitle: 'FAST_TRACK.FTR_LOAN_PURPOSE.title',
			sectionSubTitle: '',
			sectionNote: '',
			section: FTRSectionNameEnum.loanPurpose,
			header: '',
			route: '',
			fields: [],
			mode: FTRSectionModeEnum.newAddition
		};
	}

	/**
	 *
	 *
	 * @param {IApplicant} application
	 * @return {*}  {IFTRSection}
	 * @memberof FastTrackComponent
	 */
	private getFtrYourInformationStep(application: IApplicant, phoneDuration: IMetadata[]): IFTRSection {
		let ownerPhone = application?.phones?.length ? application?.phones[0] : null;
		return {
			sectionTitle: 'FAST_TRACK.FTR_YOUR_INFO.title',
			sectionSubTitle: 'FAST_TRACK.FTR_YOUR_INFO.subTitle',
			sectionNote: 'FAST_TRACK.FTR_YOUR_INFO.infoContent',
			section: FTRSectionNameEnum.yourInformation,
			header: 'FAST_TRACK.FTR_YOUR_INFO.header',
			route: '/personal-info',
			fields: [
				{
					title: 'FAST_TRACK.FTR_YOUR_INFO.FIELDS.name',
					value: `${application.firstName || ''} ${application.middleName || ''} ${application.lastName || ''}
					${application.maternalName || ''} ${application.suffix || ''}`
				},
				{
					title: 'FAST_TRACK.FTR_YOUR_INFO.FIELDS.dateOfBirth',
					value: application.dateOfBirth ? formatDate(application.dateOfBirth, 'MM/dd/yyyy') : ' '
				},
				{
					title: 'FAST_TRACK.FTR_YOUR_INFO.FIELDS.phoneNumber',
					value: ownerPhone ? ownerPhone.phoneNumber : ''
				},
				{
					title: 'FAST_TRACK.FTR_YOUR_INFO.FIELDS.email',
					value: application.emailAddress || ''
				},
				{
					title: 'FAST_TRACK.FTR_YOUR_INFO.FIELDS.smsNotifications',
					value: application.smsAuthorization ? this.yes : this.no
				},
				{
					title: 'FAST_TRACK.FTR_YOUR_INFO.FIELDS.howLongOwnedPhone',
					value: ownerPhone ? phoneDuration?.find((item) => item.key === ownerPhone.ownerDateRange)?.text : ''
				}
			],
			mode: FTRSectionModeEnum.editable
		};
	}

	/**
	 *
	 * Calculate array index asper the section name from session storage
	 * @return {*}  {number}
	 * @memberof FastTrackComponent
	 */
	getFastTrackCurrentStep(): Observable<number> {
		return of(this.sessionStorageService.get('fastTrackStep')).pipe(
			map((section) => (section ? this.fastTackSteps.findIndex((step) => step.section === section) : 0)),
			map((index) => (index === -1 ? 0 : index))
		);
	}

	/**
	 *
	 * Get section name from the array which matches to the index of the array
	 * @param {number} selectedIndex
	 * @return {*}  {string}
	 * @memberof FastTrackComponent
	 */
	getStepper(selectedIndex: number): string {
		let selectedStep: string;
		this.fastTackSteps.forEach((step, index) => {
			if (index === selectedIndex) {
				selectedStep = step.section;
			}
		});
		return selectedStep;
	}

	/**
	 *
	 *
	 * @param {MatStepper} stepper
	 * @param {FTRSection} ftrStep
	 * @memberof FastTrackComponent
	 */
	continue(stepper: MatStepper, ftrStep: IFTRSection): void {
		setTimeout(() => {
			switch (ftrStep.section) {
				case FTRSectionNameEnum.income:
					this.checkIfStepDone(this.fastTrackService.fastTrackIncomeStep(), stepper);
					break;
				case FTRSectionNameEnum.identification:
					this.checkIfStepDone(this.fastTrackService.fastTrackLegalIdentificationStep(), stepper);
					break;
				case FTRSectionNameEnum.finances:
					this.checkIfStepDone(this.fastTrackService.fastTrackFinancesStep(this.isHomeAddressCA), stepper);
					break;
				case FTRSectionNameEnum.debt:
					this.checkIfStepDone(
						this.fastTrackService.fastTrackDebtStep(this.isHomeAddressCA, this.hasOtherDebts),
						stepper
					);
					break;
				case FTRSectionNameEnum.address:
					this.checkIfStepDone(this.fastTrackService.fastTrackAddressStep(), stepper);
					break;
				case FTRSectionNameEnum.loanPurpose:
					this.checkIfStepDone(of(true), stepper);
					break;
				case FTRSectionNameEnum.legalDisclosure:
					this.fastTrackService.fastTrackLegalDisclosureStep();
					break;
				case FTRSectionNameEnum.yourInformation:
					this.checkIfStepDone(this.fastTrackService.fastTrackYourInformationStep(), stepper);
					break;
				default:
					this.checkIfStepDone(of(false), stepper);
					break;
			}
		}, 1);
	}

	/**
	 *
	 *
	 * @param {Observable<any>} step
	 * @param {MatStepper} stepper
	 * @memberof FastTrackComponent
	 */
	checkIfStepDone(step: Observable<any>, stepper: MatStepper): void {
		step.subscribe({
			next: () => {
				this.tagDataService.view(
					{},
					{
						tealium_event: 'fast-track-continue' + this.sessionStorageService.get('fastTrackStep'),
						product_sub_category: this.sessionStorageService.get('productCategorySelection'),
						product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
							this.loanAppService.getLoanApp().productOfferDetails
						)
					}
				);

				const currIndex = stepper.selectedIndex;
				const isPageEdit = false;
				if (currIndex === 3) {
					// identification complete successfully
					this.routeToPage(RoutingPathsEnum.creditAuth, FTRSectionNameEnum.finances, isPageEdit);
				} else {
					stepper.next(); // we moved a step here
					currIndex === this.lastStepIndex && stepper.selected.completed
						? this.routeToPage(RoutingPathsEnum.summary, null, isPageEdit)
						: this.sessionStorageService.set('fastTrackStep', this.getStepper(stepper.selectedIndex));
				}
			},
			error: (err) => {
				this.showErrorMessage(Array.isArray(err?.error) ? err.error.find(Boolean).msg : err.error);
			}
		});
	}

	/**
	 *
	 *
	 * @param {string} errorMessage
	 * @memberof FastTrackComponent
	 */
	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openSimpleMessageDialog({
				icon: 'op-alert',
				title: this.translocoService.translate('DIALOG_MESSAGE.genericTitle'),
				message:
					typeof errorMessage === 'string'
						? errorMessage
						: this.translocoService.translate('ERROR_MESSAGE.genericMessage')
			})
			.subscribe();
	}

	/**
	 *
	 *
	 * @private
	 * @param {IMetadata[]} debtType
	 * @param {*} debts
	 * @return {*} FTRSectionFields[]}
	 * @memberof FastTrackComponent
	 */
	private getApplicantDebts(debtType: IMetadata[], debts: any, haveOtherDebt: boolean): IFTRSectionFields[] {
		const PAYDAY_LOAN = 9100;
		const amount = this.translocoService.translate('SUMMARY.amount');
		const monthlyPayment = this.translocoService.translate('SUMMARY.monthlyPayment');
		let fields: IFTRSectionFields[] = [];
		if (haveOtherDebt) {
			fields = debts.map((debt) => {
				const label = debtType.find((item) => item.key === debt.type)?.text;

				if (debt.type === PAYDAY_LOAN) {
					return {
						title: `${label} ${amount}`,
						titleValue: true,
						value: `$${debt.originalBalance}`
					};
				} else {
					return {
						title: `${label}  ${monthlyPayment}`,
						titleValue: true,
						value: `$${debt.monthlyPayment}`
					};
				}
			});
		}
		fields = fields.length ? fields : [{ title: 'SUMMARY.noDebts', value: '  ' }];
		return fields;
	}

	/**
	 *
	 *
	 * @private
	 * @param {IMetadata[]} incomeSourceType
	 * @param {IMetadata[]} paymentFrequency
	 * @param {*} incomes
	 * @return {*} FTRSectionFields[]}
	 * @memberof FastTrackComponent
	 */
	private getApplicantIncome(
		incomeSourceType: IMetadata[],
		paymentFrequency: IMetadata[],
		incomes: any
	): IFTRSectionFields[] {
		let type: string;
		const paid = this.translocoService.translate('SUMMARY.paid');
		return incomes.map((income) => {
			if (this.JOB === income.incomeSourceType && income.company) {
				type = income.company;
			} else {
				type = incomeSourceType?.find((item) => item.key === income.incomeSourceType)?.text;
			}
			const frequency = paymentFrequency?.find((item) => item.key === income.paymentFrequency)?.text;
			return {
				title: `${type} `,
				titleValue: true,
				value: `$${income.amount} ${paid} ${frequency}`
			};
		});
	}

	/**
	 *
	 * Log tealium events for Edit of any section
	 * @param {string} routeToPath
	 * @param {string} queryParams
	 * @memberof FastTrackComponent
	 */
	routeToPage(routeToPath: string, queryParams: string, isEdit: Boolean) {
		this.tagDataService.link(
			{},
			{
				tealium_event: 'Fast-Track-Edit' + routeToPath,
				event_action: 'Click',
				event_label: isEdit ? 'Edit' : 'View',
				event_category: 'FAST-TRACK-APPLICATION',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			}
		);
		this.router.navigate([routeToPath], { queryParams: { returnToFTR: queryParams } });
	}

	/**
	 *
	 * Set the queryParam value in session. This value is read in init so paint the page
	 */
	checkForQueryParam(): void {
		let returnToFTR = this.route.snapshot.queryParams?.returnToFTR;
		if (returnToFTR) {
			this.sessionStorageService.set('fastTrackStep', returnToFTR);
		}
	}
}
