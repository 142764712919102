import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors
} from '@angular/forms';
import { timer } from 'rxjs';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-adjust-loan-details-list',
	templateUrl: './loan-details-list.component.html',
	styleUrls: ['./loan-details-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: LoanDetailsListComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: LoanDetailsListComponent
		}
	]
})
export class LoanDetailsListComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	private _list: any[];
	@Input()
	get list(): any[] {
		return this._list;
	}
	set list(list: any[]) {
		const oldList = this._list;
		this._list = list;
		timer(1).subscribe({
			next: (val) => {
				if (!oldList) {
					this.onChange(this.value);
				}
			}
		});
	}

	@Input()
	showMoreOptions: boolean;

	@Output()
	chooseTerms = new EventEmitter<any>();

	formGroup: FormGroup;

	constructor(private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({
			loanDetails: [1]
		});

		this.formGroup.valueChanges.subscribe({
			next: (val) => {
				this.onChange(this.value);
				this.onTouched();
			}
		});
	}

	get value(): any | null {
		if (this.formGroup.get('loanDetails')) {
			const found = this.list?.find((item) => item.index === this.formGroup.get('loanDetails').value);
			return found || null;
		}
		return null;
	}
	set value(loanSelection: any | null) {
		if (loanSelection) {
			this.formGroup.get('loanDetails').setValue(loanSelection.index);
			this.onChange(loanSelection);
		}
	}

	onChange = (change) => {
		// This is intentional empty
	};
	onTouched = () => {
		// This is intentional empty
	};
	touched = false;
	disabled = false;

	ngOnInit(): void {
		// This is intentional empty
	}

	// Begin ControlValueAccessor interface
	writeValue(value: any): void {
		this.value = value;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	// End ControlValueAccessor interface

	// Begin Validator interface
	validate(control: AbstractControl): ValidationErrors | null {
		return null;
	}
	// End Validator interface

	onChooseTerms(): void {
		this.chooseTerms.emit();
	}
}
