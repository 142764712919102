<div class="accessibility" *transloco="let t">
	<op-back-link class="op-mt-20" id="accessibilityBck">{{ t('ACCESSIBILITY.back') }}</op-back-link>
	<op-masthead [title]="t('ACCESSIBILITY.title')"></op-masthead>
	<op-content-container class="content">
		<p>{{ t('ACCESSIBILITY.mission') }}</p>
		<p>
			{{ t('ACCESSIBILITY.havingIssues1') }} <a href="mailto:info@oportun.com" id="mailToInfo">info@oportun.com</a>.
			{{ t('ACCESSIBILITY.havingIssues2') }}
		</p>
	</op-content-container>
</div>
