import { Component, Input } from '@angular/core';

/**
 * Page title styled.
 *
 * @export
 * @class MastheadComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-masthead',
	templateUrl: './masthead.component.html',
	styleUrls: ['./masthead.component.scss']
})
export class MastheadComponent {
	@Input() title!: string;
	@Input() subTitle!: string;
	@Input() customClass!: string;
}
