<div class="increment-decrement">
	<div class="op-mt-20 op-mb-12 op-body-1-bold-font" id="incrementDecrementTitle">
		{{ title }}
	</div>

	<div [formGroup]="formGroup" class="increment-decrement-panel">
		<button
			mat-flat-button
			type="button"
			id="decrementBtn"
			class="value-button border-radius-minus"
			[disabled]="disableDecreaseAmountBtn"
			(click)="decreaseValue()"
		>
			<mat-icon class="decrease"></mat-icon>
		</button>
		<input
			matInput
			class="loan-amount-input"
			id="incrementDecrementNumber"
			type="text"
			id="incDecAmount"
			aria-labelledby="incrementDecrementTitle"
			readonly
			tabindex="-1"
			formControlName="amount"
		/>
		<button
			mat-flat-button
			type="button"
			id="incrementBtn"
			class="value-button border-radius-plus"
			[disabled]="disableIncreaseAmountBtn"
			(click)="increaseValue()"
		>
			<mat-icon class="increase"></mat-icon>
		</button>
	</div>
</div>
