import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MobileApiBusyNotifierService } from 'src/app/core/services/mobile-api-busy-notifier/mobile-api-busy-notifier.service';

export const SKIP_BUSY_INDICATOR = new HttpContextToken<boolean>(() => false);

/**
 * Shows a busy indicator when there is an outstanding ajax request.
 *
 * @export
 * @class MobileApiBusyInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class MobileApiBusyInterceptor implements HttpInterceptor {
	constructor(private busyService: MobileApiBusyNotifierService) {}

	private requestCounter = 0;

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (request.context.get(SKIP_BUSY_INDICATOR)) {
			return next.handle(request);
		} else {
			this.beginRequest();
			return next.handle(request).pipe(
				finalize(() => {
					this.endRequest();
				})
			);
		}
	}

	beginRequest() {
		this.requestCounter = Math.max(this.requestCounter, 0) + 1;
		if (this.requestCounter === 1) {
			this.busyService.beginRequest();
		}
	}

	endRequest() {
		this.requestCounter = Math.max(this.requestCounter, 1) - 1;

		if (this.requestCounter === 0) {
			this.busyService.endRequest();
		}
	}
}
