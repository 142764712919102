import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription, of } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import IProduct, {
	ProductCategoriesEnum,
	ProductStatusesEnum,
	SecuredOfferResponseEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { IMessageDialogData } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { preferNotAnswerVehicleStatus } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { VehicleApiService } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.service';
import {
	AddressTypeEnum,
	ConfigApiService,
	IDisbursementOptions,
	IResult,
	IVehicle
} from 'src/app/core/services/mobile-api';
import { VehicleUtils } from 'src/app/core/utils/vehicle-utils';
import {
	ApplicationStatusEnum,
	PreApprovedTermsEntity
} from 'src/app/core/services/loan-application/loan-application.model';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { DisbursementChannelEnum } from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';

@Component({
	selector: 'op-vehicle-eligibility-question',
	templateUrl: './vehicle-eligibility-question.component.html',
	styleUrls: ['./vehicle-eligibility-question.component.scss']
})
export class VehicleEligibilityQuestionComponent implements OnInit, OnDestroy {
	language: string;
	whatIsSecuredPersonalLoanLink: SafeUrl;

	private subscription = new Subscription();

	customerStateName: string;
	customerState: string;
	numOfDaysToRenewRegistration: number;
	vehicleUtils = new VehicleUtils();

	productOfferDetailUtilities: ProductOfferDetailsUtils;
	productOfferDetails: IProduct[] | PreApprovedTermsEntity[];
	disbursementInfo: DisbursementUtils;
	fundingInfo: FundingInfoUtils;

	private shouldOverrideDisbursement: boolean = false;

	constructor(
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private vehicleApiService: VehicleApiService,
		private configApiService: ConfigApiService
	) {}

	ngOnInit(): void {
		const langSub = this.translocoService.langChanges$.subscribe({
			next: (language) => {
				this.language = language;
				this.createTealiumEvent('page_load', 'page load', 'view');
			}
		});
		this.subscription.add(langSub);

		this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(this.loanAppService.getLoanApp());
		this.productOfferDetails = this.productOfferDetailUtilities?.productOfferDetails;

		this.disbursementInfo = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());

		this.mobileService.getAddresses(this.loanAppService.loanApplicationId).subscribe({
			next: (addresses) => {
				this.customerState = this.vehicleUtils.getCustomerState(addresses, AddressTypeEnum.home);
				this.customerStateName = this.vehicleUtils.getCustomerStateName(this.customerState);
				this.numOfDaysToRenewRegistration = this.vehicleUtils.getNumOfDaysToRenewRegistration(this.customerState);
			}
		});

		if (
			this.loanAppService.isPartnerApplication() &&
			!this.fundingInfo.hasDisbursementOption(
				this.disbursementInfo.originationDisbursementChannel,
				this.disbursementInfo.originationDisbursementType
			)
		) {
			this.configApiService
				.configOverrideDisbursement(this.loanAppService.getLoanApp().partnerId, this.customerState)
				.subscribe((configResult) => {
					this.shouldOverrideDisbursement = configResult.value === false;
				});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	exploreLater(): void {
		this.createTealiumEvent('go_back_to_offers_status_page', 'click', 'link');
		let vehicle: IVehicle = { isFinalVehicle: true, vehicleOwnershipStatus: preferNotAnswerVehicleStatus };

		this.vehicleApiService.setVehicle(vehicle, this.loanAppService.loanApplicationId).subscribe({
			next: (rsp) => this.routingService.route(RoutingPathsEnum.offerStatus),
			error: (err) => this.routingService.route(RoutingPathsEnum.offerStatus)
		});
	}

	confirmToMoveToOfferStatus(): void {
		const data: IMessageDialogData = {
			title: this.translocoService.translate('VEHICLE_ELIGIBILITY_QUESTION.exploreLaterTitle'),
			message: this.translocoService.translate('VEHICLE_ELIGIBILITY_QUESTION.exploreLaterMessage'),
			confirmText: this.translocoService.translate('VEHICLE_ELIGIBILITY_QUESTION.btn_own_vehicle'),
			cancelText: this.translocoService.translate('VEHICLE_ELIGIBILITY_QUESTION.btn_explore_later'),
			displayBtnAsFlex: true
		};
		this.dialogService
			.openMessageDialog(
				data,
				() => of(this.forwardToVehiclePage()),
				(val: any) => {
					return val === undefined ? of() : of(this.exploreLater());
				}
			)
			.subscribe();
	}

	forwardToVehiclePage(): void {
		this.createTealiumEvent('check_vehicle_eligibility_question_', 'click', 'link');

		const productCategory: ProductCategoriesEnum = ProductCategoriesEnum.securedPersonalLoan;
		this.sessionStorageService.set('productCategorySelection', productCategory);

		const applicationStatus = this.loanAppService.applicationStatus;
		if (ApplicationStatusEnum.securedAccepted === applicationStatus) {
			this.routingService.route(RoutingPathsEnum.vehicle);
		} else if (ApplicationStatusEnum.securedOffered === applicationStatus) {
			this.mobileService
				.setSecuredAppOffer(
					{ securedAppOfferAction: SecuredOfferResponseEnum.accept },
					this.loanAppService.loanApplicationId
				)
				.subscribe({
					next: () => {
						this.routingService.route(RoutingPathsEnum.vehicle);
					}
				});
		} else {
			const requestParameters = { productCategory, vehicleEligibilityCheck: 'Yes' };

			this.mobileService.setProductSelection(requestParameters, this.loanAppService.loanApplicationId).subscribe({
				next: () => {
					this.updateDisBursementChannelAndNavigate(productCategory, RoutingPathsEnum.vehicle);
				}
			});
		}
	}

	private updateDisBursementChannelAndNavigate(
		productCategory: ProductCategoriesEnum,
		nextPage: RoutingPathsEnum
	): void {
		this.createTealiumEvent('I_own_vehicle_without_payments', 'click', 'link');
		if (this.productOfferDetailUtilities.isAnyProductInStatus(ProductStatusesEnum.approved)) {
			this.updateDisbursementChannel(productCategory).subscribe({
				next: () => {
					this.routingService.route(nextPage);
				}
			});
		} else {
			this.routingService.route(nextPage);
		}
	}

	private updateDisbursementChannel(productCategory: ProductCategoriesEnum): Observable<IResult> {
		const disbursementParameters: IDisbursementOptions = {
			disbursementChannel: this.disbursementInfo.getOriginationDisbursementChannel(),
			disbursementType: this.disbursementInfo.originationDisbursementType
		};
		if (ProductCategoriesEnum.securedPersonalLoan === productCategory) {
			disbursementParameters.disbursementChannel = DisbursementChannelEnum.store;
		} else if (this.shouldOverrideDisbursement) {
			disbursementParameters.disbursementChannel = DisbursementChannelEnum.store;
			disbursementParameters.disbursementType = this.fundingInfo.getPreferredDisbursementType(
				DisbursementChannelEnum.store,
				this.disbursementInfo.originationDisbursementType
			);
		}
		return this.mobileService.updateDisbursementChannel(disbursementParameters, this.loanAppService.loanApplicationId);
	}

	createTealiumEvent(event: string, eventAction: string, eventType: string): any {
		return this.tagDataService[eventType](
			{
				application_type: 'CONSUMER_INSTALLMENT_LOAN',
				loan_application_id: this.loanAppService.loanApplicationId,
				loan_application_status: this.loanAppService.applicationStatus,
				page_location: window.location.href,
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			},
			{
				event_action: eventAction,
				event_category: 'CONSUMER_INSTALLMENT_LOAN',
				event_label: event,
				tealium_event: 'check_vehicle_eligibility_question_' + event
			}
		);
	}
}
