import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-almost-done',
	templateUrl: './almost-done.component.html',
	styleUrls: ['./almost-done.component.scss']
})
export class AlmostDoneComponent implements OnInit {
	constructor(private dialogService: DialogService, private tagDataService: TagDataService) {}

	ngOnInit(): void {
		this.tagDataService.view({
			opEventId: 'view',
			tealium_event: 'almost-done_status'
		});
	}

	findLocation(): void {
		this.dialogService.openFindLocationDialog().subscribe();
	}
}
