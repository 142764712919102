<div
	class="inline-expansion-panel"
	id="{{ id + '_expansionPanel' }}"
	[ngClass]="{ expanded: expanded, afterCollapse: afterCollapse }"
>
	<div
		id="{{ id + '_expansionPanel_content' }}"
		class="inline-expansion-panel-content"
		[@openClose]="expanded ? 'open' : 'closed'"
		(@openClose.done)="openCloseDone($event)"
	>
		<button mat-icon-button (click)="toggle()" id="{{ id + '_expansionPanel_toggleButton' }}">
			<mat-icon></mat-icon>
		</button>
		<ng-content></ng-content>
	</div>
</div>
