<div class="op-slide-toggle" *transloco="let t">
	<form [formGroup]="formGroup">
		<label class="switch">
			<input type="checkbox" id="{{ id + '_op-toggle-button' }}" formControlName="toggle" />
			<div class="slider round">
				<span class="on">{{ t('GLOBAL.on') | uppercase }}</span>
				<span class="off">{{ t('GLOBAL.off') | uppercase }}</span>
			</div>
		</label>
	</form>
</div>
