import { Component, OnDestroy, OnInit } from '@angular/core';
import { includes } from 'lodash';
import { from, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicantStepCompleteEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { IConfigResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { VWOService } from 'src/app/vendor/wingify/vwo.service';

import { IAddressResult } from '../../core/services/mobile-api/mobile-api.model';

enum IncomeThreshold {
	GCP_INCOME_THRESHOLD = 3000,
	NEW_INCOME_THRESHOLD = 1300
}

@Component({
	selector: 'op-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {
	public loanStatusEnum: typeof ApplicationStatusEnum = ApplicationStatusEnum;
	productOfferDetailUtilities: ProductOfferDetailsUtils;

	constructor(
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private softPullService: SoftPullService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private vwoService: VWOService
	) {}

	// TODO: Group into one object to send.
	loanApp: ILoanApplication;
	applicationStatus: ApplicationStatusEnum;
	softPull: IConfigResult;
	isSoftPullEnabled: boolean;
	isPreScreened: boolean;
	homeAddress: IAddressResult;
	vwoWidget: boolean = false;
	showSection = {};

	private subscription = new Subscription();

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$.pipe(filter(Boolean)).subscribe({
			next: (softPull: IConfigResult) => {
				this.softPull = softPull;
			}
		});
		this.subscription.add(softPullSub);

		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (resp: ILoanApplication) => {
				this.loanApp = resp;
				this.applicationStatus = this.loanApp.applicationStatus;

				// if there exists 2 loans in valid status then it reddirects to offer status page
				this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(this.loanApp);
				if (
					this.productOfferDetailUtilities.isAllLoansHaveValidStatus() ||
					this.applicationStatus === ApplicationStatusEnum.approved
				) {
					this.routingService.route(RoutingPathsEnum.offerStatus);
				}

				this.showSection = this.loanAppService.getEditableSectionFlags();

				if (this.applicationStatus === ApplicationStatusEnum.preApproved) {
					this.mobileService.getAddresses(this.loanAppService.loanApplicationId).subscribe({
						next: (addresses) => {
							this.homeAddress = AddressUtils.getHomeAddress(addresses);
						}
					});
				}

				this.tagDataService.view({
					tealium_event: this.applicationStatus === 'SECURED_OFFERED' ? 'secured_loan_offered' : 'finalize',
					loan_application_id: this.loanAppService.loanApplicationId,
					loan_application_status: this.applicationStatus,
					page_location: '/' + this.routingService.getCurrentRoute()
				});
			}
		});
		this.subscription.add(loanAppSub);
		this.vwoActivation();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	vwoActivation(): void {
		const loanApplicationId = this.loanAppService?.loanApplicationId?.toString();
		// userId: how you identify a particular user
		// options is a map to define customVariables and variationTargetingVariables
		if (this.loanApp?.originationSource === 'ONLINE') {
			from(this.vwoService.activate('OR_FE_Status', loanApplicationId)).subscribe({
				next: (variationName: string) => {
					if (variationName === 'Control') {
						this.vwoWidget = false;
					} else if (variationName === 'Status_Notify') {
						this.vwoWidget = true;
						this.vwoService.track(['OR_FE_Status'], loanApplicationId, 'OR_FE_Status_Goal', {});
					}
				}
			});
		}
	}

	isOtherStatus(): boolean {
		const handledStatus = [
			ApplicationStatusEnum.approved,
			ApplicationStatusEnum.bounced,
			ApplicationStatusEnum.preApproved,
			ApplicationStatusEnum.coAppOffered,
			ApplicationStatusEnum.coAppDeclined,
			ApplicationStatusEnum.notApproved,
			ApplicationStatusEnum.submitted,
			ApplicationStatusEnum.submitInProgress,
			ApplicationStatusEnum.referenceCheck,
			ApplicationStatusEnum.referencesVerified,
			ApplicationStatusEnum.nonProcessable,
			ApplicationStatusEnum.verified,
			ApplicationStatusEnum.secondaryReview
		];
		return this.loanApp ? !includes(handledStatus, this.loanApp.applicationStatus) : false;
	}

	onSubmit(post: any): void {
		if (post.next === 'adjustterms') {
			this.routingService.route(RoutingPathsEnum.adjustTerms);
		} else if (post.next === 'references') {
			this.routingService.route(RoutingPathsEnum.references);
		} else {
			const isDisbursementComplete = this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.disbursementInfo);
			if (this.loanAppService.isIncomeVerificationEligible()) {
				this.routingService.route(RoutingPathsEnum.autoVerifyIncome);
			} else if (!isDisbursementComplete) {
				this.routingService.route(RoutingPathsEnum.receiveFunds);
			} else {
				this.routingService.route(RoutingPathsEnum.documentSubmit);
			}
		}
	}

	getAriaPreApprovedDescribedBy(): string {
		let des = 'pre-approved-message ';
		if (this.isSoftPullEnabled) {
			des += 'verify-info final-review';
		} else {
			des += 'almost-done';
		}
		return des;
	}
}
