import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SamplesComponent } from './samples/samples.component';
import { SamplesGuard } from './samples/samples.guard';

const routes: Routes = [{ path: 'samples', component: SamplesComponent, canActivate: [SamplesGuard] }];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// enableTracing: true,
			relativeLinkResolution: 'legacy',
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			scrollOffset: [0, 25] // cool option, or ideal option when you have a fixed header on top.
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
