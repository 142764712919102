import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import player from 'lottie-web';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LottieModule } from 'ngx-lottie';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApprovalModule } from './approval/approval.module';
import { CoreModule } from './core/core.module';
import { EnvironmentService } from './core/services/environment/environment.service';
import { GlobalErrorHandler } from './core/services/global-errorhandler/global-errorhandler';
import { GoogleAnalyticsService } from './core/services/google-analytics/google-analytics.service';
import { NewRelicService } from './core/services/new-relic/new-relic.service';
import { DisbursedModule } from './disbursed/disbursed.module';
import { EsignModule } from './esign/esign.module';
import { LandingModule } from './landing/landing.module';
import { WildCardModule } from './landing/wild-card/wild-card.module';
import { PreApprovalModule } from './pre-approval/pre-approval.module';
import { PreQualifyModule } from './pre-qualify/pre-qualify.module';
import { SamplesComponent } from './samples/samples.component';
import { SharedModule } from './shared/shared.module';
import { StatusModule } from './status/status.module';
import { TermsModule } from './terms/terms.module';
import { AppMaterialModule } from './vendor/material-design/material-design.module';
import { rollbarFactory, RollbarService } from './vendor/rollbar/rollbar.service';
import { TealiumModule } from './vendor/tealium/tealium.module';
import { TranslocoRootModule } from './vendor/transloco/transloco-root.module';
import { VWOModule } from './vendor/wingify/vwo.module';

const maskConfig: Partial<IConfig> = {
	validation: false
};

export function playerFactory() {
	return player;
}

@NgModule({
	declarations: [AppComponent, SamplesComponent],

	imports: [
		// Note: translation module should be first
		TranslocoRootModule,
		AppMaterialModule,
		AppRoutingModule,
		ApprovalModule,
		BrowserAnimationsModule,
		BrowserModule,
		CoreModule,
		DateFnsModule.forRoot(),
		DisbursedModule,
		DisbursedModule,
		EsignModule,
		FormsModule,
		HttpClientModule,
		LandingModule,
		LottieModule.forRoot({ player: playerFactory }),
		NgxMaskModule.forRoot(maskConfig),
		NgxExtendedPdfViewerModule,
		NgxSpinnerModule.forRoot({ type: 'op-spin-type' }),
		PreApprovalModule,
		PreQualifyModule,
		ReactiveFormsModule,
		SharedModule,
		StatusModule,
		TealiumModule,
		TermsModule,
		// Note: Wildcard Module is added to handle the wild card routes which doesn't match any of the existing routes.
		// This module should be the last module to be imported always.
		WildCardModule,
		VWOModule
	],

	providers: [
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{ provide: RollbarService, useFactory: rollbarFactory },
		{
			provide: MAT_RADIO_DEFAULT_OPTIONS,
			useValue: { color: 'primary' }
		},
		{
			provide: APP_INITIALIZER,
			useFactory:
				(environmentService: EnvironmentService, newRelicService: NewRelicService, gaService: GoogleAnalyticsService) =>
				() => {
					environmentService.init();
					newRelicService.init();
					gaService.init();
				},
			deps: [EnvironmentService, NewRelicService, GoogleAnalyticsService],
			multi: true
		}
	],
	bootstrap: [AppComponent],
	exports: []
})
export class AppModule {
	constructor(iconRegistry: MatIconRegistry) {
		iconRegistry.setDefaultFontSetClass('material-icons-outlined');
	}
}
