import { IAddressResult, IFinancesResult } from '../../mobile-api';

export enum PlaidAssetRefreshEnum {
	success = 'SUCCESS',
	partialSuccess = 'PARTIAL_SUCCESS',
	failed = 'FAILED'
}

export interface IApplicant {
	id: number;
	loanApplicationId: number;
	applicantIndex: number;
	clientId: string;
	firstName: string;
	middleName: string;
	lastName: string;
	leadId: number;
	maternalName: string;
	suffix: string;
	title: string;
	dateOfBirth: string;
	itin: string;
	ssn: string;
	last4Ssn: string;
	last4Itin: string;
	inUSSince: string;
	emailAddress: string;
	editableSections: any;
	preferredLanguage: string;
	smsAuthorization: boolean;
	applicantDocumentsId: string;
	previousLoanCount: number;
	referencesRequired: number;
	skipReferences: string;
	creditRunSuccess: boolean;
	bureauInquiryType: string;
	quovoAccountsAdded: boolean;
	quovoIncomeEligible: boolean;
	quovoBankAccountEligible: boolean;
	preScreened: boolean;
	statedMonthlyIncomeAmount: string;
	verifiedMonthlyIncomeAmount: string;
	incomeSourceOptionsOffered: boolean;
	incomeSourceSelected: string;
	verifiedMonthlyIncomeDate: string;
	initialTakeHomePay: string;
	currentAddress: IAddress;
	previousAddress: IAddress;
	phones: Array<IPhone>;
	debts: Array<any>;
	incomes: Array<any>;
	references: Array<any>;
	requirements: IRequirements;
	applicantStatus: string;
	nameProcessComplete: boolean;
	identificationProcessComplete: boolean;
	phoneProcessComplete: boolean;
	addressProcessComplete: boolean;
	creditAuthProcessComplete: boolean;
	financeProcessComplete: boolean;
	incomeProcessComplete: boolean;
	dataCompleteProcessComplete: boolean;
	referenceProcessComplete: boolean;
	disbursementInfoProcessComplete: boolean;
	bounceReasons: Array<any>;
	declineCodes: Array<any>;
	nonProcessableCodes: Array<any>;
	referralCode: string;
	referralCodeConsent: string;
	referralCodeSource: string;
	bankAccountVerificationService: string; //'PLAID' || 'WORKNUMBER'
	bankAccountVerificationEligible: boolean;
	incomeVerificationEligible: boolean;
	bankAccountVerificationAdded: boolean; // set if at least one Bank Account found during PLAID income verification
	homePostalCode: string;
	identificationDocument: IIdentificationDocument;
	plaidIncentiveApplied?: boolean;
	isSecuredPauseEligible: boolean;
	verifiedMonthlyIncomeSource: string;
	isMFARequired?: boolean;
	homeAddress?: IAddressResult;
	mailingAddress?: IAddressResult;
	finances?: IFinancesResult;
	vehicleOwnershipStatus?: string;
	bankTransactionModelEligible?: boolean;
	bankAccountConnectionSource?: string; // set to 'PLAID' only if bank account added
	plaidOneClickConsent?: boolean;
	plaidOneClickEligible?: boolean;
	plaidAssetRefreshStatus?: PlaidAssetRefreshEnum; // if previous Plaid asset are still valid and refreshed from plaid.
}

export interface IIdentificationDocument {
	type: string;
	errors: Array<IError>;
	issuingCountry: string;
	last4Number: string;
	number: string;
	ssn: string;
	itin: string;
	issuingState: string;
}

export interface IRequirements {
	identificationRequired: boolean;
	residenceRequired: boolean;
	incomeRequired: boolean;
	applicantPhotoRequired: boolean;
	applicantPhotoUploaded: boolean;
	bankAccountRequired: boolean;
	creditAuthRequired: boolean;
	payoffRequired: boolean;
	vehicleRegistrationRequired: boolean;
	vehiclePhotosRequired: boolean;
	vehicleDriversLicenseRequired: boolean;
	vehicleDriversLicenseCompleted: boolean;
}

export interface IAddress {
	id: number;
	type: string;
	streetAddress1: string;
	streetAddress2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	moveInDate: string;
	outsideRadius: string;
	currentAddress: string;
	latitude: string;
	longitude: string;
	note: string;
	cassVerified: boolean;
	cassGeoMatchFlag: string;
	cassGeoTract: string;
	cassGeoBlock: string;
	cassGeoToLatitude: string;
	cassGeoToLongitude: string;
	documentImageId: string;
	documentCollectedOn: string;
	verificationVendor: string;
	verificationVendorId: string;
}

export interface IPhone {
	id: number;
	isActive: boolean;
	phoneNumber: string;
	phoneType: string;
	ownerDateRange: number;
	lastSmsValidated: boolean;
	lastVoiceValidated: boolean;
}

export interface IError {
	type: string;
	path: string;
	field: string;
	code: string;
	messageCode: string;
}

export enum ApplicantStepCompleteEnum {
	'name' = 'name',
	'address' = 'address',
	'phone' = 'phone',
	'identification' = 'identification',
	'creditAuth' = 'creditAuth',
	'finance' = 'finance',
	'income' = 'income',
	'preApproval' = 'preApproval',
	'reference' = 'reference',
	'disbursementInfo' = 'disbursementInfo',
	'notification' = 'notification'
}

export enum VerificationStatusEnum {
	autoVerified = 'AUTO_VERIFIED',
	verified = 'VERIFIED',
	stated = 'STATED'
}
