<div class="loan-details">
	<div
		class="radio-group-card op-mt-20"
		[ngClass]="{ selected: item.index === selectedLoanDetailId }"
		aria-labelledby="radioGroupCardTitle"
	>
		<div class="op-center rg-card-column">
			<div>
				<div class="rg-card-header1 op-display-3-font">
					{{ item.paymentAmount | currency: 'USD':'symbol':'0.2' }}
				</div>
				<span class="rg-card-subheader1 op-center op-micro-font">{{ 'ADJUST_TERMS.paymentAmt' | transloco }}</span>
			</div>
		</div>
		<div class="op-center rg-card-column">
			<div class="rg-card-subheader1 op-center op-label-font">{{ 'ADJUST_TERMS.months' | transloco }}</div>
			<div class="rg-card-header1 op-display-3-font">{{ item.paymentTerm }}</div>
			<div class="rg-card-subheader1 op-center op-micro-font no-wrapping">
				{{ item.numberOfPayments }} {{ 'ADJUST_TERMS.totalPayments' | transloco }}
			</div>
		</div>
		<div class="op-center rg-card-column">
			<div class="rg-card-subheader1 op-label-font">{{ 'ADJUST_TERMS.apr' | transloco }}</div>
			<div class="op-micro-bold-font">{{ item.realApr | percent: '2.2-2' }}</div>
		</div>
	</div>
</div>
