import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAchBankAccount } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-plaid-account-list',
	templateUrl: './plaid-account-list.component.html',
	styleUrls: ['./plaid-account-list.component.scss']
})
export class PlaidAccountListComponent {
	@Input()
	@opRequired()
	id: string;

	private _accountList: IAchBankAccount[];
	@Input()
	get accountList(): IAchBankAccount[] {
		return this._accountList;
	}
	set accountList(list: IAchBankAccount[]) {
		this._accountList = list;
	}

	@Output() next = new EventEmitter();
	@Output() addBank = new EventEmitter();

	onNext(): void {
		this.next.emit();
	}

	onAddBank(): void {
		this.addBank.emit();
	}
}
