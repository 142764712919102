<div class="pending-display" *transloco="let t">
	<ng-content select="[uploadPendingTitle]"></ng-content>
	<div
		class="single-file-container"
		opDragDrop
		id="{{ id + '_drop_zone' }}"
		(fileDropped)="fileDropped.emit($event)"
		[opTagClick]="id + '_upload'"
	>
		<div></div>
		<div>
			<div>
				<img
					*ngIf="singleFile && singleFile?.thumbnail"
					class="thumbnail-img"
					id="{{ id + '_singleFile' + '_image' }}"
					alt=""
					[src]="singleFile?.thumbnail"
				/>
				<img
					*ngIf="singleFile && !singleFile?.thumbnail"
					id="{{ id + '_singleFile' + '_default_image' }}"
					class="default-img"
					alt=""
					src="/assets/images/icons/op-document.svg"
				/>
			</div>

			<div class="file-name op-micro-font op-light-black">
				{{ singleFile?.name }}
			</div>
			<ng-content
				*ngIf="
					singleFile?.status !== fileUploadStatusEnum.pending && singleFile?.status !== fileUploadStatusEnum.finished
				"
				select="[uploadPendingInfo]"
			></ng-content>
			<div class="op-mt-8" *ngIf="showReUploadButton">
				<button
					mat-flat-button
					color="primary-small"
					id="{{ id + '_reupload_button' }}"
					[disabled]="disabled"
					(click)="opReUploadClick.emit($event)"
					[opTagClick]="id + '_reUpload_button'"
				>
					<ng-content select="[reUploadPendingButtonText]"></ng-content>
				</button>
			</div>
		</div>
		<div>
			<div
				*ngIf="singleFile?.status === fileUploadStatusEnum.pending"
				tabindex="0"
				class="delete-icon"
				(click)="opDeleteClick.emit(singleFile.id)"
				[opTagClick]="id + '_delete_button'"
			></div>
		</div>
	</div>
</div>
