import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlContainer,
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	FormGroupDirective,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validators
} from '@angular/forms';
import { compose, sortBy, uniqBy } from 'lodash/fp';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IZipCodeLookUpResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';

import { opRequired } from '../../decorators/required.decorator';
import { IAutoCompleteAddress, ILatLngLimiter } from '../../directives/address-auto-complete.directive';
import { homeStreetAddress1Validator, zipCodeValidator } from '../../validators/form-validators';

export interface IAddressDetailValue {
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipCode: string;
}

@Component({
	selector: 'op-address-detail-form',
	templateUrl: './address-detail.component.html',
	styleUrls: ['./address-detail.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AddressDetailComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => AddressDetailComponent),
			multi: true
		}
	]
})
export class AddressDetailComponent implements ControlValueAccessor, OnInit, OnDestroy {
	formGroup: FormGroup;
	stateList$: Observable<IMetadata[]>;
	private subscription = new Subscription();
	latLngLimiter: ILatLngLimiter;

	@Input()
	@opRequired()
	id: string;

	private _setValidator: boolean;

	@Input()
	get setValidator(): boolean {
		return this._setValidator;
	}
	set setValidator(value: boolean) {
		this._setValidator = value;
		Boolean(value) ? this.clearValidator() : this.setRequiredValidator();
	}

	private _homeAddress: boolean;
	@Input()
	get homeAddress(): boolean {
		return this._homeAddress;
	}
	set homeAddress(value: boolean) {
		this._homeAddress = value;
		this.setHomeAddressValidator();
	}

	get value(): IAddressDetailValue {
		return this.formGroup.value;
	}
	set value(value: IAddressDetailValue) {
		this.formGroup.patchValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(
		private formBuilder: FormBuilder,
		private metadataService: MetadataService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {
		this.createForm(this.formBuilder);
		// any time the inner form changes update the parent of any change
		const formValueChangeSub = this.formGroup.valueChanges.subscribe((value) => {
			this.onChange(value);
			this.onTouched();
		});
		this.subscription.add(formValueChangeSub);

		this.onZipCodeChange();
	}

	ngOnInit(): void {
		this.stateList$ = this.metadataService
			.select(MetadataEnum.USState)
			.pipe(map((val) => compose(sortBy('text'), uniqBy('text'))(val as any) as any[]));
	}

	createForm(fb: FormBuilder): FormGroup {
		return (this.formGroup = fb.group({
			address1: ['', [Validators.required, Validators.maxLength(30)]],
			address2: [],
			city: [null, Validators.required],
			state: [null, Validators.required],
			zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10), zipCodeValidator()]]
		}));
	}

	private clearValidator(): void {
		const addressValidator = [Validators.maxLength(30)];
		if (this.homeAddress) {
			addressValidator.push(homeStreetAddress1Validator());
		}

		this.formGroup.get('address1').setValidators(addressValidator);
		this.formGroup
			.get('zipCode')
			.setValidators([Validators.minLength(5), Validators.maxLength(10), zipCodeValidator()]);
		this.formGroup.get('city').clearValidators();
		this.formGroup.get('state').clearValidators();

		this.formGroup.get('address1').updateValueAndValidity();
		this.formGroup.get('zipCode').updateValueAndValidity();
		this.formGroup.get('city').updateValueAndValidity();
		this.formGroup.get('state').updateValueAndValidity();
	}

	private setRequiredValidator(): void {
		const addressValidator = [Validators.required, Validators.maxLength(30)];
		if (this.homeAddress) {
			addressValidator.push(homeStreetAddress1Validator());
		}

		this.formGroup.get('address1').setValidators(addressValidator);
		this.formGroup
			.get('zipCode')
			.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(10), zipCodeValidator()]);
		this.formGroup.get('city').setValidators(Validators.required);
		this.formGroup.get('state').setValidators(Validators.required);

		this.formGroup.get('address1').updateValueAndValidity();
		this.formGroup.get('zipCode').updateValueAndValidity();
		this.formGroup.get('city').updateValueAndValidity();
		this.formGroup.get('state').updateValueAndValidity();
	}

	private setHomeAddressValidator(): void {
		const addressValidator = [Validators.maxLength(30)];
		if (this.homeAddress) {
			addressValidator.push(homeStreetAddress1Validator());
		}
		if (!this.setValidator) {
			addressValidator.push(Validators.required);
		}

		this.formGroup.get('address1').setValidators(addressValidator);
		this.formGroup.get('address1').updateValueAndValidity();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onChange: any = () => {
		// This is intentional empty
	};

	onZipCodeChange: any = () => {
		// update home zip lookup if it changes
		const zipSub = this.formGroup
			.get('zipCode')
			.valueChanges.pipe(
				skip(1),
				filter((zip) => zip?.length === 5),
				distinctUntilChanged(),
				switchMap((zip) => this.updateFromZipLookUp(zip))
			)
			.subscribe();
		this.subscription.add(zipSub);
	};

	onTouched: any = () => {
		// This is intentional empty
	};

	/**
	 * Partially update the form from a zipCode look up.
	 *
	 * @returns {Observable<IZipCodeLookUpResult>}
	 * @memberof AddressComponent
	 */
	updateFromZipLookUp(zipCode: string = null): Observable<IZipCodeLookUpResult> {
		const zip = zipCode || this.loanAppService.getCurrentApplicant()?.homePostalCode;
		return this.mobileService.zipCodeLookUp(zip).pipe(
			tap((resp) => {
				this.formGroup.get('zipCode').setValue(zip);
				this.formGroup.get('city').setValue(resp?.populateAddress?.city[0]);
				this.formGroup.get('state').setValue(resp?.populateAddress?.state[0]);
				if (resp.populateAddress) {
					this.latLngLimiter = {
						lat: parseFloat(resp.populateAddress.latitude[0]),
						lng: parseFloat(resp.populateAddress.longitude[0])
					};
				}
			})
		);
	}

	registerOnChange(fn): void {
		this.onChange = fn;
	}

	registerOnTouched(fn): void {
		this.onTouched = fn;
	}

	handleAddressChange(event: IAutoCompleteAddress): void {
		this.formGroup.patchValue({
			address1: event?.address,
			address2: '',
			city: event?.city,
			state: event?.state,
			zipCode: event?.postalCode
		});
	}

	writeValue(value): void {
		if (value) {
			this.value = value;
		}
		if (value === null) {
			this.formGroup.reset();
		}
	}
	// communicate the inner form validation to the parent form
	validate(_: FormControl) {
		return this.formGroup.valid ? null : { addressDetail: { valid: false } };
	}
}
