import { ChangeDetectionStrategy, Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface IPdfDialogData {
	title: string;
	url: string;
}

/**
 * Shows pdf file in a dialog
 *
 * @export
 * @class PdfDialogComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-pdf-dialog',
	templateUrl: './pdf-dialog.component.html',
	styleUrls: ['./pdf-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfDialogComponent implements OnInit {
	safeUrl: SafeUrl;

	constructor(
		private sanitizer: DomSanitizer,
		public dialogRef: MatDialogRef<PdfDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IPdfDialogData
	) {
		this.safeUrl = this.sanitizer.sanitize(
			SecurityContext.RESOURCE_URL,
			this.sanitizer.bypassSecurityTrustResourceUrl(data.url)
		);
	}

	ngOnInit(): void {
		this.dialogRef.updatePosition({ top: '10px' });
		this.dialogRef.updateSize('100%', '95%');
	}

	close(): void {
		this.dialogRef.close(false);
	}

	onSubmit(): void {
		this.dialogRef.close(true);
	}
}
