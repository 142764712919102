<div class="special-offer" *transloco="let t">
	<op-masthead [title]="t('SPECIAL_OFFER.LANDING.title')">
		<div class="op-green-divider"></div>
		<p></p>
		<h4 class="op-center">
			<strong>{{ t('SPECIAL_OFFER.LANDING.subtitle') }}</strong>
		</h4>
	</op-masthead>
	<op-content-container class="content">
		<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('SPECIAL_OFFER.LANDING.firstName') }}</mat-label>
				<input matInput formControlName="firstName" name="firstName" required data-test-id="firstName" />
				<mat-error
					id="firstNameError"
					*ngIf="!formGroup.controls['firstName'].valid && formGroup.controls['firstName'].touched"
				>
					{{ t('SPECIAL_OFFER.LANDING.firstNameError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('SPECIAL_OFFER.LANDING.middleName') }}</mat-label>
				<input matInput type="text" formControlName="middleName" name="middleName" data-test-id="middleName" />
				<mat-error
					id="middleNameError"
					*ngIf="!formGroup.controls['middleName'].valid && formGroup.controls['middleName'].touched"
				>
					{{ t('SPECIAL_OFFER.LANDING.middleNameError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('SPECIAL_OFFER.LANDING.lastName') }} </mat-label>
				<input matInput type="text" formControlName="lastName" name="lastName" required data-test-id="lastName" />
				<mat-error
					id="lastNameError"
					*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
				>
					{{ t('SPECIAL_OFFER.LANDING.lastNameError') }}
				</mat-error>
			</mat-form-field>

			<div class="address">
				<op-address-detail-form
					[homeAddress]="true"
					id="specialOffer"
					formControlName="addressDetail"
				></op-address-detail-form>
			</div>

			<div class="left">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					id="submitBtn"
				>
					{{ t('SPECIAL_OFFER.LANDING.submitBtn') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
