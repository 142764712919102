import { Injectable, isDevMode } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import _, { camelCase, lowerCase, noop, reduce, startCase } from 'lodash';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, map, retry, share, switchMap, take, tap } from 'rxjs/operators';
import { EventDataTagPlaidEnum } from 'src/app/core/services/event-data/event-data.service';
import { IRoutingInfo, RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';
import { IMessageDialogData } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { LanguageService } from '../language/language.service';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { LoggingService } from '../logging/logging.service';
import { EventLogApiService } from '../mobile-api/event-log/event-log-api-service';
import { ILoanAppEvent, LoanAppEventTypeEnum } from '../mobile-api/event-log/event-log-model';
import {
	FeatureNameEnum,
	IFeatureEligibilityResult,
	IPlaidConnectionComplete,
	IPlaidCraDetailsResult,
	IPlaidCreateUserResult,
	IPlaidLinkTokenResult
} from '../mobile-api/mobile-api.model';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { TagDataService } from '../tag-data/tag-data.service';
import { PlaidLink } from './plaid-link';
import { PlaidEventMapping } from './plaid-link-events';
import {
	IPlaidConfig,
	IPlaidError,
	IPlaidErrorMetadata,
	IPlaidEventMetadata,
	IPlaidSessionData,
	IPlaidSuccessMetadata,
	SectionTypeEnum
} from './plaid-link.model';
import { PlaidRestoreService } from './plaid-restore.service';
import { ConfigApiService, IConfigResult } from '../mobile-api';
import { DialogService } from '../dialog/dialog.service';

export enum BankConnectEventTypeEnum {
	loading = 'LOADING',
	idle = 'idle',
	initialized = 'INITIALIZED',
	opened = 'OPENED',
	waiting = 'WAITING',
	complete = 'COMPLETE',
	closed = 'CLOSED',
	error = 'ERROR'
}

export enum BankConnectResponseStatusEnum {
	accountsFound = 'ACCOUNTS_FOUND',
	emptyAccountList = 'EMPTY_ACCOUNT_LIST'
}

export interface IBankConnectEvent {
	type: BankConnectEventTypeEnum;
	data?: any;
	section?: any;
}

export enum EventNameEnum {
	open = 'OPEN',
	transitionView = 'TRANSITION_VIEW',
	error = 'ERROR'
}

export enum EventTypeEnum {
	success = 'SUCCESS',
	load = 'LOAD',
	event = 'EVENT',
	exit = 'EXIT'
}

@Injectable({
	providedIn: 'root'
})
export class PlaidLinkService {
	private readonly plaidCdnUrl = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
	private readonly plaidSource = new BehaviorSubject<IBankConnectEvent>({ type: BankConnectEventTypeEnum.loading });

	// Exposed observable (read-only).
	readonly plaid$ = this.plaidSource.asObservable();
	private readonly storageKey = 'plaid';

	private plaidHandler: PlaidLink;
	private plaidLoad$;
	private plaidInit$;
	private plaidLinkConfig: IPlaidConfig;

	bankOnly: boolean;
	currentPage: RoutingPathsEnum;
	plaidConnectViaBanner: boolean;
	plaidBannerInitiatedPage: RoutingPathsEnum;
	plaidCraEnabled: boolean;
	plaidVendorTokenCreated: boolean;

	constructor(
		private scriptService: ScriptLoaderService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private eventLogService: EventLogApiService,
		private tagDataService: TagDataService,
		private sessionStorageService: SessionStorageService,
		private gaService: GoogleAnalyticsService,
		private languageService: LanguageService,
		private plaidRestoreService: PlaidRestoreService,
		private router: Router,
		private loggingService: LoggingService,
		private configService: ConfigApiService,
		private dialogService: DialogService
	) {
		this.plaidLinkConfig = {
			onLoad: this.logEvent(this.onLoad, 'LOAD'),
			onSuccess: this.logEvent(this.onSuccess, 'SUCCESS'),
			onEvent: this.logEvent(this.onEvent, 'EVENT'),
			onExit: this.logEvent(this.onExit, 'EXIT')
		};

		this.plaidLoad$ = this.loadPlaid().pipe(take(1));
		this.plaidInit$ = this.plaidLoad$.pipe(
			switchMap(() =>
				this.mobileService.setEligibility(
					FeatureNameEnum.plaidCraFeature,
					null,
					this.loanAppService.loanApplicationId,
					this.loanAppService.getCurrentApplicant().applicantIndex
				)
			),
			switchMap((rsp: IFeatureEligibilityResult) => {
				this.plaidCraEnabled = rsp.eligible;
				if (!this.plaidCraEnabled) {
					return this.mobileService.getLinkToken(this.bankOnly, this.loanAppService.loanApplicationId);
				} else {
					return of({});
				}
			}),
			tap((rsp: IPlaidLinkTokenResult) => {
				//Set the token and expiration in session storage, this will be used in Oauth flow.
				if (!this.plaidCraEnabled) {
					//Set the token and expiration in session storage, this will be used in Oauth flow.
					const plaidSessionData: IPlaidSessionData = this.buildPlaidSessionData(rsp);
					this.setPlaidSessionData(plaidSessionData);
					this.plaidHandler = new PlaidLink({ ...this.plaidLinkConfig, token: rsp.linkToken }, rsp.expiration);
				}
			}),
			switchMap(() => {
				return this.mobileService.getPlaidCraDetails(
					this.loanAppService.loanApplicationId,
					this.loanAppService.getCurrentApplicant().applicantIndex
				);
			}),
			tap((rsp: IPlaidCraDetailsResult) => {
				this.plaidVendorTokenCreated = rsp.vendorTokenCreated;
			}),
			share()
		);
		this.plaidLoad$.subscribe();

		// add a cypress hook to simulate plaid bank connect.
		if (isDevMode()) {
			window['opPldSrcEvent'] = function () {
				const event = arguments[0];
				this.plaidSource.next(event);
			}.bind(this);
			window['opPldEvent'] = function () {
				const event = arguments[0];
				this[`on${startCase(lowerCase(event.type))}`](event.data);
			}.bind(this);
		}
	}

	buildPlaidSessionData(plaidListResult: IPlaidLinkTokenResult): IPlaidSessionData {
		return {
			token: plaidListResult.linkToken,
			expiration: plaidListResult?.expiration
		};
	}

	public initializePlaid(bankOnly: boolean, eligible: boolean = false, currentPage: RoutingPathsEnum = null): void {
		if (this.plaidHandler) {
			this.destroyPlaid();
		}

		this.bankOnly = bankOnly;
		if (currentPage) {
			this.currentPage = currentPage;
		}
		this.plaidInit$.pipe(take(1)).subscribe();
		this.logEligibility(eligible);
	}

	public initAndOpenPlaidOauth(bankOnly: boolean, eligible: boolean, currentPage: RoutingPathsEnum = null): void {
		this.bankOnly = bankOnly;
		this.currentPage = currentPage;
		this.destroyPlaid();
		this.plaidLoad$
			.pipe(
				map(() => this.getPlaidSessionData()),
				tap((sessionOauthData: IPlaidSessionData) => {
					if (!this.isPlaidSessionValid(sessionOauthData)) {
						throw new Error('session data invalid');
					}
				}),
				map((sessionOauthData: IPlaidSessionData) => {
					return (this.plaidHandler = new PlaidLink(
						{
							...this.plaidLinkConfig,
							token: sessionOauthData.token,
							receivedRedirectUri: sessionOauthData.receivedRedirectUri
						},
						sessionOauthData.expiration
					));
				})
			)
			.subscribe({
				next: () => {
					this.plaidHandler.open();
				},
				error: () => {
					this.plaidSource.next({
						type: BankConnectEventTypeEnum.error,
						data: {}
					});
					this.initializePlaid(bankOnly, eligible, currentPage);
				},
				complete: () => this.clearOauthPlaidSessionData()
			});
	}

	public openPlaid(
		sectionType: SectionTypeEnum = null,
		forwardedUri: string = null,
		currentPage: RoutingPathsEnum = null
	): void {
		this.currentPage = currentPage;
		let sessionData = this.getPlaidSessionData() || {};
		sessionData.sectionType = sectionType || SectionTypeEnum.income;
		sessionData.forwardedUri = forwardedUri || currentPage;
		this.setPlaidSessionData(sessionData);
		const plaidCreateUser$ =
			this.plaidCraEnabled && !this.plaidVendorTokenCreated
				? this.mobileService.getPlaidUserToken(
						this.loanAppService.loanApplicationId,
						this.loanAppService.getCurrentApplicant().applicantIndex
				  )
				: of({});
		if (this.plaidCraEnabled) {
			let isPlaidUserCreated = this.plaidVendorTokenCreated;
			let linkTokenCreated = false;
			plaidCreateUser$
				.pipe(
					switchMap((rsp: IPlaidCreateUserResult) => {
						if (isPlaidUserCreated || rsp.successful) {
							isPlaidUserCreated = true;
							return this.mobileService.getLinkToken(this.bankOnly, this.loanAppService.loanApplicationId);
						} else {
							return of({});
						}
					}),
					tap((rsp: IPlaidLinkTokenResult) => {
						if (isPlaidUserCreated && rsp?.successful) {
							//Set the token and expiration in session storage, this will be used in Oauth flow.
							const plaidSessionData: IPlaidSessionData = this.buildPlaidSessionData(rsp);
							this.setPlaidSessionData(plaidSessionData);
							this.plaidHandler = new PlaidLink({ ...this.plaidLinkConfig, token: rsp.linkToken }, rsp.expiration);
							linkTokenCreated = true;
						}
					})
				)
				.subscribe({
					next: (rsp) => {
						if (isPlaidUserCreated && linkTokenCreated) {
							this.plaidHandler?.open();
						} else {
							this.clearPlaidSessionData();
							this.destroyPlaid();
							this.logPlaidError();
							this.dialogService.openErrorDialog(this.languageService.translate('DIALOG_MESSAGE.message')).subscribe();
						}
					},
					error: () => {
						this.clearPlaidSessionData();
						this.destroyPlaid();
						this.logPlaidError();
						this.dialogService.openErrorDialog(this.languageService.translate('DIALOG_MESSAGE.message')).subscribe();
					}
				});
		} else {
			this.plaidHandler?.open();
		}
	}

	private logPlaidError(): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: 'Plaid CRA Error',
				loan_application_id: this.loanAppService.loanApplicationId
			}
		);
		this.loggingService.info('plaid_event: Plaid CRA Error');
	}

	isPlaidCraEnabled(): boolean {
		return this.plaidCraEnabled;
	}

	public closePlaid(): void {
		this.plaidHandler?.exit();
	}

	public destroyPlaid(): void {
		this.plaidHandler?.destroy();
		this.plaidHandler = null;
	}

	/**
	 * Create a Plaid Link instance as soon as Plaid Link has loaded.
	 * @param PlaidConfig config
	 * @returns Promise<PlaidLinkHandler>
	 */
	private loadPlaid(): Observable<void> {
		return this.scriptService.loadScript(this.plaidCdnUrl).pipe(
			tap(() => {
				this.plaidSource.next({ type: BankConnectEventTypeEnum.idle });
			}),
			catchError((err) => {
				console.error('-op- plaid err', err);
				throw err;
			})
		);
	}

	onLoad(): void {
		this.plaidSource.next({ type: BankConnectEventTypeEnum.initialized, data: { initialized: true } });
	}

	onSuccess(token: string, metadata: IPlaidSuccessMetadata): void {
		let plaidEvent: ILoanAppEvent = {};
		let valueOne = EventTypeEnum.success;
		plaidEvent.eventType = LoanAppEventTypeEnum.loginCompleted;
		plaidEvent.value1 = valueOne;
		plaidEvent.value2 = metadata.link_session_id;

		let sectionType = this.getPlaidSessionData()?.sectionType;
		this.clearPlaidSessionData();

		this.loanAppService.isApplicationStatusBounced();
		const connectionInfo: IPlaidConnectionComplete = {
			publicToken: metadata.public_token,
			institutionId: metadata.institution?.institution_id,
			institutionName: metadata.institution?.name
		};

		const plaidFn$ =
			this.bankOnly || this.currentPage === RoutingPathsEnum.plaidConnect
				? this.mobileService.setPlaidBankConnect(connectionInfo, this.loanAppService.loanApplicationId)
				: this.mobileService.setPlaidIncome(connectionInfo, this.loanAppService.loanApplicationId);

		this.plaidSource.next({ type: BankConnectEventTypeEnum.waiting, data: {} });
		plaidFn$
			.pipe(
				retry({
					count: 2,
					delay: () => {
						this.logCompleteRetries(metadata);
						return timer(100);
					}
				})
			)
			.subscribe({
				next: (rsp) => {
					this.plaidSource.next({ type: BankConnectEventTypeEnum.complete, data: rsp, section: sectionType });
					this.initializePlaid(this.bankOnly);
				},
				error: (err) => {
					this.plaidSource.next({ type: BankConnectEventTypeEnum.error, data: err, section: sectionType });
					this.initializePlaid(this.bankOnly);
				}
			});
	}

	onEvent(eventName: string, metadata: IPlaidEventMetadata): void {
		let plaidEvent: ILoanAppEvent = {};

		if (eventName === 'OPEN') {
			this.plaidSource.next({ type: BankConnectEventTypeEnum.opened, data: null });
			plaidEvent.eventType = LoanAppEventTypeEnum.loginInitiated;
		} else if (eventName === 'HANDOFF') {
			this.plaidSource.next({ type: BankConnectEventTypeEnum.closed, data: null });
		} else if (eventName === 'OPEN_OAUTH') {
			this.plaidRestoreService.saveSessionStorage();
			this.loggingService.info('plaid_event:OPEN_OAUTH');
		}
	}

	onExit(error: IPlaidError, metadata: IPlaidErrorMetadata): void {
		let plaidEvent: ILoanAppEvent = {};
		let errorType = error?.error_type ? EventNameEnum.error : EventTypeEnum.exit;
		if (EventTypeEnum.exit === errorType) {
			plaidEvent.eventType = LoanAppEventTypeEnum.loginCompleted;
			plaidEvent.value1 = errorType;
			plaidEvent.value2 = metadata?.link_session_id;
		}

		if (error != null && error.error_code) {
			this.clearPlaidSessionData();
			this.destroyPlaid();
		}
		this.plaidSource.next({
			type: BankConnectEventTypeEnum.closed,
			data: { error: error ? error.error_code : undefined }
		});
		this.initializePlaid(this.bankOnly);
	}

	getPlaidSessionData(): IPlaidSessionData {
		return this.sessionStorageService.get(this.storageKey);
	}

	setPlaidSessionData(plaidSessionData: IPlaidSessionData): void {
		this.sessionStorageService.set(this.storageKey, plaidSessionData);
	}

	private clearOauthPlaidSessionData(): void {
		this.setPlaidSessionData({
			...this.getPlaidSessionData(),
			receivedRedirectUri: null,
			isOauthFlow: null
		});
	}

	clearPlaidSessionData(): void {
		this.sessionStorageService.remove(this.storageKey);
	}

	/**
	 * Create a delimited string from an object
	 *
	 * @param {*} params object to create the string from
	 * @param {*} includeKeys indicates that the object keys should be included in the string ie key=value
	 * @param {*} delimiter delimiter to use. defaulted to '&'
	 * @returns a delimited string ie "key1=value1&key2=value2" OR "value1:value2:value3"
	 */
	private createDelimitedStringFromObject(params: any, includeKeys: boolean = false, delimiter: string = '&'): string {
		return reduce(
			params,
			function (result, values, key) {
				return values ? (includeKeys ? result.push(key + '=' + values) : result.push(values)) : result;
			},
			_([])
		).join(delimiter);
	}

	private logToGoogleAnalytics(action: string, label: string): void {
		this.gaService.ga('send', 'event', 'PLAID', action, label, this.loanAppService.loanApplicationId);
	}

	private logToTealium(action: string, label: string, metadata: IPlaidEventMetadata): void {
		let event_name: string = action;
		switch (action) {
			case 'LOAD':
				event_name = EventDataTagPlaidEnum.plaidLoads;
				break;
			case 'SUCCESS':
				event_name = EventDataTagPlaidEnum.plaidLoginCompleted;
				break;
			case 'EVENT:OPEN':
				event_name = EventDataTagPlaidEnum.plaidLoginInitiated;
				break;
			case 'EVENT:EXIT':
				event_name = EventDataTagPlaidEnum.plaidLoginCompleted;
				break;
		}
		const tagEvent = {
			tealium_event: event_name,
			application_type: 'CONSUMER_INSTALLMENT_LOAN',
			event_action: action,
			event_category: 'CONSUMER_INSTALLMENT_LOAN',
			event_label: this.getEventValueData(),
			loan_application_id: this.loanAppService.loanApplicationId,
			page_location: window.location.href,
			institution_id: metadata?.institution_id,
			institution_name: metadata?.institution_name,
			link_session_id: metadata?.link_session_id,
			request_id: metadata?.request_id,
			view_name: metadata?.view_name,
			applicant_id: this.loanAppService.getCurrentApplicant()?.id,
			application_status: this.loanAppService.getLoanApp()?.applicationStatus,
			language_preference: this.languageService.language,
			error_code: metadata?.error_code,
			error_message: metadata?.error_message || metadata?.exit_status || metadata?.status,
			error_type: metadata?.error_type
		};
		this.tagDataService.link({}, tagEvent);
	}

	private logToLoanApplication(action: string, metadata: IPlaidEventMetadata): void {
		const logLoanApp = PlaidEventMapping.getEventLogMap(this.getEventValueData(), this.bankOnly, metadata);
		if (logLoanApp[action]) {
			const plaidEvent = {
				applicantId: this.loanAppService.getCurrentApplicant()?.id,
				eventType: logLoanApp[action].type,
				loanApplicationId: this.loanAppService.loanApplicationId,
				value1: this.createDelimitedStringFromObject(logLoanApp[action].value1, false, ':'),
				value2: logLoanApp[action].value2
			};
			this.eventLogService.logLoanAppEvent(plaidEvent).subscribe();
		}
	}

	/**
	 * Log plaid events to google analytics, tealium and loanApp.log.
	 * event params    |   arg[0]  |   arg[1]
	 * -------------------------------------------------------------
	 * LOAD            | NULL      |  NULL
	 * EVENT           | eventName |  metadata
	 * SUCCESS         | pub_token |  metadata
	 * EXIT            | error obj |  metadata without error info
	 *
	 * https://plaid.com/docs/#parameter-reference
	 *
	 * @param {*} fn to call after logging
	 * @param {*} event name plaid event.
	 * @returns fn results.
	 */
	private logEvent(fn: Function, event: string): Function {
		return (...params): void => {
			const eventData = params[0];
			const metadata: IPlaidEventMetadata = params[1] || {};
			let opMetadata = metadata;
			let logParams = {
				event: event,
				eventName: event !== EventTypeEnum.success && event !== EventTypeEnum.exit ? eventData : undefined
			};
			logParams.eventName =
				logParams.event === EventTypeEnum.exit && eventData ? EventNameEnum.error : logParams.eventName;
			logParams['viewName'] = logParams.eventName === EventNameEnum.transitionView ? metadata.view_name : undefined;
			const action = this.createDelimitedStringFromObject(logParams, false, ':');
			const errorCode = logParams.event === EventTypeEnum.exit && eventData ? eventData.error_code : undefined;
			if (logParams.event === EventTypeEnum.exit) {
				opMetadata = {
					...metadata,
					error_code: eventData?.error_code,
					error_message: eventData?.error_message,
					error_type: eventData?.error_type,
					institution_id: metadata?.institution?.institution_id,
					institution_name: metadata?.institution?.name
				};
			}
			const appParams = {
				loanId: this.loanAppService.loanApplicationId,
				applicantId: this.loanAppService.getCurrentApplicant()?.id,
				sessionLinkId: opMetadata.link_session_id,
				requestId: opMetadata.request_id,
				errorCode: opMetadata.error_code || errorCode
			};
			const label = this.createDelimitedStringFromObject(appParams, true);

			this.logToGoogleAnalytics(action, label);
			this.logToTealium(action, label, opMetadata);
			this.logToLoanApplication(action, opMetadata);

			return fn.apply(this, params);
		};
	}

	/**
	 * log to google analytics and tealium if the applicant is eligible for plaid
	 *
	 * @param {*} eligible
	 * @returns
	 */
	private logEligibility(eligible: boolean): void {
		eligible && this.logEvent(noop, 'ELIGIBLE')();
	}

	private logPlaidOauthReturn(): void {
		this.logEvent(noop, 'OAUTH_RETURN')();
	}

	/**
	 * log to google analytics and tealium if a retry was necessary to complete plaid.
	 *
	 * @returns
	 */
	private logCompleteRetries(metadata: IPlaidSuccessMetadata): void {
		this.logEvent(noop, 'RETRY')(null, metadata);
	}

	private isPlaidSessionValid(plaidSessionData: IPlaidSessionData): boolean {
		if (!plaidSessionData) {
			this.loggingService.error('plaid session data missing');
			return false;
		}
		if (this.isPlaidTokenExpired(plaidSessionData.expiration)) {
			this.loggingService.error('plaid link token expired');
			return false;
		}
		return true;
	}

	private isPlaidTokenExpired(expiration: string): boolean {
		if (expiration) {
			let currentTime = Date.now();
			if (Date.parse(expiration) < currentTime) {
				return true;
			}
		}
		return false;
	}

	setPlaidConnectBannerValue(data: boolean, initiatedPage: RoutingPathsEnum = null): void {
		this.plaidConnectViaBanner = data;
		this.plaidBannerInitiatedPage = initiatedPage;
	}

	getEventValueData(): string {
		if (this.plaidConnectViaBanner) {
			return EventDataTagPlaidEnum.plaidConnectedViaBanner + '_' + this.plaidBannerInitiatedPage;
		} else if (this.currentPage === RoutingPathsEnum.plaidConnect) {
			return EventDataTagPlaidEnum.plaidLoadsFirstTime;
		} else {
			return this.currentPage;
		}
	}

	private routeOauth(sessionOauthData: IPlaidSessionData): IRoutingInfo {
		const sessionData = { ...sessionOauthData, receivedRedirectUri: window.location.href, isOauthFlow: true };
		this.sessionStorageService.set(this.storageKey, sessionData);
		if (sessionData.forwardedUri) {
			const tree: UrlTree = this.router.parseUrl(sessionData.forwardedUri);
			const queryParams = tree.queryParams;
			const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
			const path: RoutingPathsEnum = RoutingPathsEnum[camelCase(g?.segments[0].path)];
			return { route: path, extra: { queryParams } };
		} else {
			this.loggingService.error('plaid forwarded uri missing');
			return { route: RoutingPathsEnum.home };
		}
	}

	getRouteAfterOauthRedirect(): Observable<IRoutingInfo> {
		let sessionOauthData: IPlaidSessionData = this.sessionStorageService.get(this.storageKey);
		this.logPlaidOauthReturn();
		this.loggingService.info('plaid_event:OAUTH:returned');

		if (sessionOauthData) {
			this.plaidRestoreService.removeSessionKey();
			return of(this.routeOauth(sessionOauthData));
		} else {
			if (!this.sessionStorageService.get('auth')) {
				this.loggingService.error('plaid & auth session storage missing');
			} else {
				this.loggingService.error('plaid session storage missing');
			}
			return this.plaidRestoreService.restoreSessionStorage().pipe(
				map(() => this.sessionStorageService.get(this.storageKey)),
				tap((sd: IPlaidSessionData) =>
					sd
						? this.loggingService.info('plaid session storage restore success')
						: this.loggingService.error('plaid session storage restore failed')
				),
				tap(() => this.plaidRestoreService.removeSessionKey()),
				map((sd: IPlaidSessionData) => (sd ? this.routeOauth(sd) : { route: RoutingPathsEnum.home }))
			);
		}
	}

	getBankDialogMessage(accountsFound: boolean): IMessageDialogData {
		if (!accountsFound) {
			return {
				title: this.languageService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.accountNotAccepted'),
				message: this.languageService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.provideAccountOrDocument')
			};
		}
		return null;
	}

	getIncomeDialogMessage(accountsFound: boolean, incomeVerified: boolean): IMessageDialogData {
		if (!accountsFound) {
			return this.getBankDialogMessage(accountsFound);
		}

		if (!incomeVerified) {
			return {
				title: this.languageService.translate('DOCUMENT_SUBMIT.proofOfIncome.incomeNotFound'),
				message: this.languageService.translate('DOCUMENT_SUBMIT.proofOfIncome.provideAccountOrDocuments')
			};
		}

		return null;
	}
}
