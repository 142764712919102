<div class="plaid-connect" *transloco="let t">
	<op-content-container class="content">
		<op-plaid-bank-transaction
			(next)="onNext($event)"
			(skip)="skipPlaid($event)"
			[sectionType]="'bank'"
			[bankOnly]="false"
			[showBenefits]="true"
		></op-plaid-bank-transaction>
		<op-website-terms class="op-mt-20"></op-website-terms>
	</op-content-container>
</div>
