import { Component } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';
import { VehicleReferenceGuideService } from 'src/app/core/services/vehicleReferenceGuide/vehicle-reference-guide.service';
@Component({
	selector: 'op-section-vehicle-photos',
	templateUrl: './section-vehicle-photos.component.html',
	styleUrls: ['./section-vehicle-photos.component.scss'],
	providers: [VehicleReferenceGuideService]
})
export class SectionVehiclePhotosComponent extends ExpandSectionComponent {
	constructor(private vehicleReferenceGuideService: VehicleReferenceGuideService) {
		super();
	}

	openVehicleReferenceGuideModal(): void {
		this.vehicleReferenceGuideService.openVehicleReferenceGuideModal().subscribe();
	}
}
