<div class="plaid-account-list" *transloco="let t">
	<op-content-container class="content">
		<mat-radio-group name="accounts" id="{{ id + '_selected' }}">
			<div *ngIf="_accountList.length">
				<div class="op-label-font op-gray op-mb-20 deposit">{{ t('SELECT_BANK_ACCOUNT.deposit') }}</div>
				<div class="op-mat-radio-group">
					<mat-radio-button
						(change)="onAccountSelectionChange($event)"
						class="op-mb-5"
						id="{{ id + '_verified_selected_' + i }}"
						*ngFor="let account of _accountList; let i = index"
						[value]="account.id"
						[checked]="account.selected"
					>
						<div>{{ account.bankName }}</div>
						<div class="op-label-font op-gray">
							{{ account.accountType | titlecase }}************{{ account.accountNumber | slice: -4 }}
						</div>
					</mat-radio-button>
				</div>
			</div>
		</mat-radio-group>

		<div class="op-mt-20">
			<button
				class="op-full-width"
				mat-flat-button
				color="secondary"
				id="{{ id + '_plaidAccountListAddAnotherBank' }}"
				(click)="onAddBank($event)"
			>
				{{ t('PLAID_CONNECT.ACCOUNT_LIST.addAnotherBank') }}
				<mat-icon svgIcon="op-popout"> </mat-icon>
			</button>
		</div>
	</op-content-container>
</div>
