import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { some } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PdfDialogComponent } from '../../../shared/components/dialogs/pdf-dialog/pdf-dialog.component';
import { EnvironmentService } from '../environment/environment.service';
import { IssuingPartnerService } from '../partner/issuing-partner.service';
import { DialogService } from '../dialog/dialog.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Injectable({
	providedIn: 'root'
})
export class VehicleReferenceGuideService implements OnDestroy {
	private readonly VEHICLE_REFERENCE_GUIDE_PDF_SUFFIX = '/personal-loans/SPL/vehicle-photo-guide';
	private readonly META_VEHICLE_REF_GUIDE_PATH = '/personal-loans/SPL/vehicle-photo-guide-META';
	private readonly S3_PROD_URL = 'https://oportun-s3-prod-usw1-public-all.s3.us-west-1.amazonaws.com';
	private readonly S3_DEV_URL = 'https://oportun-s3-dev-usw1-public-all.s3.us-west-1.amazonaws.com';
	private subscription: Subscription = new Subscription();

	private language: string;
	private issuingOrganization: string;
	isMetaOrganization: boolean;

	constructor(
		private environmentService: EnvironmentService,
		private translocoService: TranslocoService,
		private issuingPartnerService: IssuingPartnerService,
		private dialogService: DialogService
	) {
		const translocoSub = this.translocoService.langChanges$.pipe(tap((lang) => (this.language = lang))).subscribe();
		const issuingOrgSub = this.issuingPartnerService.issuingOrganization$
			.pipe(
				tap((issuingOrg) => {
					this.issuingOrganization = issuingOrg;
					this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingOrganization);
				})
			)
			.subscribe();
		this.subscription.add(translocoSub);
		this.subscription.add(issuingOrgSub);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	getFullS3PDFUrl() {
		const urlPrefix = this.environmentService.isProduction ? this.S3_PROD_URL : this.S3_DEV_URL;
		const pdfSuffix = this.isMetaOrganization
			? this.META_VEHICLE_REF_GUIDE_PATH
			: this.VEHICLE_REFERENCE_GUIDE_PDF_SUFFIX;
		return `${urlPrefix}${pdfSuffix}-${this.language.toUpperCase()}.pdf`;
	}

	openVehicleReferenceGuideModal(): Observable<any> {
		const data = {
			title: this.translocoService.translate('DOCUMENT_SUBMIT.vehiclePhotos.requirements.referenceGuide', {}),
			url: this.getFullS3PDFUrl()
		};
		return this.dialogService.openSimpleDialog(PdfDialogComponent, { data });
	}
}
