import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, groupBy } from 'lodash';
import { Subscription } from 'rxjs';
import { IAchBankAccount } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { IInstitution, IPlaidConnectedInstitutions, RefreshStatus } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-plaid-refresh-reconnect',
	templateUrl: './plaid-refresh-reconnect.component.html'
})
export class PlaidRefreshReconnectComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	bankOnly: boolean = true;

	@Input()
	set accountList(accountList: IAchBankAccount[]) {
		this._accountList = accountList;
		this.accountListByBank = groupBy(this._accountList, 'bankName');
	}

	@Input()
	set connectedInstitutions(connectedInstitutions: IPlaidConnectedInstitutions) {
		this._connectedInstitutions = connectedInstitutions;
		this.failedInstitutions = filter(connectedInstitutions.institutions, { refreshStatus: RefreshStatus.FAILED });
	}

	@Output()
	openPlaid = new EventEmitter();

	_connectedInstitutions: IPlaidConnectedInstitutions;
	failedInstitutions: IInstitution[];
	_accountList: IAchBankAccount[] = [];
	accountListByBank: { [key: string]: IAchBankAccount[] };

	constructor(private routingService: RoutingService) {}

	confirm(): void {
		this.navigateToNextScreen();
	}

	navigateToNextScreen(): void {
		this.routingService.routeFromLoanApp();
	}
}
