<div class="funds-disbursement-selection" *transloco="let t">
	<div class="op-mt-20">
		<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-radio-group name="accounts" id="{{ id + '_selected' }}" formControlName="selectedId">
				<div class="funds-border" *ngIf="verifiedAccounts.length">
					<div class="op-mb-5 op-body-1-bold-font">{{ t('FUNDS.depositVerifiedAccount') }}</div>
					<div class="op-label-font op-gray op-mb-10">{{ t('FUNDS.receiveBankFunds') }}</div>
					<div class="op-mat-radio-group">
						<mat-radio-button
							class="op-mb-5"
							id="{{ id + '_verified_selected_' + i }}"
							*ngFor="let account of verifiedAccounts; let i = index"
							[value]="account.id"
							[checked]="account.selected"
						>
							<div>{{ account.bankName }} (...{{ account.accountNumber | slice: -4 }})</div>
						</mat-radio-button>
					</div>
				</div>

				<div class="op-mt-10"></div>
				<div class="funds-border" *ngIf="activeAccounts.length">
					<div class="op-mb-5 op-body-1-bold-font">{{ t('FUNDS.depositUnverifiedAccount') }}</div>
					<div class="op-label-font op-gray op-mb-10">{{ t('FUNDS.requiresProofOfBank') }}</div>
					<div class="op-mat-radio-group">
						<mat-radio-button
							class="op-mb-5"
							id="{{ id + '_selected_' + i }}"
							*ngFor="let account of activeAccounts; let i = index"
							[value]="account.id"
							[checked]="account.selected"
						>
							<div>{{ account.bankName }} (...{{ account.accountNumber | slice: -4 }})</div>
						</mat-radio-button>
					</div>
				</div>

				<div class="op-mt-10"></div>
				<div class="funds-border">
					<div class="op-mat-radio-group">
						<mat-radio-button
							id="{{ id + '_selected_check' }}"
							[value]="'check'"
							[checked]="account?.get('selected').value"
						>
							<div>{{ t('FUNDS.check') }}</div>
						</mat-radio-button>
						<div class="op-label-font op-gray op-mb-5" *ngIf="checkAtStore" id="giveAtStore">
							{{ t('FUNDS.' + issuingOrganization + '.checkAtStore') }}
						</div>
						<div class="op-label-font op-gray op-mb-5" *ngIf="!checkAtStore" id="giveByMail">
							{{ t('FUNDS.' + issuingOrganization + '.checkInMail') }}
						</div>
					</div>
				</div>
			</mat-radio-group>
			<div class="op-label-font op-gray op-mb-10 op-mt-20">{{ t('FUNDS.fundingMayVary') }}</div>

			<button
				class="op-full-width"
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="{{ id + '_submitBtn' }}"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('FUNDS.next') }}
			</button>
		</form>
	</div>
</div>
