<div class="document-submit-auto" *transloco="let t">
	<div *ngIf="initialized">
		<div *ngIf="!showReviewingDocuments">
			<div class="title-remove-all">
				<op-masthead
					[customClass]="'head'"
					[title]="
						!isNoDocState
							? t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.title')
							: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.readyToSubmit')
					"
				>
				</op-masthead>
				<div class="remove-all op-right">
					<a
						href=""
						mat-flat-button
						class="op-label-b2"
						color="link-label"
						onclick="return false"
						id="removeAllBtn"
						*ngIf="showDeleteDocumentButton && (automaticVerificationList?.length || showBouncedResubmitSection)"
						(click)="removeDocument()"
						[opTagClick]="'remove all documents'"
					>
						{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.removeAllUploads') }}
					</a>
				</div>
			</div>

			<div *ngIf="showBouncedResubmitSection" class="op-mtb-30" id="bounceResubmit">
				<div class="bounced-attention">
					<h4>{{ t('DOCUMENT_SUBMIT.noAddlDocsRequired') }}</h4>
					<ul>
						<li class="attention">{{ t('DOCUMENT_SUBMIT.submitAgainToContinue') }}</li>
					</ul>
				</div>
			</div>

			<op-content-container class="content">
				<div *ngIf="isNoDocState">
					<p>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.applicationReady') }}</p>
				</div>
				<div
					class="document-category"
					[@listAnimation]="automaticVerificationList.length"
					*ngFor="let fileType of automaticVerificationList; let i = index; trackBy: trackBy"
					id="docSubmitProofOf_{{ fileType.categoryType }}_{{ i }}"
				>
					<op-document-section
						id="docSubmitProofOf_{{ fileType.categoryType }}"
						tabindex="0"
						class="op-mb-16"
						[categoryType]="fileType.categoryType"
						[bounceAttention]="fileType.bounceAttention"
						[badge]="fileType.badge"
						[count]="fileType.count"
						[icon]="fileType.icon"
						[title]="t(fileType.title)"
						[description]="t(fileType.description)"
						(click)="fileType.click(fileType)"
						[opTagClick]="'doc submit ' + fileType.categoryType + ' selected'"
					></op-document-section>
				</div>

				<div *ngIf="hasAlert" class="icon-align">
					<mat-icon svgIcon="op-info-green"></mat-icon>
					<div class="op-label-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fasterDecision') }}</div>
				</div>

				<div class="op-mt-20 op-center">
					<button
						mat-flat-button
						color="primary"
						class="submit-button op-full-width"
						name="submitBtn"
						id="submitBtn"
						[disabled]="disableSubmit"
						(click)="onSubmit()"
						[opTagClick]="'Submit for final review'"
					>
						{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.submitFinalReview') }}
					</button>
				</div>

				<div class="op-mt-20 op-center">
					<button
						*ngIf="showReturnToOffers"
						mat-flat-button
						color="secondary"
						class="submit-button op-full-width"
						name="returnToOffersButton"
						id="returnToOfferBtn"
						(click)="returnToOffers()"
						[opTagClick]="'return to offers'"
					>
						{{ t('DOCUMENT_SUBMIT.returnToOffers') }}
					</button>
				</div>

				<div class="op-mt-20 op-center">
					<a
						href=""
						mat-flat-button
						color="link-md"
						class="ach-link"
						onclick="return false"
						id="chooseAchLink"
						*ngIf="!showChangeAchDisbursement"
						(click)="switchToAchDisbursement()"
						[opTagClick]="'switch to ach disbursement'"
					>
						{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.chooseAchDisbursementOption') }}
					</a>
					<a
						href=""
						mat-flat-button
						color="link-md"
						class="ach-link"
						onclick="return false"
						id="chooseNonAchLink"
						*ngIf="showRejectDisbursementLink"
						(click)="confirmAndRejectDisbursement()"
						[opTagClick]="'reject ach disbursement'"
					>
						{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.chooseNonAchDisbursementOption') }}
					</a>
				</div>
			</op-content-container>
		</div>
		<div *ngIf="showReviewingDocuments">
			<op-document-review
				id="reviewingDocuments"
				[progress]="submitProgress"
				[duration]="submitTime"
			></op-document-review>
		</div>
	</div>
</div>
