import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'op-spl-product-offer-info-card',
	templateUrl: './spl-product-offer-info-card.component.html',
	styleUrls: ['./spl-product-offer-info-card.component.scss']
})
export class SplProductOfferInfoCardComponent {
	@Input()
	showSkipSPLOfferButton: boolean;

	@Input()
	skipOfferButtonText: string;

	@Input()
	splMaxLoanAmount: number;

	@Input()
	showSPLDocSection: boolean;

	@Output()
	continue = new EventEmitter<any>();

	onContinue(): void {
		this.continue.emit();
	}
}
