<div class="op-header-commons-pro pre-qualify income-sources" *transloco="let t">
	<op-masthead [title]="t('PRE_QUALIFY.Income.title')"></op-masthead>
	<p></p>

	<op-content-container class="content" *ngIf="ready">
		<div class="op-body-2-font op-mb-10">{{ t('PRE_QUALIFY.Income.monhtlyExpenses') }}</div>

		<div class="op-display-3-font op-mt-20 op-mb-10">{{ initialTakeHomePay | currency }}</div>

		<div class="op-body-1-font op-mt-20 op-mb-10">{{ t('PRE_QUALIFY.Income.details') }}</div>

		<div class="op-header-2-font op-mt-20 op-mb-10">{{ t('PRE_QUALIFY.Income.subTitle') }}</div>

		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<section class="income-source-section">
				<div
					class="income-source"
					*ngFor="let income of incomeList.controls; let i = index"
					(click)="openAddIncomeSourceModal(income, i)"
				>
					<div class="income-row" id="{{ i + '_incomeRow' }}">
						<div class="income-type">
							<div class="op-header-2-font company">
								{{ income.get('company').value || getIncomeType(income.get('incomeSourceType').value) }} &nbsp;
							</div>
							<div class="op-body-1-font">{{ t('PRE_QUALIFY.Income.startDate') }}</div>
						</div>
						<div class="income-info">
							<div>
								<div class="income-amount op-header-2-font">
									{{ income.get('amount').value | currency: 'USD':'symbol':'0.0' }}
								</div>
								<div class="op-body-1-font">{{ income.get('startDate').value | date: 'MM/yyyy' }}</div>
							</div>
							<div class="icon-container">
								<mat-icon class="caret-right-icon" svgIcon="op-caretRightSmall"></mat-icon>
							</div>
						</div>
					</div>
				</div>

				<div
					class="add-income"
					id="add-income"
					(click)="openNewAddIncomeSourceModal()"
					aria-label="{{ t('PRE_QUALIFY.Income.addIncome') }}"
				>
					<div class="add-income-info">
						<mat-icon class="op-add" svgIcon="op-add2"></mat-icon>
						<div class="op-header-2-font add-icon-text">{{ t('PRE_QUALIFY.Income.addIncome') }}</div>
					</div>
					<div class="icon-container">
						<mat-icon class="caret-right-icon" svgIcon="op-caretRightSmall"></mat-icon>
					</div>
				</div>
			</section>

			<p></p>

			<section class="ssl-section op-mt-30" aria-describedby="sslInfo">
				<mat-icon class="ssl-icon" svgIcon="op-protection"></mat-icon>
				<div id="sslInfo" class="ssl-info op-ml-10">{{ t('PRE_QUALIFY.ssl') }}</div>
			</section>

			<button
				class="buttons"
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="submitBtn"
				[opTagClick]="'Income next'"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('PRE_QUALIFY.next') }}
			</button>
		</form>
	</op-content-container>
</div>
