import { isBefore, parseISO } from 'date-fns';

import { IPlaidConfig } from './plaid-link.model';

declare let Plaid: any;

export class PlaidLink {
	private plaidLink: any;
	private expiration: string;

	constructor(config: IPlaidConfig, expiration: string) {
		this.expiration = expiration;
		this.plaidLink = Plaid.create(config);
	}

	public isExpired(): boolean {
		return isBefore(parseISO(this.expiration), new Date());
	}

	public open(): void {
		this.plaidLink.open();
	}

	public exit(): void {
		this.plaidLink.exit();
	}

	public destroy(): void {
		this.plaidLink.destroy();
	}
}
