import { formatDate } from 'src/app/core/services/date-utils/date-utils';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IFinancesResult, IGetIncomeResult } from 'src/app/core/services/mobile-api/mobile-api.model';

import { SummarySection } from './section.model';

/**
 * Stores income summary information.
 *
 * @param {IGetIncomeResult[]} incomes
 * @param {IFinancesResult} finances
 * @param {IMetadata[]} incomeSourceType
 * @param {IMetadata[]} paymentFrequency
 * @param {string} paid
 * @param {string} started
 * @returns {SummarySection}
 */
export const incomeSection = (
	incomes: IGetIncomeResult[],
	finances: IFinancesResult,
	incomeSourceType: IMetadata[],
	incomeFrequency: IMetadata[],
	paid: string,
	started: string,
	locale: Locale
): SummarySection => {
	const fields = incomes.map((income) => {
		const type = incomeSourceType.find((item) => item.key === income.incomeSourceType)?.text;
		const frequency = incomeFrequency.find((item) => item.key === income.paymentFrequency)?.text;
		return {
			title: `${type} - $${income.amount} ${paid} ${frequency}`,
			titleValue: true,
			value: `${started} ${formatDate(income.startDate, 'MMM yyy', locale)}`
		};
	});

	return {
		section: 'income',
		header: 'SUMMARY.incomeSources',
		route: '/income',
		fields
	};
};
