<div class="plaid-refresh" *transloco="let t">
	<op-content-container class="content">
		<div class="plaid-refresh">
			<op-learn-more class="op-pad-20" src="/assets/images/connect-bank.svg">
				<div id="EstbCreditHistoryA">
					<label class="op-header-1-font refresh-bank" [innerHtml]="t('PLAID_REFRESH.refreshYourBank')"> </label>
				</div>
			</op-learn-more>

			<section id="connectedBankAccounts">
				<div id="plaidIncentive">
					<span [innerHtml]="t('PLAID_REFRESH.usePreviouslyConnectedAccounts')"></span>
				</div>

				<div class="op-icon-align-body-1 op-mtb-20">
					<div class="dark-green-dot"></div>
					<span class="op-primary-black" [innerHtml]="t('PLAID_REFRESH.higherLoanAmount')"></span>
				</div>

				<div class="op-icon-align-body-1 op-mtb-20">
					<div class="dark-green-dot"></div>
					<span class="op-primary-black" [innerHtml]="t('PLAID_REFRESH.fewerDocuments')"></span>
				</div>

				<div class="op-icon-align-body-1 op-mtb-20">
					<div class="dark-green-dot"></div>
					<span class="op-primary-black" [innerHtml]="t('PLAID_REFRESH.expeditedLoan')"></span>
				</div>

				<div class="op-mtb-20">
					<strong class="op-header-2-font" [innerHtml]="t('PLAID_REFRESH.previouslyConnectedAccounts')"></strong>
				</div>

				<div *ngIf="connectedInstitutions?.institutions?.length" class="plaid-account-group">
					<div
						*ngFor="let institution of connectedInstitutions?.institutions; index as i"
						class="plaid-account plaid-account-bottom-border"
					>
						<div class="account-name">
							<label>{{ institution.name }}</label>
							<mat-checkbox
								id="accountSelection_{{ i }}"
								[disabled]="connectedInstitutions?.readOnly"
								(change)="onAccountSelectionChange(institution, $event)"
								color="primary"
								labelPosition="before"
								[checked]="institution.select"
							>
							</mat-checkbox>
						</div>
						<div class="account-list">
							<div
								class="op-label-font op-gray line-height-md"
								*ngFor="let account of institution.accounts; let i = index"
							>
								{{ account.accountType | titlecase }} {{ account.accountNumberLastFour | slice: -4 }}
							</div>
						</div>
					</div>
					<div class="deselect-accounts">
						<div class="op-dark-grey" [innerHtml]="t('PLAID_REFRESH.deselectAccounts')"></div>
					</div>
				</div>

				<div id="newAccounts" class="op-mt-20" *ngIf="_accountList.length">
					<div class="plaid-account-group">
						<div
							*ngFor="let account of accountListByBank | keyvalue; let last = last"
							class="plaid-account"
							[class.plaid-account-bottom-border]="!last"
						>
							<div class="plaid-account-container">
								<div>
									<div class="account-name">
										<label>{{ account.key }}</label>
									</div>
									<div class="account-list">
										<div
											class="op-label-font op-gray line-height-md"
											*ngFor="let account of account.value; let i = index"
										>
											{{ account.accountType | titlecase }} {{ account.accountNumberLastFour | slice: -4 }}
										</div>
									</div>
								</div>
								<div>
									<op-badge small="true" id="account{{ i }}" class="ready" icon="op-checkMark-green">
										{{ t('PLAID_RECONNECT.connected') }}
									</op-badge>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="add-bank-container op-mtb-10" id="addBankContainer" (click)="this.openPlaid.emit()">
					<div class="add-bank-inner-container">
						<mat-icon svgIcon="op-add2" class="add-icon"> </mat-icon>
						<span class="op-body-1-bold-font op-ml-10">{{ t('PLAID_RECONNECT.addNewBank') }}</span>
					</div>
					<mat-icon svgIcon="op-caretRightSmall" class="op-caret"></mat-icon>
				</div>
			</section>
		</div>

		<div class="op-label-font op-mt-20">
			<p class="policy" [innerHTML]="t('PLAID_CONNECT.youAgreePlaidAccess')"></p>

			<p class="policy" [innerHTML]="t('PLAID_CONNECT.reviewPlaidTerms')"></p>
		</div>
		<div class="op-center">
			<button
				mat-flat-button
				color="primary"
				class="op-full-width"
				id="confirm"
				(click)="confirm.emit()"
				[opTagClick]="confirm"
			>
				{{ t('PLAID_REFRESH.confirm') }}
			</button>
		</div>
		<div class="op-center op-mb-20" *ngIf="!connectedInstitutions?.readOnly">
			<button
				mat-flat-button
				color="secondary"
				class="op-full-width"
				id="declineRefresh"
				(click)="declineRefresh.emit()"
				[opTagClick]="'declineRefresh'"
			>
				{{ t('PLAID_REFRESH.decline') }}
			</button>
		</div>
		<hr />
		<op-website-terms class="op-mt-10"></op-website-terms>
	</op-content-container>
</div>
