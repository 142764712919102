<div class="plaid-link" *transloco="let t">
	<ng-content select="[plaid-title]"></ng-content>
	<div *ngIf="!minimum && showIcon" class="op-mb-16">
		<img alt="" class="loan-icon" src="/assets/images/icon_plaid_add_bank.svg" role="presentation" />
	</div>
	<div *ngIf="!minimum" class="op-body-1-font op-left op-mb-8">
		<div [innerHTML]="t('PLAID_LINK.automaticDesc')"></div>
	</div>

	<div class="op-left" *ngIf="issuingOrganization">
		<p *ngIf="!minimum" class="disclaimerText policy op-body-2-font">
			{{ 'AUTO_VERIFY_INCOME.plaidThirdPartyAgreement.' + issuingOrganization + '.notApplicableForSPL' | transloco }}
		</p>
		<p
			class="policy op-micro-font"
			[innerHTML]="t('AUTO_VERIFY_INCOME.plaidThirdPartyAgreement.' + issuingOrganization + '.youAgreePlaidAccess')"
		></p>
		<p
			class="policy op-micro-font"
			[innerHTML]="t('AUTO_VERIFY_INCOME.plaidThirdPartyAgreement.' + issuingOrganization + '.reviewPlaidTerms')"
		></p>
	</div>

	<div *ngIf="showConnectBank">
		<op-plaid-button
			id="{{ id + '_plaidButton' }}"
			[sectionType]="sectionType"
			[bankOnly]="bankOnly"
			[bankAccountExists]="bankAccountExists"
		></op-plaid-button>
	</div>
	<p></p>

	<ng-content></ng-content>
</div>
