import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class PersonalInfoService implements OnDestroy {
	private readonly storageKey = 'personalInfo';
	private _referringUrl: string;

	// May need to switch to saving variable in landingService of where the user originates from so it is saved for a longer period
	constructor(private router: Router, private sessionStorageService: SessionStorageService) {
		this._referringUrl = this.sessionStorageService.get(this.storageKey);
	}

	get referringUrl(): string {
		return `${this._referringUrl}`;
	}

	ngOnDestroy(): void {
		this.sessionStorageService.remove(this.storageKey);
	}

	saveReferringUrl(url: string): void {
		this._referringUrl = url;
		this.sessionStorageService.set(this.storageKey, url);
	}
}
