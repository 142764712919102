import { InjectionToken } from '@angular/core';
import Rollbar from 'rollbar';

const rollbarConfig = {
	captureUncaught: true,
	captureUnhandledRejections: true
};

export function rollbarFactory() {
	return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
