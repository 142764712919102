<div class="manually-add-bank" *transloco="let t">
	<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<div class="op-mtb-10">
			<div class="op-form-title op-body-1-bold-font op-mb-16">
				{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.accountType') }}
			</div>
			<mat-radio-group class="account-type-group" name="accountType" id="accountType" formControlName="accountType">
				<mat-radio-button value="CHECKING" id="accountType_checking">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.checkingAccount') }}
				</mat-radio-button>
				<mat-radio-button value="SAVING" id="accountType_saving">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.savingsAccount') }}
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="op-mtb-16">
			<div class="op-form-title op-body-1-bold-font op-mb-16">
				<op-info-tip
					id="accountDetailsTip"
					[key]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.accountDetailsTip')"
					ariaLabel="{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.accountDetails') }}"
				>
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.accountDetails') }}
				</op-info-tip>
			</div>
			<mat-form-field class="form-element op-mt-10" appearance="outline">
				<mat-label>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.routingNumber') }}</mat-label>
				<input matInput type="text" name="routingNumber" id="routingNumber" formControlName="routingNumber" />
				<mat-hint>{{ bankName }}</mat-hint>
				<mat-error
					id="routingNumberError"
					*ngIf="formGroup.controls['routingNumber'].invalid && formGroup.controls['routingNumber'].touched"
				>
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.errorMsgRouting') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element op-mt-10" appearance="outline">
				<mat-label>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.accountNumber') }}</mat-label>
				<input matInput type="text" name="accountNumber" id="accountNumber" formControlName="accountNumber" />
				<mat-error
					id="accountNumberError"
					*ngIf="formGroup.controls['accountNumber'].invalid && formGroup.controls['accountNumber'].touched"
				>
					{{ t('MANUAL_ADD_BANK.errorMsgAccount') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element op-mt-10" appearance="outline">
				<mat-label>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.confirmAccountNumber') }}</mat-label>
				<input
					matInput
					type="text"
					name="accountNumberConfirmed"
					id="accountNumberConfirmed"
					formControlName="accountNumberConfirmed"
				/>
				<mat-error
					id="accNumberConfirmedError"
					*ngIf="
						formGroup.controls['accountNumberConfirmed'].invalid && formGroup.controls['accountNumberConfirmed'].touched
					"
				>
					{{ t('MANUAL_ADD_BANK.errorMsgAccountMatch') }}
				</mat-error>
			</mat-form-field>

			<mat-checkbox
				class="op-mb-8"
				name="customerConfirms"
				id="customerConfirms"
				formControlName="customerConfirms"
				color="primary"
			>
				<span class="op-body-1-font">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.authorizationNote') }}
				</span>
			</mat-checkbox>
			<op-loan-distribution-term [issuingOrganization]="issuingOrganization"></op-loan-distribution-term>
		</div>
		<div class="op-center">
			<button
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="manualAddBankSubmitBtn"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.addBankAccount') }}
			</button>
		</div>
	</form>
</div>
