import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';

export interface ConfirmSubmitData {
	isSoftPullEnabled: string;
	instructions: string;
	isWI: boolean;
	earlyRenewalEligible: boolean;
	hasConsent: boolean;
	showWirelessConsentDisclaimer: boolean;
	noDocumentsRequired?: boolean;
}

/**
 * confirm submit dialog for document-submit
 *
 * @export
 * @class ConfirmSubmitComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-confirm-submit',
	templateUrl: './confirm-submit-dialog.component.html',
	styleUrls: ['./confirm-submit-dialog.component.scss']
})
export class ConfirmSubmitDialogComponent implements OnInit, OnDestroy {
	showWirelessConsentDisclaimer: boolean;
	issuingOrganization: string;
	private subscription = new Subscription();
	constructor(
		public dialogRef: MatDialogRef<ConfirmSubmitDialogComponent>,
		private issuingPartnerService: IssuingPartnerService,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmSubmitData
	) {}

	onSubmit(): void {
		this.dialogRef.close(true);
	}

	close(): void {
		this.dialogRef.close(false);
	}

	ngOnInit(): void {
		const issuingOrganizationSub = this.issuingPartnerService.issuingOrganization$
			.pipe(
				tap((issuingOrg) => {
					this.issuingOrganization = issuingOrg;
				})
			)
			.subscribe();
		this.subscription.add(issuingOrganizationSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	wirelessOperationsConsentToggle() {
		this.showWirelessConsentDisclaimer = !this.showWirelessConsentDisclaimer;
	}
}
