import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-esign-event-signing-timeout',
	templateUrl: './esign-event-signing-timeout.component.html',
	styleUrls: ['./esign-event-signing-timeout.component.scss']
})
export class EsignEventSigningTimeoutComponent {
	constructor(private authenticationService: AuthenticationService, private routingService: RoutingService) {}

	public signOut(): void {
		this.authenticationService.signOut();
		// TODO: Clear session storage??
		this.routingService.route(RoutingPathsEnum.home);
	}
}
