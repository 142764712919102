<div class="status" *transloco="let t">
	<op-content-container class="content">
		<op-status-requires-attention
			*ngIf="applicationStatus | opIsStatusEqual: loanStatusEnum.bounced:loanStatusEnum.requiresAttention"
			[loanApp]="loanApp"
			[showSection]="showSection"
			aria-labelledby="requires-attention-bounced"
			aria-describedby="rq-message"
		>
		</op-status-requires-attention>

		<op-status-approved
			*ngIf="applicationStatus | opIsStatusEqual: loanStatusEnum.approved"
			[loanApp]="loanApp"
			(next)="onSubmit($event)"
			aria-label="{{ t('STATUS.PRE_APPROVED.congrats') }}"
		>
		</op-status-approved>

		<op-status-pre-approved
			*ngIf="applicationStatus | opIsStatusEqual: loanStatusEnum.preApproved"
			[loanApp]="loanApp"
			[softpull]="softpull"
			[homeAddress]="homeAddress"
			(next)="onSubmit($event)"
			aria-labelledby="pre-approved-label"
			aria-describedby="{{ getAriaPreApprovedDescribedBy() }}"
		>
		</op-status-pre-approved>

		<op-status-co-app-offered
			*ngIf="applicationStatus | opIsStatusEqual: loanStatusEnum.coAppOffered"
			[loanApp]="loanApp"
			aria-labelledby="co-app-offered-label"
		>
		</op-status-co-app-offered>

		<op-status-not-approved *ngIf="applicationStatus | opIsStatusEqual: loanStatusEnum.notApproved" [loanApp]="loanApp">
		</op-status-not-approved>

		<op-status-non-processable
			*ngIf="applicationStatus | opIsStatusEqual: loanStatusEnum.nonProcessable"
			[loanApp]="loanApp"
		>
		</op-status-non-processable>

		<op-status-submitted
			*ngIf="
				applicationStatus
					| opIsStatusEqual
						: loanStatusEnum.submitted
						: loanStatusEnum.submitInProgress
						: loanStatusEnum.titleReview
						: loanStatusEnum.referenceCheck
						: loanStatusEnum.referencesVerified
						: loanStatusEnum.verified
						: loanStatusEnum.secondaryReview
			"
			[loanApp]="loanApp"
			[vwoWidget]="vwoWidget"
		>
		</op-status-submitted>

		<op-status-other *ngIf="isOtherStatus()" [loanApp]="loanApp" aria-label="initial"></op-status-other>
	</op-content-container>
</div>
