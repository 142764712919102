import { Component } from '@angular/core';

/**
 * Static page to describe accessibility
 *
 * @export
 * @class AccessibilityComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-accessibility',
	templateUrl: './accessibility.component.html',
	styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent {}
