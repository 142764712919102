import { Pipe, PipeTransform } from '@angular/core';
import { FileUploadStatusEnum, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';

/**
 * Filter file uploads for FINISHED status
 *
 * @export
 * @class FinishedFileUploadsPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opFinishedFileUploads'
})
export class FinishedFileUploadsPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		const fileUploads = value as IFileUpload[];
		return fileUploads?.filter((fileUpload) => fileUpload.status === FileUploadStatusEnum.finished) || [];
	}
}
