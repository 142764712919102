<div class="terms-confirmed" *transloco="let t">
	<op-masthead [title]="t('TERMS_CONFIRMED.loanTermsConfirmed')"></op-masthead>
	<div class="op-center">
		<img src="/assets/images/sign-document.svg" alt="Terms Confirmed" />
	</div>

	<op-content-container class="content">
		<div *ngIf="isSpl && !showChangeDisbursementOffer" class="op-mt-40">
			<div id="splTitle" class="op-header-2-font op-mb-12">{{ t('TERMS_CONFIRMED.completeYourLoan') }}</div>
		</div>
		<div *ngIf="fundingInfoLoaded && showStoreLocation && !showVerifyBankOffer">
			<op-next-steps
				id="nextSteps"
				[isSpl]="isSpl"
				[isPartnerCashDisbursement]="isPartnerCashDisbursement"
				[titleRequirementText]="titleRequirementText"
				[vehicleMakeModel]="vehicleMakeModel"
				[partnerBrand]="partnerBrand"
				(findLocation)="onFindLocation()"
			></op-next-steps>
		</div>

		<op-change-disbursement-option
			id="changeDisbursement"
			*ngIf="fundingInfoLoaded && showChangeDisbursementOffer"
			[isOnlineACHOption]="isOnlineACHOption"
			[isOnlineCheckOption]="isOnlineCheckOption"
			[isPartnerCashDisbursement]="isPartnerCashDisbursement"
			[isPartnerApplication]="isPartnerApplication"
			[showMetaBankMessage]="showMetaBankMessage"
			[partnerBrand]="partnerBrand"
			[isCheckOnly]="isCheckOnly"
			(notSaved)="notVerified($event)"
			(saved)="verified($event)"
			(findLocation)="onFindLocation()"
		></op-change-disbursement-option>
		<div *ngIf="showVerifyBankOffer" class="multipleChoice-panels">
			<ng-container *ngIf="!storeCheckEsignEnabled; else signOnlineWithCheck">
				<div class="op-header-2-font op-mb-12">{{ t('TERMS_CONFIRMED.finishOnlineHeading') }}</div>
				<op-sign-online id="signOnline" [showAddBankDesc]="showAddBankDesc">
					<div *ngIf="showBankSection">
						<div class="op-mt-30 op-mb-20">
							<div class="op-body-1-bold-font op-mb-10" *ngIf="isMicroBiltEnabled">
								{{ t('TERMS_CONFIRMED.ONLINE.choose') }}
							</div>
							<op-info-tip id="hasBankTip" [key]="t('TERMS_CONFIRMED.onlineHasBankAccountHelp')">
								<span id="funds">{{ t('TERMS_CONFIRMED.ONLINE.fundingCutoff') }}</span>
							</op-info-tip>
						</div>
						<div *ngIf="isGCPOrReturn && !isPlaidBankAccountExists && isGCPOrReturnAccountExists && !selectAccount">
							<op-select-bank-account
								id="selectPrevBankAccount"
								[selectAndContinue]="true"
								[isGCPOrReturn]="true"
								(checkVerificationStatus)="continueOnlineProcess()"
							>
							</op-select-bank-account>
						</div>

						<div *ngIf="!selectAccount" class="op-body-1-font op-mb-10">
							{{ t('TERMS_CONFIRMED.plaidConnect') }}
						</div>

						<op-plaid-extended
							id="termsPlaid"
							minimum="true"
							[bankOnly]="true"
							[sectionType]="'bank'"
							[showConnectBank]="!selectAccount"
						>
							<op-alert level="ERROR" *ngIf="showNoAccountsError && bankConnectEvent !== 'WAITING'">
								{{ 'DOCUMENT_SUBMIT.proofOfBankAccount.noAccountsError' | transloco }}
							</op-alert>
							<op-select-bank-account
								*ngIf="selectAccount"
								id="selectBankAccount"
								[filter]="bankFilter"
								[fromPlaid]="isFromPlaid"
								[postApproval]="isPostApproval"
								(checkVerificationStatus)="onBankSelectionComplete($event)"
							></op-select-bank-account>
						</op-plaid-extended>

						<op-horizontal-rule [text]="t('GLOBAL.or') | uppercase"></op-horizontal-rule>

						<div class="op-body-1-font op-mb-10" *ngIf="isMicroBiltEnabled">
							{{ t('TERMS_CONFIRMED.accountAndRouting') }}
						</div>

						<div class="op-mt-10 op-mb-40">
							<op-microbilt-bank-account
								(notSaved)="notVerified($event)"
								(saved)="verified($event)"
								*ngIf="isMicroBiltEnabled"
							>
							</op-microbilt-bank-account>
						</div>
					</div>
					<div *ngIf="showCompleteOnline">
						<button
							mat-flat-button
							color="primary"
							name="submitBtn"
							id="completeOnlineBtn"
							type="button"
							(click)="updateDisbursementChannelAndNavigate()"
						>
							{{ t('TERMS_CONFIRMED.completeOnline') }}
						</button>
					</div>
				</op-sign-online>

				<div *ngIf="showStoreLocation">
					<op-horizontal-rule [text]="t('GLOBAL.or') | uppercase"></op-horizontal-rule>

					<div class="op-mt-20">
						<op-go-to-store
							id="goToStore"
							[isPartnerCashDisbursement]="isPartnerCashDisbursement"
							[partnerBrand]="partnerBrand"
						>
						</op-go-to-store>
					</div>

					<div class="op-mt-20">
						<a
							*ngIf="!isPartnerCashDisbursement"
							href=""
							onclick="return false"
							mat-flat-button
							color="link-md"
							id="findLocationLink"
							(click)="onFindLocation()"
							[opTagClick]="'Find Locations'"
						>
							{{ t('TERMS_CONFIRMED.findLocationNearMe') }}
						</a>
					</div>
				</div>
			</ng-container>
		</div>
	</op-content-container>
	<ng-template #signOnlineWithCheck>
		<div [@.disabled]="true">
			<mat-tab-group mat-stretch-tabs="false" disablePagination="true">
				<mat-tab [label]="t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.finishOnline')" class="op-micro-font op-black">
					<ng-container *ngTemplateOutlet="finishOnline"></ng-container>
				</mat-tab>
				<mat-tab [label]="t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.finishAtStore')" class="op-micro-font">
					<ng-container *ngTemplateOutlet="finishAtStore"></ng-container>
				</mat-tab>
			</mat-tab-group>
		</div>
	</ng-template>

	<ng-template #finishOnline>
		<div class="op-mt-16 op-body-1-font">
			<p class="op-mb-16" [innerHTML]="t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.description')"></p>
			<div
				class="add-bank-container"
				(click)="showPlaidConnect()"
				*ngIf="!groupedAccountList?.size"
				[opTagClick]="'connect bank account via plaid'"
			>
				<div class="add-text op-ml-10">
					<div class="title">
						<span>{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.loginToBankAccount') }}</span>
						<div class="arrow">
							<mat-icon svgIcon="op-chevron-right"></mat-icon>
						</div>
					</div>
				</div>
				<div class="description op-label-font op-ml-10 op-mt-4">
					{{ t('PLAID_CONNECT.enterCredentialViaPlaid') }}
				</div>
			</div>
			<div
				class="add-bank-container"
				(click)="navigateToAddBankAccount()"
				[opTagClick]="'navigate to add bank account'"
				*ngIf="groupedAccountList?.size"
			>
				<div class="add-text op-ml-10">
					<div class="title">
						<span>{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.connectedBankAccount') }}</span>
						<div class="arrow">
							<mat-icon svgIcon="op-chevron-right"></mat-icon>
						</div>
					</div>
					<div *ngFor="let bankAccount of groupedAccountList | keyvalue" class="section-group op-mb-10 op-body-2-font">
						<div>{{ bankAccount.key }}</div>
						<div class="op-dark-grey op-mtb-10" *ngFor="let account of bankAccount.value">
							<div class="bank-account-info op-label-font">
								{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.TYPE.' + account.accountType) }}
								{{ account.accountNumber | opLastNNumber: '.':3 }}
							</div>
						</div>
					</div>
				</div>
				<div class="description op-label-font op-ml-10 op-mt-4"></div>
			</div>
			<div class="add-bank-container" (click)="navigateToAddManually()" [opTagClick]="'add bank account manually'">
				<div class="add-text op-ml-10">
					<div class="title">
						<span>{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.addBankAccountManually') }}</span>
						<div class="arrow">
							<mat-icon svgIcon="op-chevron-right"></mat-icon>
						</div>
					</div>
				</div>
				<div class="description op-label-font op-ml-10 op-mt-4">
					{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.enterRoutingAccountNumber') }}
				</div>
			</div>
			<op-horizontal-rule [text]="t('GLOBAL.or') | uppercase"></op-horizontal-rule>
			<div class="add-bank-container" (click)="continueWithCheck()" [opTagClick]="'continue with physic check'">
				<div class="add-text op-ml-10">
					<div class="title">
						<span>{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.continueWithPhysicCheck') }}</span>
						<div class="arrow">
							<mat-icon svgIcon="op-chevron-right"></mat-icon>
						</div>
					</div>
				</div>
				<div class="description op-label-font op-ml-10 op-mt-4">
					{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.getYourFunds') }}
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template #finishAtStore>
		<div class="op-mt-20 op-body-1-font">
			<p [innerHTML]="t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.STORE.description')"></p>
			<button
				*ngIf="!isPartnerCashDisbursement"
				mat-flat-button
				color="secondary"
				(click)="onFindLocation()"
				[opTagClick]="'Find Locations'"
			>
				{{ t('TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.STORE.findLocationNearMe') }}
			</button>
		</div>
	</ng-template>
</div>
