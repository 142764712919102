<div class="find-your-letter" *transloco="let t">
	<op-masthead [title]="t('FIND_YOUR_LETTER.title')"></op-masthead>
	<p style="margin-bottom: 20px">{{ t('FIND_YOUR_LETTER.subtitle') }}</p>
	<op-content-container class="content">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('FIND_YOUR_LETTER.phoneNumber') }}</mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					placeholder="+1 (US)(___) ___-____"
					mask="(000) 000-0000"
					name="telephone"
					id="telephone"
					formControlName="phoneNumber"
					[dropSpecialCharacters]="false"
				/>
				<mat-error id="telephoneError">
					{{ t('FIND_YOUR_LETTER.phoneNumberError') }}
				</mat-error>
			</mat-form-field>

			<div class="op-form-group">
				<div class="op-form-title">{{ t('FIND_YOUR_LETTER.dateOfBirth') | opRequired }}</div>
				<op-date-input
					name="dateOfBirth"
					id="dateOfBirth"
					formControlName="date"
					showFormat="ddmmyyyy"
					required
				></op-date-input>
				<mat-error
					id="dateOfBirthError"
					*ngIf="!formGroup.controls['date'].valid && formGroup.controls['date'].touched"
				>
					{{ t('FIND_YOUR_LETTER.dateOfBirthError') }}
				</mat-error>
			</div>

			<div class="left">
				<button
					mat-flat-button
					color="primary"
					name="findLetterBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('FIND_YOUR_LETTER.sendCode') }}
				</button>
			</div>
		</form>
	</op-content-container>

	<div style="margin-top: 20px">
		<p></p>
		<div>
			<p class="op-body-2-font" [innerHTML]="t('MULTIFACTOR.runIntoIssues')"></p>
			<p class="op-body-2-font">{{ t('MULTIFACTOR.rateMayApply') }}</p>
		</div>
	</div>
</div>
