<div
	class="message-dialog"
	[ngClass]="data?.panelClass"
	aria-labelledby="messageDialogTitle"
	*transloco="let t"
	cdkTrapFocus
	[cdkTrapFocusAutoCapture]="true"
>
	<div mat-dialog-title>
		<div class="op-right">
			<button
				mat-icon-button
				id="messageDialogCloseBtn"
				[mat-dialog-close]="data.closeAction"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close" alt="{{ t('GLOBAL.close') }}"></mat-icon>
			</button>
		</div>
		<div
			*ngIf="data?.titleBody1"
			[ngClass]="{ 'op-icon-align-body-1': data.icon }"
			class="op-black-bold op-body-1-font"
		>
			<mat-icon *ngIf="data.icon" svgIcon="{{ data.icon }}"></mat-icon>
			<span id="messageDialogTitle" *ngIf="data.title" [innerHTML]="data.title"></span>
		</div>
		<div
			*ngIf="!data?.titleBody1"
			[ngClass]="{ 'op-icon-align-header-1': data.icon }"
			class="op-black-bold op-header-1-font"
		>
			<mat-icon *ngIf="data.icon" svgIcon="{{ data.icon }}"></mat-icon>
			<span id="messageDialogTitle" *ngIf="data.title" [innerHTML]="data.title"></span>
		</div>
	</div>
	<div class="op-center" *ngIf="data.image">
		<img [src]="data.image" />
	</div>
	<div mat-dialog-content id="messageDialogContent" *ngIf="data.message" [innerHTML]="data.message"></div>
	<p></p>
	<div
		class="op-center"
		[ngClass]="{ 'cancel-btn': data.cancelText, 'display-as-flex': data.displayBtnAsFlex }"
		mat-dialog-actions
	>
		<button
			class="confirm"
			mat-flat-button
			color="primary-small"
			id="messageDlgSubmitBtn"
			(click)="onSubmit()"
			cdkFocusInitial
			aria-describedby="messageDialogContent"
		>
			{{ data.confirmText ? data.confirmText : ('GLOBAL.ok' | transloco) }}
		</button>
		<button
			*ngIf="data.cancelText"
			class="confirm"
			mat-flat-button
			color="secondary-small"
			id="messageDlgCloseBtn"
			(click)="close()"
			aria-describedby="messageDialogContent"
		>
			{{ data.cancelText ? data.cancelText : ('GLOBAL.ok' | transloco) }}
		</button>
	</div>
</div>
