import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { catchError, combineLatest, map, Observable, of, retry, zip } from 'rxjs';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import {
	DocumentStatusCriteriaEnum,
	FileStatusEnum,
	IGetIncomeResult,
	IMetadata,
	MobileApiService
} from 'src/app/core/services/mobile-api';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import AutoVerificationUtils from '../auto-verification-utils';
import { AutoVerificationService, DocBadgeEnum, IFileUploadExtended } from '../auto-verification.service';
import { BaseCategoryComponent, ProofStatusEnum } from '../base-category/base-category.component';
import { CollationService } from '../collation.service';
import { DocumentStatusEventEnum, IDocumentStatusEvent } from '../document-status/document-status.component';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';

interface IncomeSources {
	source: string;
	amount: string;
	frequency: string;
}

export interface IBankCriteriaMet {
	[key: string]: boolean;
}

@Component({
	selector: 'op-proof-of-income',
	templateUrl: './proof-of-income.component.html',
	styleUrls: ['./proof-of-income.component.scss']
})
export class ProofOfIncomeComponent extends BaseCategoryComponent implements OnInit, OnDestroy, AfterViewInit {
	readonly categoryType = FileUploadType.income;
	readonly storageKey = 'proofOfIncome';
	readonly metaClassification = MetadataEnum.IncomeDocumentClassification;

	documentClassificationList$: Observable<IMetadata[]>;
	canCancel: boolean = true;
	submitDisabled: boolean = true;

	public docBadgeEnum: typeof DocBadgeEnum = DocBadgeEnum;
	public criteriaEnum: typeof DocumentStatusCriteriaEnum = DocumentStatusCriteriaEnum;
	showCriteria: boolean = false;
	criteria: IBankCriteriaMet = {};

	incomeSources: IncomeSources[] = [];

	showContinueMessage: boolean = false;

	@ViewChildren('bankAccountUpload31Days')
	public Grid31: QueryList<DocumentUploadComponent>;
	private bankAccountUpload31Days: DocumentUploadComponent;
	singleFile31Days: IFileUploadExtended;
	day31String: string;

	@ViewChildren('bankAccountUpload65Days')
	public Grid65: QueryList<DocumentUploadComponent>;
	private bankAccountUpload65Days: DocumentUploadComponent;
	singleFile65Days: IFileUploadExtended;
	day65String: string;

	showMonthLabeling: boolean = false;

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService,
		activatedRoute: ActivatedRoute,
		autoVerificationService: AutoVerificationService,
		private tagDataService: TagDataService,
		private mobileService: MobileApiService,
		private collationService: CollationService,
		private languageService: LanguageService,
		private dateUtilsService: DateUtilsService
	) {
		super(
			fileUploadService,
			sessionStorageService,
			routingService,
			metadataService,
			loanAppService,
			autoVerificationService,
			activatedRoute
		);
		const langSub = this.languageService.langChanges$.subscribe({
			next: (lang) => {
				const locale = this.dateUtilsService.getLocale(lang);
				this.day31String = AutoVerificationUtils.get30DayString(locale);
				this.day65String = AutoVerificationUtils.get60DayString(locale);
			}
		});
		this.subscription.add(langSub);
	}

	private downloadPDF(file: File) {
		const fileUrl = window.URL.createObjectURL(file);
		const alink = document.createElement('a');
		alink.href = fileUrl;
		alink.download = 'my_collated_doc.pdf';
		alink.click();
	}

	ngAfterViewInit() {
		const documentUpload$ = combineLatest([
			this.Grid31.changes,
			this.Grid65.changes,
			this.activatedRoute.paramMap.pipe(map(() => window.history.state))
		]).subscribe({
			next: ([Grid31, Grid65, state]) => {
				this.bankAccountUpload31Days = Grid31.first;
				this.bankAccountUpload65Days = Grid65.first;
				if (state.criteria && !isEmpty(this.collationService.getFileList()) && state.upload) {
					this.collationService.createPdfDoc().subscribe({
						next: (pdf: File) => {
							if (state.criteria === DocumentStatusCriteriaEnum.zeroToThirtyDays) {
								this.bankAccountUpload31Days.uploadFileSelected(pdf);
							} else if (state.criteria === DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays) {
								this.bankAccountUpload65Days.uploadFileSelected(pdf);
							}
							// this.downloadPDF(pdf);
						}
					});
				}
			}
		});
		this.subscription.add(documentUpload$);
	}

	ngOnInit(): void {
		super.ngOnInit();

		const routeSub = this.activatedRoute.data.subscribe((rsp: any) => {
			this.showMonthLabeling = rsp?.collationEnabled;
		});
		this.subscription.add(routeSub);

		const fileSub = this.files$.subscribe({
			next: (files: IFileUpload[]) => {
				this.showCriteria = this.getShowCriteria(files, this.selectedDocumentClassification);
				this.attention = this.showAttention(this.reviewFiles, this.showCriteria, this.criteria);

				if (!isEmpty(files) && (this.isProofTypeSelection || this.isProofTypeEmpty) && !this.choosing) {
					this.proofState = ProofStatusEnum.status;
				}

				if (this.isProofTypeEmpty) {
					this.proofState = ProofStatusEnum.classificationSelection;
				}

				this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(this.selectedClassificationFiles);

				this.submitDisabled = this.checkIfSubmitDisabled(this.selectedClassificationFiles);
				this.showContinueMessage = this.checkContinueMessage(this.selectedClassificationFiles);
			}
		});
		this.subscription.add(fileSub);

		const sub = combineLatest([
			this.mobileService.getIncome(this.loanAppService.loanApplicationId).pipe(
				retry(1),
				catchError((error) => of([]))
			),
			zip(
				this.metadataService.select(MetadataEnum.IncomeSourceType),
				this.metadataService.select(MetadataEnum.IncomeFrequency)
			)
		]).subscribe({
			next: ([getIncome, [getIncomeSourceType, getIncomeFrequency]]: any) => {
				this.incomeSources = this.incomeSection(getIncome, getIncomeSourceType, getIncomeFrequency);
			}
		});
		this.subscription.add(sub);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private incomeSection(
		incomes: IGetIncomeResult[],
		incomeSourceType: IMetadata[],
		incomeFrequency: IMetadata[]
	): IncomeSources[] {
		const JOB = 1700;

		const fields = incomes.map((income) => {
			const type = incomeSourceType.find((item) => item.key === income.incomeSourceType);
			const frequency = incomeFrequency.find((item) => item.key === income.paymentFrequency)?.text;
			return type.key !== JOB
				? { source: `${type.text}`, amount: ` $${income.amount}`, frequency: ` ${frequency}` }
				: { source: `${income.company}`, amount: ` $${income.amount}`, frequency: ` ${frequency}` };
		});

		return fields;
	}

	private isContinueAvailable(files: IFileUploadExtended[]): boolean {
		return files?.every((f) => f?.progressData?.continueAvailable);
	}

	private checkContinueMessage(files: IFileUploadExtended[]): boolean {
		const isPending = files?.some((f) => f?.ocrInfo?.documentStatus === FileStatusEnum.pending);
		return isPending && this.isContinueAvailable(files);
	}

	private getShowCriteria(files: IFileUpload[], classification: IMetadata): boolean {
		this.criteria = {
			[DocumentStatusCriteriaEnum.zeroToThirtyDays]: files?.some((f) =>
				f?.ocrInfo?.criteriaMet?.some((c) => c === DocumentStatusCriteriaEnum.zeroToThirtyDays)
			),
			[DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays]: files?.some((f) =>
				f?.ocrInfo?.criteriaMet?.some((c) => c === DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays)
			)
		};

		const isBankStatement = classification?.key === 'BANK_STATEMENT';
		// const verification = AutoVerificationUtils.isLastOcrFileVerification(files);
		const someOcrDone = files?.some(
			(f) =>
				f?.ocrInfo?.documentStatus === FileStatusEnum.review || f?.ocrInfo?.documentStatus === FileStatusEnum.complete
		);

		return someOcrDone && isBankStatement;
	}

	private showAttention(files: IFileUpload[], showCriteria: boolean, criteria: any): boolean {
		return (
			Boolean(files?.length) ||
			(showCriteria &&
				(!criteria?.[DocumentStatusCriteriaEnum.zeroToThirtyDays] ||
					!criteria?.[DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays]))
		);
	}

	private tag(tag: string, action: string = 'click'): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: tag,
				event_action: action,
				event_label: 'upload',
				event_category: 'rx_form'
			}
		);
	}

	protected updateClassFiles(categoryFiles: IFileUpload[], classification: IMetadata): void {
		const modified = AutoVerificationUtils.modifyOCRAttention(categoryFiles);
		this.selectedClassificationFiles = this.filterClassificationFiles(modified, classification);
		this.reviewFiles = this.filterOcrReview(this.selectedClassificationFiles);
		this.finishedFiles = modified?.filter((file) => file?.status === FileUploadStatusEnum.finished);
		this.showCriteria = this.getShowCriteria(this.selectedClassificationFiles, classification);
		this.attention = this.showAttention(this.reviewFiles, this.showCriteria, this.criteria);
	}

	private checkIfSubmitDisabled(files: IFileUploadExtended[]): boolean {
		const finished = this.fileUploadService.isSomeFilesUploadFinished(files);
		return files?.length < 1 || !finished || !this.isContinueAvailable(files);
	}

	saveDocumentClassification(docClassification: IMetadata): void {
		this.choosing = true;
		this.selectedDocumentClassification = docClassification;
		this.proofState = ProofStatusEnum.upload;
		this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(this.selectedClassificationFiles);
		this.submitDisabled = this.checkIfSubmitDisabled(this.selectedClassificationFiles);
		this.showContinueMessage = this.checkContinueMessage(this.selectedClassificationFiles);
	}

	onContinue(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onCancel(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.route(RoutingPathsEnum.documentSubmit);
	}

	onDocStatus(statusEvent: IDocumentStatusEvent): void {
		switch (statusEvent.event) {
			case DocumentStatusEventEnum.uploadMore:
				this.saveDocumentClassification(statusEvent.documentClassification);
				this.proofState = ProofStatusEnum.upload;
				break;
			case DocumentStatusEventEnum.addMore:
				this.choosing = true;
				this.proofState = ProofStatusEnum.classificationSelection;
				break;
		}
	}

	displayedSingleFile31Days(file: IFileUploadExtended): void {
		if (file?.type === this.categoryType && file?.classification === this.selectedDocumentClassification.key) {
			this.singleFile31Days = file;
		}
	}

	displayedSingleFile65Days(file: IFileUploadExtended): void {
		if (file?.type === this.categoryType && file?.classification === this.selectedDocumentClassification.key) {
			this.singleFile65Days = file;
		}
	}
}
