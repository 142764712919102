import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-digit-offer-for-approved',
	templateUrl: './digit-offer-for-approved.component.html'
})
export class DigitOfferForApprovedComponent implements OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input() digitUrl: string;
	@Output() OnClickTriggerAction = new EventEmitter<string>();

	@Input()
	showCreateButton: boolean = false;

	@Input()
	isMobile: boolean = false;

	@Output()
	mobileService = new EventEmitter<void>();

	@Output()
	createAccount = new EventEmitter<void>();

	private subscription = new Subscription();

	public image: string;
	constructor(private languageService: LanguageService, private deviceService: DeviceDetectorService) {
		this.subscription = this.languageService.langChanges$.pipe(filter(Boolean)).subscribe({
			next: (lang) => {
				this.image = `/assets/images/digit/approved-${lang}.svg`;
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public goToDigit(): void {
		this.OnClickTriggerAction.emit('digit_offer_clicked');
		window.open(this.digitUrl, '_blank');
	}
}
