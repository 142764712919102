import { Injectable, OnDestroy } from '@angular/core';
import { MobileApiService, IPartnerLead, IPartnerLeadConsent } from '../mobile-api';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from '../storage/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class PartnerLeadService implements OnDestroy {
	private readonly partnerLeadMap: Map<number, IPartnerLead> = new Map();

	private readonly partnerLeadIdSource = new BehaviorSubject<number>(null);

	readonly partnerLeadId$: Observable<number> = this.partnerLeadIdSource.asObservable();

	private readonly subscription = new Subscription();

	private storageKey: string = 'landing.partner_lead_id';

	constructor(private mobileApiService: MobileApiService, private sessionStorageService: SessionStorageService) {
		this.subscription.add(
			this.sessionStorageService.select(this.storageKey).subscribe({
				next: (value) => {
					this.partnerLeadIdSource.next(value);
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	get partnerLeadId(): number {
		return this.partnerLeadIdSource.value;
	}

	checkPartnerLead(leadId: number): Observable<IPartnerLead> {
		return this.mobileApiService.checkPartnerLead(leadId).pipe(
			tap((partnerLead) => {
				if (
					partnerLead?.validLead &&
					!partnerLead.loanApplicationExists &&
					partnerLead.leadId &&
					partnerLead.phoneType &&
					partnerLead.phoneNumber
				) {
					this.sessionStorageService.set(this.storageKey, partnerLead.leadId);
				}
			})
		);
	}

	clearPartnerLead(): void {
		this.sessionStorageService.remove(this.storageKey);
	}

	getPartnerLeadDetails(): Observable<IPartnerLead> {
		if (this.partnerLeadMap.has(this.partnerLeadId)) {
			return of(this.partnerLeadMap.get(this.partnerLeadId));
		}
		return this.mobileApiService.getPartnerLeadDetails(this.partnerLeadId).pipe(
			tap((partnerLead: IPartnerLead) => {
				this.partnerLeadMap.set(this.partnerLeadId, partnerLead);
				this.sessionStorageService.set('addressComp', {
					homeAddress: {
						type: partnerLead.addressType,
						address1: partnerLead.address1,
						address2: partnerLead.address2,
						city: partnerLead.city,
						zipCode: partnerLead.postalCode,
						state: partnerLead.state
					},
					date: null,
					mailSameAsHome: true,
					mailAddress: null
				});
			})
		);
	}

	updatePartnerFinderConsent(partnerLeadConsent: IPartnerLeadConsent): Observable<IPartnerLead> {
		return this.mobileApiService.updatePartnerFinderConsent(partnerLeadConsent).pipe(
			tap((partnerLead: IPartnerLead) => {
				this.partnerLeadMap.set(partnerLead.leadId, partnerLead);
			})
		);
	}
}
