import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

export interface IPaymentDate {
	biWeeklyDate: number;
	semiMonthlyPaymentDate: number;
	monthlyDate: number;
}

export enum PaymentFrequencyEnum {
	'BI_WEEKLY' = 14,
	'SEMI_MONTHLY' = 15,
	'MONTHLY' = 30
}

@Component({
	selector: 'op-adjust-payment-date',
	templateUrl: './payment-date.component.html',
	styleUrls: ['./payment-date.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PaymentDateComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: PaymentDateComponent
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentDateComponent implements OnInit, OnDestroy {
	// 	SEMI_MONTHLY: ['SEMI_MONTHLY'], //15
	// 	Biweekly: ['BI_WEEKLY'], //14
	// 	Monthly: ['MONTHLY'] //30
	@Input()
	@opRequired()
	id: string;

	@Input()
	paymentFrequency: number;

	@Input()
	monthlyPaymentDates: string[];

	paymentFrequencyEnum = PaymentFrequencyEnum;

	formGroup: FormGroup;

	paymentDateTitle: string;
	weekdays;
	monthDays;
	defaultSemiMonthlyDate: number;

	private subscription = new Subscription();

	constructor(private formBuilder: FormBuilder, private translocoService: TranslocoService) {
		this.formGroup = this.formBuilder.group({
			biWeeklyDate: [null],
			semiMonthlyPaymentDate: [null],
			monthlyDate: [null]
		});

		const keyParams = {
			'ADJUST_TERMS.WEEKDAYS': null,
			'ADJUST_TERMS.MONTHDAYS': null
		};
		const translocoSub = this.translocoService.selectTranslateObject(keyParams).subscribe({
			next: ([weekDay, monthDays]) => {
				this.weekdays = [
					{ key: weekDay.day1key, text: weekDay.day1text, title: weekDay.title1 },
					{ key: weekDay.day2key, text: weekDay.day2text, title: weekDay.title2 },
					{ key: weekDay.day3key, text: weekDay.day3text, title: weekDay.title3 },
					{ key: weekDay.day4key, text: weekDay.day4text, title: weekDay.title4 },
					{ key: weekDay.day5key, text: weekDay.day5text, title: weekDay.title5 },
					{ key: weekDay.day6key, text: weekDay.day6text, title: weekDay.title6 },
					{ key: weekDay.day7key, text: weekDay.day7text, title: weekDay.title7 }
				];

				this.monthDays = [
					{ text: monthDays.monthDt1text },
					{ text: monthDays.monthDt2text },
					{ text: monthDays.monthDt3text },
					{ text: monthDays.monthDt4text },
					{ text: monthDays.monthDt5text },
					{ text: monthDays.monthDt6text },
					{ text: monthDays.monthDt7text },
					{ text: monthDays.monthDt8text },
					{ text: monthDays.monthDt9text },
					{ text: monthDays.monthDt10text },
					{ text: monthDays.monthDt11text },
					{ text: monthDays.monthDt12text },
					{ text: monthDays.monthDt13text }
				];
				this.paymentDateTitle = this.getPaymentDateTitle(this.formGroup.get('biWeeklyDate').value);
			}
		});
		this.subscription.add(translocoSub);

		this.formGroup.valueChanges.subscribe({
			next: (val) => {
				this.onChange(val);
				this.paymentDateTitle = this.getPaymentDateTitle(val.biWeeklyDate);
				this.defaultSemiMonthlyDate = val.semiMonthlyDate;
			}
		});
	}

	getPaymentDateTitle(key: string): string {
		return this.weekdays.find((item) => item.key === key)?.title;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get value(): IPaymentDate | null {
		return this.formGroup.value;
	}
	set value(paymentDate: IPaymentDate | null) {
		if (paymentDate) {
			this.formGroup.patchValue(paymentDate);
			this.onChange(paymentDate);
		}
	}

	onChange = (change) => {
		// This is intentional empty
	};
	onTouched = () => {
		// This is intentional empty
	};
	touched = false;
	disabled = false;

	ngOnInit(): void {
		// This is intentional empty
	}

	// Begin ControlValueAccessor interface
	writeValue(value: IPaymentDate): void {
		this.value = value;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	// End ControlValueAccessor interface

	// Begin Validator interface
	validate(control: AbstractControl): ValidationErrors | null {
		return null;
	}
	// End Validator interface
}
