<div
	class="esign-event-signing-declined"
	*transloco="let t"
	aria-labelledby="declined-label"
	aria-describedby="declined-message"
>
	<op-masthead id="declined-label" [title]="t('ESIGN.EVENT.SIGNING_DECLINED.title')"></op-masthead>
	<op-content-container class="content">
		<p [innerHTML]="t('ESIGN.EVENT.SIGNING_DECLINED.message')" id="declined-message"></p>

		<button mat-flat-button color="primary" id="closeBtn" (click)="signOut()">
			{{ t('GLOBAL.close') }}
		</button>
	</op-content-container>
</div>
