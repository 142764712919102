<div class="identity-confirm" *transloco="let t">
	<op-masthead [title]="t('IDENTITY_CONFIRM.title')"></op-masthead>
	<op-content-container class="content">
		<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div class="left">
				<mat-checkbox
					name="sameAsCurrent"
					formControlName="sameAsCurrent"
					id="sameAsCurrent"
					(change)="onCheckChange($event)"
					color="primary"
				>
					{{ t('IDENTITY_CONFIRM.currentAddress') }}
				</mat-checkbox>
			</div>

			<div class="mail-address">
				<op-address-detail-form id="identity" formControlName="addressDetail"></op-address-detail-form>
			</div>

			<div class="left">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
