import { Component, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { format } from 'date-fns';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';
import { phoneValidator } from 'src/app/shared/validators/form-validators';
import { ActivatedRoute } from '@angular/router';
import { IFindELettersResult } from '../../core/services/mobile-api/mobile-api.model';

@Component({
	selector: 'op-find-your-letter',
	templateUrl: './find-your-letter.component.html',
	styleUrls: ['./find-your-letter.component.scss']
})
export class FindYourLetterComponent implements AfterViewInit {
	formGroup: FormGroup;
	token: string;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private landingService: LandingService,
		private dateUtilService: DateUtilsService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private nidService: NeuroIdService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private activatedRoute: ActivatedRoute
	) {
		this.createForm(this.formBuilder);
	}

	ngAfterViewInit(): void {
		this.token = this.activatedRoute.snapshot.queryParams?.token;
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			date: [null, Validators.required],
			phoneNumber: ['', [Validators.required, phoneValidator()]]
		});
	}

	openErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('FIND_YOUR_LETTER.ERROR_MODAL.title'),
				this.translocoService.translate('FIND_YOUR_LETTER.ERROR_MODAL.message')
			)
			.subscribe();
	}

	onSubmit(post: any): void {
		const { date, phoneNumber, ...body } = post;
		body.dateOfBirth = format(date, 'yyyy-MM-dd');
		body.phoneNumber = PhoneUtils.formatPhoneNumber(phoneNumber);
		body.token = this.token;
		let eventLabel = 'not_found';

		this.landingService.searchLetter(body).subscribe({
			next: (resp: IFindELettersResult) => {
				if (Boolean(resp?.success)) {
					eventLabel = 'e_letter_application';
					resp.data.completePhoneNumber = resp.data.phoneNumber;
					resp.data.phoneNumber = resp?.data?.phoneNumber.slice(-4);

					this.sessionStorageService.set('letterType', resp?.data?.letterType);
					this.sessionStorageService.set('letterMetadataId', resp?.data?.letterMetadataId);
					this.sessionStorageService.set('letterLoanApplicationId', resp?.data?.loanApplicationId);
					this.sessionStorageService.set('letterApplicantId', resp?.data?.applicantId);

					this.landingService.updateFindELetters(resp);
					this.routingService.route(RoutingPathsEnum.multifactor);
				} else {
					this.openErrorMessage();
				}

				this.tagDataService.link(
					{
						phoneNumber: resp?.data?.phoneNumber
					},
					{
						tealium_event: 'find_your_letter',
						event_category: 'CONSUMER_INSTALLMENT_LOAN',
						event_label: eventLabel
					}
				);
			},
			error: (err) => {
				//send a error msg to tealium
				this.tagDataService.link(
					{
						phoneNumber: body.phoneNumber,
						token: body.token,
						dateOfBirth: body.dateOfBirth
					},
					{
						tealium_event: 'find_your_letter',
						event_category: 'CONSUMER_INSTALLMENT_LOAN',
						event_action: 'e_letter_application_error',
						event_label: err?.error[0]?.msg?.substring(0, 1000)
					}
				);
				this.openErrorMessage();
			}
		});
	}
}
