import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { InstrumentationService } from 'src/app/core/services/instrumentation/instrumentation.service';

import { EnvironmentService } from '../services/environment/environment.service';
import { SessionStorageService } from '../services/storage/session-storage.service';

// NOTE: depending on loanAppService causes a circular dependency from the interceptor.
// Get the loanAppId from sessionStorage in the interceptor.
@Injectable()
export class InstrumentationInterceptor implements HttpInterceptor {
	// NOTE: should match key in loanAppService
	private readonly storageKey = 'loanId';

	constructor(
		private instrumentationService: InstrumentationService,
		private environmentService: EnvironmentService,
		private sessionStorageService: SessionStorageService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let instrument = null;
		if (request.url.includes(this.environmentService.apiUrl)) {
			instrument = this.instrumentationService.getInstrumentationValues();
			instrument.opUuid = this.instrumentationService.getUuid();
			instrument.opPageId = encodeURIComponent(window.location.pathname.substring(1));
			const loanAppId = this.sessionStorageService.get(this.storageKey);
			if (loanAppId) {
				instrument.opProductId = loanAppId;
			}
			this.instrumentationService.setKeys(instrument);
		}

		const cloneReq = isEmpty(instrument) ? request.clone() : request.clone({ setParams: { ...instrument } });
		return next.handle(cloneReq);
	}
}
