import { ApplicationFlowEnum } from '../mobile-api';
import { IApplicant } from './applicant/applicant.model';
import { DisbursementChannelEnum, DisbursementType, IDisbursement } from './disbursement/disbursement.model';
import { IFundingInfo } from './funding-info/funding-info.model';
import IProduct, { paymentFrequencyEnum } from './product-offer/product/product.model';

export enum ApplicationStatusEnum {
	started = 'STARTED',

	approved = 'APPROVED',
	bounced = 'BOUNCED',
	coAppDeclined = 'CO_APP_DECLINED',
	coAppOffered = 'CO_APP_OFFERED',
	nonProcessable = 'NON_PROCESSABLE',
	other = 'OTHER',
	preApproved = 'PRE_APPROVED',
	prequalAccepted = 'PREQUAL_ACCEPTED',
	referenceCheck = 'REFERENCE_CHECK',
	referencesVerified = 'REFERENCES_VERIFIED',
	requiresAttention = 'REQUIRES_ATTENTION',
	securedAccepted = 'SECURED_ACCEPTED',
	securedDeclined = 'SECURED_DECLINED',
	securedOffered = 'SECURED_OFFERED',
	submitted = 'SUBMITTED',
	submitInProgress = 'SUBMIT_IN_PROGRESS',
	titleReview = 'TITLE_REVIEW',
	notApproved = 'NOT_APPROVED',
	termsConfirmed = 'TERMS_CONFIRMED',

	futureProspect = 'FUTURE_PROSPECT',
	documentsSigned = 'DOCUMENTS_SIGNED',
	disbursed = 'DISBURSED',
	verified = 'VERIFIED',
	secondaryReview = 'SECONDARY_REVIEW'
}

export const ValidApplicationStateForOfferStatus = [
	ApplicationStatusEnum.approved,
	ApplicationStatusEnum.preApproved,
	ApplicationStatusEnum.securedAccepted,
	ApplicationStatusEnum.securedOffered
];

export enum ApplicationTypeEnum {
	new = 'NEW',
	earlyRenewal = 'EARLY_RENEWAL',
	gcp = 'GCP',
	renewal = 'RENEWAL'
}

export interface ILoanApplicationStatus {
	applicationStatus: ApplicationStatusEnum;
}

export interface ILoanApplication {
	id: number;
	productType: string;
	productCategory: string;
	applicationType: ApplicationTypeEnum;
	applicationTypeAcknowledged: boolean;
	createdAt: string;
	updatedAt: string;
	originationSource: string;
	leadOfferId?: null;
	applicationStatus: ApplicationStatusEnum;
	partnerId?: number | null;
	finalApprovedTerms: IFinalApprovedTerms;
	finderNumber?: null;
	finderNumberZipCode?: null;
	loanPurpose: number;
	hearAboutUs?: null;
	hearAboutUsOther?: null;
	applicants?: IApplicant[];
	isCanceledByClient: boolean;
	issuingOrganization: string;
	isSplStorelessEligible?: boolean;
	cancelReason?: null;
	sipPfLoan?: null;
	documents?: null[] | null;
	preApprovalProcessComplete: boolean;
	verificationProcessComplete: boolean;
	referenceCheckProcessComplete: boolean;
	notificationProcessComplete: boolean;
	disbursementProcessComplete: boolean;
	bounceReasons?: null[] | null;
	fundingInfo: IFundingInfo;
	loanTerms: ILoanTerms;
	preApprovedTerms?: PreApprovedTermsEntity[] | null;
	marketingSource?: null;
	disbursement: IDisbursement;
	seedNumber: string;
	productOfferDetails?: IProduct[];
	declineCodes?: null[] | null;
	applicationFlow: ApplicationFlowEnum;
	originationChannelOrganization?: string;
	rachWithoutAchOptInEligible?: boolean;
	partnerSource?: string | null;
}

export interface IDisbursementOptionsEntity {
	channel: DisbursementChannelEnum;
	partner: string;
	type: DisbursementType;
	isMaxCashAmountExceeded?: boolean;
}

export type PreApprovedTermsEntity = IProduct;

export interface IShowBounceAttention {
	count: number;
	referencesOnly: boolean;
	showPhotoIdItem?: string[];
	showAddressItem?: string[];
	showIncomeItem?: string[];
	showReferencesItem?: string[];
	showBankAccountItem?: string[];
	showPhotoItem?: string[];
	showVehiclePhotos?: string[];
	showVehicleRegistration?: string[];
	showVehicleDriversLicense?: string[];
	showVehicleItem?: string[];
	showDMVReqIdProof?: string[];
}

export interface IFinalApprovedTerms {
	minApprovedAmount: number;
	maxApprovedAmount: number;
	paymentAmount: number;
	maxPaymentAmount: number;
	downpaymentPercentage?: null;
	paymentFrequency: string;
	paymentTerm: number;
	paymentDay?: null;
	paymentDate1: number;
	paymentDate2: number;
	product: string;
	apr: number;
	realApr: number;
	numberOfPayments: number;
	originationFee: number;
	feeDescription1?: null;
	feeAmount1?: null;
	productCategory: string;
}

export interface ILoanTerms {
	purchaseAmount?: null;
	loanAmount: number;
	downPayment?: null;
	totalTax: number;
	paymentFrequency: paymentFrequencyEnum;
	paymentAmount: number;
	paymentTerm: number;
	paymentDay?: null;
	paymentDate1: number;
	paymentDate2: number;
	printedAt?: null;
	esignedAt?: null;
	disbursementDate: string;
	apr: number;
	realApr: number;
	termsExpired: boolean;
	numberOfPayments: number;
	feeType: string;
	originationFee: number;
	feeDescription1?: null;
	feeAmount1?: null;
	previousPfLoan?: null;
	payoffAmount?: null;
	disbursementAmount: number;
	firstPaymentDate?: null;
	productCategory: string;
}

export const EditableSectionsList = [
	{ key: 0, text: 'IDENTIFICATION' },
	{ key: 1, text: 'RESIDENCE' },
	{ key: 2, text: 'INCOME' },
	{ key: 3, text: 'REFERENCES' },
	{ key: 4, text: 'DISBURSEMENT' },
	{ key: 5, text: 'PHOTO' },
	{ key: 6, text: 'VEHICLE_PHOTO' },
	{ key: 7, text: 'VEHICLE_REGISTRATION' },
	{ key: 8, text: 'VEHICLE_DRIVERS_LICENSE' }
];

export const EditableSectionMap = {
	0: 'showPhotoIdItem',
	1: 'showAddressItem',
	2: 'showIncomeItem',
	3: 'showReferencesItem',
	4: 'showBankAccountItem',
	5: 'showPhotoItem',
	6: 'showVehiclePhotos',
	7: 'showVehicleRegistration',
	8: 'showVehicleDriversLicense'
};

export enum BounceReasonsEnum {
	residence = 'RESIDENCE',
	identification = 'IDENTIFICATION',
	income = 'INCOME',
	disbursement = 'DISBURSEMENT',
	references = 'REFERENCES',
	photo = 'PHOTO',
	vehicleDriverLicence = 'VEHICLE_DRIVERS_LICENSE',
	vehiclePhoto = 'VEHICLE_PHOTO',
	vehicleRegistration = 'VEHICLE_REGISTRATION'
}
