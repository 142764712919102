<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="addressPanel"
		title="{{ t('DOCUMENT_SUBMIT.proofOfAddress.title') }}"
		[fileList]="fileList"
		[expanded]="expanded"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
	>
		<div class="panel-content">
			<div class="op-header-3-font">{{ t('DOCUMENT_SUBMIT.proofOfAddress.subtitle') }}</div>
			<ul>
				<li>{{ t('DOCUMENT_SUBMIT.proofOfAddress.requirements.billType') }}</li>
				<li>{{ t('DOCUMENT_SUBMIT.proofOfAddress.requirements.visibleFields') }}</li>
				<li>{{ t('DOCUMENT_SUBMIT.proofOfAddress.requirements.noPOBox') }}</li>
			</ul>
			<op-file-upload
				id="addressFileUpload"
				[categoryType]="fileType"
				requiredFileType="application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/bmp"
			>
			</op-file-upload>
		</div>
	</op-document-expansion-panel>
</div>
