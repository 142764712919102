import { Component, Input } from '@angular/core';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

import { DocBadgeEnum } from '../auto-verification.service';

@Component({
	selector: 'op-document-section',
	templateUrl: './document-section.component.html',
	styleUrls: ['./document-section.component.scss']
})
export class DocumentSectionComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	icon: string;

	@Input()
	categoryType: FileUploadType;

	@Input()
	title: string;

	@Input()
	description: string;

	@Input()
	bounceAttention: boolean = false;

	@Input()
	badge: DocBadgeEnum = DocBadgeEnum.idle;

	@Input()
	count: number;

	public docBadgeEnum: typeof DocBadgeEnum = DocBadgeEnum;

	constructor() {}
}
