<div
	class="esign-event-signing-canceled"
	*transloco="let t"
	aria-labelledby="cancel-label"
	aria-describedby="cancel-message"
>
	<op-masthead id="cancel-label" [title]="t('ESIGN.EVENT.SIGNING_CANCELED.title')"></op-masthead>
	<op-content-container class="content">
		<p [innerHTML]="t('ESIGN.EVENT.SIGNING_CANCELED.message')" id="cancel-message"></p>
		<p [innerHTML]="t('ESIGN.EVENT.SIGNING_CANCELED.action')"></p>

		<button mat-flat-button color="primary" id="signOutBtn" (click)="signOut()">
			{{ t('GLOBAL.close') }}
		</button>
	</op-content-container>
</div>
