import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MobileApiService } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable({
	providedIn: 'root'
})
export class PlaidRefreshGuard implements CanActivate {
	constructor(
		private loanAppService: LoanApplicationService,
		private mobileApiService: MobileApiService,
		private routingService: RoutingService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.updateLoanApplication().pipe(
			switchMap((loanApp) => {
				return this.mobileApiService.getPlaidRefreshEligibility(loanApp.id).pipe(
					map((rsp) => {
						if (rsp.eligible) {
							return true;
						} else {
							this.routingService.routeFromLoanApp();
							return false;
						}
					})
				);
			})
		);
	}
}
