<div class="product-offer-card" *transloco="let t">
	<mat-card id="splEligible">
		<mat-card-content>
			<p class="loan-range-p title op-header-2-font">{{ t('ADJUST_TERMS.LOAN_TYPES.securedPersonalLoan') }}</p>
			<p class="op-body-2-font">{{ t('OFFER_STATUS.splVehicleRequiredNote') }}</p>

			<p></p>
			<div class="larger-loan-note-div">
				<div class="op-header-3-font op-mb-5" *ngIf="!isSplOnlyLoan">{{ t('OFFER_STATUS.needLargerLoan') }}</div>
				<div>{{ t('OFFER_STATUS.carTitleForSPL') }}</div>
			</div>

			<p></p>
			<div class="loan-range-note-div">
				<div class="loan-range-p op-mb-5 op-header-3-font">{{ t('OFFER_STATUS.splLoanRange') }}</div>
				<ul class="spl-loan-advantage">
					<li>{{ t('OFFER_STATUS.LOAN_ADVANTAGE.lowerAPR') }}</li>
					<li *ngIf="isSplOnlyLoan">{{ t('OFFER_STATUS.LOAN_ADVANTAGE.flexiblePaymentOptions') }}</li>
					<li *ngIf="!isSplOnlyLoan">{{ t('OFFER_STATUS.LOAN_ADVANTAGE.betterChanceText') }}</li>
					<li>
						{{ t('OFFER_STATUS.LOAN_ADVANTAGE.forMoreInfo') }}
						<a
							mat-flat-button
							color="link-md"
							class="confirm-button"
							id="forMoreInfoHere"
							href="https://oportun.com/secured-personal-loans/"
							target="_blank"
						>
							{{ t('OFFER_STATUS.LOAN_ADVANTAGE.forMoreInfoHere') }}
						</a>
					</li>
				</ul>
			</div>

			<div>
				<button
					mat-flat-button
					color="primary-small"
					class="confirm-button padding-for-long-title op-full-width"
					id="splCheckVehicleBtn"
					(click)="onContinue('vehicleEligibilityQuestion')"
				>
					{{ t('OFFER_STATUS.checkVehicleEligibility') }}
				</button>
			</div>
			<div class="offer-status-info-url">
				<a
					mat-flat-button
					color="link-md"
					class="confirm-button"
					id="splOfferStatusInfoLink"
					(click)="goToOfferStatusInformation()"
				>
					{{ t('OFFER_STATUS.SECURED_OFFERED.customerNotSureAboutOffer') }}
				</a>
			</div>
			<div class="op-mt-20 op-form-info op-gray" *ngIf="isSplOnlyLoan">
				{{ t('OFFER_STATUS.SECURED_OFFERED.qualificationStatement') }}
			</div>
		</mat-card-content>
	</mat-card>
</div>
