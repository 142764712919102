<div class="plaid-link-ext" *transloco="let t">
	<div class="add-text">
		<div class="benefit" *ngIf="showBenefits">
			<p class="op-body-1-bold-font">{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.benefit') }}</p>
			<div class="benefit-item op-mtb-16">
				<div class="benefit-title op-mb-4 op-icon-align-body-1">
					<mat-icon svgIcon="op-history"></mat-icon>
					<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.expeditedFunds.title') }}</span>
				</div>
				<div class="benefit-description op-gray">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.expeditedFunds.description') }}
				</div>
			</div>
			<div class="benefit-item op-mtb-16">
				<div class="benefit-title op-mb-4 op-icon-align-body-1">
					<mat-icon svgIcon="op-refreshDouble"></mat-icon>
					<span [innerHTML]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.fewerDoc.title')"></span>
				</div>
				<div class="benefit-description op-gray">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.fewerDoc.description') }}
				</div>
			</div>
			<div class="benefit-item op-mt-16">
				<div class="benefit-title op-mb-4 op-icon-align-body-1">
					<mat-icon svgIcon="op-ascend"></mat-icon>
					<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.higherAmount.title') }}</span>
				</div>
				<div class="benefit-description op-gray">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.higherAmount.description') }}
				</div>
			</div>
			<div class="benefit-item op-mt-4 op-mb-12 op-label-font op-gray">
				{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.subjectToLimits') }}
			</div>
			<hr class="op-gray" />
		</div>
		<div class="description op-label-font">
			<div class="op-mt-8 op-mb-20 op-gray">
				<p class="policy" [innerHTML]="t('PLAID_CONNECT.youAgreePlaidAccess')"></p>
				<p class="policy" [innerHTML]="t('PLAID_CONNECT.reviewPlaidTerms')"></p>
			</div>
		</div>
	</div>

	<div class="op-center plaid-button op-mt-20">
		<button
			mat-flat-button
			[color]="color"
			id="{{ id + '_plaidOpenBtn' }}"
			[disabled]="isBankConnectAvailable"
			(click)="openPlaid()"
		>
			<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.connectNew') }}</span>
			<mat-icon svgIcon="op-popout" class="op-ml-10"></mat-icon>
		</button>
	</div>
	<op-website-terms class="op-mt-12 op-mb-20"></op-website-terms>
</div>
