<div class="file-upload" *transloco="let t">
	<input
		type="file"
		class="file-input"
		id="{{ id + '_file' }}"
		[accept]="requiredFileType"
		(change)="onFileSelected($event)"
		#fileUpload
	/>

	<p class="thumbnail-title" *ngIf="filesType?.length">
		<strong>{{ t('DOCUMENT_SUBMIT.selectedDocuments') }}</strong>
	</p>

	<op-file-thumbnails id="{{ id + '_fileThumbnails' }}" [files]="filesType">
		<button mat-flat-button color="secondary" id="{{ id + '_fileAdd' }}" (click)="fileUpload.click()">
			<mat-icon svgIcon="op-add"></mat-icon>
		</button>
		<div>{{ t('DOCUMENT_SUBMIT.selectMore') }}</div>
	</op-file-thumbnails>

	<div class="file-upload-button" *ngIf="!filesType?.length">
		<button mat-flat-button color="secondary" id="{{ id + '_fileUploadBtn' }}" (click)="fileUpload.click()">
			{{ t('FILE_UPLOAD.addDocuments') }}
		</button>
	</div>
</div>
