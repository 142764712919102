import { Component, OnInit, ViewChild } from '@angular/core';
import { catchError, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ConfigApiService, MobileApiService } from 'src/app/core/services/mobile-api';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { OrganizationEnum, OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { CheckDeliveryNoticeComponent } from 'src/app/shared/components/dialogs/check-delivery-notice/check-delivery-notice.component';

import { FundsCheckComponent } from './fundsCheck/fundsCheck.component';

@Component({
	selector: 'op-funds',
	templateUrl: './funds.component.html',
	styleUrls: ['./funds.component.scss']
})
export class FundsComponent implements OnInit {
	@ViewChild('checkComponent') checkComponent: FundsCheckComponent;

	constructor(
		private routingService: RoutingService,
		private loanAppService: LoanApplicationService,
		private dialogService: DialogService,
		private mobileService: MobileApiService,
		private bankAccountService: AchBankAccountsService,
		private tagDataService: TagDataService,
		private configService: ConfigApiService
	) {}

	private subscription = new Subscription();

	bankAccountList$: Observable<IAchBankAccount[]>;
	returningBankAccountList$: Observable<IAchBankAccount[]>;
	accountList: IAchBankAccount[] = [];
	isCheckAtStore = false;
	issuingOrganization: string = OrganizationEnum.OPORTUN.toLowerCase();

	initialized: boolean;
	showNewDesign = undefined;

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				switchMap((loanApp: ILoanApplication) => {
					return forkJoin({
						fundingInfo: of(FundingInfoUtils.fromLoanApp(loanApp)),
						issuingOrganization: of(loanApp.issuingOrganization)
					});
				})
			)
			.subscribe({
				next: ({ fundingInfo, issuingOrganization }) => {
					this.issuingOrganization = issuingOrganization?.toLowerCase();
					this.isCheckAtStore =
						fundingInfo.isCoverageAreaWithinRadius() && !OrganizationUtils.isMetaBank(issuingOrganization);
				}
			});

		this.subscription.add(loanAppSub);

		this.bankAccountList$ = this.bankAccountService.bankAccounts$;
		this.returningBankAccountList$ = this.bankAccountService.returningBankAccounts$;
		const bankSub = combineLatest([this.bankAccountList$, this.returningBankAccountList$])
			.pipe(map(([rsp, returningRsp]) => rsp?.concat(returningRsp) || []))
			.subscribe({
				next: (rsp) => {
					this.accountList = rsp;
					this.initialized = true;
				}
			});
		this.subscription.add(bankSub);

		this.getConfigValue();
	}

	onCheckSelection(): void {
		this.openDenyAchDisbursementDialog()
			.pipe(
				switchMap((result) =>
					result ? this.mobileService.updateDisbursementAch(false, this.loanAppService.loanApplicationId) : of(null)
				)
			)
			.subscribe({
				next: (resp) => resp && this.routingService.routeBtm()
			});
	}

	onDisbursementSelection(event): void {
		if (event.id === 'check') {
			this.onCheckSelection();
		} else {
			const bank = {
				bankAccountId: event.id,
				customerConfirms: true
			};
			this.bankAccountService
				.selectBankAccount(bank)
				.pipe(
					take(1),
					switchMap(() => this.mobileService.updateDisbursementAch(true, this.loanAppService.loanApplicationId))
				)
				.subscribe({
					next: () => {
						this.routingService.routeBtm();
					}
				});
		}
	}

	onAddBank(event): void {
		const extra = { state: { hidePlaid: Boolean(this.accountList.length) } };
		this.routingService.route(RoutingPathsEnum.bankVerification, extra);
	}

	private openDenyAchDisbursementDialog(): Observable<any> {
		if (OrganizationUtils.isMetaBank(this.loanAppService.getLoanApp().issuingOrganization)) {
			return this.dialogService.openSimpleDialog(CheckDeliveryNoticeComponent);
		} else {
			return of(true);
		}
	}

	getConfigValue(): void {
		this.configService
			.showNewFundingDesign(this.loanAppService.loanApplicationId)
			.pipe(
				map((res) => res.result),
				catchError((err) => {
					return of(false);
				})
			)
			.subscribe((value) => {
				this.showNewDesign = value;
				const tagEvent = {
					event_action: 'on_page_load',
					applicant_id: this.loanAppService.loanApplicationId,
					issuing_org: this.loanAppService.getLoanApp()?.issuingOrganization,
					clientId: this.loanAppService.getCurrentApplicant()?.clientId,
					show_new_design: String(this.showNewDesign)
				};
				this.tagDataService.view({}, tagEvent);
			});
	}
}
