import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';

@Component({
	selector: 'op-status-submitted',
	templateUrl: './submitted.component.html',
	styleUrls: ['./submitted.component.scss']
})
export class SubmittedComponent implements OnInit {
	private _loanApp: ILoanApplication;
	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
	}

	@Input()
	vwoWidget: boolean = false;

	constructor(private dialogService: DialogService) {}

	subTitle: string;
	body: string;

	ngOnInit(): void {
		this.subTitle = `STATUS.${this.loanApp.applicationStatus}.initial`;
		this.body = `STATUS.${this.loanApp.applicationStatus}.body`;
	}

	contactUs(): void {
		this.dialogService.openContactUsDialog(Boolean(this.loanApp.id)).subscribe();
	}
}
