<div class="op-mtb-20 debit-card-faq-dialog" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button mat-icon-button id="debitCardFaqCloseBtn" [mat-dialog-close]="false" aria-label="{{ t('GLOBAL.close') }}">
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-1-font" id="debitCardFaqHeading">
			<strong>{{ t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.title') }}</strong>
		</div>
	</div>
	<div mat-dialog-content>
		<op-debit-card-faq></op-debit-card-faq>
	</div>
</div>
