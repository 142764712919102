<div class="op-how-it-works-panel" *transloco="let t">
	<op-expansion-panel
		[expanded]="false"
		id="expansionPanel"
		[titleBold]="false"
		[title]="t('HOME.HOW_IT_WORKS.howItWorks')"
	>
		<op-stepper></op-stepper>
	</op-expansion-panel>
</div>
