<div class="op-attention-item">
	<h4>
		<div id="{{ id + '_Text' }}">{{ itemText | transloco }}</div>
		<div id="{{ id + '_SubText' }}" *ngIf="itemSubText">
			{{ itemSubText | transloco }}
		</div>
	</h4>

	<div>
		<ul *ngIf="attentionReasons">
			<li id="{{ id + '_reason' }}" class="attention" *ngFor="let reason of attentionReasons">{{ reason }}</li>
		</ul>
	</div>
</div>
