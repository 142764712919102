import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import * as Castle from '@castleio/castle-js';
import { from, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CastleService {
	constructor(private environmentService: EnvironmentService) {
		const castlePublicKey = environmentService.isProduction
			? 'pk_pmarYeQdVe1TZdyzj3px4pySMht3ur3L'
			: 'pk_Zv9wzP7SVYJmZ7CPvvDqoejygny3Dm6h';
		Castle.configure({ pk: castlePublicKey });
	}

	getRequestToken(): Observable<string> {
		return from(this.getCastleToken());
	}

	private async getCastleToken(): Promise<string> {
		try {
			return await Castle.createRequestToken();
		} catch (error) {
			return Promise.resolve(null);
		}
	}
}