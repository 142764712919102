import { Injectable } from '@angular/core';
import { isFunction } from 'lodash';

import { WindowRefService } from '../window-ref/window-ref.service';
import { NewRelicMonitorService } from './new-relic-monitor.service';

@Injectable({
	providedIn: 'root'
})
export class NewRelicInteractionService {
	constructor(private windowService: WindowRefService, private newRelicMonitorService: NewRelicMonitorService) {}

	/**
	 * Save the interaction to New Relic
	 * Interaction is appended with the flowName,
	 *
	 * @param {string} [interactionName]
	 * @return {*}  {*}
	 * @memberof NewRelicInteractionService
	 */
	public saveInteraction(interactionName?: string): any {
		if (isFunction(this.windowService.window?.['NREUM'].interaction().save)) {
			let interaction = this.windowService.window?.['NREUM'].interaction().save();
			if (interactionName) {
				interaction.setName(`${this.newRelicMonitorService.getFlowName()}: ${interactionName}`);
			}
			return interaction;
		}
	}
}
