export interface IIdentificationOtherType {
	name: string;
	front: boolean;
	back: boolean;
}

export const licenseTypes: Array<IIdentificationOtherType> = [
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.consularId',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.driversLicense',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.stateId',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.immigrationVisa',
		front: true,
		back: false
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.passport',
		front: true,
		back: false
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.passportCard',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.permanentResidenceCard',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.residencePermit',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.usRefugeeTravelDoc',
		front: true,
		back: false
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.voterId',
		front: true,
		back: true
	},
	{
		name: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypes.workPermit',
		front: true,
		back: true
	}
];
