import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ProgressBarService {
	private progressBarValue$ = new BehaviorSubject<number>(0);

	constructor() {}

	setProgressBarValue(step: number) {
		this.progressBarValue$.next(step);
	}

	getProgressBarValue(): Observable<number> {
		return this.progressBarValue$;
	}
}
