import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IIneligibleVehicleResult } from './vehicle-api.model';
import {
	ISearchVinResult,
	IVehicle,
	IVehicleMakesResult,
	IVehicleModelsResult,
	IVehicleTrimsResult,
	IVehicleYearsResult
} from './vehicle-api.model';

@Injectable({
	providedIn: 'root'
})
export class VehicleApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	getVehicleVin(vin: string): Observable<ISearchVinResult> {
		return this.http.get<ISearchVinResult>(`${this.API_MOBILE_METADATA_URL}/vehicle/vin/${vin}`);
	}

	getVehicleYears(): Observable<IVehicleYearsResult> {
		return this.http.get<IVehicleYearsResult>(`${this.API_MOBILE_METADATA_URL}/vehicle/years`);
	}

	getVehicleMakes(year: number): Observable<IVehicleMakesResult> {
		return this.http.get<IVehicleMakesResult>(`${this.API_MOBILE_METADATA_URL}/vehicle/years/${year}`);
	}

	getVehicleModels(year: number, makeId: number): Observable<IVehicleModelsResult> {
		return this.http.get<IVehicleModelsResult>(`${this.API_MOBILE_METADATA_URL}/vehicle/years/${year}/make/${makeId}`);
	}

	getVehicleTrims(year: number, makeId: number, modelId: number): Observable<IVehicleTrimsResult> {
		return this.http.get<IVehicleTrimsResult>(
			`${this.API_MOBILE_METADATA_URL}/vehicle/years/${year}/make/${makeId}/model/${modelId}`
		);
	}

	getVehicle(loanId: number, applicantIndex: number = 0): Observable<any> {
		return this.http.get<any>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/vehicle`);
	}

	setVehicle(vehicle: IVehicle, loanId: number, applicantIndex: number = 0): Observable<any> {
		return this.http.post<any>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/vehicle`,
			vehicle
		);
	}

	getIneligibleVehicleRules(): Observable<IIneligibleVehicleResult> {
		return this.http.get<IIneligibleVehicleResult>(`${this.API_MOBILE_METADATA_URL}/vehicle/ineligible-vehicle-rules`);
	}
}
