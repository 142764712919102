<div class="op-expansion-panel">
	<mat-expansion-panel id="{{ id + '_expansionPanel' }}" [expanded]="expanded" *ngIf="title" (opened)="opened.emit()">
		<mat-expansion-panel-header
			[ngClass]="titleBold ? 'op-body-1-bold-font' : 'op-body-2-font'"
			[class.applyGreen]="applyColor"
		>
			<mat-panel-title>
				{{ title }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<ng-content></ng-content>
		</div>
	</mat-expansion-panel>

	<op-inline-expansion-panel [id]="id" [expanded]="expanded" *ngIf="!title" (opened)="opened.emit()">
		<ng-content select="[inline]"></ng-content>
	</op-inline-expansion-panel>
</div>
