import { isEmpty } from 'lodash';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IFinancesResult } from 'src/app/core/services/mobile-api/mobile-api.model';

import { SummarySection } from './section.model';

/**
 * Stores Finance summary information.
 *
 * @param {IFinancesResult} finances
 * @param {IMetadata[]} checkCashingFee
 * @param {string} yes
 * @param {string} no
 * @returns {SummarySection}
 */
export const financesSection = (
	finances: IFinancesResult,
	checkCashingFee: IMetadata[],
	yes: string,
	no: string
): SummarySection => {
	return {
		section: 'finances',
		header: 'SUMMARY.personalFinance',
		route: '/finances',
		fields: [
			{
				title: 'SUMMARY.monthlyHousing',
				value: finances?.monthlyPayment ? `$${finances?.monthlyPayment}` : ''
			},
			{
				title: 'SUMMARY.dependents',
				value: finances?.numberOfDependents ? String(finances?.numberOfDependents) : ''
			},
			{
				title: 'SUMMARY.doYouHaveBank',
				value: finances?.hasBankAccount ? yes : no
			},
			{
				title: 'SUMMARY.doYouPayCheckFee',
				value: checkCashingFee.find((item) => item.key === finances?.checkCashingFee)?.text
			},
			{
				title: 'SUMMARY.usedPaydayService',
				value: finances?.usedPaydayService === null ? null : finances?.usedPaydayService ? yes : no
			}
		]
	};
};
