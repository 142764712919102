import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { MobileApiService } from 'src/app/core/services/mobile-api';
import {
	AddressTypeEnum,
	ICassAddress,
	ICassAddressResult,
	ILeadsByNameAddress,
	ILeadsByNameAddressResult
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { emailValidator, firstNameValidator, lastNameValidator } from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-special-offer-variants',
	templateUrl: './special-offer-variants.component.html',
	styleUrls: ['./special-offer-variants.component.scss']
})
export class SpecialOfferVariantsComponent implements OnDestroy, OnInit {
	formGroup: FormGroup;
	variantName: string;
	subscription = new Subscription();
	currentStep = 1;
	isVariant2 = false;

	constructor(
		private formBuilder: FormBuilder,
		private routingService: RoutingService,
		private landingService: LandingService,
		private nidService: NeuroIdService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private mobileApiService: MobileApiService
	) {}

	ngOnInit(): void {
		this.variantName = window.location.pathname.split('/')[1];
		this.isVariant2 = this.variantName === 'special-offer-2';
		this.createForm(this.formBuilder);

		// Delay the tagDataService.view because it conflicts with the view call made by the router and results in chat not loading
		let loadTimer = timer(500);
		loadTimer.subscribe((n) => {
			this.tagDataService.view(
				{},
				{
					opEventId: 'view',
					tealium_event: `dp-${this.variantName}-load`
				}
			);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			firstName: ['', [Validators.required, firstNameValidator(), Validators.minLength(2), Validators.maxLength(14)]],
			lastName: ['', [Validators.required, lastNameValidator()]],
			address: [],
			email: ['', [emailValidator()]]
		});
	}

	/**
	 * call the leads api with address from formGroup or selected address (data)
	 * from openAddressSelectionDialog choice
	 *
	 * @param {*} data
	 * @return {*}  {Observable<any>}
	 * @memberof SpecialOfferComponent
	 */
	searchForLeadInfo(
		data: ICassAddressResult['originalAddress'] | ICassAddressResult['suggestedAddress']
	): Observable<ILeadsByNameAddressResult> {
		const leadsData: ILeadsByNameAddress = {
			firstName: this.formGroup.value.firstName,
			lastName: this.formGroup.value.lastName,
			streetAddress1: data?.streetAddress1,
			zipCode: data?.postalCode
		};
		return this.landingService.getLeadsByNameAndAddress(leadsData, 'DIGITAL_PRESCREEN');
	}

	redirectToNextPage(matchFound: boolean): void {
		this.nidService.setNid('setVariable', 'direct_mail_prescreen', matchFound ? 'dm' : 'non_dm');
		if (matchFound) {
			this.tagDataService.link({
				tealium_event: `dp-${this.variantName}-found-apply`
			});
			this.routingService.route(RoutingPathsEnum.offerAvailable);
		} else {
			this.tagDataService.link({
				tealium_event: `dp-${this.variantName}-not-found-apply`
			});
			this.routingService.route(RoutingPathsEnum.getOffer);
		}
	}

	createAddressObject(): ICassAddress {
		const verifyAddress = this.formGroup.get('address').value;
		this.tagDataService.link(
			{
				applicant_city: verifyAddress?.city,
				applicant_state: verifyAddress?.state,
				applicant_country: 'US',
				applicant_postal_code: verifyAddress?.zipCode
			},
			{
				tealium_event: `dp-${this.variantName}-search-submit`
			}
		);
		return {
			type: AddressTypeEnum.home,
			streetAddress1: verifyAddress?.address1,
			streetAddress2: verifyAddress?.address2 || '',
			city: verifyAddress?.city,
			state: verifyAddress?.state,
			postalCode: verifyAddress?.zipCode,
			country: 'US',
			currentAddress: true
		};
	}

	onSubmit(): void {
		const address = this.createAddressObject();
		const cassVerifySub = this.mobileApiService
			.getCassAddress(address)
			.pipe(
				switchMap((cassResult: ICassAddressResult) => {
					const originalAddress = Object.assign({}, address, cassResult.originalAddress);
					const cassAddress: ICassAddressResult['originalAddress'] | ICassAddressResult['suggestedAddress'] = Boolean(
						cassResult.returnCode === 'NO_ERRORS'
					)
						? originalAddress
						: cassResult.suggestedAddress;
					return this.searchForLeadInfo(cassAddress);
				})
			)
			.subscribe({
				next: (rsp) => {
					this.redirectToNextPage(rsp?.status);
				},
				error: (err) => {
					this.dialogService.openErrorDialog(err).subscribe();
				}
			});
		this.subscription.add(cassVerifySub);
	}

	nextWizardPage(): void {
		const canMoveToNextStep: boolean = this.checkStepValidation();
		if (this.currentStep < 3 && canMoveToNextStep) {
			this.currentStep++;
			this.tagStepEvent();
		}
	}

	prevWizardPage(): void {
		if (this.currentStep > 1) {
			this.currentStep--;
			this.tagStepEvent();
		}
	}

	tagStepEvent(): void {
		const stepNames = ['name', 'address', 'email'];
		this.tagDataService.view({
			opEventId: 'view',
			tealium_event: `dp-${this.variantName}-${stepNames[this.currentStep - 1]}`
		});
	}

	private checkStepValidation(): boolean {
		let isFormValid: boolean = false;
		if (this.currentStep === 1) {
			isFormValid = this.formGroup.controls['firstName']?.valid && this.formGroup.controls['lastName']?.valid;
		} else if (this.currentStep === 2) {
			isFormValid = this.formGroup.controls['address']?.valid;
		} else if (this.currentStep === 3) {
			isFormValid = this.formGroup.controls['email']?.valid;
		}

		return isFormValid;
	}
}
