/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors
} from '@angular/forms';

import { opRequired } from '../../decorators/required.decorator';

@Component({
	selector: 'op-slide-toggle',
	templateUrl: './slide-toggle.component.html',
	styleUrls: ['./slide-toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SlideToggleComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: SlideToggleComponent
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideToggleComponent {
	@Input()
	@opRequired()
	id: string;

	@HostListener('keyup', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		switch (event.code) {
			case 'ArrowLeft':
			case 'ArrowRight':
			case 'Space':
				this.value = !this.value;
		}
	}

	formGroup: FormGroup;

	constructor(private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({
			toggle: [null]
		});

		this.formGroup.valueChanges.subscribe({
			next: (val) => {
				this.onChange(this.value);
			}
		});
	}

	get value(): boolean | null {
		return this.formGroup.get('toggle').value;
	}
	set value(toggle: boolean | null) {
		this.formGroup.patchValue({ toggle });
		this.onChange(toggle);
	}

	onChange = (change) => {
		// This is intentional empty
	};
	onTouched = () => {
		// This is intentional empty
	};
	touched = false;
	disabled = false;

	// Begin ControlValueAccessor interface
	writeValue(value: boolean): void {
		this.value = value;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	// End ControlValueAccessor interface

	// Begin Validator interface
	validate(control: AbstractControl): ValidationErrors | null {
		return null;
	}
	// End Validator interface
}
