<div class="ca-finder-partner-disclosure" *transloco="let t">
	<div *ngIf="partnerLead">
		<op-masthead [title]="t('CA_FINDER_PARTNER_DISCLOSURE.title')"></op-masthead>
		<op-content-container class="content">
			<p>
				{{ t('CA_FINDER_PARTNER_DISCLOSURE.greeting', { userName: partnerLead?.firstName }) }}
			</p>
			<p [innerHTML]="t('CA_FINDER_PARTNER_DISCLOSURE.message', { partnerName: partnerName })"></p>
			<div class="controls">
				<div>
					<button mat-flat-button color="primary" type="button" (click)="acknowledge()">
						{{ t('CA_FINDER_PARTNER_DISCLOSURE.acknowledge') }}
					</button>
				</div>
				<div>
					<button mat-flat-button color="secondary" type="button" (click)="decline()">
						{{ t('CA_FINDER_PARTNER_DISCLOSURE.decline') }}
					</button>
				</div>
			</div>
		</op-content-container>
	</div>
</div>
