<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="incomePanel"
		title="{{ t('DOCUMENT_SUBMIT.proofOfIncome.title') }}"
		[fileList]="fileList"
		[expanded]="expanded"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
		[isVerified]="isVerified"
	>
		<div *ngIf="!isVerified">
			<div class="panel-content">
				<div class="op-header-3-font">{{ t('DOCUMENT_SUBMIT.proofOfIncome.subtitle') }}</div>
				<ul>
					<li>{{ t('DOCUMENT_SUBMIT.proofOfIncome.requirements.includeAllPages') }}</li>
					<li>{{ t('DOCUMENT_SUBMIT.proofOfIncome.requirements.showDeposits') }}</li>
				</ul>
				<op-file-upload
					id="incomeFileUpload"
					[categoryType]="fileType"
					requiredFileType="application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/bmp"
				>
				</op-file-upload>
			</div>
		</div>
	</op-document-expansion-panel>
</div>
