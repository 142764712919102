<div class="go-to-stores" *transloco="let t">
	<div class="option">
		<div class="op-body-1-bold-font" id="receive-funds-label" *ngIf="!isPartnerCashDisbursement">
			{{ t('TERMS_CONFIRMED.STORE.goIntoStore') }}
		</div>
		<div class="op-body-1-bold-font" id="receive-funds-label" *ngIf="isPartnerCashDisbursement">
			{{ t('TERMS_CONFIRMED.PARTNER_STORE.gotoPartnerLocation' | transloco: { partner: partnerBrand }) }}
		</div>

		<div class="op-center op-mt-20">
			<img alt="" src="/assets/images/sign-in-store.svg" role="presentation" />
		</div>

		<ul>
			<li>
				<div id="">
					<div *ngIf="!isPartnerCashDisbursement">{{ t('TERMS_CONFIRMED.STORE.reviewAndSign') }}</div>
					<div *ngIf="isPartnerCashDisbursement">{{ t('TERMS_CONFIRMED.PARTNER_STORE.reviewAndSign') }}</div>
				</div>
			</li>
		</ul>
	</div>
</div>
