<div *transloco="let t">
	<div class="op-header-2-font op-mb-12">{{ t('LOAN_COMPLETED.howToManageLoan') }}</div>
	<div>{{ t('LOAN_COMPLETED.createOnlineAccount') }}</div>
	<ul>
		<li *ngIf="showCreateButton" [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature0')"></li>
		<li [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature1')"></li>
		<li [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature2')"></li>
		<li [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature3')"></li>
		<li [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature4')"></li>
		<li [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature5')"></li>
		<li [innerHTML]="t('LOAN_COMPLETED.onlineServingFeature6')"></li>
	</ul>
	<button
		mat-flat-button
		color="secondary"
		id="gotoServicingBtn"
		*ngIf="isMobile && !showCreateButton"
		(click)="mobileService.emit()"
	>
		{{ t('LOAN_COMPLETED.gotoServicing') }}
	</button>

	<button
		mat-flat-button
		color="secondary"
		id="gotoServicesBtn"
		*ngIf="!isMobile && !showCreateButton"
		(click)="mobileService.emit()"
	>
		{{ t('LOAN_COMPLETED.gotoServices') }}
	</button>

	<button
		mat-flat-button
		color="secondary"
		id="gotoCreateAccountBtn"
		*ngIf="showCreateButton"
		(click)="createAccount.emit()"
	>
		{{ t('LOAN_COMPLETED.setUpAccount') }}
	</button>
</div>
