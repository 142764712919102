import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdleTimeoutService } from 'src/app/core/services/idle-timeout/idle-timeout.service';

/**
 * Resets a timer when a request is sent.
 * User with IdleTimerService TODO: create idle timer service
 *
 * @export
 * @class IdleTimeoutInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class IdleTimeoutInterceptor implements HttpInterceptor {
	constructor(private idleTimeoutService: IdleTimeoutService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this.idleTimeoutService.resetTimer();
		return next.handle(request);
	}
}
