import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IResult, IStringResult } from '../mobile-api.model';
import { ILoanAppEvent } from './event-log-model';
import { SKIP_BUSY_INDICATOR } from 'src/app/core/interceptors/mobile-api-busy.interceptor';

export enum MfaActionEnum {
	continueApplication = 'Continue_Application',
	personalInfo = 'Personal_Info',
	documentUpload = 'Document_Upload',
	plaidConnect = 'Plaid_Connect',

	leadToApplication = 'Lead_to_Application'
}

@Injectable({
	providedIn: 'root'
})
export class EventLogApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	logLoanAppEvent(eventLog: ILoanAppEvent): Observable<IStringResult> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, true);
		return this.http.post<IStringResult>(`${this.API_MOBILE_LOAN_APP_URL}/events`, eventLog, { context });
	}
}
