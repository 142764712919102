import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, iif, Observable, of, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ApplicantStepCompleteEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { DisbursementChannelEnum } from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import {
	ApplicationStatusEnum,
	ILoanApplication,
	PreApprovedTermsEntity
} from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import IProduct, {
	paymentFrequencyEnum,
	ProductCategoriesEnum,
	ProductStatusesEnum,
	ProductSubStatusesEnum,
	SecuredOfferResponseEnum,
	preferNotAnswerVehicleStatus
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { ConfigApiService } from 'src/app/core/services/mobile-api/config-api/config-api.service';
import {
	AddressTypeEnum,
	IDisbursementOptions,
	IProductStatusAction,
	IResult
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { ElectronicTitleEnabledStates } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { VehicleUtils } from 'src/app/core/utils/vehicle-utils';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DebitCardFaqDialogComponent } from 'src/app/shared/components/dialogs/debit-card-faq-dialog/debit-card-faq-dialog.component';

// TODO: Add Offer Status phase two logic (Risk 2). Currently this is only managing phase one (Risk 3)
@Component({
	selector: 'op-offer-status',
	templateUrl: './offer-status.component.html',
	styleUrls: ['./offer-status.component.scss']
})
export class OfferStatusComponent implements OnInit, OnDestroy {
	applicant: ApplicantUtils;
	numberOfOffers: number;
	productOfferDetailUtilities: ProductOfferDetailsUtils;
	productOfferDetails: IProduct[] | PreApprovedTermsEntity[];
	disbursementInfo: DisbursementUtils;

	// specific loan objects
	preApprovedUnsecuredPersonalLoan: IProduct;
	approvedUnsecuredPersonalLoan: IProduct;
	eligibleSecuredPersonalLoan: IProduct;
	preApprovedSecuredPersonalLoan: IProduct;
	approvedSecuredPersonalLoan: IProduct;
	securedDeclinedPersonalLoan: IProduct;

	areAllProductsApproved: boolean;
	showBankConnectedMessage: boolean;

	showWhatIsSPLMsg: boolean;
	showLoanDifferencesMsg: boolean;
	showVehicleReqMsg: boolean;
	applicationStatus: string;
	fundingInfo: FundingInfoUtils;
	private subscription = new Subscription();

	vehicleUtils = new VehicleUtils();
	customerStateName: string;
	customerState: string;
	numOfDaysToRenewRegistration: number;
	isSplOnlyLoan: boolean;
	plaidIncentiveApplied: boolean;
	isElectronicTitleEnabledState: boolean;

	issuingOrganization: string;
	isMetaOrganization: boolean;

	isVehicleStatusPreferNotAnswer: boolean;
	isEligibleForDebitCard: boolean = false;
	showInstantFundingExperience: boolean = false;
	loaded: boolean = false;
	private shouldOverrideDisbursement: boolean = false;
	private plaidConnectForIncomeVerified: boolean = false;
	private incomeVerifiedExternally: boolean = false;

	constructor(
		private mobileService: MobileApiService,
		private configApiService: ConfigApiService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private sessionStorageService: SessionStorageService,
		private issuingPartnerService: IssuingPartnerService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		// If we land on the Offer Status page we always reset the soft product category selection
		/**
		 * bugfix for SPL-3068 : donot reset the productCategorySelection
		 * as it paints empty objects when user navigates using browser back buttons
		 */
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				mergeMap((loanApp) => {
					return iif(
						() => loanApp.applicationStatus === ApplicationStatusEnum.preApproved,
						combineLatest([
							this.configApiService.configInstantFundingExperience(),
							this.mobileService.getIncomeVerifiedPlaidEligibility(loanApp.id),
							this.mobileService.getDebitCardEligibility(loanApp.id)
						]).pipe(
							map(([fundingExperienceConfig, incomeVerifiedEligibility, debitCardEligibility]) => {
								this.showInstantFundingExperience = Boolean(fundingExperienceConfig?.value);
								this.plaidConnectForIncomeVerified = Boolean(incomeVerifiedEligibility?.eligible);
								this.isEligibleForDebitCard = Boolean(debitCardEligibility?.eligible);
								return loanApp;
							})
						),
						of(loanApp)
					);
				})
			)
			.subscribe({
				next: (loanApp: ILoanApplication) => {
					this.loaded = true;
					this.applicant = ApplicantUtils.fromLoanApp(this.loanAppService.getLoanApp());
					this.plaidIncentiveApplied = this.applicant.plaidIncentiveApplied;
					this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp);
					this.productOfferDetails = this.productOfferDetailUtilities?.productOfferDetails;
					this.numberOfOffers = this.productOfferDetailUtilities?.numberOfOffers;

					this.isVehicleStatusPreferNotAnswer = Boolean(
						this.applicant?.vehicleOwnershipStatus === preferNotAnswerVehicleStatus
					);

					this.issuingOrganization = this.issuingPartnerService.lender;
					this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingOrganization);
					// this.applicant.is
					this.incomeVerifiedExternally =
						this.loanAppService.isAutoVerifiedIncome() && this.applicant?.verifiedMonthlyIncomeSource !== 'PLAID';
					this.areAllProductsApproved = this.productOfferDetailUtilities.checkIfAllProductsAreApproved();

					this.preApprovedUnsecuredPersonalLoan = this.productOfferDetailUtilities.getLoanObject(
						ProductCategoriesEnum.unsecuredPersonalLoan,
						ProductStatusesEnum.preApproved
					);

					if (loanApp.productOfferDetails?.length > 0) {
						this.approvedUnsecuredPersonalLoan = this.productOfferDetailUtilities.getLoanObject(
							ProductCategoriesEnum.unsecuredPersonalLoan,
							ProductStatusesEnum.approved
						);
					} else if (loanApp.applicationStatus === ApplicationStatusEnum.approved) {
						// If the control comes then
						// it is a non-dual offer state originated UPL
						this.approvedUnsecuredPersonalLoan = Object.assign(
							{
								lasyPaycheckDate: null,
								productAction: null,
								productStatus: null,
								productSubStatus: null,
								incomeSource: null,
								paymentFrequency: paymentFrequencyEnum[loanApp.finalApprovedTerms.paymentFrequency],
								productCategory: ProductCategoriesEnum[loanApp.finalApprovedTerms.productCategory],
								paymentDate1: null,
								paymentDate2: null
							},
							loanApp.finalApprovedTerms
						);
					}

					this.eligibleSecuredPersonalLoan = this.productOfferDetailUtilities.getLoanObject(
						ProductCategoriesEnum.securedPersonalLoan,
						ProductStatusesEnum.eligible
					);
					this.preApprovedSecuredPersonalLoan = this.productOfferDetailUtilities.getLoanObject(
						ProductCategoriesEnum.securedPersonalLoan,
						ProductStatusesEnum.preApproved
					);
					this.approvedSecuredPersonalLoan = this.productOfferDetailUtilities.getLoanObject(
						ProductCategoriesEnum.securedPersonalLoan,
						ProductStatusesEnum.approved
					);
					this.securedDeclinedPersonalLoan = this.productOfferDetailUtilities.getLoanObject(
						ProductCategoriesEnum.securedPersonalLoan,
						ProductStatusesEnum.securedDeclined
					);

					this.showBankConnectedMessage = this.applicant?.plaidIncentiveApplied;

					this.disbursementInfo = DisbursementUtils.fromLoanApp(loanApp);
					this.fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);

					this.applicationStatus = loanApp.applicationStatus;
					this.isSplOnlyLoan =
						this.loanAppService.applicationStatus === ApplicationStatusEnum.securedOffered ||
						this.loanAppService.applicationStatus === ApplicationStatusEnum.securedDeclined ||
						this.productOfferDetailUtilities.hasOnlySPL();

					this.showWhatIsSPLMsg =
						!Boolean(this.approvedUnsecuredPersonalLoan) &&
						(Boolean(this.eligibleSecuredPersonalLoan) ||
							Boolean(this.preApprovedSecuredPersonalLoan) ||
							Boolean(this.approvedSecuredPersonalLoan) ||
							Boolean(this.securedDeclinedPersonalLoan));

					this.showLoanDifferencesMsg =
						Boolean(this.approvedUnsecuredPersonalLoan) &&
						(Boolean(this.eligibleSecuredPersonalLoan) ||
							Boolean(this.preApprovedSecuredPersonalLoan) ||
							Boolean(this.approvedSecuredPersonalLoan) ||
							Boolean(this.securedDeclinedPersonalLoan));

					this.showVehicleReqMsg =
						Boolean(this.eligibleSecuredPersonalLoan) ||
						Boolean(this.preApprovedSecuredPersonalLoan) ||
						Boolean(this.securedDeclinedPersonalLoan);
					if (this.showVehicleReqMsg) {
						this.mobileService.getAddresses(this.loanAppService.loanApplicationId).subscribe({
							next: (addresses) => {
								this.customerState = this.vehicleUtils.getCustomerState(addresses, AddressTypeEnum.home);
								this.customerStateName = this.vehicleUtils.getCustomerStateName(this.customerState);
								this.numOfDaysToRenewRegistration = this.vehicleUtils.getNumOfDaysToRenewRegistration(
									this.customerState
								);
								this.isElectronicTitleEnabledState = ElectronicTitleEnabledStates.includes(
									this.customerState?.toUpperCase()
								);
							}
						});
					}
					if (
						this.loanAppService.isPartnerApplication() &&
						!this.fundingInfo.hasDisbursementOption(
							this.disbursementInfo.originationDisbursementChannel,
							this.disbursementInfo.originationDisbursementType
						)
					) {
						this.configApiService
							.configOverrideDisbursement(loanApp.partnerId, this.customerState)
							.subscribe((configResult) => {
								this.shouldOverrideDisbursement = configResult.value === false;
							});
					}
					this.createTealiumEvent('page_load', 'page load', 'view');
				}
			});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	showDebitCardFaq() {
		this.dialogService.open(DebitCardFaqDialogComponent, {});
	}

	onSubmit({ method, productCategory }): void {
		this.sessionStorageService.set('productCategorySelection', productCategory);
		return this[method](productCategory);
	}

	private adjustTerms(productCategory: ProductCategoriesEnum): void {
		this.sessionStorageService.set('productCategorySelection', productCategory);
		this.createTealiumEvent('select_terms_for_' + productCategory.toLowerCase(), 'click', 'link');
		let isNotificationComplete = this.loanAppService.getLoanApp().notificationProcessComplete;
		if (
			ProductCategoriesEnum.unsecuredPersonalLoan === productCategory &&
			ApplicationStatusEnum.securedAccepted === this.applicationStatus
		) {
			const productStatusReqParams: IProductStatusAction = {
				productCategory: productCategory,
				productAction: 'product.status.preapproved'
			};
			this.mobileService.updateProductStatus(productStatusReqParams, this.loanAppService.loanApplicationId).subscribe({
				next: () => {
					this.updateDisBursementChannelAndNavigate(productCategory, RoutingPathsEnum.adjustTerms);
				}
			});
		} else if (
			ProductCategoriesEnum.securedPersonalLoan === productCategory &&
			ApplicationStatusEnum.approved === this.applicationStatus &&
			this.loanAppService.getLoanApp().isSplStorelessEligible &&
			this.routingService.isSplStorelessEligibleWithStatus(null) &&
			!isNotificationComplete
		) {
			let isSubStatusInitiated = this.productOfferDetailUtilities.findProductSubStatus(
				ProductCategoriesEnum.securedPersonalLoan,
				ProductSubStatusesEnum.initiated
			);
			let isSubStatusComplete = this.productOfferDetailUtilities.findProductSubStatus(
				ProductCategoriesEnum.securedPersonalLoan,
				ProductSubStatusesEnum.complete
			);
			if (!isNotificationComplete || (isNotificationComplete && isSubStatusInitiated)) {
				this.routingService.route(RoutingPathsEnum.storeLessDisbursementInfo);
			} else {
				this.updateDisBursementChannelAndNavigate(productCategory, RoutingPathsEnum.adjustTerms);
			}
		} else {
			this.updateDisBursementChannelAndNavigate(productCategory, RoutingPathsEnum.adjustTerms);
		}
	}

	private updateDisBursementChannelAndNavigate(
		productCategory: ProductCategoriesEnum,
		nextPage: RoutingPathsEnum
	): void {
		this.createTealiumEvent('I_am_not_sure_about_offer', 'click', 'link');
		if (this.productOfferDetailUtilities.isAnyProductInStatus(ProductStatusesEnum.approved)) {
			this.updateDisbursementChannel(productCategory).subscribe({
				next: () => {
					this.routingService.route(nextPage);
				}
			});
		} else {
			this.routingService.route(nextPage);
		}
	}

	private checkVehicleEligibility(productCategory: ProductCategoriesEnum): void {
		this.sessionStorageService.set('productCategorySelection', productCategory);
		this.createTealiumEvent('check_vehicle_eligiblity', 'click', 'link');

		const applicationStatus = this.loanAppService.applicationStatus;

		if (ApplicationStatusEnum.securedAccepted === applicationStatus) {
			this.routingService.route(RoutingPathsEnum.vehicle);
		} else if (
			ApplicationStatusEnum.securedDeclined === applicationStatus ||
			ApplicationStatusEnum.securedOffered === applicationStatus
		) {
			this.mobileService
				.setSecuredAppOffer(
					{ securedAppOfferAction: SecuredOfferResponseEnum.accept },
					this.loanAppService.loanApplicationId
				)
				.subscribe({
					next: () => {
						this.routingService.route(RoutingPathsEnum.vehicle);
					}
				});
		} else {
			const requestParameters = { productCategory, vehicleEligibilityCheck: 'Yes' };

			this.mobileService.setProductSelection(requestParameters, this.loanAppService.loanApplicationId).subscribe({
				next: () => {
					this.updateDisBursementChannelAndNavigate(productCategory, RoutingPathsEnum.vehicle);
				}
			});
		}
	}

	private vehicleEligibilityQuestion(productCategory: ProductCategoriesEnum): void {
		this.routingService.route(RoutingPathsEnum.vehicleEligibilityQuestion);
	}

	private goToDocumentSubmission() {
		this.createTealiumEvent('submit_vehicle_documents', 'click', 'link');
		this.routingService.route(RoutingPathsEnum.documentSubmit);
	}

	private goToOfferStatusInformation() {
		this.numberOfOffers === 2
			? this.routingService.route(RoutingPathsEnum.offerStatusInformation)
			: this.routingService.route(RoutingPathsEnum.securedPersonal);
	}

	private updateDisbursementChannel(productCategory: ProductCategoriesEnum): Observable<IResult> {
		const disbursementParameters: IDisbursementOptions = {
			disbursementChannel: this.disbursementInfo.getOriginationDisbursementChannel(),
			disbursementType: this.disbursementInfo.originationDisbursementType
		};
		if (ProductCategoriesEnum.securedPersonalLoan === productCategory) {
			disbursementParameters.disbursementChannel = DisbursementChannelEnum.store;
		} else if (this.shouldOverrideDisbursement) {
			disbursementParameters.disbursementChannel = DisbursementChannelEnum.store;
			disbursementParameters.disbursementType = this.fundingInfo.getPreferredDisbursementType(
				DisbursementChannelEnum.store,
				this.disbursementInfo.originationDisbursementType
			);
		}
		return this.mobileService.updateDisbursementChannel(disbursementParameters, this.loanAppService.loanApplicationId);
	}

	private goToReferences(productCategory: ProductCategoriesEnum): void {
		this.sessionStorageService.set('productCategorySelection', productCategory);
		this.createTealiumEvent('select_terms_for_' + productCategory.toLowerCase(), 'click', 'link');
		if (
			ApplicationStatusEnum.securedAccepted === this.applicationStatus ||
			ApplicationStatusEnum.approved === this.applicationStatus
		) {
			const selectedProductAction: string =
				productCategory === ProductCategoriesEnum.unsecuredPersonalLoan
					? 'product.status.preapproved'
					: 'spl.product.continue.to.submit';

			const productStatusReqParams: IProductStatusAction = {
				productCategory: productCategory,
				productAction: selectedProductAction
			};
			this.mobileService.updateProductStatus(productStatusReqParams, this.loanAppService.loanApplicationId).subscribe({
				next: () => {
					this.updateDisbursementChannelAndNavigateToReference(productCategory);
				}
			});
		} else {
			this.updateDisbursementChannelAndNavigateToReference(productCategory);
		}
	}

	private updateDisbursementChannelAndNavigateToReference(productCategory: ProductCategoriesEnum): void {
		if (this.productOfferDetailUtilities.isAnyProductInStatus(ProductStatusesEnum.approved)) {
			this.updateDisbursementChannel(productCategory).subscribe({
				next: () => {
					this.determineNextRouteAndNavigate();
				}
			});
		} else {
			this.determineNextRouteAndNavigate();
		}
	}

	private determineNextRouteAndNavigate(): void {
		const skipReferencesFlag = this.applicant.referenceProcessComplete;
		if (skipReferencesFlag) {
			if (
				this.incomeVerifiedExternally &&
				this.plaidConnectForIncomeVerified &&
				this.loanAppService.isApplicationStatusPreApproved()
			) {
				this.routingService.route(RoutingPathsEnum.incomeVerification);
			} else {
				if (this.loanAppService.isBtmEligible() || this.plaidConnectForIncomeVerified) {
					this.routingService.routeBtm();
				} else if (this.loanAppService.isIncomeVerificationEligible()) {
					this.routingService.route(RoutingPathsEnum.autoVerifyIncome);
				} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.disbursementInfo)) {
					this.routingService.route(RoutingPathsEnum.receiveFunds);
				} else {
					//TODO: This should be changed to routingService.determineSubmitRoute?
					this.routingService.route(RoutingPathsEnum.receiveFunds);
				}
			}
		} else {
			this.routingService.route(RoutingPathsEnum.references);
		}
	}

	private createTealiumEvent(event, eventAction, eventType) {
		return this.tagDataService[eventType](
			{
				application_type: 'CONSUMER_INSTALLMENT_LOAN',
				loan_application_id: this.loanAppService.loanApplicationId,
				loan_application_status: this.loanAppService.applicationStatus,
				page_location: window.location.href,
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp()?.productOfferDetails
				)
			},
			{
				event_action: eventAction,
				event_category: 'CONSUMER_INSTALLMENT_LOAN',
				event_label: event,
				tealium_event: 'offer_status' + event
			}
		);
	}
}
