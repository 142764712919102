<section [attr.id]="id" class="payment-method" [class.selected]="selected">
	<div class="accordion-header" *ngIf="!hideHeader">
		<div (click)="toggle()">
			<div class="accordion-action-row">
				<p class="selected-button op-rounded-button-small"><mat-icon svgIcon="op-checkMark"></mat-icon> Selected</p>
				<mat-icon
					*ngIf="canExpand"
					aria-hidden="true"
					[ngClass]="expanded ? 'expand' : 'collapse'"
					svgIcon="op-caretLeft"
				></mat-icon>
			</div>
			<div class="title op-black-bold op-body-1-font op-mb-10">
				<mat-icon svgIcon="{{ paymentMethodIcon }}"></mat-icon> <span class="op-ml-10"> {{ paymentMethodTitle }} </span>
			</div>
		</div>
	</div>
	<div class="section-content" *ngIf="!hideHeader">
		<div>{{ paymentMethodInfo }}</div>
		<div class="duration op-grey-bold op-mt-20">{{ paymentMethodDuration }}</div>
	</div>

	<div [@expand]="expanded" *ngIf="canExpand" class="collapsible-section">
		<hr class="horizontal-rule op-mt-20 op-mb-20" *ngIf="!hideHeader" />
		<ng-content></ng-content>
	</div>
</section>
