import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../environment/environment.service';
import { IMultiProductResult } from '../mobile-api';
import { MobileApiService } from '../mobile-api/mobile-api.service';

export enum ProductTypeEnum {
	creditCard = 'credit_card',
	digit = 'digit'
}

@Injectable({
	providedIn: 'root'
})
export class MultiProductService {
	constructor(private mobileService: MobileApiService, private environmentService: EnvironmentService) {}

	checkMultiProductEligibility(loanApplicationId: number): Observable<IMultiProductResult> {
		return this.mobileService.checkMultiProductEligibility(loanApplicationId);
	}

	getCCHost(redirectCode: string, lang: string): string {
		let host = 'creditcards.oportun.com';
		if (lang === 'es') {
			host = 'tarjetacredito.oportun.com';
		}
		host = `${host}/#/landing?`;
		if (this.environmentService.isQa) {
			host = `ng-ccos.12.app.${this.environmentService.env}.pfops.com`;
			if (lang === 'es') {
				host = `ng-ccos-es.12.app.${this.environmentService.env}.pfops.com`;
			}
			host = `${host}/#/landing?`;
		}
		if (this.environmentService.isStage) {
			host = `ng-ccos.1.app.${this.environmentService.env}.pfops.com`;
			if (lang === 'es') {
				host = `ng-ccos-es.1.app.${this.environmentService.env}.pfops.com`;
			}
			host = `${host}/#/landing?`;
		}
		if (this.environmentService.isDevHost) {
			host = window.location.host.replace(/movil|online-origination/gi, function () {
				return 'ccos';
			});
			host = `${host}/#/landing?`;
			if (lang === 'es') {
				host = `${host}lang=es&`;
			}
		}
		if (this.environmentService.isInternal) {
			host = 'localhost:4200/#/landing?';
			if (lang === 'es') {
				host = `${host}lang=es&`;
			}
		}
		host = `${host}ctoken=${redirectCode}`;
		return `${this.environmentService.isInternal ? 'http' : 'https'}://${host}`;
	}
}
