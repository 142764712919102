<div class="masthead {{ customClass }}">
	<div>
		<div class="op-header-1-font op-mtb-6" id="masthead_title" [innerHTML]="title"></div>
	</div>
	<div>
		<div class="op-body-1-font" id="masthead_subtitle" *ngIf="subTitle" [innerHTML]="subTitle"></div>
	</div>
	<p *ngIf="!subTitle"></p>
	<ng-content></ng-content>
</div>
