import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'opLastNNumber'
})
@Injectable({
	providedIn: 'root'
})
export class LastNNumberMaskPipe implements PipeTransform {
	transform(number: string | number, ch: string = '.', mask: number = 8, digit: number = 4): string {
		const maskText = new Array(mask).fill(ch, 0, mask).join('');
		const accountNumberText = `${number}`;
		if (accountNumberText.length < digit) {
			return `${maskText}${accountNumberText}`;
		} else {
			return `${maskText}${accountNumberText.substr(accountNumberText.length - digit, digit)}`;
		}
	}
}
