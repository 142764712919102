import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { opRequired } from '../../../../shared/decorators/required.decorator';

@Component({
	selector: 'op-document-badge',
	templateUrl: './document-badge.component.html',
	styleUrls: ['./document-badge.component.scss']
})
export class DocumentBadgeComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	icon: string;

	@Input()
	small: boolean;
}
