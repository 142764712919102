import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { SectionTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.model';
import { BankConnectEventTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.service';

import { opRequired } from '../../decorators/required.decorator';

/**
 * Display button to open plaid
 *
 * @export
 * @class PlaidExtendedComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
	selector: 'op-plaid-extended',
	templateUrl: './plaid-extended.component.html',
	styleUrls: ['./plaid-extended.component.scss']
})
export class PlaidExtendedComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	minimum: boolean;

	@Input()
	bankOnly: boolean = false;

	@Input()
	showIcon: boolean = true;

	@Input()
	sectionType = SectionTypeEnum.income;

	@Input()
	bankAccountExists: boolean;

	@Input()
	showConnectBank: boolean = true;

	private subscription = new Subscription();
	bankConnectState: BankConnectEventTypeEnum;

	issuingOrganization: string;

	constructor(private issuingPartnerService: IssuingPartnerService) {}

	ngOnInit(): void {
		const issuingOrganizationSub = this.issuingPartnerService.issuingOrganization$.subscribe({
			next: (issuingOrg) => {
				this.issuingOrganization = issuingOrg;
			}
		});
		this.subscription.add(issuingOrganizationSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
