<div class="offer-available" *transloco="let t">
	<div class="op-center">
		<p></p>
		<img
			alt=""
			class="op-center"
			src="/assets/images/prescreen_getPrequalified.svg"
			role="presentation"
			height="200"
			width="200"
		/>
	</div>
	<op-masthead
		class="op-center"
		*ngIf="user?.firstName"
		[title]="t('GLOBAL.congratulations') + ' ' + user?.firstName + '!'"
	>
		<div class="op-green-divider"></div>
		<p></p>
		<h4 class="op-center">{{ t('SPECIAL_OFFER.OFFER_AVAILABLE.subtitle') }}</h4>
	</op-masthead>
	<op-content-container class="content op-center">
		<div>
			<div class="loanAmountContainer mb-5">
				<p class="upTo op-micro-bold-font">{{ t('SPECIAL_OFFER.OFFER_AVAILABLE.upTo') }}</p>
				<p class="loanAmount op-display-2-font">{{ loanAmount | currency: 'USD':'symbol':'0.0' }}</p>
			</div>
		</div>
		<p></p>
		<h5 class="op-body-2">{{ t('SPECIAL_OFFER.OFFER_AVAILABLE.disclaimer') }}</h5>
		<button
			mat-flat-button
			color="primary"
			name="applyBtn"
			id="applyBtn"
			type="button"
			(click)="startApplicationWithOffer()"
		>
			{{ t('SPECIAL_OFFER.OFFER_AVAILABLE.applyBtn') }}
		</button>
		<p></p>

		<hr />
		<div class="disclaimer">{{ t('SPECIAL_OFFER.OFFER_AVAILABLE.unsubscribe') }}</div>
		<hr />
		<p class="prescreenNotice" [innerHTML]="t('SPECIAL_OFFER.OFFER_AVAILABLE.prescreenNotice')"></p>
		<hr />
		<p class="terms op-body-2" [innerHTML]="t('SPECIAL_OFFER.OFFER_AVAILABLE.terms')"></p>
	</op-content-container>
</div>
