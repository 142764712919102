import { ProductStatusesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';

export enum RequiredDocumentTypeEnum {
	bankAccountRequired = 'bankAccountRequired',
	applicantPhotoRequired = 'applicantPhotoRequired',
	applicantPhotoUploaded = 'applicantPhotoUploaded'
}

export enum DocumentTypeEnum {
	bankAccount = 'bankAccount',
	income = 'income',
	residence = 'residence',
	identification = 'identification',
	vehicleDriversLicense = 'vehicleDriversLicense',
	vehicleRegistration = 'vehicleRegistration',
	vehiclePhotos = 'vehiclePhotos'
}

export const DmvIdSectionKey = 'DMV_IDENTIFICATION';
export const MULTI_OFFER_ENABLED_STATES_STRING = 'MULTI_OFFER_ID_VERIFICATION_ENABLED_STATES';
export const SplBounceSectionList = ['VEHICLE_PHOTO', 'VEHICLE_REGISTRATION', 'VEHICLE_TITLE', 'IDENTIFICATION'];
export const UplBounceSectionList = ['IDENTIFICATION', 'RESIDENCE', 'DISBURSEMENT', 'INCOME'];

/* This constant is to declare the valid status of SPL object for a Valid status of UPL object
	Key for this constant is the UPL object status and values is the valid status of SPL objects.
*/
export const ValidSPLStatusListForSubmit = {
	PRE_APPROVED: [ProductStatusesEnum.preApproved, ProductStatusesEnum.eligible],
	APPROVED: [ProductStatusesEnum.preApproved, ProductStatusesEnum.bounced],
	BOUNCED: [ProductStatusesEnum.eligible, ProductStatusesEnum.preApproved]
};
