<div
	class="esign-event-signing-timeout"
	*transloco="let t"
	aria-labelledby="signing-timeout-label"
	aria-describedby="signing-timeout-message"
>
	<op-masthead id="signing-timeout-label" [title]="t('ESIGN.EVENT.SIGNING_TIMEOUT.title')"></op-masthead>
	<op-content-container class="content">
		<div class="op-icon-align-body-1">
			<mat-icon aria-hidden="true" svgIcon="op-alert"></mat-icon>
			<span [innerHTML]="t('ESIGN.EVENT.SIGNING_TIMEOUT.message')" id="signing-timeout-message"></span>
		</div>

		<br />
		<p [innerHTML]="t('ESIGN.EVENT.SIGNING_TIMEOUT.action')"></p>

		<button mat-flat-button color="primary" id="continueBtn" (click)="signOut()">
			{{ t('GLOBAL.continue') }}
		</button>
	</op-content-container>
</div>
