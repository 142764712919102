import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { EventDataTagPlaidEnum } from 'src/app/core/services/event-data/event-data.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LanguageEnum, LanguageService } from 'src/app/core/services/language/language.service';
import { EventLogApiService, IBankAccountDecryptTokenResult, MobileApiService } from 'src/app/core/services/mobile-api';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Injectable({
	providedIn: 'root'
})
export class BankConnectGuard implements CanActivate {
	constructor(
		private activatedRoute: ActivatedRoute,
		private landingService: LandingService,
		private routingService: RoutingService,
		private languageService: LanguageService,
		private mobileService: MobileApiService,
		private eventLogService: EventLogApiService,
		private tagDataService: TagDataService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const queryParams = route.queryParams;
		return this.mobileService.setDecryptBankConnectToken(queryParams?.token).pipe(
			map((rsp: IBankAccountDecryptTokenResult) => this.processBankConnectInfo(rsp.data)),
			catchError((err) => this.handleError(err))
		);
	}

	private handleError(err = null): Observable<boolean> {
		if (err?.messageCode === 'token expired' || err?.message === 'no token') {
			return of(true);
		} else {
			this.routingService.route(RoutingPathsEnum.findYourApplication);
			return of(false);
		}
	}

	private processBankConnectInfo(rsp: any): boolean {
		if (rsp?.loanApplicationId && rsp?.phoneNumber && rsp?.token) {
			this.languageService.language = rsp?.preferredLanguage === 'ES' ? LanguageEnum.spanish : LanguageEnum.english;

			const plaidEvent = {
				applicantId: rsp?.applicantId,
				eventType: EventDataTagPlaidEnum.plaidOneClickLaunchedMobile,
				loanApplicationId: rsp?.loanApplicationId
			};

			this.landingService.updateFindApp(rsp);
			this.routingService.route(RoutingPathsEnum.multifactor);
			this.eventLogService.logLoanAppEvent(plaidEvent).subscribe();
			return false;
		} else {
			this.languageService.language = LanguageEnum.english;
			this.tagDataService.view(
				{},
				{
					tealium_event: EventDataTagPlaidEnum.plaidOneClickExpiredMobile
				}
			);
			return true;
		}
	}
}
