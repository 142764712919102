import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { IFileUploadExtended } from '../../auto-verification.service';
import { FileUploadStatusEnum } from 'src/app/core/services/file-upload/file-upload.service';

@Component({
	selector: 'op-upload-display-pending',
	templateUrl: './upload-display-pending.component.html',
	styleUrls: ['./upload-display-pending.component.scss']
})
export class UploadDisplayPendingComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	singleFile: IFileUploadExtended;

	@Input()
	showReUploadButton: boolean;

	@Input() disabled: boolean;

	@Output() fileDropped = new EventEmitter<any>();
	@Output() opDeleteClick = new EventEmitter<any>();
	@Output() opReUploadClick = new EventEmitter<any>();

	public fileUploadStatusEnum: typeof FileUploadStatusEnum = FileUploadStatusEnum;

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}
