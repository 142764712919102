import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AddressUtils } from '../../utils/address-utils';
import { AccountStatusEnum } from '../ach-bank-accounts/ach-bank-accounts.service';
import { ApplicantUtils } from '../loan-application/applicant/applicant-utils';
import { DisbursementUtils } from '../loan-application/disbursement/disbursement-utils';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { ConfigApiService, IAchBankAccountResult, MobileApiService } from '../mobile-api';
import { IConfigResult } from '../mobile-api/config-api/config-api.model';

export interface IOnlineNotifications {
	onlineNotifyEnabled?: boolean;
	onlineNotifyNewAppEnabled?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class OnlineNotificationService {
	constructor(
		private loanAppService: LoanApplicationService,
		private configApiService: ConfigApiService,
		private mobileApiService: MobileApiService
	) {}

	getOnlineNotificationOptions(): Observable<IOnlineNotifications> {
		const onlineNotifyEnabled$ = this.configApiService.configOnlineNotificationEnabled();
		const applicant = ApplicantUtils.fromLoanApp(this.loanAppService.getLoanApp());
		const disbursementInfo = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());

		return onlineNotifyEnabled$.pipe(
			filter(Boolean),
			switchMap((config: IConfigResult) => {
				if (config && applicant.isReturning() && disbursementInfo.isOnlineNotificationEnabled()) {
					const onlineNotifications = { onlineNotifyEnabled: true };
					return of(onlineNotifications);
				} else {
					return this.getOnlineNotificationFlagForNewApplication();
				}
			})
		);
	}

	verifyBankAccount(): Observable<boolean> {
		const bankAccount$ = this.mobileApiService.getAchBankAccount(this.loanAppService.loanApplicationId);

		return bankAccount$.pipe(
			filter(Boolean),
			take(1),
			switchMap((bankAccount: IAchBankAccountResult) => {
				if (bankAccount) {
					return this.mobileApiService.getVerifyBankAccount(bankAccount.id, this.loanAppService.loanApplicationId).pipe(
						map((verified) => {
							return AccountStatusEnum.active === verified?.status;
						})
					);
				} else {
					return of(false);
				}
			})
		);
	}

	getOnlineNotificationFlagForNewApplication(): Observable<IOnlineNotifications> {
		const onlineNotifyNewAppEnabled$ = this.configApiService.configOnlineNotificationNewApplicantEnabled();
		let onlineNotifications: IOnlineNotifications = {};
		return onlineNotifyNewAppEnabled$.pipe(
			map((config: IConfigResult) => {
				const disbursementInfo = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
				if (config && disbursementInfo.isOnlineNotificationEnabled()) {
					onlineNotifications = Object.assign({}, onlineNotifications, { onlineNotifyNewAppEnabled: true });
				}
				return onlineNotifications;
			})
		);
	}

	isWIFlow(loanApp: ILoanApplication): Observable<boolean> {
		const applicantIndex = this.loanAppService.getCurrentApplicant()?.applicantIndex;

		return this.mobileApiService
			.getAddresses(loanApp?.id || this.loanAppService.loanApplicationId, applicantIndex)
			.pipe(
				map((addresses) => {
					if (addresses?.length > 0 && AddressUtils.getHomeAddress(addresses)?.state?.toUpperCase() === 'WI') {
						return true;
					}
					return false;
				})
			);
	}
}
