import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, Observable, of } from 'rxjs';
import { IAgentChannel, IAgentData, MobileApiService } from 'src/app/core/services/mobile-api';

enum agentStoreStateEnum {
	agent = 'agent',
	store = 'store',
	success = 'success'
}

/**
 * Dialog to allow store agent to get attribution for referrals by entering a code.
 *
 * @export
 * @class AgentReferralDialogComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-agent-store-dialog',
	templateUrl: './agent-store-dialog.component.html',
	styleUrls: ['./agent-store-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentStoreDialogComponent {
	@ViewChild('agentStoreField') agentStoreField: ElementRef;

	agentFormGroup: FormGroup;
	storeFormGroup: FormGroup;
	public agentStateEnum: typeof agentStoreStateEnum = agentStoreStateEnum;
	public agentState: agentStoreStateEnum = agentStoreStateEnum.agent;

	userName: string = '';
	storeList: IAgentChannel[] = [];
	selectedStore: IAgentChannel;

	constructor(
		public dialogRef: MatDialogRef<AgentStoreDialogComponent>,
		private formBuilder: FormBuilder,
		private mobileApiService: MobileApiService,
		private ref: ChangeDetectorRef
	) {
		dialogRef.updateSize('400px');

		this.agentFormGroup = this.formBuilder.group({
			agentId: [null, [Validators.required]]
		});
		this.storeFormGroup = this.formBuilder.group({
			storeId: [null, [Validators.required]]
		});
	}

	private checkAgentReferral(code: string): Observable<IAgentData> {
		return this.mobileApiService.getAgent(code).pipe(
			map((rsp) => rsp?.data),
			catchError(() => of(null))
		);
	}

	back(): void {
		this.agentState = agentStoreStateEnum.agent;
	}

	private getAgentInformation(code: string): void {
		this.checkAgentReferral(code).subscribe({
			next: (agentData) => {
				if (agentData) {
					this.agentState = agentStoreStateEnum.store;
					this.userName = agentData?.firstName;
					this.storeList = agentData?.channels;
					this.ref.detectChanges();
				} else {
					this.agentFormGroup.get('agentId').setErrors({ invalid: true });
					this.agentStoreField.nativeElement.focus();
				}
			},
			error: () => {
				this.agentFormGroup.get('agentId').setErrors({ invalid: true });
				this.agentStoreField.nativeElement.focus();
			}
		});
	}

	accept(event: any): void {
		if (event?.agentId) {
			this.getAgentInformation(event.agentId);
		}

		if (event?.storeId) {
			this.selectedStore = this.storeList.find((store) => store.id === event.storeId);
			this.agentState = agentStoreStateEnum.success;
		}
	}

	closeDialog(): void {
		const agentStoreChannel = {
			agentId: this.agentFormGroup.get('agentId').value,
			channelId: this.storeFormGroup.get('storeId').value
		};
		this.dialogRef.close(agentStoreChannel);
	}
}
