import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter, map, take } from 'rxjs';
import { LoanApplicationService } from '../core/services/loan-application/loan-application.service';
import { ApplicationStatusEnum } from '../core/services/loan-application/loan-application.model';
import { LoggingService } from '../core/services/logging/logging.service';
import { ApplicationFlowEnum } from '../core/services/mobile-api';

/**
 * Guard to check that the loan status is PREQUAL_ACCEPTED before showing pages.
 *
 * @export
 * @class PreQualifyGuard
 * @implements {CanActivate}
 */
@Injectable()
export class PreQualifyGuard implements CanActivate {
	constructor(
		private router: Router,
		private loanAppService: LoanApplicationService,
		private loggingService: LoggingService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.loanApplication$.pipe(
			filter(Boolean),
			take(1),
			map((loanApp) => {
				if (
					loanApp.applicationFlow === ApplicationFlowEnum.oportunPrequal &&
					loanApp.applicationStatus === ApplicationStatusEnum.prequalAccepted
				)
					return true;
				else {
					this.loggingService.error(
						`Denied access to prequal route : ${this.router.url}. Loan status is ${loanApp.applicationStatus}`
					);
					return this.router.parseUrl('/home');
				}
			})
		);
	}
}
