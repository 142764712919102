import { Component, Input } from '@angular/core';

/**
 * Container with a box showdown and title
 *
 * @export
 * @class ContentContainerComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-content-container',
	templateUrl: './content-container.component.html',
	styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent {
	@Input() title: string;
}
