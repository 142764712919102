import { Component, Input } from '@angular/core';

@Component({
	selector: 'op-promo-card',
	templateUrl: './promo-card.component.html',
	styleUrls: ['./promo-card.component.scss']
})
export class PromoCardComponent {
	@Input()
	promo: string;

	@Input()
	promoKey: string;

	@Input()
	showRightArrow: boolean;

	@Input()
	icon: string = 'op-info-blue-fill';
}
