<div class="op-step-info">
	<div class="step-left">
		<mat-icon
			@fadeIn
			@pulse
			*ngIf="checked"
			class="step-circle-check"
			[ngClass]="{ active: state?.active, burst: burst }"
			svgIcon="op-checkMark-solid"
			alt=""
		></mat-icon>
		<mat-icon *ngIf="!checked" class="step-circle" [ngClass]="{ active: state?.active }" alt=""> </mat-icon>
		<div [ngClass]="{ 'step-track': !end }">
			<div
				[@fadeInNow]="state?.active"
				[ngClass]="{ percent: !end }"
				[ngStyle]="{ height: state?.percent === 100 ? state?.percent + '%' : 0 }"
			></div>
		</div>
	</div>
	<div class="step-content">
		<div class="step-title op-body-1-bold-font">{{ title }}</div>
		<div class="step-info op-label-font">
			<ng-content></ng-content>
		</div>
	</div>
</div>
