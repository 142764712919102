import { Injectable } from '@angular/core';
import { MobileApiService } from '../mobile-api';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AutoPayBankAccountVerificationService {
	private autoPayVerifiedBankAccounts: Map<number, Map<number, any>> = new Map<number, Map<number, any>>();

	constructor(private mobileService: MobileApiService) {}

	verifyBankAccounts(accountIds: [], loanAppId: number): Observable<any> {
		const autoPayVerifiedBankAccountsForLoanApp = this.autoPayVerifiedBankAccounts.get(loanAppId);
		if (autoPayVerifiedBankAccountsForLoanApp) {
			const verifiedBankAccounts = [];
			accountIds.forEach((id) => {
				const bankAccount = autoPayVerifiedBankAccountsForLoanApp.get(id);
				if (bankAccount?.status === 'ACTIVE') {
					verifiedBankAccounts.push(bankAccount);
				}
			});
			if (verifiedBankAccounts.length === accountIds.length) {
				return of(verifiedBankAccounts);
			} else {
				return this.refreshVerifiedBankAccount(accountIds, loanAppId);
			}
		} else {
			return this.refreshVerifiedBankAccount(accountIds, loanAppId);
		}
	}

	reset() {
		this.autoPayVerifiedBankAccounts = new Map<number, Map<number, any>>();
	}

	private refreshVerifiedBankAccount(accountIds: [], loanAppId: number) {
		this.autoPayVerifiedBankAccounts = new Map<number, Map<number, any>>();
		this.autoPayVerifiedBankAccounts.set(loanAppId, new Map<number, any>());
		return this.mobileService.verifyBankAccounts(accountIds, loanAppId).pipe(
			tap((bankAccounts) => {
				const verifiedBankAccount = this.autoPayVerifiedBankAccounts.get(loanAppId);
				bankAccounts.forEach((account) => {
					verifiedBankAccount.set(account.bankAccountId, account);
				});
			})
		);
	}
}
