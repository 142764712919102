import { RoutingPathsEnum } from './routing.service';

export class HistoryArray {
	private _history: RoutingPathsEnum[] = [];

	constructor(arr: RoutingPathsEnum[], readonly maxLength: number) {
		this._history = arr.slice(-this.maxLength);
	}

	set routes(arr: RoutingPathsEnum[]) {
		this._history = arr.slice(-this.maxLength);
	}

	get routes(): RoutingPathsEnum[] {
		return this._history;
	}

	get length(): number {
		return this._history.length;
	}

	push(route: RoutingPathsEnum): RoutingPathsEnum[] {
		this._history.push(route);
		if (this._history.length > this.maxLength) {
			this._history.shift();
		}
		return this._history;
	}

	pop(): RoutingPathsEnum {
		return this._history.pop();
	}

	previous(): RoutingPathsEnum {
		if (this._history.length >= 2) {
			return this._history[this._history.length - 2];
		} else {
			return null;
		}
	}

	current(): RoutingPathsEnum {
		if (this._history.length >= 1) {
			return this._history[this._history.length - 1];
		} else {
			return null;
		}
	}
}
