import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CastleService } from '../services/castle/castle.service';
import { mergeMap } from 'rxjs/operators';

/**
 * Adds the Castle api header to the http request.
 *
 * @export
 * @class CastleApiInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class CastleApiInterceptor implements HttpInterceptor {
	castleApiInterceptorEnabled = true;
	constructor(private castleService: CastleService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.castleApiInterceptorEnabled) {
			return next.handle(request);
		}
		return this.castleService.getRequestToken().pipe(
			mergeMap((castleToken: string) => {
				if (castleToken) {
					const appendHeaders = {
						'X-Castle-Request-Token': castleToken
					};
					return next.handle(request.clone({ setHeaders: appendHeaders }));
				}
				return next.handle(request);
			})
		);
	}
}
