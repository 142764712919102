import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VerificationStatusEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';

@Component({
	selector: 'op-income-selection',
	templateUrl: './income-selection.component.html',
	styleUrls: ['./income-selection.component.scss']
})
export class IncomeSelectionComponent {
	formGroup: FormGroup;

	constructor(private formBuilder: FormBuilder) {
		this.createForm(this.formBuilder);
	}

	@Input()
	verifiedMonthlyIncomeAmount: string;

	@Input()
	statedMonthlyIncomeAmount: string;

	@Output()
	next = new EventEmitter<VerificationStatusEnum>();

	public get VerificationStatusEnum() {
		return VerificationStatusEnum;
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			incomeSelection: [null, [Validators.required]]
		});
	}

	onSubmit(post: any): void {
		this.next.emit(post.incomeSelection);
	}
}
