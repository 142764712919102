import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { opRequired } from '../../decorators/required.decorator';
import { MatExpansionPanel } from '@angular/material/expansion';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'op-inline-expansion-panel',
	templateUrl: './inline-expansion-panel.component.html',
	styleUrls: ['./inline-expansion-panel.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'open',
				style({
					display: 'block',
					height: '*'
				})
			),
			state(
				'closed',
				style({
					height: '40px'
				})
			),
			transition('open => closed', [animate('150ms')]),
			transition('closed => open', [animate('150ms')])
		])
	]
})
export class InlineExpansionPanelComponent {
	afterCollapse: boolean = false;

	@Input()
	expanded: boolean = false;

	@Input()
	@opRequired()
	id: string;

	@Output()
	opened = new EventEmitter<void>();

	@Output()
	closed = new EventEmitter<void>();

	openCloseDone(event: any) {
		this.afterCollapse = !this.expanded;
	}

	toggle() {
		this.expanded = !this.expanded;
		this.afterCollapse = false;
	}
}
