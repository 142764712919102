<div class="secured-personal" *transloco="let t">
	<op-masthead [title]="t('SECURED_PERSONAL.LEARN_MORE.whySecuredPersonalLoan')"></op-masthead>

	<op-content-container class="content">
		<op-learn-more
			iconSize="small"
			icon="op-accountingBills"
			[title]="t('SECURED_PERSONAL.LEARN_MORE.whatSecuredPersonalLoan')"
		>
			<div [innerHTML]="t('SECURED_PERSONAL.LEARN_MORE.whatSecuredPersonalLoanAnswer')"></div>
		</op-learn-more>
		<div class="op-mt-30">
			<op-learn-more iconSize="small" icon="op-help" [title]="t('SECURED_PERSONAL.LEARN_MORE.whyGoodOption')">
				<div [innerHTML]="t('SECURED_PERSONAL.LEARN_MORE.whyGoodOptionAnswer')"></div>
			</op-learn-more>
		</div>

		<div class="op-mt-40">
			<button
				mat-flat-button
				color="primary"
				type="button"
				id="acceptOfferBtn"
				(click)="acceptSecuredOffer()"
				[opTagClick]="'See if my vehicle qualifies'"
			>
				{{ t('SECURED_PERSONAL.LEARN_MORE.seeIfVehicleQualifies') }}
			</button>
			<p></p>
			<p class="op-center op-label-font">{{ t('SECURED_PERSONAL.LEARN_MORE.doesNotEffectCredit') }}</p>
		</div>
		<div class="op-mt-20">
			<h3>{{ t('SECURED_PERSONAL.LEARN_MORE.letUsHelp') }}</h3>
			<p [innerHTML]="t('SECURED_PERSONAL.LEARN_MORE.letUsHelpAnswer')"></p>

			<p class="op-center">
				<button
					mat-flat-button
					color="primary"
					type="button"
					id="talkToAgentBtn"
					(click)="talkToAgent()"
					[opTagClick]="'Talk to an agent'"
				>
					<mat-icon svgIcon="op-phoneCall"></mat-icon>{{ t('SECURED_PERSONAL.LEARN_MORE.talkToAnAgent') }}
				</button>
			</p>
		</div>
	</op-content-container>
</div>
