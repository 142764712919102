import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'opSingularPlural'
})
export class SingularPluralPipe implements PipeTransform {
	transform(number: number, singularString: string, pluralString: string = null): string {
		return number > 1 ? `${number} ${pluralString}` : `${number} ${singularString}`;
	}
}

@Pipe({
	name: 'opSingularPluralString'
})
export class SingularPluralStringPipe implements PipeTransform {
	transform(number: number, singularString: string, pluralString: string = null): string {
		return number > 1 ? `${pluralString}` : `${singularString}`;
	}
}
