import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { switchMap } from 'rxjs';
import { isEmpty } from 'lodash';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { ActivatedRoute } from '@angular/router';
import { MobileApiService } from 'src/app/core/services/mobile-api';


@Component({
	selector: 'op-esign-disclosures',
	templateUrl: './esign-disclosures.component.html',
	styleUrls: ['./esign-disclosures.component.scss']
})
export class EsignDisclosuresComponent implements OnInit {
	formGroup: FormGroup;
	token: string;
	clientName: string;
	applicantId: string;
	loanApplicationId: string;

	constructor(
		private formBuilder: FormBuilder,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private activatedRoute: ActivatedRoute,
		private mobileService: MobileApiService
	) {
		this.createForm(this.formBuilder);
	}

	ngOnInit(): void {
		this.token = this.activatedRoute.snapshot.queryParams?.token;

		this.mobileService
			.setEsignDecrypt(this.token)
			.pipe(switchMap((rsp) => this.mobileService.setEsignGet(this.token)))
			.subscribe({
				next: (response) => {
					if (response?.success) {
						if (isEmpty(response.consent)) {
							this.sessionStorageService.set('clientName', response?.data?.clientName);
							this.sessionStorageService.set('esign-token', this.token);

							this.clientName = response.data.clientName;
							this.loanApplicationId = response.data.loanApplicationId;
							this.applicantId = response.data.applicantId;

							this.tagDataService.view(
								{
									loan_application_id: response.data.loanApplicationId,
									applicant_id: response.data.applicantId,
									application_type: 'CONSUMER_INSTALLMENT_LOAN'
								},
								{
									tealium_event: 'esign-disbursement',
									opEventId: 'view'
								}
							);
						} else {
							this.routingService.route(RoutingPathsEnum.findYourApplication);
						}
					} else {
						this.openErrorMessage();
						this.tagDataService.link(
							{},
							{
								tealium_event: 'esign-disbursement',
								event_category: 'CONSUMER_INSTALLMENT_LOAN',
								event_label: 'landing-error'
							}
						);
					}
				}
			});
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			accept: ['', Validators.required]
		});
	}

	onSubmit(post: any): void {
		this.tagDataService.link(
			{
				loan_application_id: this.loanApplicationId,
				applicant_id: this.applicantId

			},
			{
				tealium_event: 'esign-disbursement',
				event_category: 'CONSUMER_INSTALLMENT_LOAN',
				event_label: 'esign-submit-click'
			}
		);
		this.mobileService.setEsignAccept(this.sessionStorageService.get('esign-token')).subscribe({
			next: (res) => {
				if (res?.success) {
				this.routingService.route(RoutingPathsEnum.acceptEsign);
				} else {
					this.openErrorMessage();
					this.tagDataService.link(
						{
							loan_application_id: this.loanApplicationId,
							applicant_id: this.applicantId
						},
						{
							tealium_event: 'esign-disbursement',
							event_category: 'CONSUMER_INSTALLMENT_LOAN',
							event_label: 'esign-submit-error'
						}
					);
				}
			}
		});
	}

	openErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('ESIGN_DISCLOSURE.ERROR_MODAL.title'),
				this.translocoService.translate('ESIGN_DISCLOSURE.ERROR_MODAL.message')
			)
			.subscribe();
	}
}
