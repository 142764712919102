import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

/**
 * Header for site.
 *
 * @export
 * @class HeaderComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Output() public sidenavToggle = new EventEmitter();

	logo: string;

	private _lang: string = 'en';
	get language(): string {
		return this._lang;
	}
	set language(value: string) {
		this._lang = value;
		this.logo = `${this.issuingPartnerService.lender}_${this.language}`;
	}

	private subscription = new Subscription();
	constructor(
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private issuingPartnerService: IssuingPartnerService,
		private loanAppService: LoanApplicationService,
		private landingService: LandingService,
		private sessionStorageService: SessionStorageService
	) {}

	ngOnInit(): void {
		const langSub = this.translocoService.langChanges$
			.pipe(tap((lang) => (this.language = lang.toLocaleLowerCase())))
			.subscribe();
		this.subscription.add(langSub);

		const issuingOrganizationSub = this.issuingPartnerService.issuingOrganization$
			.pipe(tap((lender) => (this.logo = `${this.issuingPartnerService.lender}_${this.language}`)))
			.subscribe();
		this.subscription.add(issuingOrganizationSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public onToggleSidenav = () => {
		this.sidenavToggle.emit();
	};

	clearSessionStorage(): void {
		this.sessionStorageService.clear();
	}

	onPhone(): void {
		this.dialogService.openContactUsDialog(Boolean(this.loanAppService.loanApplicationId)).subscribe();
	}
}
