<div class="sign-online" *transloco="let t">
	<div class="option">
		<span class="op-body-1-bold-font" id="receive-funds-label">
			{{ t('TERMS_CONFIRMED.ONLINE.signOnline') }}
		</span>
		<ul>
			<li *ngIf="showAddBankDesc">
				<div>{{ t('TERMS_CONFIRMED.ONLINE.addBank') }}</div>
			</li>
			<li>
				<div>{{ t('TERMS_CONFIRMED.ONLINE.signDocument') }}</div>
			</li>
			<li>
				<div>{{ t('TERMS_CONFIRMED.ONLINE.fundsInBank') }}</div>
			</li>
		</ul>
		<div class="op-mt-10">
			<ng-content></ng-content>
		</div>
	</div>
</div>
