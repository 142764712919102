<div class="select-bank-account" *transloco="let t">
	<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<p id="{{ id + '_selectAccountTitle' }}">
			{{ t(selectAccountTitle) }}
		</p>

		<div class="op-mt-20">
			<mat-radio-group
				name="accounts"
				id="{{ id + '_selected' }}"
				formControlName="selectedId"
				class="op-mat-radio-group"
			>
				<mat-radio-button
					class="op-mb-20"
					id="{{ id + '_selected_' + i }}"
					*ngFor="let account of accountList.controls; let i = index"
					[value]="account?.get('id').value"
					[checked]="account?.get('selected').value"
				>
					{{ account.get('bankName').value }}
					<br />
					<span class="mat-caption">{{ account.get('details').value }}</span>
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<p></p>
		<div class="op-left" *ngIf="section === 'bank'">
			<p>
				<span>{{ t('SELECT_BANK_ACCOUNT.authorize') }}</span>
				<a
					href=""
					onclick="return false"
					(click)="isTermVisible = !isTermVisible"
					id="termsLink"
					aria-controls="terms-of-loan"
				>
					{{ t('SELECT_BANK_ACCOUNT.termsOfLoanDist') }}
				</a>
			</p>

			<p
				*ngIf="isTermVisible"
				id="terms-of-loan"
				role="status"
				aria-live="assertive"
				[innerHTML]="t('SELECT_BANK_ACCOUNT.termsOfLoanDistContent')"
			></p>
		</div>
		<div class="center">
			<button
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="{{ id + '_submitBtn' }}"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t(!selectAndContinue ? 'SELECT_BANK_ACCOUNT.selectAccountButton' : 'SELECT_BANK_ACCOUNT.continue') }}
			</button>

			<p></p>
			<p id="credit-score-notice" *ngIf="(isSoftPullEnabled$ | async) && !selectAndContinue">
				{{ t('SOFT_PULL.notImpactCreditScore') }}
			</p>
		</div>
	</form>
</div>
