import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';

/**
 * Add the ordinal string to the day value
 *
 * @export
 * @class DayOrdinalPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opDayOrdinal'
})
export class DayOrdinalPipe implements PipeTransform {
	constructor(private dateUtilService: DateUtilsService) {}
	transform(day: number, locale: string): string {
		const date = new Date();
		date.setDate(day);
		return this.dateUtilService.format(date, 'do', locale);
	}
}
