import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import Rollbar from 'rollbar';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';

import { RollbarService } from '../../../vendor/rollbar/rollbar.service';
import versionData from '../../../version.json';
import { IApplicant } from '../loan-application/applicant/applicant.model';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { NewRelicLoggerService } from '../new-relic/new-relic-logger.service';
import { NewRelicMonitorService } from '../new-relic/new-relic-monitor.service';

interface IRollbarPerson {
	id: number | string;
	loanAppId: number | string;
	applicantId: number | string;
	clientId: string;
}

interface IOpRollbarConfig {
	isDevelopment: boolean;
	loanApplicationId: number | string;
	person: IRollbarPerson;
	environment: string;
	code_version: string;
}

@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
	private loanAppService: LoanApplicationService;
	private environmentService: EnvironmentService;
	private newRelicLoggerService: NewRelicLoggerService;
	private newRelicMonitorService: NewRelicMonitorService;
	private subscription: Subscription;

	constructor(@Inject(RollbarService) private rollbar: Rollbar, private injector: Injector) {
		this.loanAppService = this.injector.get(LoanApplicationService);
		this.environmentService = this.injector.get(EnvironmentService);
		this.newRelicLoggerService = this.injector.get(NewRelicLoggerService);
		this.newRelicMonitorService = this.injector.get(NewRelicMonitorService);

		this.rollbar.configure({
			accessToken: this.getRollbarAccessTokenByEnvironment()
		});

		this.subscription = this.loanAppService.loanApplication$
			.pipe(distinctUntilChanged((prev, curr) => this.isIdsEqual(prev, curr)))
			.subscribe({
				next: (loanApp) => {
					this.setRollbarSessionInformation(this.getRollbarConfig(loanApp));
				}
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	handleError(err: any): void {
		try {
			// newrelic tracking during error handling
			this.newRelicLoggerService.handleError(err);
			if (this.newRelicMonitorService.getFlowName() && !this.newRelicMonitorService.getFlowError()) {
				this.newRelicMonitorService.setCriticalError(err);
			}

			//Rollbar tracking
			this.rollbar.error(...this.formatError(err.originalError || err));
		} catch (e) {
			console.log('error:', e);
		}
	}

	private formatError(error: any): Array<any> {
		if (error instanceof Error) {
			return [error.message, error];
		} else if (error instanceof HttpErrorResponse) {
			return [`Http failure response: ${error.status}`, error];
		} else if (error instanceof String) {
			return [error];
		} else {
			return ['Error', error];
		}
	}

	private isAppIdEqual(a: ILoanApplication, b: ILoanApplication): boolean {
		return a?.id === b?.id;
	}

	private isApplicantIdsEqual(a: ILoanApplication, b: ILoanApplication): boolean {
		const aa = a?.applicants?.length ? a.applicants[0] : ({} as IApplicant);
		const ba = b?.applicants?.length ? b.applicants[0] : ({} as IApplicant);
		return aa.id === ba.id && aa.clientId === ba.clientId;
	}

	private isIdsEqual(a: ILoanApplication, b: ILoanApplication): boolean {
		return this.isAppIdEqual(a, b) && this.isApplicantIdsEqual(a, b);
	}

	private getRollbarConfig(loanApp: ILoanApplication): IOpRollbarConfig {
		const currentApplicant = this.loanAppService.getCurrentApplicant();
		return {
			isDevelopment: this.environmentService.isDevHost || this.environmentService.isInternal,
			loanApplicationId: loanApp ? loanApp.id : '',
			person: {
				id: currentApplicant?.id || '',
				loanAppId: loanApp ? loanApp.id : '',
				applicantId: currentApplicant?.id || '',
				clientId: currentApplicant?.clientId || ''
			},
			environment: this.getEnvironment(),
			code_version: `${versionData.build_version}`
		};
	}

	private setRollbarSessionInformation(config: IOpRollbarConfig): void {
		const payload = Object.assign(
			{},
			{
				loanApplicationId: config.loanApplicationId,
				person: config.person,
				environment: config.environment,
				client: {
					javascript: {
						source_map_enabled: true,
						code_version: config.code_version,
						// Optionally have Rollbar guess which frames the error was
						// thrown from when the browser does not provide line
						// and column numbers.
						guess_uncaught_frames: true
					}
				}
			}
		);
		this.rollbar.configure({
			payload: payload,
			verbose: config.isDevelopment,
			scrubTelemetryInputs: !config.isDevelopment
		});
	}

	private getEnvironment(): string {
		if (this.environmentService.isProduction) return 'production';
		if (this.environmentService.isQa) return 'QA';
		if (this.environmentService.isStage) return 'staging';
		if (this.environmentService.isDev) return 'feature-box';

		return 'development';
	}

	private getRollbarAccessTokenByEnvironment(): string {
		if (this.environmentService.isProduction) return 'a8d9dfd9b28b49ce84b06d82e73bd5c2'; // Production
		if (this.environmentService.isQa) return 'e54af2c9b6a640489e1c6a2edf62b0fa'; // QA
		if (this.environmentService.isStage) return 'b26eb692fce7408a8b7dc5f11e65bf28'; // Stage
		if (this.environmentService.isDev) return '2ea51376b05646aeb866696be23d52ee'; // Feature Box

		return '656fb8bf18414cc7aa39b73a871cf436'; // Local Dev
	}
}
