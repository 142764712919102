import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CassAddressService } from 'src/app/core/services/ccas/cass-address.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { AddressTypeEnum, ICassAddress, ILeadsByNameAddress } from 'src/app/core/services/mobile-api/mobile-api.model';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { firstNameValidator, lastNameValidator, middleNameValidator } from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-special-offer',
	templateUrl: './special-offer.component.html',
	styleUrls: ['./special-offer.component.scss']
})
export class SpecialOfferComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private dialog: MatDialog,
		private translocoService: TranslocoService,
		private landingService: LandingService,
		private cassAddressService: CassAddressService,
		private nidService: NeuroIdService,
		private tagDataService: TagDataService,
		private dialogService: DialogService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;
	subscription = new Subscription();

	ngOnInit(): void {
		// Delay the tagDataService.view because it conflicts with the view call made by the router and results in chat not loading
		let loadTimer = timer(500);
		loadTimer.subscribe((n) => {
			this.tagDataService.view(
				{},
				{
					opEventId: 'view',
					tealium_event: 'dp-special-offer-load'
				}
			);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			firstName: ['', [Validators.required, firstNameValidator(), Validators.minLength(2), Validators.maxLength(14)]],
			middleName: [null, [middleNameValidator(), Validators.minLength(1), Validators.maxLength(14)]],
			lastName: ['', [Validators.required, lastNameValidator()]],
			addressDetail: []
		});
	}

	/**
	 * call the leads api with address from formGroup or selected address (data)
	 * from openAddressSelectionDialog choice
	 *
	 * @param {*} data
	 * @return {*}  {Observable<any>}
	 * @memberof SpecialOfferComponent
	 */
	searchForLeadInfo(data: ICassAddress): Observable<any> {
		const leadsData: ILeadsByNameAddress = {
			firstName: this.formGroup.value.firstName,
			lastName: this.formGroup.value.lastName,
			streetAddress1: data?.streetAddress1,
			zipCode: data?.postalCode
		};
		return this.landingService.getLeadsByNameAndAddress(leadsData, 'DIGITAL_PRESCREEN');
	}

	redirectToNextPage(matchFound) {
		this.nidService.setNid('setVariable', 'direct_mail_prescreen', matchFound ? 'dm' : 'non_dm');
		this.router.navigate(matchFound ? [RoutingPathsEnum.offerAvailable] : [RoutingPathsEnum.getOffer]);
	}

	createAddressObject(): any {
		const verifyAddress = this.formGroup.get('addressDetail').value;
		this.tagDataService.link(
			{
				applicant_city: verifyAddress?.city,
				applicant_state: verifyAddress?.state,
				applicant_country: 'US',
				applicant_postal_code: verifyAddress?.zipCode
			},
			{
				tealium_event: 'dp-special-offer-load'
			}
		);
		return {
			type: AddressTypeEnum.home,
			streetAddress1: verifyAddress?.address1,
			streetAddress2: verifyAddress?.address2 || '',
			city: verifyAddress?.city,
			state: verifyAddress?.state,
			postalCode: verifyAddress?.zipCode,
			country: 'US',
			currentAddress: true
		};
	}

	onSubmit(): void {
		const cassVerifySub = this.cassAddressService
			.verifyCassAddress(this.createAddressObject())
			.pipe(
				switchMap((cassAddress) => {
					return this.searchForLeadInfo(cassAddress);
				})
			)
			.subscribe({
				next: (rsp) => {
					this.redirectToNextPage(rsp?.status);
				},
				error: (err) => {
					this.dialogService.openErrorDialog(err).subscribe();
				}
			});
		this.subscription.add(cassVerifySub);
	}
}
