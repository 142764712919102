<div class="credit-auth" *transloco="let t">
	<op-masthead [title]="t('CREDIT_AUTH.title')"></op-masthead>
	<op-content-container class="content">
		<div>
			<div *ngIf="!isMetaOrganization" class="op-mb-16">
				<div class="op-body-1-bold-font">{{ t('CREDIT_AUTH.permission') }}</div>
			</div>

			<span class="op-mt-16 op-body-2-font" id="creditAuthorize" *ngIf="!isSoftPullEnabled && issuingOrganization">
				{{ t('CREDIT_AUTH.authorize.' + issuingOrganization) }}
			</span>

			<span class="op-mt-16 op-body-2-font" id="softPullAurhorize" *ngIf="isSoftPullEnabled && issuingOrganization">
				{{ t('SOFT_PULL.authorize.' + issuingOrganization) }}
			</span>

			<div class="op-body-1-bold-font op-mt-16" *ngIf="!isMetaOrganization">
				{{ t('CREDIT_AUTH.myPermission') }}
			</div>

			<div class="op-mt-12 op-mb-16">
				<span *ngIf="!isSoftPullEnabled">
					<span [ngClass]="isMetaOrganization ? 'op-body-2-font' : 'op-body-2-font'">
						{{ t('CREDIT_AUTH.disclosures1') }}
						<a [routerLink]="['/loan-disclosures']" id="loanDisclosuresLink">{{ t('CREDIT_AUTH.disclosures2') }}</a>
						{{ t('CREDIT_AUTH.disclosures3') }}
						<a href="" onclick="return false" (click)="toggleWirelessOperationsAuth()" id="wirelessOperationsAuthLink">
							{{ t('CREDIT_AUTH.wirelessOperationsAuth') }} </a
						>.
					</span>
				</span>
				<span *ngIf="isSoftPullEnabled">
					<span [ngClass]="isMetaOrganization ? 'op-body-2-font' : 'op-body-2-font'">
						{{ t('SOFT_PULL.disclosures1') }}
						<a [routerLink]="['/loan-disclosures']" id="loanDisclosuresLink">{{ t('SOFT_PULL.disclosures2') }}</a>
						{{ t('SOFT_PULL.disclosures3') }}
						<a href="" onclick="return false" (click)="toggleWirelessOperationsAuth()" id="wirelessOperationsAuthLink">
							{{ t('SOFT_PULL.wirelessOperationsAuth') }} </a
						>.
					</span>
				</span>
			</div>

			<div class="op-mb-20" *ngIf="showWirelessOperationsAuth" id="wirelessOperationsAuth">
				{{ t('CREDIT_AUTH.wirelessOperationsAuthMsg') }}
			</div>

			<div
				*ngIf="!isMetaOrganization"
				class="op-micro-font"
				id="oportunDisclosure4"
				[innerHTML]="t('CREDIT_AUTH.disclosures4')"
			></div>
		</div>

		<form class="op-mt-20" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div class="op-form-group op-mb-20">
				<mat-radio-group name="creditCheck" id="creditCheck" formControlName="creditCheck">
					<mat-radio-button
						*ngFor="let item of toggleCreditCheckOptions"
						id="{{ 'credit_' + item.key }}"
						[value]="item.key"
					>
						{{ t(item.text) }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div id="credit-score-notice" class="op-icon-align-body-1" *ngIf="isSoftPullEnabled">
				<mat-icon svgIcon="op-info"></mat-icon>
				<span [innerHTML]="t('CREDIT_AUTH.willNotImpactCreditScore')"></span>
			</div>
			<div
				*ngIf="customerState | opIsStateEqual: 'OH'"
				class="op-label-font op-gray op-mt-10"
				id="credit-equally-statement"
			>
				{{ t('CREDIT_AUTH.ohCreditEquallyStatement') }}
			</div>

			<div class="op-center">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					[opTagClick]="'Credit-auth next'"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>

		<div class="op-center op-mt-8">
			<div *ngIf="customerState | opIsStateEqual: 'CA'" class="op-label-font op-gray" id="married-text">
				{{ t('CREDIT_AUTH.married') }}
			</div>
			<div *ngIf="customerState | opIsStateEqual: 'WI'" class="op-label-font op-gray" id="property-statement">
				{{ t('CREDIT_AUTH.wiMaritalPropertyStatement') }}
			</div>
		</div>

		<div class="op-pt-40"></div>
	</op-content-container>
</div>
