<div class="bank-criteria-met" *transloco="let t">
	<div
		class="criteria-wrapper"
		[ngClass]="{
			attention: !criteria[criteriaEnum.zeroToThirtyDays] && !criteria[criteriaEnum.thirtyOneToSixtyFiveDays],
			attention2:
				(!criteria[criteriaEnum.zeroToThirtyDays] || !criteria[criteriaEnum.thirtyOneToSixtyFiveDays]) &&
				(criteria[criteriaEnum.zeroToThirtyDays] || criteria[criteriaEnum.thirtyOneToSixtyFiveDays]),
			met: criteria[criteriaEnum.zeroToThirtyDays] && criteria[criteriaEnum.thirtyOneToSixtyFiveDays]
		}"
	>
		<div class="icon-align" [ngClass]="{ met: criteria[criteriaEnum.zeroToThirtyDays] }" id="criteria0_30">
			<mat-icon *ngIf="!criteria[criteriaEnum.zeroToThirtyDays]" svgIcon="op-attention"></mat-icon>
			<mat-icon
				class="op-white"
				*ngIf="criteria[criteriaEnum.zeroToThirtyDays]"
				svgIcon="op-checkMark-dark-green"
			></mat-icon>
			<span *ngIf="!criteria[criteriaEnum.zeroToThirtyDays]" id="criteriaNotMet0_30" class="icon-text">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteria.0_30_DAYS') }}
			</span>
			<span *ngIf="criteria[criteriaEnum.zeroToThirtyDays]" id="criteriaMet0_30" class="icon-text">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteriaMet.0_30_DAYS') }}
			</span>
		</div>
		<div
			class="icon-align op-mt-8"
			[ngClass]="{ met: criteria[criteriaEnum.thirtyOneToSixtyFiveDays] }"
			id="criteria31_65"
		>
			<mat-icon *ngIf="!criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" svgIcon="op-attention"></mat-icon>
			<mat-icon
				class="op-white"
				*ngIf="criteria[criteriaEnum.thirtyOneToSixtyFiveDays]"
				svgIcon="op-checkMark-dark-green"
			></mat-icon>
			<span *ngIf="!criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" id="criteriaNotMet31_65" class="icon-text">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteria.31_65_DAYS') }}
			</span>
			<span *ngIf="criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" id="criteriaMet31_65" class="icon-text">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteriaMet.31_65_DAYS') }}
			</span>
		</div>
	</div>
</div>
