import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';

@Component({
	selector: 'op-credit-card-img',
	templateUrl: './credit-card-img.component.html',
	styleUrls: ['./credit-card-img.component.scss']
})
export class CreditCardImgComponent implements OnInit, OnDestroy {
	cardHolderName: string;
	@Input () productCategory: string;

	private subscription = new Subscription();
	constructor(private loanAppService: LoanApplicationService) {}

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean), take(1)).subscribe({
			next: (loanApp) => {
				this.getCardHolderName(this.loanAppService.getCurrentApplicant());
			}
		});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private getCardHolderName(applicant: IApplicant) {
		/**
		 * @description
		 * The generic format is First name (space) Middle name (Initial) (space) Last name.
		 * If sum (length of first name middle name and last name) > 21 characters , then check
		 * sum(If the sum (length of first name and last name) = 21 characters, then show the first name (full) last name (full)
		 * else if sum( length of first name and last name) > 21 characters,
		 * then show the first name (initial) middle (initial) last name (upto 19 characters),
		 * else show First name (space) Middle name (Initial) (space) Last name (full).
		 * Else show First name (space) Middle name (Initial) (space) Last name (full)
		 */
		const firstNameLength = applicant.firstName.length;
		const lastNameLength = applicant.lastName.length;
		if (firstNameLength + lastNameLength === 21) {
			this.cardHolderName = `${applicant.firstName} ${applicant.lastName}`;
		} else if (firstNameLength + lastNameLength > 21) {
			this.cardHolderName = `${applicant.firstName.charAt(0)} ${
				applicant.middleName?.charAt(0) || ''
			} ${applicant.lastName.slice(0, 19)}`;
		} else {
			this.cardHolderName = `${applicant.firstName} ${applicant.middleName?.charAt(0) || ''} ${applicant.lastName}`;
		}
		this.cardHolderName = this.cardHolderName.toUpperCase();
	}
}
