import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import { isEmpty } from 'lodash';

import { isFunction } from 'lodash';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';

import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingService, RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';

import { SelectBankAccountComponent } from '../select-bank-account/select-bank-account.component';
import { ProductSubStatusesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';

import { AutoPayBankAccountVerificationService } from 'src/app/core/services/auto-pay-bank-account-verification/auto-pay-bank-account-verification.service';

@Component({
	selector: 'op-select-bank-account-auto-pay',
	templateUrl: './select-bank-account-auto-pay.component.html',
	styleUrls: ['./select-bank-account-auto-pay.scss']
})
export class SelectBankAccountAutoPayComponent extends SelectBankAccountComponent {
	groupedAccountList: Map<string, IAchBankAccount[]>;

	constructor(
		protected formBuilder: FormBuilder,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected loanAppService: LoanApplicationService,
		protected softPull: SoftPullService,
		protected sessionStorageService: SessionStorageService,
		protected mobileService: MobileApiService,
		protected routingService: RoutingService,
		private autoPayBankAccountVerificationService: AutoPayBankAccountVerificationService
	) {
		super(
			formBuilder,
			bankAccountService,
			tagDataService,
			loanAppService,
			softPull,
			sessionStorageService,
			mobileService
		);
	}

	ngOnInit(): void {
		this.selectAccountTitle = this.getSelectAccountTitle();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.autoPayBankAccountVerificationService.reset();
	}

	@Input()
	set accounts(accounts: IAchBankAccount[]) {
		if (accounts?.length) {
			this.updateFormData(accounts);

			if (accounts?.length === 1) {
				this.mobileService.setUserAccountId(accounts[0].id);
				accounts[0].selected = true;
			}

			if (accounts?.length > 1 && !this.loanAppService.isGCPOrReturnApplicant()) {
				this.createTealiumEvent();
			}
		}
		if (this.groupedAccountList) {
			this.groupedAccountList = new Map();
		}
		if (isEmpty(accounts)) {
			const disbursement = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
			if (
				disbursement.onlineNotificationEnabled &&
				disbursement.isChannelAnyStore() &&
				!this.routingService.isSplStorelessEligibleWithStatus(ProductSubStatusesEnum.complete)
			) {
				this.routingService.route(RoutingPathsEnum.termsConfirmed);
			} else {
				this.routingService.route(RoutingPathsEnum.esignSignDocument);
			}
		}

		this.groupedAccountList = accounts.reduce(
			(entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]),
			new Map(this.groupedAccountList)
		);
		this.showAccountList = Boolean(accounts.length);
	}

	updateSelectedValidator(selected: number): void {
		let validators = [Validators.required];
		this.formGroup.get('selectedId').setValidators(validators);
		this.formGroup.get('selectedId').updateValueAndValidity();
	}

	radioChange($event: MatRadioChange) {
		this.submitAutoPay($event.value);
		this.mobileService.setUserAccountId($event.value);
	}

	submitAutoPay(selectedAccountId: any): void {
		const selectedAccount = selectedAccountId;
		const bank = {
			bankAccountId: selectedAccountId,
			customerConfirms: true
		};
		const updateBankAccount$ = this.postApproval
			? this.bankAccountService.updateBankAccountPostApproval(bank.bankAccountId).pipe(take(1))
			: this.bankAccountService.selectBankAccount(bank).pipe(take(1));

		updateBankAccount$.subscribe({
			next: () => {
				this.checkVerificationStatus.emit(selectedAccount);
			}
		});
	}

	allAccountsVerified(userAccounts: any[]): Observable<any> {
		if (userAccounts?.length === 0) {
			return of([]);
		}

		const disbursement = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
		if (disbursement.isTypeAch()) {
			return of(userAccounts.filter((account) => account.status === 'ACTIVE'));
		}
		const bankAccountIds = Array.from(
			userAccounts.map((accountIds) => {
				return accountIds.id;
			})
		) as [];
		return this.autoPayBankAccountVerificationService
			.verifyBankAccounts(bankAccountIds, this.loanAppService.loanApplicationId)
			.pipe(
				map((accounts) => {
					const everyAccountsVerified = accounts.every((account) => account.status === 'ACTIVE');
					return !everyAccountsVerified && isFunction(this.filter) ? userAccounts.filter(this.filter) : userAccounts;
				})
			);
	}
}
