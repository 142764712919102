import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-vendor-general-info',
	template: '',
	styles: ['']
})
export class VendorGeneralInfoComponent implements OnInit {
	constructor(private route: ActivatedRoute, private routingService: RoutingService, private router: Router) {}

	ngOnInit(): void {
		if (this.routingService.hasValidReferralParams()) {
			this.router.navigate(['vendor-personal-info'], { queryParamsHandling: 'preserve' });
		} else {
			this.routingService.route(RoutingPathsEnum.home);
		}
	}
}
