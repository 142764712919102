<div class="funding-alternative" *transloco="let t">
	<div class="op-mb-10">
		<op-info-tip id="cutOffTip" [key]="t('ACH_DISBURSEMENT_SELECTION.fundingCutOffInfoTip')">
			{{ 'ACH_DISBURSEMENT_SELECTION.fundingCutOffDisclaimer' | transloco }}
		</op-info-tip>
	</div>
	<div class="op-header-2-font op-mb-5">
		{{ t('RECEIVE_FUNDS.otherWays') }}
	</div>
	<div id="fundingInfo" class="op-body-1-font">
		{{ t(alternateFundingKey, { partner: partnerBrand }) }}
	</div>
</div>
