<div *ngIf="showNewDesign === true">
	<op-funding-method></op-funding-method>
</div>

<div *ngIf="showNewDesign === false">
	<div class="funds" *transloco="let t">
		<op-masthead [title]="t('FUNDS.title')"></op-masthead>
		<op-content-container class="content" *ngIf="initialized">
			<div *ngIf="!accountList.length">
				<div class="op-mb-20">{{ t('FUNDS.selectOption') }}</div>
				<op-funds-check
					#checkComponent
					(next)="onCheckSelection($event)"
					[checkAtStore]="isCheckAtStore"
					[issuingOrganization]="issuingOrganization"
				></op-funds-check>

				<div *ngIf="isCheckAtStore" id="storeFunds">
					<div class="op-mtb-20 op-header-1-font">{{ t('FUNDS.fundsInBankTitle') }}</div>
					<div class="op-mb-40">{{ t('FUNDS.fundsInBank') }}</div>
				</div>
				<div *ngIf="!isCheckAtStore" id="mailFunds">
					<div class="op-mtb-20 op-header-1-font">{{ t('FUNDS.fundsSooner') }}</div>
					<div class="op-mb-40">{{ t('FUNDS.connectBank') }}</div>
				</div>

				<button
					class="op-full-width"
					mat-flat-button
					color="primary"
					name="connectBank"
					id="connectBank"
					(click)="onAddBank($event)"
					[opTagClick]="'connectBank'"
				>
					{{ t('PLAID_BANNER.connectBank') }}
				</button>
			</div>

			<div *ngIf="accountList.length">
				<div class="op-mb-5">{{ t('FUNDS.whichOptionToReceive') }}</div>
				<div class="op-label-font op-gray op-mb-10">{{ t('FUNDS.receiveYourFunds') }}</div>

				<op-funds-disbursement-selection
					id="bankDisbursementSelect"
					(next)="onDisbursementSelection($event)"
					[checkAtStore]="isCheckAtStore"
					[issuingOrganization]="issuingOrganization"
				>
				</op-funds-disbursement-selection>

				<div class="op-center op-mt-20 op-mb-30">
					<a
						mat-flat-button
						color="link-md"
						href=""
						onclick="return false"
						id="addAnotherBankLink"
						(click)="onAddBank($event)"
						[opTagClick]="'addAnotherBank'"
					>
						{{ t('FUNDS.addAnotherBank') }}
					</a>
				</div>
			</div>
		</op-content-container>
	</div>
</div>
