export interface ISpousalContact {
	firstName: string;
	lastName: string;
	cassVerified?: boolean;
	streetAddress1: string;
	streetAddress2?: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
}
export interface ISpousalContactResult {
	firstName: string;
	lastName: string;
	cassVerified: boolean;
	streetAddress1: string;
	streetAddress2?: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	cassGeoTld?: string;
	cassGeoMatchFlag?: boolean;
	cassGeoTract?: string;
	cassGeoBlock?: string;
	cassGeoToLatitude?: string;
	cassGeoToLongitude?: string;
}

export enum AddressCategoryEnum {
	mailAddress = 'mailAddress',
	differentAddress = 'differentAddress'
}
