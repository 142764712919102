<div class="document-collation" *transloco="let t">
	<op-back-link class="op-mtb-20" [green]="false" id="colation Back" (back)="onBack()">
		<span *ngIf="criteria === criteriaEnum.zeroToThirtyDays">{{
			t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.month1')
		}}</span>
		<span *ngIf="criteria === criteriaEnum.thirtyOneToSixtyFiveDays">{{
			t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.month2')
		}}</span>
	</op-back-link>
	<ul class="op-body-2-font op-mb-20">
		<li class="op-body-2">
			<span>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.statementEndDate') }}</span>
			<span class="op-body-2-bold-font"> {{ dateRangeString }} </span>
		</li>
		<li>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.30Days') }}</li>
	</ul>

	<op-collation-selection [disabled]="disableAddMore" criteria="criteria" #selection></op-collation-selection>

	<op-document-notification *ngIf="documentSize > maxDocumentSize" class="needs op-mt-10">
		<div class="icon-align">
			<mat-icon svgIcon="op-alert4"></mat-icon>
			<div class="icon-text">
				<div class="op-body-2-font">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.fileSizeExceeds') }}
				</div>
			</div>
		</div>
	</op-document-notification>

	<op-document-notification *ngIf="documentCount === maxDocumentCount" class="needs op-mt-10">
		<div class="icon-align">
			<mat-icon svgIcon="op-alert4"></mat-icon>
			<div class="icon-text">
				<div class="op-body-2-font">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.maxDocuments') }}
				</div>
			</div>
		</div>
	</op-document-notification>

	<div class="op-mt-30">
		<button
			mat-flat-button
			color="primary"
			class="op-full-width"
			name="collationUploadBtn"
			id="collationUploadBtn"
			[disabled]="uploadDisabled"
			type="submit"
			(click)="onContinue()"
			[opTagClick]="'Document collation Upload'"
		>
			{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.upload') }}
		</button>
	</div>
</div>
