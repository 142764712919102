import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { camelCase } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
	EventDataService,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { MobileApiBusyNotifierService } from '../mobile-api-busy-notifier/mobile-api-busy-notifier.service';
import { RoutingPathsEnum, RoutingService } from '../routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from '../storage/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class PlaidBannerService implements OnDestroy {
	private readonly plaidBannerSource = new BehaviorSubject<boolean>(false);
	readonly plaidBanner$ = this.plaidBannerSource.asObservable();

	private readonly storageKey = 'plaidBanner';
	private readonly storageKeyShow = 'plaidBannerShow';
	private plaidOffered = true;
	private subscription = new Subscription();

	private shouldShow = false;
	private pageList = [RoutingPathsEnum.identification, RoutingPathsEnum.preQualifyIdentification];

	constructor(
		private router: Router,
		private busyService: MobileApiBusyNotifierService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService,
		private eventDataService: EventDataService,
		private tagDataService: TagDataService
	) {
		const sessionData = this.sessionStorageService.get(this.storageKey);
		this.shouldShow = this.sessionStorageService.get(this.storageKeyShow);
		const plaidConnectAddressScreenOrderSwapEnabled = Boolean(
			this.sessionStorageService.get(STORAGE_KEYS.PLAID_CONNECT_ADDRESS_SCREEN_ORDER_SWAP_ENABLED)
		);

		if (!plaidConnectAddressScreenOrderSwapEnabled) {
			this.pageList = [
				RoutingPathsEnum.address,
				RoutingPathsEnum.identification,
				RoutingPathsEnum.preQualifyIdentification
			];
		}
		sessionData ? this.show() : this.hideBanner();

		const routingSub = this.router.events
			.pipe(
				filter((event: Event) => event instanceof NavigationEnd),
				map((r: RoutesRecognized) => new URL(r.urlAfterRedirects, window.location.origin).pathname),
				map((path: string) => RoutingPathsEnum[camelCase(path.substring(1))])
			)
			.subscribe({
				next: (page: RoutingPathsEnum) => {
					if (this.canShow(page)) {
						this.show(page);
					} else if (this.plaidBannerSource.value) {
						this.hideBanner();
					}
				}
			});
		this.subscription.add(routingSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private canShow(page: RoutingPathsEnum): boolean {
		return this.shouldShow && this.pageList.some((p) => p === page);
	}

	private setShouldShow(value: boolean): void {
		this.shouldShow = value;
		this.sessionStorageService.set(this.storageKeyShow, this.shouldShow);
	}

	private showBanner(): void {
		this.sessionStorageService.set(this.storageKey, true);
		this.plaidBannerSource.next(true);
		this.logToLoanApplication();
		this.sendTagEvent();
	}

	show(page: RoutingPathsEnum = null): void {
		this.setShouldShow(true);
		this.busyService.busy$
			.pipe(
				filter((r) => !Boolean(r)),
				take(1)
			)
			.subscribe({
				next: () => {
					if (this.canShow(page || this.routingService.getCurrentRoute())) {
						this.showBanner();
					}
				}
			});
	}

	private hideBanner(): void {
		this.sessionStorageService.remove(this.storageKey);
		this.plaidBannerSource.next(false);
	}

	hide(): void {
		this.setShouldShow(false);
		this.hideBanner();
	}

	toggle(): void {
		this.plaidBannerSource.value ? this.hide() : this.show();
	}

	offered(): boolean {
		return this.plaidOffered;
	}

	setOffered(offered: boolean): void {
		this.plaidOffered = offered;
	}

	private logToLoanApplication(): void {
		const plaidEvent = {
			eventType: EventDataTagTypeEnum.bannerEventType,
			value1: EventDataTagValueEnum.bannerValue + '_' + this.routingService.getCurrentRoute()
		};
		this.eventDataService.logEventDataToLoanApplication(plaidEvent);
	}

	private sendTagEvent(): void {
		const tagEvent = {
			tealium_event: EventDataTagTypeEnum.bannerEventType,
			event_action: EventDataTagValueEnum.bannerValue + '_' + this.routingService.getCurrentRoute()
		};
		this.tagDataService.link({}, tagEvent);
	}
}
