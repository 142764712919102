import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { every, isFunction } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IAchBankAccountResult } from 'src/app/core/services/mobile-api';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { opRequired } from '../../decorators/required.decorator';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';

@Component({
	selector: 'op-select-bank-account',
	templateUrl: './select-bank-account.component.html',
	styleUrls: ['./select-bank-account.component.scss']
})
export class SelectBankAccountComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	filter: Function;

	@Input()
	fromPlaid: boolean;

	@Input()
	selectAndContinue: boolean;

	@Input()
	section: string;

	@Input()
	postApproval: boolean;

	@Input()
	isGCPOrReturn: boolean;

	@Output()
	checkVerificationStatus = new EventEmitter<IAchBankAccount>();

	constructor(
		protected formBuilder: FormBuilder,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected loanAppService: LoanApplicationService,
		protected softPull: SoftPullService,
		protected sessionStorageService: SessionStorageService,
		protected mobileService: MobileApiService
	) {
		this.createForm(formBuilder);
	}
	formGroup: FormGroup;

	showAccountList = false;

	isSoftPullEnabled$: Observable<boolean>;

	subscription = new Subscription();

	currentlySelection: number;

	bankAccountList$: Observable<IAchBankAccount[]>;

	selectAccountTitle: string;

	ngOnInit(): void {
		this.isSoftPullEnabled$ = this.softPull.softPull$.pipe(map((rsp) => rsp?.value));

		if (this.isGCPOrReturn) {
			this.bankAccountList$ = this.mobileService.getAchBankAccountsForApplicant(this.loanAppService.loanApplicationId);
		} else {
			this.bankAccountList$ = this.bankAccountService.bankAccounts$;
		}

		const bankSub = this.bankAccountList$
			.pipe(
				filter((r) => Boolean(r)),
				tap(() => this.accountList.clear()),
				map((rsp) => (!this.areAllAccountsVerified(rsp) && isFunction(this.filter) ? rsp.filter(this.filter) : rsp))
			)
			.subscribe({
				next: (accounts) => {
					if (accounts.length) {
						this.updateFormData(accounts);

						if (accounts?.length > 1 && !this.isGCPOrReturn) {
							this.createTealiumEvent();
						}
					}
					this.showAccountList = Boolean(accounts.length);
				}
			});
		this.subscription.add(bankSub);

		this.selectAccountTitle = this.getSelectAccountTitle();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	protected updateFormData(accounts: IAchBankAccount[]): void {
		//When the plaid callBack is called, this flag will be set as true and the first item will be the selected item except for postApproval
		if (!this.currentlySelection && this.fromPlaid && !this.postApproval) {
			accounts[0].selected = true;
		}
		accounts.forEach((item) => {
			if (item.selected) {
				this.currentlySelection = item.id;
				this.formGroup.get('selectedId').setValue(item.id);
				this.updateSelectedValidator(item.id);
			}
			this.increaseAccount(item);
		});
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			selectedId: [null, [Validators.required]],
			accounts: new FormArray([])
		});
	}

	getSelectAccountTitle(): string {
		if (this.id === 'selectPrevBankAccount') {
			return 'SELECT_BANK_ACCOUNT.prevBankAccount';
		} else if (this.isGCPOrReturn) {
			return 'SELECT_BANK_ACCOUNT.prevBankAccountDesc';
		} else if (this.selectAndContinue) {
			return 'SELECT_BANK_ACCOUNT.deposit';
		} else {
			return 'SELECT_BANK_ACCOUNT.selectAccount';
		}
	}

	updateSelectedValidator(selected: number): void {
		let validators = [Validators.required];
		if (!Boolean(this.selectAndContinue)) {
			validators.push(this.mustHaveChanged(selected));
		}
		this.formGroup.get('selectedId').setValidators(validators);
		this.formGroup.get('selectedId').updateValueAndValidity();
	}

	mustHaveChanged(currentSelected: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.errors && !control.errors.mustHaveChanged) {
				return;
			}
			if (this.fromPlaid) {
				return null;
			}

			if (control.value === currentSelected) {
				return { mustHaveChanged: true };
			} else {
				return null;
			}
		};
	}

	get formControls(): any {
		return this.formGroup.controls;
	}
	get accountList(): FormArray {
		return this.formControls.accounts as FormArray;
	}

	createFormAccount(account: IAchBankAccount): FormGroup {
		return this.formBuilder.group({
			id: [account.id],
			selected: [account.selected || false],
			accountType: [account.accountType],
			bankName: [account.bankName],
			details: [this.getAccountDetails(account)],
			verificationStatus: [account.verificationStatus],
			routingNumber: [account.routingNumber],
			accountNumber: [account.accountNumber],
			status: [account.status]
		});
	}

	increaseAccount(account: IAchBankAccount): void {
		this.accountList.push(this.createFormAccount(account));
	}

	getAccountDetails(account: IAchBankAccount): string {
		return `${account.accountType} ${account.accountNumber}`;
	}

	onSubmit(post: any): void {
		const selectedAccount = this.getSelectedAccount(post);
		const bank = {
			bankAccountId: post.selectedId,
			customerConfirms: true
		};
		const updateBankAccount$ = this.postApproval
			? this.bankAccountService.updateBankAccountPostApproval(bank.bankAccountId).pipe(take(1))
			: this.bankAccountService.selectBankAccount(bank).pipe(take(1));

		updateBankAccount$.subscribe({
			next: () => {
				this.checkVerificationStatus.emit(selectedAccount);
			}
		});
	}

	getSelectedAccount(post: any): IAchBankAccount {
		return post.accounts.find((item) => item.id === post.selectedId);
	}

	areAllAccountsVerified(bankAccounts: IAchBankAccountResult[]): boolean {
		return every(bankAccounts, (bankAccount) => bankAccount?.verificationStatus == AccountStatusEnum.verified);
	}

	protected createTealiumEvent(): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: 'another_bank_account_added',
				event_action: 'submit',
				event_label: 'Form',
				event_category: 'rx_form',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			}
		);
	}
}
