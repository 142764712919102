<op-layout>
	<mat-sidenav-container>
		<mat-sidenav #sidenav mode="over" position="end" role="navigation">
			<op-side-nav
				[navItems]="navItems"
				[navFooterItems]="navFooterItems"
				(sidenavClose)="sidenav.close()"
			></op-side-nav>
		</mat-sidenav>

		<mat-sidenav-content>
			<op-header (sidenavToggle)="sidenav.toggle()"></op-header>
			<main>
				<div class="inner-padding">
					<op-stepper bar="true"></op-stepper>
					<router-outlet #outlet="outlet"></router-outlet>
				</div>
			</main>
			<op-footer></op-footer>
			<op-plaid-banner></op-plaid-banner>
		</mat-sidenav-content>
	</mat-sidenav-container>
	<ngx-spinner
		id="busySpinner"
		bdColor="rgba(51,51,51,0.2)"
		[fullScreen]="true"
		template="<img src='assets/images/spinnerGreen.gif' width='80' height='80'/>"
	>
	</ngx-spinner>
</op-layout>
