import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IMetadata, MobileApiService } from 'src/app/core/services/mobile-api';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { format } from 'date-fns';

import { companyNameValidator } from '../../../shared/validators/form-validators';
import { of } from 'rxjs';

export interface AddIncomeSourceDialogData {
	id: string;
	parent: FormGroup;
	count: number;
	index: number;
	newSourceText: string;
	incomeTypeList: Array<IMetadata>;
	incomeFrequencyList: Array<IMetadata>;
	industryTypeList: Array<IMetadata>;
}

@Component({
	selector: 'op-pre-qualify-add-income-source-dialog',
	templateUrl: './add-income-source-dialog.component.html',
	styleUrls: ['./add-income-source-dialog.component.scss']
})
export class AddIncomeSourceDialogComponent implements OnInit {
	id: string;
	parent: FormGroup;
	count: number;
	index: number;
	newSourceText: string;
	incomeTypeList: Array<IMetadata>;
	incomeFrequencyList: Array<IMetadata>;
	industryTypeList: Array<IMetadata>;

	@Output()
	save = new EventEmitter<any>();

	@Output()
	delete = new EventEmitter<any>();

	@Output()
	opened = new EventEmitter<any>();

	@Output()
	closed = new EventEmitter<any>();

	isIncomeSourceOther: boolean;
	isIncomeSourceJob: boolean;

	originalValue;

	readonly JOB = 1700; // Value for "job" in the IncomeSourceType enum
	readonly SELF_EMPLOYED = 1701; // Value for "self employed" in the IncomeSourceType enum
	readonly OTHER = 1705; // Value for "other" in the IncomeSourceType enum

	constructor(
		public dialogRef: MatDialogRef<AddIncomeSourceDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: AddIncomeSourceDialogData,
		private formBuilder: FormBuilder,
		private dialogService: DialogService,
		private translocoService: TranslocoService
	) {}

	ngOnInit(): void {
		this.setValues(this.data);
		this.originalValue = { ...this.parent.value };
		this.isIncomeSourceJob = this.isJob(this.parent);
	}

	restoreOriginalValue(): void {
		if (this.originalValue?.id) {
			this.parent.setValue(this.originalValue);
		}
		this.isIncomeSourceOther = this.isOtherIncome(this.parent);
		this.updateValidators(this.parent);
	}
	/**
	 * Determines and returns the appropriate title of an income. If no title is
	 * available, it returns null.
	 *
	 * If the income is a job, it returns the company attribute.
	 * If the income is 'other', it returns the incomeDescription attribute.
	 * If the income is not a job and is not 'other', it returns the text attribute
	 * of the currently selected incomeSourceType enum.
	 * Returns null otherwise.
	 */
	incomeTitle(income: AbstractControl): string {
		if (this.isJob(income)) {
			return income.value.company || this.newSourceText;
		} else if (!income.value.incomeSourceType) {
			return this.newSourceText;
		} else if (this.isOtherIncome(income)) {
			return income.value.incomeDescription || this.newSourceText;
		}
		return this.incomeTypeList?.find((value) => value.key === income.value.incomeSourceType)?.text;
	}

	getFrequencyText(income: AbstractControl): string {
		return this.incomeFrequencyList?.find((value) => value.key === income.value.paymentFrequency)?.text;
	}

	/**
	 * Returns the month and year an income started in a human readable format.
	 * Used in the header of each income in the accordion.
	 */
	startedDate(income: AbstractControl): string {
		if (income.value.startDate) {
			return format(income.value.startDate, 'MMM yyyy');
		} else {
			return '';
		}
	}

	/**
	 * Helper function to determine if an income source is a job.
	 * Also available through $scope
	 */
	isJob(income: AbstractControl): boolean {
		return income.value.incomeSourceType === this.JOB;
	}

	/**
	 * Helper function to determine if an income source is self employed.
	 * Also available through $scope
	 */
	isSelfEmployed(income: AbstractControl): boolean {
		return income.value.incomeSourceType === this.SELF_EMPLOYED;
	}

	/**
	 * Helper function to determine if an income source is a job or self employed
	 * Also available through $scope
	 */
	isJobOrSE(income: AbstractControl): boolean {
		return this.isJob(income) || this.isSelfEmployed(income);
	}

	/**
	 * Helper function to determine if an income source is "other"
	 * Also available through $scope
	 */
	isOtherIncome(income: AbstractControl): boolean {
		return income.value.incomeSourceType === this.OTHER;
	}

	saveIncome(income: FormGroup): void {
		this.originalValue = { ...this.parent.value };
		this.dialogRef.close({ action: 'save', income: income });
	}

	confirmDelete(income: FormGroup, index): void {
		const data = {
			title: this.translocoService.translate('PRE_QUALIFY.Income.AddIncome.deleteConfirmTitle'),
			message: this.translocoService.translate('PRE_QUALIFY.Income.AddIncome.deleteConfirmMessage'),
			confirmText: this.translocoService.translate('PRE_QUALIFY.Income.AddIncome.deleteConfirmBtnCancel'),
			cancelText: this.translocoService.translate('PRE_QUALIFY.Income.AddIncome.deleteConfirmBtnDelete'),
			panelClass: 'pre-qualify'
		};
		this.dialogService
			.openMessageDialog(
				data,
				(res) => of(this.deleteIncome(income, index)),
				() => of()
			)
			.subscribe();
	}

	deleteIncome(income: FormGroup, index): void {
		this.dialogRef.close({ action: 'delete', income: { income, index } });
	}

	panelOpened(income: FormGroup): void {
		this.opened.emit(income);
	}

	panelClosed(income: FormGroup): void {
		this.isIncomeSourceJob = this.isJob(income);
		this.closed.emit(income);
	}

	setValues(data: AddIncomeSourceDialogData): void {
		this.id = data.id;
		this.parent = data.parent;
		this.count = data.count;
		this.index = data.index;
		this.newSourceText = data.newSourceText;
		this.incomeTypeList = data.incomeTypeList;
		this.incomeFrequencyList = data.incomeFrequencyList;
		this.industryTypeList = data.industryTypeList;
	}
	updateValidators(income: FormGroup): void {
		this.isIncomeSourceOther = this.isOtherIncome(income);
		if (this.isIncomeSourceOther) {
			income.get('incomeDescription').setValidators(Validators.required);
			income.get('company').setValue(null);
			income.get('company').setValidators([companyNameValidator()]);
			income.get('industryType').setValidators(null);
			income.get('industryType').setValue(null);
		} else if (this.isJob(income)) {
			income.get('incomeDescription').setValidators(null);
			income.get('incomeDescription').setValue(null);
			income.get('company').setValidators([Validators.required, companyNameValidator()]);
			income.get('industryType').setValidators(Validators.required);
		} else if (this.isJobOrSE(income)) {
			income.get('incomeDescription').setValidators(null);
			income.get('incomeDescription').setValue(null);
			income.get('company').setValidators([companyNameValidator()]);
			income.get('company').setValue(null);
			income.get('industryType').setValidators(Validators.required);
		} else {
			income.get('incomeDescription').setValidators(null);
			income.get('incomeDescription').setValue(null);
			income.get('company').setValidators(null);
			income.get('company').setValue(null);
			income.get('industryType').setValidators(null);
			income.get('industryType').setValue(null);
		}

		income.get('incomeDescription').updateValueAndValidity();
		income.get('company').updateValueAndValidity();
		income.get('industryType').updateValueAndValidity();
	}

	onChange(income: FormGroup): void {
		this.updateValidators(income);
	}
}
