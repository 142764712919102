import { Pipe, PipeTransform } from '@angular/core';

/**
 * Check to see if loan status is equal to param.
 *
 * @export
 * @class IsStatusEqualPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opIsStatusEqual'
})
export class IsStatusEqualPipe implements PipeTransform {
	transform(loanAppStatus: string, ...args: unknown[]): unknown {
		return args.some((status: string) => loanAppStatus?.toLowerCase() === status.toLowerCase());
	}
}
