/**
 * This directive differs from the standard `maxlength` directive in
 * that the user can actually type in more characters than defined as maximum.
 * Which then will result in a validation error.
 *
 * maxlength just truncates the input and doesn't show the error
 *
 * NOTE: You need to add NgMaxLengthValidator to your @NgModule declarations, in order to use this
 * directive.
 *
 */

import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

const MAX_LENGTH_VALIDATOR: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => OpMaxLengthDirective),
	multi: true
};

@Directive({
	selector: '[opMaxLength]',
	providers: [MAX_LENGTH_VALIDATOR]
})
export class OpMaxLengthDirective implements Validator, OnChanges {
	private _validator: ValidatorFn;
	private _onChange: () => void;

	@Input('opMaxLength')
	maxlength: string;

	ngOnChanges(changes: SimpleChanges): void {
		if ('maxlength' in changes) {
			this._createValidator();
			if (this._onChange) {
				this._onChange();
			}
		}
	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this.maxlength != null ? this._validator(c) : null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this._onChange = fn;
	}

	private _createValidator(): void {
		this._validator = Validators.maxLength(parseInt(this.maxlength, 10));
	}
}
