import { IMetadata } from '../services/mobile-api';
import { IPhoneResult } from '../services/mobile-api/mobile-api.model';

export enum PhoneTypeEnum {
	cell = 'CL',
	home = 'HM'
}

export class PhoneUtils {
	private userPhones: any;

	static fromPhoneResult(phoneResults: IPhoneResult): PhoneUtils {
		return new this(phoneResults);
	}

	constructor(readonly phoneResults: IPhoneResult) {
		this.userPhones = phoneResults.phones?.reduce((acc, cur) => {
			acc[cur.phoneType] = cur;
			return acc;
		}, {});
	}

	public getPhoneType(): PhoneTypeEnum {
		return this.userPhones[PhoneTypeEnum.cell] ? PhoneTypeEnum.cell : PhoneTypeEnum.home;
	}

	public getPhoneNumber(): string {
		return this.userPhones[PhoneTypeEnum.cell] ? this.getCellPhone() : this.getHomePhone();
	}

	public getPhoneOwnerDateRange(): number {
		return this.userPhones[PhoneTypeEnum.cell] ? this.getCellOwnerDateRange() : this.getHomeOwnerDateRange();
	}

	public getCellPhone(): string {
		return this.userPhones[PhoneTypeEnum.cell]?.phoneNumber;
	}

	public getCellOwnerDateRange(): number {
		return this.userPhones[PhoneTypeEnum.cell]?.ownerDateRange;
	}

	public getHomePhone(): string {
		return this.userPhones[PhoneTypeEnum.home]?.phoneNumber;
	}

	public getHomeOwnerDateRange(): number {
		return this.userPhones[PhoneTypeEnum.home]?.ownerDateRange;
	}

	get isSmsAuthorization(): boolean {
		return this.phoneResults.smsAuthorization;
	}

	static filterAllowedPhones(val: IMetadata[]): IMetadata[] {
		const allowedPhones = [PhoneTypeEnum.cell, PhoneTypeEnum.home];
		return val.filter((o) => allowedPhones.some((k) => k === o.key));
	}

	public hasSinglePhoneType(): boolean {
		return this.userPhones?.length === 1;
	}

	static formatPhoneNumber(phoneNumber: string): string {
		return phoneNumber.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
	}
}
