import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'op-adjust-loan-details',
	templateUrl: './loan-details.component.html',
	styleUrls: ['./loan-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanDetailsComponent {
	@Input()
	item;

	@Input()
	selectedLoanDetailId;
}
