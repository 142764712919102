<div class="file-upload" *transloco="let t">
	<div>
		<div>
			<input
				type="file"
				class="file-input"
				id="{{ id + '_file' }}"
				[accept]="requiredFileType"
				(change)="onFileSelected($event)"
				#fileUpload
			/>

			<p class="thumbnail-title" *ngIf="filesList?.length">
				<strong>{{ t('DOCUMENT_SUBMIT.selectedDocuments') }}</strong>
			</p>

			<op-file-thumbnails id="{{ id + '_fileThumbnails' }}" [categoryType]="categoryType" [files]="filesList">
			</op-file-thumbnails>

			<div
				*ngIf="
					filesList?.length && filesList[0].status !== 'FINISHED';
					then displayRetakeBtn;
					else (filesList?.length && filesList[0].status === 'FINISHED' && displaySavedBtn) || displayCameraBtn
				"
			></div>

			<ng-template #displayCameraBtn>
				<button
					mat-flat-button
					id="{{ id + '_selfieTakeBtn' }}"
					color="secondary-small"
					(click)="fileUpload.click()"
					type="submit"
				>
					{{ t('DOCUMENT_SUBMIT.selfie.useCamera') }}
					<mat-icon svgIcon="op-photoCamera"> </mat-icon>
				</button>
			</ng-template>
			<ng-template #displaySavedBtn>
				<button mat-flat-button id="{{ id + '_selfieSavedBtn' }}" disabled="true" color="secondary-small">
					{{ t('DOCUMENT_SUBMIT.selfie.selfieSaved') }}
					<mat-icon svgIcon="op-cloud"></mat-icon>
				</button>
			</ng-template>
			<ng-template #displayRetakeBtn>
				<div>
					<div class="op-center">
						<a href="" id="{{ id + '_selfieRemoveLink' }}" onclick="return false" (click)="removeThumbnail()">
							{{ t('DOCUMENT_SUBMIT.remove') | uppercase }}
						</a>
					</div>
					<div class="op-mt-10">
						<button
							mat-flat-button
							id="{{ id + '_selfieUploadBtn' }}"
							color="primary-small"
							(click)="uploadSelfie()"
							type="submit"
						>
							{{ t('DOCUMENT_SUBMIT.selfie.uploadSelfie') }}
							<mat-icon svgIcon="op-cloudUpload"></mat-icon>
						</button>
					</div>
					<div class="op-mt-20">
						<button
							mat-flat-button
							id="{{ id + '_selfieRetakeBtn' }}"
							color="secondary-small"
							(click)="fileUpload.click()"
							type="submit"
						>
							{{ t('DOCUMENT_SUBMIT.selfie.retakeSelfie') }}
							<mat-icon svgIcon="op-photoCamera"></mat-icon>
						</button>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
