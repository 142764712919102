<ng-container *transloco="let t" #debitCardFaq>
	<op-expansion-panel
		id="whenToGetFundQ"
		[titleBold]="false"
		[title]="t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.whenToGetFund.question')"
		[opTagClick]="'when to get fund'"
	>
		<p id="whenToGetFundA">{{ t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.whenToGetFund.answer') }}</p>
	</op-expansion-panel>
	<op-expansion-panel
		id="areAllEligibleQ"
		[titleBold]="false"
		[title]="t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.areAllEligible.question')"
		[opTagClick]="'are all eligible'"
	>
		<p id="areAllEligibleA">{{ t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.areAllEligible.answer') }}</p>
	</op-expansion-panel>
	<op-expansion-panel
		id="whatIfNotAcceptedQ"
		[titleBold]="false"
		[title]="t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.whatIfNotAccepted.question')"
		[opTagClick]="'what if not accepted'"
	>
		<p id="whatIfNotAcceptedA">{{ t('OFFER_STATUS.DEBIT_CARD_PROMO.FAQ.whatIfNotAccepted.answer') }}</p>
	</op-expansion-panel>
</ng-container>
