import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'op-confirm-remove-document-dialog',
	templateUrl: './confirm-remove-document-dialog.component.html',
	styleUrls: ['./confirm-remove-document-dialog.component.scss']
})
export class ConfirmRemoveDocumentDialogComponent {
	constructor(public dialogRef: MatDialogRef<ConfirmRemoveDocumentDialogComponent>) {}

	onSubmit(): void {
		this.dialogRef.close(true);
	}

	close(): void {
		this.dialogRef.close(false);
	}
}
