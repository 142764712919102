import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { DialogService } from '../dialog/dialog.service';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { IClaimOfferResult } from '../mobile-api/mobile-api.model';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from '../routing/routing.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { REQUEST_PARAM_APP_SOURCE } from 'src/app/shared/constants/common-const';

declare let IGLOO: any;

export interface IUser {
	firstName: string;
	lastName: string;
	middleName: string;
	maternalName: string;
	emailAddress: string;
	preferredLanguage: string;
	oktaUserId: string;
}

@Injectable({
	providedIn: 'root'
})
export default class TokenService implements OnDestroy {
	constructor(
		private mobileService: MobileApiService,
		private authService: AuthenticationService,
		private dialogService: DialogService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService
	) {
		const storageKeySub = this.sessionStorageService.select(this.storageKey).subscribe({
			next: (value) => {
				this.user = value;
			},
			complete: () => {
				this.user = null;
			}
		});
		this.subscription.add(storageKeySub);
	}

	user: IUser;
	token: string;

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private readonly storageKey = 'sso';
	private subscription = new Subscription();

	getUser(): IUser {
		return this.user;
	}

	reset(): void {
		this.user = null;
		this.token = null;
		this.sessionStorageService.remove(this.storageKey);
	}

	login(tokenStr: string, appSource: string): Observable<any> {
		this.token = tokenStr;
		return this.mobileService.claimOffer(this.token).pipe(
			switchMap((summary) => {
				this.user = this.buildUser(summary);
				this.sessionStorageService.set(this.storageKey, this.user);
				let loanAppId = this.hasOpenLoanApp(summary?.applicationId);
				if (loanAppId) {
					this.authService.updateAuthorizationToken(this.token);
					return this.loanAppService.updateLoanApplication(loanAppId).pipe(
						tap((loanApp) => this.handleLogin(loanApp)),
						catchError((err) => this.dialogService.openErrorDialog(err))
					);
				} else if (summary?.ableToReapply) {
					//start brand new loanApp
					this.authService.updateAuthorizationToken(this.token);
					const queryParams = { sso: 'true' };
					if (appSource) {
						queryParams[REQUEST_PARAM_APP_SOURCE] = appSource;
					}
					return of(this.routingService.route(RoutingPathsEnum.home, { queryParams }));
				} else {
					this.reset();
					return of(this.routingService.route(RoutingPathsEnum.home));
				}
			}),
			catchError((err) => {
				this.reset();
				throw err;
			})
		);
	}

	buildUser(data: IClaimOfferResult): IUser {
		return {
			firstName: data.firstName,
			lastName: data.lastName,
			middleName: data.middleName,
			maternalName: data.maternalName,
			emailAddress: data.emailAddress,
			oktaUserId: data.oktaUserId,
			preferredLanguage: data.preferredLanguage
		};
	}

	submitLasso(loanId, applicantId): void {
		const bbInfo = IGLOO.getBlackbox();
		const lasso = {
			blackBox: bbInfo.blackbox,
			captureDate: new Date().toISOString(),
			applicantId: String(applicantId),
			loanApplicationId: loanId,
			page: window.location.href
		};
		this.mobileService.lassoCapture(lasso).subscribe();
	}

	handleLogin(loanApp: ILoanApplication): void {
		this.submitLasso(loanApp.id, loanApp.applicants[0].id);
		this.routingService.routeFromLoanApp();
	}

	hasOpenLoanApp(applicationId: string): number {
		return Number(applicationId) || 0;
	}
}
