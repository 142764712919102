<div class="pdf-dialog">
	<div mat-dialog-title class="title">
		<div class="op-right op-mb-8">
			<button
				mat-icon-button
				id="pdfDlgCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ 'GLOBAL.close' | transloco }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-1-font">
			<strong>{{ data.title }}</strong>
		</div>
	</div>
	<div mat-dialog-content class="container">
		<ngx-extended-pdf-viewer
			[src]="safeUrl"
			[useBrowserLocale]="true"
			[mobileFriendlyZoom]="'120%'"
			[showSecondaryToolbarButton]="false"
		>
		</ngx-extended-pdf-viewer>
	</div>
</div>
