<mat-nav-list class="side-nav">
	<div class="side-nav-header">
		<button mat-icon-button id="sideNavCloseBtn" (click)="onSidenavClose()">
			<mat-icon svgIcon="op-close"></mat-icon>
		</button>
	</div>
	<p></p>
	<a
		href=""
		onclick="return false"
		mat-list-item
		*ngFor="let item of navItems; let i = index"
		class="nav-item"
		id="{{ 'navItemLink_' + i }}"
		[routerLink]="item.link ? item.link : null"
		(click)="onSidenavClose(item.fn)"
	>
		<mat-icon *ngIf="item.icon" svgIcon="{{ item.icon }}"></mat-icon>
		<span id="sideMenuHeading" class="op-header-4-font">{{ item.title | transloco }}</span>
	</a>

	<div class="nav-footer">
		<a
			href=""
			onclick="return false"
			mat-list-item
			*ngFor="let item of navFooterItems; let i = index"
			class="nav-item"
			[opTagClick]="item.tagClick"
			id="{{ 'navFooterItemLink_' + i }}"
			[routerLink]="item.link ? item.link : null"
			(click)="onSidenavClose(item.fn)"
		>
			<div class="op-center">
				<mat-icon *ngIf="item.icon" svgIcon="{{ item.icon }}"></mat-icon>
				<div class="op-body-2">{{ item.title | transloco }}</div>
			</div>
		</a>
	</div>
</mat-nav-list>
