import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { InstrumentEnum } from 'src/app/core/services/instrumentation/instrumentation.service';
import IProduct from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { TealiumService } from 'src/app/vendor/tealium/tealium.service';

import { Environment, EnvironmentService } from '../environment/environment.service';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { SessionStorageService } from '../storage/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class TagDataService implements OnDestroy {
	tagData = {
		application_type: 'CONSUMER_INSTALLMENT_LOAN'
	};

	private subscription = new Subscription();

	constructor(
		private environmentService: EnvironmentService,
		private loanAppService: LoanApplicationService,
		private router: Router,
		private tealiumService: TealiumService,
		private translocoService: TranslocoService,
		private sessionStorageService: SessionStorageService
	) {
		const routingSub = this.router.events.subscribe({
			next: (event) => {
				if (event instanceof ActivationEnd) {
					this.view({
						tealium_event: `${this.router.url}_view`
					});
				}
			}
		});
		this.subscription.add(routingSub);

		const loanAppSub = this.loanAppService.loanApplication$.subscribe({
			next: (loanApp) => {
				this.updateLoanAppDataForTracking(loanApp);
			}
		});
		this.subscription.add(loanAppSub);

		this.setLanguage();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	initConfig() {
		this.tealiumService.setConfig({
			account: 'oportun',
			profile: 'main',
			environment: this.getTealiumEnvironment()
		});
	}

	gatherInstrumentation(): any {
		return Object.keys(InstrumentEnum).reduce((acc, key) => {
			const keyData = this.sessionStorageService.get(key);
			if (keyData) {
				acc[key] = keyData;
			}
			return acc;
		}, {});
	}

	merge(data: any): void {
		this.tagData = {
			...this.tagData,
			...data
		};
	}

	view(viewData?: any, obj?: any): void {
		this.merge(viewData);
		let data = { ...this.tagData, ...this.gatherInstrumentation() };
		if (obj) {
			data = {
				...data,
				...obj
			};
		}
		this.tealiumService.view(data);
	}

	link(linkData?: any, event?: any): void {
		this.merge(linkData);
		let data = { ...this.tagData, ...this.gatherInstrumentation() };
		if (event) {
			data = {
				...data,
				...event
			};
		}
		this.tealiumService.link(data);
	}

	reset(): void {
		this.tagData = {
			application_type: 'CONSUMER_INSTALLMENT_LOAN'
		};
	}

	setLanguage(): void {
		this.link({
			event_action: 'submit',
			event_category: 'Form',
			event_form: 'rx_form',
			tealium_event: 'change_language',
			application_type: 'CONSUMER_INSTALLMENT_LOAN',
			event_source: 'online-origination'
		});
	}

	getTealiumEnvironment(): string {
		const envMap = {
			[Environment.Stage]: Environment.Qa,
			[Environment.Local]: Environment.Dev
		};
		return (envMap[this.environmentService.env] || this.environmentService.env).toLowerCase();
	}

	getTeliumVisitorId(): string {
		return this.tealiumService.getTeliumVisitorId();
	}

	getTealiumStringForOfferStatus(productOfferDetails: IProduct[]) {
		if (!productOfferDetails || productOfferDetails.length < 1) {
			return null;
		}
		return (
			productOfferDetails.length > 0 &&
			productOfferDetails
				.map((detail) => {
					return detail.productCategory + ':' + detail.productStatus;
				})
				.join(',')
		);
	}

	updateLoanAppDataForTracking(loanApp: any): void {
		this.merge({
			preferred_language: loanApp?.preferredLanguage,
			loan_application_id: loanApp?.loanApplicationId,
			application_type: 'CONSUMER_INSTALLMENT_LOAN',
			application_status: loanApp?.applicationStatus,
			origination_source: loanApp?.originationSource,
			email_hash: loanApp?.emailAddress,
			applicant_type: loanApp?.applicationType,
			applicant_id: loanApp?.applicants ? loanApp?.applicants[0]?.id : 0,
			created_at: loanApp?.createdAt,
			skip_references: loanApp?.skipReferences,
			sms_authorization: loanApp?.smsAuthorization,
			referral_code: loanApp?.referralCode,
			referral_code_consent: loanApp?.referralCodeConsent,
			referral_code_source: loanApp?.referralCodeSource,
			previous_loan_count: loanApp?.previousLoanCount
		});
	}
}
