import { Component, OnDestroy, ViewChild, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MobileApiService } from 'src/app/core/services/mobile-api';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { BankVerificationDialogComponent } from 'src/app/shared/components/dialogs/bank-verification-dialog/bank-verification-dialog.component';
import { CheckDeliveryNoticeComponent } from 'src/app/shared/components/dialogs/check-delivery-notice/check-delivery-notice.component';
import { ManuallyAddBankComponent } from 'src/app/shared/components/manually-add-bank/manually-add-bank.component';
import { PlaidBankTransactionComponent } from 'src/app/shared/components/plaid-bank-transaction/plaid-bank-transaction.component';

export enum FundingMethodEnum {
	'plaid' = 'PLAID',
	'newAccount' = 'NEW_ACCOUNT',
	'check' = 'CHECK'
}

@Component({
	selector: 'op-funding-method',
	templateUrl: './funding-method.component.html',
	styleUrls: ['./funding-method.component.scss']
})
export class FundingMethodComponent implements OnDestroy {
	private subscription = new Subscription();
	manualBankExpanded = false;
	hidePlaid = false;
	showNewDesign = undefined;
	fundingMethodEnum = FundingMethodEnum;
	selectedFundingMethod: FundingMethodEnum = FundingMethodEnum.plaid;
	expandPlaidPanel = true;
	selectedVerifiedAccountId: number;

	@Input()
	plaidConnectForIncomeVerified: boolean;

	@ViewChild(ManuallyAddBankComponent) addBank!: ManuallyAddBankComponent;
	@ViewChild(PlaidBankTransactionComponent) plaid!: PlaidBankTransactionComponent;

	constructor(
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private bankAccountService: AchBankAccountsService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	notSaved(event): void {
		this.notVerified(() => {
			this.routingService.route(RoutingPathsEnum.documentSubmit);
		});
	}

	notVerified(cb: () => any): void {
		const data = {
			title: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailed'),
			message: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailMessage'),
			retryText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.retry'),
			manualText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verifyManually'),
			manualDisclaimer: this.translocoService.translate('BANK_VERIFICATION_DIALOG.submitDocuments')
		};

		this.dialogService
			.openDialog(
				BankVerificationDialogComponent,
				{ data },
				() => of(true),
				() => of(false)
			)
			.subscribe({
				next: (manually) => manually && cb()
			});
	}

	setSelectedPaymentMethod(paymentMethod: FundingMethodEnum): void {
		this.selectedFundingMethod = paymentMethod;
	}

	continue(): void {
		const tagEvent = {
			tealium_event: 'button_click',
			selected_funding_method: this.selectedFundingMethod,
			applicant_id: this.loanAppService.loanApplicationId,
			issuing_org: this.loanAppService.getLoanApp()?.issuingOrganization,
			clientId: this.loanAppService.getCurrentApplicant()?.clientId
		};
		this.tagDataService.view({}, tagEvent);

		switch (this.selectedFundingMethod) {
			case FundingMethodEnum.plaid:
				const bank = {
					bankAccountId: this.selectedVerifiedAccountId,
					customerConfirms: true
				};
				this.setBankAsFundingMethod(bank);
				break;
			case FundingMethodEnum.newAccount:
				this.addBank.onSubmit();
				break;
			case FundingMethodEnum.check:
				this.onCheckSelection();
				break;
			default:
				break;
		}
	}

	get isPlaidValid(): boolean {
		return Boolean(this.selectedVerifiedAccountId);
	}

	get isManualBankValid(): boolean {
		return this.addBank.formGroup.valid;
	}

	get isFormValid(): boolean {
		return (
			(this.selectedFundingMethod === FundingMethodEnum.plaid && this.isPlaidValid) ||
			(this.selectedFundingMethod === FundingMethodEnum.newAccount && this.isManualBankValid) ||
			this.selectedFundingMethod === FundingMethodEnum.check
		);
	}

	accountSelected(bankId: number): void {
		this.selectedVerifiedAccountId = bankId;
	}

	bankDetailsSaved(event): void {
		const bank = {
			bankAccountId: event.id,
			customerConfirms: true
		};
		if (event?.verified === false) {
			this.notVerified(() => {
				this.setBankAsFundingMethod(bank);
			});
		} else {
			this.setBankAsFundingMethod(bank);
		}
	}

	setBankAsFundingMethod(bank): void {
		this.bankAccountService
			.selectBankAccount(bank)
			.pipe(
				take(1),
				switchMap(() => this.mobileService.updateDisbursementAch(true, this.loanAppService.loanApplicationId))
			)
			.subscribe({
				next: () => {
					this.routingService.route(RoutingPathsEnum.documentSubmit);
				}
			});
	}

	onCheckSelection(): void {
		this.openDenyAchDisbursementDialog()
			.pipe(
				switchMap((result) =>
					result ? this.mobileService.updateDisbursementAch(false, this.loanAppService.loanApplicationId) : of(null)
				)
			)
			.subscribe({
				next: (resp) => resp && this.routingService.route(RoutingPathsEnum.documentSubmit)
			});
	}

	private openDenyAchDisbursementDialog(): Observable<any> {
		if (OrganizationUtils.isMetaBank(this.loanAppService.getLoanApp().issuingOrganization)) {
			return this.dialogService.openSimpleDialog(CheckDeliveryNoticeComponent);
		} else {
			return of(true);
		}
	}
}
