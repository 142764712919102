import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'opIssuingCountry'
})
export class IssuingCountryPipe implements PipeTransform {
	readonly FOREIGN_ID_TYPES = ['PASSPORT', 'MATRICULA_CONSULAR'];
	transform(key: string | number, ...args: unknown[]): unknown {
		return this.FOREIGN_ID_TYPES.includes(String(key));
	}
}
