import { Injectable } from '@angular/core';
import { isEmpty, isFunction } from 'lodash';
import { Observable } from 'rxjs';
import { Environment, EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { ScriptLoaderService } from 'src/app/core/services/script-loader/script-loader.service';
import { WindowRefService } from 'src/app/core/services/window-ref/window-ref.service';

declare let ga: Function;

@Injectable({
	providedIn: 'root'
})
export class GoogleAnalyticsService {
	constructor(
		private environmentService: EnvironmentService,
		private scriptLoaderService: ScriptLoaderService,
		private windowRefService: WindowRefService
	) {}

	readonly gaKeys = {
		[Environment.Prod]: 'UA-6858604-14',
		['default']: 'UA-6858604-13'
	};

	cache: any[] = new Array<any>();

	private getGaKey(env: string): string {
		return this.gaKeys[env] || this.gaKeys.default;
	}

	init(): void {
		this.loadNewRelicScript().subscribe({
			next: (data) => {
				window['ga'] =
					window['ga'] ||
					function () {
						(ga['q'] = ga['q'] || []).push(arguments);
					};
				ga['l'] = +new Date();

				this.ga('create', `${this.getGaKey(this.environmentService.env)}`, 'auto');

				while (!isEmpty(this.cache)) {
					const item = this.cache.shift();
					this.windowRefService.nativeWindow.ga(...item);
				}
			}
		});
	}

	loadNewRelicScript(): Observable<void> {
		const url = `https://www.google-analytics.com/analytics.js`;
		return this.scriptLoaderService.loadScript(url, true);
	}

	ga(...args: any[]): void {
		if (isFunction(this.windowRefService.nativeWindow.ga)) {
			this.windowRefService.nativeWindow.ga(...args);
		} else {
			this.cache.push(args);
		}
	}
}
