import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'op-learn-more',
	templateUrl: './learn-more.component.html',
	styleUrls: ['./learn-more.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LearnMoreComponent {
	@Input()
	icon: string;

	@Input()
	src: string;

	@Input()
	title: string;

	@Input()
	iconSize: string;

	@Input()
	iconAlign = 'top';
}
