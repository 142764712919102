import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountStatusEnum } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { IAchBankAccountResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { opRequired } from '../../decorators/required.decorator';

@Component({
	selector: 'op-plaid-bank-account',
	templateUrl: './plaid-bank-account.component.html',
	styleUrls: ['./plaid-bank-account.component.scss']
})
export class PlaidBankAccountComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	expanded: boolean;

	@Input()
	isGCPOrReturn: boolean;

	@Output()
	opened = new EventEmitter<any>();

	@Output()
	bankVerificationComplete = new EventEmitter<any>();

	@Output()
	closed = new EventEmitter<any>();

	bankFilterForVerified(item: IAchBankAccountResult): boolean {
		return item.verificationStatus === AccountStatusEnum.verified && item.status === AccountStatusEnum.active;
	}
}
