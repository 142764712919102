export enum OrganizationEnum {
	OPORTUN = 'OPORTUN',
	METABANK = 'METABANK'
}

export class OrganizationUtils {
	static isOportun(value: string): boolean {
		return value?.toUpperCase() === OrganizationEnum.OPORTUN;
	}

	static isMetaBank(value: string): boolean {
		return value?.toUpperCase() === OrganizationEnum.METABANK;
	}

	static isNewOrganization(value: string): boolean {
		return value?.toUpperCase() === 'NEW_ORGANIZATION';
	}
}
