<div class="product-offer-card" *transloco="let t">
	<mat-card id="uplPreApproved">
		<mat-card-content>
			<div class="op-header-2-font">{{ t('ADJUST_TERMS.LOAN_TYPES.personalLoan') }}</div>
			<op-expansion-panel
				[applyColor]="true"
				[expanded]="false"
				id="uplPreApproval"
				[titleBold]="true"
				[title]="t('PRODUCT.PRODUCT_STATUSES.preApproved')"
			>
				<p>
					{{ t('OFFER_STATUS.unSecuredLoanNextStep') }}
				</p>
				<hr class="productDetailHorizontalLine" />
			</op-expansion-panel>

			<div class="loan-amount">
				<div class="op-header-4-font">{{ t('OFFER_STATUS.upTo') }}</div>
				<div class="op-display-2-font">
					{{ product.maxApprovedAmount | currency: 'USD':'symbol':'0.0' }}
					<sup>*</sup>
				</div>
			</div>
			<div>
				<button
					mat-flat-button
					color="primary-small"
					id="uplPreApprovalContinueBtn"
					class="continue-button op-full-width"
					(click)="onContinue('goToReferences')"
					[innerHTML]="t('GLOBAL.continue')"
				></button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
