import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-funding-method-accordion',
	templateUrl: './funding-method-accordion.component.html',
	styleUrls: ['./funding-method-accordion.component.scss'],
	animations: [
		trigger('expand', [
			state(
				'true',
				style({
					height: '*'
				})
			),
			state(
				'false',
				style({
					height: '0px'
				})
			),
			transition('true => false', animate('400ms ease')),
			transition('false => true', animate('400ms ease'))
		])
	]
})
export class FundingMethodAccordionComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	selected: boolean = false;

	@Input()
	hideHeader: boolean = false;

	@Input()
	expanded: boolean = false;

	@Input()
	paymentMethodIcon: string;

	@Input()
	paymentMethodTitle: string;

	@Input()
	paymentMethodInfo: string;

	@Input()
	paymentMethodDuration: string;

	@Input()
	canExpand: boolean = true;

	@Output()
	opened = new EventEmitter<void>();

	@Output()
	closed = new EventEmitter<void>();

	constructor(private tagDataService: TagDataService) {}

	public toggle(): void {
		this.expanded = !this.expanded;
		this.expanded ? this.opened.emit() : this.closed.emit();
		this.tagDataService.view(
			{},
			{
				event_action: this.expanded ? 'expand' : 'collapse',
				tealium_event: `${this.expanded ? 'expand' : 'collapse'}_${this.id} `
			}
		);
	}
}
