<div class="op-plaid-overlay" *ngIf="show$ | async" @fadeSlideInOut>
	<div class="overlay" *transloco="let t">
		<div class="connect op-mt-10 op-ml-10">
			<div class="connect-icon">
				<img alt="" src="/assets/images/circle.svg" role="presentation" />
			</div>
			<div class="content">
				<div class="op-body-2-font op-white op-mt-5">
					{{ t('PLAID_BANNER.connectBankDescription') }}
				</div>
			</div>
		</div>
		<div class="connect-link">
			<div></div>
			<div>
				<a class="thin" href="" onclick="return false" id="overlayNotNow" (click)="notNow()">
					{{ t('PLAID_BANNER.notNow') }}
				</a>
			</div>
			<div class="op-body-1-bold-font">
				<a href="" onclick="return false" id="overlayConnectBank" (click)="connectBank()">
					{{ t('PLAID_BANNER.connectBank') }}
				</a>
			</div>
		</div>
	</div>
</div>
