<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="vehicleRegPanel"
		title="{{
			isHomeStateIL ? t('DOCUMENT_SUBMIT.vehicleRegistrationIL.title') : t('DOCUMENT_SUBMIT.vehicleRegistration.title')
		}}"
		[fileList]="fileList"
		[expanded]="expanded"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
	>
		<div class="panel-content">
			<div class="op-header-3-font">
				{{
					isHomeStateIL
						? t('DOCUMENT_SUBMIT.vehicleRegistrationIL.subtitle')
						: t('DOCUMENT_SUBMIT.vehicleRegistration.subtitle')
				}}
			</div>
			<op-file-upload
				id="vehicleRegFileUpload"
				[categoryType]="fileType"
				requiredFileType="application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/bmp"
			>
			</op-file-upload>
		</div>
	</op-document-expansion-panel>
</div>
