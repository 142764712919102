import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IPartnerResponse } from './partner-api.model';

@Injectable({
	providedIn: 'root'
})
export class PartnerApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	getPartnerBrand(shortCode: string): Observable<IPartnerResponse> {
		return this.http.get<IPartnerResponse>(`${this.API_MOBILE_LOAN_APP_URL}/partners/${shortCode}`);
	}

	getOriginationCodeEligibility(originationCode: string): Observable<boolean> {
		return this.http
			.get<IPartnerResponse>(
				`${this.API_MOBILE_SERVICEABLE_URL}/config?name=ELIGIBLE_ONLINE_ORIGINATION_CODE_LIST&value=${originationCode}`
			)
			.pipe(map((partner) => partner.value));
	}
}
