import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

/**
 * Guard to check if phoneNumber and phoneType are valid
 *
 * @export
 * @class MultifactorGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class MultifactorGuard implements CanActivate {
	constructor(private landingService: LandingService, private routingService: RoutingService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const phoneNumber = this.landingService.getPhoneNumber();
		const phoneType = this.landingService.getPhoneType();
		if (phoneNumber && phoneType) {
			return true;
		}

		this.routingService.route(RoutingPathsEnum.findYourApplication);
		return false;
	}
}
