<div class="change-disbursement-option" *transloco="let t">
	<h2 class="op-header-2-font">{{ t('TERMS_CONFIRMED.finishOnlineHeading') }}</h2>
	<div>
		<div>
			<h2>
				<div *ngIf="isOnlineACHOption" id="receive-funds-label">
					{{ t('TERMS_CONFIRMED.ONLINE.esign') }}
				</div>
				<div *ngIf="isOnlineCheckOption" id="receive-funds-label">
					{{ t('TERMS_CONFIRMED.ONLINE.check') }}
				</div>
			</h2>
			<p class="text-content"></p>
			<ul>
				<li>
					<span id="review-and-sign">{{ t('TERMS_CONFIRMED.ONLINE.reviewAndSign') }}</span>
				</li>

				<li *ngIf="isOnlineACHOption">
					<op-info-tip class="op-display-block" id="hasBankTip" [key]="t('TERMS_CONFIRMED.onlineHasBankAccountHelp')">
						<span id="funds">{{ t('TERMS_CONFIRMED.ONLINE.provideDetailsFundsACH') }}</span>
					</op-info-tip>
				</li>
				<li *ngIf="isOnlineCheckOption">
					<op-info-tip class="op-display-block" id="hasBankTip" [key]="t('TERMS_CONFIRMED.onlineHasNoBankAccountHelp')">
						<span id="funds" [innerHTML]="t('TERMS_CONFIRMED.ONLINE.fundsCheck')"></span>
					</op-info-tip>
				</li>
				<li *ngIf="isOnlineCheckOption && !showMetaBankMessage">
					<span id="funds-help" [innerHTML]="t('TERMS_CONFIRMED.onlineHasNoBankAccountHelp')"></span>
				</li>
				<li *ngIf="isOnlineCheckOption && showMetaBankMessage">
					<span id="funds-help" [innerHTML]="t('TERMS_CONFIRMED.onlineHasNoBankAccountHelpMetaBank')"></span>
				</li>
				<li>
					<span id="review-and-sign">{{ t('TERMS_CONFIRMED.ONLINE.secure') }}</span>
				</li>
			</ul>

			<div *ngIf="!showBankSection">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="completeOnlineBtn"
					type="button"
					(click)="completeOnline()"
				>
					{{ t('TERMS_CONFIRMED.completeOnline') }}
				</button>
			</div>

			<div *ngIf="showBankSection">
				<h3>{{ t('TERMS_CONFIRMED.provideBankHeading') }}</h3>

				<div class="op-mb-40">
					<op-microbilt-bank-account [expanded]="true" (notSaved)="notVerified($event)" (saved)="verified($event)">
					</op-microbilt-bank-account>
				</div>
			</div>
		</div>
	</div>

	<div class="op-mt-20">
		<h2 *ngIf="!isPartnerCashDisbursement">
			<div id="instore-label" *ngIf="!isPartnerApplication">
				{{ t('TERMS_CONFIRMED.STORE.goIntoStore') }}
			</div>
			<div id="instore-label" *ngIf="isPartnerApplication">
				{{ t('TERMS_CONFIRMED.STORE.goToOportun') }}
			</div>
		</h2>
		<h2 *ngIf="isPartnerCashDisbursement">
			<div id="instore-label">
				{{ t('TERMS_CONFIRMED.PARTNER_STORE.gotoPartnerLocation' | transloco: { partner: partnerBrand }) }}
			</div>
		</h2>
		<div *ngIf="!isPartnerCashDisbursement">
			<ul>
				<li>
					<span id="in-person">{{ t('TERMS_CONFIRMED.STORE.reviewAndSign') }}</span>
				</li>
				<li *ngIf="!isCheckOnly">
					<span id="instore-text">{{ t('TERMS_CONFIRMED.STORE.fundsAvailable') }}</span>
				</li>
				<li *ngIf="isCheckOnly">
					<span id="instore-text"> {{ t('TERMS_CONFIRMED.STORE.inStoreCheckOnly') }}</span>
				</li>
			</ul>
		</div>
		<p *ngIf="isPartnerCashDisbursement">
			<span id="instore-text">
				<div>{{ t('TERMS_CONFIRMED.PARTNER_STORE.reviewAndSign') }}</div>
			</span>
		</p>
		<div *ngIf="!isPartnerCashDisbursement">
			<button
				mat-flat-button
				color="primary"
				id="findLocationBtn"
				type="button"
				(click)="onFindLocation()"
				[opTagClick]="'Find Locations'"
			>
				{{ t('TERMS_CONFIRMED.findLocations') }}
			</button>
		</div>
	</div>
</div>
