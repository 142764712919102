import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
	Translation,
	TRANSLOCO_CONFIG,
	TRANSLOCO_LOADER,
	translocoConfig,
	TranslocoLoader,
	TranslocoModule
} from '@ngneat/transloco';
import { TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangModule } from '@ngneat/transloco-persist-lang';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private http: HttpClient) {}

	getTranslation(lang: string): any {
		return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
	}
}

/**
 * transloco
 *
 * @export
 */
@NgModule({
	exports: [TranslocoModule],
	imports: [
		TranslocoPersistLangModule.forRoot({
			storage: {
				provide: TRANSLOCO_PERSIST_LANG_STORAGE,
				useValue: localStorage
			}
		})
	],
	providers: [
		{
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: ['en', 'es'],
				defaultLang: 'en',
				fallbackLang: 'es',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.production
			})
		},
		{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
	]
})
export class TranslocoRootModule {}
