import { AfterContentInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, combineLatest, delay, forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { ConfigApiService } from 'src/app/core/services/mobile-api';
import { PreQualifyService } from 'src/app/pre-qualify/pre-qualify.service';

export enum Steps {
	WithIdentityScreen = 4,
	WithoutIdentityScreen = 3
}

@Component({
	selector: 'op-pre-qualify',
	templateUrl: './pre-qualify.component.html',
	styleUrls: ['./pre-qualify.component.scss']
})
export class PreQualifyComponent implements OnInit, AfterContentInit, OnDestroy {
	constructor(
		private progressBarService: ProgressBarService,
		private loanAppService: LoanApplicationService,
		private configApiService: ConfigApiService,
		private preQualifyService: PreQualifyService
	) {}

	totalSteps: number;
	progressBarValue: number = 0;
	currentStep: number;
	isCAPostalCode: boolean = false;
	private subscription = new Subscription();

	ngOnInit(): void {
		const loanSub = combineLatest([
			this.loanAppService.loanApplication$.pipe(filter(Boolean)),
			this.configApiService.showIdentityScreen()
		]).subscribe({
			next: (rsp) => {
				const skipIdentityScreen =
					rsp?.[1].value && Boolean(this.loanAppService.getCurrentApplicant().identificationProcessComplete);
				this.preQualifyService.skipIdentityScreen = skipIdentityScreen;
				this.totalSteps = skipIdentityScreen ? Steps.WithoutIdentityScreen : Steps.WithIdentityScreen;
			}
		});
		this.subscription.add(loanSub);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngAfterContentInit(): void {
		this.progressBarService
			.getProgressBarValue()
			.pipe(delay(0))
			.subscribe((currentStep) => {
				this.currentStep = currentStep;
				this.progressBarValue = 100 * (currentStep / this.totalSteps);
			});
	}
}
