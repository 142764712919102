import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'op-go-to-store',
	templateUrl: './go-to-store.component.html',
	styleUrls: ['./go-to-store.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoToStoreComponent {
	@Input()
	isPartnerCashDisbursement: boolean;
	@Input()
	partnerBrand: string;
}
