import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-next-steps',
	templateUrl: './next-steps.component.html',
	styleUrls: ['./next-steps.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextStepsComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	isSpl: boolean;

	@Input()
	isPartnerCashDisbursement: boolean;

	@Input()
	titleRequirementText: string;

	@Output()
	findLocation = new EventEmitter<any>();

	@Input()
	vehicleMakeModel: string;

	@Input()
	partnerBrand: string;

	learnMoreTitle: string;

	ngOnInit(): void {
		this.learnMoreTitle = this.isSpl ? 'TERMS_CONFIRMED.NEXT_STEPS.title' : 'TERMS_CONFIRMED.NEXT_STEPS.oneLastStep';
	}

	onFindLocation(): void {
		this.findLocation.emit();
	}
}
