import { Injectable } from '@angular/core';
import {
	vwoInstance,
	VWOLaunchConfig,
	getSettingsFile,
	launch,
	VWOUserStorageConfig,
	VWOAsyncLaunchConfig,
	VWOApiOptions
} from 'vwo-node-sdk';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { from } from 'rxjs';

@Injectable()
export class VWOService {
	constructor(private environmentService: EnvironmentService) {}
	vwoLaunchConfig: VWOAsyncLaunchConfig;
	vwoClientInstance: vwoInstance;
	variation: string;
	setting: object;

	initialize() {
		const account = this.environmentService.isProduction ? '647380' : '617294';
		const sdkKey = this.environmentService.isProduction
			? '5091b4de48050dad147d4a08239da7c5'
			: '0ee0c1fe8d2d577c05a9da5c9787d49f';
		this.getSettingFile(account, sdkKey);
	}

	getSettingFile(accountId: string, apiKey: string, userStorageService?: VWOUserStorageConfig) {
		from(getSettingsFile(accountId, apiKey, userStorageService)).subscribe({
			next: (setting: any) => {
				this.vwoLaunchConfig = {
					settingsFile: setting,
					isDevelopmentMode: false,
					returnPromiseFor: { all: true }
				};
				this.vwoClientInstance = this.launch(this.vwoLaunchConfig);
			}
		});
	}

	setSettingData(setting: object): void {
		this.setting = setting;
	}
	launch(vwoLaunchConfig: VWOLaunchConfig): vwoInstance {
		return launch(vwoLaunchConfig);
	}

	activate(campaignKey: string, userId: string, options?: VWOApiOptions): string {
		return this.vwoClientInstance?.activate(campaignKey, userId, options);
	}

	getVariationName(campaignKey: string, userId: string, options?: VWOApiOptions): string {
		return this.vwoClientInstance?.getVariationName(campaignKey, userId, options);
	}

	isFeatureEnabled(campaignKey: string, userId: string, options?: VWOApiOptions): boolean {
		return this.vwoClientInstance?.isFeatureEnabled(campaignKey, userId, options);
	}

	track(campaignKey: String[], userId: string, goalIdentifier: string, options): void {
		this.vwoClientInstance?.track(campaignKey, userId, goalIdentifier, options);
	}

	push(tagKey: string, tagValue: string, userId: string): boolean {
		return this.vwoClientInstance?.push(tagKey, tagValue, userId);
	}
}
