<div class="op-stepper-step">
	<div class="step-top">
		<div [ngClass]="{ 'step-track': !begin }">
			<div
				[@fadeInNow]="state?.active"
				[ngClass]="{ percent: !begin }"
				[ngStyle]="{ width: state?.active ? '100%' : 0 }"
			></div>
		</div>
		<mat-icon
			@fadeIn
			@pulse
			*ngIf="checked"
			class="step-circle-check"
			[ngClass]="{ active: state?.active, burst: burst }"
			svgIcon="op-checkMark-solid"
			alt=""
		></mat-icon>
		<mat-icon
			*ngIf="!checked"
			class="step-circle"
			fontSet="material-icons"
			[ngClass]="{ active: state?.active }"
			alt=""
		>
		</mat-icon>
		<div [ngClass]="{ 'step-track': !end }">
			<div [ngClass]="{ percent: !end }" [ngStyle]="{ width: state?.percent === 100 ? state?.percent + '%' : 0 }"></div>
		</div>
	</div>

	<div class="step-bottom">
		<div class="title-space"></div>
		<div class="step-title op-micro-alt-font">{{ title }}</div>
		<div class="title-space"></div>
	</div>
</div>
