<div class="vehicle" *transloco="let t">
	<op-back-link [green]="false" class="op-mt-20 op-black" [opRoute]="'offerStatus'" id="vehicleBack">{{
		t('VEHICLE.returnToOffers')
	}}</op-back-link>

	<op-masthead [title]="t('VEHICLE.title')"></op-masthead>

	<op-content-container class="content">
		<op-vin-search [enableVinSearch]="enableVinSearch" (vinResults)="vinSearchResults($event)"></op-vin-search>

		<div class="op-header-3-font op-gray op-mtb-20 op-center">{{ t('VEHICLE.or') }}</div>

		<div class="op-label-font op-gray op-mb-16">{{ t('ADDRESS.requiredFields') }}</div>

		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('VEHICLE.year') }}</mat-label>
				<mat-select formControlName="year" name="years" id="years" required>
					<mat-option *ngFor="let year of years" id="{{ 'year_' + year }}" [value]="year">{{ year }}</mat-option>
				</mat-select>
				<mat-error id="yearError" *ngIf="formGroup.controls['year'].invalid && formGroup.controls['year'].touched">
					{{ t('VEHICLE.vehicleYearsErrMsg') }}
				</mat-error>
				<mat-hint>{{ t('VEHICLE.yearsHint') }}</mat-hint>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('VEHICLE.make') }}</mat-label>
				<mat-select formControlName="makeId" #makeId name="makes" id="makes" required>
					<mat-option *ngFor="let make of makes" id="{{ 'make_' + make.id }}" [value]="make.id">{{
						make.makeName
					}}</mat-option>
				</mat-select>
				<mat-error
					id="makeIdError"
					*ngIf="formGroup.controls['makeId'].invalid && formGroup.controls['makeId'].touched"
				>
					{{ t('VEHICLE.vehicleTypeErrMsg') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('VEHICLE.model') }}</mat-label>
				<mat-select formControlName="modelId" name="models" id="models" required>
					<mat-option *ngFor="let model of models" id="{{ 'model_' + model.id }}" [value]="model.id">{{
						model.modelName
					}}</mat-option>
				</mat-select>
				<mat-error
					id="modelIdError"
					*ngIf="formGroup.controls['modelId'].invalid && formGroup.controls['modelId'].touched"
				>
					{{ t('VEHICLE.vehicleTypeErrMsg') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('VEHICLE.trim') }}</mat-label>
				<mat-select formControlName="trimId" name="trims" id="trims" required>
					<mat-option *ngFor="let trim of trims" id="{{ 'trim_' + trim.id }}" [value]="trim.id">{{
						trim.trimName
					}}</mat-option>
				</mat-select>
				<mat-error
					id="trimIdError"
					*ngIf="formGroup.controls['trimId'].invalid && formGroup.controls['trimId'].touched"
				>
					{{ t('VEHICLE.vehicleTypeErrMsg') }}
				</mat-error>
			</mat-form-field>

			<div class="op-input-field">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('VEHICLE.mileage') }}</mat-label>
					<input
						matInput
						type="text"
						inputmode="numeric"
						name="odometer"
						maxlength="8"
						id="odometer"
						formControlName="odometer"
						mask="separator"
						thousandSeparator=","
						trackInputValue="true"
						(blur)="onMileageBlurEvent()"
						required
					/>
					<mat-error
						id="odometerError"
						*ngIf="formGroup.controls['odometer'].invalid && formGroup.controls['odometer'].touched"
					>
						{{ t('VEHICLE.mileageErrorText') }}
					</mat-error>
					<mat-hint>{{ t('VEHICLE.mileageHint') }}</mat-hint>
				</mat-form-field>
			</div>

			<div class="op-center op-mt-20">
				<button mat-flat-button color="primary" id="submitBtn" type="submit" [disabled]="formGroup.invalid">
					{{ t('VEHICLE.btn_check_eligibility') }}
				</button>
			</div>
			<div *ngIf="hasMultipleValidProductOffers" class="op-center op-mt-20">
				<button
					mat-flat-button
					color="secondary"
					id="returnToOffersBtn"
					type="button"
					(click)="returnToOffers()"
					[opTagClick]="'returnToOffers'"
				>
					{{ t('VEHICLE.btn_explore_later') }}
				</button>
			</div>
		</form>
		<p></p>

		<div class="op-mt-40">
			<div id="faqSubtitle" class="op-header-3-font">
				{{ t('VEHICLE.faq') }}
			</div>

			<op-expansion-panel
				id="whyMyCarPanel"
				[titleBold]="false"
				[title]="t('VEHICLE.whyMyCarQ')"
				[opTagClick]="'Why are you asking about my car'"
			>
				<p>{{ t('VEHICLE.whyMyCarA') }}</p>
			</op-expansion-panel>

			<op-expansion-panel
				id="whatAreRequirementPanel"
				[titleBold]="false"
				[title]="t('VEHICLE.whatAreRequirementsQ')"
				[opTagClick]="'What are the vehicle requirements'"
			>
				<ul>
					<li>{{ t('VEHICLE.whatAreRequirementsA1') }}</li>
					<li class="op-mt-20">{{ t('VEHICLE.whatAreRequirementsA2') }}</li>
					<li class="op-mt-20">
						{{ t('VEHICLE.whatAreRequirementsA3', { customerStateName: customerStateName }) }}
					</li>
					<li class="op-mt-20">
						{{ t('VEHICLE.whatAreRequirementsA4', { numOfDaysToRenewRegistration: numOfDaysToRenewRegistration }) }}
					</li>
					<li class="op-mt-20">{{ t('VEHICLE.whatAreRequirementsA5') }}</li>
				</ul>
			</op-expansion-panel>

			<op-expansion-panel id="whereToFindVinPanel" [titleBold]="false" [title]="t('VEHICLE.whereFindVinQ')">
				<p>{{ t('VEHICLE.whereFindVinA') }}</p>
			</op-expansion-panel>

			<op-expansion-panel id="vehicleTypesPanel" [titleBold]="false" [title]="t('VEHICLE.vehicleTypesQ')">
				<p [innerHTML]="t('VEHICLE.vehicleTypesA1')"></p>
				<p class="op-mt-20" [innerHTML]="t('VEHICLE.vehicleTypesA2')"></p>
				<p class="op-mt-20" [innerHTML]="t('VEHICLE.vehicleTypesA3')"></p>
			</op-expansion-panel>
		</div>
	</op-content-container>
</div>
