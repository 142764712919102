import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable({
	providedIn: 'root'
})
export class MfaRequiredGuard implements CanActivate {
	constructor(private loanAppService: LoanApplicationService, private routingService: RoutingService) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.updateLoanApplication().pipe(
			map((loanApp) => {
				if (
					this.loanAppService.getCurrentApplicant().isMFARequired &&
					!(this.loanAppService.isGCP() || this.loanAppService.isEarlyRenewal()) &&
					!this.loanAppService.isFTR()
				) {
					const queryParams = {
						returnPage: 'documentSubmit'
					};
					if (route.queryParams.addBank) {
						queryParams['query'] = 'addBank';
					}
					this.routingService.route(RoutingPathsEnum.multifactor, { queryParams });
					return false;
				} else {
					return true;
				}
			})
		);
	}
}
