<section
	[attr.id]="id"
	class="payment-method"
	[class.selected]="selected"
	*transloco="let t"
	[opTagClick]="paymentMethodTitle"
>
	<div class="card-header">
		<div class="title left-align op-black-bold op-header-3-font">
			<mat-icon svgIcon="{{ paymentMethodIcon }}"></mat-icon> <span class="op-ml-10"> {{ paymentMethodTitle }} </span>
		</div>
		<div class="right-align op-black-bold op-header-3-font">
			{{ paymentMethodDuration }}
		</div>
	</div>
	<div class="card-header">
		<div class="title left-align op-gray op-label-font op-mt-5 op-mb-10">
			<span class="payment-method-note">{{ paymentMethodNote }}</span>
		</div>
		<div class="title right-align op-dark-grey op-label-font op-mt-5 op-mb-10">
			{{ paymentMethodDurationInfo }}
		</div>
	</div>
	<div class="section-content">
		<div>{{ paymentMethodInfo }}</div>
		<div class="duration op-grey-bold"></div>
		<div class="op-mt-20" *ngIf="groupedAccountList?.size">
			<div *ngFor="let bankAccount of groupedAccountList | keyvalue" class="section-group op-mb-10">
				<div *ngIf="this.selectedBank === bankAccount.key" class="op-black-bold op-mb-12">
					{{ t('DISBURSEMENT_SELECTION.FUNDING_SELECTION.methodSelected') }}
				</div>
				<div class="op-black">{{ bankAccount.key }}</div>
				<div class="op-body-1-font op-dark-grey op-mtb-10" *ngFor="let account of bankAccount.value">
					<div class="bank-account-info op-label-font">
						<mat-icon svgIcon="op-checkMark-green" class="op-mr-5" *ngIf="account.selected"></mat-icon>
						{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.TYPE.' + account.accountType) }}
						{{ account.accountNumber | opLastNNumber: '.':3 }}
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="debitCardDetails">
			{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.title') }}

			{{ debitCardDetails.last4 | opLastNNumber: '.':3 }}
		</div>
	</div>
</section>
