import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { LoanExamplesService } from 'src/app/core/services/loan-examples/loan-examples.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IFinances, IFinancesResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { moneyValidator } from 'src/app/shared/validators/form-validators';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Component({
	selector: 'op-finances',
	templateUrl: './finances.component.html',
	styleUrls: ['./finances.component.scss'],
	providers: [LoanExamplesService]
})
export class FinancesComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private metadataService: MetadataService,
		private loanExampleService: LoanExamplesService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private issuingPartnerService: IssuingPartnerService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	applicant: IApplicant;

	loanPurposeFeeList$: Observable<IMetadata[]>;
	housingTypeList$: Observable<IMetadata[]>;
	checkCashingFeeList$: Observable<IMetadata[]>;

	bankAccountOptions: Array<IMetadata> = [
		{ key: true, text: 'GLOBAL.yes' },
		{ key: false, text: 'GLOBAL.no' }
	];

	paydayLendingServiceOptions: Array<IMetadata> = [
		{ key: true, text: 'GLOBAL.yes' },
		{ key: false, text: 'GLOBAL.no' }
	];

	customerState: string;
	doesLiveInCA: boolean;

	returnToSummary: string;
	vehicleOwnershipType$: Observable<IMetadata[]>;
	isPrequal: boolean = false;
	issuingOrganization: string;
	isMetaOrganization: boolean;

	returnToFTR: string;

	private subscription = new Subscription();

	ngOnInit(): void {
		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;

		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;

		this.checkCashingFeeList$ = this.metadataService.select(MetadataEnum.CheckCashingFee);

		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean), take(1)).subscribe({
			next: (loanApp: ILoanApplication) => {
				this.isPrequal = this.loanAppService.isPrequal(loanApp);
				this.applicant = this.loanAppService.getCurrentApplicant();
				this.issuingOrganization = this.issuingPartnerService.lender;
				this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingOrganization);

				this.mobileService.getAddresses(this.loanAppService.loanApplicationId).subscribe({
					next: (addresses) => {
						this.customerState = AddressUtils.getHomeAddress(addresses).state;
						this.doesLiveInCA = this.customerState === 'CA';
						this.tagDataService.view(
							{
								applicant_state: this.customerState,
								applicant_city: addresses?.length > 0 ? addresses[0].city : undefined,
								applicant_postal_code: addresses?.length > 0 ? addresses[0].postalCode : undefined
							},
							{
								tealium_event: 'finances'
							}
						);
					}
				});

				this.mobileService.getFinances(this.loanAppService.loanApplicationId).subscribe({
					next: (finance) => {
						this.updateFromBE(finance);
					}
				});
			}
		});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getTitleText(): string {
		return this.isPrequal
			? `${this.translocoService.translate('FINANCES.title')}`
			: `${this.translocoService.translate('GLOBAL.congratulations')}, ${this.applicant?.firstName}`;
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			monthlyPayment: [null, [Validators.required, moneyValidator()]],
			hasBankAccount: [null, Validators.required],
			checkCashingFee: [null, this.optionalRequired()],
			usedPaydayService: [null, this.optionalRequired()]
		});
	}
	private clearValidator(): void {
		const fields = ['checkCashingFee', 'usedPaydayService'];
		if (this.formGroup) {
			fields.forEach((field) => {
				this.formGroup.get(field).clearValidators();
				this.formGroup.get(field).updateValueAndValidity();
			});
		}
	}

	private setRequiredValidator(): void {
		const fields = ['checkCashingFee', 'usedPaydayService'];
		if (this.formGroup) {
			fields.forEach((field) => {
				this.formGroup.get(field).setValidators(Validators.required);
				this.formGroup.get(field).updateValueAndValidity();
			});
		}
	}
	private optionalRequired(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			if (this.doesLiveInCA && !this.isMetaOrganization) {
				this.clearValidator();
				this.setRequiredValidator();
			} else {
				this.clearValidator();
			}
			return null;
		};
	}

	updateFromBE(finances: IFinancesResult): void {
		this.formGroup.setValue({
			monthlyPayment: finances?.monthlyPayment ?? null,
			hasBankAccount: finances?.hasBankAccount ?? null,
			checkCashingFee: finances?.checkCashingFee ?? null,
			usedPaydayService: finances?.usedPaydayService ?? null
		});
	}

	openLoanExamplesModal(): void {
		this.loanExampleService.openLoanExamplesModal(this.customerState).subscribe();
	}

	navigate(route: RoutingPathsEnum): void {
		if (this.returnToFTR) {
			this.routingService.routeForFastTrack(this.returnToFTR);
		} else if (this.returnToSummary) {
			this.routingService.route(RoutingPathsEnum.summary, { fragment: this.returnToSummary });
		} else {
			this.routingService.route(route);
		}
	}

	onSubmit(post: IFinances): void {
		const loanApp = this.loanAppService.getLoanApp();

		this.tagDataService.link(
			{},
			{
				event_action: 'submit',
				event_category: 'Form',
				event_label: 'rx_form',
				tealium_event: 'finance_process_complete'
			}
		);
		this.mobileService.setFinances(post, loanApp.id).subscribe({
			next: () => {
				// the debt route was removed because CA is only MetaBank
				// comment out for now.  Will remove in the future
				// if (this.doesLiveInCA && !this.isMetaOrganization) {
				// 	return this.navigate(RoutingPathsEnum.debt);
				// }

				this.navigate(RoutingPathsEnum.income);
			},
			error: (err) => {
				return this.dialogService.openErrorDialog(err).subscribe();
			}
		});
	}

	addVehiclePaidOffTeliumEvent(selectedVehicleOwnershipStatus: string): void {
		this.tagDataService.view(
			{ own_paid_off_vehicle_answer: selectedVehicleOwnershipStatus },
			{
				tealium_event: 'finances_paid_off_vehicle_question',
				event_action: 'Finances paid off vehicle question',
				event_label: 'Own paid off vehicle answer selected',
				event_category: 'CONSUMER_INSTALLMENT_LOAN'
			}
		);
	}
}
