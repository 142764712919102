import { ValidatorFn, Validators } from '@angular/forms';

const zipCode = /^[0-9]{5}(-[0-9]{4})?$/;
const name = /^.{2,32}$/;
const firstName = /^[A-Za-z-\' ]{2,32}$/;
const middleName = /^[A-Za-z-\' ]{1,32}$/;
const lastName = /^[A-Za-z-\' ]{2,32}$/;
const maternalName = /^[A-Za-z-\' ]{2,32}$/;
const nameAlpha = /^[a-zA-Z]{2,30}$/;
const companyName = /^[-/ ,.&@+A-Za-z0-9]{2,60}$/;
const email =
	/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const ID = /^[0-9a-zA-Z-]{5,21}$/;
const homeStreetAddress1 = /^(?!\s{4,}|p[\W]?o[\W]?(st)?(\s*(off)+\s+|\s*(office)+|.*(b(in|ox))|\s+))[\w\s*\W]*/i;
const homeStreetAddress1NoSpecialCharacters: RegExp =
	/^(?!\s{4,}|p[\W]?o[\W]?(st)?(\s*(off)+\s+|\s*(office)+|.*(b(in|ox))|\s+))[\w\s]*$/i;

const money = /^[0-9]+(.[0-9]{2}){0,1}$/;
const integer = /^[0-9]*$/;
const password = /^(?=.*[a-zA-Z])(?=.*\\d)(?=.*\\D).{8,32}$/;
const phone = /^((\+1)?[\s-]?)?\(?[2-9]\d\d\)?[\s-]?[2-9]\d\d[\s-]?\d\d\d\d/;
const referralCode = /^.{5,20}$/;
const vin = /[A-HJ-NPR-Z0-9]{17}/;
const itin = /^9\d{8}$/;
const ssn = /[^9]\d{8}$/;
const maskedSsnOrItin = /^[\*]{5}[0-9]{4}$/;
const numberOnly = /^[0-9]*$/;

export function numberOnlyValidator(): ValidatorFn {
	return Validators.pattern(numberOnly);
}

export function zipCodeValidator(): ValidatorFn {
	return Validators.pattern(zipCode);
}

export function nameValidator(): ValidatorFn {
	return Validators.pattern(name);
}

export function nameAlphaValidator(): ValidatorFn {
	return Validators.pattern(nameAlpha);
}

export function firstNameValidator(): ValidatorFn {
	return Validators.pattern(firstName);
}

export function middleNameValidator(): ValidatorFn {
	return Validators.pattern(middleName);
}

export function lastNameValidator(): ValidatorFn {
	return Validators.pattern(lastName);
}

export function maternalNameValidator(): ValidatorFn {
	return Validators.pattern(maternalName);
}

export function companyNameValidator(): ValidatorFn {
	return Validators.pattern(companyName);
}

export function emailValidator(): ValidatorFn {
	return Validators.pattern(email);
}

export function referralCodeValidator(): ValidatorFn {
	return Validators.pattern(referralCode);
}

export function idValidator(): ValidatorFn {
	return Validators.pattern(ID);
}

export function homeStreetAddress1Validator(): ValidatorFn {
	return Validators.pattern(homeStreetAddress1);
}

export function homeStreetAddress1NoSpecialCharactersValidator(): ValidatorFn {
	return Validators.pattern(homeStreetAddress1NoSpecialCharacters);
}

export function moneyValidator(): ValidatorFn {
	return Validators.pattern(money);
}

export function integerValidator(): ValidatorFn {
	return Validators.pattern(integer);
}

export function passwordValidator(): ValidatorFn {
	return Validators.pattern(password);
}

export function phoneValidator(): ValidatorFn {
	return Validators.pattern(phone);
}

export function vinValidator(): ValidatorFn {
	return Validators.pattern(vin);
}

export function itinValidator(): ValidatorFn {
	return Validators.pattern(itin);
}

export function ssnValidator(): ValidatorFn {
	return Validators.pattern(ssn);
}

export function maskedSsnItinValidator(): ValidatorFn {
	return Validators.pattern(maskedSsnOrItin);
}
