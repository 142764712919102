import { Component, Input } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-dmv-req-id-proof',
	templateUrl: './section-dmv-req-id-proof.component.html',
	styleUrls: ['./section-dmv-req-id-proof.component.scss']
})
export class SectionDmvReqIdProofComponent extends ExpandSectionComponent {
	constructor() {
		super();
	}

	@Input()
	dmvAcceptedIdList: string[];
}
