<div class="bank-verification-dialog">
	<div mat-dialog-title>
		<div class="title op-icon-align-header-1">
			<span *ngIf="data.title" [innerHTML]="data.title"></span>
		</div>
	</div>
	<div mat-dialog-content id="messageDialogContent" *ngIf="data.message" [innerHTML]="data.message"></div>
	<p></p>
	<div class="op-center op-grid-dialog" mat-dialog-actions>
		<button
			class="confirm"
			mat-flat-button
			color="primary-small"
			id="messageDlgSubmitBtn"
			(click)="onSubmit()"
			*ngIf="data.confirmText"
		>
			{{ data.confirmText ? data.confirmText : ('GLOBAL.ok' | transloco) }}
		</button>
		<button
			*ngIf="data.retryText"
			class="confirm"
			mat-flat-button
			color="primary-small"
			id="messageDlgRetryBtn"
			(click)="close()"
		>
			{{ data.retryText ? data.retryText : ('GLOBAL.ok' | transloco) }}
		</button>
		<button
			*ngIf="data.manualText"
			class="confirm"
			mat-flat-button
			color="secondary-small"
			id="messageDlgManualBtn"
			(click)="onSubmit()"
		>
			{{ data.manualText ? data.manualText : ('GLOBAL.ok' | transloco) }}
		</button>
	</div>
	<div id="requiredFields" class="op-label-font op-center op-gray op-mtb-20" *ngIf="data.manualDisclaimer">
		{{ data.manualDisclaimer }}
	</div>
</div>
