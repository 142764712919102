import { Component, EventEmitter, Input, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { IAchBankAccount } from '../../../core/services/ach-bank-accounts/ach-bank-accounts.service';
import { IDebitCardDetails } from '../../../core/services/mobile-api';

@Component({
	selector: 'op-funding-selection-card',
	templateUrl: './funding-selection-card.component.html',
	styleUrls: ['./funding-selection-card.component.scss']
})
export class FundingSelectionCardComponent {
	private _groupedAccountList;

	selectedBank = null;

	@Input()
	@opRequired()
	id: string;

	@Input()
	selected: boolean = false;

	@Input()
	hideHeader: boolean = false;

	@Input()
	paymentMethodIcon: string;

	@Input()
	paymentMethodTitle: string;

	@Input()
	paymentMethodInfo: string;

	@Input()
	paymentMethodNote: string;

	@Input()
	paymentMethodDuration: string;

	@Input()
	paymentMethodDurationInfo: string;

	@Input()
	set groupedAccountList(groupList: Map<string, IAchBankAccount[]>) {
		this._groupedAccountList = groupList;
		groupList?.forEach((value, key, map) => {
			if (value?.some((item) => item.selected)) {
				this.selectedBank = key;
			}
		});
	}

	get groupedAccountList(): Map<string, IAchBankAccount[]> {
		return this._groupedAccountList;
	}

	@Input()
	debitCardDetails: IDebitCardDetails;

	@Output()
	opened = new EventEmitter<void>();

	@Output()
	closed = new EventEmitter<void>();
}
