import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { EsignRedirectLandingPageComponent } from './esign-redirect-landing-page/esign-redirect-landing-page.component';
import { EsignComponent } from './esign/esign.component';

const routes: Routes = [
	// Legacy routes
	{
		path: RoutingPathsEnum.esignEventSigningCanceled,
		redirectTo: 'esign/event/signing-canceled'
	},
	{
		path: RoutingPathsEnum.esignEventSigningDeclined,
		redirectTo: 'esign/event/signing-declined'
	},
	{
		path: RoutingPathsEnum.esignEventSigningFailed,
		redirectTo: 'esign/event/signing-failed'
	},
	{
		path: RoutingPathsEnum.esignEventSigningTimeout,
		redirectTo: 'esign/event/signing-timeout'
	},
	{
		path: RoutingPathsEnum.esignEventSigningCompleted,
		redirectTo: 'esign/event/signing-completed'
	},
	{
		path: RoutingPathsEnum.esignEventViewingCompleted,
		redirectTo: 'esign/event/viewing-completed'
	},
	{
		path: RoutingPathsEnum.esignHowToPrintSaveLoan,
		redirectTo: 'esign/how-to-print-save-loan'
	},
	{
		path: RoutingPathsEnum.esignShowError,
		redirectTo: 'esign/show-error'
	},
	{
		path: RoutingPathsEnum.esignPingListener,
		redirectTo: 'esign/ping-listener'
	},
	{ path: RoutingPathsEnum.esignRedirectLandingPage, component: EsignRedirectLandingPageComponent },

	// New routes
	// IPagesEnum.esignPage
	{ path: 'esign/:page', component: EsignComponent, resolve: { loanApp: LoanApplicationResolver } },

	//IPagesEnum.esignPageEvent
	{ path: 'esign/:page/:event', component: EsignComponent, resolve: { loanApp: LoanApplicationResolver } }
];

// TODO: Use forwarding routes to bypass the ( - ) situation

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EsignRoutingModule {}
