import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationStatusEnum } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

/**
 * Guard to check if coAppOffered for the loan before routing to offer-status page.
 *
 * @export
 * @class OfferStatusGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class OfferStatusGuard implements CanActivate {
	constructor(private loanAppService: LoanApplicationService, private routingService: RoutingService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// NOTE: the guard runs before the resolver.
		return this.loanAppService.updateLoanApplication().pipe(
			map((loanApp) => {
				if (ApplicationStatusEnum.coAppOffered === loanApp?.applicationStatus) {
					this.routingService.route(RoutingPathsEnum.status);
					return false;
				}
				return true;
			})
		);
	}
}
