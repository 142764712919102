import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { VWOService } from './vwo.service';

@NgModule({
	declarations: [],
	providers: [VWOService],
	imports: [CommonModule]
})
export class VWOModule {
	constructor(@Optional() @SkipSelf() parentModule: VWOModule) {
		if (parentModule) {
			throw new Error('VWOService is already loaded. Import it in the AppModule only');
		}
	}
}
