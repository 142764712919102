import { Injectable, OnDestroy } from '@angular/core';
import { omit } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';

import { LoanApplicationService } from '../../services/loan-application/loan-application.service';
import { EnvironmentService } from '../environment/environment.service';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { IPhoneResult } from '../mobile-api';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { CcasUserValidationEnum, IccasOktaAccountCreation, ICcasUser, ICcasUserValidationResult } from './ccas.model';

/**
 * @export
 * @class CcasService
 */
@Injectable({
	providedIn: 'root'
})
export class CcasService implements OnDestroy {
	private readonly cassUserSource = new BehaviorSubject<ICcasUser>(null);
	private subscription = new Subscription();
	private phoneUtilities: PhoneUtils;

	// Exposed observable (read-only).
	readonly cassUser$ = this.cassUserSource.asObservable();
	accountCreated: boolean;
	oktaAccountCreation: IccasOktaAccountCreation;
	validationResult: CcasUserValidationEnum;

	constructor(
		private loanAppService: LoanApplicationService,
		private mobileApiService: MobileApiService,
		private environmentService: EnvironmentService
	) {
		const userSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				switchMap((loanApplication: ILoanApplication) =>
					loanApplication.id ? this.mobileApiService.getPhone(loanApplication.id) : of(null)
				),
				filter(Boolean),
				map((phone: IPhoneResult) => {
					this.phoneUtilities = PhoneUtils.fromPhoneResult(phone);
					return this.buildCcasUser();
				}),
				mergeMap((user: ICcasUser) => forkJoin([of(user), this.validateClient(user.emailAddress, user)]))
			)
			.subscribe({
				next: ([user, validator]) => {
					this.updateCassUser(user);
				}
			});

		this.subscription.add(userSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private updateCassUser(user: ICcasUser): void {
		this.cassUserSource.next(user);
	}

	get ccasUser(): ICcasUser {
		return this.cassUserSource.getValue();
	}

	public createAccount(email: string = null): Observable<any> {
		const currentApplicant = this.loanAppService.getCurrentApplicant();

		return this.mobileApiService
			.setCcasAccount(email ? email : currentApplicant.emailAddress)
			.pipe(tap(() => (this.accountCreated = true)));
	}

	public getCcasHost(): string {
		return this.environmentService.getServicingHost();
	}

	public canCreateAccount(): boolean {
		return !this.accountCreated && this.validationResult !== CcasUserValidationEnum.clientExistHasOkta;
	}

	public isEmailRequired(): boolean {
		return (
			[CcasUserValidationEnum.clientExistHasOkta, CcasUserValidationEnum.clientExistsNoEmail].includes(
				this.validationResult
			) || Boolean(this.ccasUser?.emailAddress)
		);
	}

	public isEmailTaken(): boolean {
		return this.validationResult === CcasUserValidationEnum.clientExistsInOktaInUse;
	}

	public setCcasUserEmail(emailAddress: string): void {
		this.ccasUser.emailAddress = emailAddress;
	}

	public validateClient(emailAddress: string, user: ICcasUser = null): Observable<ICcasUserValidationResult> {
		const userToValidate = user ? { ...user, emailAddress } : { ...this.ccasUser, emailAddress };

		let omitFields = ['firstName', 'clientId', 'context'];
		if (!userToValidate.loanId || !userToValidate.emailAddress) {
			omitFields.push('loanId');
			omitFields.push('emailAddress');
		}

		return this.mobileApiService.setCcasValidateClient(omit(userToValidate, omitFields)).pipe(
			tap((response: ICcasUserValidationResult) => {
				const { status } = response;
				this.accountCreated = status === CcasUserValidationEnum.clientExistHasOkta;
				this.validationResult = status;
				if (response.hasOwnProperty('registrationToken') && response.registrationToken) {
					this.oktaAccountCreation = {
						creation: true,
						registrationToken: response.registrationToken
					};
				}
			})
		);
	}

	private buildCcasUser(): ICcasUser {
		const loanApplication = this.loanAppService.getLoanApp();
		const currentApplicant = this.loanAppService.getCurrentApplicant();

		const cellPhone = this.phoneUtilities.getCellPhone();
		const homePhone = this.phoneUtilities.getHomePhone();
		const phoneNumber = isEmpty(cellPhone) ? homePhone : cellPhone;

		return {
			phoneNumber,
			firstName: currentApplicant.firstName,
			lastName: currentApplicant.lastName,
			emailAddress: currentApplicant.emailAddress,
			dateOfBirth: currentApplicant.dateOfBirth?.substring(0, 10),
			zipCode: currentApplicant.homePostalCode,
			loanId: loanApplication?.sipPfLoan,
			clientId: currentApplicant.clientId,
			context: {
				applicantId: currentApplicant.id,
				clientId: currentApplicant.clientId,
				productType: loanApplication?.productType
			}
		};
	}
}
