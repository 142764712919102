<div class="adjust-terms" *transloco="let t">
	<op-switch-offer
		*ngIf="hasMultipleValidProductOffers"
		[isSPL]="isSPL"
		[areAllProductsApproved]="areAllProductsApproved"
		(switchOffer)="switchOffer()"
	>
	</op-switch-offer>

	<op-content-container class="content">
		<mat-card class="op-mt-40">
			<mat-card-content>
				<div class="op-header-2-font op-mb-5">{{ 'ADJUST_TERMS.adjustingFor' | transloco }}</div>
				<div>
					<div *ngIf="!isSPL" class="op-header-4-font selected-product">
						{{ t('ADJUST_TERMS.personalLoan') }}
					</div>

					<div *ngIf="isSPL" class="op-header-4-font selected-product">
						<span>{{ t('ADJUST_TERMS.securedPersonalLoan') }}</span>
					</div>
				</div>

				<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
					<op-increment-decrement
						*ngIf="!isStarterLoan && loanTermsLoaded"
						[maxAmount]="finalApprovedTerms?.maxApprovedAmount"
						[minAmount]="finalApprovedTerms?.minApprovedAmount"
						title="{{ 'ADJUST_TERMS.chooseLoanAmt' | transloco }}"
						(increment)="increaseValue()"
						(decrement)="decreaseValue()"
						formControlName="amount"
					>
					</op-increment-decrement>

					<div id="starterLoans" *ngIf="isStarterLoan">
						<div class="op-center op-header-1-font">{{ loanTerms.loanAmount | currency: 'USD':'symbol':'0.0' }}</div>
					</div>

					<div *ngIf="isGCP" class="op-center gcp-calculator">
						<div id="gcpCalculator">
							<span class="op-body-2-bold-font">
								{{ t('ADJUST_TERMS.existingBalance') }}
							</span>
							: {{ loanTerms.payoffAmount | currency: 'USD':'symbol':'0.0' }}
							<span class="op-body-2-bold-font">+</span>
							<span class="op-body-2-bold-font">{{ t('ADJUST_TERMS.disbursedAmount') }}</span>
							: {{ loanTerms.disbursementAmount | currency: 'USD':'symbol':'0.0' }}
						</div>
						<br />
						<div>
							<p>{{ t('ADJUST_TERMS.gcpBodyText') }}</p>
						</div>
					</div>

					<op-adjust-payment-frequency
						class="op-center"
						id="paymentFrequency"
						[viewablePayFreq]="paymentFrequencyOptions"
						formControlName="paymentFrequency"
					>
					</op-adjust-payment-frequency>

					<op-adjust-payment-date
						class="op-center"
						id="paymentDay"
						[paymentFrequency]="formGroup.get('paymentFrequency').value"
						[monthlyPaymentDates]="monthlyPaymentOptions"
						formControlName="paymentDay"
					>
					</op-adjust-payment-date>

					<hr class="horizontal-rule" />

					<div class="op-center op-mt-20">
						<p class="op-left op-header-2-font" id="radioGroupCard">{{ t('ADJUST_TERMS.chooseLoanDet') }}</p>
						<p class="op-left op-body-1-font" id="radioGroupCardInfo">
							{{ t('ADJUST_TERMS.hereIsWhatToExpect') }}
						</p>

						<div class="op-left op-label-font">
							<span *ngFor="let fee of formGroup.get('loanDetails').value?.fee">
								<span *ngIf="fee.amount > 0">
									{{ t('ADJUST_TERMS.' + fee.type) }} {{ fee.amount | currency: 'USD':'symbol':'0.2' }}
								</span>
							</span>
							<span>
								<span class="vertical-line">{{ t('ADJUST_TERMS.estAprPayments') }}</span>
							</span>
						</div>
					</div>

					<op-adjust-loan-details-list
						class="op-center"
						id="loanDetails"
						[list]="loanOptions"
						[showMoreOptions]="showMoreOptions"
						(chooseTerms)="onSubmit()"
						formControlName="loanDetails"
					>
					</op-adjust-loan-details-list>

					<div *ngIf="showMoreOptionsBtn" class="op-left op-mt-20">
						<a
							href=""
							mat-flat-button
							color="link-md"
							onclick="return false"
							id="showMoreOptionsLink"
							(click)="toggleShowMoreOptions()"
						>
							{{ t('ADJUST_TERMS.seeMoreLoanOptions') }}
						</a>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
		<div class="op-mt-40 expansion-panels">
			<op-expansion-panel
				id="onlyOptionsLoanQPanel"
				[title]="t('ADJUST_TERMS.onlyOptionsLoanQ')"
				[titleBold]="false"
				[opTagClick]="'Why are these my only options for a loan?'"
			>
				<p>{{ t('ADJUST_TERMS.onlyOptionsLoanA') }}</p>
			</op-expansion-panel>

			<op-expansion-panel
				id="aprPaymentsEstimateQPanel"
				[title]="t('ADJUST_TERMS.aprPaymentsEstimateQ')"
				[titleBold]="false"
				[opTagClick]="'Why are APR and payments estimated?'"
			>
				<p>{{ t('ADJUST_TERMS.aprPaymentsEstimateA') }}</p>
			</op-expansion-panel>
		</div>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
