import { find, some } from 'lodash';

import { DisbursementChannelEnum, DisbursementType } from '../disbursement/disbursement.model';
import { IDisbursementOptionsEntity, ILoanApplication } from '../loan-application.model';
import { FundingCoverageArea, IFundingInfo } from './funding-info.model';

export class FundingInfoUtils {
	static fromLoanApp(loanApp: ILoanApplication): FundingInfoUtils {
		return new this(loanApp?.fundingInfo || <IFundingInfo>{});
	}

	constructor(readonly fundingInfo: IFundingInfo) {
		Object.assign(this, fundingInfo);
	}

	getState(): string {
		return this.fundingInfo.state;
	}

	isOver50miles(): boolean {
		return this.fundingInfo.isOver50miles;
	}

	isOver100miles(): boolean {
		const distance: number = this.fundingInfo?.distance ? +this.fundingInfo.distance : 0;
		return distance > 100;
	}
	/**
	 * Check if ONLINE ACH is a disbursement option.
	 *
	 * @param {ILoanApplication} [loanApp=null]
	 * @return {*}  {boolean}
	 * @memberof FundingInfoService
	 */
	isMobileDisbursementEligible(): boolean {
		const disbursementOptions = this.fundingInfo.disbursementOptions || [];
		return some(disbursementOptions, { channel: DisbursementChannelEnum.online, type: DisbursementType.ach });
	}

	/**
	 * Check if coverage area is with in radius
	 *
	 * @param {ILoanApplication} [loanApp=null]
	 * @return {*}
	 * @memberof FundingInfoService
	 */
	isCoverageAreaWithinRadius(): boolean {
		return this.fundingInfo.coverageArea === FundingCoverageArea.withInRadius;
	}

	/**
	 * Check if coverage area outside radius
	 *
	 *  @return {*}  {boolean}
	 * @memberof FundingInfoUtils
	 */
	isCoverageAreaOutsideRadius(): boolean {
		return this.fundingInfo.coverageArea === FundingCoverageArea.outsideRadius;
	}

	/**
	 * check if channel online and type ach are disbursement options
	 *
	 * @return {*}  {boolean}
	 * @memberof FundingInfoUtils
	 */
	hasOnlineACHOption(): boolean {
		return some(this.fundingInfo.disbursementOptions, {
			channel: DisbursementChannelEnum.online,
			type: DisbursementType.ach
		});
	}

	/**
	 * check if channel online and type check are disbursement options
	 *
	 * @return {*}  {boolean}
	 * @memberof FundingInfoUtils
	 */
	hasOnlineCheckOption(): boolean {
		return some(this.fundingInfo.disbursementOptions, {
			channel: DisbursementChannelEnum.online,
			type: DisbursementType.check
		});
	}

	hasDisbursementOption(disbursementChannel: DisbursementChannelEnum, disbursementType: DisbursementType): boolean {
		return some(this.fundingInfo.disbursementOptions, { channel: disbursementChannel, type: disbursementType });
	}

	hasPartnerCashOption(): boolean {
		return some(this.fundingInfo.disbursementOptions, {
			channel: DisbursementChannelEnum.partnerStore,
			type: DisbursementType.cash
		});
	}

	getPartnerDisbursementChannel(): string {
		return find(this.fundingInfo.disbursementOptions, { channel: DisbursementChannelEnum.partnerStore })?.partner;
	}

	getPreferredDisbursementType(
		disbursementChannel: DisbursementChannelEnum,
		disbursementType: DisbursementType = undefined
	): DisbursementType {
		const criteria: { [key: string]: string } = { channel: disbursementChannel };
		if (disbursementType && this.hasDisbursementOption(disbursementChannel, disbursementType)) {
			criteria.type = disbursementType;
		}
		return (
			(find(this.fundingInfo.disbursementOptions, criteria) as IDisbursementOptionsEntity)?.type || disbursementType
		);
	}

	isStorelessState(): boolean {
		return this.fundingInfo.isStorelessState;
	}

	isPartnerCashAmountExceeded(): boolean {
		return (
			find(this.fundingInfo.disbursementOptions, {
				channel: DisbursementChannelEnum.partnerStore,
				type: DisbursementType.cash
			})?.isMaxCashAmountExceeded === true
		);
	}
}
