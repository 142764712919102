<div
	class="plaid-connect-dialog"
	aria-labelledby="messageDialogTitle"
	*transloco="let t"
	cdkTrapFocus
	[cdkTrapFocusAutoCapture]="true"
>
	<div mat-dialog-title>
		<div class="op-right">
			<button
				mat-icon-button
				id="plaidConnectDialogCloseBtn"
				aria-label="{{ t('GLOBAL.close') }}"
				[mat-dialog-close]="true"
			>
				<mat-icon svgIcon="op-close" alt="{{ t('GLOBAL.close') }}"></mat-icon>
			</button>
		</div>
		<div class="op-black-bold op-header-1-font">
			<span id="messageDialogTitle">{{ t('PLAID_CONNECT.connectYourBankAccount') }}</span>
		</div>
	</div>

	<div mat-dialog-content id="plaidConnectDialogContent">
		<div class="op-body-1-font op op-gray op-mb-10">
			{{ t('PLAID_CONNECT.enterCredentialViaPlaid') }}
		</div>
		<op-plaid-button-ext id="plaidConnectDialogButton" [bankOnly]="true"></op-plaid-button-ext>
	</div>
</div>
