import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { filter as _filter, isFunction, noop } from 'lodash';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	ConfigApiService,
	IAchBankAccountResult,
	IConfigResult,
	ISetAchBankAccountResult,
	MobileApiService
} from 'src/app/core/services/mobile-api';
import { IDisbursementOptions } from 'src/app/core/services/mobile-api/mobile-api.model';
import {
	ElectronicTitleEnabledStates,
	TitleTypeEnum
} from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.model';
import { VehicleApiService } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.service';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { StatesEnum } from 'src/app/core/utils/state-utils';
import { BankVerificationDialogComponent } from 'src/app/shared/components/dialogs/bank-verification-dialog/bank-verification-dialog.component';
import { PlaidConnectDialogComponent } from 'src/app/shared/components/dialogs/plaid-connect-dialog/plaid-connect-dialog.component';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';

@Component({
	selector: 'op-terms-confirmed',
	templateUrl: './terms-confirmed.component.html',
	styleUrls: ['./terms-confirmed.component.scss']
})
export class TermsConfirmedComponent implements OnInit, OnDestroy {
	constructor(
		private plaidLinkService: PlaidLinkService,
		private bankAccountService: AchBankAccountsService,
		private loanAppService: LoanApplicationService,
		private vehicleService: VehicleApiService,
		private translocoService: TranslocoService,
		private mobileApiService: MobileApiService,
		private originationPartnerService: OriginationPartnerService,
		private dialogService: DialogService,
		private routingService: RoutingService,
		private configApiService: ConfigApiService
	) {}

	private subscription = new Subscription();

	fundingInfoLoaded: boolean;

	applicant: ApplicantUtils;
	disbursement: DisbursementUtils;
	fundingInfo: FundingInfoUtils;

	isSpl: boolean;
	isPartnerCashDisbursement: boolean;

	showChangeDisbursementOffer: boolean;
	showVerifyBankOffer: boolean;
	isMicroBiltEnabled: boolean;
	showCompleteOnline: boolean;
	showBankSection: boolean;
	hasVerifiedActiveBankAccount: boolean;
	showStoreLocation: boolean = true;
	showAddBankDesc: boolean;
	fileType = FileUploadType.bank;

	selectAccount: boolean;
	showNoAccountsError: boolean;
	bankConnectEvent: BankConnectEventTypeEnum;

	titleRequirementText: string;
	isCheckOnly: boolean;
	customerState: string;

	isOnlineACHOption: boolean;
	isOnlineCheckOption: boolean;
	isPartnerApplication: boolean;

	vehicleMakeModel: string = '';
	showMetaBankMessage: boolean;
	isPostApproval: boolean;
	isFromPlaid: boolean;
	partnerBrand: string;
	isGCPOrReturn: boolean;
	isPlaidBankAccountExists: boolean;
	isGCPOrReturnAccountExists: boolean;
	storeCheckEsignEnabled: boolean = false;
	groupedAccountList: Map<string, IAchBankAccount[]> = null;

	ngOnInit(): void {
		this.verifyMicroBiltStatus().subscribe((res) => {
			// reading the flag to turn on or off microbilt widget display
			this.isMicroBiltEnabled = res;
		});
		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				switchMap((loanApp: ILoanApplication) => {
					this.applicant = ApplicantUtils.fromLoanApp(loanApp);
					this.disbursement = DisbursementUtils.fromLoanApp(loanApp);
					this.fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
					this.isPartnerApplication = this.loanAppService.isPartnerApplication();
					this.isOnlineACHOption = this.fundingInfo.hasOnlineACHOption();
					this.isOnlineCheckOption = this.fundingInfo.hasOnlineCheckOption();
					this.isGCPOrReturn = this.isGCPOrReturnApplicant();
					this.isPlaidBankAccountExists = this.loanAppService.isVerifiedBankAccountExists();
					const storeCheckEsignEligible = Boolean(
						!this.isSpl &&
							!OrganizationUtils.isOportun(loanApp.issuingOrganization) &&
							this.disbursement.isTypeCheck() &&
							this.disbursement.isChannelStore()
					);
					return forkJoin({
						addresses: this.mobileApiService.getAddresses(this.loanAppService.loanApplicationId),
						partnerBrand: this.originationPartnerService.getPartnerBrand(
							this.fundingInfo.getPartnerDisbursementChannel()
						),
						bankAccounts: this.bankAccountService.refreshBankAccounts(),
						gcpOrReturnAccountList: this.mobileApiService.getAchBankAccountsForApplicant(
							this.loanAppService.loanApplicationId
						),
						storeCheckEsignEligibility: storeCheckEsignEligible
							? this.configApiService.configUPLStoreCheckEsignEnabled()
							: of(null)
					});
				}),
				map(({ addresses, partnerBrand, bankAccounts, gcpOrReturnAccountList, storeCheckEsignEligibility }) => {
					this.isCheckOnly = AddressUtils.isCheckOnlyState(StatesEnum[addresses[0].state.toUpperCase()]);
					this.customerState = AddressUtils.getHomeAddress(addresses)?.state;
					this.partnerBrand = partnerBrand;

					this.isSpl = this.loanAppService.isSPL();
					this.showMetaBankMessage = OrganizationUtils.isMetaBank(this.loanAppService.getIssuingOrganization());
					this.isPostApproval = true;
					const verifiedActiveBankAccounts = _filter(bankAccounts, function (item) {
						return item.verificationStatus === 'VERIFIED' && item.status === 'ACTIVE';
					});
					this.hasVerifiedActiveBankAccount = verifiedActiveBankAccounts?.length > 0 ? true : false;
					this.checkDisbursementOption();
					this.isGCPOrReturnAccountExists = gcpOrReturnAccountList.length > 0 ? true : false;
					this.storeCheckEsignEnabled = Boolean(storeCheckEsignEligibility?.value);
					if (this.storeCheckEsignEnabled) {
						const combinedBankAccounts = bankAccounts?.concat(gcpOrReturnAccountList) || [];
						this.groupedAccountList = this.groupBankAccountsByName(combinedBankAccounts);
					}
					return this.isSpl;
				}),
				switchMap((isSpl) => {
					return isSpl ? this.vehicleService.getVehicle(this.loanAppService.loanApplicationId) : of(null);
				}),
				map((vehicle) => {
					this.setTitleRequirementText(vehicle);
					this.showChangeDisbursementOffer = this.getShowChangeDisbursementOffer();
					this.showVerifyBankOffer = this.getShowVerifyBankOffer();
					this.isPartnerCashDisbursement = this.disbursement.isPartnerCash();
					return this.showVerifyBankOffer;
				}),
				tap(() => {
					if (this.showVerifyBankOffer && this.hasVerifiedActiveBankAccount) {
						this.selectAccount = true;
						this.isFromPlaid = true;
					}
				})
			)
			.subscribe({
				next: () => {
					this.fundingInfoLoaded = true;
				}
			});
		this.subscription.add(loanAppSub);
	}

	showPlaidConnect(): void {
		this.dialogService
			.openSimpleDialog(PlaidConnectDialogComponent, {})
			.pipe(
				mergeMap((result) => {
					return iif(() => Boolean(result), this.bankAccountService.refreshBankAccounts(), of(null));
				})
			)
			.subscribe({
				next: (bankAccounts) => {
					this.groupedAccountList = this.groupBankAccountsByName(bankAccounts);
				}
			});
	}

	navigateToAddBankAccount() {
		this.routingService.route(RoutingPathsEnum.termsConfirmedAddBankAccount);
	}

	navigateToAddManually() {
		this.routingService.route(RoutingPathsEnum.termsConfirmedAddManually);
	}

	private checkDisbursementOption(): void {
		if (!this.isSpl) {
			// Customers is within 100 miles from a store & "ACH" disbursement method "verified"
			if (this.disbursement.isTypeAch()) {
				if (this.hasVerifiedActiveBankAccount) {
					this.selectAccount = true;
					this.isFromPlaid = true;
					this.showCompleteOnline = true;
					// Customer is over 100 miles from a store & "ACH" disbursement method is "Verified"
					if (this.fundingInfo.isOver100miles()) {
						this.showStoreLocation = false;
					}
				} else {
					this.showBankSection = true;
					this.showAddBankDesc = true;
				}
			} else {
				// Customer is under 100 miles from a store, has a bank account verified through PLAID but disbursement method is not ACH
				if (!this.fundingInfo.isOver100miles()) {
					this.showBankSection = true;
					this.selectAccount = this.loanAppService.isVerifiedBankAccountExists();
					this.showAddBankDesc = !this.selectAccount;
				}
			}
		}
	}

	private verifyMicroBiltStatus(): Observable<boolean> {
		const mocroBiltEnabled$ = this.configApiService.configMicroBiltEnabled();
		return mocroBiltEnabled$.pipe(
			filter(Boolean),
			map((rsp: IConfigResult) => rsp.value)
		);
	}

	private setTitleRequirementText(vehicle: any) {
		if (vehicle?.makeStr && vehicle?.modelStr) {
			const isElectronicTitle = vehicle?.titleType === TitleTypeEnum.electronic;
			if (ElectronicTitleEnabledStates.includes(this.customerState) && isElectronicTitle) {
				this.titleRequirementText = 'TERMS_CONFIRMED.NEXT_STEPS.electronicTitleRequirement';
			} else {
				this.titleRequirementText = 'TERMS_CONFIRMED.NEXT_STEPS.splStep1';
				this.vehicleMakeModel = `${vehicle?.makeStr} ${vehicle?.modelStr}`;
			}
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent = event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = event.data.responseStatus !== BankConnectResponseStatusEnum.accountsFound;

		this.bankAccountService.refreshBankAccounts().subscribe({
			next: (rsp) => {
				if (event.data?.responseStatus !== BankConnectResponseStatusEnum.emptyAccountList && rsp.length > 0) {
					this.selectAccount = true;
					this.isFromPlaid = true;
					this.routingService.route(RoutingPathsEnum.termsConfirmedAddBankAccount);
				}
			}
		});
	}
	bankErrorCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = true;
	}

	getShowChangeDisbursementOffer(): boolean {
		return (
			this.isSpl &&
			this.applicant.isReturning() &&
			this.disbursement.isChannelAnyStore() &&
			this.fundingInfo.hasOnlineACHOption()
		);
	}

	getShowVerifyBankOffer(): boolean {
		//co-app
		if (this.loanAppService.hasCoApplicant()) {
			return false;
		}

		// spl
		if (this.loanAppService.isSPL()) {
			return false;
		}

		// disbursementType !== 'ACH'
		if (!this.disbursement.isTypeAch()) {
			return true;
		}

		//disbursementChannel === 'STORE' && disbursementType === 'ACH'
		if (this.disbursement.isChannelAnyStore()) {
			return true;
		}

		return false;
	}

	bankFilter(item: IAchBankAccountResult): boolean {
		return item.verificationStatus === 'VERIFIED' && item.status === 'ACTIVE';
	}

	updateDisbursementChannelAndNavigate(): void {
		const disbursementParameters: IDisbursementOptions = {
			disbursementChannel: DisbursementChannelEnum.online,
			disbursementType: DisbursementType.ach
		};
		this.mobileApiService
			.updateDisbursementChannel(disbursementParameters, this.loanAppService.loanApplicationId)
			.subscribe({
				next: () => {
					this.continueOnlineProcess();
				}
			});
	}

	continueOnlineProcess(): void {
		if (this.disbursement.isNotificationAutoPayCompleted()) {
			this.routingService.route(RoutingPathsEnum.esignSignDocument);
		} else {
			this.routingService.route(RoutingPathsEnum.esignAutoPay);
		}
	}

	continueWithCheck(): void {
		this.mobileApiService
			.updateDisbursementChannel(
				{
					disbursementChannel: DisbursementChannelEnum.online,
					disbursementType: DisbursementType.check
				},
				this.loanAppService.loanApplicationId
			)
			.subscribe({
				complete: () => {
					this.continueOnlineProcess();
				}
			});
	}

	showMetaMessage(): boolean {
		return OrganizationUtils.isMetaBank(this.loanAppService.getLoanApp().issuingOrganization);
	}

	onBankSelectionComplete(): void {
		this.continueOnlineProcess();
	}

	notVerified(event): void {
		const data = {
			title: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailed'),
			message: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailMessage'),
			retryText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.retry')
		};
		this.dialogService.openSimpleDialog(BankVerificationDialogComponent, { data }).subscribe();
	}

	verified(event: ISetAchBankAccountResult): void {
		if (event?.verified) {
			const bank = {
				bankAccountId: event?.id,
				customerConfirms: true
			};
			const updateBankAccount$ = this.bankAccountService
				.updateBankAccountPostApproval(bank?.bankAccountId)
				.pipe(take(1));

			updateBankAccount$.subscribe({
				next: () => {
					this.continueOnlineProcess();
				}
			});
		} else {
			this.notVerified(event);
		}
	}

	onFindLocation(): void {
		this.dialogService.openFindLocationDialog().subscribe();
	}

	isGCPOrReturnApplicant(): boolean {
		return this.applicant.isReturning() || this.loanAppService.isGCP();
	}

	groupBankAccountsByName(bankAccounts: IAchBankAccount[]): Map<string, IAchBankAccount[]> {
		return bankAccounts?.reduce(
			(entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]),
			new Map()
		);
	}
}
