import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IIdentification, IIdentificationResult } from './identification-api.model';

@Injectable({
	providedIn: 'root'
})
export class IdentificationApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	getIdentification(loanId: number, applicantIndex = 0): Observable<IIdentificationResult> {
		return this.http.get<IIdentificationResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/identification`
		);
	}

	setIdentification(
		identification: IIdentification,
		loanId: number,
		applicantIndex = 0
	): Observable<IIdentificationResult> {
		return this.http.post<IIdentificationResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/identification`,
			identification
		);
	}

	updateIdentification(
		identification: IIdentification,
		loanId: number,
		applicantIndex = 0
	): Observable<IIdentificationResult> {
		return this.http.put<IIdentificationResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/identification`,
			identification
		);
	}
}
