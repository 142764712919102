<div class="funding-method-confirm" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button
				mat-icon-button
				id="fundingMethodConfirmCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<p class="funding-method-title" innerHTML="{{ t('DISBURSEMENT_SELECTION.FUNDING_SELECTION.title') }}"></p>
	</div>
	<div mat-dialog-content>
		<div *ngIf="data.fundingOptions === 'ACH'">
			<div class="op-center op-mtb-10"><img src="assets/images/icon_manual_add_bank.svg" /></div>
			<p>{{ t('DISBURSEMENT_SELECTION.FUNDING_SELECTION.bankAccount') }}</p>
			<div class="funding-method-details">
				{{ data.account.bankName }}
				<div class="op-body-1-font op-dark-grey op-mt-10">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.TYPE.' + data.account.accountType) }}
					{{ data.account.accountNumber | opLastNNumber: '.':3 }}
				</div>
			</div>
		</div>
		<div *ngIf="data.fundingOptions === 'PUSH_TO_DEBIT'">
			<div class="op-center op-mtb-10"><img src="assets/images/icon_add_debit_card.svg" /></div>
			<p>{{ t('DISBURSEMENT_SELECTION.FUNDING_SELECTION.bankCard.' + data.card.fundsAvailability) }}</p>
			<div class="funding-method-details">
				<div class="op-body-1-font disabled-grey op-mt-10">
					{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.title') }} {{ data.card.last4 | opLastNNumber: '.':3 }}
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions class="footer">
		<button mat-flat-button color="primary-small" (click)="onClick()" id="fundingMethodConfirmContinue">
			{{ 'GLOBAL.continue' | transloco }}
		</button>
	</div>
</div>
