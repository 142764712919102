import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISetAchBankAccountResult } from 'src/app/core/services/mobile-api';

@Component({
	selector: 'op-microbilt-bank-account',
	templateUrl: './microbilt-bank-account.component.html',
	styleUrls: ['./microbilt-bank-account.component.scss']
})
export class MicrobiltBankAccountComponent {
	@Input()
	expanded: boolean;

	@Output()
	opened = new EventEmitter<void>();

	@Output()
	closed = new EventEmitter<void>();

	@Output()
	saved = new EventEmitter<ISetAchBankAccountResult>();

	@Output()
	notSaved = new EventEmitter<void>();
}
