<div class="income-selection" *transloco="let t">
	<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<mat-radio-group class="op-toggle-group1" name="income-selection" formControlName="incomeSelection">
			<div class="border op-mt-20">
				<div>
					<mat-radio-button class="op-mb-20" [value]="VerificationStatusEnum.verified">
						<strong>{{ t('INCOME_VERIFICATION.INCOME_SELECTION.useAutoIncome') }}</strong>
					</mat-radio-button>
					<p>
						{{ t('INCOME_VERIFICATION.INCOME_SELECTION.weVerified') }}
						<strong>{{ verifiedMonthlyIncomeAmount | currency }}</strong>
						{{ t('INCOME_VERIFICATION.INCOME_SELECTION.perMonth') }}
					</p>
				</div>
				<div class="op-gray">{{ t('INCOME_VERIFICATION.INCOME_SELECTION.noProofRequired') }}</div>
			</div>

			<div class="border op-mt-20 op-mb-20">
				<div>
					<mat-radio-button class="op-mb-20" [value]="VerificationStatusEnum.stated">
						<strong>{{ t('INCOME_VERIFICATION.INCOME_SELECTION.useStatedIncome') }} </strong>
					</mat-radio-button>
					<p>
						{{ t('INCOME_VERIFICATION.INCOME_SELECTION.youStated') }}
						<strong>{{ statedMonthlyIncomeAmount | currency }}</strong>
						{{ t('INCOME_VERIFICATION.INCOME_SELECTION.perMonth') }}
					</p>
				</div>
				<div class="op-gray">{{ t('INCOME_VERIFICATION.INCOME_SELECTION.proofRequired') }}</div>
			</div>
		</mat-radio-group>

		<button
			mat-flat-button
			color="primary"
			name="submitBtn"
			class="op-full-width"
			id="submitBtn"
			[disabled]="formGroup.invalid"
			type="submit"
		>
			{{ t('GLOBAL.next') }}
		</button>
	</form>
</div>
