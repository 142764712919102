<div class="offer-code" *transloco="let t">
	<div class="offer-border" [ngClass]="{ collapsed: !showOfferCode }">
		<div
			[@openClose]="!showOfferCode ? 'open' : 'closed'"
			class="op-icon-align-body-1"
			id="clickForOfferCode"
			(click)="expandOfferCode()"
			[tabindex]="!showOfferCode ? 0 : -1"
			#toggleOpen
		>
			<mat-icon svgIcon="op-tagsDouble"></mat-icon>
			{{ t('PERSONAL_INFO.clickForOfferCode') }}
		</div>

		<div [hidden]="!showOfferCode">
			<form
				[@openClose]="showOfferCode ? 'open' : 'closed'"
				[formGroup]="formGroup"
				(ngSubmit)="onSubmit(formGroup.value)"
			>
				<div
					[tabindex]="showOfferCode ? 0 : -1"
					(click)="expandOfferCode()"
					(keydown.space)="expandOfferCode()"
					#toggleClose
				>
					{{ t('PERSONAL_INFO.enterOfferCode') }}
				</div>
				<div class="op-label-font op-gray op-mtb-20">{{ t('PERSONAL_INFO.requiredFields') }}</div>
				<mat-form-field class="form-element op-mb-3" appearance="outline">
					<mat-label>{{ t('LANDING.reservationNumber') }}</mat-label>
					<input matInput type="text" name="offerNumber" id="offerNumber" formControlName="offerNumber" />
					<mat-error
						id="offerNumberError"
						*ngIf="!formGroup.controls['offerNumber'].valid && formGroup.controls['offerNumber'].touched"
					>
						{{ t('LANDING.offerNumberError') }}
					</mat-error>
				</mat-form-field>
				<br />
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('LANDING.lastName') }}</mat-label>
					<input matInput type="text" name="offerLastName" id="offerLastName" formControlName="lastName" />
					<mat-error
						id="lastNameError"
						*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
					>
						{{ t('LANDING.nameError') }}
					</mat-error>
				</mat-form-field>
				<p></p>
				<div id="offerCodeError" class="op-label-font" *ngIf="showError">
					{{ promoResult?.errorMessage }}
				</div>

				<div id="referralCode" class="op-label-font op-mt-10" *ngIf="showError">
					{{ t('PERSONAL_INFO.referralCode') }}
				</div>

				<div class="op-mt-8 offer-code-buttons">
					<div>
						<button
							mat-flat-button
							color="primary-small"
							class="op-full-width"
							type="submit"
							id="offerCodeBtn"
							[disabled]="formGroup.invalid"
						>
							{{ t('PERSONAL_INFO.apply') }}
						</button>
					</div>
					<div class="offer-clear">
						<a class="green-underline" href="" onclick="return false" id="offerClearLink" (click)="clear()">
							{{ t('PERSONAL_INFO.clear') }}
						</a>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
