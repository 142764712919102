<div class="funds-check" *transloco="let t">
	<div class="funds-check-group">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div class="op-form-group">
				<mat-radio-group name="check" id="check" formControlName="check">
					<mat-radio-button [value]="true">
						<strong>{{ t('FUNDS.check') }}</strong>
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div class="op-gray op-mt-10">{{ t('FUNDS.timeToReceiveFundsTitle') }}</div>
			<div class="op-mt-10" id="receiveFundsDesc">
				{{
					checkAtStore
						? t('FUNDS.' + issuingOrganization + '.checkAtStore')
						: t('FUNDS.' + issuingOrganization + '.checkInMail')
				}}
			</div>
			<div class="op-center op-mt-20">
				<button mat-flat-button color="primary-small" id="checkNext" [disabled]="formGroup.invalid">
					{{ t('FUNDS.next') }}
				</button>
			</div>
		</form>
	</div>
	<div class="op-gray op-mt-10 op-label-font">
		{{ t('FUNDS.fundingMayVary') }}
	</div>
</div>
