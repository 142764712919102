<div class="find-your-application" *transloco="let t">
	<op-masthead [title]="t('FIND_YOUR_APPLICATION.title')"></op-masthead>
	<p class="find-application-notice">{{ t('FIND_YOUR_APPLICATION.notice') }}</p>
	<op-content-container class="content">
		<div class="op-label-font op-gray op-mtb-16 op-left">{{ t('ADDRESS.requiredFields') }}</div>
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('FIND_YOUR_APPLICATION.zipCode') }}</mat-label>
				<input
					matInput
					formControlName="zipCode"
					type="text"
					inputmode="numeric"
					pattern="[0-9]*"
					name="postalCode"
					id="postalCode"
					required
				/>
				<mat-error
					id="postalCodeError"
					*ngIf="!formGroup.controls['zipCode'].valid && formGroup.controls['zipCode'].touched"
				>
					{{ t('FIND_YOUR_APPLICATION.zipCodeError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('FIND_YOUR_APPLICATION.lastName') }} </mat-label>
				<input matInput type="text" formControlName="lastName" name="lastName" id="lastName" required />
				<mat-error
					id="lastNameError"
					*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
				>
					{{ t('FIND_YOUR_APPLICATION.lastNameError') }}
				</mat-error>
			</mat-form-field>

			<div class="op-form-group form-group">
				<div class="op-form-title">{{ t('FIND_YOUR_APPLICATION.dateOfBirth') | opRequired }}</div>
				<op-date-input
					name="dateOfBirth"
					id="dateOfBirth"
					formControlName="date"
					showFormat="ddmmyyyy"
					required
				></op-date-input>
				<mat-error
					id="dateOfBirthError"
					*ngIf="!formGroup.controls['date'].valid && formGroup.controls['date'].touched"
				>
					{{ t('FIND_YOUR_APPLICATION.dateOfBirthError') }}
				</mat-error>
			</div>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('FIND_YOUR_APPLICATION.phoneNumber') }}</mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					placeholder="+1 (US)(___) ___-____"
					mask="(000) 000-0000"
					name="telephone"
					id="telephone"
					formControlName="phoneNumber"
					[dropSpecialCharacters]="false"
				/>
				<mat-error
					id="telephoneError"
					*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
				>
					{{ t('FIND_YOUR_APPLICATION.phoneNumberError') }}
				</mat-error>
			</mat-form-field>

			<div class="op-center">
				<button
					mat-flat-button
					color="primary"
					name="findApplicationBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('FIND_YOUR_APPLICATION.findApplication') }}
				</button>
			</div>
		</form>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
