<div class="document-section" *transloco="let t">
	<mat-expansion-panel
		id="{{ id + '_expansionPanel' }}"
		[expanded]="expanded"
		(opened)="opened.emit()"
		(closed)="closed.emit()"
		[ngClass]="isExpansionDisabled()"
		[hideToggle]="isVerified"
	>
		<mat-expansion-panel-header [collapsedHeight]="'107px'" [expandedHeight]="'107px'">
			<mat-panel-title class="op-left">
				<div class="op-body-1-bold-font op-mb-5">{{ title }}</div>
				<div id="{{ id + '_verified' }}" *ngIf="isVerified" class="op-icon-align-body-2 op-mt-5 verified">
					<mat-icon class="verified-icon" svgIcon="op-checkMark-outline"></mat-icon>
					{{ t('STATUS.verified') }}
				</div>
				<div class="upload-count" *ngIf="!notDisplayCount && !isVerified">
					<span id="{{ id + '_expansionPanel_fileCount' }}" class="attachment op-body-2-font">
						{{ (fileList | opFinishedFileUploads)?.length || 0 }} {{ t('DOCUMENT_SUBMIT.attachment') }}
					</span>
				</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-content></ng-content>
	</mat-expansion-panel>
</div>
