<div class="income-add" [formGroup]="parent" *transloco="let t">
	<mat-expansion-panel
		id="{{ id + '_expanionPanel' }}"
		[expanded]="parent.get('expanded').value"
		(closed)="panelClosed(parent)"
		(opened)="panelOpened(parent)"
		hideToggle
	>
		<mat-expansion-panel-header #panelH (click)="panelH._toggle()">
			<mat-panel-title class="op-header-3-font"> {{ t('INCOME.newSource') }} # {{ index }} </mat-panel-title>
			<mat-panel-description>
				<a
					mat-flat-button
					color="link-md"
					id="{{ id + '_cancelEditLink' }}"
					[opTagClick]="'Income edit toggle'"
					*ngIf="parent.get('id').value || !parent.get('expanded').value"
					(click)="panelH._toggle(); restoreOriginalValue()"
				>
					{{ parent.get('expanded').value ? t('GLOBAL.cancel') : t('GLOBAL.edit') }}
				</a>
				<a
					mat-flat-button
					color="link-md"
					id="{{ id + '_removeLink' }}"
					*ngIf="parent.get('id').value || !parent.get('expanded').value"
					[opTagClick]="'Income remove'"
					(click)="deleteIncome(parent, index - 1)"
				>
					{{ t('INCOME.remove') }}
				</a>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="op-body-1-bold-font">{{ t('INCOME.incomeDetails') }}</div>
		<div class="op-mt-20" [ngClass]="{ 'op-mb-20': isOtherIncomeSource }">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('INCOME.source') }} </mat-label>
				<mat-select formControlName="incomeSourceType" id="incomeSourceType" (selectionChange)="onChange(parent)">
					<mat-option
						*ngFor="let incomeType of incomeTypeList; let i = index"
						id="{{ id + '_incomeType_' + incomeType.key }}"
						[value]="incomeType?.key"
					>
						{{ incomeType?.text }}
					</mat-option>
				</mat-select>
				<mat-hint *ngIf="isIncomeSourceOther">{{ t('INCOME.otherTip') }}</mat-hint>
			</mat-form-field>
		</div>

		<div *ngIf="parent.get('incomeSourceType').value">
			<mat-form-field *ngIf="isJob(parent)" class="form-element" appearance="outline">
				<mat-label>{{ t('INCOME.companyName') }}</mat-label>
				<input matInput type="text" name="company" id="{{ id + '_company' }}" formControlName="company" />
			</mat-form-field>

			<mat-form-field *ngIf="isOtherIncome(parent)" class="form-element" appearance="outline">
				<mat-label>{{ t('INCOME.description') }}</mat-label>
				<input
					matInput
					type="text"
					name="incomeDescription"
					id="{{ id + '_incomeDescription' }}"
					formControlName="incomeDescription"
				/>
			</mat-form-field>

			<mat-form-field *ngIf="isJobOrSE(parent)" class="form-element" appearance="outline">
				<mat-label>{{ t('INCOME.industry') }} </mat-label>
				<mat-select name="industryType" id="{{ id + '_industryType' }}" formControlName="industryType">
					<mat-option
						*ngFor="let industry of industryTypeList; let i = index"
						id="{{ id + '_industry_' + industry.key }}"
						[value]="industry.key"
					>
						{{ industry.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="op-form-group">
				<div class="op-form-title">
					<div *ngIf="isJob(parent)">
						{{ t('INCOME.howLongAtJob') | opRequired }}
					</div>
					<div *ngIf="!isJob(parent)">
						{{ t('INCOME.howLongOther') | opRequired }}
					</div>
				</div>

				<op-date-input
					name="startDate"
					id="{{ id + '_startDate' }}"
					formControlName="startDate"
					showFormat="mmyyyy"
				></op-date-input>
				<mat-error
					id="{{ id + '_startDayValidation' }}"
					*ngIf="parent.controls['startDate'].errors?.futureDate && parent.controls['startDate'].dirty"
				>
					{{ t('INCOME.invalidDate') }}
				</mat-error>
			</div>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('INCOME.frequency') }} </mat-label>
				<mat-select name="paymentFrequency" id="{{ id + '_paymentFrequency' }}" formControlName="paymentFrequency">
					<mat-option
						*ngFor="let frequency of incomeFrequencyList; let i = index"
						id="{{ id + '_frequency_' + frequency.key }}"
						[value]="frequency.key"
					>
						{{ frequency.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('INCOME.amount') }}</mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					placeholder="$"
					mask="separator"
					thousandSeparator=","
					prefix="$"
					name="amount"
					id="{{ id + '_amount' }}"
					formControlName="amount"
				/>
				<mat-hint>{{ t('INCOME.sourceNetIncomeInfo') }}</mat-hint>
			</mat-form-field>
		</div>

		<div class="op-center op-full-width op-mt-20">
			<button
				class="op-full-width"
				mat-flat-button
				color="primary"
				name="saveIncome"
				id="{{ id + '_saveIncomeBtn' }}"
				type="button"
				[opTagClick]="'Income save'"
				[disabled]="parent.invalid"
				(click)="saveIncome($event)"
			>
				{{ t('INCOME.save') }}
			</button>
		</div>
	</mat-expansion-panel>

	<div *ngIf="!parent.get('expanded').value">
		<p *ngIf="isIncomeSourceJob">{{ t('INCOME.company') }} {{ parent.get('company').value }}</p>
		<p></p>
		{{ t('INCOME.startDate') }} {{ parent.get('startDate').value | date: 'MM/yyyy' }}
		<p></p>
		{{ t('INCOME.incomeAmount') }} {{ parent.get('amount').value | currency: 'USD':'symbol':'0.0' }}
		<p></p>
	</div>
</div>
