<div class="op-stepper-bar" *transloco="let t">
	<div *ngIf="barState">
		<div class="stepper-bar" [@openClose]="collapsed ? 'true' : 'false'" [attr.aria-hidden]="!collapsed">
			<p></p>
			<div [tabindex]="collapsed ? 0 : -1" class="stepper-horizontal" [ngClass]="{ 'bottom-border': bar }">
				<op-step-title [begin]="true" [title]="t('HOME.HOW_IT_WORKS.yourDetails')" [state]="details"> </op-step-title>
				<op-step-title [title]="t('HOME.HOW_IT_WORKS.getPreApproved')" [state]="preApproved"> </op-step-title>
				<op-step-title [title]="t('HOME.HOW_IT_WORKS.submit')" [state]="submit"> </op-step-title>
				<op-step-title [end]="true" [title]="t('HOME.HOW_IT_WORKS.approved')" [state]="approved"> </op-step-title>
			</div>
			<div>
				<img
					[tabindex]="collapsed ? 0 : -1"
					id="collapsedtrue"
					stepper-gap
					[alt]="collapsed ? t('HOME.HOW_IT_WORKS.showImageAria') : t('HOME.HOW_IT_WORKS.hideImageAria')"
					class="stepper-button"
					(click)="toggle()"
					role="button"
					src="/assets/images/stepper-bar-btn.svg"
					#toggleOpen
				/>
			</div>
		</div>
		<div
			class="stepper-expanded"
			[tabindex]="!collapsed ? 0 : -1"
			[@openClose]="!collapsed ? 'true' : 'false'"
			[attr.aria-hidden]="collapsed"
			#toggleClose
		>
			<p></p>
			<div class="op-left" [ngClass]="{ 'bottom-border': bar }">
				<op-step-info [title]="t('HOME.HOW_IT_WORKS.yourDetails')" [state]="details">
					<span [innerHTML]="t('HOME.HOW_IT_WORKS.provideBasicInformation')"></span>
				</op-step-info>
				<op-step-info [title]="t('HOME.HOW_IT_WORKS.getPreApproved')" [state]="preApproved">
					{{ t('HOME.HOW_IT_WORKS.getMoreInDepth') }}
				</op-step-info>
				<op-step-info [title]="t('HOME.HOW_IT_WORKS.submit')" [state]="submit">
					{{ t('HOME.HOW_IT_WORKS.mayNeedDocuments') }}
				</op-step-info>
				<op-step-info [end]="true" [title]="t('HOME.HOW_IT_WORKS.approved')" [state]="approved">
					{{ t('HOME.HOW_IT_WORKS.goodToGo') }}
					<p></p>
					<p class="op-gray">{{ t('HOME.HOW_IT_WORKS.approvalSubjectToVerification') }}</p>
				</op-step-info>
			</div>
			<div *ngIf="bar">
				<img
					[tabindex]="!collapsed ? 0 : -1"
					id="collapsedfalse"
					[alt]="collapsed ? t('HOME.HOW_IT_WORKS.showImageAria') : t('HOME.HOW_IT_WORKS.hideImageAria')"
					class="stepper-button"
					(click)="toggle()"
					role="button"
					src="/assets/images/stepper-bar-btn.svg"
				/>
			</div>
		</div>
	</div>
</div>
