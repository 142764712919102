import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isAfter } from 'date-fns';
// NOTE: $ npm i --save-dev babel-plugin-lodash @babel/cli @babel/preset-env
// it may reduce package size???? and be able to remove allowedCommonJsDependencies in angular.json
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { CassAddressService } from 'src/app/core/services/ccas/cass-address.service';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import {
	AddressTypeEnum,
	IAddressResult,
	ICassAddress,
	IOfferCodeResult,
	IPartnerLead,
	ISetAddress,
	ISetAddressResult,
	IZipCodeLookUpResult
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { IAddressDetailValue } from 'src/app/shared/components/address-detail/address-detail.component';
import { ILatLngLimiter } from 'src/app/shared/directives/address-auto-complete.directive';

import { LoanApplicationService } from '../../core/services/loan-application/loan-application.service';
import { MobileApiService } from '../../core/services/mobile-api/mobile-api.service';
import { OriginationPartnerService } from '../../core/services/partner/origination-partner.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';

interface IAddressForm {
	date: string;
	homeAddress: IAddressDetailValue;
	mailAddress: IAddressDetailValue;
	mailSameAsHome: boolean;
}

/**
 * Get the users address
 *
 * @export
 * @class AddressComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private mobileService: MobileApiService,
		private landingService: LandingService,
		private loanAppService: LoanApplicationService,
		private dateUtilService: DateUtilsService,
		private routingService: RoutingService,
		private cassAddressService: CassAddressService,
		private originationPartnerService: OriginationPartnerService,
		private tagDataService: TagDataService,
		private sessionStorageService: SessionStorageService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private authService: AuthenticationService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;
	returnToSummary: string;
	latLngLimiter: ILatLngLimiter;
	addressId: string;
	mailAddressId: string;
	selectedAddressCounty: string;
	returnToFTR: string;
	isFTPApplication: boolean;

	private subscription = new Subscription();

	ngOnInit(): void {
		const formSub = this.formGroup.valueChanges.subscribe({
			next: (values) => {
				this.sessionStorageService.set(STORAGE_KEYS.ADDRESS_COMP, values);
			}
		});
		this.subscription.add(formSub);

		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;
		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				take(1),
				switchMap((loanApp: ILoanApplication) => this.mobileService.getAddresses(loanApp.id)),
				switchMap((rsp) => (Boolean(rsp?.length) ? this.updateFromAddresses(rsp) : this.updateFromLookUp()))
			)
			.subscribe();
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private isAddressSame(addresses: IAddressResult[]): boolean {
		const homeAddress = AddressUtils.getHomeAddress(addresses);
		const mailAddress = AddressUtils.getMailAddress(addresses);
		return !Boolean(mailAddress) || AddressUtils.addressesAreEqual(homeAddress, mailAddress);
	}

	private updateFromAddresses(addresses: IAddressResult[]): Observable<any[]> {
		addresses.forEach((item) => {
			if (item.type === AddressTypeEnum.home) {
				this.formGroup.get('homeAddress').setValue({
					address1: item.streetAddress1,
					address2: item.streetAddress2 || '',
					city: item.city,
					state: item.state,
					zipCode: item.postalCode
				});
				if (item.moveInDate && !this.returnToFTR) {
					this.formGroup.get('date').setValue(this.dateUtilService.parseISODate(item.moveInDate));
				}
				this.addressId = item?.id?.toString();
			} else if (item.type === AddressTypeEnum.mail) {
				this.formGroup.get('mailAddress').setValue({
					address1: item.streetAddress1,
					address2: item.streetAddress2 || '',
					city: item.city,
					state: item.state,
					zipCode: item.postalCode
				});
				this.mailAddressId = item?.id?.toString();
			}
		});
		// Set mailingSameAs flag if there is no address (default it) or when they are equal
		this.formGroup.get('mailSameAsHome').setValue(this.isAddressSame(addresses));
		return of(addresses);
	}

	private updateFromLookUp(): Observable<any> {
		const sessionData = this.sessionStorageService.get(STORAGE_KEYS.ADDRESS_COMP);
		const leadId: number = this.loanAppService.getCurrentApplicant()?.leadId || 0;

		return of(this.landingService.getPromoCodeInformation()).pipe(
			switchMap((offerCode) => {
				if (Boolean(offerCode)) {
					return this.updateFromOfferCode(offerCode);
				} else if (leadId > 0) {
					return this.updateFromLead(leadId);
				} else {
					throw new Error();
				}
			}),
			catchError(() => {
				if (Boolean(sessionData)) {
					return this.updateFromSessionStorage(sessionData);
				} else {
					return this.updateFromZipLookUp(AddressTypeEnum.home);
				}
			})
		);
	}

	private updateFromOfferCode(offerCodeInformation: IOfferCodeResult): Observable<IOfferCodeResult> {
		return of(offerCodeInformation).pipe(
			tap((rsp) => {
				this.formGroup.get('homeAddress').setValue({
					address1: rsp.street_addresss1,
					address2: rsp.street_addresss2 || '',
					city: rsp.city,
					state: rsp.state,
					zipCode: rsp.postal_code
				});
			})
		);
	}

	private updateFromLead(leadId: number): Observable<IPartnerLead | IZipCodeLookUpResult> {
		return this.mobileService.getPartnerLeadDetails(leadId).pipe(
			tap((partnerLead: IPartnerLead) => {
				if (partnerLead) {
					this.formGroup.get('homeAddress').setValue({
						address1: partnerLead?.address1,
						address2: partnerLead?.address2,
						city: partnerLead?.city,
						zipCode: partnerLead?.postalCode,
						state: partnerLead?.state
					});
				} else {
					throw new Error();
				}
			})
		);
	}

	private updateFromSessionStorage(storedFormData: IAddressForm): Observable<IAddressForm> {
		return of(storedFormData).pipe(
			tap((rsp) => {
				this.formGroup.setValue(rsp);
			})
		);
	}

	/**
	 * Partially update the form from a zipCode look up.
	 *
	 * @returns {Observable<IZipCodeLookUpResult>}
	 * @memberof AddressComponent
	 */
	private updateFromZipLookUp(addressType: AddressTypeEnum, zipCode: string = null): Observable<IZipCodeLookUpResult> {
		const zip = zipCode || this.loanAppService.getCurrentApplicant()?.homePostalCode;
		return this.mobileService.zipCodeLookUp(zip).pipe(
			tap((resp) => {
				const zipLookUpData = {
					city: resp?.populateAddress?.city[0],
					state: resp?.populateAddress?.state[0],
					zipCode: zip
				};
				if (addressType === AddressTypeEnum.home) {
					this.formGroup.get('homeAddress').patchValue(zipLookUpData);
				} else {
					this.formGroup.get('mailAddress').patchValue(zipLookUpData);
				}
			})
		);
	}

	private moveInDateValidation(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const birthDate = this.loanAppService.getCurrentApplicant().dateOfBirth;
			const priorBirth = isAfter(this.dateUtilService.parseISODate(birthDate), control.value);
			const afterToday = isAfter(control.value, new Date());

			return priorBirth ? { priorToBirthDate: priorBirth } : afterToday ? { futureDate: true } : null;
		};
	}

	private createForm(fb): FormGroup {
		this.formGroup = fb.group({
			homeAddress: [],
			date: [null, [Validators.required, this.moveInDateValidation()]],
			mailSameAsHome: [true],
			mailAddress: []
		});
		return this.formGroup;
	}

	navigate(route: RoutingPathsEnum): void {
		if (this.returnToFTR) {
			this.loanAppService
				.updateLoanApplication()
				.pipe(
					switchMap((loanApp) => {
						this.sessionStorageService.set('applicationFlow', loanApp.applicationFlow);
						if (loanApp.applicationStatus === ApplicationStatusEnum.futureProspect) {
							this.routingService.routeByStatus();
							return of();
						} else {
							this.routingService.routeForFastTrack(this.returnToFTR);
							return of();
						}
					})
				)
				.subscribe();
		} else if (this.isFTPApplication) {
			this.routingService.route(RoutingPathsEnum.summary);
		} else if (this.returnToSummary) {
			this.routingService.routeFromLoanApp({
				queryParams: { returnToSummary: this.returnToSummary }
			});
		} else {
			this.routingService.route(route);
		}
	}
	/**
	 *
	 *
	 * @private
	 * @param {string} newIssuingOrganization
	 * @param {ISetAddress} setAddress
	 * @return {*}  {Observable<any>}
	 * @memberof AddressComponent
	 */
	private processChangeOfIssuingOrganization(newIssuingOrganization: string, setAddress: ISetAddress): Observable<any> {
		const message = OrganizationUtils.isMetaBank(newIssuingOrganization)
			? `</strong><p><p> ${this.translocoService.translate('ADDRESS.stateChangeToMetaBank')} `
			: `</strong><p><p> ${this.translocoService.translate('ADDRESS.stateChangeToOportun')} `;
		const data = {
			title: `${this.translocoService.translate('DIALOG_MESSAGE.message')}`,
			message,
			confirmText: this.translocoService.translate('GLOBAL.continue'),
			cancelText: this.translocoService.translate('GLOBAL.cancel')
		};
		return this.dialogService.openMessageDialog(
			data,
			(rsp) => this.saveAddress(setAddress, this.addressId),
			() => of()
		);
	}

	saveAddress(address: ISetAddress, addressId: string): Observable<ISetAddressResult> {
		address.county = this.selectedAddressCounty;
		return addressId
			? this.mobileService.updateAddresses(address, addressId, this.loanAppService.loanApplicationId)
			: this.mobileService.setAddresses(address, this.loanAppService.loanApplicationId);
	}

	createAddressObject(addressType: AddressTypeEnum): ICassAddress {
		const address =
			addressType === AddressTypeEnum.home
				? this.formGroup.get('homeAddress').value
				: this.formGroup.get('mailAddress').value;
		return {
			streetAddress1: address?.address1,
			streetAddress2: address?.address2 || '',
			city: address?.city,
			state: address?.state,
			postalCode: address?.zipCode,
			country: 'US',
			type: addressType,
			currentAddress: addressType === AddressTypeEnum.home ? String(true) : String(false),
			originationCode: this.originationPartnerService.getOriginationCode() || null
		};
	}

	saveHomeAddress(addr, date): Observable<any> {
		const setAddress = { ...addr } as ISetAddress;
		setAddress.moveInDate = this.dateUtilService.format(date, 'yyyy-MM-dd');
		this.tagDataService.merge({
			applicant_city: setAddress.city,
			applicant_state: setAddress.state,
			applicant_country: setAddress.country,
			applicant_postal_code: setAddress.postalCode
		});
		return this.addressId ? this.checkForAddressChange(setAddress) : this.saveAddress(setAddress, this.addressId);
	}

	/**
	 *
	 *
	 * @param {*} address
	 * @return {*}  {Observable<any>}
	 * @memberof AddressComponent
	 */
	checkForAddressChange(address): Observable<any> {
		return this.mobileService
			.getAddressPreCheck(address, this.loanAppService.loanApplicationId)
			.pipe(
				switchMap((rsp) =>
					OrganizationUtils.isNewOrganization(rsp.result)
						? this.processChangeOfIssuingOrganization(rsp.value, address)
						: this.saveAddress(address, this.addressId)
				)
			);
	}

	saveMailAddress(addr, date): Observable<any> {
		const setMailAddress = { ...addr };
		setMailAddress.moveInDate = this.dateUtilService.format(date, 'yyyy-MM-dd');
		setMailAddress.type = AddressTypeEnum.mail;
		return this.saveAddress(setMailAddress, this.mailAddressId);
	}

	onSubmit(post: any): void {
		this.cassAddressService
			.verifyCassAddress(this.createAddressObject(AddressTypeEnum.home))
			.pipe(
				concatMap((addr) => {
					const save$ = [this.saveHomeAddress(addr, post.date)];
					if (this.formGroup.get('mailSameAsHome').value) {
						this.selectedAddressCounty = addr.county;
						save$.push(this.saveMailAddress(addr, post.date));
					}
					return forkJoin(save$);
				}),
				switchMap((values) => {
					if (!this.formGroup.get('mailSameAsHome').value) {
						return this.cassAddressService
							.verifyCassAddress(this.createAddressObject(AddressTypeEnum.mail))
							.pipe(switchMap((addr) => this.saveMailAddress(addr, post.date)));
					} else {
						return of(values);
					}
				}),
				switchMap(() => this.loanAppService.updateLoanApplication()) // call GET loanAPP API after address update to retrieve latest applicationFlow
			)
			.subscribe({
				next: (loanApp: ILoanApplication) => {
					this.isFTPApplication = this.loanAppService.isPrequal(loanApp);
					const plaidBtmEligible = ApplicantUtils.fromLoanApp(loanApp).isBtmEligible();
					const bankAdded = this.loanAppService.isBtmBankConnected();

					const plaidConnectAddressScreenOrderSwapEnabled = Boolean(
						this.sessionStorageService.get(STORAGE_KEYS.PLAID_CONNECT_ADDRESS_SCREEN_ORDER_SWAP_ENABLED)
					);

					const nextRoute =
						plaidBtmEligible && !bankAdded && plaidConnectAddressScreenOrderSwapEnabled
							? RoutingPathsEnum.plaidConnect
							: RoutingPathsEnum.identification;
					this.navigate(nextRoute);

					this.sessionStorageService.remove(STORAGE_KEYS.ADDRESS_COMP);
				},
				error: (err) => {
					this.dialogService
						.openErrorDialog(err)
						.pipe(
							filter(() => Array.isArray(err?.error) && err.error.some((e) => e?.id === 'appError1')),
							switchMap(() => this.authService.signOut()),
							tap(() => this.routingService.route(RoutingPathsEnum.findYourApplication))
						)
						.subscribe();
				}
			});
	}
}
