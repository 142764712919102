<div class="requires-attention" *transloco="let t">
	<op-masthead title="{{ t('STATUS.title') }}">
		<h2 id="requires-attention-bounced">{{ t('STATUS.bounced') }}</h2>
	</op-masthead>
	<op-content-container>
		<p>
			<span id="rq-message"
				>{{ t('STATUS.REQUIRES_ATTENTION.body') }}
				<a href="tel:18885640084" id="requiresAttentionPhoneLink" class="link-phone-number">1-888-564-0084</a>.
			</span>
		</p>
		<hr />
		<div *ngIf="loanApp?.applicationStatus | opIsStatusEqual: loanStatus.bounced">
			<div>
				<op-bounced-attention [showSection]="showSection"></op-bounced-attention>
			</div>
			<hr />
			<p></p>
			<div *ngIf="!showSection.referencesOnly" class="buttons">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="requiresAttentionUploadBtn"
					type="button"
					[opTagClick]="'status_reqAttention upload'"
					(click)="showUploadDialog()"
				>
					{{ isMobile ? t('STATUS.upload') : t('STATUS.take') }}
				</button>
				<button
					mat-flat-button
					color="secondary"
					name="submitBtn"
					id="requiresAttentionStoreBtn"
					type="button"
					(click)="showStoreDialog()"
				>
					{{ 'STATUS.store' | transloco }}
				</button>
			</div>
		</div>
	</op-content-container>
</div>
