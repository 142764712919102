import { Pipe, PipeTransform } from '@angular/core';
import { StatesEnum } from 'src/app/core/utils/state-utils';

/**
 * Check to see if state is equal to params.
 *
 * @export
 * @class IsStateEqualPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opIsStateEqual'
})
export class IsStateEqualPipe implements PipeTransform {
	transform(state: StatesEnum, ...args: unknown[]): unknown {
		return args?.some((s: string) => s?.toUpperCase() === state);
	}
}
