<div class="address" *transloco="let t">
	<op-masthead [title]="t('ADDRESS.title')"></op-masthead>
	<div class="op-label-font op-gray op-mb-20">{{ t('ADDRESS.requiredFields') }}</div>
	<op-content-container class="content">
		<div id="yourAddressLabel" class="op-left op-header-3-font op-mb-20">
			{{ t('ADDRESS.whatIsYourAddress') }}
		</div>

		<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div class="home-address" role="group" aria-labelledby="yourAddressLabel">
				<op-address-detail-form
					[homeAddress]="true"
					id="homeAddress"
					formControlName="homeAddress"
				></op-address-detail-form>
			</div>

			<div class="op-form-group" role="group" aria-labelledby="moveInLabel" aria-live="polite">
				<div id="moveInLabel" class="op-form-title">{{ t('ADDRESS.whenDidYouMove') | opRequired }}</div>
				<op-date-input formControlName="date" name="moveDate" id="moveDate" showFormat="mmyyyy"></op-date-input>
				<mat-error
					class="op-label-font op-ml-5"
					id="birthDayValidation"
					*ngIf="formGroup.controls['date'].errors?.priorToBirthDate && formGroup.controls['date'].touched"
				>
					{{ t('ADDRESS.birthDateValidation') }}
				</mat-error>
				<mat-error class="op-label-font op-ml-5" id="futureDate" *ngIf="formGroup.controls['date'].errors?.futureDate">
					{{ t('ADDRESS.invalidDate') }}
				</mat-error>
			</div>

			<div id="mailAddressLabel" class="op-left op-header-3-font">
				{{ t('ADDRESS.mailAddress') }}
			</div>
			<div class="op-left op-mt-12">
				<mat-checkbox name="mailSameAsHome" id="mailSameAsHome" formControlName="mailSameAsHome" color="primary">
					<div class="op-body-1-font">{{ t('ADDRESS.mailSameAsHome') }}</div>
				</mat-checkbox>
			</div>

			<div
				role="group"
				aria-labelledby="mailAddressLabel"
				class="mail-address"
				[hidden]="formGroup.controls['mailSameAsHome'].value"
			>
				<op-address-detail-form
					id="mailAddress"
					[setValidator]="formGroup.controls['mailSameAsHome'].value"
					formControlName="mailAddress"
				></op-address-detail-form>
			</div>

			<div class="op-center op-mt-20">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					[opTagClick]="'Address next'"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
