import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { endOfMonth, isAfter } from 'date-fns';
import { Subscription, filter } from 'rxjs';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IGetIncomeResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { moneyValidator } from 'src/app/shared/validators/form-validators';
import { AddressUtils } from 'src/app/core/utils/address-utils';

import { companyNameValidator } from '../../shared/validators/form-validators';
import { AddIncomeSourceDialogComponent } from './add-income-source-dialog/add-income-source-dialog.component';
import { find, noop } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarAlertComponent } from 'src/app/shared/components/snack-bar-alert/snack-bar-alert.component';
import { PreQualifyService } from 'src/app/pre-qualify/pre-qualify.service';

export interface AddIncomeSourceDialogData {
	id: string;
	parent: FormGroup;
	count: number;
	index: number;
	newSourceText: string;
	incomeTypeList: Array<IMetadata>;
	incomeFrequencyList: Array<IMetadata>;
	industryTypeList: Array<IMetadata>;
}

@Component({
	selector: 'op-pre-qualify-income-source',
	templateUrl: './income-source.component.html',
	styleUrls: ['./income-source.component.scss']
})
export class IncomeSourceComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private dialogService: DialogService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private metadataService: MetadataService,
		private dateUtilService: DateUtilsService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private progressBarService: ProgressBarService,
		private snackBar: MatSnackBar,
		private preQualifyService: PreQualifyService
	) {
		this.createForm(formBuilder);
	}

	formGroup: FormGroup;

	ready = false;
	incomeSourceTypeList: IMetadata[];
	industryTypeList: IMetadata[];
	incomeFrequencyList: IMetadata[];

	initialTakeHomePay: string;

	newSourceText: string;

	isCAPostalCode: boolean = false;

	private subscription = new Subscription();

	incomeMatch: boolean = false;

	progressBarStep: number = 4;

	ngOnInit(): void {
		const loanSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApp) => {
				this.progressBarService.setProgressBarValue(
					this.preQualifyService.skipIdentityScreen
						? this.route.snapshot.data?.step - 1
						: this.route.snapshot.data?.step
				);
			}
		});
		this.subscription.add(loanSub);

		const newSource = this.translocoService.selectTranslate('INCOME.newSource').subscribe({
			next: (value) => (this.newSourceText = value)
		});
		this.subscription.add(newSource);

		const industryTypeSub = this.metadataService.select(MetadataEnum.IndustryType).subscribe({
			next: (rsp) => {
				this.industryTypeList = rsp;
			}
		});
		this.subscription.add(industryTypeSub);

		this.getIncomes();

		const incomeTypeSub = this.metadataService.select(MetadataEnum.IncomeSourceType).subscribe({
			next: (rsp) => {
				this.incomeSourceTypeList = rsp;
				this.tagDataService.view(
					{
						income_source: this.incomeList.value
					},
					{
						tealium_event: 'income'
					}
				);
			}
		});
		this.subscription.add(incomeTypeSub);

		const reqSub = this.metadataService.select(MetadataEnum.IncomeFrequency).subscribe({
			next: (rsp) => {
				this.incomeFrequencyList = rsp;
			}
		});
		this.subscription.add(reqSub);

		this.formGroup.valueChanges.subscribe({
			next: (rsp) => {
				this.incomeMatch = this.checkIncomeMatch(rsp.incomes);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	checkIncomeMatch(incomes): boolean {
		return incomes.reduce((acc, curr) => acc + Number(curr.amount), 0) === this.initialTakeHomePay;
	}
	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			incomes: new FormArray([], Validators.required)
		});
	}

	private getIncomes(): void {
		const income$ = this.mobileService.getIncome(this.loanAppService.loanApplicationId);
		income$.subscribe({
			next: (income) => {
				this.initialTakeHomePay = this.loanAppService.getCurrentApplicant().initialTakeHomePay;
				this.ready = true;
				if (income.length) {
					income.forEach((item) => this.increaseIncome(item));
					this.incomeMatch = this.checkIncomeMatch(income);
				} else {
					//this.increaseIncome({}, true);
				}
			}
		});
	}

	get formControls(): any {
		return this.formGroup.controls;
	}
	get incomeList(): FormArray {
		return this.formControls.incomes as FormArray;
	}

	/**
	 * Returns the month and year an income started in a human readable format.
	 * Used in the header of each income in the accordion.
	 */
	startedDate(income: AbstractControl): string {
		if (income.value.startDate) {
			return this.dateUtilService.format(income.value.startDate, 'MMM yyyy');
		} else {
			return '';
		}
	}

	protected openAddIncomeSourceModal(income, index): void {
		const data = {
			parent: income,
			count: this.incomeList.length,
			index: index,
			id: this.incomeList.length,
			newSourceText: this.newSourceText,
			incomeTypeList: this.incomeSourceTypeList,
			industryTypeList: this.industryTypeList,
			incomeFrequencyList: this.incomeFrequencyList
		};
		const dialogRef = this.dialogService.open(AddIncomeSourceDialogComponent, {
			data,
			height: 'calc(100%)',
			maxWidth: '100vw !important',
			maxHeight: '100vh',
			panelClass: ['pre-qualify', 'add-income-dialog']
		});
		dialogRef.afterClosed().subscribe((rsp) => {
			Boolean(rsp.action === 'save')
				? this.saveIncome(rsp.income)
				: Boolean(rsp.action === 'delete')
				? this.deleteIncome(rsp.income)
				: noop;
		});
	}

	protected openNewAddIncomeSourceModal(): void {
		const income_ = this.createIncome({});
		const index = this.incomeList.length;
		this.openAddIncomeSourceModal(income_, index);
	}

	createIncome(income: Partial<IGetIncomeResult>): FormGroup {
		return this.formBuilder.group({
			incomeSourceType: [income.incomeSourceType, Validators.required],
			incomeDescription: [income.incomeDescription],
			company: [income.company, [companyNameValidator()]],
			industryType: [income.industryType],
			paymentFrequency: [income.paymentFrequency, Validators.required],
			amount: [income.amount, [Validators.required, moneyValidator(), Validators.min(1)]],
			startDate: [
				income.startDate ? this.dateUtilService.parseISODate(income.startDate) : null,
				[Validators.required, this.startDateValidation()]
			],
			id: [income.id]
		});
	}

	private startDateValidation(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const afterToday = isAfter(control.value, endOfMonth(new Date()));
			return afterToday ? { futureDate: true } : null;
		};
	}

	increaseIncome(income: Partial<IGetIncomeResult>): void {
		this.incomeList.push(this.createIncome(income));
	}

	addIncome(): void {
		this.increaseIncome({});
	}

	saveIncome(income: AbstractControl): void {
		const incomeValue = income.value;
		const JOB = 1700;

		const data = {
			incomeSourceType: incomeValue.incomeSourceType,
			incomeDescription: incomeValue.incomeDescription,
			company: incomeValue.incomeSourceType === JOB ? incomeValue.company : null,
			industryType: incomeValue.industryType,
			paymentFrequency: incomeValue.paymentFrequency,
			amount: incomeValue.amount,
			startDate: this.dateUtilService.format(incomeValue.startDate, 'yyyy-MM-dd'),
			hasIncomeDocuments: false,
			id: incomeValue.id
		};

		if (incomeValue.id) {
			this.mobileService.updateIncome(data, this.loanAppService.loanApplicationId).subscribe({
				next: (resp) => {
					income.get('id').setValue(resp.id);
				}
			});
		} else {
			this.mobileService.setIncome(data, this.loanAppService.loanApplicationId).subscribe({
				next: (resp) => {
					income.get('id').setValue(resp.id);
					this.incomeList.push(income);
				}
			});
		}
	}

	snackBarAlert(): void {
		this.snackBar.openFromComponent(SnackBarAlertComponent, {
			data: {
				message: this.translocoService.translate('PRE_QUALIFY.Income.delete'),
				icon: 'op-checkMark-outline',
				styleClasses: 'op-header-commons-pro pre-qualify',
				spanClasses: 'op-body-1'
			},
			duration: 10000,
			verticalPosition: 'bottom'
		});
	}

	deleteIncome(event): void {
		const { income, index } = event;
		const incomeValue = income.value;

		if (incomeValue.id) {
			this.mobileService.deleteIncome(incomeValue.id, this.loanAppService.loanApplicationId).subscribe();
		}

		income.parent.removeAt(index);
		this.snackBarAlert();
	}

	getIncomeType(incomeTypeId: string): string {
		return find(this.incomeSourceTypeList, { key: incomeTypeId }).text;
	}

	navigate(route: RoutingPathsEnum): void {
		this.routingService.route(route);
	}

	onSubmit(post: any): void {
		this.tagDataService.link(
			{
				income_source: this.incomeList.value
			},
			{
				tealium_event: 'income_process_complete',
				event_action: 'submit',
				event_label: 'Form',
				event_category: 'rx_form'
			}
		);

		this.mobileService.updateIdentity(this.loanAppService.loanApplicationId).subscribe({
			next: (loanApp) => {
				if (this.loanAppService.isBtmEligible()) {
					this.routingService.routeBtm();
				} else if (this.loanAppService.isIncomeVerificationEligible()) {
					this.routingService.route(RoutingPathsEnum.autoVerifyIncome);
				} else {
					this.routingService.route(RoutingPathsEnum.receiveFunds);
				}
			}
		});
	}
}
