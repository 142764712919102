<div *ngIf="productCategory !== 'cashback'">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 153 242.6"
		style="enable-background: new 0 0 153 242.6"
		xml:space="preserve"
	>
		<style type="text/css">
			.st0 {
				fill: #ffffff;
			}

			.st1 {
				clip-path: url(#SVGID_00000145055861318634371230000003457483860659189385_);
			}

			.st2 {
				opacity: 0.89;
			}

			.st3 {
				clip-path: url(#SVGID_00000091704499574340306970000016574974420962410633_);
			}

			.st4 {
				fill: url(#SVGID_00000137832211557756010690000006540822636832392101_);
			}

			.st5 {
				opacity: 9e-2;
				fill: #3c3b3c;
				enable-background: new;
			}

			.st6 {
				clip-path: url(#SVGID_00000006682055317650021070000015397986684324071576_);
			}

			.st7 {
				fill: url(#SVGID_00000065065240323481631170000000252480074335941266_);
			}

			.st8 {
				fill: none;
				stroke: #b3b4b6;
				stroke-width: 0.19;
			}

			.st9 {
				fill: none;
				stroke: #a0a2a4;
				stroke-width: 0.24;
			}

			.st10 {
				fill: none;
				stroke: #b3b4b6;
				stroke-width: 0.24;
			}

			.st11 {
				fill: #6cdb8b;
			}

			.st12 {
				opacity: 0.15;
			}

			.st13 {
				clip-path: url(#SVGID_00000123426475181608211580000014232471857942371513_);
			}
		</style>
		<g>
			<g id="Layer_1_00000139256033998011732300000002619454341743924645_">
				<path
					class="st0"
					d="M153,233.8V8.9c0-5-4.1-8.9-8.9-8.9H8.9C3.8,0,0,4.1,0,9.1v224.4c0,5,4.1,9.1,9.1,9.1h134.8
        C149,242.6,153,238.6,153,233.8L153,233.8z"
				/>
				<g>
					<defs>
						<path
							id="SVGID_1_"
							d="M0,8.9v224.9c0,5,4.1,8.9,8.9,8.9h135.3c5,0,8.9-4.1,8.9-9.1V9.1c0-5-4.1-9.1-9.1-9.1H9.1
            C4.1,0,0,4.1,0,8.9z"
						/>
					</defs>
					<clipPath id="SVGID_00000171702255509236939630000002051955254135233456_">
						<use xlink:href="#SVGID_1_" style="overflow: visible" />
					</clipPath>
					<g style="clip-path: url(#SVGID_00000171702255509236939630000002051955254135233456_)">
						<g class="st2">
							<g>
								<defs>
									<rect
										id="SVGID_00000155853096253583565020000006196407454521974925_"
										x="-2.8"
										y="-3.3"
										width="159.3"
										height="249.1"
									/>
								</defs>
								<clipPath id="SVGID_00000100383375304246276030000012283237653794747563_">
									<use
										xlink:href="#SVGID_00000155853096253583565020000006196407454521974925_"
										style="overflow: visible"
									/>
								</clipPath>
								<g style="clip-path: url(#SVGID_00000100383375304246276030000012283237653794747563_)">
									<linearGradient
										id="SVGID_00000074428480538556652890000007163677550181843854_"
										gradientUnits="userSpaceOnUse"
										x1="-411.0387"
										y1="212.6985"
										x2="-409.6887"
										y2="212.6985"
										gradientTransform="matrix(170.5471 170.5471 -170.5471 170.5471 106332.6562 33826.4883)"
									>
										<stop offset="0" style="stop-color: #808285; stop-opacity: 0.9" />
										<stop offset="2.000000e-02" style="stop-color: #898b8e; stop-opacity: 0.91" />
										<stop offset="0.1" style="stop-color: #a9aaac; stop-opacity: 0.95" />
										<stop offset="0.18" style="stop-color: #bfc0c2; stop-opacity: 0.98" />
										<stop offset="0.24" style="stop-color: #cdcecf; stop-opacity: 0.99" />
										<stop offset="0.29" style="stop-color: #d2d3d4" />
										<stop offset="0.72" style="stop-color: #808285" />
										<stop offset="0.79" style="stop-color: #919295" />
										<stop offset="0.94" style="stop-color: #bdbfc0" />
										<stop offset="1" style="stop-color: #d2d3d4" />
									</linearGradient>
									<polygon
										style="fill: url(#SVGID_00000074428480538556652890000007163677550181843854_)"
										points="-155.5,107.9 63.9,-111.5 
                  294.4,119 75,338.4 							"
									/>
								</g>
							</g>
						</g>
						<rect x="-6" y="-6.3" class="st5" width="168" height="256.5" />
						<g id="chip">
							<g>
								<defs>
									<path
										id="SVGID_00000124857040102162426470000014238883124852082334_"
										d="M74.6,27.2c-1.8,0-3.3,1.5-3.3,3.4v20.3
                  c0,1.9,1.5,3.4,3.3,3.4h19.7c1.8,0,3.3-1.5,3.3-3.4V30.6c0-1.9-1.5-3.4-3.3-3.4C94.3,27.2,74.6,27.2,74.6,27.2z"
									/>
								</defs>
								<clipPath id="SVGID_00000182512942718963583250000012672197923023321250_">
									<use
										xlink:href="#SVGID_00000124857040102162426470000014238883124852082334_"
										style="overflow: visible"
									/>
								</clipPath>
								<g style="clip-path: url(#SVGID_00000182512942718963583250000012672197923023321250_)">
									<linearGradient
										id="SVGID_00000096030049702684703600000008188566899842939294_"
										gradientUnits="userSpaceOnUse"
										x1="-406.5732"
										y1="196.7213"
										x2="-405.5732"
										y2="196.7213"
										gradientTransform="matrix(-18.26 31.6272 -31.6272 -18.26 -1109.0365 16476.4473)"
									>
										<stop offset="0" style="stop-color: #8f9092" />
										<stop offset="0.39" style="stop-color: #d6d8d8" />
										<stop offset="0.64" style="stop-color: #e8e9e9" />
										<stop offset="0.71" style="stop-color: #d1d2d3" />
										<stop offset="0.83" style="stop-color: #b6b7b9" />
										<stop offset="0.92" style="stop-color: #a5a7a9" />
										<stop offset="1" style="stop-color: #a0a2a4" />
									</linearGradient>
									<polygon
										style="fill: url(#SVGID_00000096030049702684703600000008188566899842939294_)"
										points="59.6,47.5 77.9,15.9 
                  109.3,34 91,65.6 							"
									/>
								</g>
							</g>
						</g>
						<g id="Visa">
							<path
								class="st8"
								d="M71.3,50.9c0,1.9,1.5,3.4,3.3,3.4h19.7c1.8,0,3.3-1.5,3.3-3.4V30.6c0-1.9-1.5-3.4-3.3-3.4H74.6
              c-1.8,0-3.3,1.5-3.3,3.4C71.3,30.6,71.3,50.9,71.3,50.9z"
							/>
							<polyline class="st9" points="90.2,27.5 90.2,36.4 86.9,38.2 86.9,44.9 					" />
							<polyline class="st9" points="86.9,38.1 80.1,38.1 80.1,44.8 77.1,47.9 77.1,54 					" />
							<polyline class="st9" points="80.1,44.8 86.8,44.8 90.2,48.3 90.2,54 					" />
							<line class="st9" x1="84.2" y1="44.8" x2="84.2" y2="54.1" />
							<line class="st9" x1="83.9" y1="38" x2="83.9" y2="27.5" />
							<polyline class="st9" points="80.1,38.1 77.4,35.1 77.4,27.3 					" />
							<polyline class="st10" points="90.6,27.4 90.6,36 87.3,37.8 87.3,44.5 					" />
							<polyline class="st10" points="87.2,37.7 80.5,37.7 80.5,44.4 77.5,47.5 77.5,54 					" />
							<polyline class="st10" points="80.5,44.4 87.2,44.4 90.6,47.9 90.6,54 					" />
							<line class="st10" x1="84.6" y1="44.4" x2="84.6" y2="54" />
							<line class="st10" x1="84.3" y1="37.7" x2="84.3" y2="27.4" />
							<polyline class="st10" points="80.5,37.7 77.8,34.7 77.8,27.2 					" />
							<line class="st9" x1="79.6" y1="41.3" x2="71.7" y2="41.3" />
							<line class="st10" x1="79.7" y1="41.8" x2="71.7" y2="41.8" />
							<line class="st9" x1="97.2" y1="41.3" x2="87.8" y2="41.3" />
							<line class="st10" x1="97.2" y1="41.8" x2="87.7" y2="41.8" />
						</g>
						<g id="Logo">
							<path
								id="Wordmark"
								class="st0"
								d="M63.4,127.4v1.1c1.1-1.5,2.9-2.4,4.9-2.4c4,0,7,3.3,7,7.6s-3.1,7.6-7,7.6
              c-2,0-3.8-0.9-4.9-2.3v7.1c0,0.5-0.4,1-1,1H61c-0.5,0-1-0.4-1-1v-18.6c0-0.5,0.4-1,1-1h1.5C63,126.5,63.4,126.9,63.4,127.4
              L63.4,127.4z M67.6,129.2c-2.4,0-4.3,2-4.3,4.5s1.9,4.5,4.3,4.5s4.3-2,4.3-4.5S70.1,129.2,67.6,129.2z M92.2,133.6
              c0,4.2-3.3,7.6-7.7,7.6s-7.7-3.3-7.7-7.6s3.3-7.6,7.7-7.6S92.2,129.4,92.2,133.6z M80.3,133.6c0,2.5,1.9,4.4,4.3,4.4
              s4.3-2,4.3-4.4s-1.9-4.4-4.3-4.4S80.3,131.2,80.3,133.6z M102.2,128.9c0,0.4-0.3,0.7-0.7,0.6c-0.1,0-0.1,0-0.2,0
              c-2.5,0-4,1.9-4,4.9v5.4c0,0.5-0.4,1-1,1h-1.5c-0.5,0-1-0.4-1-1v-12.5c0-0.5,0.4-1,1-1h1.4c0.5,0,1,0.4,1,1v1.5
              c0.7-1.7,2.4-2.8,4.3-2.8l0,0c0.3,0,0.6,0.3,0.6,0.7L102.2,128.9L102.2,128.9z M109.5,123.1v3.3h2.9c0.5,0,1,0.4,1,1v1
              c0,0.5-0.4,1-1,1h-2.9v6.5c0,1.6,0.7,2.3,2.2,2.3c0.2,0,0.4,0,0.7-0.1c0.5-0.1,0.9,0.3,0.9,0.8v1.4c0,0.3-0.2,0.6-0.5,0.7
              c-0.5,0.2-1.2,0.3-2.1,0.3c-2.9,0-4.6-1.8-4.6-4.8v-7.1h-1.2c-0.5,0-1-0.4-1-1v-1c0-0.5,0.4-1,1-1h1.2v-3.3c0-0.5,0.4-1,1-1
              h1.5C109,122.2,109.5,122.6,109.5,123.1L109.5,123.1z M126.3,126.4h1.5c0.5,0,1,0.4,1,1v12.5c0,0.5-0.4,1-1,1h-1.5
              c-0.5,0-1-0.4-1-1v-0.9c-0.9,1.4-2.4,2.3-4.4,2.3c-3.4,0-5.5-2.4-5.5-6.1v-7.7c0-0.5,0.4-1,1-1h1.5c0.5,0,1,0.4,1,1v7.1
              c0,2.2,1.2,3.6,3.1,3.6s3.4-1.6,3.4-4v-6.6C125.3,126.9,125.7,126.4,126.3,126.4L126.3,126.4z M133.4,140.8h-1.5
              c-0.5,0-1-0.4-1-1v-12.5c0-0.5,0.4-1,1-1h1.5c0.5,0,1,0.4,1,1v0.9c0.9-1.3,2.4-2.3,4.4-2.3c3.5,0,5.6,2.4,5.6,6.1v7.7
              c0,0.5-0.4,1-1,1h-1.5c-0.5,0-1-0.4-1-1v-7.1c0-2.2-1.1-3.6-3.2-3.6s-3.4,1.6-3.4,4v6.6C134.3,140.4,133.9,140.8,133.4,140.8
              L133.4,140.8z"
							/>
							<g id="Symbol">
								<path
									id="Segments"
									class="st0"
									d="M41.3,135.1c0,1-0.8,1.7-1.7,1.7c-0.7,0-1.2-0.4-1.5-0.8c-0.2-0.4-0.5-0.8-0.7-1.3
                c-0.2-0.4-0.3-0.8-0.4-1.3c-0.2-0.9-0.4-1.9-0.4-2.8c0-0.8,0.1-1.5,0.2-2.2c0.2-0.7,0.4-1.5,0.7-2.1c0.1-0.3,0.4-0.6,0.7-0.8
                c0.3-0.2,0.6-0.2,0.9-0.2c1,0,1.7,0.8,1.7,1.7c0,0.2,0,0.5-0.2,0.7l0,0c-0.4,0.9-0.6,1.9-0.6,3c0,1.3,0.3,2.6,1,3.6l0,0
                C41.3,134.5,41.3,134.8,41.3,135.1L41.3,135.1z M47.3,119.9L47.3,119.9L47.3,119.9c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7l0,0
                c4,0,7.2,3.2,7.2,7.2c0,4-3.2,7.2-7.2,7.2c-1,0-1.9-0.2-2.7-0.5c-0.3-0.1-0.6-0.3-0.9-0.4l0,0c-0.2-0.1-0.5-0.2-0.8-0.2
                c-1,0-1.7,0.8-1.7,1.7c0,0.3,0.1,0.5,0.2,0.7c0.2,0.3,0.4,0.6,0.7,0.8h0c0,0,0.1,0.1,0.1,0.1c0.5,0.3,1,0.5,1.6,0.7
                c1.1,0.4,2.4,0.6,3.7,0.6c5.9,0,10.7-4.8,10.7-10.7S53.2,119.9,47.3,119.9L47.3,119.9z"
								/>
								<circle id="Dot" class="st11" cx="42.5" cy="123" r="2.2" />
							</g>
						</g>
						<path
							class="st0"
							d="M111.3,217.1l-7,16.7h-4.6l-3.4-13.3c-0.2-0.8-0.4-1.1-1-1.5c-1-0.6-2.8-1.1-4.3-1.4l0.1-0.5h7.3
            c0.9,0,1.8,0.6,2,1.7l1.8,9.7l4.5-11.4L111.3,217.1L111.3,217.1z M129.2,228.4c0-4.4-6.1-4.6-6.1-6.6c0-0.6,0.6-1.2,1.8-1.4
            c0.6-0.1,2.3-0.1,4.3,0.8l0.8-3.5c-1-0.4-2.4-0.7-4-0.7c-4.3,0-7.3,2.3-7.3,5.5c0,2.4,2.2,3.7,3.8,4.6c1.7,0.8,2.2,1.3,2.2,2.1
            c0,1.1-1.3,1.6-2.6,1.6c-2.2,0-3.4-0.6-4.4-1.1l-0.8,3.7c1,0.5,2.9,0.9,4.8,0.9C126.2,234.1,129.2,231.9,129.2,228.4
            L129.2,228.4z M140.5,233.8h4l-3.5-16.7h-3.7c-0.8,0-1.5,0.5-1.8,1.2l-6.5,15.5h4.5l0.9-2.5h5.5L140.5,233.8L140.5,233.8z
             M135.6,227.9l2.3-6.3l1.3,6.3H135.6z M117.5,217.1l-3.6,16.7h-4.3l3.6-16.7H117.5z"
						/>
						<g class="st12">
							<path
								class="st0"
								d="M24.8,143.8l-0.1-0.1c-2.3-3.9-3.5-8.4-3.5-13.2c0-3.8,0.8-7.4,2.3-10.7l0,0c0.4-0.8,0.5-1.7,0.5-2.6
              c0-3.5-2.8-6.3-6.3-6.3c-1.1,0-2.2,0.3-3.2,0.9c-1.2,0.7-2.1,1.7-2.6,2.9c-1.1,2.4-2,5.1-2.5,7.7c-0.6,2.7-0.9,5.4-0.9,8.2
              c0,3.5,0.5,7,1.4,10.3c0.4,1.5,1,3.1,1.6,4.6c0.7,1.7,1.6,3.4,2.4,4.7c1,1.7,3,3.1,5.4,3.1c3.5,0,6.3-2.8,6.3-6.3
              C25.6,145.8,25.3,144.8,24.8,143.8L24.8,143.8z"
							/>
							<path
								class="st0"
								d="M47.3,91.7C47.3,91.7,47.3,91.7,47.3,91.7L47.3,91.7c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3c0,0,0,0,0,0
              c14.5,0,26.2,11.7,26.2,26.2s-11.7,26.2-26.2,26.2c-3.5,0-6.8-0.7-9.8-1.9c-1.1-0.5-2.2-1-3.2-1.6l-0.1-0.1
              c-0.9-0.5-1.9-0.8-3.1-0.8c-3.5,0-6.3,2.8-6.3,6.3c0,1,0.2,1.9,0.6,2.7c0.5,1.1,1.4,2.1,2.5,2.7l0.1,0.1
              c0.1,0.1,0.2,0.1,0.4,0.2c1.8,1,3.7,1.9,5.7,2.6c4.1,1.5,8.6,2.3,13.3,2.3c21.4,0,38.8-17.4,38.8-38.8S68.8,91.7,47.3,91.7
              L47.3,91.7z"
							/>
							<circle class="st0" cx="29.7" cy="103.3" r="8.1" />
						</g>
					</g>
				</g>
				<g>
					<defs>
						<path
							id="SVGID_00000093872333995183930670000016174388710329220274_"
							d="M-36,469.2H189c5,0,8.9-4.1,8.9-8.9V325
            c0-5-4.1-8.9-9.1-8.9H-35.7c-5,0-9.1,4.1-9.1,9.1v131.1l0,0v3.7C-44.8,465.1-40.7,469.2-36,469.2L-36,469.2z"
						/>
					</defs>
					<clipPath id="SVGID_00000072270256400004836170000013487369725226710931_">
						<use xlink:href="#SVGID_00000093872333995183930670000016174388710329220274_" style="overflow: visible" />
					</clipPath>
					<g style="clip-path: url(#SVGID_00000072270256400004836170000013487369725226710931_)">
						<g class="st2">
							<g>
								<defs>
									<rect
										id="SVGID_00000075127873388876881490000007711424564203253930_"
										x="-48.1"
										y="312.8"
										width="249.1"
										height="159.3"
									/>
								</defs>
								<clipPath id="SVGID_00000133508348845888093800000018280065335099050651_">
									<use
										xlink:href="#SVGID_00000075127873388876881490000007711424564203253930_"
										style="overflow: visible"
									/>
								</clipPath>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</div>
<div *ngIf="productCategory === 'cashback'">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 153 242.6"
		style="enable-background: new 0 0 153 242.6"
		xml:space="preserve"
	>
		<style type="text/css">
			.st0 {
				clip-path: url(#SVGID_00000080912847024063897270000006845847965640026021_);
			}

			.st1 {
				opacity: 0.89;
			}

			.st2 {
				clip-path: url(#SVGID_00000109005815268839141840000012060003142111194293_);
			}

			.st3 {
				clip-path: url(#SVGID_00000173119378351494151950000002319837560616512700_);
			}

			.st4 {
				fill: url(#SVGID_00000165224990948142683590000017594727493907028159_);
			}

			.st5 {
				fill: none;
				stroke: #b3b4b6;
				stroke-width: 0.19;
			}

			.st6 {
				fill: none;
				stroke: #a0a2a4;
				stroke-width: 0.24;
			}

			.st7 {
				fill: none;
				stroke: #b3b4b6;
				stroke-width: 0.24;
			}

			.st8 {
				fill: #ffffff;
			}

			.st9 {
				fill: #6cdb8b;
			}

			.st10 {
				opacity: 0.15;
			}

			.st11 {
				clip-path: url(#SVGID_00000090973926550444412000000002580012570187039898_);
			}
		</style>
		<g>
			<defs>
				<path
					id="SVGID_1_"
					d="M-222.7,8.9v224.9c0,5,4.1,8.9,8.9,8.9h135.3c5,0,8.9-4.1,8.9-9.1V9.1c0-5-4.1-9.1-9.1-9.1h-134.8
			C-218.7,0-222.7,4.1-222.7,8.9z"
				/>
			</defs>
			<clipPath id="SVGID_00000139287372488832418080000017676446707208833702_">
				<use xlink:href="#SVGID_1_" style="overflow: visible" />
			</clipPath>
			<g style="clip-path: url(#SVGID_00000139287372488832418080000017676446707208833702_)">
				<g class="st1">
					<g>
						<defs>
							<rect
								id="SVGID_00000084493273293018182470000008075562874715392905_"
								x="-225.6"
								y="-3.3"
								width="159.3"
								height="249.1"
							/>
						</defs>
						<clipPath id="SVGID_00000166634495870807479440000010031921423967862912_">
							<use xlink:href="#SVGID_00000084493273293018182470000008075562874715392905_" style="overflow: visible" />
						</clipPath>
					</g>
				</g>
			</g>
		</g>
		<g>
			<defs>
				<path
					id="SVGID_00000021797666631038898460000006117154088747924367_"
					d="M0,8.9v224.9c0,5,4.1,8.9,8.9,8.9h135.3
			c5,0,8.9-4.1,8.9-9.1V9.1c0-5-4.1-9.1-9.1-9.1H9.1C4.1,0,0,4.1,0,8.9z"
				/>
			</defs>
			<clipPath id="SVGID_00000148642188587166868550000014687129320743818652_">
				<use xlink:href="#SVGID_00000021797666631038898460000006117154088747924367_" style="overflow: visible" />
			</clipPath>
			<g style="clip-path: url(#SVGID_00000148642188587166868550000014687129320743818652_)">
				<rect x="-6" y="-6.3" width="168" height="256.5" />
				<g id="chip">
					<g>
						<defs>
							<path
								id="SVGID_00000183970647545510357320000014521671128671724171_"
								d="M74.6,27.2c-1.8,0-3.3,1.5-3.3,3.4v20.3
						c0,1.9,1.5,3.4,3.3,3.4h19.7c1.8,0,3.3-1.5,3.3-3.4V30.6c0-1.9-1.5-3.4-3.3-3.4C94.3,27.2,74.6,27.2,74.6,27.2z"
							/>
						</defs>
						<clipPath id="SVGID_00000054979813541603275420000014683550857814200241_">
							<use xlink:href="#SVGID_00000183970647545510357320000014521671128671724171_" style="overflow: visible" />
						</clipPath>
						<g style="clip-path: url(#SVGID_00000054979813541603275420000014683550857814200241_)">
							<linearGradient
								id="SVGID_00000058561034911137989660000013826215957052634796_"
								gradientUnits="userSpaceOnUse"
								x1="-632.3632"
								y1="191.4389"
								x2="-631.3632"
								y2="191.4389"
								gradientTransform="matrix(-18.26 31.6272 -31.6272 -18.26 -5398.6465 23520.4492)"
							>
								<stop offset="0" style="stop-color: #8f9092" />
								<stop offset="0.39" style="stop-color: #d6d8d8" />
								<stop offset="0.64" style="stop-color: #e8e9e9" />
								<stop offset="0.71" style="stop-color: #d1d2d3" />
								<stop offset="0.83" style="stop-color: #b6b7b9" />
								<stop offset="0.92" style="stop-color: #a5a7a9" />
								<stop offset="1" style="stop-color: #a0a2a4" />
							</linearGradient>
							<polygon
								style="fill: url(#SVGID_00000058561034911137989660000013826215957052634796_)"
								points="59.6,47.5 77.9,15.9 
						109.3,34 91,65.6 					"
							/>
						</g>
					</g>
				</g>
				<g id="Visa">
					<path
						class="st5"
						d="M71.3,50.9c0,1.9,1.5,3.4,3.3,3.4h19.7c1.8,0,3.3-1.5,3.3-3.4V30.6c0-1.9-1.5-3.4-3.3-3.4H74.6
				c-1.8,0-3.3,1.5-3.3,3.4C71.3,30.6,71.3,50.9,71.3,50.9z"
					/>
					<polyline class="st6" points="90.2,27.5 90.2,36.4 86.9,38.2 86.9,44.9 			" />
					<polyline class="st6" points="86.9,38.1 80.1,38.1 80.1,44.8 77.1,47.9 77.1,54 			" />
					<polyline class="st6" points="80.1,44.8 86.8,44.8 90.2,48.3 90.2,54 			" />
					<line class="st6" x1="84.2" y1="44.8" x2="84.2" y2="54.1" />
					<line class="st6" x1="83.9" y1="38" x2="83.9" y2="27.5" />
					<polyline class="st6" points="80.1,38.1 77.4,35.1 77.4,27.3 			" />
					<polyline class="st7" points="90.6,27.4 90.6,36 87.3,37.8 87.3,44.5 			" />
					<polyline class="st7" points="87.2,37.7 80.5,37.7 80.5,44.4 77.5,47.5 77.5,54 			" />
					<polyline class="st7" points="80.5,44.4 87.2,44.4 90.6,47.9 90.6,54 			" />
					<line class="st7" x1="84.6" y1="44.4" x2="84.6" y2="54" />
					<line class="st7" x1="84.3" y1="37.7" x2="84.3" y2="27.4" />
					<polyline class="st7" points="80.5,37.7 77.8,34.7 77.8,27.2 			" />
					<line class="st6" x1="79.6" y1="41.3" x2="71.7" y2="41.3" />
					<line class="st7" x1="79.7" y1="41.8" x2="71.7" y2="41.8" />
					<line class="st6" x1="97.2" y1="41.3" x2="87.8" y2="41.3" />
					<line class="st7" x1="97.2" y1="41.8" x2="87.7" y2="41.8" />
				</g>
				<g id="Logo">
					<path
						id="Wordmark"
						class="st8"
						d="M63.4,127.4v1.1c1.1-1.5,2.9-2.4,4.9-2.4c4,0,7,3.3,7,7.6s-3.1,7.6-7,7.6
				c-2,0-3.8-0.9-4.9-2.3v7.1c0,0.5-0.4,1-1,1H61c-0.5,0-1-0.4-1-1v-18.6c0-0.5,0.4-1,1-1h1.5C63,126.5,63.4,126.9,63.4,127.4
				L63.4,127.4z M67.6,129.2c-2.4,0-4.3,2-4.3,4.5s1.9,4.5,4.3,4.5s4.3-2,4.3-4.5S70.1,129.2,67.6,129.2z M92.2,133.6
				c0,4.2-3.3,7.6-7.7,7.6s-7.7-3.3-7.7-7.6s3.3-7.6,7.7-7.6S92.2,129.4,92.2,133.6z M80.3,133.6c0,2.5,1.9,4.4,4.3,4.4
				s4.3-2,4.3-4.4s-1.9-4.4-4.3-4.4S80.3,131.2,80.3,133.6z M102.2,128.9c0,0.4-0.3,0.7-0.7,0.6c-0.1,0-0.1,0-0.2,0
				c-2.5,0-4,1.9-4,4.9v5.4c0,0.5-0.4,1-1,1h-1.5c-0.5,0-1-0.4-1-1v-12.5c0-0.5,0.4-1,1-1h1.4c0.5,0,1,0.4,1,1v1.5
				c0.7-1.7,2.4-2.8,4.3-2.8l0,0c0.3,0,0.6,0.3,0.6,0.7L102.2,128.9L102.2,128.9z M109.5,123.1v3.3h2.9c0.5,0,1,0.4,1,1v1
				c0,0.5-0.4,1-1,1h-2.9v6.5c0,1.6,0.7,2.3,2.2,2.3c0.2,0,0.4,0,0.7-0.1c0.5-0.1,0.9,0.3,0.9,0.8v1.4c0,0.3-0.2,0.6-0.5,0.7
				c-0.5,0.2-1.2,0.3-2.1,0.3c-2.9,0-4.6-1.8-4.6-4.8v-7.1h-1.2c-0.5,0-1-0.4-1-1v-1c0-0.5,0.4-1,1-1h1.2v-3.3c0-0.5,0.4-1,1-1h1.5
				C109,122.2,109.5,122.6,109.5,123.1L109.5,123.1z M126.3,126.4h1.5c0.5,0,1,0.4,1,1v12.5c0,0.5-0.4,1-1,1h-1.5c-0.5,0-1-0.4-1-1
				v-0.9c-0.9,1.4-2.4,2.3-4.4,2.3c-3.4,0-5.5-2.4-5.5-6.1v-7.7c0-0.5,0.4-1,1-1h1.5c0.5,0,1,0.4,1,1v7.1c0,2.2,1.2,3.6,3.1,3.6
				s3.4-1.6,3.4-4v-6.6C125.3,126.9,125.7,126.4,126.3,126.4L126.3,126.4z M133.4,140.8h-1.5c-0.5,0-1-0.4-1-1v-12.5
				c0-0.5,0.4-1,1-1h1.5c0.5,0,1,0.4,1,1v0.9c0.9-1.3,2.4-2.3,4.4-2.3c3.5,0,5.6,2.4,5.6,6.1v7.7c0,0.5-0.4,1-1,1h-1.5
				c-0.5,0-1-0.4-1-1v-7.1c0-2.2-1.1-3.6-3.2-3.6s-3.4,1.6-3.4,4v6.6C134.3,140.4,133.9,140.8,133.4,140.8L133.4,140.8z"
					/>
					<g id="Symbol">
						<path
							id="Segments"
							class="st8"
							d="M41.3,135.1c0,1-0.8,1.7-1.7,1.7c-0.7,0-1.2-0.4-1.5-0.8c-0.2-0.4-0.5-0.8-0.7-1.3
					c-0.2-0.4-0.3-0.8-0.4-1.3c-0.2-0.9-0.4-1.9-0.4-2.8c0-0.8,0.1-1.5,0.2-2.2c0.2-0.7,0.4-1.5,0.7-2.1c0.1-0.3,0.4-0.6,0.7-0.8
					c0.3-0.2,0.6-0.2,0.9-0.2c1,0,1.7,0.8,1.7,1.7c0,0.2,0,0.5-0.2,0.7l0,0c-0.4,0.9-0.6,1.9-0.6,3c0,1.3,0.3,2.6,1,3.6l0,0
					C41.3,134.5,41.3,134.8,41.3,135.1L41.3,135.1z M47.3,119.9L47.3,119.9L47.3,119.9c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7l0,0
					c4,0,7.2,3.2,7.2,7.2c0,4-3.2,7.2-7.2,7.2c-1,0-1.9-0.2-2.7-0.5c-0.3-0.1-0.6-0.3-0.9-0.4l0,0c-0.2-0.1-0.5-0.2-0.8-0.2
					c-1,0-1.7,0.8-1.7,1.7c0,0.3,0.1,0.5,0.2,0.7c0.2,0.3,0.4,0.6,0.7,0.8h0c0,0,0.1,0.1,0.1,0.1c0.5,0.3,1,0.5,1.6,0.7
					c1.1,0.4,2.4,0.6,3.7,0.6c5.9,0,10.7-4.8,10.7-10.7S53.2,119.9,47.3,119.9L47.3,119.9z"
						/>
						<circle id="Dot" class="st9" cx="42.5" cy="123" r="2.2" />
					</g>
				</g>
				<path
					class="st8"
					d="M111.3,217.1l-7,16.7h-4.6l-3.4-13.3c-0.2-0.8-0.4-1.1-1-1.5c-1-0.6-2.8-1.1-4.3-1.4l0.1-0.5h7.3
			c0.9,0,1.8,0.6,2,1.7l1.8,9.7l4.5-11.4L111.3,217.1L111.3,217.1z M129.2,228.4c0-4.4-6.1-4.6-6.1-6.6c0-0.6,0.6-1.2,1.8-1.4
			c0.6-0.1,2.3-0.1,4.3,0.8l0.8-3.5c-1-0.4-2.4-0.7-4-0.7c-4.3,0-7.3,2.3-7.3,5.5c0,2.4,2.2,3.7,3.8,4.6c1.7,0.8,2.2,1.3,2.2,2.1
			c0,1.1-1.3,1.6-2.6,1.6c-2.2,0-3.4-0.6-4.4-1.1l-0.8,3.7c1,0.5,2.9,0.9,4.8,0.9C126.2,234.1,129.2,231.9,129.2,228.4L129.2,228.4z
			 M140.5,233.8h4l-3.5-16.7h-3.7c-0.8,0-1.5,0.5-1.8,1.2l-6.5,15.5h4.5l0.9-2.5h5.5L140.5,233.8L140.5,233.8z M135.6,227.9l2.3-6.3
			l1.3,6.3H135.6z M117.5,217.1l-3.6,16.7h-4.3l3.6-16.7H117.5z"
				/>
				<g class="st10">
					<path
						class="st8"
						d="M24.8,143.8l-0.1-0.1c-2.3-3.9-3.5-8.4-3.5-13.2c0-3.8,0.8-7.4,2.3-10.7l0,0c0.4-0.8,0.5-1.7,0.5-2.6
				c0-3.5-2.8-6.3-6.3-6.3c-1.1,0-2.2,0.3-3.2,0.9c-1.2,0.7-2.1,1.7-2.6,2.9c-1.1,2.4-2,5.1-2.5,7.7c-0.6,2.7-0.9,5.4-0.9,8.2
				c0,3.5,0.5,7,1.4,10.3c0.4,1.5,1,3.1,1.6,4.6c0.7,1.7,1.6,3.4,2.4,4.7c1,1.7,3,3.1,5.4,3.1c3.5,0,6.3-2.8,6.3-6.3
				C25.6,145.8,25.3,144.8,24.8,143.8L24.8,143.8z"
					/>
					<path
						class="st8"
						d="M47.3,91.7C47.3,91.7,47.3,91.7,47.3,91.7L47.3,91.7c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3c0,0,0,0,0,0
				c14.5,0,26.2,11.7,26.2,26.2s-11.7,26.2-26.2,26.2c-3.5,0-6.8-0.7-9.8-1.9c-1.1-0.5-2.2-1-3.2-1.6l-0.1-0.1
				c-0.9-0.5-1.9-0.8-3.1-0.8c-3.5,0-6.3,2.8-6.3,6.3c0,1,0.2,1.9,0.6,2.7c0.5,1.1,1.4,2.1,2.5,2.7l0.1,0.1c0.1,0.1,0.2,0.1,0.4,0.2
				c1.8,1,3.7,1.9,5.7,2.6c4.1,1.5,8.6,2.3,13.3,2.3c21.4,0,38.8-17.4,38.8-38.8S68.8,91.7,47.3,91.7L47.3,91.7z"
					/>
					<circle class="st8" cx="29.7" cy="103.3" r="8.1" />
				</g>
			</g>
		</g>
		<g>
			<defs>
				<path
					id="SVGID_00000033352922415642306800000006290674569149388682_"
					d="M-258.7,469.2h224.9c5,0,8.9-4.1,8.9-8.9V325
			c0-5-4.1-8.9-9.1-8.9h-224.4c-5,0-9.1,4.1-9.1,9.1v131.1l0,0v3.7C-267.6,465.1-263.5,469.2-258.7,469.2L-258.7,469.2z"
				/>
			</defs>
			<clipPath id="SVGID_00000039839281611220125910000006458239152991772064_">
				<use xlink:href="#SVGID_00000033352922415642306800000006290674569149388682_" style="overflow: visible" />
			</clipPath>
			<g style="clip-path: url(#SVGID_00000039839281611220125910000006458239152991772064_)">
				<g class="st1">
					<g>
						<defs>
							<rect
								id="SVGID_00000097473403900786631750000008762355349275330450_"
								x="-270.9"
								y="312.8"
								width="249.1"
								height="159.3"
							/>
						</defs>
						<clipPath id="SVGID_00000103980377837866794130000014104203545449092492_">
							<use xlink:href="#SVGID_00000097473403900786631750000008762355349275330450_" style="overflow: visible" />
						</clipPath>
					</g>
				</g>
			</g>
		</g>
	</svg>
</div>
