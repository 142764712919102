import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DeviceDetectorService } from 'src/app/core/services/device-detector/device-detector.service';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { FindLocationDialogComponent } from 'src/app/shared/components/dialogs/find-location-dialog/find-location-dialog.component';

@Component({
	selector: 'op-status-requires-attention',
	templateUrl: './requires-attention.component.html',
	styleUrls: ['./requires-attention.component.scss']
})
export class RequiresAttentionComponent implements OnInit {
	loanStatus = ApplicationStatusEnum;

	private _loanApp: ILoanApplication;

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
		status = this._loanApp.applicationStatus;
	}
	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	showSection: any;

	constructor(
		private dialog: MatDialog,
		private deviceDetectorService: DeviceDetectorService,
		private routingService: RoutingService
	) {}

	isMobile: boolean = false;
	ngOnInit(): void {
		this.isMobile = this.deviceDetectorService.isMobile();
	}

	showUploadDialog(): void {
		this.routingService.route(RoutingPathsEnum.documentSubmit);
	}

	showStoreDialog(): void {
		const dialogRef = this.dialog.open(FindLocationDialogComponent, {});
		dialogRef.afterClosed().pipe(take(1)).subscribe();
	}
}
