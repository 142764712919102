<div class="verify-income" *transloco="let t">
	<div class="op-right op-mb-8" *ngIf="data?.isDialog">
		<button mat-icon-button id="messageDialogCloseBtn" [mat-dialog-close]="false">
			<mat-icon svgIcon="op-close"></mat-icon>
		</button>
	</div>
	<op-masthead [title]="t(data?.isDialog ? 'VERIFY_INCOME.selectIncome' : 'VERIFY_INCOME.initial')"> </op-masthead>
	<op-content-container class="content">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div>
				<strong>{{ t('VERIFY_INCOME.details') }}</strong>
				<button mat-icon-button type="button" (click)="openInfoDialog()">
					<mat-icon svgIcon="op-info"></mat-icon>
				</button>
			</div>
			<div class="op-mt-30">
				{{ t('VERIFY_INCOME.detailsDisclaimer') }}
			</div>
			<mat-radio-group class="op-toggle-group1" name="income-selection" formControlName="incomeSelection">
				<mat-card class="op-mt-30">
					<div>
						<mat-radio-button class="op-mb-20" [value]="VerificationStatusEnum.verified">
							<strong>{{ t('VERIFY_INCOME.useAutoIncome') }}</strong>
						</mat-radio-button>
						<hr />
						<p>
							{{ t('VERIFY_INCOME.weVerified') }}
							{{ verifiedMonthlyIncomeAmount | currency }} {{ t('VERIFY_INCOME.perMonth') }}
						</p>
					</div>
					<div>{{ t('VERIFY_INCOME.noProofRequired') }}</div>
				</mat-card>
				<mat-divider></mat-divider>
				<mat-card class="op-mt-30">
					<div>
						<mat-radio-button class="op-mb-20" [value]="VerificationStatusEnum.stated">
							<strong>{{ t('VERIFY_INCOME.useStatedIncome') }} </strong>
						</mat-radio-button>
						<hr />
						<p>
							{{ t('VERIFY_INCOME.youStated') }}
							<a
								class="link-button op-body-1-font"
								href=""
								onclick="return false"
								id="clickForDetails"
								(click)="showMore ? (showMore = false) : (showMore = true)"
							>
								{{ t('VERIFY_INCOME.clickForDetails') }}
							</a>
							{{ statedMonthlyIncomeAmount | currency }} {{ t('VERIFY_INCOME.perMonth') }}
						</p>
					</div>
					<div>{{ t('VERIFY_INCOME.proofRequired') }}</div>
					<div class="op-pad-20" *ngIf="showMore">
						<mat-divider></mat-divider>
					</div>
					<div *ngFor="let field of sectionFields; let j = index" [hidden]="!showMore">
						<div *ngIf="field.value" class="op-full-width">
							<div class="fieldTitle op-label-font op-gray" *ngIf="field.titleValue">{{ field.title }}</div>
							<div class="fieldTitle op-label-font op-gray" *ngIf="!field.titleValue">{{ t(field.title) }}</div>
							<div class="fieldValue op-mb-10" id="{{ 'field_' + j }}">{{ field.value }}</div>
						</div>
					</div>
				</mat-card>
			</mat-radio-group>
			<div class="op-center" mat-dialog-actions *ngIf="data?.isDialog">
				<button
					class="confirm op-button"
					mat-flat-button
					color="primary-small"
					id="messageDlgSubmitBtn"
					(click)="onSubmit()"
				>
					{{ data.confirmText ? data.confirmText : ('GLOBAL.ok' | transloco) }}
				</button>
				<button
					*ngIf="data.cancelText"
					class="confirm op-button op-button-cancel"
					mat-flat-button
					color="secondary-small"
					id="messageDlgCloseBtn"
					(click)="close()"
				>
					{{ data.cancelText ? data.cancelText : ('GLOBAL.ok' | transloco) }}
				</button>
			</div>

			<div class="left" *ngIf="!data?.isDialog">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					class="op-button"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
