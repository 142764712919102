export enum StatesEnum {
	'AL' = 'AL',
	'AK' = 'AK',
	'AS' = 'AS',
	'AZ' = 'AZ',
	'AR' = 'AR',
	'CA' = 'CA',
	'CO' = 'CO',
	'CT' = 'CT',
	'DE' = 'DE',
	'DC' = 'DC',
	'FM' = 'FM',
	'FL' = 'FL',
	'GA' = 'GA',
	'GU' = 'GU',
	'HI' = 'HI',
	'ID' = 'ID',
	'IL' = 'IL',
	'IN' = 'IN',
	'IA' = 'IA',
	'KS' = 'KS',
	'KY' = 'KY',
	'LA' = 'LA',
	'ME' = 'ME',
	'MH' = 'MH',
	'MD' = 'MD',
	'MA' = 'MA',
	'MI' = 'MI',
	'MN' = 'MN',
	'MS' = 'MS',
	'MO' = 'MO',
	'MT' = 'MT',
	'NE' = 'NE',
	'NV' = 'NV',
	'NH' = 'NH',
	'NJ' = 'NJ',
	'NM' = 'NM',
	'NY' = 'NY',
	'NC' = 'NC',
	'ND' = 'ND',
	'MP' = 'MP',
	'OH' = 'OH',
	'OK' = 'OK',
	'OR' = 'OR',
	'PW' = 'PW',
	'PA' = 'PA',
	'PR' = 'PR',
	'RI' = 'RI',
	'SC' = 'SC',
	'SD' = 'SD',
	'TN' = 'TN',
	'TX' = 'TX',
	'UT' = 'UT',
	'VT' = 'VT',
	'VI' = 'VI',
	'VA' = 'VA',
	'WA' = 'WA',
	'WV' = 'WV',
	'WI' = 'WI',
	'WY' = 'WY'
}

export class StateUtils {
	static isCalifornia(state: StatesEnum): boolean {
		return state === StatesEnum.CA;
	}

	static isWisconsin(state: StatesEnum): boolean {
		return state === StatesEnum.WI;
	}

	static isOhio(state: StatesEnum): boolean {
		return state === StatesEnum.OH;
	}

	static isTexas(state: StatesEnum): boolean {
		return state === StatesEnum.TX;
	}

	static isNewJersey(state: StatesEnum): boolean {
		return state === StatesEnum.NJ;
	}
}
