<div class="payment-frequency op-mt-20">
	<div [formGroup]="formGroup">
		<div class="op-mt-20 op-left op-body-1-bold-font">{{ 'ADJUST_TERMS.choosePaymentFreq' | transloco }}</div>
		<p></p>
		<div class="op-toggle-group" aria-labelledby="paymentFrequency">
			<mat-radio-group
				class="op-toggle-group1"
				[value]="this.formGroup?.get('paymentFrequency')?.value"
				formControlName="paymentFrequency"
			>
				<mat-radio-button
					class="frequenct-radio-button"
					*ngFor="let item of payFreqOptions | opMetadataFilter: viewablePayFreqKeys"
					id="{{ id + '_paymentFrequency_' + item.key }}"
					[value]="item.key"
				>
					<span class="wrap-long-text">{{ item.text | transloco }}</span>
				</mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
</div>
