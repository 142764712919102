<div class="reference-add" [formGroup]="parent" *transloco="let t">
	<mat-expansion-panel
		id="{{ id + '_expansionPanel' }}"
		[expanded]="parent.get('expanded').value"
		(closed)="panelClosed(parent)"
		(opened)="panelOpened(parent)"
		hideToggle
	>
		<mat-expansion-panel-header #panelH (click)="panelH._toggle()">
			<mat-panel-title class="op-header-3-font" id="{{ id + '_expansionPanelTitle' }}">
				{{ t('REFERENCES.reference', { count: index }) }}
			</mat-panel-title>
			<mat-panel-description>
				<a
					mat-flat-button
					color="link-md"
					id="{{ id + '_cancelEditLink' }}"
					*ngIf="parent.get('id').value || !parent.get('expanded').value"
					(click)="panelH._toggle(); restoreOriginalValue(!parent.get('expanded').value)"
				>
					{{ parent.get('expanded').value ? t('GLOBAL.cancel') : t('GLOBAL.edit') }}
				</a>
				<a mat-flat-button color="link-md" id="{{ id + '_removeLink' }}" (click)="deleteReference(parent, index - 1)">
					{{ t('REFERENCES.remove') }}
				</a>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<p class="op-body-1-bold-font">{{ t('REFERENCES.referenceInformation') }}</p>

		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('REFERENCES.relationshipType') }}</mat-label>
			<mat-select name="relationship" id="{{ id + '_relationship' }}" formControlName="relationship">
				<mat-option
					*ngFor="let relationshipType of relationshipTypeList; let i = index"
					id="{{ id + '_relationship_' + relationshipType.key }}"
					[value]="relationshipType.key"
				>
					{{ relationshipType.text }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('REFERENCES.firstName') }}</mat-label>
			<input matInput type="text" id="{{ id + '_firstName' }}" name="firstName" formControlName="firstName" />
			<mat-error
				id="{{ id + '_firstNameError' }}"
				*ngIf="!parent.controls['firstName'].valid && parent.controls['firstName'].touched"
			>
				{{ t('REFERENCES.firstNameError') }}
			</mat-error>
		</mat-form-field>

		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('REFERENCES.middleName') }}</mat-label>
			<input matInput type="text" name="middleName" id="{{ id + '_middleName' }}" formControlName="middleName" />
			<mat-error
				id="{{ id + '_middleNameError' }}"
				*ngIf="!parent.controls['middleName'].valid && parent.controls['middleName'].touched"
			>
				{{ t('REFERENCES.middleNameError') }}
			</mat-error>
		</mat-form-field>

		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('REFERENCES.lastName') }}</mat-label>
			<input matInput type="text" name="lastName" id="{{ id + '_lastName' }}" formControlName="lastName" />
			<mat-error
				id="{{ id + '_lastNameError' }}"
				*ngIf="!parent.controls['lastName'].valid && parent.controls['lastName'].touched"
			>
				{{ t('REFERENCES.lastNameError') }}
			</mat-error>
		</mat-form-field>

		<div class="combined">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('REFERENCES.maternalName') }}</mat-label>
				<input
					matInput
					type="text"
					name="maternalName"
					id="{{ id + '_maternalName' }}"
					formControlName="maternalName"
				/>
				<mat-error
					id="{{ id + '_maternalNameError' }}"
					*ngIf="!parent.controls['maternalName'].valid && parent.controls['maternalName'].touched"
				>
					{{ t('REFERENCES.maternalNameError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('REFERENCES.suffix') }}</mat-label>
				<mat-select name="suffix" id="{{ id + '_suffix' }}" formControlName="suffix">
					<mat-option
						*ngFor="let suffix of suffixList; let i = index"
						id="{{ id + '_suffix_' + suffix.key }}"
						[value]="suffix.key"
					>
						{{ suffix.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('REFERENCES.phoneNumber') }}</mat-label>
			<input
				matInput
				type="text"
				inputmode="numeric"
				name="phoneNumber"
				id="{{ id + '_phoneNumber' }}"
				mask="(000) 000-0000"
				formControlName="phoneNumber"
				[dropSpecialCharacters]="false"
			/>
		</mat-form-field>

		<div class="op-form-group">
			<div class="op-form-title">{{ t('REFERENCES.preferredLanguage') | opRequired }}</div>
			<mat-radio-group
				[value]="selectedValue"
				name="preferredLanguage"
				id="{{ id + '_preferredLanguaage' }}"
				formControlName="preferredLanguage"
			>
				<mat-radio-button *ngFor="let item of languageList" id="{{ id + '_language_' + item.key }}" [value]="item.key">
					{{ item.text }}
				</mat-radio-button>
			</mat-radio-group>
		</div>
	</mat-expansion-panel>
	<div *ngIf="!parent.get('expanded').value" class="op-body-1-font">
		<div>
			{{ t('REFERENCES.name') }}<br />
			{{ parent.get('firstName').value }} {{ parent.get('lastName').value }}
		</div>
		<p></p>
		<div>
			{{ t('REFERENCES.relationship') }}<br />
			{{ parent.get('relationship').value | opGetMetadataText: relationshipTypeList }}
		</div>
		<p></p>
		<div>
			{{ t('REFERENCES.phoneNumber2') }}<br />
			{{ parent.get('phoneNumber').value }}
		</div>
		<p></p>
	</div>
</div>
