<div class="select-bank-account" *transloco="let t">
	<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<div class="op-mtb-8">
			<mat-radio-group
				labelPosition="before"
				name="accounts"
				id="{{ id + '_selected' }}"
				formControlName="selectedId"
				class="op-mat-radio-group"
			>
				<div *ngFor="let bankAccount of groupedAccountList | keyvalue" class="bank-account-group">
					<div>{{ bankAccount.key }}</div>
					<mat-radio-button
						class="op-mtb-10 op-body-1-font"
						*ngFor="let account of bankAccount.value"
						[value]="account?.id"
						[checked]="account?.selected"
						[opTagClick]="'auto pay select bank ' + account?.id"
						(change)="radioChange($event)"
					>
						<div class="op-body-1-font op-dark-grey">
							{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.TYPE.' + account.accountType) }}
							{{ account.accountNumberLastFour || account.accountNumber | opLastNNumber: '.':3 }}
						</div>
					</mat-radio-button>
				</div>
			</mat-radio-group>
		</div>
	</form>
</div>
