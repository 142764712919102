import { Component, Input } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { AnimationOptions } from 'ngx-lottie';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
	selector: 'op-document-review',
	templateUrl: './document-review.component.html',
	styleUrls: ['./document-review.component.scss']
})
export class DocumentReviewComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	progress: number;

	@Input()
	duration: number;

	options: AnimationOptions = {
		path: '/assets/lottie/product-review-en-1.json'
	};

	constructor(private languageService: LanguageService) {
		this.languageService.langChanges$.subscribe((lang) => {
			const index = Math.floor(Math.random() * 100) % 2 ? 1 : 2;
			this.options = {
				...this.options,
				path: `/assets/lottie/product-review-${lang}-${index}.json`
			};
		});
	}
}
