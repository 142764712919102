import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { endOfMonth, isAfter } from 'date-fns';
import { Subscription } from 'rxjs';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IGetIncomeResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { moneyValidator } from 'src/app/shared/validators/form-validators';

import { companyNameValidator } from '../../shared/validators/form-validators';

@Component({
	selector: 'op-income',
	templateUrl: './income.component.html',
	styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private metadataService: MetadataService,
		private dateUtilService: DateUtilsService,
		private routingService: RoutingService,
		private tagDataService: TagDataService
	) {
		this.createForm(formBuilder);
	}

	formGroup: FormGroup;

	ready = false;
	incomeSourceTypeList: IMetadata[];
	industryTypeList: IMetadata[];
	incomeFrequencyList: IMetadata[];

	initialTakeHomePay: string;

	newSourceText: string;

	returnToSummary: string;

	private subscription = new Subscription();

	editing: boolean;

	incomeMatch: boolean = false;

	returnToFTR: string;

	ngOnInit(): void {
		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;

		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;

		const newSource = this.translocoService.selectTranslate('INCOME.newSource').subscribe({
			next: (value) => (this.newSourceText = value)
		});
		this.subscription.add(newSource);

		const industryTypeSub = this.metadataService.select(MetadataEnum.IndustryType).subscribe({
			next: (rsp) => {
				this.industryTypeList = rsp;
			}
		});
		this.subscription.add(industryTypeSub);

		this.getIncomes();

		const incomeTypeSub = this.metadataService.select(MetadataEnum.IncomeSourceType).subscribe({
			next: (rsp) => {
				this.incomeSourceTypeList = rsp;
				this.tagDataService.view(
					{
						income_source: this.incomeList.value
					},
					{
						tealium_event: 'income'
					}
				);
			}
		});
		this.subscription.add(incomeTypeSub);

		const reqSub = this.metadataService.select(MetadataEnum.IncomeFrequency).subscribe({
			next: (rsp) => {
				this.incomeFrequencyList = rsp;
			}
		});
		this.subscription.add(reqSub);

		this.formGroup.valueChanges.subscribe({
			next: (rsp) => {
				this.editing = rsp.incomes.some((income) => income.expanded);
				this.incomeMatch = this.checkIncomeMatch(rsp.incomes);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	checkIncomeMatch(incomes): boolean {
		// TODO: properly calculate.  Need to take the rate of pay into account.
		return incomes.reduce((acc, curr) => acc + Number(curr.amount), 0) === this.initialTakeHomePay;
	}
	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			incomes: new FormArray([])
		});
	}

	private getIncomes(): void {
		const income$ = this.mobileService.getIncome(this.loanAppService.loanApplicationId);
		income$.subscribe({
			next: (income) => {
				this.initialTakeHomePay = this.loanAppService.getCurrentApplicant().initialTakeHomePay;
				this.ready = true;
				if (income.length) {
					income.forEach((item) => this.increaseIncome(item));
					this.incomeMatch = this.checkIncomeMatch(income);
				} else {
					this.increaseIncome({}, true);
				}
			}
		});
	}

	get formControls(): any {
		return this.formGroup.controls;
	}
	get incomeList(): FormArray {
		return this.formControls.incomes as FormArray;
	}

	/**
	 * Returns the month and year an income started in a human readable format.
	 * Used in the header of each income in the accordion.
	 */
	startedDate(income: AbstractControl): string {
		if (income.value.startDate) {
			return this.dateUtilService.format(income.value.startDate, 'MMM yyyy');
		} else {
			return '';
		}
	}

	createIncome(income: Partial<IGetIncomeResult>, expanded = false): FormGroup {
		return this.formBuilder.group({
			incomeSourceType: [income.incomeSourceType, Validators.required],
			incomeDescription: [income.incomeDescription],
			company: [income.company, [companyNameValidator()]],
			industryType: [income.industryType],
			paymentFrequency: [income.paymentFrequency, Validators.required],
			amount: [income.amount, [Validators.required, moneyValidator()]],
			startDate: [
				income.startDate ? this.dateUtilService.parseISODate(income.startDate) : null,
				[Validators.required, this.startDateValidation()]
			],
			id: [income.id],
			expanded: [expanded]
		});
	}

	private startDateValidation(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const afterToday = isAfter(control.value, endOfMonth(new Date()));
			return afterToday ? { futureDate: true } : null;
		};
	}

	increaseIncome(income: Partial<IGetIncomeResult>, expanded = false): void {
		this.incomeList.push(this.createIncome(income, expanded));
	}

	insertIncome(income: Partial<IGetIncomeResult>, expanded = false): void {
		this.incomeList.insert(0, this.createIncome(income, expanded));
	}

	addIncome(): void {
		this.increaseIncome({}, true);
	}

	hasIncomes(): boolean {
		return this.incomeList.controls.some((income) => Boolean(income.value.id));
	}

	panelOpened(income: AbstractControl): void {
		income.get('expanded').setValue(true);
	}

	panelClosed(income: AbstractControl): void {
		income.get('expanded').setValue(false);
	}

	getExpandedIncome(): AbstractControl {
		return this.incomeList.controls.find((income) => income.value.expanded);
	}

	saveIncome(/*income: AbstractControl*/): void {
		const income = this.getExpandedIncome();
		const incomeValue = income.value;
		const JOB = 1700;

		const data = {
			incomeSourceType: incomeValue.incomeSourceType,
			incomeDescription: incomeValue.incomeDescription,
			company: incomeValue.incomeSourceType === JOB ? incomeValue.company : null,
			industryType: incomeValue.industryType,
			paymentFrequency: incomeValue.paymentFrequency,
			amount: incomeValue.amount,
			startDate: this.dateUtilService.format(incomeValue.startDate, 'yyyy-MM-dd'),
			hasIncomeDocuments: false,
			id: incomeValue.id
		};

		if (incomeValue.id) {
			this.mobileService.updateIncome(data, this.loanAppService.loanApplicationId).subscribe({
				next: (resp) => {
					income.get('id').setValue(resp.id);
					income.get('expanded').setValue(false);
				}
			});
		} else {
			this.mobileService.setIncome(data, this.loanAppService.loanApplicationId).subscribe({
				next: (resp) => {
					income.get('id').setValue(resp.id);
					income.get('expanded').setValue(false);
				}
			});
		}
	}

	deleteIncome(event): void {
		const { income, index } = event;
		const incomeValue = income.value;

		if (incomeValue.id) {
			this.mobileService.deleteIncome(incomeValue.id, this.loanAppService.loanApplicationId).subscribe();
		}

		if (income.parent.length === 1) {
			this.incomeList.clear();
			this.increaseIncome({}, true);
		} else {
			income.parent.removeAt(index);
		}
	}

	navigate(route: RoutingPathsEnum): void {
		if (this.returnToFTR) {
			this.routingService.routeForFastTrack(this.returnToFTR);
		} else if (this.returnToSummary) {
			this.routingService.route(RoutingPathsEnum.summary, { fragment: this.returnToSummary });
		} else {
			this.routingService.route(route);
		}
	}

	onSubmit(post: any): void {
		this.tagDataService.link(
			{
				income_source: this.incomeList.value
			},
			{
				tealium_event: 'income_process_complete',
				event_action: 'submit',
				event_label: 'Form',
				event_category: 'rx_form'
			}
		);
		this.navigate(RoutingPathsEnum.summary);
	}
}
