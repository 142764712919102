import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-funds-check',
	templateUrl: './fundsCheck.component.html',
	styleUrls: ['./fundsCheck.component.scss']
})
export class FundsCheckComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	checkAtStore: boolean = false;

	@Input()
	issuingOrganization: string = '';

	@Output() next = new EventEmitter<boolean>();

	formGroup: FormGroup;

	constructor(formBuilder: FormBuilder) {
		this.createForm(formBuilder);
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			check: [null, [Validators.required]]
		});
	}

	onSubmit(values: any): void {
		this.next.emit(values.check);
	}

	public reset(): void {
		this.formGroup.reset();
	}
}
