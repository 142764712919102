import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IMessageDialogData {
	icon?: string;
	title: string;
	image?: string;
	message: string;
	confirmText?: string;
	cancelText?: string;
	closeAction?: boolean;
	panelClass?: string;
	displayBtnAsFlex?: boolean;
	titleBody1?: boolean;
}

/**
 * basic message dialog takes in icon, title and message.
 *
 * @export
 * @class MessageDialogComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-message-dialog',
	templateUrl: './message-dialog.component.html',
	styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<MessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IMessageDialogData
	) {}

	ngOnInit(): void {
		this.data.closeAction = this.data.closeAction ? this.data.closeAction : undefined;
	}

	close(): void {
		this.dialogRef.close(false);
	}

	onSubmit(): void {
		this.dialogRef.close(true);
	}
}
