import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
	selector: 'op-bank-connect',
	template: `<div class="bank-connect" *transloco="let t">
		<op-masthead [title]="t('BANK_CONNECT.title')"></op-masthead>
		<op-content-container class="content">
			<div>{{ t('BANK_CONNECT.description') }}</div>
			<div class="op-mt-40 align-phone">
				<h4 plaid-title class="op-body-1-font op-left">
					{{ t('BANK_CONNECT.customerSupport') }}
				</h4>
				<a
					mat-flat-button
					color="link-bold"
					href="tel:{{ t('BANK_CONNECT.customerSupportNumber') }}"
					id="callCustomerSupportLink"
				>
					<span *ngIf="isLanguageEn">{{ t('BANK_CONNECT.call') }}&nbsp;</span>
					<span>{{ t('BANK_CONNECT.customerSupportNumber') }}</span>
				</a>
			</div>
		</op-content-container>
	</div>`,
	styles: []
})
export class BankConnectComponent {
	isLanguageEn: boolean;
	constructor(private translocoService: TranslocoService) {}

	subscription: Subscription;

	ngOnInit(): void {
		this.subscription = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.isLanguageEn = lang === 'en';
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
