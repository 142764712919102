<div class="add-bank" *transloco="let t">
	<button
		id="addBankManuallyBtn"
		mat-flat-button
		color="secondary"
		*ngIf="!showAccountList && !hideAddBank"
		(click)="addBank = !addBank"
	>
		{{ t('MANUAL_ADD_BANK.routingAndAccount') }}
	</button>

	<div [hidden]="!addBank">
		<op-manually-add-bank id="bankManuallyAddBank" (saved)="addBank = !addBank; selectBank = true">
		</op-manually-add-bank>
	</div>

	<div [hidden]="!showAccountList" class="op-mt-20">
		<op-select-bank-account
			[filter]="filter"
			id="bankAddBankSelectAccount"
			[selectAndContinue]="selectAndContinue"
			(checkVerificationStatus)="onBankSelected($event)"
			*ngIf="selectBank"
		>
		</op-select-bank-account>
		<div class="left" *ngIf="!hideAddBank">
			<p>{{ t('MANUAL_ADD_BANK.lookingForAccount') }}</p>
			<button
				mat-flat-button
				color="link-text-b1"
				id="addBankBtn"
				name="addBtn"
				type="button"
				(click)="addBank = !addBank"
			>
				<mat-icon aria-hidden="true" svgIcon="op-add"></mat-icon>
				<span *ngIf="!addBank">{{ t('MANUAL_ADD_BANK.addBank') }}</span>
				<span *ngIf="addBank">{{ t('MANUAL_ADD_BANK.cancelAddBank') }}</span>
			</button>
		</div>
	</div>
</div>
