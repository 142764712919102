import { Component, EventEmitter, Input, Output } from '@angular/core';
import IProduct from 'src/app/core/services/loan-application/product-offer/product/product.model';

@Component({
	selector: 'op-secured-personal-loan-eligible',
	templateUrl: './secured-personal-loan-eligible.component.html',
	styleUrls: ['./secured-personal-loan-eligible.component.scss']
})
export class SecuredPersonalLoanEligibleComponent {
	@Input() product: IProduct;
	@Input() showBankConnectedMessage: boolean = false;
	@Input() loanApplicationId: number;
	@Input() isSplOnlyLoan: boolean;

	@Output()
	continue = new EventEmitter<any>();

	@Output()
	notSureAboutOffer = new EventEmitter<any>();

	onContinue(method: string): void {
		this.continue.emit({ method, productCategory: this.product.productCategory });
	}

	goToOfferStatusInformation(): void {
		this.notSureAboutOffer.emit();
	}
}
