<div class="idle-timeout-dialog" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button mat-icon-button id="idleTimeoutCloseBtn" [mat-dialog-close]="false" aria-label="{{ t('GLOBAL.close') }}">
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-1-font">
			<strong>{{ 'IDLE_TIMEOUT.modalTitle' | transloco }}</strong>
		</div>
	</div>
	<div mat-dialog-content>
		<p innerHTML="{{ 'IDLE_TIMEOUT.modalMessage' | transloco }}"></p>
	</div>
	<div class="footer" mat-dialog-actions>
		<button class="confirm" mat-flat-button color="primary-small" id="idleTimeoutContinueBtn" (click)="onSubmit()">
			{{ 'IDLE_TIMEOUT.modalContinue' | transloco }}
		</button>
		<button class="confirm" mat-flat-button color="secondary-small" id="idleTimeoutLogoutBtn" (click)="close()">
			{{ 'IDLE_TIMEOUT.modalLogout' | transloco }}
		</button>
	</div>
</div>
