import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
import { from, fromEvent } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, timeout } from 'rxjs/operators';

import { ThumbnailSizeEnum } from '../file-upload.service';

export const readFile = (file: File): Observable<any> => {
	const reader = new FileReader();
	reader.readAsDataURL(file); // transform to base64 data url
	return fromEvent(reader, 'load').pipe(
		map((event: any) => {
			const target: FileReader = event.target;
			return target.result;
		})
	);
};

/**
 * render the pdf
 *
 * @param {*} fileData
 * @return {*}  {Observable<any>}
 */
export const renderDocument = (fileData: any): Observable<any> => {
	const pdfWorkerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
	GlobalWorkerOptions.workerSrc = pdfWorkerSrc;

	const canvas = document.createElement('canvas');
	const maxSize = 100;
	let scale;
	return from(getDocument(fileData).promise).pipe(
		switchMap((pdf) => from(pdf.getPage(1))),
		switchMap((page) => {
			const vp = page.getViewport({ scale: 1 });
			scale = Math.min(maxSize / vp.width, maxSize / vp.height);
			canvas.height = maxSize;
			canvas.width = vp.width * scale;
			return from(
				page.render({
					canvasContext: canvas.getContext('2d'),
					viewport: page.getViewport({ scale: scale })
				}).promise
			);
		}),
		map(() => canvas.toDataURL())
	);
};

const createCanvas = (width: string, height: string, type: ThumbnailSizeEnum): HTMLCanvasElement => {
	const canvas = document.createElement('canvas');
	const defaultSize = type === ThumbnailSizeEnum.small ? '100' : '256';
	canvas.setAttribute('width', width || defaultSize);
	canvas.setAttribute('height', height || defaultSize);
	return canvas;
};

/**
 * render and image file to a thumbnail
 *
 * @param {*} fileData
 * @return {*}  {Observable<any>}
 */
export const renderImage = (fileData: any, type: ThumbnailSizeEnum = ThumbnailSizeEnum.small): Observable<any> => {
	const image = new Image();
	image.src = fileData;
	const canvasSize = type === ThumbnailSizeEnum.small ? 100 : 256;

	return fromEvent(image, 'load').pipe(
		timeout(2000),
		map((event: any) => {
			var canvas = createCanvas(String(canvasSize * (image.width / image.height)), String(canvasSize), type);
			canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
			return canvas.toDataURL();
		})
	);
};
