import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IStoreEntity } from 'src/app/core/services/mobile-api/mobile-api.model';

import { LoanApplicationService } from '../../../../core/services/loan-application/loan-application.service';
import { MobileApiService } from '../../../../core/services/mobile-api/mobile-api.service';
import { ConfigApiService } from 'src/app/core/services/mobile-api/config-api/config-api.service';
import { IConfigResult } from 'src/app/core/services/mobile-api';
import { take } from 'rxjs/operators';

/**
 * Display store lookup and results in a dialog
 *
 * @export
 * @class FindLocationDialogComponent
 */
@Component({
	templateUrl: './find-location-dialog.component.html',
	styleUrls: ['./find-location-dialog.component.scss']
})
export class FindLocationDialogComponent implements OnInit {
	constructor(
		private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<FindLocationDialogComponent>,
		private mobileService: MobileApiService,
		private loanApplicationService: LoanApplicationService,
		private configApiService: ConfigApiService
	) {
		this.createForm(this.formBuilder);
	}
	formGroup: FormGroup;
	stores: IStoreEntity[];
	selectedStore: IStoreEntity;
	storeless: boolean;
	noneFound: boolean;
	googleMapsAPIKey: string;

	ngOnInit(): void {
		this.configApiService
			.configGoogleMapsAPIKey()
			.pipe(take(1))
			.subscribe({
				next: (config: IConfigResult) => {
					this.googleMapsAPIKey = config.key;
				}
			});
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			zipCode: [
				null,
				[Validators.required, Validators.minLength(5), Validators.maxLength(10), Validators.pattern('[0-9]*')]
			]
		});
	}

	onSubmit(event): void {
		this.noneFound = this.storeless = false;
		this.mobileService.getClosesStore(event.zipCode, 10, this.loanApplicationService.loanApplicationId).subscribe({
			next: (rsp) => {
				this.storeless = rsp.storeless;
				this.stores = rsp.stores;
				if (!rsp.storeless) {
					if (!Boolean(rsp.stores?.length)) {
						this.noneFound = true;
					} else {
						this.noneFound = false;
					}
				}
			}
		});
	}

	onBack(): void {
		this.selectedStore = null;
	}

	onStoreSelected(store: IStoreEntity): void {
		this.selectedStore = store;
	}
}
