<div class="address op-header-commons-pro" *transloco="let t">
	<op-masthead [title]="t('PRE_QUALIFY.ADDRESS.title')"> </op-masthead>
	<op-content-container class="content">
		<form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
			<section class="primary-address op-mt-30">
				<div class="label">
					{{ t('PRE_QUALIFY.ADDRESS.primaryAddress') }}
				</div>

				<div *ngIf="addressForm?.controls['primaryAddress'].value" class="op-mt-10" id="primary-address">
					{{ getFormattedAddress(addressForm?.controls['primaryAddress'].value) | titlecase }}
				</div>
			</section>
			<hr class="seperator op-mt-30" />

			<section class="date-of-move op-mt-30">
				<header>{{ t('PRE_QUALIFY.ADDRESS.moveDate') }}</header>
				<div class="op-mt-20">
					<op-date-input
						class="equal-width"
						name="moveDate"
						id="moveDate"
						showFormat="mmyyyy"
						formControlName="firstMoveDate"
						hideRequiredMarker="true"
					></op-date-input>

					<mat-error
						class="op-label-font op-ml-5"
						id="birthDayValidation"
						*ngIf="
							addressForm.controls['firstMoveDate'].errors?.priorToBirthDate &&
							addressForm.controls['firstMoveDate'].touched
						"
					>
						{{ t('ADDRESS.birthDateValidation') }}
					</mat-error>
					<mat-error
						class="op-label-font op-ml-5"
						id="futureDate"
						*ngIf="addressForm.controls['firstMoveDate'].errors?.futureDate"
					>
						{{ t('ADDRESS.invalidDate') }}
					</mat-error>
				</div>
			</section>

			<section class="mailling-address op-mt-30">
				<header>{{ t('PRE_QUALIFY.ADDRESS.maillingAddressSameAsPrimary') }}</header>

				<mat-radio-group
					class="op-toggle-group"
					name="same-as-primary-selection"
					id="same-as-primary-selection"
					formControlName="sameMailingAddressAsPrimary"
				>
					<div class="border op-mt-20" *ngFor="let item of samePrimaryAddressOptions; let first = first">
						<div>
							<mat-radio-button
								(click)="onChangesameMailingAddressAsPrimary(item.key)"
								labelPosition="before"
								id="{{ 'sameAsPrimary_' + item.key }}"
								class="op-mb-20"
								[value]="item.key"
							>
								{{ t(item.text) }}
							</mat-radio-button>
						</div>
						<hr class="seperator" *ngIf="first" />
					</div>
				</mat-radio-group>
			</section>

			<section
				class="mailling-address op-mt-30"
				*ngIf="
					!addressForm?.controls['sameMailingAddressAsPrimary'].value && addressForm?.controls['mailingAddress'].value
				"
			>
				<header>{{ t('PRE_QUALIFY.ADDRESS.mailingAddress') }}</header>

				<div class="mailling-address-content">
					<div class="border op-mt-20">
						<span id="mailling-address">
							{{ getFormattedAddress(addressForm?.controls['mailingAddress'].value) }}
						</span>
					</div>
					<a class="mailling-address-edit op-header-2-font" (click)="openAddAddressModal()" aria-label="Edit">
						{{ t('GLOBAL.edit') }}
					</a>
				</div>
			</section>

			<section class="ssl-section op-mt-30">
				<mat-icon class="ssl-icon" svgIcon="op-protection"></mat-icon>
				<div class="ssl-info op-ml-10">{{ t('PRE_QUALIFY.ssl') }}</div>
			</section>

			<div>
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="addressForm.invalid"
					type="submit"
				>
					{{ t('PRE_QUALIFY.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
