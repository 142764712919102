<div class="esign-event-signing-completed" *transloco="let t">
	<op-masthead [title]="t('ESIGN.EVENT.SIGNING_COMPLETED.title')"></op-masthead>
	<op-content-container *ngIf="initialized" class="content">
		<section *ngIf="!paymentRemindersSaved" class="payment-reminders-receipts">
			<form class="op-form-group" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
				<div *ngIf="paymentRemindersOptions?.paymentReminders">
					<div class="op-form-title">{{ t('PAYMENT_REMINDERS.paymentReminders') }}</div>
					<mat-radio-group name="payment-reminders" formControlName="paymentReminders">
						<div *ngFor="let paymentReminders of paymentRemindersOptions?.paymentReminders">
							<mat-radio-button
								class="radio-button"
								id="{{ 'reminder_' + paymentReminders }}"
								*ngIf="paymentReminders !== 'PAYMENT_REMINDERS_NONE' && paymentReminders !== 'RACH_REMINDERS_NONE'"
								[value]="paymentReminders"
							>
								<div
									id="completeReminderSms"
									*ngIf="paymentReminders === 'PAYMENT_REMINDERS_SMS' || paymentReminders === 'RACH_REMINDERS_SMS'"
								>
									{{ t('PAYMENT_REMINDERS.optionSMS') }}
								</div>

								<div
									id="completeReminderPhone"
									*ngIf="
										paymentReminders === 'PAYMENT_REMINDERS_PHONE_CALL' ||
										paymentReminders === 'RACH_REMINDERS_PHONE_CALL'
									"
								>
									{{ t('PAYMENT_REMINDERS.optionPhone') }}
								</div>
							</mat-radio-button>
						</div>
					</mat-radio-group>
				</div>
				<div *ngIf="paymentRemindersOptions?.receipts">
					<div class="op-form-title">{{ t('PAYMENT_REMINDERS.paymentReceipts') }}</div>
					<mat-radio-group name="payment-receipts" formControlName="receipts">
						<div *ngFor="let receipts of paymentRemindersOptions?.receipts">
							<mat-radio-button
								class="radio-button"
								id="{{ 'receipts_' + receipts }}"
								*ngIf="receipts !== 'RACH_RECEIPTS_NONE'"
								[value]="receipts"
							>
								<div *ngIf="receipts === 'RACH_RECEIPTS_SMS'">
									{{ t('PAYMENT_REMINDERS.optionSMS') }}
								</div>

								<div *ngIf="receipts === 'RACH_RECEIPTS_MAIL'">
									{{ t('PAYMENT_REMINDERS.optionMail') }}
								</div>
							</mat-radio-button>
						</div>
					</mat-radio-group>
				</div>

				<div class="op-center op-label-font">
					{{ t('PAYMENT_REMINDERS.alternateToClickSave') }}
				</div>
				<p></p>

				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="saveContinueBtn"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('GLOBAL.saveAndContinue') }}
				</button>
				<p></p>

				<div class="op-center op-label-font op-gray">*{{ t('PAYMENT_REMINDERS.disclaimerSMS') }}</div>
				<p></p>

				<div class="op-center">
					<div class="op-icon-align-body-2-green">
						<mat-icon svgIcon="op-notifications"></mat-icon>
						{{ t('PAYMENT_REMINDERS.downloadDocNotes') }}
					</div>
					<a href="" (click)="continue()" onclick="return false" id="paymentRemindersSetupLater">
						{{ t('PAYMENT_REMINDERS.setupLater') }}
					</a>
				</div>
			</form>
		</section>
	</op-content-container>
</div>
