import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Adds the transaction id to the http request.
 *
 * @export
 * @class TransactionIdInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TransactionIdInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const appendHeaders = {
			'X-App-Id': 'mobile-origination-web',
			TransactionId: uuidv4()
		};
		return next.handle(request.clone({ setHeaders: appendHeaders }));
	}
}
