<div class="get-offer" *transloco="let t">
	<div class="op-center">
		<p></p>
		<img alt="" src="/assets/images/prescreen_pl.svg" width="100" height="100" />
	</div>
	<op-masthead class="op-center" [title]="t('SPECIAL_OFFER.GET_OFFER.title')">
		<div class="op-green-divider"></div>
		<p></p>
		<h4 class="op-center">{{ t('SPECIAL_OFFER.GET_OFFER.subtitle') }}</h4>
	</op-masthead>
	<op-content-container class="content">
		<button
			mat-flat-button
			color="primary"
			name="startBtn"
			id="startBtn"
			type="button"
			[routerLink]="['/personal-info']"
			(click)="trackTealiumEvent('dp-offer-not-found-apply')"
		>
			{{ t('SPECIAL_OFFER.GET_OFFER.startBtn') }}
		</button>

		<p></p>
		<op-learn-more src="/assets/images/get-prequalify.svg" [title]="t('SPECIAL_OFFER.GET_OFFER.getPrequal.title')">
			{{ t('SPECIAL_OFFER.GET_OFFER.getPrequal.body') }}
		</op-learn-more>
		<p></p>

		<op-learn-more src="/assets/images/complete.svg" [title]="t('SPECIAL_OFFER.GET_OFFER.completeApp.title')">
			{{ t('SPECIAL_OFFER.GET_OFFER.completeApp.body') }}
		</op-learn-more>
		<p></p>

		<op-learn-more src="/assets/images/offers-handMoney.svg" [title]="t('SPECIAL_OFFER.GET_OFFER.receiveLoan.title')">
			{{ t('SPECIAL_OFFER.GET_OFFER.receiveLoan.body') }}
		</op-learn-more>
		<p></p>

		<div class="op-center op-body-2">
			<p></p>
			<span [innerHTML]="t('SPECIAL_OFFER.GET_OFFER.terms.preLinkText')"></span>
			<a class="op-green" tabindex="0" role="button" [matMenuTriggerFor]="menu" id="variesByState">
				{{ t('SPECIAL_OFFER.GET_OFFER.terms.linkText') }}
			</a>
			<span>{{ t('SPECIAL_OFFER.GET_OFFER.terms.postLinkText') }}</span>
		</div>

		<mat-menu #menu="matMenu">
			<table class="table table-bordered" aria-label="max state amounts">
				<thead>
					<tr>
						<th id="state">{{ t('SPECIAL_OFFER.GET_OFFER.popupTable.state') }}</th>
						<th id="amount">{{ t('SPECIAL_OFFER.GET_OFFER.popupTable.maxLoanAmt') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td rowspan="2">California</td>
						<td>
							$10,000 <span>{{ t('SPECIAL_OFFER.GET_OFFER.popupTable.unsecured') }}</span>
						</td>
					</tr>
					<tr>
						<td>
							$20,000 <span>{{ t('SPECIAL_OFFER.GET_OFFER.popupTable.secured') }}</span>
						</td>
					</tr>
					<tr>
						<td>Texas</td>
						<td>$6,000</td>
					</tr>
					<tr>
						<td>Florida</td>
						<td>$6,000</td>
					</tr>
					<tr>
						<td>Illinois</td>
						<td>$6,000</td>
					</tr>
					<tr>
						<td>Wisconsin</td>
						<td>$6,000</td>
					</tr>
				</tbody>
			</table>
		</mat-menu>
	</op-content-container>
</div>
