import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LoanApplicationService } from '../services/loan-application/loan-application.service';
import { ILoanApplication } from '../services/loan-application/loan-application.model';
import { IFeatureEligibilityResult, MobileApiService } from '../services/mobile-api';

@Injectable()
export class EligibilityRoutingGuard implements CanActivate {
	constructor(
		private router: Router,
		private mobileApiService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.updateLoanApplication().pipe(
			filter(Boolean),
			switchMap((loanApp: ILoanApplication) => {
				return this.mobileApiService.setEligibility(route.data.feature, null, loanApp.id);
			}),
			map((eligibility: IFeatureEligibilityResult) => {
				const fallBackPath = `/${route?.data?.fallBackPath as string}`;
				if (!eligibility?.eligible) {
					this.router.navigate([fallBackPath], {
						queryParams: {},
						queryParamsHandling: 'merge',
						replaceUrl: true
					});
				}
				return Boolean(eligibility?.eligible);
			})
		);
	}
}
