import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { es } from 'date-fns/locale';

import { TranslocoService } from '@ngneat/transloco';

import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { TagDataService } from '../../core/services/tag-data/tag-data.service';
import { RoutingPathsEnum } from '../../core/services/routing/routing.service';
import { PartnerLeadService } from '../../core/services/partner/partner-lead.service';
import { OriginationPartnerService } from '../../core/services/partner/origination-partner.service';
import { IPartnerLead, IPartnerLeadConsent, PartnerLeadConsentEnum } from '../../core/services/mobile-api';
import { STATE_CA } from '../../shared/constants/states-const';

/**
 *
 * California finder partner discclosure
 *
 * @export
 * @class CaFinderPartnerDisclosureComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-finder-partner-disclosure',
	templateUrl: './ca-finder-partner-disclosure.component.html',
	styleUrls: ['./ca-finder-partner-disclosure.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CaFinderPartnerDisclosureComponent implements OnInit {
	partnerLead: IPartnerLead;
	isCaFinderPartner: boolean;
	partnerName: string;

	constructor(
		private router: Router,
		private translocoService: TranslocoService,
		private authenticationService: AuthenticationService,
		private partnerLeadService: PartnerLeadService,
		private tagDataService: TagDataService,
		private originationPartnerService: OriginationPartnerService
	) {}

	ngOnInit() {
		this.partnerLeadService
			.getPartnerLeadDetails()
			.pipe(
				tap((partnerLead: IPartnerLead) => {
					this.isCaFinderPartner = partnerLead?.finderPartner === true && partnerLead?.state === STATE_CA;
				}),
				switchMap((partnerLead: IPartnerLead) => {
					this.partnerLead = partnerLead;
					return this.isCaFinderPartner
						? this.originationPartnerService.getPartnerBrand(partnerLead?.partnerShortName)
						: of(null);
				})
			)
			.subscribe({
				next: (partnerName: string) => {
					this.partnerName = partnerName;
					if (!this.isCaFinderPartner) {
						this.router.navigate([RoutingPathsEnum.personalInfo]);
					}
				},
				error: (error) => {
					const extras = this.partnerLead?.leadId ? { queryParams: { leadId: this.partnerLead.leadId } } : undefined;
					this.router.navigate([RoutingPathsEnum.home], extras);
				}
			});
	}

	public acknowledge(): void {
		this.tagDataService.link({}, { tealium_event: 'accept_ca_finder_consent' });
		const partnerLeadConsent = this.buildPartnerLeadConsentPayload(true);
		this.partnerLeadService.updatePartnerFinderConsent(partnerLeadConsent).subscribe({
			next: (partnerLead: IPartnerLead) => {
				this.router.navigate([RoutingPathsEnum.personalInfo]);
			}
		});
	}

	public decline(): void {
		this.tagDataService.link({}, { tealium_event: 'decline_ca_finder_consent' });
		const partnerLeadConsent = this.buildPartnerLeadConsentPayload(false);

		this.partnerLeadService
			.updatePartnerFinderConsent(partnerLeadConsent)
			.pipe(switchMap((partnerLead: IPartnerLead) => this.authenticationService.signOut()))
			.subscribe({
				complete: () => {
					const activeLang = this.translocoService.getActiveLang();
					const exitUrl = `https\://oportun.com/${activeLang === es.code ? 'es' : ''}`;
					window.location.href = exitUrl;
				}
			});
	}

	private buildPartnerLeadConsentPayload(status: boolean): IPartnerLeadConsent {
		return {
			leadId: this.partnerLead.leadId,
			consentAccepted: status,
			consentType: PartnerLeadConsentEnum.partnerFinderAcknowledgement,
			partnerShortName: this.partnerLead.partnerShortName
		} as IPartnerLeadConsent;
	}
}
