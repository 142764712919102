import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { of, Subscription } from 'rxjs';
import { isFunction, noop } from 'lodash';
import { AccountNotConnectedComponent } from '../account-not-connected/account-not-connected.component';

@Component({
	selector: 'op-plaid-connect-dialog',
	templateUrl: './plaid-connect-dialog.component.html'
})
export class PlaidConnectDialogComponent implements OnInit, OnDestroy {
	private plaidConnected = false;

	private subscription = new Subscription();

	bankConnectEvent: BankConnectEventTypeEnum = BankConnectEventTypeEnum.idle;

	showNoAccountsError: boolean = false;

	constructor(
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService,
		private plaidLinkService: PlaidLinkService,
		private dialogService: DialogService,
		private tagDataService: TagDataService,
		public dialogRef: MatDialogRef<PlaidConnectDialogComponent>
	) {
		this.plaidConnected = this.sessionStorageService.get(STORAGE_KEYS.PLAID_CONNECTED);
	}

	ngOnInit() {
		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);
	}

	ngOnDestroy(): void {
		this.sessionStorageService.remove(STORAGE_KEYS.PLAID_CONNECTED);
		this.subscription.unsubscribe();
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent = event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.opened]: this.bankConnectionOpenedCallback.bind(this),
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionOpenedCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = false;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		if (event.data.responseStatus === BankConnectResponseStatusEnum.emptyAccountList) {
			this.showPlaidConnectError();
		} else {
			const accountsFound = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;
			const msgData = this.plaidLinkService.getBankDialogMessage(accountsFound);
			if (msgData) {
				this.dialogService
					.openMessageDialog(
						msgData,
						() => of(),
						() => of()
					)
					.subscribe({
						next: () => {
							this.tagDataService.link(
								{},
								{
									tealium_event: 'bank_not_found'
								}
							);
						}
					});
			} else {
				this.dialogRef.close(true);
			}
		}
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.showPlaidConnectError();
	}

	showPlaidConnectError(): void {
		this.dialogService.openSimpleDialog(AccountNotConnectedComponent).subscribe({
			next: (result) => {
				if (result?.addManually === true) {
					this.routingService.route(RoutingPathsEnum.termsConfirmedAddManually);
					this.dialogRef.close(false);
				}
				if (result?.addUsingPlaid === true) {
					this.plaidLinkService.openPlaid();
				}
			},
			error: () => {}
		});
	}
}
