<div class="proof-of-identification" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead
		[customClass]="'head-bp-4'"
		[title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.identificationTitle')"
	>
	</op-masthead>
	<op-content-container class="content" *ngIf="documentClassificationList$ | async as classificationList">
		<div *ngIf="proofState === proofStatusEnum.classificationSelection">
			<op-document-classification
				id="identificationClassification"
				[categoryType]="categoryType"
				[documentClassificationList]="classificationList"
				(documentClassification)="saveDocumentClassification($event)"
				(cancel)="onCancel($event)"
			>
				<hr />
				<div
					class="op-mb-20 op-dark-grey op-micro-font"
					[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.privacyPolicy')"
				></div>
			</op-document-classification>
		</div>

		<div *ngIf="proofState === proofStatusEnum.upload">
			<div class="icon-align op-mb-20" id="criteria0_30">
				<mat-icon svgIcon="op-info-green"></mat-icon>
				<span
					class="icon-text"
					[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.uploadNotExpired')"
				></span>
			</div>
			<div>
				<div class="op-header-3-font op-mb-8" *ngIf="stateStorage?.selectedDocumentClassification?.key !== 'PASSPORT'">
					{{ stateStorage?.selectedDocumentClassification?.text }}
				</div>
				<div class="op-header-3-font op-mb-8" *ngIf="stateStorage?.selectedDocumentClassification?.key === 'PASSPORT'">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.validPassport') }}
				</div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								)
							"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</div>

			<div *ngIf="stateStorage?.selectedDocumentClassification?.key === 'OTHER' && frontBackOptional">
				<op-identification-other></op-identification-other>
			</div>

			<div class="op-mt-20" *ngIf="stateStorage?.selectedDocumentClassification?.key | opIssuingCountry">
				<form class="personal-content" [formGroup]="formGroup">
					<op-info-tip
						*ngIf="stateStorage?.selectedDocumentClassification?.key === 'PASSPORT'"
						id="issuingCountryTip"
						[key]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.issuingCountryPassportTip')"
					>
						<strong>{{ t('IDENTIFICATION.issuingCountry') | opRequired }}</strong>
					</op-info-tip>
					<op-info-tip
						*ngIf="stateStorage?.selectedDocumentClassification?.key === 'MATRICULA_CONSULAR'"
						id="issuingCountryTip"
						[key]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.issuingCountryConsularTip')"
					>
						<strong>{{ t('IDENTIFICATION.issuingCountry') | opRequired }}</strong>
					</op-info-tip>
					<p></p>
					<mat-form-field class="form-element" appearance="outline">
						<mat-select
							formControlName="issuingCountry"
							id="issuingCountry"
							name="issuingCountry"
							placeholder="{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.selectCountry') }}"
							aria-label="issuingCountry"
						>
							<mat-option
								*ngFor="let country of countryList$ | async; let i = index"
								id="{{ 'issuingCountry_' + country.key }}"
								[value]="country.key"
								>{{ country.text }}</mat-option
							>
						</mat-select>
						<mat-error
							id="issuingCountryError"
							*ngIf="!formGroup.controls['issuingCountry'].valid && formGroup.controls['issuingCountry'].touched"
						>
							<div *ngIf="stateStorage?.selectedDocumentClassification?.key === 'PASSPORT'">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.providePassport') }}
							</div>
							<div *ngIf="stateStorage?.selectedDocumentClassification?.key === 'MATRICULA_CONSULAR'">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.provideConsularId') }}
							</div>
						</mat-error>
					</mat-form-field>
				</form>
			</div>

			<div *ngIf="classificationFiles?.length" class="op-body-1-bold-font op-mtb-20">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadedDocuments') }}
			</div>

			<div *ngIf="attention && reviewFileMessageCount" class="op-mtb-20">
				<op-document-notification id="identificationNeedsAttention" class="needs op-mb-10">
					<div id="{{ 'messages' }}">
						<div *ngFor="let file of reviewFiles">
							<div *ngFor="let msg of file?.ocrInfo?.messages; let isLast = last">
								<div class="icon-align">
									<mat-icon svgIcon="op-attention"></mat-icon>
									<span class="icon-text">
										{{ msg }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</op-document-notification>
			</div>

			<op-document-notification
				*ngIf="bounceReasons?.length"
				id="identificationBounceReasons"
				class="attention op-mtb-20"
			>
				<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification>

			<op-document-upload
				class="op-mt-20"
				id="identificationUploadFront"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[showSingleFile]="
					(stateStorage?.selectedDocumentClassification?.key | opIssuingCountry) || showFrontBack ? true : false
				"
				[fileSide]="'front'"
				(displayedSingleFile)="displayedSingleFileFront($event)"
				[delayUpload]="
					(stateStorage?.selectedDocumentClassification?.key | opIssuingCountry) || showFrontBack ? true : false
				"
				[hideReUploadButton]="!attention && !bounceReasons?.length"
				[country]="formGroup?.value?.issuingCountry"
				#docUpload
			>
				<div *ngIf="showFrontBack" uploadTitle>
					<div class="upload-title">
						<div class="op-header-4-font op-mb-0">
							{{
								frontBackOptional
									? t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.frontSide')
									: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.front')
							}}
						</div>
						<div class="title-badge">
							<op-document-badge
								*ngIf="
									singleFileFront?.badge === docBadgeEnum.ready &&
									singleFileFront?.status !== fileUploadStatusEnum.cancelled
								"
								id="{{ 'id_front_ready' }}"
								class="ready badge"
								icon="op-checkMark-green"
								[small]="true"
							>
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.verified') }}
							</op-document-badge>
							<op-document-badge
								*ngIf="
									singleFileFront?.badge === docBadgeEnum.attention &&
									singleFileFront?.status !== fileUploadStatusEnum.cancelled
								"
								id="{{ 'id_front_attention' }}"
								class="attention badge"
								icon="op-attention"
								[small]="true"
							>
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
							</op-document-badge>
						</div>
					</div>
				</div>
				<div uploadIdleInfo>
					<div
						class="op-dark-grey op-mt-8 op-body-2-font"
						[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
					></div>
				</div>
				<div uploadIdleButtonText>
					<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
				</div>
				<div uploadPendingInfo>
					<div
						class="op-dark-grey op-mt-8 op-body-2-font"
						[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
					></div>
				</div>
				<div reUploadPendingButtonText>
					<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reUploadDocument') }}</div>
				</div>
			</op-document-upload>

			<div class="op-mt-20" *ngIf="showFrontBack">
				<op-document-upload
					id="identificationUploadBack"
					[categoryType]="categoryType"
					[documentClassification]="stateStorage?.selectedDocumentClassification"
					[selectedClassificationFiles]="selectedClassificationFiles"
					[showSingleFile]="showFrontBack ? true : false"
					[fileSide]="'back'"
					(displayedSingleFile)="displayedSingleFileBack($event)"
					[delayUpload]="true"
					[hideReUploadButton]="!attention && !bounceReasons?.length"
					[disabled]="optionalDisabled"
					[country]="formGroup?.value?.issuingCountry"
				>
					<div *ngIf="showFrontBack" uploadTitle>
						<div class="upload-title">
							<div class="op-header-4-font op-mb-0">
								{{
									frontBackOptional
										? t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.backSide')
										: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.back')
								}}
								<span class="op-body-1-font">
									{{
										frontBackOptional ? t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.ifApplicable') : t('')
									}}
								</span>
							</div>
							<div class="title-badge">
								<op-document-badge
									*ngIf="
										singleFileBack?.badge === docBadgeEnum.ready &&
										singleFileBack?.status !== fileUploadStatusEnum.cancelled
									"
									id="{{ 'id_back_ready' }}"
									class="ready badge"
									icon="op-checkMark-green"
									[small]="true"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.verified') }}
								</op-document-badge>
								<op-document-badge
									*ngIf="
										singleFileBack?.badge === docBadgeEnum.attention &&
										singleFileBack?.status !== fileUploadStatusEnum.cancelled
									"
									id="{{ 'id_back_attention' }}"
									class="attention badge"
									icon="op-attention"
									[small]="true"
								>
									{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
								</op-document-badge>
							</div>
						</div>
						<div *ngIf="showFrontBack && frontBackOptional">
							<div class="op-body-2-font op-dark-grey">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherBackInfo') }}
							</div>
						</div>
					</div>

					<div uploadIdleInfo>
						<div
							class="op-dark-grey op-mt-8 op-body-2-font"
							[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
						></div>
					</div>
					<div uploadIdleButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
					</div>
					<div uploadPendingInfo>
						<div
							class="op-dark-grey op-mt-8 op-body-2-font"
							[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
						></div>
					</div>
					<div reUploadPendingButtonText>
						<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reUploadDocument') }}</div>
					</div>
				</op-document-upload>
			</div>

			<op-document-notification *ngIf="showContinueMessage" id="incomeContinueApplication" class="processing op-mtb-20">
				<div class="icon-align">
					<mat-icon svgIcon="op-processingInfo"></mat-icon>
					<div class="icon-text">
						<div class="op-body-2-bold-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.continueYourApplication') }}</div>
					</div>
				</div>
				<div class="op-body-2-font op-light-black op-mt-5">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.continueWeWillLetYouKnow') }}
				</div>
			</op-document-notification>

			<div class="op-mt-40 op-center">
				<button
					mat-flat-button
					color="primary"
					name="identificationContinueBtn"
					id="identificationContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="'continue identification'"
				>
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.saveAndContinue') }}
				</button>
			</div>

			<div class="op-mt-20 op-center" *ngIf="canCancel" #continueBtn>
				<button
					mat-flat-button
					color="secondary"
					name="identificationCancelBtn"
					id="identificationCancelBtn"
					(click)="onCancel()"
					[opTagClick]="'cancel identification'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>

		<div *ngIf="proofState === proofStatusEnum.status">
			<op-document-status
				id="identificationStatus"
				[categoryType]="categoryType"
				[classificationVerification]="autoVerificationMap"
				(done)="onDocStatus($event)"
				[documentClassificationList]="classificationList"
			>
				<div [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.additionDocInfo')"></div>

				<op-document-notification
					*ngIf="bounceReasons?.length"
					id="identificationBounceReasons"
					class="attention op-mtb-20"
				>
					<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
						<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
					</ul>
				</op-document-notification>
			</op-document-status>

			<div class="op-mt-30 op-center">
				<button
					mat-flat-button
					color="primary"
					name="doneBtn"
					id="identificationStatusBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</div>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
