import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class PlaidConnectGuard implements CanActivate {
	constructor(
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.updateLoanApplication().pipe(
			map((loanApp) => {
				const zeusConnectLinkEnabled = Boolean(this.sessionStorageService.get(STORAGE_KEYS.ZEUS_CONNECT_LINK_ENABLED));
				if (zeusConnectLinkEnabled) {
					return true;
				}
				const applicant = ApplicantUtils.fromLoanApp(this.loanAppService.getLoanApp());
				if (this.loanAppService.isBtmEligible() || applicant.isPlaidOneClickEligible()) {
					return true;
				} else {
					this.routingService.routeFromLoanApp();
					return false;
				}
			})
		);
	}
}
