<div class="loan-details-list" *transloco="let t">
	<div [formGroup]="formGroup">
		<div class="op-center" *ngFor="let item of list; let i = index">
			<label for="{{ id + '_loanDetails_' + item.id }}" tabindex="0" [hidden]="i > 0 && !showMoreOptions">
				<input
					type="radio"
					id="{{ id + '_loanDetails_' + item.id }}"
					[value]="item.index"
					formControlName="loanDetails"
					name="loanDetails"
				/>
				<op-adjust-loan-details
					[hidden]="i > 0 && !showMoreOptions"
					[item]="item"
					[selectedLoanDetailId]="formGroup.get('loanDetails').value"
				></op-adjust-loan-details>
				<div class="op-mt-20" *ngIf="item.index === formGroup.get('loanDetails').value">
					<button
						mat-flat-button
						color="primary"
						class="op-full-width"
						type="button"
						id="{{ id + '_submitBtn' + item.id }}"
						name="submitBtn"
						[disabled]="formGroup.invalid"
						(click)="onChooseTerms()"
					>
						{{ t('GLOBAL.continue') }}
					</button>
				</div>
			</label>
		</div>
	</div>
</div>
