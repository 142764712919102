import { Injectable, OnDestroy } from '@angular/core';
import { PageSizes, PDFDocument, PDFImage, PDFPage } from '@cantoo/pdf-lib';
import { isEmpty } from 'lodash';
import { BehaviorSubject, concatMap, from, map, Observable, of, reduce, switchMap, tap } from 'rxjs';
import { FileUploadService } from 'src/app/core/services/file-upload/file-upload.service';
import { DocumentStatusCriteriaEnum } from 'src/app/core/services/mobile-api';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

export interface collateFileInfo {
	file: File;
	thumbnail: string;
	criteria: DocumentStatusCriteriaEnum;
}

@Injectable({
	providedIn: 'root'
})
export class CollationService implements OnDestroy {
	readonly WIDTH = 320;
	readonly HEIGHT = 240;
	// private subscription = new Subscription();

	private readonly collationSource = new BehaviorSubject<collateFileInfo[]>([]);
	readonly collation$ = this.collationSource.asObservable();

	constructor(
		private fileUploadService: FileUploadService,
		private sessionStorageService: SessionStorageService,
		private tagDataService: TagDataService
	) {}

	ngOnDestroy(): void {
		this.collationSource.complete();
		// this.subscription.unsubscribe();
	}

	public getFileList(): collateFileInfo[] {
		return this.collationSource.getValue();
	}

	private setFileList(fileList: collateFileInfo[]): void {
		this.collationSource.next(fileList);
	}

	public addFile(file: File, criteria: DocumentStatusCriteriaEnum): void {
		this.fileUploadService.getThumbnail(file).subscribe({
			next: (thumbnail) => {
				const collateFile: collateFileInfo = {
					file: file,
					thumbnail: thumbnail,
					criteria: criteria
				};
				const fileList = this.getFileList();
				fileList.push(collateFile);
				this.setFileList(fileList);
			}
		});
	}

	public removeFile(index: number): void {
		const fileList = this.getFileList();
		fileList.splice(index, 1);
		this.setFileList(fileList);
	}

	private clearFileList(): void {
		this.setFileList([]);
	}

	public createPdfDoc(): Observable<File> {
		const observable$ = from(PDFDocument.create());
		return observable$.pipe(
			switchMap((pdfDoc) => {
				const fileList = this.getFileList();
				if (isEmpty(fileList)) {
					throw new Error('No files to collate');
				} else {
					if (fileList.length === 1 && fileList[0].file.type === 'application/pdf') {
						return of(fileList[0].file).pipe(tap((_) => this.clearFileList()));
					} else {
						return this.generatePdfDoc(pdfDoc, fileList).pipe(
							reduce((pdf, _) => pdf, pdfDoc),
							switchMap((pdf) => from(pdf.save())),
							map((pdfData) => {
								// get the pdf file name from localized string
								const pdfFile = new File([pdfData], 'compiled bank statement.pdf', { type: 'application/pdf' });
								return pdfFile;
							}),
							tap((_) => this.clearFileList())
						);
					}
				}
			})
		);
	}

	private generatePdfDoc(pdfDoc: PDFDocument, images: collateFileInfo[]): Observable<PDFDocument> {
		const embedDocument = (
			pdfDoc: PDFDocument,
			imageData: string,
			format: string
		): Observable<PDFImage | PDFPage[]> => {
			switch (format) {
				case 'image/jpeg':
				case 'image/jpg':
					return from(pdfDoc.embedJpg(imageData));
				case 'image/png':
					return from(pdfDoc.embedPng(imageData));
				case 'application/pdf':
					return from(PDFDocument.load(imageData, { ignoreEncryption: true, password: '' })).pipe(
						switchMap((pdf) => {
							// const pages = Array.from({ length: pdf.getPageCount() }, (el, idx) => idx);
							return from(pdfDoc.copyPages(pdf, pdf.getPageIndices()));
						})
					);
			}
		};

		const addImageToPdf = (pdfDoc: PDFDocument, pdfImage: PDFImage | PDFPage[]): PDFDocument => {
			if (pdfImage instanceof PDFImage) {
				const page = pdfDoc.addPage(PageSizes.A4);
				const imageDims = pdfImage.scaleToFit(page.getWidth(), page.getHeight());
				page.drawImage(pdfImage, {
					x: page.getWidth() / 2 - imageDims.width / 2,
					y: page.getHeight() / 2 - imageDims.height / 2,
					width: imageDims.width,
					height: imageDims.height
				});
			} else {
				pdfImage.forEach((pdfPage) => {
					pdfDoc.addPage(pdfPage);
				});
			}
			return pdfDoc;
		};

		return from(images).pipe(
			concatMap((image, index) => {
				return this.readFileAsDataURL(image.file).pipe(
					switchMap((info) => embedDocument(pdfDoc, info, image.file.type)),
					map((pdfImage) => addImageToPdf(pdfDoc, pdfImage))
				);
			})
		);
	}

	private readFileAsDataURL(file: File): Observable<string> {
		return new Observable((observer) => {
			const reader = new FileReader();
			reader.onload = () => {
				observer.next(reader.result as string);
				observer.complete();
			};
			reader.onerror = (error) => {
				observer.error(error);
			};
			reader.readAsDataURL(file);
		});
	}
}
