import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription } from 'rxjs';
import { IAdditionalData } from 'src/app/core/services/mobile-api';
import { MultiProductService } from 'src/app/core/services/multi-product/multi-product.service';

import { PdfDialogComponent } from '../dialogs/pdf-dialog/pdf-dialog.component';

@Component({
	selector: 'op-credit-card-offer',
	templateUrl: './credit-card-offer.component.html',
	styleUrls: ['./credit-card-offer.component.scss']
})
export class CreditCardOfferComponent implements OnInit, OnDestroy {
	@Input() redirectCode: string;
	@Input() eligibleOfferDays: number;
	@Input() showOfferDays: boolean;
	@Input() multiProductSegmentNode: string;
	@Input() multiProductPricingCode: string;
	@Input() productCategory: string;
	@Input() creditCardAdditionalData: IAdditionalData;
	@Output() OnClickTriggerAction = new EventEmitter<string>();
	currentLanguage: string;
	private subscription = new Subscription();

	constructor(
		private multiProductService: MultiProductService,
		private translocoService: TranslocoService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		const langSub = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.currentLanguage = lang;
			}
		});
		this.subscription.add(langSub);		
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public goToCCService(): void {
		const ccServiceHost = this.multiProductService.getCCHost(this.redirectCode, this.currentLanguage);
		this.OnClickTriggerAction.emit(`${this.multiProductSegmentNode}_${this.multiProductPricingCode}_${this.productCategory}_applied_creditcard_product`);
		window.open(ccServiceHost, '_self');
	}

	public openDisclosureDialog(): Observable<any> {
		this.OnClickTriggerAction.emit('open_credit_card_disclosure_modal');
		const data = {
			url: this.getCreditCardDisclosurePath()
		};
		const dialogRef = this.dialog.open(PdfDialogComponent, { data });
		return dialogRef.afterClosed();
	}

	private getCreditCardDisclosurePath(): string {
		let urlPrefix = 'https://oportun-s3-prod-usw1-public-all.s3-us-west-1.amazonaws.com/credit-cards';
		if (this.translocoService?.getActiveLang() === 'es') {
			urlPrefix = `${urlPrefix}/Spanish_29-0.pdf`;
		} else {
			urlPrefix = `${urlPrefix}/English_29-0.pdf`;
		}
		return urlPrefix;
	}
}
