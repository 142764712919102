import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { DisbursementRoutingGuard } from '../core/guards/disbursement-routing.guard';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { AddBankAccountTermsConfirmedComponent } from './add-bank-account/add-bank-account-terms-confirmed.component';
import { AddBankAccountComponent } from './add-bank-account/add-bank-account.component';
import { AddManuallyTermsConfirmComponent } from './add-bank-account/add-manually/add-manually-terms-confirm.component';
import { AddManuallyComponent } from './add-bank-account/add-manually/add-manually.component';
import { AddDebitCardComponent } from './add-debit-card/add-debit-card.component';
import { AdditionalContactComponent } from './additional-contact/additional-contact.component';
import { AdjustTermsComponent } from './adjust-terms/adjust-terms.component';
import { AlmostDoneComponent } from './almost-done/almost-done.component';
import { AutoVerifyIncomeComponent } from './auto-verify-income/auto-verify-income.component';
import { BankVerificationComponent } from './bank-verification/bank-verification.component';
import { ProofOfAddressComponent } from './document-submit/auto-verification/address/proof-of-address.component';
import { ProofOfBankAccountComponent } from './document-submit/auto-verification/bank-account/proof-of-bank-account.component';
import { DocumentCollationComponent } from './document-submit/auto-verification/document-collation/document-collation.component';
import { ProofOfIdentificationComponent } from './document-submit/auto-verification/identification/proof-of-identification.component';
import { ProofOfIdentificationResolver } from './document-submit/auto-verification/identification/proof-of-identification.resolver';
import { ProofOfIncomeComponent } from './document-submit/auto-verification/income/proof-of-income.component';
import { ProofOfIncomeResolver } from './document-submit/auto-verification/income/proof-of-income.resolver';
import { SelfieComponent } from './document-submit/auto-verification/selfie/selfie.component';
import { StatusFileResolver } from './document-submit/auto-verification/status-file.resolver';
import { DocumentSubmitComponent } from './document-submit/document-submit.component';
import { AutoEligibilityResolver } from './document-submit/guards/auto-eligibility.resolver';
import { MfaRequiredGuard } from './document-submit/guards/mfa-required.guard';
import { VerifyIncomeGuard } from './document-submit/guards/verify-income.guard';
import { FundingSelectionComponent } from './funding-selection/funding-selection.component';
import { FundsComponent } from './funds/funds.component';
import { IncomeVerificationComponent } from './income-verification/income-verification.component';
import { ReceiveFundsComponent } from './receive-funds/receive-funds.component';
import { StoreLessDisbursementInfoComponent } from './store-less-disbursement-info/store-less-disbursement-info.component';
import { TermsConfirmedComponent } from './terms-confirmed/terms-confirmed.component';
import { VerifyIncomeComponent } from './verify-income/verify-income.component';

const routes: Routes = [
	{
		path: RoutingPathsEnum.receiveFunds,
		component: ReceiveFundsComponent,
		canActivate: [AuthenticationGuard, DisbursementRoutingGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.addBankAccount,
		component: AddBankAccountComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.addDebitCard,
		component: AddDebitCardComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.addManually,
		component: AddManuallyComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.fundingOptions,
		component: FundingSelectionComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.documentSubmit,
		canActivate: [AuthenticationGuard, MfaRequiredGuard, VerifyIncomeGuard],
		resolve: { loanApp: LoanApplicationResolver, autoVerificationEligible: AutoEligibilityResolver },
		children: [
			{
				path: '',
				component: DocumentSubmitComponent
			},
			{
				path: 'collation',
				component: DocumentCollationComponent
			},
			{
				path: 'proof-of-income',
				component: ProofOfIncomeComponent,
				resolve: { fileStatus: StatusFileResolver, collationEnabled: ProofOfIncomeResolver }
			},
			{
				path: 'proof-of-address',
				component: ProofOfAddressComponent,
				resolve: { fileStatus: StatusFileResolver }
			},
			{
				path: 'proof-of-bank-account',
				component: ProofOfBankAccountComponent,
				resolve: { fileStatus: StatusFileResolver }
			},
			{
				path: 'proof-of-identification',
				component: ProofOfIdentificationComponent,
				resolve: { fileStatus: StatusFileResolver, idTwoSidedOtherEnabled: ProofOfIdentificationResolver }
			},
			{
				path: 'proof-of-selfie',
				component: SelfieComponent
			}
		]
	},
	{
		path: RoutingPathsEnum.adjustTerms,
		component: AdjustTermsComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.additionalContact,
		component: AdditionalContactComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.termsConfirmed,
		component: TermsConfirmedComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.termsConfirmedAddBankAccount,
		component: AddBankAccountTermsConfirmedComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.termsConfirmedAddManually,
		component: AddManuallyTermsConfirmComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.autoVerifyIncome,
		component: AutoVerifyIncomeComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.verifyIncome,
		component: VerifyIncomeComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.incomeVerification,
		component: IncomeVerificationComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.bankVerification,
		component: BankVerificationComponent,
		canActivate: [AuthenticationGuard, DisbursementRoutingGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.funds,
		component: FundsComponent,
		canActivate: [AuthenticationGuard, DisbursementRoutingGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.almostDone,
		component: AlmostDoneComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.storeLessDisbursementInfo,
		component: StoreLessDisbursementInfoComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ApprovalRoutingModule {}
