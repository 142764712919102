<div class="auto-verify-income" *transloco="let t">
	<op-masthead [title]="t('AUTO_VERIFY_INCOME.header')"></op-masthead>
	<op-content-container class="content">
		<op-plaid-extended id="incomePlaid" class="op-center" *ngIf="showPlaid" [bankOnly]="false" [sectionType]="fileType">
			<div plaid-title class="op-header-3-font op-left">
				{{ t('AUTO_VERIFY_INCOME.fasterApproval') }}
			</div>

			<div *ngIf="bankConnectEvent === 'WAITING'">
				{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.loadingAccounts') }}
			</div>

			<op-alert level="WARNING" *ngIf="connectAccountError && bankConnectEvent !== 'WAITING'">
				{{ connectAccountError }}
			</op-alert>
			<op-alert level="ERROR" *ngIf="showNoAccountsError && bankConnectEvent !== 'WAITING'">
				{{ 'DOCUMENT_SUBMIT.proofOfBankAccount.noAccountsError' | transloco }}
			</op-alert>

			<p></p>

			<op-select-bank-account
				id="incomeSelectAccount"
				*ngIf="selectAccount"
				[filter]="bankFilter"
				[fromPlaid]="isFromPlaid"
				(checkVerificationStatus)="onIncomeVerificationComplete($event)"
			>
			</op-select-bank-account>
		</op-plaid-extended>

		<button mat-flat-button color="secondary-small" id="skipIncomeVerify" (click)="showSkipDialog()">
			{{ t('AUTO_VERIFY_INCOME.skipButton') }}
		</button>
		<op-website-terms></op-website-terms>
	</op-content-container>
</div>
