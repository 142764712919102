import { cloneDeep, isEmpty } from 'lodash';
import { formatDate } from 'src/app/core/services/date-utils/date-utils';
import { FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { DocumentSideEnum, DocumentStatusCriteriaEnum, FileStatusEnum } from 'src/app/core/services/mobile-api';

export const frontBackList = ['DRIVERS_LICENSE', 'STATE_ID', 'MATRICULA_CONSULAR'];
export const optionalFrontBackList = ['OTHER'];
export const singleFileList = ['PASSPORT'];

export const incomeBankStatementCriteria = [
	DocumentStatusCriteriaEnum.zeroToThirtyDays,
	DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays
];

export default class AutoVerificationUtils {
	static sortOcrFiles(files: IFileUpload[]): IFileUpload[] {
		const filesClone = cloneDeep(files);
		const sorted = filesClone?.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1)) || [];
		return sorted;
	}

	static lastOcrFile(files: IFileUpload[]): IFileUpload {
		const sorted = this.sortOcrFiles(files);
		return sorted[0];
	}

	/**
	 * A file is considered in verification if criteriaMet is empty and documentStatus is complete
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isLastOcrFileVerification(files: IFileUpload[]): boolean {
		if (files?.every((file) => !file.createdDate)) {
			return false;
		}
		const lastFile = this.lastOcrFile(files);
		return lastFile?.ocrInfo?.documentStatus === FileStatusEnum.complete && isEmpty(lastFile?.ocrInfo?.criteriaMet);
	}

	static hasIncomeBankStatement(files: IFileUpload[]): boolean {
		return files.some((file) => file.type === FileUploadType.income && file?.classification === 'BANK_STATEMENT');
	}

	static filterIncomeBankStatementFiles(files: IFileUpload[]): IFileUpload[] {
		return files.filter((file) => file.type === FileUploadType.income && file?.classification === 'BANK_STATEMENT');
	}

	/**
	 * each incomeBankStatementCriteria must be included someplace in files criteriaMet
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isIncomeBankStatementCriteriaMet(files: IFileUpload[]): boolean {
		const incomeBankStatementFiles = this.filterIncomeBankStatementFiles(files);
		const criteriaMet = incomeBankStatementCriteria.every((criteria) =>
			incomeBankStatementFiles?.some((file) => file?.ocrInfo?.criteriaMet?.includes(criteria))
		);
		return criteriaMet;
	}

	/**
	 * each incomeBankStatementCriteria must be included someplace in files criteriaMet
	 *
	 * @static
	 * @param {IFileUpload} file
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isIncomeBankStatementCriteriaMetForSingleFile(file: IFileUpload): boolean {
		return incomeBankStatementCriteria.some((criteria) => file?.ocrInfo?.criteriaMet?.includes(criteria));
	}

	/**
	 * one incomeBankStatementCriteria must be included someplace in files criteriaMet
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isIncomeBankStatementCriteriaPartiallyMet(files: IFileUpload[]): boolean {
		const incomeBankStatementFiles = this.filterIncomeBankStatementFiles(files);
		const criteriaMet = incomeBankStatementCriteria.some((criteria) =>
			incomeBankStatementFiles?.some((file) => file?.ocrInfo?.criteriaMet?.includes(criteria))
		);
		return criteriaMet;
	}

	static isRequiredFrontBack(file: IFileUpload): boolean {
		return frontBackList.includes(String(file?.classification));
	}

	static isOptionalFrontBack(file: IFileUpload): boolean {
		return optionalFrontBackList.includes(String(file?.classification));
	}

	/**
	 * Check if the files have a front and back file in first and second position
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static hasFrontBackFiles(files: IFileUpload[]): boolean {
		const [first, second] = files;
		if (first?.createdDate === second?.createdDate) {
			return (
				(first?.ocrInfo?.documentSide === DocumentSideEnum.front &&
					second?.ocrInfo?.documentSide === DocumentSideEnum.back) ||
				(first?.ocrInfo?.documentSide === DocumentSideEnum.back &&
					second?.ocrInfo?.documentSide === DocumentSideEnum.front)
			);
		}
		return false;
	}

	// static hasFrontBackFilesExperiment(files: IFileUpload[]): boolean {
	// 	const uniqueCreateDate = files.reduce((acc, file) => {
	// 		if (file?.type === FileUploadType.identification) {
	// 			const key = `${file.createdDate}`;
	// 			acc[key] = acc[key] ? [...acc[key], file] : [file];
	// 		}
	// 		return acc;
	// 	}, {});
	// 	const pairs = Object.keys(uniqueCreateDate)
	// 		.filter((key) => uniqueCreateDate[key].length === 2)
	// 		.reduce((acc, key) => {
	// 			acc[key] = uniqueCreateDate[key];
	// 			return acc;
	// 		}, {});
	// 	return this.hasFrontBackFilesOld(files);
	// }

	/**
	 * only look at the last files ocr review status
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {IFileUpload[]}
	 * @memberof AutoVerificationUtils
	 */
	static modifyOCRAttention(files: IFileUpload[]): IFileUpload[] {
		const attentionId: [string, string] = [null, null];

		const filesClone = cloneDeep(files);
		if (filesClone?.every((file) => !file.createdDate)) {
			return filesClone;
		}

		const enumOrder = Object.values(FileStatusEnum);
		filesClone?.sort(
			(a, b) => enumOrder.indexOf(a?.ocrInfo?.documentStatus) - enumOrder.indexOf(b?.ocrInfo?.documentStatus)
		);
		const sorted = filesClone?.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1)) || [];
		const [first, second] = sorted;
		if (this.isRequiredFrontBack(first)) {
			if (first?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[0] = first.id;
			}
			if (second?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[1] = second.id;
			}
		} else if (this.isOptionalFrontBack(first) && this.hasFrontBackFiles(sorted)) {
			if (first?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[0] = first.id;
			}
			if (second?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[1] = second.id;
			}
		} else {
			if (first?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[0] = first.id;
				attentionId[1] = first.id;
			}
		}
		return sorted.map((file) => {
			if (
				file.id !== attentionId[0] &&
				file.id !== attentionId[1] &&
				file.ocrInfo?.documentStatus === FileStatusEnum.review
			) {
				file.ocrInfo.documentStatus = FileStatusEnum.complete;
			}
			return file;
		});
	}

	static get30Day(): Date {
		const today = new Date();
		return new Date(today.setDate(today.getDate() - 30));
	}

	static get31Day(): Date {
		const today = new Date();
		return new Date(today.setDate(today.getDate() - 31));
	}

	static get60Day(): Date {
		const today = new Date();
		return new Date(today.setDate(today.getDate() - 60));
	}

	static get30DayString(locale: Locale): string {
		const last30Days = this.get30Day();
		return `${formatDate(last30Days, 'LLL dd', locale)} - ${formatDate(new Date(), 'LLL dd', locale)}`;
	}

	static get60DayString(locale: Locale): string {
		const last60Days = this.get60Day();
		const last31Days = this.get31Day();
		return `${formatDate(last60Days, 'LLL dd', locale)} - ${formatDate(last31Days, 'LLL dd', locale)}`;
	}
}
