import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import SingleSignOnService from 'src/app/core/services/single-sign-on/single-sign-on.service';

@Injectable({
	providedIn: 'root'
})
export class SsoGuard implements CanActivate {
	constructor(private ssoService: SingleSignOnService) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.ssoService.login().pipe(map(() => false));
	}
}
