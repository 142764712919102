<div class="op-expansion-panel" *transloco="let t">
	<op-expansion-panel
		[expanded]="expanded"
		(opened)="opened.emit()"
		id="microbiltBankFunds"
		[titleBold]="true"
		[title]="t('MICROBILT_BANK_ACCOUNT.addBank')"
	>
		<div class="content">
			<op-manually-add-bank id="bankManuallyAddBank" (notSaved)="notSaved.emit($event)" (saved)="saved.emit($event)">
			</op-manually-add-bank>
		</div>
	</op-expansion-panel>
</div>
