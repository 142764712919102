import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { DialogService } from '../dialog/dialog.service';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { IAccountsMeResult } from '../mobile-api/mobile-api.model';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from '../routing/routing.service';
import { SessionStorageService } from '../storage/session-storage.service';

declare let IGLOO: any;

export interface ISsoProfile {
	user: any;
	token: string;
}

@Injectable({
	providedIn: 'root'
})
export default class SingleSignOnService implements OnDestroy {
	constructor(
		private mobileService: MobileApiService,
		private authService: AuthenticationService,
		private dialogService: DialogService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService
	) {
		const storageKeySub = this.sessionStorageService.select(this.storageKey).subscribe({
			next: (value) => {
				this.user = value;
			},
			complete: () => {
				this.user = null;
			}
		});
		this.subscription.add(storageKeySub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private readonly storageKey = 'sso';
	private subscription = new Subscription();

	user: any;
	token: any;

	getUser(): any {
		return this.user;
	}

	reset(): void {
		this.user = null;
		this.token = null;
		this.sessionStorageService.remove(this.storageKey);
	}

	login(): Observable<any> {
		return this.getProfile(false).pipe(
			catchError((err) => {
				this.routingService.route(RoutingPathsEnum.home);
				return of(err);
			}),
			switchMap(({ user, token }) => {
				this.token = token;
				this.sessionStorageService.set(this.storageKey, user);
				return this.mobileService.getMobileHomeSummary(token).pipe(
					switchMap((summary) => {
						let loanAppId = this.hasOpenLoanApp(summary?.eligibilityInfo);
						if (loanAppId) {
							this.authService.updateAuthorizationToken(this.token);
							return this.loanAppService.updateLoanApplication(loanAppId).pipe(
								tap((loanApp) => this.handleLogin(loanApp)),
								catchError((err) => this.dialogService.openErrorDialog(err))
							);
						} else if (this.ableToReApply(summary?.eligibilityInfo)) {
							//start brand new loanApp
							this.authService.updateAuthorizationToken(this.token);
							return of(this.routingService.route(RoutingPathsEnum.home, { queryParams: { sso: 'true' } }));
						} else {
							this.reset();
							return of(this.routingService.route(RoutingPathsEnum.home));
						}
					})
				);
			})
		);
	}

	getProfile(external): Observable<ISsoProfile> {
		return this.mobileService.getCcasAccountsMe().pipe(
			mergeMap((me) => {
				if (me.status === 'SUCCESS' && me.token) {
					return forkJoin({ user: of(this.buildUser(me)), token: of(me.token) });
				} else {
					throw new Error('failure');
				}
			}),
			catchError((err) => {
				if (err.status === 400) {
					this.reset();
				}
				throw err;
			})
		);
	}

	buildUser(data: IAccountsMeResult): any {
		if (data) {
			return {
				firstName: data.firstName,
				lastName: data.lastName,
				middleName: data.middleName,
				maternalName: data.maternalName,
				emailAddress: data.email,
				preferredLanguage: data.preferredLanguage
			};
		}
		return this.user;
	}

	submitLasso(loanId, applicantId): void {
		const bbInfo = IGLOO.getBlackbox();
		const lasso = {
			blackBox: bbInfo.blackbox,
			captureDate: new Date().toISOString(),
			applicantId: String(applicantId),
			loanApplicationId: loanId,
			page: window.location.href
		};
		this.mobileService.lassoCapture(lasso).subscribe();
	}

	handleLogin(loanApp: ILoanApplication): void {
		this.submitLasso(loanApp.id, loanApp.applicants[0].id);
		this.routingService.routeFromLoanApp();
	}

	ableToReApply(eligibilities): boolean {
		return eligibilities?.length > 0 ? eligibilities.find(Boolean)?.ableToReapply : false;
	}

	hasOpenLoanApp(eligibilities): number {
		if (eligibilities?.length > 0) {
			let openLoanApp = eligibilities.find(Boolean)?.openApplication;
			return !isNaN(openLoanApp.applicationId) ? Number.parseInt(openLoanApp.applicationId) : 0;
		} else {
			return 0;
		}
	}
}
