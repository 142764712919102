import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

import { PersonalInfoService } from './personal-info.service';
import { PartnerLeadService } from '../../core/services/partner/partner-lead.service';

/**
 * Guard to check if zipCode and loanId are available for personal-info page.
 *
 * @export
 * @class PersonalInfoGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class PersonalInfoGuard implements CanActivate {
	constructor(
		private router: Router,
		private landingService: LandingService,
		private loanAppService: LoanApplicationService,
		private personalInfoService: PersonalInfoService,
		private partnerLeadService: PartnerLeadService,
		private routingService: RoutingService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.router.url != '/') {
			this.personalInfoService.saveReferringUrl(this.router.url);
		}

		if (
			!route.queryParams.zipCode &&
			!this.landingService.getZipCode() &&
			!this.loanAppService.loanApplicationId &&
			!this.partnerLeadService.partnerLeadId &&
			this.router.navigated
		) {
			// && isPartnerReferral
			this.routingService.route(RoutingPathsEnum.home);
			return false;
		}
		return true;
	}
}
