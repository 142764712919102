import { Component, Input } from '@angular/core';

@Component({
	selector: 'op-loan-distribution-term',
	templateUrl: './loan-distribution-term.component.html',
	styleUrls: ['./loan-distribution-term.component.scss']
})
export class LoanDistributionTermComponent {
	@Input()
	issuingOrganization: string;

	@Input()
	isTermVisible: boolean;
}
