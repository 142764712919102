import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { lastValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	AddressTypeEnum,
	IAddressResult,
	ICassAddress,
	IDebt,
	IDebtResult,
	IdentificationApiService,
	IFinancesResult,
	IGetIncomeResult,
	IIdentification,
	IIdentificationResult,
	IIncomeResult,
	INewApplication,
	ISetAddress,
	ISetAddressResult,
	MobileApiService
} from 'src/app/core/services/mobile-api';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';

@Injectable({
	providedIn: 'root'
})
export class FastTrackService {
	constructor(
		private identificationService: IdentificationApiService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private dateUtilService: DateUtilsService,
		private originationPartnerService: OriginationPartnerService,
		private routingService: RoutingService
	) {}
	/**
	 *
	 *
	 * @return {*}  {Observable<IGetIncomeResult[]>}
	 * @memberof FastTrackService
	 */
	fastTrackIncomeStep(): Observable<IGetIncomeResult[]> {
		return this.mobileService
			.getIncome(this.loanAppService.loanApplicationId)
			.pipe(switchMap((rsp) => this.processIncome(rsp)));
	}

	/**
	 *
	 *
	 * @private
	 * @param {IGetIncomeResult[]} items
	 * @return {*}
	 * @memberof FastTrackService
	 */
	private processIncome(items: IGetIncomeResult[]): Observable<IGetIncomeResult[]> {
		items.forEach(async (income) => {
			await lastValueFrom(this.saveIncome(income));
		});
		return of(items);
	}

	/**
	 *
	 *
	 * @param {IGetIncomeResult} incomeValue
	 * @memberof FastTrackService
	 */
	saveIncome(incomeValue: IGetIncomeResult): Observable<IIncomeResult> {
		const data = {
			incomeSourceType: incomeValue.incomeSourceType,
			incomeDescription: incomeValue.incomeDescription,
			company: incomeValue.company,
			industryType: incomeValue.industryType,
			paymentFrequency: incomeValue.paymentFrequency,
			amount: incomeValue.amount,
			startDate: this.dateUtilService.format(incomeValue.startDate, 'yyyy-MM-dd'),
			hasIncomeDocuments: false,
			id: incomeValue.id
		};

		return incomeValue.id
			? this.mobileService.updateIncome(data, this.loanAppService.loanApplicationId)
			: this.mobileService.setIncome(data, this.loanAppService.loanApplicationId);
	}

	/**
	 *
	 *
	 * @return {*}  {Observable<IIdentificationResult>}
	 * @memberof FastTrackService
	 */
	fastTrackLegalIdentificationStep(): Observable<IIdentificationResult> {
		return this.identificationService
			.getIdentification(this.loanAppService.loanApplicationId)
			.pipe(switchMap((rsp) => this.processIdentification(this.getNewIdentification(isEmpty(rsp) ? null : rsp))));
	}

	/**
	 *
	 *
	 * @private
	 * @param {IIdentificationResult} identity
	 * @return {*}  {IIdentification}
	 * @memberof FastTrackService
	 */
	private getNewIdentification(identity: IIdentificationResult): IIdentification {
		return {
			type: identity.type,
			dateOfBirth: identity.dateOfBirth,
			initialTakeHomePay: identity.initialTakeHomePay,
			issuingCountry: identity.issuingCountry,
			issuingState: identity.issuingState,
			reportsIncomeTax: identity.reportsIncomeTax,
			ssn: identity.ssn,
			itin: identity.itin
		};
	}

	/**
	 *
	 *
	 * @private
	 * @param {IIdentification} identification
	 * @return {*}  {Observable<IIdentificationResult>}
	 * @memberof FastTrackService
	 */
	private processIdentification(identification: IIdentification): Observable<IIdentificationResult> {
		return identification
			? this.identificationService.updateIdentification(identification, this.loanAppService.loanApplicationId)
			: this.identificationService.setIdentification(identification, this.loanAppService.loanApplicationId);
	}

	/**
	 *
	 *
	 * @return {*}  {Observable<IFinancesResult>}
	 * @memberof FastTrackService
	 */
	fastTrackFinancesStep(isHomeAddressCA: boolean, updateHasOtherDebts?: boolean): Observable<IFinancesResult> {
		return this.mobileService.getFinances(this.loanAppService.loanApplicationId).pipe(
			switchMap((rsp) => {
				let financeRsp = Object.assign({}, rsp);
				if (isHomeAddressCA) {
					financeRsp.checkCashingFee = financeRsp.checkCashingFee === null ? 7307 : financeRsp.checkCashingFee;
					financeRsp.usedPaydayService = financeRsp.usedPaydayService === null ? false : financeRsp.usedPaydayService;
					if (updateHasOtherDebts) {
						financeRsp.haveOtherDebt = financeRsp.haveOtherDebt === null ? false : financeRsp.haveOtherDebt;
					}
				}
				return this.processFinance(financeRsp);
			})
		);
	}

	/**
	 *
	 *
	 * @private
	 * @param {IFinancesResult} finance
	 * @return {*}  {Observable<IFinancesResult>}
	 * @memberof FastTrackService
	 */
	private processFinance(finance: IFinancesResult): Observable<IFinancesResult> {
		return this.mobileService.setFinances(finance, this.loanAppService.loanApplicationId);
	}

	/**
	 *
	 *
	 * @return {*}  {Observable<ILoanApplication>}
	 * @memberof FastTrackService
	 */
	fastTrackYourInformationStep(): Observable<ILoanApplication> {
		return this.mobileService.getPhone(this.loanAppService.loanApplicationId).pipe(
			map((rsp) => PhoneUtils.fromPhoneResult(rsp)),
			map((phone: PhoneUtils) => this.formatPersonalInformation(this.loanAppService.getCurrentApplicant(), phone)),
			switchMap((payload: INewApplication) => {
				return this.mobileService.updateApplicant(payload, this.loanAppService.loanApplicationId);
			})
		);
	}

	/**
	 *
	 *
	 * @param {IApplicant} applicant
	 * @param {PhoneUtils} phones
	 * @return {*}  {Observable<INewApplication>}
	 * @memberof FastTrackService
	 */
	formatPersonalInformation(applicant: IApplicant, phones: PhoneUtils): INewApplication {
		return {
			firstName: applicant.firstName,
			middleName: applicant.middleName,
			lastName: applicant.lastName,
			maternalName: applicant.maternalName,
			dateOfBirth: this.dateUtilService.format(applicant.dateOfBirth, 'yyyy-MM-dd'),
			phoneType: phones.getPhoneType(),
			phoneNumber: phones.getPhoneNumber(),
			emailAddress: applicant.emailAddress,
			preferredLanguage: applicant.preferredLanguage,
			ownerDateRange: phones.getPhoneOwnerDateRange(),
			smsAuthorization: phones.isSmsAuthorization,
			tosAgree: true,
			marketingSource: '',
			partnerReferralPartnerSource: '',
			finderNumber: '',
			originationCode: '',
			priorDataAgree: true,
			zipCode: applicant?.homePostalCode
		};
	}

	/**
	 *
	 *
	 * @return {*}  {Observable<any>}
	 * @memberof FastTrackService
	 */
	fastTrackDebtStep(isHomeAddressCA: boolean, hasOtherDebts: any): Observable<IDebtResult[]> {
		if (isHomeAddressCA && hasOtherDebts === null) {
			return this.fastTrackFinancesStep(isHomeAddressCA, true).pipe(switchMap((rsp) => this.fastTrackDebtOnlyStep()));
		} else {
			return this.fastTrackDebtOnlyStep();
		}
	}

	/**
	 *
	 * @returns IDebtResult
	 */
	fastTrackDebtOnlyStep(): Observable<IDebtResult[]> {
		return this.mobileService
			.getDebt(this.loanAppService.loanApplicationId)
			.pipe(switchMap((rsp) => this.processDebts(rsp)));
	}
	/**
	 *
	 *
	 * @private
	 * @param {IDebtResult[]} debts
	 * @return {*}  {Observable<IDebtResult[]>}
	 * @memberof FastTrackService
	 */
	private processDebts(debts: IDebtResult[]): Observable<IDebtResult[]> {
		debts.forEach(async (item) => {
			await lastValueFrom(this.saveDebt(item));
		});
		return of(debts);
	}
	/**
	 *
	 *
	 * @param {IDebtResult} debtValue
	 * @return {*}  {Observable<IDebtResult>}
	 * @memberof FastTrackService
	 */
	saveDebt(debtValue: IDebtResult): Observable<IDebtResult> {
		const data: IDebt = {
			type: debtValue.type,
			originalBalance: debtValue.originalBalance,
			monthlyPayment: debtValue.monthlyPayment,
			id: debtValue.id
		};
		return debtValue.id
			? this.mobileService.updateDebt(data, this.loanAppService.loanApplicationId)
			: this.mobileService.setDebt(data, this.loanAppService.loanApplicationId);
	}

	/**
	 *
	 *
	 * @return {*}  {Observable<IAddressResult[]>}
	 * @memberof FastTrackService
	 */
	fastTrackAddressStep(): Observable<IAddressResult[]> {
		return this.mobileService
			.getAddresses(this.loanAppService.loanApplicationId)
			.pipe(switchMap((rsp) => this.processAddresses(rsp)));
	}

	/**
	 *
	 *
	 * @private
	 * @param {IAddressResult[]} addresses
	 * @return {*}  {Observable<IAddressResult[]>}
	 * @memberof FastTrackService
	 */
	private processAddresses(addresses: IAddressResult[]): Observable<IAddressResult[]> {
		addresses.forEach(async (item) => {
			const setAddress = { ...this.createAddressObject(item.type, item) } as ISetAddress;
			await lastValueFrom(this.saveAddress(setAddress, item?.id?.toString()));
		});
		return of(addresses);
	}

	/**
	 *
	 *
	 * @param {ISetAddress} address
	 * @param {string} addressId
	 * @return {*}  {Observable<ISetAddressResult>}
	 * @memberof FastTrackService
	 */
	saveAddress(address: ISetAddress, addressId: string): Observable<ISetAddressResult> {
		return addressId
			? this.mobileService.updateAddresses(address, addressId, this.loanAppService.loanApplicationId)
			: this.mobileService.setAddresses(address, this.loanAppService.loanApplicationId);
	}

	/**
	 *
	 *
	 * @param {string} addressType
	 * @param {IAddressResult} address
	 * @return {*}  {ICassAddress}
	 * @memberof FastTrackService
	 */
	createAddressObject(addressType: string, address: IAddressResult): ICassAddress {
		return {
			streetAddress1: address?.streetAddress1,
			streetAddress2: address?.streetAddress2 || '',
			city: address?.city,
			state: address?.state,
			postalCode: address?.postalCode,
			country: 'US',
			type: addressType,
			currentAddress: addressType === AddressTypeEnum.home ? String(true) : String(false),
			originationCode: this.originationPartnerService.getOriginationCode() || null,
			moveInDate: address.moveInDate,
			county: address.county
		};
	}

	/**
	 *
	 *
	 * @memberof FastTrackService
	 */
	fastTrackLegalDisclosureStep(): void {
		this.mobileService
			.updateDataComplete(this.loanAppService.loanApplicationId)
			.pipe(
				map((updatedLoanApp: ILoanApplication) => {
					return updatedLoanApp?.applicationStatus;
				})
			)
			.subscribe({
				next: (applicationStatus) => {
					if (
						ApplicationStatusEnum.started === applicationStatus ||
						ApplicationStatusEnum.notApproved === applicationStatus
					) {
						this.routingService.routeByStatus();
					} else {
						this.routingService.route(RoutingPathsEnum.offerStatus);
					}
				}
			});
	}
}
