import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { MobileApiService } from '../mobile-api/mobile-api.service';

/**
 * Get the google places scripts from the BE and load them.
 *
 * @export
 * @class GoogleApiPlacesService
 */
@Injectable({
	providedIn: 'root'
})
export class GoogleApiPlacesService {
	private readonly googleApiSource = new ReplaySubject<boolean>();
	readonly googleApi$ = this.googleApiSource.asObservable();

	constructor(private mobileService: MobileApiService) {
		this.initGooglePlacesAutoComplete();
		this.mobileService.getGoogleApiPlaces('initGooglePlacesAutoComplete').subscribe({
			next: (rsp) => {
				try {
					window.eval(rsp);
				} catch (err) {
					console.error(err);
				}
			}
		});
	}

	initGooglePlacesAutoComplete() {
		window['initGooglePlacesAutoComplete'] = () => {
			this.googleApiSource.next(true);
		};
	}

	getAutoComplete(element, options): any {
		return new window['google'].maps.places.Autocomplete(element, options);
	}
}
