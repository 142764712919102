<div class="op-header-commons-pro pre-qualify" [formGroup]="parent" *transloco="let t">
	<div mat-dialog-title *transloco="let t">
		<div class="op-right op-mb-8">
			<a
				mat-icon-button
				id="incomeAddCloseBtn"
				[mat-dialog-close]="false"
				(click)="restoreOriginalValue()"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</a>
		</div>
		<div class="op-label-font income-title-delete">
			<div id="income-label" *ngIf="!parent?.controls['id'].value">{{ t('PRE_QUALIFY.Income.AddIncome.title') }}</div>
			<div id="income-label" *ngIf="parent?.controls['id'].value">
				{{ t('PRE_QUALIFY.Income.AddIncome.updateTitle') }}
			</div>
			<div class="op-right delete-income" *ngIf="parent?.controls['id'].value">
				<a mat-flat-button color="link-md" id="incomeAddDeleteBtn" (click)="confirmDelete(parent, index)">
					<span id="income-label-delete">{{ t('PRE_QUALIFY.Income.AddIncome.delete') }}</span>
				</a>
			</div>
		</div>
	</div>
	<mat-dialog-content class="income-source-dialog-content">
		<div class="op-mt-20" [ngClass]="{ 'op-mb-20': isIncomeSourceOther }">
			<mat-form-field class="form-element income-input" appearance="outline" [hideRequiredMarker]="true">
				<mat-label>{{ t('PRE_QUALIFY.Income.source') }} </mat-label>
				<mat-select formControlName="incomeSourceType" id="incomeSourceType" (selectionChange)="onChange(parent)">
					<mat-option
						*ngFor="let incomeType of incomeTypeList; let i = index"
						id="{{ id + '_incomeType_' + incomeType.key }}"
						[value]="incomeType?.key"
					>
						{{ incomeType?.text }}
					</mat-option>
				</mat-select>
				<mat-hint *ngIf="isIncomeSourceOther">{{ t('PRE_QUALIFY.Income.otherTip') }}</mat-hint>
			</mat-form-field>
		</div>

		<div *ngIf="parent?.controls['incomeSourceType'].value">
			<mat-form-field
				*ngIf="parent?.controls['incomeSourceType'].value === 1700"
				class="form-element income-input"
				appearance="outline"
				[hideRequiredMarker]="true"
			>
				<mat-label>{{ t('PRE_QUALIFY.Income.companyName') }}</mat-label>
				<input matInput type="text" name="company" id="{{ id + '_company' }}" formControlName="company" />
			</mat-form-field>

			<mat-form-field
				*ngIf="parent?.controls['incomeSourceType'].value === 1705"
				class="form-element income-input"
				appearance="outline"
				[hideRequiredMarker]="true"
			>
				<mat-label>{{ t('PRE_QUALIFY.Income.description') }}</mat-label>
				<input
					matInput
					type="text"
					name="incomeDescription"
					id="{{ id + '_incomeDescription' }}"
					formControlName="incomeDescription"
				/>
			</mat-form-field>

			<mat-form-field
				*ngIf="
					parent?.controls['incomeSourceType'].value === 1700 || parent?.controls['incomeSourceType'].value === 1701
				"
				class="form-element income-input"
				appearance="outline"
				[hideRequiredMarker]="true"
			>
				<mat-label>{{ t('PRE_QUALIFY.Income.industry') }} </mat-label>
				<mat-select name="industryType" id="{{ id + '_industryType' }}" formControlName="industryType">
					<mat-option
						*ngFor="let industry of industryTypeList; let i = index"
						id="{{ id + '_industry_' + industry.key }}"
						[value]="industry.key"
					>
						{{ industry.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="op-mb-40">
				<div class="op-form-title">
					<div *ngIf="parent?.controls['incomeSourceType'].value === 1700">
						{{ t('PRE_QUALIFY.Income.howLongAtJob') }}
					</div>
					<div *ngIf="!parent?.controls['incomeSourceType'].value === 1700">
						{{ t('PRE_QUALIFY.Income.howLongOther') }}
					</div>
				</div>

				<op-date-input
					name="startDate"
					id="{{ id + '_startDate' }}"
					formControlName="startDate"
					showFormat="mmyyyy"
					hideRequiredMarker="true"
				></op-date-input>
				<mat-error
					id="{{ id + '_startDayValidation' }}"
					*ngIf="parent.controls['startDate'].errors?.futureDate && parent.controls['startDate'].dirty"
				>
					{{ t('PRE_QUALIFY.Income.invalidDate') }}
				</mat-error>
			</div>
			<div class="op-mb-40">
				<div class="op-form-title">
					<div>
						{{ t('PRE_QUALIFY.Income.howOften') }}
					</div>
				</div>
				<mat-form-field
					class="form-element income-input income-frequency"
					appearance="outline"
					[hideRequiredMarker]="true"
				>
					<mat-label>{{ t('PRE_QUALIFY.Income.frequency') }} </mat-label>
					<mat-select name="paymentFrequency" id="{{ id + '_paymentFrequency' }}" formControlName="paymentFrequency">
						<mat-option
							*ngFor="let frequency of incomeFrequencyList; let i = index"
							id="{{ id + '_frequency_' + frequency.key }}"
							[value]="frequency.key"
						>
							{{ frequency.text }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="op-mb-40">
				<div class="op-form-title">
					<div>
						{{ t('PRE_QUALIFY.Income.amount') }}
					</div>
				</div>
				<mat-form-field class="form-element income-input" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('PRE_QUALIFY.Income.amount') }}</mat-label>
					<input
						matInput
						type="text"
						inputmode="numeric"
						placeholder="$"
						mask="separator"
						thousandSeparator=","
						prefix="$"
						name="amount"
						id="{{ id + '_amount' }}"
						formControlName="amount"
						(blur)="onChange()"
					/>
					<mat-hint>{{ t('PRE_QUALIFY.Income.sourceNetIncomeInfo') }}</mat-hint>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions *ngIf="parent?.controls['id'].value" align="center">
		<button
			class="buttons form-element cancel-button"
			mat-flat-button
			color="secondary"
			id="{{ id + '_restoreIncomeBtn' }}"
			type="button"
			[mat-dialog-close]="true"
			[opTagClick]="'Income save'"
			(click)="restoreOriginalValue()"
		>
			{{ t('PRE_QUALIFY.Income.AddIncome.noChanges') }}
		</button>
		<button
			class="buttons form-element"
			mat-flat-button
			color="primary"
			name="saveIncome"
			id="{{ id + '_saveIncomeBtn' }}"
			type="button"
			[opTagClick]="'Income save'"
			[disabled]="parent.invalid"
			(click)="saveIncome(parent)"
		>
			{{ t('PRE_QUALIFY.Income.AddIncome.update') }}
		</button>
	</mat-dialog-actions>
	<mat-dialog-actions *ngIf="!parent?.controls['id'].value" align="center">
		<button
			mat-flat-button
			color="primary"
			name="saveIncome"
			id="{{ id + '_saveIncomeBtn' }}"
			type="button"
			[opTagClick]="'Income save'"
			[disabled]="parent.invalid"
			(click)="saveIncome(parent)"
		>
			{{ t('PRE_QUALIFY.Income.save') }}
		</button>
	</mat-dialog-actions>
</div>
