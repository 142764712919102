<div class="next-steps" *transloco="let t">
	<div class="op-header-3-font" [innerHTML]="t(learnMoreTitle)"></div>
	<ul>
		<li id="titleRequirementTextId" *ngIf="isSpl">
			<div>
				{{ t(titleRequirementText, { vehicleMakeModel: vehicleMakeModel }) }}
			</div>
		</li>

		<li *ngIf="!isSpl">
			<div>
				<span *ngIf="!isPartnerCashDisbursement">{{ t('TERMS_CONFIRMED.NEXT_STEPS.step1') }}</span>
				<span *ngIf="isPartnerCashDisbursement">
					{{ t('TERMS_CONFIRMED.PARTNER_STORE.gotoPartnerLocation' | transloco: { partner: partnerBrand }) }}
				</span>
			</div>
		</li>
		<li>
			<div>
				<span *ngIf="!isPartnerCashDisbursement">{{ t('TERMS_CONFIRMED.NEXT_STEPS.step2') }}</span>
				<span *ngIf="isPartnerCashDisbursement">{{ t('TERMS_CONFIRMED.PARTNER_STORE.reviewAndSign') }}</span>
			</div>
		</li>
		<li *ngIf="!isPartnerCashDisbursement">
			<div>{{ t('TERMS_CONFIRMED.NEXT_STEPS.step3') }}</div>
		</li>
	</ul>

	<div *ngIf="!isPartnerCashDisbursement" class="op-center op-mt-20">
		<button
			mat-flat-button
			color="primary"
			id="findLocationBtn"
			type="button"
			(click)="onFindLocation()"
			[opTagClick]="'Find Locations'"
		>
			{{ t('TERMS_CONFIRMED.findLocations') }}
		</button>
	</div>

	<div id="splFooter" *ngIf="isSpl" class="op-mt-20 op-left op-body-2-font">
		<div class="op-dark-grey op-mb-20">{{ t('TERMS_CONFIRMED.callAgentAboutQuestions') }}</div>
		<a mat-flat-button color="link-md" href="tel:888-564-0084" id="phoneNumber" [opTagClick]="'888-564-0084'">
			<span class="op-body-1-font op-mb-20">{{ t('TERMS_CONFIRMED.agentPhoneNumber') }}</span>
		</a>
		<div class="collateral-warning">{{ t('TERMS_CONFIRMED.collateralWarning') }}</div>
	</div>
</div>
