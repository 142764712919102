<div class="switch-offer op-mt-20">
	<div *ngIf="areAllProductsApproved; else backToOtherOffer">
		<span class="op-body-1-bold-font">{{ 'ESIGN_CONFIRM_TERM.exploreOtherOffer' | transloco }}</span>
		<br />
		<button
			mat-flat-button
			color="link-text-b1"
			id="switchOfferLink"
			(click)="switchToOtherOffer()"
			[opTagClick]="'Explore your other offer'"
		>
			<mat-icon svgIcon="op-caretLeft"></mat-icon>
			<span *ngIf="isSPL">
				{{ 'ADJUST_TERMS.LOAN_TYPES.personalLoan' | transloco }}
			</span>
			<span *ngIf="!isSPL">
				{{ 'ADJUST_TERMS.LOAN_TYPES.securedPersonalLoan' | transloco }}
			</span>
		</button>
	</div>

	<ng-template #backToOtherOffer>
		<a
			mat-flat-button
			color="link-no-dec"
			href=""
			id="backToOtherOffer"
			onclick="return false"
			(click)="switchToOtherOffer()"
			[opTagClick]="'Back To Other Offers'"
		>
			<mat-icon svgIcon="op-caretLeft"></mat-icon>
			<span class="op-dark-green">{{ 'ADJUST_TERMS.backToOtherOffers' | transloco }}</span>
		</a>
	</ng-template>
</div>
