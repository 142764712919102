import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { noop } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IAdditionalInfo } from 'src/app/core/services/mobile-api/mobile-api.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';

import { LoanApplicationService } from '../../core/services/loan-application/loan-application.service';
import { MobileApiService } from '../../core/services/mobile-api/mobile-api.service';
import { PreQualifyService } from 'src/app/pre-qualify/pre-qualify.service';

@Component({
	selector: 'op-pre-qualify-additional-information',
	templateUrl: './additional-information.component.html',
	styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {
	@Input() returnToFTR: string;
	@Output()
	saveToFTR = new EventEmitter<any>();
	constructor(
		private formBuilder: FormBuilder,
		private mobileService: MobileApiService,
		private route: ActivatedRoute,
		private loanAppService: LoanApplicationService,
		private metadataService: MetadataService,
		private routingService: RoutingService,
		private progressBarService: ProgressBarService,
		private preQualifyService: PreQualifyService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	purposeList$: Observable<IMetadata[]>;
	isCAPostalCode: boolean = false;

	private subscription = new Subscription();

	ngOnInit(): void {
		this.progressBarService.setProgressBarValue(
			this.preQualifyService.skipIdentityScreen ? this.route.snapshot.data?.step - 1 : this.route.snapshot.data?.step
		);

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				map((loanApp) => {
					const postalCode = Number(this.loanAppService.getCurrentApplicant()?.homePostalCode);
					this.isCAPostalCode = AddressUtils.isCaliforniaPostalCode(postalCode);
					return loanApp?.loanPurpose;
				})
			)
			.subscribe({
				next: (purpose) => {
					if (Boolean(purpose)) {
						this.formGroup.get('loanPurpose').setValue(purpose);
					}
				}
			});
		this.subscription.add(loanAppSub);

		this.purposeList$ = this.metadataService.select(MetadataEnum.LoanPurpose);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb): void {
		this.formGroup = fb.group({
			loanPurpose: [null, [Validators.required]]
		});
	}

	navigate(): void {
		this.routingService.route(RoutingPathsEnum.preQualifyIncome);
	}

	onFocus(): void {
		this.formGroup.get('loanPurpose').errors?.required ? this.formGroup.get('loanPurpose').reset() : noop();
	}

	onSubmit(post: IAdditionalInfo): void {
		const loanApp = this.loanAppService.getLoanApp();
		post.referralCode = post.referralCode || '';
		this.mobileService.setAdditionalInfo(post, loanApp.id /*, loanApp.applicantIndex*/).subscribe({
			next: (resp) => {
				this.navigate();
			}
		});
	}
}
