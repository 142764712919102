import { Component, OnInit } from '@angular/core';
import {
	AbstractControl,
	AbstractControlOptions,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';
import { isEmpty } from 'lodash';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	IPaymentRemindersOptionsResult,
	IPaymentRemindersResult,
	IPhoneResult,
	PaymentRemindersEnum,
	ReceiptsEnum
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';

@Component({
	selector: 'op-esign-event-signing-completed',
	templateUrl: './signing-completed.component.html',
	styleUrls: ['./signing-completed.component.scss']
})
export class EsignEventSigningCompletedComponent implements OnInit {
	constructor(
		private formBuilder: FormBuilder,
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private routingService: RoutingService
	) {
		this.createForm(this.formBuilder);
	}
	formGroup: FormGroup;

	paymentRemindersOptions: IPaymentRemindersOptionsResult;
	paymentReminders: IPaymentRemindersResult;

	phoneUtils: PhoneUtils;

	paymentRemindersSaved: boolean;
	initialized = false;

	ngOnInit(): void {
		this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				switchMap(() => this.mobileService.getPhone(this.loanAppService.loanApplicationId)),
				filter(Boolean),
				tap((phone: IPhoneResult) => {
					this.phoneUtils = PhoneUtils.fromPhoneResult(phone);
					if (this.phoneUtils?.hasSinglePhoneType() && this.phoneUtils.getHomePhone()) {
						this.paymentRemindersOptions = { homePhoneNumber: this.phoneUtils.getHomePhone() };
					}
				}),
				switchMap(() => this.mobileService.getPaymentRemindersOptions(this.loanAppService.loanApplicationId)),
				tap((reminderOptions) => (this.paymentRemindersOptions = this.paymentRemindersOptions || reminderOptions)),
				switchMap((reminderOptions) => {
					return isEmpty(reminderOptions.paymentReminders)
						? of(this.routingService.route(RoutingPathsEnum.loanCompleted))
						: this.mobileService.getPaymentReminders(this.loanAppService.loanApplicationId);
				}),
				filter(Boolean),
				tap((reminder: IPaymentRemindersResult) => (this.paymentReminders = reminder))
			)
			.subscribe({
				next: () => {
					this.updateFromBe(this.paymentReminders);
					this.initialized = true;
				}
			});
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group(
			{
				paymentReminders: [null],
				receipts: [null],
				mobilePhoneNumber: ['']
			},
			{
				validator: [this.required('paymentReminders', 'receipts')]
			} as AbstractControlOptions
		);
	}

	updateFromBe(reminders: IPaymentRemindersResult): void {
		this.formGroup.patchValue({
			paymentReminders: reminders.paymentReminders,
			...(reminders?.receipts && { receipts: reminders.receipts }),
			mobilePhoneNumber: reminders.mobilePhoneNumber
		});
	}

	required(paymentReminders: string, receipts: string): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
			const paymentRemindersCtrl = formGroup.get(paymentReminders);
			const receiptsCtrl = formGroup.get(receipts);

			if (paymentRemindersCtrl.value || receiptsCtrl.value) {
				return null;
			} else {
				return { required: true };
			}
		};
	}

	addDescribedByForReceipt(receipts_option): string {
		if (receipts_option !== 'RACH_RECEIPTS_SMS') {
			return;
		}

		return 'disclaimer-sms';
	}

	continue(): void {
		this.routingService.route(RoutingPathsEnum.loanCompleted);
	}

	onSubmit(): void {
		const reminders = Object.assign({}, this.formGroup.value);

		if (this.paymentReminders.rachEnabled) {
			reminders.paymentReminders = reminders.paymentReminders
				? reminders.paymentReminders
				: PaymentRemindersEnum.rachRemindersNone;
			reminders.receipts = reminders.receipts ? reminders.receipts : ReceiptsEnum.rachRReceiptsNone;
		}

		this.mobileService.updatePaymentReminders(reminders, this.loanAppService.loanApplicationId).subscribe({
			next: () => {
				this.routingService.route(RoutingPathsEnum.loanCompleted);
			},
			error: (err) => {
				this.showErrorPage(err);
			}
		});
	}

	private showErrorPage(data): void {
		const error = (Array.isArray(data.error) && data.error.find(Boolean)) || {};
		if (error.id === 'device.verify.failed') {
			return;
		}
		this.routingService.route(RoutingPathsEnum.esignShowError, { queryParams: { errorMsg: error.msg } });
	}
}
