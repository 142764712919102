import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { find } from 'lodash';
import { IAchBankAccount } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-plaid-account-selection-list',
	templateUrl: './plaid-account-selection-list.component.html',
	styleUrls: ['./plaid-account-selection-list.component.scss']
})
export class PlaidAccountSelectionListComponent implements AfterViewInit {
	@Input()
	@opRequired()
	id: string;

	private _accountList: IAchBankAccount[];
	@Input()
	get accountList(): IAchBankAccount[] {
		return this._accountList;
	}
	set accountList(list: IAchBankAccount[]) {
		this._accountList = list;
	}

	@Output() accountSelected = new EventEmitter<number>();
	@Output() addBank = new EventEmitter();

	ngAfterViewInit(): void {
		const selectedAccount = find(this._accountList, { selected: true });
		if (selectedAccount) {
			this.accountSelected.emit(selectedAccount.id);
		}
	}

	onAddBank(): void {
		this.addBank.emit();
	}

	onAccountSelectionChange(event: MatRadioChange): void {
		this.accountSelected.emit(event.value);
	}
}
