import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IIdentificationResult } from 'src/app/core/services/mobile-api/identification-api/identification-api.model';

import { SummarySection } from './section.model';

/**
 * stores personal-info summary information
 *
 * @param {IApplicant} applicant
 * @param {IIdentificationResult} id
 * @param {IMetadata[]} idType
 * @param {IMetadata[]} country
 * @param {IMetadata[]} usStates
 * @param {string} doNotHave
 * @returns {SummarySection}
 */
export const identificationSection = (
	applicant: IApplicant,
	id: IIdentificationResult,
	idType: IMetadata[],
	country: IMetadata[],
	usStates: IMetadata[],
	doNotHave: string
): SummarySection => {
	return {
		section: 'personalFinance',
		header: 'SUMMARY.confirmIdentity',
		route: '/identification',
		fields: [
			{
				title: 'SUMMARY.idType',
				value: idType.find((item) => item.key === id.type)?.text
			},
			{
				title: 'SUMMARY.homeCountry',
				value: country.find((item) => item.key === id.issuingCountry)?.text
			},
			{
				title: 'SUMMARY.issuingState',
				value: usStates.find((item) => item.key === id.issuingState)?.text
			},
			{
				title: applicant.last4Ssn ? 'IDENTIFICATION.ssn' : applicant.last4Ssn ? 'IDENTIFICATION.itin' : 'SSN / ITIN',
				titleValue: applicant.last4Ssn || applicant.last4Itin ? false : true,
				value: applicant.last4Ssn
					? '***-**-' + applicant.last4Ssn
					: applicant.last4Itin
					? '***-**-' + applicant.last4Itin
					: doNotHave
			}
		]
	};
};
