<div class="status-other" *transloco="let t">
	<op-masthead [title]="t('STATUS.appSummary')" [subTitle]="t(subTitle)"></op-masthead>
	<op-content-container>
		<div
			*ngIf="newApplicationStartDate"
			translate-values="{newApplicationStartDate: newApplicationStartDate}"
			[innerHTML]="innerHTML"
			="t(body)"
		></div>
		<div role="presentation" *ngIf="!newApplicationStartDate" [innerHTML]="t(body)"></div>
	</op-content-container>
</div>
