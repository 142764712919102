import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, map, skip, skipWhile, tap } from 'rxjs/operators';
import { LoanApplicationService } from '../loan-application/loan-application.service';

import { IMetadata, IMetadataResult, MetadataApiService } from '../mobile-api';
import { MetadataEnum } from './metadata.model';

/**
 * MetadataService to handle retrieving and updating metadata from the BE.
 * Listeners are updated after a language change.
 *
 *
 * @export
 * @class MetadataService
 */
@Injectable({
	providedIn: 'root'
})
export class MetadataService {
	private metadataSubjectMap = {};

	private currentLanguage = 'en';

	constructor(
		private metadataApiService: MetadataApiService,
		private translocoService: TranslocoService,
		private loanAppService: LoanApplicationService
	) {
		// generate BehaviorSubject and observable for each metadata select.
		Object.keys(MetadataEnum).forEach((item) => {
			const save = {};
			save['source'] = new BehaviorSubject<IMetadataResult>(null);
			save['metadata$'] = save['source'].asObservable();
			this.metadataSubjectMap[item] = save;
		});

		// On Language change update any metadata select that has a subscriber
		this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.currentLanguage = lang;
				this.checkSubscribers(this.currentLanguage);
			}
		});
	}

	/**
	 * Look for any subscribers and make a new request with the new lang
	 *
	 * @param {string} language
	 * @memberof MetadataService
	 */
	private checkSubscribers(language: string): void {
		for (const item in this.metadataSubjectMap) {
			if (this.metadataSubjectMap[item].source.observers.length) {
				this.getSelect(MetadataEnum[item], language, this.metadataSubjectMap[item].state).subscribe();
			}
		}
	}

	/**
	 * Select the metadata to subscribe to and refresh its value from the backend.
	 * When the language changes the values will get updated.
	 *
	 * @param {MetadataEnum} name
	 * @param {boolean} [update]
	 * @returns {Observable<IMetadata[]>}
	 * @memberof MetadataService
	 */
	select(name: MetadataEnum, state: string = null): Observable<IMetadata[]> {
		this.metadataSubjectMap[name].state = state ? state : undefined;
		return this.metadataSubjectMap[name].metadata$.pipe(
			concatMap((value, index) => (index === 0 ? this.getSelect(name, this.currentLanguage, state) : of(value))),
			skip(1),
			skipWhile((v) => !v),
			map((v: IMetadataResult) => v.values)
		);
	}

	/**
	 * set the next value of the Behavior subject
	 *
	 * @param {MetadataEnum} name
	 * @param {*} state
	 * @memberof MetadataService
	 */
	private set(name: MetadataEnum, state: any): void {
		this.metadataSubjectMap[name].source.next(state);
	}

	/**
	 * get a new value from backend and set it to the behavior subject
	 *
	 * @param {MetadataEnum} name
	 * @param {string} [lang]
	 * @returns
	 * @memberof MetadataService
	 */
	private getSelect(name: MetadataEnum, lang?: string, state?: string): Observable<IMetadataResult> {
		let metaParams = {
			type: name,
			lang: lang,
			random: String(name === MetadataEnum.LoanPurpose)
		};

		if (state) {
			metaParams['state'] = state;
		}

		if (name === MetadataEnum.PaymentFrequency) {
			metaParams['loanApplicationId'] = this.loanAppService.loanApplicationId;
		}

		return this.metadataApiService.metadataSelect(metaParams).pipe(tap((next) => this.set(name, next)));
	}
}
