import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Dialog to show when user has been idle.
 *
 * @export
 * @class IdleTimeoutDialogComponent
 */
@Component({
	selector: 'op-idle-timeout-dialog',
	templateUrl: './idle-timeout-dialog.component.html',
	styleUrls: ['./idle-timeout-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdleTimeoutDialogComponent {
	constructor(public dialogRef: MatDialogRef<IdleTimeoutDialogComponent>) {}

	onSubmit() {
		this.dialogRef.close(true);
	}

	close() {
		this.dialogRef.close(false);
	}
}
