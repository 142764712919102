<div
	class="message-dialog"
	[ngClass]="data?.panelClass"
	aria-labelledby="messageDialogTitle"
	*transloco="let t"
	cdkTrapFocus
	[cdkTrapFocusAutoCapture]="true"
>
	<div mat-dialog-title>
		<div class="op-right">
			<button
				mat-icon-button
				id="messageDialogCloseBtn"
				[mat-dialog-close]="data.closeAction"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close" alt="{{ t('GLOBAL.close') }}"></mat-icon>
			</button>
		</div>
		<div [ngClass]="{ 'op-icon-align-header-1-font': data.icon }" class="op-black-bold op-header-1-font">
			<mat-icon *ngIf="data.icon" svgIcon="{{ data.icon }}"></mat-icon>
			<span id="messageDialogTitle" *ngIf="data.title" [innerHTML]="data.title"></span>
		</div>
	</div>
	<form id="confirmForm" [formGroup]="confirmForm">
		<mat-checkbox formControlName="confirm">
			<div
				mat-dialog-content
				id="messageDialogContent"
				class="op-micro-font"
				*ngIf="data.message"
				[innerHTML]="data.message"
			></div>
		</mat-checkbox>
	</form>
	<p></p>
	<div class="op-center" [ngClass]="{ 'cancel-btn': data.cancelText }" mat-dialog-actions>
		<button
			class="confirm"
			mat-flat-button
			color="primary-small"
			id="messageDlgSubmitBtn"
			(click)="onSubmit()"
			cdkFocusInitial
			[disabled]="!confirmForm.value.confirm"
			aria-describedby="messageDialogContent"
			[opTagClick]="data.title"
		>
			{{ data.confirmText ? data.confirmText : ('GLOBAL.ok' | transloco) }}
		</button>
		<button
			*ngIf="data.cancelText"
			class="confirm"
			mat-flat-button
			color="secondary-small"
			id="messageDlgCloseBtn"
			(click)="close()"
			aria-describedby="messageDialogContent"
		>
			{{ data.cancelText ? data.cancelText : ('GLOBAL.ok' | transloco) }}
		</button>
	</div>
</div>
