import { Pipe, PipeTransform } from '@angular/core';

/**
 * Displays phone number 1-000-000-0000
 *
 * @export
 * @class PhoneNumberPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opPhoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
	transform(phone: string, ...args: unknown[]): unknown {
		const parts = [];
		parts.push(phone.substr(0, 3));
		parts.push(phone.substr(3, 3));
		parts.push(phone.substr(6));
		return '1-' + parts.join('-');
	}
}
