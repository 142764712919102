import { Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentStatusCriteriaEnum } from 'src/app/core/services/mobile-api';

import { collateFileInfo, CollationService } from '../../collation.service';

@Component({
	selector: 'op-collation-selection',
	templateUrl: './collation-selection.component.html',
	styleUrls: ['./collation-selection.component.scss']
})
export class CollationSelectionComponent {
	@Input() criteria: DocumentStatusCriteriaEnum;
	@Input() disabled: boolean;

	fileList$: Observable<collateFileInfo[]>;
	requiredFileType = 'image/jpg,image/jpeg,image/png,application/pdf';

	constructor(private collationService: CollationService) {
		this.fileList$ = this.collationService.collation$;
	}

	@ViewChild('fileUpload') fileUpload: any;

	onFileSelected(event: any): void {
		const file: File = event.target.files[0];
		if (file) {
			this.collationService.addFile(file, this.criteria);
		}
		event.target.value = '';
	}

	onAddMore(): void {
		if (!this.disabled) {
			this.fileUpload.nativeElement.click();
		}
	}

	onKeydown(event: KeyboardEvent): void {
		if (event.key === 'Enter' || event.key === ' ') {
			this.onAddMore();
		}
	}

	onRemoveFile(index: number): void {
		this.collationService.removeFile(index);
	}

	onKeyDownRemoveFile(event: KeyboardEvent, index: number): void {
		if (event.key === 'Enter' || event.key === ' ') {
			this.onRemoveFile(index);
		}
	}
}
