import { IDisbursementOptionsEntity } from '../loan-application.model';

export interface IFundingInfo {
	state: string;
	coverageArea: string;
	distance: string;
	isStorelessState: boolean /* cspell: disable-line */;
	isOver50miles?: boolean;
	onlineDisbursementUnavailable: boolean;
	disbursementOptions?: IDisbursementOptionsEntity[] | null;
}

export enum FundingCoverageArea {
	withInRadius = 'WITHIN_RADIUS',
	outsideRadius = 'OUTSIDE_RADIUS'
}
