import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { SharedModule } from '../shared/shared.module';
import { AutoPayAddBankComponent } from './esign-auto-pay/auto-pay-add-bank/auto-pay-add-bank.component';
import { EsignAutoPayComponent } from './esign-auto-pay/esign-auto-pay.component';
import { EsignConfirmTermComponent } from './esign-confirm-term/esign-confirm-term.component';
import { EsignSignDocumentComponent } from './esign-sign-document/esign-sign-document.component';
import { TermsRoutingModule } from './terms-routing.module';
import { ManuallyAddBankForteComponent } from '../shared/components/manually-add-bank-forte/manually-add-bank-forte.component';

/**
 * eSign and confirm term pages
 *
 * @export
 * @class TermsModule
 */
@NgModule({
	declarations: [
		AutoPayAddBankComponent,
		EsignAutoPayComponent,
		EsignConfirmTermComponent,
		EsignSignDocumentComponent,
		ManuallyAddBankForteComponent
	],
	imports: [CommonModule, SharedModule, TranslocoModule, TermsRoutingModule]
})
export class TermsModule {}
