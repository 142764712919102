import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { distinctUntilChanged, map, Observable, of, pairwise, startWith } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	FileSideEnum,
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { FileStatusEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { IMessageDialogData } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

import AutoVerificationUtils, { frontBackList, optionalFrontBackList } from '../auto-verification-utils';
import { AutoVerificationService, DocBadgeEnum, IFileUploadExtended } from '../auto-verification.service';
import { BaseCategoryComponent, ProofStatusEnum } from '../base-category/base-category.component';
import { DocumentStatusEventEnum, IDocumentStatusEvent } from '../document-status/document-status.component';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { IssuingCountryPipe } from './issuing-country.pipe';

@Component({
	selector: 'op-proof-of-identification',
	templateUrl: './proof-of-identification.component.html',
	styleUrls: ['./proof-of-identification.component.scss']
})
export class ProofOfIdentificationComponent extends BaseCategoryComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.identification;
	readonly storageKey = 'proofOfIdentification';
	readonly metaClassification = MetadataEnum.IDDocumentClassification;

	public docBadgeEnum: typeof DocBadgeEnum = DocBadgeEnum;
	public fileUploadStatusEnum: typeof FileUploadStatusEnum = FileUploadStatusEnum;

	countryList$: Observable<IMetadata[]>;

	documentClassificationList$: Observable<IMetadata[]>;
	showFrontBack: boolean = false;
	canCancel: boolean = true;
	submitDisabled: boolean = true;
	showContinueMessage: boolean = false;
	reviewFileMessageCount: number = 0;

	readonly KEY_USA = 'US';
	newCountrySelected = false;

	showTwoSidedOther: boolean = false;
	frontBackOptional: boolean = false;
	optionalDisabled: boolean = true;

	singleFileFront: IFileUploadExtended;
	singleFileBack: IFileUploadExtended;

	formGroup: FormGroup;

	@ViewChild(DocumentUploadComponent) docUpload: DocumentUploadComponent;
	@ViewChild('continueBtn') continueBtn: ElementRef;

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService,
		autoVerificationService: AutoVerificationService,
		activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private languageService: LanguageService,
		private dialogService: DialogService,
		private issuingCountryPipe: IssuingCountryPipe
	) {
		super(
			fileUploadService,
			sessionStorageService,
			routingService,
			metadataService,
			loanAppService,
			autoVerificationService,
			activatedRoute
		);
		this.createForm(this.formBuilder);
	}

	ngOnInit(): void {
		super.ngOnInit();

		const routeSub = this.activatedRoute.data.subscribe((rsp: any) => {
			this.showTwoSidedOther = rsp?.idTwoSidedOtherEnabled;
		});
		this.subscription.add(routeSub);

		const startedOrUploading = (list) =>
			list?.some((f) => f.status === FileUploadStatusEnum.uploading || f.status === FileUploadStatusEnum.started);
		const getStartedOrUploading = (list) =>
			list?.find((f) => f.status === FileUploadStatusEnum.uploading || f.status === FileUploadStatusEnum.started);
		const filterPending = (list) => list?.filter((f) => f.status === FileUploadStatusEnum.pending);
		const getFirstPending = (list) => list?.find((f) => f.status === FileUploadStatusEnum.pending);

		const storageKeySub = this.sessionStorageService
			.select(this.storageKey)
			.pipe(
				map((v) => v?.selectedDocumentClassification),
				distinctUntilChanged()
			)
			.subscribe({
				next: (selectedDocumentClassification) => this.setFrontBack(selectedDocumentClassification?.key)
			});
		this.subscription.add(storageKeySub);

		const fileSub = this.files$.pipe(startWith(null), pairwise()).subscribe({
			next: ([prevFiles, files]: [IFileUpload[], IFileUpload[]]) => {
				if (!isEmpty(files) && (this.isProofTypeSelection || this.isProofTypeEmpty) && !this.choosing) {
					this.proofState = ProofStatusEnum.status;
				}

				if (this.isProofTypeEmpty) {
					this.proofState = ProofStatusEnum.classificationSelection;
				}

				if (!isEmpty(this.selectedClassificationFiles)) {
					let country;
					if (this.fileUploadService.isAllFilesUploadFinished(this.selectedClassificationFiles)) {
						country = this.selectedClassificationFiles?.find(Boolean)?.country;
					} else if (startedOrUploading(this.selectedClassificationFiles)) {
						country = getStartedOrUploading(this.selectedClassificationFiles)?.country;
					} else {
						const pendingFiles = filterPending(this.selectedClassificationFiles);
						country = getFirstPending(this.selectedClassificationFiles)?.country;
						const formCountry = this.formGroup.get('issuingCountry').value;
						if (
							(!this.showFrontBack && !country && formCountry && this.newCountrySelected) ||
							(!country && formCountry && this.newCountrySelected) ||
							(!country && formCountry && pendingFiles.length === 2)
						) {
							country = formCountry;
						}
					}

					if (country) {
						this.formGroup.get('issuingCountry').setValue(country);
					} else {
						this.formGroup.get('issuingCountry').setValue(null);
					}
				} else {
					const prevClassFiles = this.filterClassificationFiles(prevFiles, this.selectedDocumentClassification);
					const prevPending = getFirstPending(prevClassFiles);
					if (prevPending) {
						this.formGroup.get('issuingCountry').setValue(null);
					}
				}

				this.canCancel = this.checkIfCanCancel(this.selectedClassificationFiles);
				this.submitDisabled = this.checkIfSubmitDisabled(
					this.selectedClassificationFiles,
					this.selectedDocumentClassification
				);
				this.optionalDisabled = this.checkIfOptionalBackDisabled(this.selectedClassificationFiles);

				if (!this.submitDisabled) {
					this.scrollToContinue();
				}

				this.showContinueMessage = this.checkContinueMessage(this.selectedClassificationFiles);
			}
		});
		this.subscription.add(fileSub);

		this.countryList$ = this.metadataService.select(MetadataEnum.Country2);

		this.formGroup
			.get('issuingCountry')
			.valueChanges.pipe(startWith(null), pairwise())
			.subscribe({
				next: ([prevCountry, currCountry]) => {
					const pendingFiles = filterPending(this.selectedClassificationFiles);
					const pending = pendingFiles?.find(Boolean);
					if (prevCountry === currCountry && (pending?.country === currCountry || !pending)) {
						this.newCountrySelected = false;
						return;
					}

					if (pending) {
						this.newCountrySelected = false;
						pendingFiles.forEach((doc) => this.fileUploadService.updateCountry(doc, currCountry));
					} else {
						const uploadedCountry = this.selectedClassificationFiles?.find(Boolean)?.country;
						this.newCountrySelected = uploadedCountry !== currCountry;
					}

					this.canCancel = this.checkIfCanCancel(this.selectedClassificationFiles);
					this.submitDisabled = this.checkIfSubmitDisabled(
						this.selectedClassificationFiles,
						this.selectedDocumentClassification
					);
					this.optionalDisabled = this.checkIfOptionalBackDisabled(this.selectedClassificationFiles);

					if (!this.submitDisabled) {
						this.scrollToContinue();
					}
				}
			});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			issuingCountry: []
		});
	}

	private checkPendingPairBadges(files: IFileUploadExtended[]): IFileUploadExtended[] {
		const [first, second] = files;
		const matching = AutoVerificationUtils.hasFrontBackFiles([first, second]);
		if (
			matching &&
			(first?.status === FileUploadStatusEnum.pending || second?.status === FileUploadStatusEnum.pending)
		) {
			// if one front/back file is being reuploaded (pending) then mark both idle
			first.badge = DocBadgeEnum.idle;
			second.badge = DocBadgeEnum.idle;
			// Make sure both are in review to show the reupload button.
			if (first?.ocrInfo?.documentStatus) {
				first.ocrInfo.documentStatus = FileStatusEnum.review;
			}
			if (second?.ocrInfo?.documentStatus) {
				second.ocrInfo.documentStatus = FileStatusEnum.review;
			}
		} else if (
			!matching &&
			second &&
			first &&
			first?.status === FileUploadStatusEnum.pending &&
			second?.status !== FileUploadStatusEnum.pending
		) {
			second.status = FileUploadStatusEnum.cancelled;
		} else if (
			!matching &&
			second &&
			this.fileUploadService.isFileUploadStarted(first) &&
			!this.fileUploadService.isFileUploadStarting(second)
		) {
			if (second?.badge !== DocBadgeEnum.idle) {
				second.status = FileUploadStatusEnum.cancelled;
			}
		}
		return files;
	}

	protected updateClassFiles(categoryFiles: IFileUpload[], classification: IMetadata): void {
		const modified = AutoVerificationUtils.modifyOCRAttention(categoryFiles);
		this.selectedClassificationFiles = this.checkPendingPairBadges(
			this.filterClassificationFiles(modified, classification)
		);
		this.reviewFiles = this.filterOcrReview(this.selectedClassificationFiles);
		this.finishedFiles = modified?.filter((file) => file?.status === FileUploadStatusEnum.finished);
		this.reviewFileMessageCount = this.reviewFiles?.reduce((acc, file) => acc + file?.ocrInfo?.messages?.length, 0);
		this.attention = Boolean(this.reviewFiles?.length);

		if (this.showFrontBack) {
			const notModifiedReviewFiles = this.filterOcrReview(
				this.filterClassificationFiles(categoryFiles, classification)
			);
			const pending = this.selectedClassificationFiles?.filter(
				(f) => f?.ocrInfo?.documentStatus === FileStatusEnum.pending
			);
			if (!isEmpty(notModifiedReviewFiles) && pending?.length < 2) {
				this.attention = true;
			}
		}
	}

	private isContinueAvailable(files: IFileUploadExtended[]): boolean {
		return files?.every(
			(f) =>
				f?.progressData?.continueAvailable ||
				f?.status === FileUploadStatusEnum.pending ||
				f?.ocrInfo?.documentStatus === FileStatusEnum.complete
		);
	}

	private checkContinueMessage(files: IFileUploadExtended[]): boolean {
		const isPending = files?.some((f) => f?.ocrInfo?.documentStatus === FileStatusEnum.pending);
		return isPending && this.isContinueAvailable(files);
	}

	private checkIfCanCancel(files: IFileUpload[]): boolean {
		if (this.showFrontBack) {
			return isEmpty(files) || this.fileUploadService.isSomeFilesUploadPending(files);
		} else {
			return isEmpty(files) || this.fileUploadService.isAllFilesUploadNotStarted(files);
		}
	}

	private checkIfOptionalBackDisabled(files: IFileUpload[]): boolean {
		return this.showTwoSidedOther && this.frontBackOptional && !this.isFrontSelectedPending();
	}

	private isFrontSelectedPending(): boolean {
		const selected = this.selectedClassificationFiles.some(
			(f) => f?.side === FileSideEnum.front && f?.status === FileUploadStatusEnum.pending
		);
		return selected;
	}

	private isBackSelectedPending(): boolean {
		const selected = this.selectedClassificationFiles.some(
			(f) => f?.side === FileSideEnum.back && f?.status === FileUploadStatusEnum.pending
		);
		return selected;
	}

	private scrollToContinue(): void {
		if (this.continueBtn) {
			this.continueBtn.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
	}

	private checkIfSubmitDisabled(files: IFileUpload[], docClassification: IMetadata): boolean {
		if (isEmpty(files)) {
			return true;
		}

		const isDocumentWaiting = !this.isContinueAvailable(files);
		const docPendingCountRequired = !this.showFrontBack || this.frontBackOptional ? 1 : 2;
		const pendingCount = files?.filter((f) => f.status === FileUploadStatusEnum.pending).length;
		const optionalOneIsFront = this.frontBackOptional && files?.[0]?.side === FileSideEnum.front;

		const isCountry = this.issuingCountryPipe.transform(String(docClassification?.key));
		if (this.showFrontBack && isCountry) {
			return (
				!(this.fileUploadService.isAllFilesUploadFinished(files) || pendingCount >= docPendingCountRequired) ||
				isDocumentWaiting
			);
		} else if (this.frontBackOptional && pendingCount === 1 && optionalOneIsFront) {
			return (
				!(this.fileUploadService.isAllFilesUploadFinished(files) || pendingCount >= docPendingCountRequired) ||
				isDocumentWaiting
			);
		} else if (this.frontBackOptional && pendingCount === 1 && !optionalOneIsFront) {
			return true;
		} else if (this.showFrontBack) {
			return (
				!(this.fileUploadService.isAllFilesUploadFinished(files) || pendingCount >= docPendingCountRequired) ||
				isDocumentWaiting
			);
		} else if (isCountry) {
			return isDocumentWaiting;
		} else {
			const finished = this.fileUploadService.isSomeFilesUploadFinished(files);
			return !finished || isDocumentWaiting;
		}
	}

	private setFrontBack(key: string | number): void {
		this.frontBackOptional = this.showTwoSidedOther && optionalFrontBackList.includes(key?.toString());
		this.showFrontBack = frontBackList.includes(key?.toString()) || this.frontBackOptional;
	}

	saveDocumentClassification(docClassification: IMetadata): void {
		this.selectedDocumentClassification = docClassification;
		this.singleFileFront = null;
		this.singleFileBack = null;

		if (!isEmpty(this.selectedClassificationFiles)) {
			const country = this.selectedClassificationFiles.find(Boolean)?.country;
			this.formGroup.get('issuingCountry').setValue(country);
		}

		this.canCancel = this.checkIfCanCancel(this.selectedClassificationFiles);
		this.submitDisabled = this.checkIfSubmitDisabled(
			this.selectedClassificationFiles,
			this.selectedDocumentClassification
		);
		this.optionalDisabled = this.checkIfOptionalBackDisabled(this.selectedClassificationFiles);

		if (!this.submitDisabled) {
			this.scrollToContinue();
		}

		this.proofState = ProofStatusEnum.upload;
		this.showContinueMessage = this.checkContinueMessage(this.selectedClassificationFiles);
	}

	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openErrorDialog(errorMessage || this.languageService.translate('DOCUMENT_SUBMIT.errorDialog'))
			.subscribe();
	}

	private isCountryNeeded(docClassification: IMetadata): boolean {
		return (
			this.issuingCountryPipe.transform(String(docClassification?.key)) &&
			!this.formGroup.get('issuingCountry').value &&
			this.selectedClassificationFiles?.some(
				(f) =>
					f?.ocrInfo?.documentStatus !== FileStatusEnum.complete &&
					f?.ocrInfo?.documentStatus !== FileStatusEnum.pending
			)
		);
	}

	private showOptionalBackDialog(): void {
		const dlgData: IMessageDialogData = {
			title: this.languageService.translate(
				'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.optionalBackDialog.title'
			),
			message: this.languageService.translate(
				'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.optionalBackDialog.message'
			),
			cancelText: this.languageService.translate(
				'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.optionalBackDialog.continue'
			),
			confirmText: this.languageService.translate(
				'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.optionalBackDialog.cancel'
			),
			titleBody1: true
		};
		this.dialogService
			.openMessageDialog(
				dlgData,
				(value) => {
					return of(true);
				},
				(value) => {
					return value === undefined ? of(true) : of(false);
				}
			)
			.subscribe({
				next: (result) => {
					!result && this.uploadPendingFiles();
				}
			});
	}

	private uploadPendingFiles(): void {
		if (this.isCountryNeeded(this.selectedDocumentClassification)) {
			this.formGroup.get('issuingCountry').setErrors({ required: true });
			this.formGroup.get('issuingCountry').markAsTouched();
			return;
		}

		if (!this.fileUploadService.isSomeFilesUploadPending(this.selectedClassificationFiles)) {
			this.routingService.route(RoutingPathsEnum.documentSubmit);
			return;
		}

		this.docUpload.uploadPendingFiles().subscribe({
			next: (files: IFileUpload[]) => {
				const errorFile = files.find((f) => f.status === FileUploadStatusEnum.error);
				if (errorFile) {
					this.fileUploadService.removeErrorFiles(this.categoryType);
					this.showErrorMessage(errorFile.error?.msg);
					return;
				}

				const allFinished = this.fileUploadService.isAllFilesUploadFinished(files);
				if (allFinished) {
					this.fileUploadService.removeErrorFiles(this.categoryType);
					// this.routingService.back();
				}
			}
		});
	}

	onContinue(): void {
		if (this.frontBackOptional && !this.isBackSelectedPending() && !this.optionalDisabled) {
			this.showOptionalBackDialog();
		} else {
			this.uploadPendingFiles();
		}
	}

	onCancel(): void {
		this.fileUploadService.removePendingFiles(this.categoryType);
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onDocStatus(statusEvent: IDocumentStatusEvent): void {
		switch (statusEvent.event) {
			case DocumentStatusEventEnum.uploadMore:
				this.saveDocumentClassification(statusEvent.documentClassification);
				this.proofState = ProofStatusEnum.upload;
				break;
			case DocumentStatusEventEnum.addMore:
				this.choosing = true;
				this.proofState = ProofStatusEnum.classificationSelection;
				break;
		}
	}

	displayedSingleFileFront(file: IFileUploadExtended): void {
		if (file?.type === this.categoryType && file?.classification === this.selectedDocumentClassification.key) {
			this.singleFileFront = file;
		}
	}

	displayedSingleFileBack(file: IFileUploadExtended): void {
		if (file?.type === this.categoryType && file?.classification === this.selectedDocumentClassification.key) {
			const matching = AutoVerificationUtils.hasFrontBackFiles([this.singleFileFront, this.singleFileBack]);
			this.singleFileBack = file;
			if (!matching) {
				this.checkPendingPairBadges([this.singleFileFront, this.singleFileBack]);
			}
		}
	}
}
