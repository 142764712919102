import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';

@Component({
	selector: 'op-expand-section',
	templateUrl: './expand-section.component.html',
	styleUrls: ['./expand-section.component.scss']
})
export class ExpandSectionComponent {
	@Input()
	fileType: FileUploadType;

	@Input()
	expanded: boolean;

	@Input()
	fileList: IFileUpload[];

	@Output()
	opened = new EventEmitter<FileUploadType>();

	@Output()
	closed = new EventEmitter<FileUploadType>();
}
