import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isFunction } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { EnvironmentService } from '../environment/environment.service';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class NeuroIdService implements OnDestroy {
	private subscription = new Subscription();

	constructor(
		private environmentService: EnvironmentService,
		private loanAppService: LoanApplicationService,
		private scriptService: ScriptLoaderService,
		private translocoService: TranslocoService,
		private windowRef: WindowRefService
	) {
		this.loadNidScript().subscribe({
			next: (data) => {
				this.setNid('start', location.pathname);
				const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
					next: (loanApplication: ILoanApplication) => {
						this.setNid('setUserId', loanApplication?.id);
						if (loanApplication?.preApprovalProcessComplete) {
							this.setNid('setCheckpoint', 'pre_approval_completed');
						}
						if (loanApplication?.referenceCheckProcessComplete) {
							this.setNid(
								'setVariable',
								'references_requested',
								String(this.loanAppService.getCurrentApplicant().referencesRequired > 0)
							);
						}
						if (this.loanAppService.isPartnerApplication()) {
							this.setNid(
								'setVariable',
								'funnel',
								loanApplication?.partnerId ? 'partner' : loanApplication.originationSource.toLowerCase()
							);
						}
					}
				});
				this.subscription.add(loanAppSub);

				const langSub = this.translocoService.langChanges$.subscribe({
					next: (language) => {
						this.setNid('setVariable', 'language', language === 'en' ? 'english' : 'spanish');
					}
				});
				this.subscription.add(langSub);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	setNid(event: string, data?: any, value?: any): void {
		if (isFunction(this.windowRef.nativeWindow.nid)) {
			this.windowRef.nativeWindow.nid(event, data, value);
		}
	}

	loadNidScript(): Observable<void> {
		let url = this.environmentService.isProduction
			? '//scripts.neuro-id.com/c/nid-oprtunpl-v1.0.js'
			: '//scripts.neuro-id.com/c/nid-oprtunpl-v1.0-stg.js';
		return this.scriptService.loadScript(url);
	}
}
