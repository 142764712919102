import { IAddress } from '../services/loan-application/applicant/applicant.model';
import { AddressTypeEnum, IAddressResult } from '../services/mobile-api/mobile-api.model';
import { StatesEnum } from './state-utils';

export class AddressUtils {
	static CheckOnlyStates: StatesEnum[] = [StatesEnum.FL, StatesEnum.NJ];

	static fromAddressResult(addressResults: IAddressResult): AddressUtils {
		return new this(addressResults);
	}

	constructor(readonly addresses: IAddressResult) {}

	static getHomeAddress(addresses: IAddressResult[]): IAddressResult {
		return Array.isArray(addresses) ? addresses?.find((add) => add.type === AddressTypeEnum.home) : null;
	}

	static getHomeState(addresses: IAddressResult[]): StatesEnum {
		return StatesEnum[this.getHomeAddress(addresses).state?.toUpperCase()];
	}

	static getMailAddress(addresses: IAddressResult[]): IAddressResult {
		return Array.isArray(addresses) ? addresses?.find((add) => add.type === AddressTypeEnum.mail) : null;
	}

	static getMailState(addresses: IAddressResult[]): StatesEnum {
		return StatesEnum[this.getMailAddress(addresses).state?.toUpperCase()];
	}

	static getFormattedAddress(address: IAddressResult): string {
		return (
			['streetAddress1', 'streetAddress2', 'city'].map((key) => address[key]).join(' ') +
			`, ${address.state} ${address.postalCode}`
		);
	}

	static addressesAreEqual = function (a: IAddressResult | IAddress, b: IAddressResult | IAddress): boolean {
		return (
			a?.city === b?.city &&
			a?.country === b?.country &&
			a?.postalCode === b?.postalCode &&
			a?.state === b?.state &&
			a?.streetAddress1 === b?.streetAddress1 &&
			a?.streetAddress2 === b?.streetAddress2
		);
	};

	static isCheckOnlyState(state: StatesEnum): boolean {
		return this.CheckOnlyStates.includes(state);
	}

	static isCaliforniaPostalCode(postalCode: number): boolean {
		const minZipcodeForCA: number = 90001;
		const maxZipcodeForCA: number = 96162;
		return postalCode >= minZipcodeForCA && postalCode <= maxZipcodeForCA;
	}
}
