export default interface IProduct {
	id?: number;
	apr: number;
	downpaymentPercentage?: number;
	feeAmount1?: number;
	feeDescription1?: string;
	lasyPaycheckDate: Date;
	maxApprovedAmount: number;
	maxPaymentAmount: number;
	minApprovedAmount: number;
	numberOfPayments: number;
	originationFee: number;
	paymentAmount: number;
	paymentFrequency: paymentFrequencyEnum;
	paymentTerm: number;
	productAction: ProductActionsEnum;
	productCategory: ProductCategoriesEnum;
	productStatus: ProductStatusesEnum;
	productSubStatus: ProductSubStatusesEnum;
	paymentDay?: null;
	paymentDate1?: null;
	paymentDate2?: null;
	incomeSource: string;
	realApr?: number;
	isIneligibleVehicleProvided?: boolean;
}

export enum paymentFrequencyEnum {
	monthly = 'MONTHLY',
	semiMonthly = 'SEMI_MONTHLY',
	biWeekly = 'BI_WEEKLY'
}

export enum ProductActionsEnum {
	approved = 'APPROVED',
	checkVehicleEligibility = 'Check Vehicle Eligibility',
	eligible = 'ELIGIBLE',
	paused = 'PAUSED'
}

export enum ProductCategoriesEnum {
	personalLoan = 'PERSONAL_LOAN',
	securedPersonalLoan = 'SECURED_PERSONAL_LOAN',
	unsecuredPersonalLoan = 'UNSECURED_PERSONAL_LOAN'
}

export enum ProductStatusesEnum {
	approved = 'APPROVED',
	eligible = 'ELIGIBLE',
	nonProcessable = 'NON_PROCESSABLE',
	notApproved = 'NOT_APPROVED',
	preApproved = 'PRE_APPROVED',
	bounced = 'BOUNCED',
	securedDeclined = 'SECURED_DECLINED'
}

export enum ProductSubStatusesEnum {
	initiated = 'DMV_PACKAGE_PROCESS_INITIATED',
	complete = 'DMV_PACKAGE_VERIFICATION_COMPLETE',
	goToStore = 'VISIT_STORE'
}

export enum SecuredOfferResponseEnum {
	accept = 'ACCEPT',
	decline = 'DECLINE',
	undecided = 'UNDECIDED'
}

export const invalidProductStatusesList = [ProductStatusesEnum.notApproved, ProductStatusesEnum.nonProcessable];

export const validProductStatusesList = [
	ProductStatusesEnum.approved,
	ProductStatusesEnum.eligible,
	ProductStatusesEnum.preApproved
];

export const noOwnPaidOffVehicleStatusList = ['OWN_VEHICLE_WITH_PAYMENTS', 'NO_OWN_VEHICLE', 'LEASE_VEHICLE'];

export const preferNotAnswerVehicleStatus = 'PREFER_NOT_ANSWER';
