<div class="select-bank-account" *transloco="let t">
	<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<p id="{{ id + '_selectAccountTitle' }}" class="op-header-3-font">
			{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.CONNECTED.previously') }}
		</p>

		<div class="op-mtb-20">
			<mat-radio-group
				labelPosition="before"
				name="accounts"
				id="{{ id + '_selected' }}"
				formControlName="selectedId"
				class="mat-radio-group"
			>
				<div *ngFor="let bankAccount of groupedAccountList | keyvalue" class="bank-account-group">
					<div class="op-body-1-bold-font">{{ bankAccount.key }}</div>
					<mat-radio-button
						class="op-mt-8 op-body-2-font"
						*ngFor="let account of bankAccount.value"
						[value]="account?.id"
						[checked]="account?.selected"
					>
						<div class="op-body-2-font op-dark-grey">
							{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.TYPE.' + account.accountType) }}
							{{ account.accountNumber | opLastNNumber: '.':3 }}
						</div>
					</mat-radio-button>
				</div>
				<button
					mat-flat-button
					color="primary-small"
					name="submitBtn"
					id="{{ id + '_submitBtn' }}"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('SELECT_BANK_ACCOUNT.continue') }}
				</button>
			</mat-radio-group>
		</div>
		<div class="add-bank-container" (click)="addNewBankAccount()">
			<div class="plus-circle"><mat-icon svgIcon="op-plus-dark-gray"></mat-icon></div>
			<div class="add-text">
				<div class="title op-body-1-bold-font">
					<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.newBankAccount') }}</span>
					<div class="arrow">
						<mat-icon svgIcon="op-chevron-right"></mat-icon>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
