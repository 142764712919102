import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'op-check-delivery-notice',
	templateUrl: './check-delivery-notice.component.html',
	styleUrls: ['./check-delivery-notice.component.scss']
})
export class CheckDeliveryNoticeComponent {
	constructor(public dialogRef: MatDialogRef<CheckDeliveryNoticeComponent>) {}

	onClick(): void {
		this.dialogRef.close(true);
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}
}
