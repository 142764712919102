<div class="file-thumbnails">
	<div [ngClass]="{ 'file-list': categoryType !== 'selfie' }">
		<div
			class="file-list-item"
			[ngClass]="'file-status-' + (file.status | lowercase)"
			*ngFor="let file of files; let i = index"
		>
			<mat-progress-bar
				class="file-progress"
				*ngIf="file.progress && file.progress !== 100"
				mode="determinate"
				[value]="file.progress"
			></mat-progress-bar>
			<img
				[ngClass]="{ 'thumbnail-img-size': file.type !== 'selfie' }"
				id="{{ id + '_' + i + '_image' }}"
				alt=""
				[src]="file.thumbnail"
			/>
			<p class="file-name" id="{{ id + '_' + i + '_name' }}" title="{{ file.name }}">{{ file.name }}</p>
		</div>
		<div *ngIf="files?.length" class="file-add">
			<ng-content></ng-content>
			<p></p>
		</div>
	</div>
</div>
