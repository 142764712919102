import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileUploadService, FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

import { DocBadgeEnum, IClassificationVerification } from '../auto-verification.service';

export enum DocumentStatusEventEnum {
	addMore = 'addMore',
	uploadMore = 'uploadMore'
}

export interface IDocumentStatusEvent {
	event: DocumentStatusEventEnum;
	documentClassification?: IMetadata;
}

interface IDocumentStatus {
	key: string;
	count: number;
	text: string;
	attention: boolean;
	processing: boolean;
}

interface IClassificationCount {
	[key: string]: {
		count: number;
		attention: boolean;
		processing: boolean;
	};
}

@Component({
	selector: 'op-document-status',
	templateUrl: './document-status.component.html',
	styleUrls: ['./document-status.component.scss']
})
export class DocumentStatusComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	private _classificationVerification: IClassificationVerification;
	@Input()
	get classificationVerification(): IClassificationVerification {
		return this._classificationVerification;
	}
	set classificationVerification(value: IClassificationVerification) {
		this._classificationVerification = value;
		this.categoryFilesStatus = this.updateCategoryFilesStatusFromClassification(value);
	}

	private _documentClassificationList: IMetadata[];
	get documentClassificationList(): IMetadata[] {
		return this._documentClassificationList;
	}

	@Input()
	set documentClassificationList(value: IMetadata[]) {
		this._documentClassificationList = value;
		this.updateCategoryFilesStatusText(value);
	}

	@Output()
	done = new EventEmitter<IDocumentStatusEvent>();

	categoryFilesStatus: IDocumentStatus[];

	public docBadgeEnum: typeof DocBadgeEnum = DocBadgeEnum;

	constructor(private fileUploadService: FileUploadService) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	private updateCategoryFilesStatusFromClassification(
		classificationVerification: IClassificationVerification
	): IDocumentStatus[] {
		const classification = classificationVerification?.classification;

		return Object.keys(classification).reduce((acc, item) => {
			acc.push({
				key: item,
				count: classification[item].count,
				text: this.documentClassificationList?.find((doc) => doc.key == item).text,
				badge: classification[item].badge,
				attention: classification[item].badge === DocBadgeEnum.attention,
				processing: classification[item].badge === DocBadgeEnum.processing
			});
			return acc;
		}, []);
	}

	private updateCategoryFilesStatusText(classificationList: IMetadata[]): void {
		if (this.categoryFilesStatus) {
			this.categoryFilesStatus = this.categoryFilesStatus?.map((item) => {
				return {
					...item,
					text: classificationList?.find((doc) => doc.key == item.key)?.text
				};
			});
		}
	}

	onUploadMore(item: IDocumentStatus): void {
		const { count, ...documentClassification } = item;
		this.done.emit({ event: DocumentStatusEventEnum.uploadMore, documentClassification });
	}

	onAddMore(): void {
		this.done.emit({ event: DocumentStatusEventEnum.addMore });
	}
}
