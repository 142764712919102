import { Component, Input, OnInit } from '@angular/core';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';

@Component({
	selector: 'op-status-other',
	templateUrl: './other.component.html',
	styleUrls: ['./other.component.scss']
})
export class OtherComponent implements OnInit {
	private _loanApp: ILoanApplication;
	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
	}

	subTitle: string;
	body: string;

	ngOnInit(): void {
		this.subTitle = `STATUS.${this.loanApp.applicationStatus}.initial`;
		this.body = `STATUS.${this.loanApp.applicationStatus}.body`;
	}
}
