import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'op-check-delivery-confirm',
	templateUrl: './check-delivery-confirm.component.html',
	styleUrls: ['./check-delivery-confirm.component.scss']
})
export class CheckDeliveryConfirmComponent {
	constructor(public dialogRef: MatDialogRef<CheckDeliveryConfirmComponent>) {}

	onClick(): void {
		this.dialogRef.close(true);
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}
}
