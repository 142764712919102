import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import TokenService from 'src/app/core/services/token/token.service';
import { REQUEST_PARAM_APP_SOURCE } from 'src/app/shared/constants/common-const';

@Injectable({
	providedIn: 'root'
})
export class TokenGuard implements CanActivate {
	constructor(private tokenService: TokenService) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const token = 'SJWT ' + route.queryParams['token'];
		const appSource = route.queryParams[REQUEST_PARAM_APP_SOURCE];
		return this.tokenService.login(token, appSource).pipe(map(() => false));
	}
}
