import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';

import { DocumentSubmitService } from '../document-submit.service';

/**
 * Guard to check that the user is required to check stated vs verified income before showing document-submit.
 *
 * @export
 * @class VerifyIncomeGuard
 * @implements {CanActivate}
 */
@Injectable()
export class VerifyIncomeGuard implements CanActivate {
	constructor(private router: Router, private documentSubmitService: DocumentSubmitService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.documentSubmitService.DocumentSubmitInitialized$.pipe(
			map(() => {
				return this.documentSubmitService.isIncomeSelectionRequired()
					? this.router.parseUrl(RoutingPathsEnum.verifyIncome)
					: true;
			})
		);
	}
}
