import { Component, Input } from '@angular/core';
import { IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';

import { FileUploadType } from '../../../core/services/file-upload/file-upload.service';
import { opRequired } from '../../decorators/required.decorator';

/**
 * Displays image thumbnail
 *
 * @export
 * @class FileThumbnailsComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-file-thumbnails',
	templateUrl: './file-thumbnails.component.html',
	styleUrls: ['./file-thumbnails.component.scss']
})
export class FileThumbnailsComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	@Input()
	files: Array<IFileUpload>;
}
