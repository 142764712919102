import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { ApplicantStepCompleteEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IConfigResult, IMetadata } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { MessageDialogComponent } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

@Component({
	selector: 'op-credit-auth',
	templateUrl: './credit-auth.component.html',
	styleUrls: ['./credit-auth.component.scss']
})
export class CreditAuthComponent implements OnInit, OnDestroy {
	formGroup: FormGroup;
	showWirelessOperationsAuth = false;
	isSoftPullEnabled = false;

	customerState: string;
	returnToSummary: string;
	returnToFTR: string;

	isPlaidAssetRefresh: boolean;
	isMetaOrganization: boolean;

	private subscription = new Subscription();
	issuingOrganization: string;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private mobileService: MobileApiService,
		private softPullService: SoftPullService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private nidService: NeuroIdService,
		private dialogService: DialogService,
		private issuingPartnerService: IssuingPartnerService,
		private authService: AuthenticationService
	) {
		this.createForm(this.formBuilder);
	}

	// TODO: localization needed
	toggleCreditCheckOptions: Array<IMetadata> = [
		{ key: true, text: 'GLOBAL.yes' },
		{ key: false, text: 'GLOBAL.no' }
	];

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$.pipe(filter(Boolean)).subscribe({
			next: (softPull: IConfigResult) => {
				this.isSoftPullEnabled = softPull.value;
			}
		});
		this.subscription.add(softPullSub);

		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;
		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				take(1),
				switchMap((loanApp: ILoanApplication) => {
					this.issuingOrganization = this.issuingPartnerService.lender;
					this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingOrganization);
					this.isPlaidAssetRefresh = ApplicantUtils.fromLoanApp(loanApp).isPlaidAssetRefresh();
					return this.mobileService.getAddresses(loanApp.id);
				})
			)
			.subscribe({
				next: (rsp) => {
					this.customerState = AddressUtils.getHomeAddress(rsp).state;
				}
			});
		this.subscription.add(loanAppSub);

		this.formGroup.controls.creditCheck.valueChanges.subscribe({
			next: (values) => {
				if (!values) {
					const data = {
						icon: 'op-alert',
						title: this.translocoService.translate('DIALOG_MESSAGE.message'),
						message: this.translocoService.translate('CREDIT_AUTH.checkRequired')
					};
					const dialogRef = this.dialog.open(MessageDialogComponent, { data });
					dialogRef.afterClosed().pipe(take(1));
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			creditCheck: [null, Validators.requiredTrue]
		});
	}

	toggleWirelessOperationsAuth(): void {
		this.showWirelessOperationsAuth = !this.showWirelessOperationsAuth;
	}

	onSubmit(post: any): void {
		const statusPage = [
			ApplicationStatusEnum.notApproved,
			ApplicationStatusEnum.coAppDeclined,
			ApplicationStatusEnum.coAppOffered,
			ApplicationStatusEnum.nonProcessable
		];
		const body = { customerAgrees: post.creditCheck };
		this.mobileService
			.setCreateAuthorization(body, this.loanAppService.getLoanApp().id)
			.pipe(switchMap(() => this.loanAppService.updateLoanApplication()))
			.subscribe({
				next: (loanApp) => {
					if (
						this.loanAppService.isCreditRunSuccess() === false &&
						!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification)
					) {
						this.routingService.route(RoutingPathsEnum.identityConfirm);
					} else if (statusPage.some((item) => item === loanApp.applicationStatus)) {
						this.routingService.route(RoutingPathsEnum.status);
					} else {
						if (this.returnToSummary) {
							this.routingService.routeFromLoanApp({ queryParams: { returnToSummary: this.returnToSummary } });
						} else if (this.returnToFTR) {
							this.routingService.routeForFastTrack(this.returnToFTR);
						} else {
							this.routingService.route(RoutingPathsEnum.finances);
						}
					}
					this.tealiumCreatConsent(body.customerAgrees);
				},
				error: (err) => {
					this.dialogService
						.openErrorDialog(err)
						.pipe(
							filter(() => Array.isArray(err?.error) && err.error.some((e) => e?.id === 'appError1')),
							switchMap(() => this.authService.signOut()),
							tap(() => this.routingService.route(RoutingPathsEnum.findYourApplication))
						)
						.subscribe();
				}
			});
	}

	tealiumCreatConsent(consent: string) {
		this.tagDataService.link(
			{
				applicant_credit_consent: '' + consent
			},
			{
				event_action: 'submit',
				event_category: 'Form',
				event_label: 'rx_form',
				tealium_event: 'credit_auth_process_complete'
			}
		);
		this.nidService.setNid('setCheckpoint', 'credit_auth_page_submitted');
	}
}
