import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CassAddressService } from 'src/app/core/services/ccas/cass-address.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IIdentification } from 'src/app/core/services/mobile-api/identification-api/identification-api.model';
import { IdentificationApiService } from 'src/app/core/services/mobile-api/identification-api/identification-api.service';
import { IAddressResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-identity-confirm',
	templateUrl: './identity-confirm.component.html',
	styleUrls: ['./identity-confirm.component.scss']
})
export class IdentityConfirmComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private identificationService: IdentificationApiService,
		private route: ActivatedRoute,
		private routingService: RoutingService,
		private cassAddressService: CassAddressService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;
	identityDetailData: IIdentification;
	loanApplicationId: number;
	returnToSummary: string;
	returnToFTR: string;
	private subscription = new Subscription();

	ngOnInit(): void {
		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;
		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(switchMap((rsp) => this.identificationService.getIdentification(rsp?.id)))
			.subscribe({
				next: (resp) => {
					this.identityDetailData = { ...resp };
					this.updateIdentityAddress(resp);
				}
			});
		this.subscription.add(loanAppSub);
	}

	onCheckChange(data: MatCheckboxChange) {
		if (data && data.checked) {
			const currentAddressSub = this.mobileService
				.getAddresses(this.loanAppService.loanApplicationId, 0, true)
				.subscribe({
					next: (currentAddress: IAddressResult[]) => {
						// TODO: move to utility
						if (currentAddress?.length > 0) {
							this.updateCurrentAddress(currentAddress[0]);
						}
					}
				});
			this.subscription.add(currentAddressSub);
		}
	}

	updateCurrentAddress(currentAddress: IAddressResult): void {
		if (currentAddress?.streetAddress1 && currentAddress?.city) {
			this.formGroup.get('addressDetail').setValue({
				address1: currentAddress.streetAddress1,
				address2: currentAddress.streetAddress2,
				city: currentAddress.city,
				state: currentAddress.state,
				zipCode: currentAddress.postalCode
			});
		}
	}

	updateIdentityAddress(data: IIdentification): void {
		if (data?.streetAddress1 && data?.city) {
			this.formGroup.get('addressDetail').setValue({
				address1: data.streetAddress1,
				address2: data.streetAddress2,
				city: data.city,
				state: data.state,
				zipCode: data.postalCode
			});
		}
	}

	setIdentityAddress(cassAddress): void {
		this.identityDetailData.streetAddress1 = cassAddress?.streetAddress1;
		this.identityDetailData.streetAddress2 = cassAddress?.streetAddress2 ? cassAddress.streetAddress2 : '';
		this.identityDetailData.city = cassAddress?.city;
		this.identityDetailData.state = cassAddress?.state;
		this.identityDetailData.postalCode = cassAddress?.postalCode;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			addressDetail: [{ address1: '', address2: '', city: '', state: '', zipCode: '' }],
			sameAsCurrent: [false]
		});
	}

	navigate(): void {
		if (this.returnToFTR) {
			this.routingService.routeForFastTrack(this.returnToFTR);
		} else if (this.returnToSummary) {
			this.routingService.route(RoutingPathsEnum.summary);
		} else {
			this.routingService.route(RoutingPathsEnum.finances);
		}
	}

	createAddressObject() {
		const verifyAddress = this.formGroup.value;
		return {
			type: 'H',
			streetAddress1: verifyAddress?.addressDetail.address1,
			streetAddress2: verifyAddress?.addressDetail.address2 || '',
			city: verifyAddress?.addressDetail.city,
			state: verifyAddress?.addressDetail.state,
			postalCode: verifyAddress?.addressDetail.zipCode,
			country: 'US'
		};
	}

	onSubmit(): void {
		this.cassAddressService
			.verifyCassAddress(this.createAddressObject())
			.pipe(
				switchMap((cassAddress) => {
					this.setIdentityAddress(cassAddress);
					return this.identificationService.setIdentification(
						this.identityDetailData,
						this.loanAppService.loanApplicationId
					);
				})
			)
			.subscribe({
				next: () => {
					this.navigate();
				}
			});
	}
}
