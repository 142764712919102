import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload,
	IFileUploads
} from '../../../core/services/file-upload/file-upload.service';
import { opRequired } from '../../decorators/required.decorator';

/**
 * Shows button to upload documents.  Displays thumbnail of doc.
 *
 * @export
 * @class FileUploadComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
	selector: 'op-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
	@Input()
	categoryType: FileUploadType;

	@Input()
	requiredFileType: string;

	@Input()
	@opRequired()
	id: string;

	uploadSub: Subscription;

	files$: Observable<IFileUploads>;
	filesType: IFileUpload[];
	subscription: Subscription;

	constructor(
		private dialog: MatDialog,
		private translocoService: TranslocoService,
		private fileUploadService: FileUploadService,
		private dialogService: DialogService
	) {
		this.files$ = this.fileUploadService.fileUploads$;
	}

	ngOnInit(): void {
		this.subscription = this.files$.pipe(map((rsp) => rsp[this.categoryType])).subscribe({
			next: (rsp) => {
				this.filesType = rsp;
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openErrorDialog(errorMessage ? errorMessage : this.translocoService.translate('DOCUMENT_SUBMIT.errorDialog'))
			.subscribe();
	}

	onFileSelected(event): void {
		const file: File = event.target.files[0];
		if (file) {
			const upload$ = this.fileUploadService.sendFile(this.categoryType, file).pipe(finalize(() => this.reset()));
			this.uploadSub = upload$.subscribe((sendEvent) => {
				if (sendEvent.status === FileUploadStatusEnum.uploading) {
					// this.uploadProgress = event.progress;
				}
				if (sendEvent.status === FileUploadStatusEnum.error) {
					this.showErrorMessage(sendEvent.error?.msg);
				}
			});
		}
		event.target.value = '';
	}

	cancelUpload(): void {
		this.uploadSub.unsubscribe();
		this.reset();
	}

	reset(): void {
		this.uploadSub = null;
	}
}
