<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="selfiePanel"
		title="{{ t('DOCUMENT_SUBMIT.selfie.photo') }}"
		[fileList]="fileList"
		[expanded]="expanded"
		notDisplayCount="true"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
	>
		<div class="panel-content">
			<div class="op-header-3-font pb-3">{{ t('DOCUMENT_SUBMIT.selfie.photoTip01') }}</div>
			<op-selfie-upload
				class="my-3"
				id="selfieFileUpload"
				[categoryType]="fileType"
				upload-factory="photoUpload"
				upload-name="selfie"
			>
			</op-selfie-upload>
		</div>
	</op-document-expansion-panel>
</div>
