<div class="spl-product-offer-info-card" *transloco="let t">
	<mat-card class="content">
		<mat-card-content>
			<div class="op-header-2-font">{{ t('ADJUST_TERMS.LOAN_TYPES.securedPersonalLoan') }}</div>
			<div class="loan-amount">
				<div class="op-header-4-font" [innerHTML]="t('OFFER_STATUS.upTo')"></div>
				<div class="op-display-2-font">
					{{ splMaxLoanAmount | currency: 'USD':'symbol':'0.0' }}
					<sup>*</sup>
				</div>
			</div>
			<div class="op-mt-20 op-mb-16" *ngIf="showSkipSPLOfferButton">
				<button mat-flat-button color="secondary-small" id="splSkipOfferBtn" (click)="onContinue()">
					{{ skipOfferButtonText }}
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
