<div class="loan-benefits" *transloco="let t">
	<div class="heading">
		<h1>
			<span class="congratulations">{{ t('LOAN_BENEFITS.congratulations') }}</span>
			<span class="applicant-name">{{ applicantName }}</span>
		</h1>
	</div>

	<op-content-container class="content">
		<div>
			<op-learn-more
				class="op-pad-20"
				id="calculatedLoanAmtQ"
				src="/assets/images/offers-handMoney.svg"
				[title]="t('LOAN_BENEFITS.calculatedLoanAmtQ')"
			>
				<div id="calculatedLoanAmtA">{{ t('LOAN_BENEFITS.calculatedLoanAmtA') }}</div>
			</op-learn-more>

			<op-learn-more
				class="op-pad-20"
				id="EstbCreditHistoryQ"
				src="/assets/images/credit.svg"
				[title]="t('LOAN_BENEFITS.EstbCreditHistoryQ')"
			>
				<div id="EstbCreditHistoryA">{{ t('LOAN_BENEFITS.EstbCreditHistoryA') }}</div>
				<br /><br />
				<div id="EstbCreditHistoryA_2">{{ t('LOAN_BENEFITS.EstbCreditHistoryA_2') }}</div>
			</op-learn-more>

			<op-learn-more
				class="op-pad-20"
				id="opportunityIncLoanAmtQ"
				src="/assets/images/piggy.svg"
				[title]="t('LOAN_BENEFITS.opportunityIncLoanAmtQ')"
			>
				<div id="opportunityIncLoanAmtA">{{ t('LOAN_BENEFITS.opportunityIncLoanAmtA') }}</div>
			</op-learn-more>
		</div>
		<div>
			<button mat-flat-button color="primary" name="submitBtn" id="submitBtn" (click)="onSubmit()">
				{{ t('LOAN_BENEFITS.getStarted') }}
			</button>
		</div>
	</op-content-container>
</div>
