<div class="address-selection-dialog" aria-labelledby="csd-label" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button
				mat-icon-button
				id="addressSelectCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-1-font">
			<span [innerHTML]="data.title | uppercase" id="csd-label"></span>
		</div>
	</div>

	<div class="multipleChoice-panels" role="radiogroup" aria-labelledby="csd-address-selection-label">
		<p id="csd-address-selection-label">
			{{ 'ADDRESS.cass.SUGGEST_ADDRESS' | transloco }}
		</p>
		<form class="personal-content" [formGroup]="formGroup" (keyup.enter)="onSubmit(formGroup.value)">
			<label
				for="original"
				class="address-radio"
				[class.active]="formGroup.controls['address'].value === 'original'"
				aria-checked="formGroup.controls['address'].value === 'original'"
			>
				<input type="radio" id="original" value="original" formControlName="address" />
				<div aria-hidden="true">
					<div class="address-title">
						<div class="op-icon-align-header-2">{{ 'ADDRESS.cass.enteredAddress' | transloco | uppercase }}</div>
						<mat-icon class="icon" svgIcon="op-checkMark-outline"></mat-icon>
					</div>
					<div class="address-info">
						<div class="block">{{ data.originalAddress.streetAddress1 }}</div>
						<div class="block" *ngIf="data.originalAddress.streetAddress2">
							{{ data.originalAddress.streetAddress2 }}
						</div>
						<div class="block">
							{{ data.originalAddress.city }}, {{ data.originalAddress.state }} {{ data.originalAddress.postalCode }}
						</div>
					</div>
				</div>
			</label>

			<label
				for="suggested"
				class="address-radio"
				[class.active]="formGroup.controls['address'].value === 'suggested'"
				aria-checked="formGroup.controls['address'].value === 'suggested'"
			>
				<input type="radio" id="suggested" value="suggested" formControlName="address" />
				<div aria-hidden="true">
					<div class="address-title">
						<div class="op-icon-align-header-2">{{ 'ADDRESS.cass.suggestedAddress' | transloco | uppercase }}</div>
						<mat-icon class="icon" svgIcon="op-checkMark-outline"></mat-icon>
					</div>
					<div class="address-info">
						<div class="block">{{ data.suggestedAddress.streetAddress1 }}</div>
						<div class="block" *ngIf="data.suggestedAddress.streetAddress2">
							{{ data.suggestedAddress.streetAddress2 }}
						</div>
						<div class="block">
							{{ data.suggestedAddress.city }}, {{ data.suggestedAddress.state }} {{ data.suggestedAddress.postalCode }}
						</div>
					</div>
				</div>
			</label>
		</form>
	</div>

	<p></p>
	<div mat-dialog-actions>
		<button
			class="submit"
			mat-flat-button
			color="primary-small"
			id="addressSelectionSubmitBtn"
			type="submit"
			[disabled]="formGroup.invalid"
			(click)="onSubmit(formGroup.value)"
		>
			{{ 'GLOBAL.next' | transloco }}
		</button>
	</div>
</div>
