import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { CassAddressService } from 'src/app/core/services/ccas/cass-address.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { AddressTypeEnum, IAddressResult, ICassAddress } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import {
	AddressCategoryEnum,
	ISpousalContact
} from 'src/app/core/services/mobile-api/spousal-contact-api/spousal-contact-api.model';
import { SpousalContactApiService } from 'src/app/core/services/mobile-api/spousal-contact-api/spousal-contact-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { firstNameValidator, lastNameValidator } from 'src/app/shared/validators/form-validators';
import { isEmpty } from 'lodash';

@Component({
	selector: 'op-spousal-contact',
	templateUrl: './spousal-contact.component.html',
	styleUrls: ['./spousal-contact.component.scss']
})
export class SpousalContactComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private spousalContactApiService: SpousalContactApiService,
		private routingService: RoutingService,
		private cassAddressService: CassAddressService,
		private dialogService: DialogService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;
	spousalContactData: ISpousalContact;
	mailingAddress: IAddressResult;
	private subscription = new Subscription();

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				mergeMap((loanApp: ILoanApplication) => {
					const spousalContact$ = this.spousalContactApiService.getSpousalContact(loanApp.id);
					const addressList$ = this.mobileService.getAddresses(loanApp.id, 0, true);
					return forkJoin([spousalContact$, addressList$]);
				})
			)
			.subscribe({
				next: (result) => {
					this.spousalContactData = { ...result[0] };
					this.mailingAddress = { ...result[1].find((addr) => addr.type === AddressTypeEnum.mail) };
					this.bindSpousalContact();
				}
			});
		this.subscription.add(loanAppSub);
		this.onChange();
	}

	onChange(): void {
		const maritalChangeSub = this.formGroup.get('maritalStatus').valueChanges.subscribe({
			next: (val) => {
				if (val) {
					this.formGroup.get('firstName').setValidators(Validators.required);
					this.formGroup.get('lastName').setValidators(Validators.required);
				} else {
					this.formGroup.get('firstName').clearValidators();
					this.formGroup.get('lastName').clearValidators();
				}
				this.formGroup.get('firstName').updateValueAndValidity();
				this.formGroup.get('lastName').updateValueAndValidity();
			}
		});
		this.subscription.add(maritalChangeSub);
	}

	bindSpousalContact(): void {
		if (this.spousalContactData?.firstName) {
			this.formGroup.get('maritalStatus').setValue(true);
			if (this.mailingAddress && this.addressesAreEqual(this.mailingAddress, this.spousalContactData)) {
				this.formGroup.get('addressType').setValue(AddressCategoryEnum.mailAddress);
			} else {
				this.formGroup.get('addressType').setValue(AddressCategoryEnum.differentAddress);
				this.formGroup.get('addressDetail').setValue(this.createSpouseAddress());
			}
			this.formGroup.get('firstName').setValue(this.spousalContactData.firstName);
			this.formGroup.get('lastName').setValue(this.spousalContactData.lastName);
		} else {
			this.formGroup.get('maritalStatus').setValue(false);
		}
	}

	createSpouseAddress() {
		return {
			address1: this.spousalContactData?.streetAddress1 || null,
			address2: this.spousalContactData?.streetAddress2 || null,
			city: this.spousalContactData?.city || null,
			state: this.spousalContactData?.state || null,
			zipCode: this.spousalContactData?.postalCode || null,
			country: 'US'
		};
	}

	private addressesAreEqual = function (a, b) {
		return (
			a.city === b.city &&
			a.country === b.country &&
			a.postalCode === b.postalCode &&
			a.state === b.state &&
			a.streetAddress1 === b.streetAddress1 &&
			(a.streetAddress2 || undefined) === (b.streetAddress2 || undefined)
		);
	};

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			maritalStatus: [false],
			firstName: ['', [Validators.required, firstNameValidator()]],
			lastName: ['', [Validators.required, lastNameValidator()]],
			addressType: [AddressCategoryEnum.mailAddress],
			addressDetail: []
		});
	}

	createAddressObject(): Partial<ICassAddress> {
		const verifyAddress = this.formGroup.value;
		return {
			streetAddress1: verifyAddress?.addressDetail.address1,
			streetAddress2: verifyAddress?.addressDetail.address2 || '',
			city: verifyAddress?.addressDetail.city,
			state: verifyAddress?.addressDetail.state,
			postalCode: verifyAddress?.addressDetail.zipCode,
			country: 'US'
		};
	}

	navigateToRoute(): void {
		this.routingService.route(RoutingPathsEnum.esignConfirmTerm);
	}

	saveSpousalContact(address: Partial<ICassAddress>): void {
		const spousalContact = {
			streetAddress1: address?.streetAddress1,
			streetAddress2: address?.streetAddress2,
			postalCode: address?.postalCode,
			city: address?.city,
			state: address?.state,
			firstName: this.formGroup.get('firstName').value,
			lastName: this.formGroup.get('lastName').value,
			country: 'US'
		};

		const saveSpousalContactSub = this.spousalContactApiService
			.saveSpousalContact(spousalContact, this.loanAppService.loanApplicationId)
			.subscribe({
				next: () => {
					this.navigateToRoute();
				},
				error: (err) => {
					this.dialogService.openErrorDialog(err).subscribe();
				}
			});
		this.subscription.add(saveSpousalContactSub);
	}

	private saveVerifiedCassAddress(): void {
		const cassAddressSub = this.cassAddressService.verifyCassAddress(this.createAddressObject()).subscribe({
			next: (cassAddress) => {
				if (cassAddress) {
					// If the cass address attributes are empty using the original address
					if (['city', 'state', 'streetAddress1', 'postalCode'].every((prop) => !isEmpty(cassAddress[prop]))) {
						this.saveSpousalContact(cassAddress);
					} else {
						this.saveSpousalContact(this.createAddressObject());
					}
				} else {
					this.saveSpousalContact(this.createAddressObject());
				}
			}
		});
		this.subscription.add(cassAddressSub);
	}

	onSubmit(post: any): void {
		if (post?.maritalStatus) {
			if (this.formGroup.get('addressType').value === AddressCategoryEnum.mailAddress) {
				this.saveSpousalContact(this.mailingAddress);
			} else {
				this.saveVerifiedCassAddress();
			}
		} else {
			const deleteSpouseContactSub = this.spousalContactApiService
				.deleteSpousalContact(this.loanAppService.loanApplicationId)
				.subscribe({
					next: (resp) => {
						this.navigateToRoute();
					}
				});
			this.subscription.add(deleteSpouseContactSub);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
