import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';

export enum IdleTimeOutEnum {
	inactive,
	active,
	idle,
	logOut
}

@Injectable({
	providedIn: 'root'
})
export class IdleTimeoutService implements OnDestroy {
	private readonly subscription = new Subscription();

	private idleStartTime: number;
	private readonly defaultTimeoutMilliseconds: number = 1000 * 60 * 13; // 13 minutes
	private timeoutMilliseconds: number = 1000 * 60 * 13; // 13 minutes
	private idleTimerSubscription = new Subscription();
	private idleTimer$: Observable<number>;

	private lastTime: number;
	private dateTimer$: Observable<number>;
	private dateTimerSubscription = new Subscription();
	private dateTimerInterval: number = 1000 * 30 * 1;
	private dateTimerTolerance: number = 1000 * 10;

	private readonly idleTimeoutSource = new BehaviorSubject<IdleTimeOutEnum>(IdleTimeOutEnum.inactive);
	public readonly idleTimeout$ = this.idleTimeoutSource.asObservable().pipe(distinctUntilChanged());

	private authToken: boolean;

	constructor(private authService: AuthenticationService) {
		const authSub = this.authService.authorization$.subscribe({
			next: (auth) => {
				this.authToken = Boolean(auth?.token);
				if (!this.authToken && this.idleTimerSubscription) {
					this.stopTimer();
				}
			}
		});
		this.subscription.add(authSub);

		this.startTimer();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	 * monitors if there is a gap in time to detect if user computer slept and woke up.
	 *
	 * @private
	 * @memberof IdleTimeoutService
	 */
	private detectWakeUp() {
		this.lastTime = new Date().getTime();
		this.dateTimer$ = timer(this.dateTimerInterval);

		if (!this.dateTimerSubscription.closed) {
			this.dateTimerSubscription.unsubscribe();
		}

		this.dateTimerSubscription = this.dateTimer$.subscribe((n) => {
			const currentTime: number = new Date().getTime();
			const timeOutTime = this.idleStartTime + this.timeoutMilliseconds;

			if (currentTime > this.lastTime + this.dateTimerInterval + this.dateTimerTolerance) {
				// look for 10 sec diff
				if (currentTime > timeOutTime) {
					this.idleTimeoutSource.next(IdleTimeOutEnum.logOut);
				} else {
					// adjust the timeout.
					this.startTimer(timeOutTime - currentTime);
				}
			}
			this.dateTimerSubscription.unsubscribe();
			this.detectWakeUp();
		});
	}

	/**
	 * starts the idle timer
	 *
	 * @memberof IdleTimeoutService
	 */
	private startTimer(adjusted: number = null) {
		if (!this.idleTimerSubscription.closed) {
			this.stopTimer();
		} else {
			this.idleTimeoutSource.next(IdleTimeOutEnum.inactive);
		}

		if (this.authToken) {
			this.timeoutMilliseconds = adjusted || this.defaultTimeoutMilliseconds;
			this.idleTimer$ = timer(this.timeoutMilliseconds);

			this.idleStartTime = new Date().getTime();

			this.detectWakeUp();
			this.idleTimeoutSource.next(IdleTimeOutEnum.active);
			this.idleTimerSubscription = this.idleTimer$.subscribe((n) => {
				this.timerComplete(n);
			});
		}
	}

	/**
	 * Stops the idle timer.
	 *
	 * @memberof IdleTimeoutService
	 */
	private stopTimer() {
		this.dateTimerSubscription.unsubscribe();
		this.idleTimerSubscription.unsubscribe();
		this.idleTimeoutSource.next(IdleTimeOutEnum.inactive);
	}

	/**
	 * reset the idle timer.
	 *
	 * @memberof IdleTimeoutService
	 */
	public resetTimer() {
		this.startTimer();
	}

	private timerComplete(n: number) {
		if (this.authToken) {
			this.idleTimeoutSource.next(IdleTimeOutEnum.idle);
		} else {
			this.stopTimer();
		}
	}
}
