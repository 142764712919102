import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { FinishedFileUploadsPipe } from 'src/app/shared/pipes/finished-file-uploads/finished-file-uploads.pipe';

import { DocumentSubmitService } from './document-submit.service';
import { OcrEligibilityEnum } from './guards/auto-eligibility.resolver';

@Component({
	selector: 'op-document-submit',
	templateUrl: './document-submit.component.html',
	styleUrls: ['./document-submit.component.scss'],
	providers: [DocumentSubmitService, FinishedFileUploadsPipe]
})
export class DocumentSubmitComponent {
	public ocrEligibilityEnum: typeof OcrEligibilityEnum = OcrEligibilityEnum;

	constructor(private route: ActivatedRoute) {}

	autoVerificationEligible$ = this.route.data.pipe(map((data) => data.autoVerificationEligible));
}
