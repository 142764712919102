import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';

export enum OcrEligibilityEnum {
	newExperience = 'newExperience',
	newExperienceDelay = 'newExperienceDelay',
	originalExperience = 'originalExperience'
}

@Injectable({
	providedIn: 'root'
})
export class AutoEligibilityResolver implements Resolve<OcrEligibilityEnum> {
	constructor(private loanApplicationService: LoanApplicationService) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OcrEligibilityEnum> {
		return this.loanApplicationService
			.queryOcrEligibility()
			.pipe(catchError(() => of(OcrEligibilityEnum.originalExperience)));
	}
}
