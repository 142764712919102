<div class="plaid-refresh-failed" *transloco="let t">
	<op-content-container class="content">
		<div class="plaid-refresh">
			<op-learn-more
				class="op-pad-20"
				src="{{ failedInstitutions.length ? '/assets/images/link-accounts.svg' : '/assets/images/connect-bank.svg' }}  "
			>
				<div>
					<strong
						class="op-header-1-font refresh-bank"
						id="reconnectAccountLabel"
						[innerHtml]="t('PLAID_RECONNECT.yourBankAccounts')"
					>
					</strong>
				</div>
			</op-learn-more>

			<section class="op-mb-20">
				<div
					class="op-dark-grey"
					*ngIf="failedInstitutions.length; else connected_successfully"
					[innerHtml]="t('PLAID_RECONNECT.unableToConnect')"
				></div>
				<ng-template #connected_successfully>
					<div class="op-dark-grey" [innerHtml]="t('PLAID_RECONNECT.connectedSuccessfully')"></div>
				</ng-template>
			</section>

			<section id="connectedBankAcxcounts">
				<div id="newAccounts" class="op-mt-20" *ngIf="_accountList.length">
					<div *ngFor="let account of accountListByBank | keyvalue; index as i" class="op-mtb-20 plaid-account-group">
						<div class="plaid-account">
							<div class="plaid-account-container">
								<div>
									<div class="account-name">
										<label>{{ account.key }}</label>
									</div>
									<div class="account-list">
										<div class="op-label-font op-gray line-height-md" *ngFor="let account of account.value">
											{{ account.accountType | titlecase }} {{ account.accountNumberLastFour | slice: -4 }}
										</div>
									</div>
								</div>

								<div>
									<op-badge small="true" id="account{{ i }}" class="ready" icon="op-checkMark-green">
										{{ t('PLAID_RECONNECT.connected') }}
									</op-badge>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="_connectedInstitutions?.institutions?.length">
					<div
						*ngFor="let institution of _connectedInstitutions?.institutions; index as i"
						class="plaid-account-group op-mtb-20"
					>
						<div class="plaid-account">
							<div class="plaid-account-container">
								<div>
									<div class="account-name">
										<label>{{ institution.name }}</label>
									</div>
									<div class="account-list">
										<div
											class="op-label-font op-gray line-height-md"
											*ngFor="let account of institution.accounts; let i = index"
										>
											{{ account.accountType | titlecase }} {{ account.accountNumberLastFour | slice: -4 }}
										</div>
									</div>
								</div>

								<div>
									<op-badge
										small="true"
										id="institution_{{ i }}"
										[icon]="institution.refreshStatus === 'SUCCESS' ? 'op-checkMark-green' : ''"
										[ngClass]="institution.refreshStatus === 'SUCCESS' ? 'ready' : 'needs'"
									>
										{{
											institution.refreshStatus === 'SUCCESS'
												? t('PLAID_RECONNECT.connected')
												: t('PLAID_RECONNECT.disconnected')
										}}
									</op-badge>
								</div>
							</div>
							<div class="plaid-refresh-btn" *ngIf="institution.refreshStatus !== 'SUCCESS'">
								<button
									mat-flat-button
									color="primary"
									class="plaid-reconnect"
									id="plaid_connect"
									type="button"
									(click)="openPlaid.emit()"
								>
									<label>{{ t('PLAID_RECONNECT.reconnect') }}</label>

									<mat-icon class="op-ml-10" svgIcon="op-popout"> </mat-icon>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					class="plaid-account-group add-bank-container op-mtb-10"
					id="addBankContainer"
					(click)="this.openPlaid.emit()"
				>
					<div class="add-bank-inner-container">
						<mat-icon svgIcon="op-add2" class="add-icon"> </mat-icon>
						<span class="op-body-1-bold-font op-ml-10">{{ t('PLAID_RECONNECT.addNewBank') }}</span>
					</div>
					<mat-icon svgIcon="op-caretRightSmall" class="op-caret"></mat-icon>
				</div>
			</section>
		</div>

		<div class="op-label-font op-mtb-20">
			<p class="policy" [innerHTML]="t('PLAID_CONNECT.youAgreePlaidAccess')"></p>

			<p class="policy" [innerHTML]="t('PLAID_CONNECT.reviewPlaidTerms')"></p>
		</div>
		<div class="op-center op-mb-20">
			<button
				mat-flat-button
				color="primary"
				class="op-full-width"
				id="continue"
				(click)="confirm()"
				[opTagClick]="confirm"
			>
				{{ t('PLAID_RECONNECT.continue') }}
			</button>
		</div>
		<hr />
		<op-website-terms class="op-mt-10"></op-website-terms>
	</op-content-container>
</div>
