<div class="upload-display" *transloco="let t">
	<ng-content select="[uploadIdleTitle]"></ng-content>
	<div
		class="dnd-container"
		opDragDrop
		id="{{ id + '_drop_zone' }}"
		(fileDropped)="fileDropped.emit($event)"
		[opTagClick]="id + '_upload'"
	>
		<ng-content select="[uploadIdleInfo]"></ng-content>
		<div class="op-mt-10">
			<button
				mat-flat-button
				color="primary-small"
				class="op-mt-10"
				name="{{ id + '_upload_button' }}"
				id="{{ id + '_upload_button' }}"
				[disabled]="disabled"
				(click)="opClick.emit($event)"
				[opTagClick]="id + '_upload_button'"
			>
				<ng-content select="[uploadIdleButtonText]"></ng-content>
			</button>
		</div>
	</div>
</div>
