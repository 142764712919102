<div class="find-store-item">
	<div>
		<div>
			<h5>{{ store.name }}</h5>
		</div>
		<div>
			<h5>{{ store.address }}</h5>
		</div>
		<div>
			<h6>{{ store.distance }} {{ 'FIND_STORE.miles' | transloco }}</h6>
		</div>
	</div>
	<div class="chevron">
		<mat-icon svgIcon="op-caretRightSmall"></mat-icon>
	</div>
</div>
