import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, isFunction } from '@ngneat/transloco';
import { Subscription, filter, noop, of, switchMap, take, map, catchError } from 'rxjs';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	AssetRefreshEnum,
	ConfigApiService,
	IConsentRequest,
	IInstitution,
	IPlaidConnectedInstitutions,
	MobileApiService
} from 'src/app/core/services/mobile-api';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { OrganizationEnum } from 'src/app/core/utils/organization-utils';
import { map as _map, filter as _filter } from 'lodash';
import { Router } from '@angular/router';

export enum PlaidConsent {
	'YES' = 'Y',
	'NO' = 'N'
}
@Component({
	selector: 'op-plaid-refresh',
	templateUrl: './plaid-refresh.component.html',
	styleUrls: ['./plaid-refresh.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PlaidRefreshComponent implements OnInit, OnDestroy {
	protected showRefreshReconnectScreen: boolean;
	accountList: IAchBankAccount[] = [];
	showNoAccountsError: boolean;
	plaidRefreshRetry: boolean = false;
	private subscription = new Subscription();
	connectedInstitutions: IPlaidConnectedInstitutions;
	private readonly institutionId = 'institutionId';

	constructor(
		private bankAccountService: AchBankAccountsService,
		private loanApplicationService: LoanApplicationService,
		private mobileApiService: MobileApiService,
		private plaidLinkService: PlaidLinkService,
		private dialogService: DialogService,
		private tagDataService: TagDataService,
		private routingService: RoutingService,
		private snackBar: MatSnackBar,
		private translocoService: TranslocoService,
		private router: Router,
		private configApiService: ConfigApiService
	) {}

	ngOnInit(): void {
		let sessionStorageData = this.plaidLinkService.getPlaidSessionData();
		const applicant = this.loanApplicationService.getCurrentApplicant();
		const eligible = applicant.incomeVerificationEligible || applicant.bankAccountVerificationEligible;

		if (!sessionStorageData?.isOauthFlow) {
			this.plaidLinkService.initializePlaid(true, eligible, this.routingService.getCurrentRoute());
		} else {
			this.plaidLinkService.initAndOpenPlaidOauth(true, eligible, this.routingService.getCurrentRoute());
		}
		this.bankAccountService.refreshBankAccounts();
		const bankSub = this.bankAccountService.bankAccounts$.pipe(filter(Boolean)).subscribe({
			next: (rsp: IAchBankAccount[]) => {
				this.accountList = rsp?.filter((account) => account.verificationStatus === AccountStatusEnum.verified);
			}
		});
		this.subscription.add(bankSub);

		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);
		this.getConnectedInstitutions();
		this.getConfigValue();
	}

	getConfigValue(): void {
		this.configApiService
			.configEnablePlaidRefreshRetry()
			.pipe(
				map((res) => res.value),
				catchError((err) => {
					return of(false);
				})
			)
			.subscribe({
				next: (value) => {
					this.plaidRefreshRetry = value;
					const tagEvent = {
						event_action: 'on_page_load',
						applicant_id: this.loanApplicationService.loanApplicationId,
						issuing_org: this.loanApplicationService.getLoanApp()?.issuingOrganization,
						clientId: this.loanApplicationService.getCurrentApplicant()?.clientId,
						plaid_refresh_retry: String(this.plaidRefreshRetry),
						consent_readonly: this.connectedInstitutions?.readOnly,
						plaid_consent: this.connectedInstitutions?.plaidConsent
					};
					this.tagDataService.view({}, tagEvent);
				}
			});
	}

	getConnectedInstitutions(): void {
		this.mobileApiService
			.getSavedAchAccounts(
				this.loanApplicationService.loanApplicationId,
				this.loanApplicationService.getCurrentApplicant().applicantIndex
			)
			.pipe(take(1))
			.subscribe({
				next: (rsp: IPlaidConnectedInstitutions) => {
					this.connectedInstitutions = rsp;
					this.showRefreshReconnectScreen = rsp.plaidConsent === PlaidConsent.YES;
				}
			});
	}

	onBankConnect(event: IBankConnectEvent): void {
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		if (event?.section === FileUploadType.income) {
			this.showNoAccountsError = event.data.responseStatus !== BankConnectResponseStatusEnum.accountsFound;
			const accountsFound: boolean = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;

			if (!accountsFound) {
				this.showNoAccountsDialog(accountsFound);
			} else {
				this.getConnectedInstitutions();
				this.showBankAddedNotification();
			}
		}
	}

	showNoAccountsDialog(accountsFound: boolean): void {
		const msgData = this.plaidLinkService.getBankDialogMessage(accountsFound);
		if (msgData) {
			this.dialogService
				.openMessageDialog(
					msgData,
					() => of(),
					() => of()
				)
				.subscribe({
					next: () => {
						this.tagDataService.link(
							{},
							{
								tealium_event: 'bank_not_found'
							}
						);
					}
				});
		}
	}

	showBankAddedNotification(): void {
		this.snackBar.open(this.translocoService.translate('PLAID_REFRESH.bankAccountAdded'), '', {
			duration: 3000
		});
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = true;
	}

	confirmRefresh(): void {
		const InstitutionsList = this.getInstitutionsList();
		const selectedInstitutions = _filter(InstitutionsList, { select: true });
		this.tagDataService.link(
			{},
			{
				tealium_event: 'Confirm Refresh',
				event_action: 'Submit',
				selected_accounts_count: selectedInstitutions.length,
				consent_readonly: this.connectedInstitutions?.readOnly
			}
		);
		if (
			this.connectedInstitutions.plaidConsent !== PlaidConsent.YES &&
			!this.connectedInstitutions?.readOnly &&
			selectedInstitutions.length
		) {
			const payload: IConsentRequest = {
				consentDescription: AssetRefreshEnum.plaidAssetRefreshConsent,
				consentStatus: PlaidConsent.YES,
				organization: OrganizationEnum.METABANK,
				institutions: InstitutionsList
			};
			this.mobileApiService
				.setAchAccountsConsent(
					payload,
					this.loanApplicationService.loanApplicationId,
					this.loanApplicationService.getCurrentApplicant().applicantIndex
				)
				.pipe(
					take(1),
					switchMap((_res) => {
						const institutionIds = { institutions: _map(this.connectedInstitutions.institutions, this.institutionId) };
						return this.mobileApiService
							.setRefreshPlaidAccounts(
								institutionIds,
								this.loanApplicationService.loanApplicationId,
								this.loanApplicationService.getCurrentApplicant().applicantIndex
							)
							.pipe(take(1));
					})
				)
				.subscribe({
					next: (rsp) => {
						if (!rsp.success && this.plaidRefreshRetry) {
							this.connectedInstitutions = null;
							this.getConnectedInstitutions();
						} else {
							this.navigateToNextScreen();
						}
					}
				});
		} else {
			this.navigateToNextScreen();
		}
	}

	declineRefresh(): void {
		if (this.connectedInstitutions.plaidConsent != PlaidConsent.NO) {
			const payload: IConsentRequest = {
				consentDescription: AssetRefreshEnum.plaidAssetRefreshConsent,
				consentStatus: PlaidConsent.NO,
				organization: OrganizationEnum.METABANK,
				institutions: []
			};
			this.mobileApiService
				.setAchAccountsConsent(
					payload,
					this.loanApplicationService.loanApplicationId,
					this.loanApplicationService.getCurrentApplicant().applicantIndex
				)
				.pipe(take(1))
				.subscribe({
					next: (result) => {
						this.navigateToNextScreen();
					},
					error: (err) => {
						console.error('Error in subscription:', err);
					}
				});
		} else {
			this.navigateToNextScreen();
		}
	}

	openPlaid(): void {
		this.plaidLinkService.openPlaid(null, this.router.url, this.routingService.getCurrentRoute());
	}

	getInstitutionsList(): Partial<IInstitution>[] {
		return _map(this.connectedInstitutions.institutions, (institution) => {
			return {
				select: institution.select,
				institutionId: institution.institutionId
			};
		});
	}

	navigateToNextScreen(): void {
		this.routingService.routeFromLoanApp();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
