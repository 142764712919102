<div class="store-less-disbursement" *transloco="let t">
	<div *ngIf="initialize">
		<div *ngIf="showBackToOffers" class="op-mb-20 op-mt-20">
			<a
				mat-flat-button
				color="link-no-dec"
				id="adjustTerms"
				class="back-to-other-offer"
				onclick="return false"
				(click)="returnToOffers()"
			>
				<mat-icon svgIcon="op-caretLeft"></mat-icon>
				{{ t('STORE_LESS.backToOffers') }}
			</a>
		</div>
		<op-masthead *ngIf="this.isSubstatusNullFlag; else dmvPendingTitle" [title]="t('STORE_LESS.title')"></op-masthead>
		<ng-template #dmvPendingTitle>
			<op-masthead [title]="t('STORE_LESS.dmvPendingTitle')"></op-masthead>
		</ng-template>

		<div class="op-center op-mb-20">
			<img alt="" src="assets/images/icon_ready_to_submit.svg" />
		</div>
		<op-content-container class="content">
			<div *ngIf="isSubstatusNullFlag">
				<span [innerHTML]="t('STORE_LESS.threeOptions')"></span>
				<span>{{ t('STORE_LESS.closestStore', { distance: storeDistance }) }}</span>
				<hr class="op-mtb-20" />

				<span>{{ t('STORE_LESS.callUs') }}</span>

				<div id="callNowDiv" class="op-mtb-20">
					<button id="callNowBtn" mat-flat-button color="primary" name="submitBtn" (click)="triggerCallDialog()">
						{{ t('STORE_LESS.callNow') }}
					</button>
				</div>
			</div>

			<div *ngIf="!isSubstatusNullFlag">
				<div id="upsNote" class="op-mb-20" [innerHTML]="t('STORE_LESS.upsNote')"></div>
				<div id="overnightShippingNote" [innerHTML]="t('STORE_LESS.overnightShippingNote')"></div>
				<hr class="op-mtb-20" />
			</div>

			<span [innerHTML]="t('STORE_LESS.autoServicingTeam')"></span>
			<div class="op-mtb-20">
				<span id="autoTeam_hours">{{ t('STORE_LESS.autoTeam') }}</span
				><br />
				<span id="fullHours">{{ t('STORE_LESS.fullHours') }}</span
				><br />
				<br />
				<span id="auto_servicing_team_phone"
					>{{ t('STORE_LESS.optionPhone') }}&#58;
					<a mat-flat-button color="link-bold" href="tel:18557545491" id="callCustomerSupportLink">
						1-855-754-5491
					</a></span
				>
			</div>
		</op-content-container>
	</div>
</div>
