import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';

import { LandingService } from '../landing/landing.service';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { SessionStorageService } from '../storage/session-storage.service';

export interface IAuthorization {
	token: string;
}

/**
 * Service to track if the user is authenticated.
 * Stores the token.
 *
 * @export
 * @class AuthenticationService
 */
@Injectable({
	providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
	private readonly authorizationSource = new BehaviorSubject<IAuthorization>(null);

	// Exposed observable (read-only).
	readonly authorization$ = this.authorizationSource.asObservable();
	private readonly storageKey = 'auth';
	private readonly subscription = new Subscription();

	constructor(
		private landingService: LandingService,
		private mobileApiService: MobileApiService,
		private sessionStorageService: SessionStorageService
	) {
		const sessionData = this.sessionStorageService.get(this.storageKey);
		this.authorizationSource.next(sessionData ? sessionData : {});

		const storageKeySub = this.sessionStorageService.select(this.storageKey).subscribe({
			next: (value) => {
				this.authorizationSource.next(value ? value : {});
			},
			complete: () => {
				this.authorizationSource.next(null);
			}
		});
		this.subscription.add(storageKeySub);

		// watch for a token to arrive.
		const landingSub = this.landingService.landing$
			.pipe(
				map((rsp) => rsp?.token),
				filter((token) => Boolean(token)),
				distinctUntilChanged()
			)
			.subscribe((token) => {
				this.updateAuthorizationToken(token);
			});
		this.subscription.add(landingSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	 * Get latest token
	 *
	 * @returns {string}
	 * @memberof AuthenticationService
	 */
	getAuthorizationToken(): string {
		return this.authorizationSource.getValue()?.token || '';
	}

	/**
	 * Clear the authorization token.
	 *
	 * @memberof AuthenticationService
	 */
	clearAuthorizationToken(): void {
		this.updateAuthorizationToken(null);
	}

	updateAuthorizationToken(token: string): void {
		const auth = { ...this.authorizationSource.getValue(), token };
		this.sessionStorageService.set(this.storageKey, auth);
	}

	signOut(): Observable<any> {
		return this.mobileApiService.setSignOut().pipe(
			catchError(() => of()),
			finalize(() => {
				this.clearAuthorizationToken();
				this.sessionStorageService.clear();
			})
		);
	}
}
