import { ILoanApplication } from '../loan-application.model';
import { DisbursementChannelEnum, DisbursementType, IDisbursement } from './disbursement.model';

export class DisbursementUtils implements IDisbursement {
	static fromLoanApp(loanApp: ILoanApplication): DisbursementUtils {
		return new this(loanApp?.disbursement || <IDisbursement>{});
	}

	constructor(readonly disbursement: IDisbursement) {
		Object.assign(this, disbursement);
	}

	isChannelEmpty(): boolean {
		return this.disbursement?.disbursementChannel ? false : true;
	}

	isChannelOnline(): boolean {
		return this.disbursement?.disbursementChannel === DisbursementChannelEnum.online;
	}

	isChannelStore(): boolean {
		return this.disbursement?.disbursementChannel === DisbursementChannelEnum.store;
	}

	isChannelPartnerStore(): boolean {
		return this.disbursement?.disbursementChannel === DisbursementChannelEnum.partnerStore;
	}

	isChannelAnyStore(): boolean {
		return this.isChannelStore() || this.isChannelPartnerStore();
	}

	isTypeAch(): boolean {
		return this.disbursement?.disbursementType === DisbursementType.ach;
	}

	isTypeCheck(): boolean {
		return this.disbursement?.disbursementType === DisbursementType.check;
	}

	isTypeCash(): boolean {
		return this.disbursement?.disbursementType === DisbursementType.cash;
	}

	isOnlineAch(): boolean {
		return this.isTypeAch() && this.isChannelOnline();
	}

	isOnlineCheck(): boolean {
		return this.isTypeCheck() && this.isChannelOnline();
	}

	isPartnerCash(): boolean {
		return this.isTypeCash() && this.isChannelPartnerStore();
	}

	isDebitCard(): boolean {
		return this.disbursement?.disbursementType === DisbursementType.debit;
	}

	hasBankAccountAndAutoPayNotComplete(): boolean {
		return this.disbursement?.disbursementBankAccountId && !this.disbursement?.notificationAutoPayCompleted;
	}

	isRachEligible(): boolean {
		return this.disbursement?.disbursementBankAccountId && !this.disbursement?.rachAccountId;
	}

	getDisbursementAccountId(): number {
		return this.disbursement?.disbursementBankAccountId;
	}

	isNotificationAutoPayCompleted(): boolean {
		return this.disbursement?.notificationAutoPayCompleted;
	}

	isOnlineNotificationEnabled(): boolean {
		return this.disbursement?.onlineNotificationEnabled;
	}

	isNotificationTermsAdjusted(): boolean {
		return this.disbursement?.notificationTermsAdjusted;
	}

	isNotificationAdditionalContactRequired(): boolean {
		return this.disbursement?.notificationAdditionalContactRequired;
	}

	isNotificationAdditionalContactCompleted(): boolean {
		return this.disbursement?.notificationAdditionalContactCompleted;
	}

	isNotificationSpouseContactRequired(): boolean {
		return this.disbursement?.notificationSpouseContactRequired;
	}

	isNotificationSpouseContactCompleted(): boolean {
		return this.disbursement?.notificationSpouseContactCompleted;
	}

	getOriginationDisbursementChannel(): string {
		return this.disbursement?.originationDisbursementChannel;
	}

	//IDisbursement interface
	loanApplicationId: number;
	disbursementChannel?: null;
	disbursementType?: null;
	originationDisbursementChannel?: null;
	onlineDisbursementUnavailable: boolean;
	onlineNotificationEnabled: boolean;
	notificationTermsAdjusted: boolean;
	notificationTermsAdjustedSource?: null;
	notificationAdditionalContactRequired: boolean;
	notificationAdditionalContactCompleted: boolean;
	notificationAdditionalContactCompletedSource?: null;
	additionalContactId?: null;
	notificationSpouseContactRequired: boolean;
	notificationSpouseContactCompleted: boolean;
	notificationSpouseContactCompletedSource?: null;
	spouseContactId?: null;
	notificationAutoPayCompleted: boolean;
	notificationAutoPayCompletedSource?: null;
	notificationCompleteSource?: null;
	rachAccountId?: null;
	disbursementDate?: null;
	disbursedAtStoreId?: null;
	disburserId?: null;
	legalDocumentId?: null;
	envelopeId?: null;
	disbursementBankAccountId?: null;
	disbursementTarjetaAccountId?: null;
	disbursementTarjetaOrderKey?: null;
	debitCardPlan?: null;
	debitCardSmsOptOut?: null;
	replacementDebitCardPrn?: null;
	issueReplacementDebitCard?: null;
	disbursementCheckAmount?: null;
	disbursementCheckNumber?: null;
	documentSigningChannel?: null;
	kioskEsignCode?: null;
	originationDisbursementType?: null;
}
