<div class="document-thumbnails" *transloco="let t">
	<div class="file-title" *ngIf="files?.length">
		<ng-content></ng-content>
	</div>
	<div class="file-list-wrapper">
		<div
			class="file-list-item-wrapper"
			[ngClass]="'file-status-' + (file.status | lowercase)"
			*ngFor="let file of files; let i = index; let isLast = last; trackBy: trackBy"
		>
			<div
				class="file-list-item"
				*ngIf="
					file?.status === fileUploadStatusEnum.finished && file?.ocrInfo?.documentStatus !== fileStatusEnum.pending
				"
			>
				<div class="img-wrapper">
					<img
						*ngIf="file.thumbnail"
						ngClass="thumbnail-img"
						id="{{ id + '_image_' + i }}"
						alt=""
						[src]="file.thumbnail"
					/>
					<img
						*ngIf="!file.thumbnail"
						ngClass="default-img"
						id="{{ id + '_svg_' + i }}"
						alt=""
						src="/assets/images/icons/op-document.svg"
					/>
				</div>
				<div class="message-wrapper">
					<op-document-badge
						*ngIf="file.ocrInfo?.documentStatus === fileStatusEnum.review"
						class="attention no-border op-mb-5"
						id="{{ id + '_needsAttention_' + i }}"
						[small]="true"
					>
						<div *ngIf="file.ocrInfo?.documentStatus === fileStatusEnum.review">
							<div class="op-black" *ngFor="let msg of file?.ocrInfo?.messages; let isLast = last">
								{{ msg }}
							</div>
						</div>
					</op-document-badge>
					<div class="file-name op-light-black op-micro-font" id="{{ id + '_name_' + i }}" title="{{ file.name }}">
						{{ file.name }}
					</div>
				</div>
			</div>
			<div
				class="file-list-item-progress"
				*ngIf="
					file?.status === fileUploadStatusEnum.started ||
					file?.status === fileUploadStatusEnum.uploading ||
					file?.ocrInfo?.documentStatus === fileStatusEnum.pending
				"
			>
				<div class="message-wrapper">
					<div
						class="op-micro-font op-light-black file-name no-under-line"
						id="{{ id + '_name_' + i }}"
						title="{{ file.name }}"
					>
						{{ file.name }}
					</div>
					<div class="op-mt-8">
						<mat-progress-bar class="file-progress" mode="determinate" [value]="file?.progressData?.fileProgress">
						</mat-progress-bar>
						<div class="op-body-2-font op-black taking-longer">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processingDocument') }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!isLast" class="op-mb-10"></div>
		</div>
	</div>
</div>
