<div class="document-upload" *transloco="let t">
	<input
		type="file"
		class="file-input"
		id="{{ id + '_file' }}"
		[accept]="requiredFileType"
		(change)="onFileSelected($event)"
		#fileUpload
	/>

	<div class="title-wrapper op-mb-8">
		<ng-content select="[uploadTitle]"></ng-content>
	</div>

	<op-upload-display-idle
		*ngIf="!showSingleFile || (showSingleFile && !singleFile) || hideSingleFileImage"
		id="{{ id + '_idle' }}"
		[disabled]="disabled"
		(opClick)="collateUploads ? onCollate() : fileUpload.click()"
		(fileDropped)="onFileDropped($event)"
	>
		<ng-content select="[uploadIdleTitle]" uploadIdleTitle></ng-content>
		<ng-content select="[uploadIdleInfo]" uploadIdleInfo></ng-content>
		<ng-content select="[uploadIdleButtonText]" uploadIdleButtonText></ng-content>
	</op-upload-display-idle>

	<op-upload-display-pending
		*ngIf="showSingleFile && singleFile && !showProgress && !hideSingleFileImage"
		id="{{ id + '_pending' }}"
		[disabled]="disabled"
		(opDeleteClick)="removeSelectedFile(singleFile.id)"
		[showReUploadButton]="showReUploadButton"
		(opReUploadClick)="collateUploads ? onCollate() : fileUpload.click()"
		(fileDropped)="onFileDropped($event)"
		[singleFile]="singleFile"
	>
		<ng-content select="[uploadPendingTitle]" uploadPendingTitle></ng-content>
		<ng-content select="[uploadPendingInfo]" uploadPendingInfo></ng-content>
		<ng-content select="[uploadPendingButtonText]" uploadPendingButtonText></ng-content>
		<ng-content select="[reUploadPendingButtonText]" reUploadPendingButtonText></ng-content>
	</op-upload-display-pending>

	<op-upload-display-processing
		*ngIf="showSingleFile && singleFile && showProgress"
		id="{{ id + '_processing' }}"
		[singleFile]="singleFile"
		[uploadProgress]="uploadProgress"
	>
	</op-upload-display-processing>

	<div *ngIf="!showSingleFile">
		<op-document-v-thumbnails id="{{ id + '_fileThumbnails' }}" [files]="categoryFiles" [categoryType]="categoryType">
			<div class="op-body-1-bold-font op-mtb-20">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadedDocuments') }} ({{ categoryFiles?.length }})
			</div>
			<ng-content select="[criteriaMet]"></ng-content>
		</op-document-v-thumbnails>
	</div>
</div>
