import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { ISpousalContact, ISpousalContactResult } from './spousal-contact-api.model';

@Injectable({
	providedIn: 'root'
})
export class SpousalContactApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	getSpousalContact(loanId: number, applicantIndex = 0): Observable<ISpousalContactResult> {
		return this.http.get<ISpousalContactResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/spouse_contact`
		);
	}

	saveSpousalContact(
		spousalContact: ISpousalContact,
		loanId: number,
		applicantIndex = 0
	): Observable<ISpousalContactResult> {
		return this.http.put<ISpousalContactResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/spouse_contact`,
			spousalContact
		);
	}

	deleteSpousalContact(loanId: number, applicantIndex = 0): Observable<ISpousalContactResult> {
		return this.http.delete<ISpousalContactResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/spouse_contact`
		);
	}
}
