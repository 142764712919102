import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { opRequired } from '../../../../shared/decorators/required.decorator';

@Component({
	selector: 'op-document-notification',
	templateUrl: './document-notification.component.html',
	styleUrls: ['./document-notification.component.scss']
})
export class DocumentNotificationComponent {
	@Input()
	@opRequired()
	id: string;

	constructor() {}
}
