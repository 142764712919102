import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { PreQualifyRoutingModule } from './pre-qualify-routing.module';
import { PreQualifyComponent } from './pre-qualify/pre-qualify.component';
import { AddressComponent } from './address/address.component';
import { IdentificationComponent } from './identification/identification.component';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';
import { IncomeSourceComponent } from './income-source/income-source.component';
import { AddIncomeSourceDialogComponent } from './income-source/add-income-source-dialog/add-income-source-dialog.component';
import { DebtSourcesComponent } from './debt-sources/debt-sources.component';
import { AddDebtSourceDialogComponent } from './debt-sources/add-debt-source/add-debt-source-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './landing/landing.component';
import { PreQualifyGuard } from './pre-qualify.guard';

@NgModule({
	declarations: [
		PreQualifyComponent,
		AddressComponent,
		DebtSourcesComponent,
		AddDebtSourceDialogComponent,
		IdentificationComponent,
		AdditionalInformationComponent,
		IncomeSourceComponent,
		AddIncomeSourceDialogComponent,
		LandingComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		TranslocoModule,
		PreQualifyRoutingModule,
		NgxMaskModule.forChild(),
		BrowserAnimationsModule
	],
	providers: [PreQualifyGuard]
})
export class PreQualifyModule {}
