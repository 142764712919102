import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { pick } from 'lodash';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IUpdateEsignSignerStatus } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-esign-redirect-landing-page',
	template: '',
	styles: ['']
})
export class EsignRedirectLandingPageComponent implements OnInit, OnDestroy {
	constructor(
		private activatedRoute: ActivatedRoute,
		private mobileApiService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService
	) {}

	private subscription = new Subscription();

	ngOnInit(): void {
		const queryParametersSub = this.activatedRoute.queryParams
			.pipe(concatMap((query: Params) => forkJoin([of(query?.event), this.updateEsignSignerStatus(query)])))
			.subscribe({
				next: ([event, EsignStatus]) => {
					this.loanAppService.updateLoanApplication().subscribe({
						next: () => {
							this.routeToEsign(event);
						}
					});
				}
			});
		this.subscription.add(queryParametersSub);
	}

	private routeToEsign(event: string): void {
		let nextRoute = RoutingPathsEnum.home;
		const eventMap = {
			viewing_complete: RoutingPathsEnum.esignEventViewingCompleted,
			signing_complete: RoutingPathsEnum.esignEventSigningCompleted,
			decline: RoutingPathsEnum.esignEventSigningDeclined,
			cancel: RoutingPathsEnum.esignEventSigningCanceled,
			id_check_failed: RoutingPathsEnum.esignEventSigningFailed,
			session_timeout: RoutingPathsEnum.findYourApplication
		};
		nextRoute = eventMap[event] ? eventMap[event] : nextRoute;
		event === 'session_timeout'
			? this.routingService.route(nextRoute, { queryParams: { sessionInactivity: true } })
			: this.routingService.route(nextRoute, { queryParams: { status: event } });
	}

	private updateEsignSignerStatus(queryParameters: Params): Observable<any> {
		const { loanApplicationId } = queryParameters;
		const requestParameters: IUpdateEsignSignerStatus = pick(queryParameters, [
			'applicantId',
			'clientId',
			'envelopeId',
			'esignPreferredLanguage',
			'loanApplicationId',
			'token',
			'event'
		]);

		return this.mobileApiService.updateEsignSignerStatus(requestParameters, Number(loanApplicationId));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
