<div class="account-not-founded" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button
				mat-icon-button
				id="fundingMethodConfirmCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<p class="account-not-found-title" innerHTML="{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.notConnected') }}"></p>
	</div>
	<div mat-dialog-content>
		<div class="op-center op-mtb-10"><img src="assets/images/icon_manual_add_bank.svg" /></div>
		<div>
			{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.notConnectedMessage') }}
		</div>
	</div>
	<div mat-dialog-actions class="footer">
		<button mat-flat-button color="primary-small" (click)="onAddManuallyClick()">
			{{ 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.addAccountManually' | transloco }}
		</button>
	</div>
	<div mat-dialog-actions class="footer">
		<button mat-flat-button color="primary-small" (click)="onUsingPlaidClick()">
			{{ 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.addAccountUsingPlaid' | transloco }}
		</button>
	</div>
</div>
