<div class="addressDetail" [formGroup]="formGroup" *transloco="let t">
	<mat-form-field class="form-element op-full-width" appearance="outline">
		<mat-label>{{ t('ADDRESS.address1') }}</mat-label>
		<input
			matInput
			formControlName="address1"
			name="address1"
			id="{{ id + '_address1' }}"
			opAddressAutoComplete
			[latLngLimiter]="latLngLimiter"
			(addressChange)="handleAddressChange($event)"
		/>
		<mat-error
			id="{{ id + '_address1Error' }}"
			*ngIf="!formGroup.controls['address1'].valid && formGroup.controls['address1'].touched"
		>
			{{ t('ADDRESS.streetAddress1Error') }}
		</mat-error>
	</mat-form-field>

	<mat-form-field class="form-element op-full-width" appearance="outline">
		<mat-label>{{ t('ADDRESS.address2') }}</mat-label>
		<input
			matInput
			type="text"
			name="address2"
			id="{{ id + '_address2' }}"
			formControlName="address2"
			aria-required="true"
		/>
	</mat-form-field>

	<div class="city-state">
		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('ADDRESS.city') }} </mat-label>
			<input matInput type="text" name="city" id="{{ id + '_city' }}" formControlName="city" aria-required="true" />
		</mat-form-field>
		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('ADDRESS.state') }} </mat-label>
			<mat-select
				formControlName="state"
				name="state"
				id="{{ id + '_state' }}"
				#state
				aria-label="state"
				aria-required="true"
			>
				<mat-option
					*ngFor="let state of stateList$ | async; let i = index"
					id="{{ id + '_state_' + state.key }}"
					[value]="state.key"
					>{{ state.text }}</mat-option
				>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="zip">
		<mat-form-field class="form-element" appearance="outline">
			<mat-label>{{ t('ADDRESS.zipCode') }} </mat-label>
			<input
				matInput
				type="text"
				inputmode="numeric"
				pattern="[0-9]*"
				name="zipCode"
				id="{{ id + '_zipCode' }}"
				formControlName="zipCode"
				aria-required="true"
			/>
			<mat-error
				id="{{ id + '_zipCodeError' }}"
				*ngIf="!formGroup.controls['zipCode'].valid && formGroup.controls['zipCode'].touched"
			>
				{{ t('ADDRESS.zipCodeError') }}
			</mat-error>
		</mat-form-field>
	</div>
</div>
