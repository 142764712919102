<div class="add-bank" *transloco="let t">
	<div class="op-mt-20">
		<op-select-bank-account-ext
			[filter]="filter"
			id="bankAddBankSelectAccount"
			[selectAndContinue]="selectAndContinue"
			(checkVerificationStatus)="onBankSelected($event)"
			(addAnotherBankAccount)="onAddAnotherBankAccount($event)"
		>
		</op-select-bank-account-ext>
	</div>
</div>
