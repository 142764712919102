import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { IMetadata } from 'src/app/core/services/mobile-api';

import { SummarySection } from './section.model';

/**
 * Stores general summary information.
 *
 * @param {ILoanApplication} loanApp
 * @param {IMetadata[]} loanPurpose
 * @returns {SummarySection}
 */
export const loanPurposeSection = (loanApp: ILoanApplication, loanPurpose: IMetadata[]): SummarySection => {
	return {
		section: 'loanPurpose',
		header: 'SUMMARY.additionalInformation',
		route: '/loan-purpose',
		fields: [
			{
				title: 'LOAN_PURPOSE.purpose',
				value: loanPurpose?.find((item) => item.key === loanApp?.loanPurpose)?.text || ' ',
				route: '/loan-purpose'
			}
		]
	};
};
