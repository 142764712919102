import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ConfigApiService, IConfigResult } from 'src/app/core/services/mobile-api';

@Injectable({
	providedIn: 'root'
})
export class ProofOfIdentificationResolver implements Resolve<boolean> {
	constructor(private configApiService: ConfigApiService) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.configApiService
			.configOcrIdOtherEnhancementEnabled()
			.pipe(map((rsp: IConfigResult) => Boolean(rsp?.value)));
		// .pipe(map((rsp: IConfigResult) => Boolean(true)));
	}
}
