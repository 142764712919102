<div class="op-learn-more" [ngClass]="{ small: iconSize === 'small' }">
	<div
		[ngClass]="{
			'op-green': iconSize !== 'small',
			'learn-icon': iconAlign === 'top',
			'learn-icon-center': iconAlign === 'center'
		}"
	>
		<img *ngIf="src" alt="" [src]="src" />
		<mat-icon *ngIf="icon" svgIcon="{{ icon }}"></mat-icon>
	</div>
	<div class="learn-content">
		<div *ngIf="title" class="op-body-1-bold-font op-mb-5" [ngClass]="{ 'op-green': iconSize !== 'small' }">
			{{ title }}
		</div>
		<ng-content></ng-content>
	</div>
</div>
