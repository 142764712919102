import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { AcceptEsignComponent } from './accept-esign-disclosures/accept-esign.component';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { BankConnectComponent } from './bank-connect/bank-connect.component';
import { CaFinderPartnerDisclosureComponent } from './ca-finder-partner-disclosure/ca-finder-partner-disclosure.component';
import { EsignDisclosuresComponent } from './esign-disclosures/esign-disclosures.component';
import { FindPrequalApplicationComponent } from './find-prequal-application/find-prequal-application.component';
import { FindYourApplicationComponent } from './find-your-application/find-your-application.component';
import { FindYourLetterComponent } from './find-your-letter/find-your-letter.component';
import { GetOfferComponent } from './get-offer/get-offer.component';
import { HandlePrescreenApplicationComponent } from './handle-prescreen-application/handle-prescreen-application.component';
import { AgentStoreDialogComponent } from './home/agent-store-dialog/agent-store-dialog.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { StepsHeadlineComponent } from './how-it-works/steps-headline/steps-headline.component';
import { LandingRoutingModule } from './landing-routing.module';
import { LoanDisclosuresComponent } from './loan-disclosures/loan-disclosures.component';
import { MultifactorComponent } from './multifactor/multifactor.component';
import { OfferAvailableComponent } from './offer-available/offer-available.component';
import { OfferCodeComponent } from './personal-info/offer-code/offer-code.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { SmsAlertComponent } from './personal-info/sms-alert/sms-alert.component';
import { PreQualifiedComponent } from './pre-qualified/pre-qualified.component';
import { SpecialOfferComponent } from './special-offer/special-offer.component';
import { SpecialOfferVariantsComponent } from './special-offer/variants/special-offer-variants.component';
import { TermsComponent } from './terms/terms.component';
import { VendorGeneralInfoComponent } from './vendor-general-info/vendor-general-info.component';

/**
 * Pre authorization pages
 *
 * @export
 * @class LandingModule
 */
@NgModule({
	imports: [CommonModule, SharedModule, LandingRoutingModule, TranslocoModule, NgxMaskModule.forChild()],
	declarations: [
		AcceptEsignComponent,
		AccessibilityComponent,
		AgentStoreDialogComponent,
		BankConnectComponent,
		CaFinderPartnerDisclosureComponent,
		EsignDisclosuresComponent,
		FindPrequalApplicationComponent,
		FindYourApplicationComponent,
		FindYourLetterComponent,
		GetOfferComponent,
		HandlePrescreenApplicationComponent,
		HomeComponent,
		HowItWorksComponent,
		LoanDisclosuresComponent,
		MultifactorComponent,
		OfferAvailableComponent,
		OfferCodeComponent,
		PersonalInfoComponent,
		PreQualifiedComponent,
		SmsAlertComponent,
		SpecialOfferComponent,
		SpecialOfferVariantsComponent,
		StepsHeadlineComponent,
		TermsComponent,
		VendorGeneralInfoComponent
	],
	exports: [SmsAlertComponent]
})
export class LandingModule {}
