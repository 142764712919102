import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { find, findIndex, forEach, isEqual } from 'lodash';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { IFinalApprovedTerms, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import IProduct, {
	ProductCategoriesEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import {
	IDisbursementOptions,
	IFirstPaymentDateOptions,
	ILoanTermsResult,
	IPaymentOptions,
	ISaveLoanTerms
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import { PaymentFrequencyEnum } from './payment-date/payment-date.component';

@Component({
	selector: 'op-adjust-terms',
	templateUrl: './adjust-terms.component.html',
	styleUrls: ['./adjust-terms.component.scss']
})
export class AdjustTermsComponent implements OnInit, OnDestroy {
	formGroup: FormGroup;
	private subscription = new Subscription();
	currentDate: Date;

	private readonly maxSemiMonthlyDate = 13;
	private readonly defaultDate = 1;
	private readonly defaultBiWeeklyDay = 'MONDAY';

	isSPL: boolean;
	isGCP: boolean;
	hasMultipleValidProductOffers: boolean;

	finalApprovedTerms: IFinalApprovedTerms;

	paymentFrequencyOptions: IMetadata[];

	originationDisbursementChannel;
	originationDisbursementType;
	productOfferDetailUtilities: ProductOfferDetailsUtils;
	disbursementInfo: DisbursementUtils;
	fundingInfo: FundingInfoUtils;

	loanDetails;

	loanTerms;
	loanTermsLoaded: boolean;
	showMoreOptions: boolean;
	showMoreOptionsBtn: boolean;

	productSelectionChanged: boolean;

	paymentOptions: any;
	loanAmountSteps;
	loanAmountStepKeys;
	semiMonthlyDate: number;
	firstPaymentDateOptions: IFirstPaymentDateOptions[];
	monthlyPaymentOptions: string[];

	areAllProductsApproved: boolean;
	isStarterLoan: boolean;

	get loanOptions() {
		return this.loanAmountSteps && this.loanAmountSteps[this.formGroup.get('amount').value];
	}

	constructor(
		private formBuilder: FormBuilder,
		private mobileService: MobileApiService,
		private metadataService: MetadataService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService
	) {
		this.currentDate = new Date();
		this.createForm(this.formBuilder);
	}

	ngOnInit(): void {
		const paymentFrequency$ = this.formGroup.get('paymentFrequency').valueChanges.pipe(distinctUntilChanged());
		const paymentDay$ = this.formGroup.get('paymentDay').valueChanges.pipe(distinctUntilChanged(isEqual));
		const selectedProduct$ = this.formGroup.get('selectedProduct').valueChanges.pipe(distinctUntilChanged());
		const formSub = combineLatest([paymentFrequency$, paymentDay$, selectedProduct$])
			.pipe(
				switchMap(([paymentFrequency, paymentDayOptions]) => {
					const paymentOptions = this.getPaymentOptionParams(paymentFrequency, paymentDayOptions);
					return this.getPaymentOptions(paymentFrequency, paymentOptions, this.loanAppService.loanApplicationId);
				})
			)
			.subscribe();
		this.subscription.add(formSub);

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				tap((loanApp: ILoanApplication) => {
					this.fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
					this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp);
					this.disbursementInfo = DisbursementUtils.fromLoanApp(loanApp);
					this.areAllProductsApproved = this.productOfferDetailUtilities.checkIfAllProductsAreApproved();
					this.hasMultipleValidProductOffers = this.productOfferDetailUtilities.hasMultipleValidProductOffers();
				}),
				filter(() => {
					if (this.canForwardToStoreless(this.sessionStorageService.get('productCategorySelection'))) {
						this.routingService.route(RoutingPathsEnum.storeLessDisbursementInfo);
						return false;
					}
					return true;
				}),
				mergeMap((loanApp: ILoanApplication) => {
					const productCategorySelection = this.getProductCategorySelection(
						loanApp,
						this.hasMultipleValidProductOffers
					);
					return forkJoin([
						of(loanApp),
						of(productCategorySelection),
						this.getLoanTerms(productCategorySelection, loanApp.id)
					]);
				})
			)
			.subscribe({
				next: ([loanApp, productCategorySelection, loanTerms]) => {
					this.finalApprovedTerms = loanApp.finalApprovedTerms;
					if (this.hasMultipleValidProductOffers) {
						this.updateFinalApprovedTerns(productCategorySelection, loanApp);
					}
					this.isSPL = this.isSecuredPersonalLoanSelected(productCategorySelection);

					this.formGroup.get('selectedProduct').setValue(productCategorySelection);

					// NOTE: if no state in fundingInfo movil sends 'undefined'
					const paymentFreqSub = this.metadataService
						.select(MetadataEnum.PaymentFrequency, this.fundingInfo.getState() || 'undefined')
						.pipe(tap((rsp) => (this.paymentFrequencyOptions = rsp)))
						.subscribe();
					this.subscription.add(paymentFreqSub);
				}
			});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getPaymentOptionParams(paymentFrequency, paymentDayOptions): IPaymentOptions {
		const paymentDay = paymentFrequency === PaymentFrequencyEnum.BI_WEEKLY ? paymentDayOptions.biWeeklyDate : undefined;
		const paymentDate1 =
			paymentFrequency === PaymentFrequencyEnum.SEMI_MONTHLY
				? paymentDayOptions.semiMonthlyPaymentDate || 1
				: undefined;
		const monthlyPaymentDate =
			paymentFrequency === PaymentFrequencyEnum.MONTHLY ? paymentDayOptions.monthlyDate || 1 : undefined;

		this.semiMonthlyDate = paymentDate1;
		const paymentDate2 = paymentFrequency === PaymentFrequencyEnum.SEMI_MONTHLY ? String(paymentDate1 + 15) : undefined;

		const options: IPaymentOptions = {
			paymentFrequency: this.getEnumKeyByEnumValue(PaymentFrequencyEnum, paymentFrequency),
			loanAmount: String(this.finalApprovedTerms.maxApprovedAmount || this.loanTerms.loanAmount),
			productCategory: this.loanTerms.productCategory,
			expectedDisbursementDate: this.loanTerms.expectedDisbursementDate
		};
		if (paymentDay) {
			options.paymentDay = paymentDay;
		}
		if (paymentDate1) {
			options.paymentDate1 = String(paymentDate1);
		}
		if (paymentDate2) {
			options.paymentDate2 = paymentDate2;
		}

		if (monthlyPaymentDate) {
			options.paymentDate1 = paymentDayOptions.monthlyDate;
			options.firstPaymentDate = this.firstPaymentDateOptions?.find(
				(firstPaymentDateOption) => firstPaymentDateOption.monthlyPaymentDate === monthlyPaymentDate
			)?.firstPaymentDate;
		}

		return options;
	}

	getEnumKeyByEnumValue<T extends { [index: string]: string | number }>(
		myEnum: T,
		enumValue: string | number
	): keyof T | null {
		const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
		return keys.length > 0 ? keys[0] : null;
	}

	getLoanTerms(productCategorySelection, loanAppId): Observable<any> {
		return this.mobileService.getLoanTerms(productCategorySelection, loanAppId).pipe(
			tap((loanTerms: ILoanTermsResult) => {
				this.loanTerms = loanTerms;
				this.formGroup.get('amount').setValue(loanTerms.loanAmount || this.finalApprovedTerms.maxApprovedAmount);
				this.formGroup.get('paymentFrequency').setValue(PaymentFrequencyEnum[loanTerms.paymentFrequency]);
				this.formGroup.get('paymentDay').setValue({
					biWeeklyDate: loanTerms.paymentDay || this.defaultBiWeeklyDay,
					semiMonthlyPaymentDate:
						loanTerms.paymentDate1 && loanTerms.paymentDate1 <= this.maxSemiMonthlyDate
							? loanTerms.paymentDate1
							: this.defaultDate,
					monthlyDate: loanTerms.paymentDate1 || this.defaultDate
				});
				this.loanTermsLoaded = true;
				this.isStarterLoan = this.finalApprovedTerms?.product?.slice(0, 11) === 'StarterLoan' ? true : false;
				this.isGCP = this.loanAppService.isGCP();
			})
		);
	}

	getPaymentOptions(paymentFrequency, paymentOptions, loanId): Observable<any> {
		return this.mobileService.getPaymentOptions(paymentOptions, loanId).pipe(
			tap((paymentOptionsResult) => {
				this.paymentOptions = paymentOptionsResult;
				this.loanAmountSteps = this.paymentOptions.reduce((acc, cur) => {
					acc[cur.amount] = acc[cur.amount] ? [...acc[cur.amount], cur] : [cur];
					return acc;
				}, {});
				this.loanAmountStepKeys = Object.keys(this.loanAmountSteps);

				this.formGroup.get('loanDetails').setValue(this.loanOptions?.[0]);
				if (this.loanOptions?.length > 1 && !this.showMoreOptions) {
					this.showMoreOptions = false;
					this.showMoreOptionsBtn = true;
				}
			}),
			tap((paymentOptionsResult) => {
				PaymentFrequencyEnum.MONTHLY === paymentFrequency
					? this.generateMonthlyPaymentOptions(paymentOptionsResult)
					: this.resetMonthlyOptions();
				const loanTerms = {
					amount: paymentOptions.loanAmount,
					paymentAmount: this.loanTerms.paymentAmount,
					paymentTerm: this.loanTerms.paymentTerm,
					numberOfPayments: this.loanTerms.numberOfPayments
				};

				const selectedLoanTerm = find(paymentOptionsResult, loanTerms);
				if (selectedLoanTerm) {
					this.formGroup.get('loanDetails').setValue(selectedLoanTerm);
				}
			})
		);
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			amount: [null, [Validators.required]],
			paymentFrequency: [null, [Validators.required]],
			paymentDay: [null, [Validators.required]],
			loanDetails: [null, [Validators.required]],
			selectedProduct: [null]
		});
	}

	decreaseValue(): void {
		const index = findIndex(this.loanAmountStepKeys, (amount) => amount == this.formGroup.get('amount').value);
		if (this.loanAmountStepKeys[index - 1]) {
			this.formGroup.get('amount').setValue(Number(this.loanAmountStepKeys[index - 1]));
			this.formGroup.get('loanDetails').setValue(this.loanOptions?.[0]);
		}
	}

	increaseValue(): void {
		const index = findIndex(this.loanAmountStepKeys, (amount) => amount == this.formGroup.get('amount').value);
		if (this.loanAmountStepKeys[index + 1]) {
			this.formGroup.get('amount').setValue(Number(this.loanAmountStepKeys[index + 1]));
			this.formGroup.get('loanDetails').setValue(this.loanOptions?.[0]);
		}
	}

	private canForwardToStoreless(productCategory: ProductCategoriesEnum): boolean {
		const isStorelessRedirectEligible: boolean = this.routingService.isStorelessRedirectEligible(
			this.productOfferDetailUtilities,
			this.loanAppService.getLoanApp()
		);
		const isNotificationComplete = this.loanAppService.getLoanApp().notificationProcessComplete;
		return (
			isStorelessRedirectEligible &&
			productCategory === ProductCategoriesEnum.securedPersonalLoan &&
			!isNotificationComplete
		);
	}

	switchOffer(): void {
		const currentProductCategorySelection = this.getProductCategorySelection(this.loanAppService.getLoanApp(), true);
		if (this.areAllProductsApproved) {
			const newProductCategorySelection =
				currentProductCategorySelection === ProductCategoriesEnum.unsecuredPersonalLoan
					? ProductCategoriesEnum.securedPersonalLoan
					: ProductCategoriesEnum.unsecuredPersonalLoan;
			this.sessionStorageService.set('productCategorySelection', newProductCategorySelection);

			if (this.canForwardToStoreless(newProductCategorySelection)) {
				return this.routingService.route(RoutingPathsEnum.storeLessDisbursementInfo);
			} else {
				// Update Disbursement channel to STORE if it is SPL, otherwise, set it to the original channel

				const disbursementParameters: IDisbursementOptions = {
					disbursementChannel:
						newProductCategorySelection === ProductCategoriesEnum.securedPersonalLoan
							? 'STORE'
							: this.disbursementInfo.originationDisbursementChannel,
					disbursementType: this.disbursementInfo.disbursement.originationDisbursementType
				};
				this.isSPL = this.isSecuredPersonalLoanSelected(newProductCategorySelection);

				const loanTerms$ = this.getLoanTerms(newProductCategorySelection, this.loanAppService.loanApplicationId).pipe(
					tap(() => this.formGroup.get('selectedProduct').setValue(newProductCategorySelection))
				);

				this.mobileService
					.updateDisbursementChannel(disbursementParameters, this.loanAppService.loanApplicationId)
					.pipe(concatMap(() => forkJoin([this.loanAppService.updateLoanApplication(), loanTerms$])))
					.subscribe();

				this.updateFinalApprovedTerns(newProductCategorySelection, this.loanAppService.getLoanApp());
			}
		} else {
			this.routingService.route(RoutingPathsEnum.offerStatus);
		}
	}

	onSubmit(post: any): void {
		const paymentFrequency = this.formGroup.get('paymentFrequency').value;
		const paymentDayOptions = this.formGroup.get('paymentDay').value;
		const loanDetails = this.formGroup.get('loanDetails').value;
		const paymentDay = paymentFrequency === PaymentFrequencyEnum.BI_WEEKLY ? paymentDayOptions.biWeeklyDate : undefined;
		const paymentDate1 =
			paymentFrequency === PaymentFrequencyEnum.SEMI_MONTHLY ? paymentDayOptions.semiMonthlyPaymentDate : undefined;
		const paymentDate2 =
			paymentFrequency === PaymentFrequencyEnum.SEMI_MONTHLY
				? String(parseInt(paymentDayOptions.semiMonthlyPaymentDate, 10) + 15)
				: undefined;
		const monthlyPaymentDate =
			paymentFrequency === PaymentFrequencyEnum.MONTHLY ? paymentDayOptions.monthlyDate : undefined;

		const saveLoanTerm: ISaveLoanTerms = {
			paymentAmount: loanDetails.paymentAmount,
			paymentTerm: loanDetails.paymentTerm,
			loanAmount: this.formGroup.get('amount').value,
			paymentFrequency: this.getEnumKeyByEnumValue(PaymentFrequencyEnum, paymentFrequency), //'SEMI_MONTHLY',
			productCategory: this.loanTerms.productCategory
		};
		if (paymentDay) {
			saveLoanTerm.paymentDay = paymentDay;
		}
		if (paymentDate1) {
			saveLoanTerm.paymentDate1 = paymentDate1;
		}
		if (paymentDate2) {
			saveLoanTerm.paymentDate2 = Number(paymentDate2);
		}
		if (monthlyPaymentDate) {
			saveLoanTerm.paymentDate1 = monthlyPaymentDate;
			saveLoanTerm.firstPaymentDate = this.firstPaymentDateOptions?.find(
				(firstPaymentDateOption) => firstPaymentDateOption.monthlyPaymentDate === monthlyPaymentDate
			)?.firstPaymentDate;
		}

		this.mobileService
			.updateSaveLoanTerms(saveLoanTerm, this.loanAppService.loanApplicationId)
			.pipe(
				switchMap((rsp) => this.loanAppService.updateLoanApplication()),
				switchMap((loanApp) => this.routingService.routeToOnlineNotificationRoute(loanApp))
			)
			.subscribe();
	}

	getProductCategorySelection(loanApp: ILoanApplication, hasMultipleProductsOffered: boolean): string {
		const loanTermsProductCategory = loanApp.productCategory;
		if (ProductCategoriesEnum.personalLoan === loanTermsProductCategory) {
			return this.sessionStorageService.get('productCategorySelection');
		}
		return loanTermsProductCategory;
	}

	isSecuredPersonalLoanSelected(selectedProductCategory: string): boolean {
		return this.loanAppService.isSPL() || ProductCategoriesEnum.securedPersonalLoan === selectedProductCategory;
	}

	updateFinalApprovedTerns(productCategorySelection: string, loanApplication: ILoanApplication): void {
		let selectedProduct: IProduct = null;
		if (this.hasMultipleValidProductOffers) {
			if (ProductCategoriesEnum.unsecuredPersonalLoan === productCategorySelection) {
				selectedProduct = this.productOfferDetailUtilities.getUnsecuredPersonalLoanProduct();
			} else if (ProductCategoriesEnum.securedPersonalLoan === productCategorySelection) {
				selectedProduct = this.productOfferDetailUtilities.getSecuredPersonalLoanProduct();
			}

			if (selectedProduct != null) {
				this.finalApprovedTerms = Object.assign({}, this.finalApprovedTerms, {
					maxApprovedAmount: selectedProduct.maxApprovedAmount,
					minApprovedAmount: selectedProduct.minApprovedAmount
				});
			}
		}
	}

	toggleShowMoreOptions(): void {
		this.showMoreOptions = !this.showMoreOptions;
		this.showMoreOptionsBtn = !this.showMoreOptionsBtn;
	}

	generateMonthlyPaymentOptions(paymentOptions): void {
		const monthlyPaymentOptions = [];
		const monthlyFirstPaymentOptions = [];
		forEach(paymentOptions, (paymentOption) => {
			const firstPaymentDateOptions = paymentOption.firstPaymentDateOptions;
			forEach(firstPaymentDateOptions, (firstPaymentDateOption) => {
				if (
					firstPaymentDateOption?.monthlyPaymentDate &&
					monthlyPaymentOptions.indexOf(firstPaymentDateOption?.monthlyPaymentDate) === -1
				) {
					monthlyFirstPaymentOptions.push(firstPaymentDateOption);
					monthlyPaymentOptions.push(firstPaymentDateOption?.monthlyPaymentDate);
				}
			});
		});
		this.monthlyPaymentOptions = monthlyPaymentOptions;
		this.firstPaymentDateOptions = monthlyFirstPaymentOptions;

		if (!this.monthlyPaymentOptions.includes(this.formGroup.get('paymentDay').value?.monthlyDate)) {
			const paymentDay = this.formGroup.get('paymentDay').value;
			this.formGroup
				.get('paymentDay')
				.setValue(Object.assign({}, paymentDay, { monthlyDate: this.monthlyPaymentOptions[0] }));
		}
	}

	resetMonthlyOptions(): void {
		this.monthlyPaymentOptions = null;
		this.firstPaymentDateOptions = null;
	}
}
