<div class="fast-track" *transloco="let t">
	<op-content-container class="content">
		<mat-horizontal-stepper #stepper>
			<mat-step *ngFor="let ftrStep of fastTackSteps; let i = index" [ngSwitch]="ftrStep.mode" editable="false">
				<mat-card class="fast-track-content">
					<mat-card-header>
						<mat-card-title class="mat-card-header">
							<div class="op-header-1-font op-mt-20" innerHTML="{{ ftrStep.sectionTitle | transloco }}"></div>
						</mat-card-title>
						<mat-card-subtitle>
							<strong>{{ t(ftrStep.sectionSubTitle) }}</strong>
						</mat-card-subtitle>
					</mat-card-header>
					<div *ngIf="ftrStep.sectionNote !== ''" class="op-label-font op-gray">
						<span>{{ t(ftrStep.sectionNote) }}</span>
					</div>
					<br />

					<mat-card-content>
						<mat-card>
							<ng-container *ngSwitchCase="stepMode.newAddition">
								<ng-container *ngTemplateOutlet="formStepper; context: { step: ftrStep }"></ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="stepMode.editable">
								<ng-container *ngTemplateOutlet="editableStepper; context: { step: ftrStep }"></ng-container>
							</ng-container>
						</mat-card>
					</mat-card-content>

					<mat-card-actions
						*ngIf="ftrStep.section !== fTRSectionName.loanPurpose && ftrStep.section !== fTRSectionName.legalDisclosure"
					>
						<button mat-flat-button color="primary" (click)="continue(stepper, ftrStep)" id="{{ 'stepPanel_' + i }}">
							{{ t('GLOBAL.continue') }}
						</button>
					</mat-card-actions>
				</mat-card>
			</mat-step>
		</mat-horizontal-stepper>
	</op-content-container>

	<ng-template let-step="step" #editableStepper>
		<div>
			<div class="sectionTitle">
				<strong>{{ t(step.header) }}</strong>
				<a
					class="link-button op-body-1-font"
					href=""
					onclick="return false"
					aria-label="Edit"
					[routerLink]="[]"
					(click)="routeToPage(step.route, step.section, true)"
				>
					{{ t('SUMMARY.edit') }}
				</a>
			</div>
		</div>

		<div *ngFor="let field of step.fields">
			<div *ngIf="field.value" class="op-full-width">
				<div class="sectionTitle" *ngIf="field.header">
					<strong>{{ t(field.header) }}</strong>
				</div>
				<div class="fieldTitle op-label-font op-gray" *ngIf="field.titleValue">{{ field.title }}</div>
				<div class="fieldTitle op-label-font op-gray" *ngIf="!field.titleValue">{{ t(field.title) }}</div>
				<div class="fieldValue op-mb-10">{{ field.value }}</div>
			</div>
		</div>
	</ng-template>

	<ng-template let-step="step" #formStepper>
		<div [ngSwitch]="step.section">
			<div *ngSwitchCase="fTRSectionName.loanPurpose">
				<op-loan-purpose [returnToFTR]="step.section" (saveToFTR)="continue(stepper, step)"> </op-loan-purpose>
			</div>
			<div *ngSwitchCase="fTRSectionName.legalDisclosure">
				<op-legal-disclosures
					[isMetaOrganization]="isMetaOrganization"
					[returnToFTR]="step.section"
					[customerState]="currentState"
					(saveToFTR)="continue(stepper, step)"
				></op-legal-disclosures>
			</div>
		</div>
	</ng-template>
</div>
