import { Component, AfterViewInit } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

@Component({
	selector: 'op-accept-esign',
	templateUrl: './accept-esign.component.html',
	styleUrls: ['./accept-esign.component.scss']
})
export class AcceptEsignComponent implements AfterViewInit {
	clientName: string;

	constructor(private sessionStorageService: SessionStorageService) {}

	ngAfterViewInit(): void {
		this.clientName = this.sessionStorageService.get('clientName');
	}
}
