import { ApplicationStatusEnum } from '../loan-application/loan-application.model';
import { RoutingPathsEnum } from '../routing/routing.service';

/**
 * New Relic Flow Enum
 *
 * @export
 * @enum {number}
 */
export enum newRelicFlowEnum {
	newAppLogin = 'new-app-login',
	existingAppLogin = 'existing-app-login',
	creditAuth = 'credit-auth',
	preApproval = 'pre-approval',
	incomeBankVerification = 'income-bank-verify',
	docSubmitOcr = 'doc-submit-ocr',
	docSubmit = 'doc-submit',
	disbursement = 'disbursement',
	newServicingAcct = 'new-servicing-acct'
}

export interface INewRelicStartFlowControl {
	flowName: newRelicFlowEnum;
	loanAppStatus: ApplicationStatusEnum;
}

export interface INewRelicStartFlow {
	[type: string]: INewRelicStartFlowControl[];
}

export const newRelicStartFlowMap: INewRelicStartFlow = {
	[RoutingPathsEnum.personalInfo]: [
		{
			flowName: newRelicFlowEnum.newAppLogin,
			loanAppStatus: null
		}
	],
	[RoutingPathsEnum.findYourApplication]: [
		{
			flowName: newRelicFlowEnum.existingAppLogin,
			loanAppStatus: null
		}
	],
	[RoutingPathsEnum.loanPurpose]: [
		{
			flowName: newRelicFlowEnum.creditAuth,
			loanAppStatus: null
		}
	],
	[RoutingPathsEnum.finances]: [
		{
			flowName: newRelicFlowEnum.preApproval,
			loanAppStatus: null
		}
	],
	[RoutingPathsEnum.offerStatus]: [
		{
			flowName: newRelicFlowEnum.incomeBankVerification,
			loanAppStatus: ApplicationStatusEnum.preApproved
		},
		{
			flowName: newRelicFlowEnum.disbursement,
			loanAppStatus: ApplicationStatusEnum.approved
		}
	],
	[RoutingPathsEnum.documentSubmit]: [
		{
			flowName: newRelicFlowEnum.docSubmitOcr,
			loanAppStatus: null
		}
	],
	[RoutingPathsEnum.adjustTerms]: [
		{
			flowName: newRelicFlowEnum.disbursement,
			loanAppStatus: null
		}
	],
	[RoutingPathsEnum.createAccount]: [
		{
			flowName: newRelicFlowEnum.newServicingAcct,
			loanAppStatus: null
		}
	]
};
