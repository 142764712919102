import { formatCurrency } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors
} from '@angular/forms';

@Component({
	selector: 'op-increment-decrement',
	templateUrl: './increment-decrement.component.html',
	styleUrls: ['./increment-decrement.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: IncrementDecrementComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: IncrementDecrementComponent
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncrementDecrementComponent implements OnInit {
	@Input()
	title: string;

	private _amount: number = 0;
	@Input()
	set amount(amount: number) {
		this._amount = amount;
	}
	get amount(): number {
		return this._amount;
	}

	@Input()
	minAmount: number;

	@Input()
	maxAmount: number;

	@Output()
	increment = new EventEmitter<any>();

	@Output()
	decrement = new EventEmitter<any>();

	formGroup: FormGroup;

	constructor(private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({
			amount: [null]
		});

		this.formGroup.valueChanges.subscribe({
			next: (val) => {
				this.onChange(this.value);
			}
		});
	}

	get value(): number | null {
		return this.formGroup.get('amount').value;
	}
	set value(amount: number | null) {
		if (amount != null) {
			this.disableButtons(amount);
		}
		const formattedNumber = formatCurrency(amount, 'en', '$', 'USD', '0.0');
		this.formGroup.setValue({ amount: formattedNumber });
		this.onChange(amount);
	}

	onChange = (change) => {
		// This is intentional empty
	};

	onTouched = () => {
		// This is intentional empty
	};
	touched = false;
	disabled = false;
	disableDecreaseAmountBtn: boolean;
	disableIncreaseAmountBtn: boolean;

	ngOnInit(): void {
		// This is intentional empty
	}

	decreaseValue(): void {
		this.decrement.emit();
	}
	increaseValue(): void {
		this.increment.emit();
	}

	// Begin ControlValueAccessor interface
	writeValue(value: number): void {
		this.value = value;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	// End ControlValueAccessor interface

	// Begin Validator interface
	validate(control: AbstractControl): ValidationErrors | null {
		return null;
	}
	// End Validator interface

	disableButtons(amount): void {
		this.disableIncreaseAmountBtn = amount === this.maxAmount ? true : false;
		this.disableDecreaseAmountBtn = amount === this.minAmount ? true : false;
	}
}
