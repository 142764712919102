import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { Environment, EnvironmentService } from '../core/services/environment/environment.service';

@Injectable({
	providedIn: 'root'
})
export class SamplesGuard implements CanActivate {
	constructor(private environmentService: EnvironmentService) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const valid = [Environment.Dev, Environment.Local];
		return valid.some((env) => env === this.environmentService.env);
	}
}
