import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { isFunction, noop } from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { LoggingService } from '../../core/services/logging/logging.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { AutoVerifyScenarioEnum, IAutoVerifyScenario } from 'src/app/core/services/plaid-link/plaid-link.model';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import {
	IMessageDialogData,
	MessageDialogComponent
} from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

import { DocumentSubmitService } from '../document-submit/document-submit.service';
import { VerifyIncomeComponent } from '../verify-income/verify-income.component';

@Component({
	selector: 'op-auto-verify-income',
	templateUrl: './auto-verify-income.component.html',
	styleUrls: ['./auto-verify-income.component.scss']
})
export class AutoVerifyIncomeComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();

	bankConnectEvent: BankConnectEventTypeEnum;

	fileType = FileUploadType.income;

	showNoAccountsError: boolean;

	selectAccount: boolean;
	isFromPlaid: boolean;
	showPlaid: boolean;

	@Input()
	isVerified: boolean;

	autoVerifyScenario: IAutoVerifyScenario;

	@Output()
	checkVerificationStatus = new EventEmitter<any>();

	constructor(
		private plaidLinkService: PlaidLinkService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private loanAppService: LoanApplicationService,
		private documentSubmitService: DocumentSubmitService,
		private tagDataService: TagDataService,
		private loggingService: LoggingService,
		private dialog: MatDialog,
		private sessionStorageService: SessionStorageService
	) {}

	ngOnInit(): void {
		this.autoVerifyScenario = this.documentSubmitService.getAutoVerifyScenario();
		this.showPlaid = this.getShowPlaidFlag();
		if (this.showPlaid) {
			const plaidSub = this.plaidLinkService.plaid$.subscribe({
				next: (rsp) => {
					this.onBankConnect(rsp);
				}
			});
			this.subscription.add(plaidSub);
		}

		this.tagDataService.view(
			{},
			{
				tealium_event: 'auto-verify-income',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp()?.productOfferDetails
				)
			}
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent = event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type])
			? bankConnectEvents[event.type](event)
			: this.logBankConnectEvent(event);
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		this.logBankConnectEvent(event);
		this.showNoAccountsError = event.data.responseStatus !== BankConnectResponseStatusEnum.accountsFound;
		const accountsFound: boolean = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;
		const foundVerifiedIncome: boolean = event?.data?.foundVerifiedIncome;

		let showVerificationErrorModal = false;
		if (this.autoVerifyScenario) {
			let autoVerifyScenario = this.autoVerifyScenario.autoVerifyScenario;
			autoVerifyScenario =
				AutoVerifyScenarioEnum.bankAndIncome === autoVerifyScenario &&
				!this.autoVerifyScenario.hasBankAccountRequirement
					? autoVerifyScenario
					: AutoVerifyScenarioEnum.incomeOnly;

			switch (autoVerifyScenario) {
				case AutoVerifyScenarioEnum.bankOnly:
					showVerificationErrorModal = !accountsFound;
					break;
				case AutoVerifyScenarioEnum.bankAndIncome:
					showVerificationErrorModal = !accountsFound || !foundVerifiedIncome;
					break;
				case AutoVerifyScenarioEnum.incomeOnly:
					showVerificationErrorModal = !foundVerifiedIncome;
					break;
			}
		}
		if (showVerificationErrorModal) {
			this.showErrorDialog(accountsFound, foundVerifiedIncome);
		} else {
			this.loanAppService.updateLoanApplication().subscribe({
				next: (loanApp) => {
					if (this.loanAppService.getCurrentApplicant().incomeSourceOptionsOffered) {
						this.updateIncomeSources();
					} else {
						this.routingService.routeFromLoanApp();
					}
				}
			});
		}
	}
	bankErrorCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = true;
	}

	onIncomeVerificationComplete(): void {
		this.checkVerificationStatus.emit(FileUploadType.income);
	}

	getShowPlaidFlag(): boolean {
		let autoVerifyScenario = this.autoVerifyScenario?.autoVerifyScenario;
		return autoVerifyScenario != AutoVerifyScenarioEnum.incomeOnly;
	}

	private tealiumEvent(accountFound: boolean, incomeVerified: boolean): string {
		if (!accountFound) {
			return 'bank_not_found';
		}

		if (!incomeVerified) {
			return 'income_not_found';
		}
	}

	showErrorDialog(accountsFound: boolean, foundVerifiedIncome: boolean): void {
		const msgData = this.plaidLinkService.getIncomeDialogMessage(accountsFound, foundVerifiedIncome);
		this.openErrorMessage(msgData, () => of()).subscribe({
			next: () => {
				this.tagDataService.link(
					{},
					{
						tealium_event: this.tealiumEvent(accountsFound, foundVerifiedIncome),
						product_sub_category: this.sessionStorageService.get('productCategorySelection'),
						product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
							this.loanAppService.getLoanApp().productOfferDetails
						)
					}
				);
			}
		});
	}

	openErrorMessage(data: IMessageDialogData, confirm: () => Observable<any>): Observable<any> {
		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(
			take(1),
			tap(() => confirm())
		);
	}

	showSkipDialog(): void {
		this.openErrorMessage(
			{
				icon: 'op-info',
				title: this.translocoService.translate('AUTO_VERIFY_INCOME.skipIncomeHeader'),
				message: this.translocoService.translate('AUTO_VERIFY_INCOME.skipIncomeVerification'),
				confirmText: this.translocoService.translate('AUTO_VERIFY_INCOME.nextButton')
			},
			() => of()
		).subscribe({
			next: (result) => {
				if (result) {
					this.tagDataService.link(
						{},
						{
							tealium_event: 'income_verification_skipped',
							event_action: 'submit',
							event_label: 'Form',
							event_category: 'rx_form',
							product_sub_category: this.sessionStorageService.get('productCategorySelection'),
							product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
								this.loanAppService.getLoanApp().productOfferDetails
							)
						}
					);

					this.routingService.routeFromLoanApp();
				}
			}
		});
	}

	openInComeSourcesDialog(confirm: () => Observable<any>): Observable<any> {
		const dialogRef = this.dialog.open(VerifyIncomeComponent, {
			data: {
				isDialog: true,
				confirmText: this.translocoService.translate('AUTO_VERIFY_INCOME.select'),
				cancelText: this.translocoService.translate('AUTO_VERIFY_INCOME.cancel')
			}
		});

		return dialogRef.afterClosed().pipe(
			take(1),
			tap(() => confirm())
		);
	}

	updateIncomeSources(): void {
		this.openInComeSourcesDialog(() => of()).subscribe({
			next: (resp) => resp && this.routingService.routeFromLoanApp()
		});
	}

	logBankConnectEvent(event: IBankConnectEvent): void {
		this.loggingService.info(
			`bank connect event: ${event.type}, ${event?.data?.responseStatus}, ${this.loanAppService.loanApplicationId}`
		);
		noop();
	}
}
