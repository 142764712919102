import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { TagDataService } from '../services/tag-data/tag-data.service';

/**
 * Guard to track specific query parameters
 *
 * @export
 * @class LandingQueryTrackingGuard
 * @implements {CanActivate}
 */
@Injectable()
export class LandingQueryTrackingGuard implements CanActivate {
	constructor(private tagDataService: TagDataService, private activatedRoute: ActivatedRoute, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const source: string = route.queryParams?.s;
		if (source) {
			const url = Array.isArray(route.url) ? route.url?.find(Boolean)?.path : '';
			this.tagDataService.link(
				{},
				{
					tealium_event: `User landed from ${source} link`,
					event_action: 'Submit'
				}
			);
			this.router.navigate([url], {
				queryParams: {},
				queryParamsHandling: 'merge',
				relativeTo: this.activatedRoute,
				replaceUrl: true
			});
		}
		return true;
	}
}
