<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="identificationPanel"
		title="{{ t('DOCUMENT_SUBMIT.proofOfIdentification.title') }}"
		[fileList]="fileList"
		[expanded]="expanded"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
	>
		<div class="panel-content">
			<div class="op-header-3-font" *ngIf="idType">
				{{ t('DOCUMENT_SUBMIT.proofOfIdentification.subtitle', { idType: idType.text }) }}
			</div>
			<div class="op-header-3-font" *ngIf="!idType">{{ t('DOCUMENT_SUBMIT.proofOfIdentification.subtitleOther') }}</div>
			<ul>
				<li>{{ t('DOCUMENT_SUBMIT.proofOfIdentification.requirements.dob') }}</li>
				<li>{{ t('DOCUMENT_SUBMIT.proofOfIdentification.requirements.idFrontBack') }}</li>
				<li>{{ t('DOCUMENT_SUBMIT.proofOfIdentification.requirements.colorPhoto') }}</li>
				<li *ngIf="idType">
					<op-info-tip
						id="identificationTypesTip"
						[key]="t('DOCUMENT_SUBMIT.proofOfIdentification.requirements.otherTypesList')"
					>
						{{ t('DOCUMENT_SUBMIT.proofOfIdentification.requirements.otherTypes') }}
					</op-info-tip>
				</li>
			</ul>
			<op-file-upload
				id="identificationFileUpload"
				[categoryType]="fileType"
				requiredFileType="application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/bmp"
			>
			</op-file-upload>
		</div>
	</op-document-expansion-panel>
</div>
