<div class="document-status" *transloco="let t">
	<ng-content></ng-content>

	<div>
		<div
			class="op-mt-10"
			*ngFor="let file of categoryFilesStatus; let i = index"
			(click)="onUploadMore(file)"
			[opTagClick]="id + file.key + 'AddMore'"
		>
			<div class="file-list-item" id="{{ id + '_' + file.key }}">
				<div
					class="file-list-info"
					id="{{ id + '_' + file.key + '_name' }}"
					[ngClass]="{ noCount: !file.attention && !file.processing }"
				>
					<div class="op-body-1-bold-font op-mb-5">{{ file.text }}</div>
					<div *ngIf="file.attention || file.processing" class="op-dark-grey op-label-font">
						{{
							file.count
								| opSingularPlural
									: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachment')
									: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachments')
						}}
					</div>
				</div>
				<div class="file-list-attention">
					<div class="file-badge">
						<op-document-badge
							*ngIf="file.attention && !file.processing"
							id="{{ id + '_needsAttention_' + file.key }}"
							class="attention"
							icon="op-attention"
							[small]="true"
						>
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
						</op-document-badge>
						<op-document-badge
							*ngIf="file.processing"
							id="{{ id + '_processing_' + file.key }}"
							class="processing"
							icon="op-processing"
							[small]="true"
						>
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processing') }}
						</op-document-badge>
						<op-document-badge
							*ngIf="!file.attention && !file.processing"
							id="{{ id + '_attachments' }}"
							class="ready"
							icon="op-checkMark-green"
							[small]="true"
						>
							{{
								file.count
									| opSingularPlural
										: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachment')
										: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachments')
							}}
						</op-document-badge>
					</div>
					<div class="caret-right">
						<mat-icon svgIcon="op-caretRightSmall"></mat-icon>
					</div>
				</div>
			</div>
			<hr />
		</div>
	</div>

	<div class="add-more-files" (click)="onAddMore()" [opTagClick]="id + 'AddMore'">
		<div class="add-more-files-info" id="{{ id + '_add_more' }}">
			<mat-icon svgIcon="op-add2"></mat-icon>
			<div class="op-body-1-bold-font add-more-text">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.addAdditionalDocuments') }}
			</div>
		</div>
		<div class="caret-right">
			<mat-icon svgIcon="op-caretRightSmall"></mat-icon>
		</div>
	</div>
</div>
