import { EventDataTagPlaidEnum } from '../event-data/event-data.service';
import { IPlaidEventMetadata, SectionTypeEnum } from './plaid-link.model';
import { EventNameEnum, EventTypeEnum } from './plaid-link.service';

export interface IEventLogValue1 {
	result: string;
	type?: string;
	code?: string;
}

export interface IEventLogData {
	type: EventDataTagPlaidEnum;
	value1?: IEventLogValue1;
	value2?: string;
}

export interface IEventLogDataMap {
	[type: string]: IEventLogData;
}

export class PlaidEventMapping {
	static stringify(metadata: IPlaidEventMetadata): string {
		return JSON.stringify(metadata, (n, v) => v ?? undefined);
	}

	static getEventLogMap(eventValueText: string, bankOnly: boolean, metadata: IPlaidEventMetadata): IEventLogDataMap {
		return {
			'EVENT:OPEN': {
				type: EventDataTagPlaidEnum.plaidLoginInitiated,
				value1: {
					result: eventValueText
				},
				value2: this.stringify(metadata)
			},
			'EVENT:EXIT': {
				type: EventDataTagPlaidEnum.plaidLoginCompleted,
				value1: {
					result: metadata.error_type ? EventNameEnum.error : EventTypeEnum.exit,
					type: metadata.error_type,
					code: metadata.error_code
				},
				value2: this.stringify(metadata)
			},
			SUCCESS: {
				type: EventDataTagPlaidEnum.plaidLoginCompleted,
				value1: {
					result: EventTypeEnum.success
				},
				value2: this.stringify(metadata)
			},
			LOAD: {
				type: EventDataTagPlaidEnum.plaidLoads,
				value1: {
					result: eventValueText
				},
				value2: bankOnly ? SectionTypeEnum.bank : SectionTypeEnum.income
			},
			'EVENT:SELECT_INSTITUTION': {
				type: EventDataTagPlaidEnum.plaidEventSelectInstitution,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			},
			'EVENT:TRANSITION_VIEW:CREDENTIAL': {
				type: EventDataTagPlaidEnum.plaidEventViewCredential,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			},
			'EVENT:SUBMIT_CREDENTIAL': {
				type: EventDataTagPlaidEnum.plaidEventSubmitCredential,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			},
			'EVENT:BANK_INCOME_INSIGHTS_COMPLETED': {
				type: EventDataTagPlaidEnum.plaidEventIncomeInsightsCompleted,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			},
			'EVENT:TRANSITION_VIEW:OAUTH': {
				type: EventDataTagPlaidEnum.plaidEventViewOauth,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			},
			'EVENT:OPEN_OAUTH': {
				type: EventDataTagPlaidEnum.plaidEventOpenOauth,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			},
			'EVENT:HANDOFF': {
				type: EventDataTagPlaidEnum.plaidEventHandoff,
				value1: {
					result: this.stringify(metadata)
				},
				value2: eventValueText
			}
		};
	}
}
