import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { FileUploadService } from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IDocumentStatus, MobileApiService } from 'src/app/core/services/mobile-api';

@Injectable({
	providedIn: 'root'
})
export class StatusFileResolver implements Resolve<void> {
	constructor(
		private fileUploadService: FileUploadService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
		return this.mobileService.getDocumentsStatus(null, this.loanAppService.loanApplicationId).pipe(
			map((rsp: IDocumentStatus[]) => {
				return this.fileUploadService.reconcileFileUploads(rsp);
			})
		);
	}
}
