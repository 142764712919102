<div class="document-classification" *transloco="let t">
	<div *ngIf="categoryType !== fileUploadType.identification" class="op-body-1-bold-font">
		{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.selectDocumentType') }}
	</div>
	<div *ngIf="categoryType === fileUploadType.identification" class="op-body-1-bold-font">
		{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.selectIdType') }}
	</div>
	<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<div role="radiogroup" aria-labelledby="documentClassificationTitle" class="op-form-group op-mt-20">
			<mat-radio-group
				name="documentType"
				class="radio-group"
				id="{{ id + '_documentType' }}"
				formControlName="documentClassification"
				role="group"
				aria-labelledby="documentClassificationTitle"
				required
			>
				<mat-radio-button
					class="op-mb-32"
					*ngFor="let item of documentClassificationList"
					id="{{ id + '_' + item.key }}"
					[value]="item.key"
				>
					{{ item.text }}
				</mat-radio-button>
				<div [@collapse]="collapsed" class="op-micro-font op-dark-grey">
					<ul class="grid-list">
						<li
							*ngFor="let value of t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherIds')"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</mat-radio-group>
		</div>

		<ng-content></ng-content>

		<div class="op-mt-40 op-center">
			<button
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="{{ id + '_submitBtn' }}"
				[disabled]="!formGroup.valid"
				type="submit"
				[opTagClick]="id + 'Continue'"
			>
				{{ t('GLOBAL.continue') }}
			</button>
		</div>

		<div class="op-mt-20 op-center">
			<button
				mat-flat-button
				color="secondary"
				name="cancelBtn"
				id="{{ id + '_cancelBtn' }}"
				(click)="onCancel()"
				[opTagClick]="id + 'Cancel'"
			>
				{{ t('GLOBAL.cancel') }}
			</button>
		</div>
	</form>
</div>
