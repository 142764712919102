import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

/**
 * Storage service
 * used to persist application data in observable key value pair
 */
@Injectable()
export class StorageService implements OnDestroy {
	private storage: Storage;
	private subjects: Map<string, BehaviorSubject<any>>;

	/**
	 * Creates an instance of StorageService.
	 * @param {Storage} storage
	 * @memberof StorageService
	 */
	constructor(storage: Storage) {
		this.storage = storage;
		this.subjects = new Map<string, BehaviorSubject<any>>();
		this.start();
	}

	ngOnDestroy() {
		this.stop();
	}

	/**
	 * Watch value of key
	 *
	 * @param {string} key
	 * @param {boolean} [isJson=true]
	 * @return {*}  {Observable<any>}
	 * @memberof StorageService
	 */
	select(key: string, isJson: boolean = true): Observable<any> {
		let item = this.storage.getItem(key);
		if (item === 'undefined') {
			item = undefined;
		} else {
			try {
				item = isJson ? JSON.parse(item) : item;
			} catch (err) {
				console.error(err, item);
			}
		}

		if (!this.subjects.has(key)) {
			this.subjects.set(key, new BehaviorSubject<any>(item));
		}

		return this.subjects
			.get(key)
			.asObservable()
			.pipe(
				finalize(() => {
					if (!this.subjects.get(key).observed) {
						this.subjects.delete(key);
					}
				})
			);
	}

	private start(): void {
		window.addEventListener('storage', this.storageEventListener.bind(this));
	}

	private storageEventListener(event: StorageEvent) {
		if (event.storageArea == this.storage && this.subjects.has(event.key)) {
			let value;
			try {
				value = JSON.parse(event.newValue);
			} catch (e) {
				value = event.newValue;
			}
			this.subjects.get(event.key).next(value);
		}
	}

	private stop(): void {
		window.removeEventListener('storage', this.storageEventListener.bind(this));
	}

	/**
	 * Get value of key
	 *
	 * @param {string} key
	 * @param {boolean} [isJson=true] specifies if JSON should be parsed
	 * @return {*}  {*}
	 * @memberof StorageService
	 */
	get(key: string, isJson: boolean = true): any {
		let item = this.storage.getItem(key);
		if (item === 'undefined') {
			item = undefined;
		} else {
			try {
				item = isJson ? JSON.parse(item) : item;
			} catch (err) {
				console.error(err);
			}
		}
		return item;
	}

	/**
	 * Set value of a key
	 *
	 * @param {string} key
	 * @param {*} value
	 * @param {boolean} [isJson=true] specifies if JSON should be stringified
	 * @return {*} returns value
	 * @memberof StorageService
	 */
	set(key: string, value: any, isJson: boolean = true): any {
		this.storage.setItem(key, isJson ? JSON.stringify(value) : value);
		if (!this.subjects.has(key)) {
			this.subjects.set(key, new BehaviorSubject<any>(value));
		} else {
			this.subjects.get(key).next(value);
		}
		return value;
	}

	/**
	 * Remove key
	 *
	 * @param {string} key
	 * @memberof StorageService
	 */
	remove(key: string): void {
		this.storage.removeItem(key);
		if (this.subjects.has(key)) {
			this.subjects.get(key).next(undefined);
			if (!this.subjects.get(key)?.observed) {
				this.subjects.delete(key);
			}
		}
	}

	/**
	 * Clear all keys
	 *
	 * @memberof StorageService
	 */
	clear(): void {
		this.storage.clear();
		this.subjects.forEach((value, key, map) => this.remove(key));
	}
}
