import { Injectable } from '@angular/core';
import { isFunction } from 'lodash';

import versionData from '../../../version.json';
import { Environment, EnvironmentService } from '../environment/environment.service';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class NewRelicService {
	constructor(private environmentService: EnvironmentService, private windowRefService: WindowRefService) {}

	init(): void {
		const newrelicConfig = this.getNewRelicConfig(this.environmentService.env);
		this.windowRefService.nativeWindow.NREUM.loader_config = newrelicConfig.loader_config;
		this.windowRefService.nativeWindow.NREUM.info = newrelicConfig.info;
		isFunction(this.windowRefService.nativeWindow.NREUM.addRelease) &&
			this.windowRefService.nativeWindow.NREUM.addRelease(
				'online origination',
				versionData.build_version + '-' + versionData.build_git_commit_id
			);
	}

	private getNewRelicConfig(env: Environment): any {
		const licenseKey = 'NRJS-a024e9641f582976765';
		const trustKey = '308408';
		const accountID = '4331052';
		const beacon = 'bam.nr-data.net';

		switch (env) {
			case Environment.Prod:
				return {
					loader_config: {
						accountID: accountID,
						trustKey: trustKey,
						agentID: '1386197349',
						licenseKey: licenseKey,
						applicationID: '1386197349'
					},
					info: {
						beacon: beacon,
						errorBeacon: beacon,
						licenseKey: licenseKey,
						applicationID: '1386197349',
						sa: 1
					}
				};
			case Environment.Stage:
				return {
					loader_config: {
						accountID: accountID,
						trustKey: trustKey,
						agentID: '1386197348',
						licenseKey: licenseKey,
						applicationID: '1386197348'
					},
					info: {
						beacon: beacon,
						errorBeacon: beacon,
						licenseKey: licenseKey,
						applicationID: '1386197348',
						sa: 1
					}
				};
			case Environment.Qa:
			case Environment.Dev:
			default:
				return {
					loader_config: {
						accountID: accountID,
						trustKey: trustKey,
						agentID: '1386197347',
						licenseKey: licenseKey,
						applicationID: '1386197347'
					},
					info: {
						beacon: beacon,
						errorBeacon: beacon,
						licenseKey: licenseKey,
						applicationID: '1386197347',
						sa: 1
					}
				};
		}
	}
}
