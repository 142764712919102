import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisbursementType } from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { IAchBankAccountResult, IDebitCardDetails } from 'src/app/core/services/mobile-api';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

export interface IFundingMethodConfirmData {
	fundingOptions: DisbursementType;
	account?: Partial<IAchBankAccountResult>;
	card?: Partial<IDebitCardDetails>;
}

@Component({
	selector: 'op-funding-method-confirm',
	templateUrl: './funding-method-confirm.component.html',
	styleUrls: ['./funding-method-confirm.component.scss']
})
export class FundingMethodConfirmComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<FundingMethodConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IFundingMethodConfirmData,
		private tagDataService: TagDataService
	) {}

	ngOnInit() {
		this.tagDataService.link(
			{},
			{
				tealium_event: `funding method confirmed ${this.data.fundingOptions}`
			}
		);
	}

	onClick(): void {
		this.dialogRef.close(true);
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}
}
