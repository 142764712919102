import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { TealiumService } from './tealium.service';

@NgModule({
	declarations: [],
	providers: [TealiumService],
	imports: [CommonModule]
})
export class TealiumModule {
	constructor(@Optional() @SkipSelf() parentModule: TealiumModule) {
		if (parentModule) {
			throw new Error('TealiumModule is already loaded. Import it in the AppModule only');
		}
	}
}
