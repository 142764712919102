<div *ngIf="showNewDesign === true">
	<op-funding-method [plaidConnectForIncomeVerified]="false"></op-funding-method>
</div>

<div *ngIf="showNewDesign === false">
	<div class="bank-verification" *transloco="let t">
		<op-masthead [title]="t('BANK_VERIFICATION.title')"></op-masthead>
		<op-content-container class="content">
			<div class="plaid-group op-mt-10" *ngIf="!hidePlaid">
				<op-plaid-bank-transaction
					#plaidLink
					(next)="onNext($event)"
					(skip)="skipPlaid($event)"
					[sectionType]="'bank'"
					[bankOnly]="true"
					[showBenefits]="true"
					[plaidConnectForIncomeVerified]="false"
				></op-plaid-bank-transaction>
			</div>

			<div class="op-mt-10 op-mb-40 plaid-group">
				<op-microbilt-bank-account
					(notSaved)="notSaved($event)"
					(saved)="saved($event)"
					[expanded]="manualBankExpanded"
				>
				</op-microbilt-bank-account>
			</div>

			<op-funding-alternative class="op-mb-20"></op-funding-alternative>

			<button mat-flat-button class="op-full-width" color="secondary" id="skipBank" (click)="skip()">
				{{ hidePlaid ? t('PLAID_CONNECT.skipForNow') : t('RECEIVE_FUNDS.skipBank') }}
			</button>
			<op-website-terms class="op-mt-10"></op-website-terms>
		</op-content-container>
	</div>
</div>
