import { Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { RollbarService } from 'src/app/vendor/rollbar/rollbar.service';

@Injectable({
	providedIn: 'root'
})
export class LoggingService {
	constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

	info(...args: any): void {
		this.rollbar.info(...args);
	}

	debug(...args: any): void {
		this.rollbar.debug(...args);
	}

	error(...args: any): void {
		this.rollbar.error(...args);
	}
}
