import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { formatDate } from 'src/app/core/services/date-utils/date-utils';
import { VerificationStatusEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IFinancesResult, IGetIncomeResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { MessageDialogComponent } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

export interface DialogData {
	isDialog: boolean;
}

@Component({
	selector: 'op-verify-income',
	templateUrl: './verify-income.component.html',
	styleUrls: ['./verify-income.component.scss']
})
export class VerifyIncomeComponent implements OnInit, OnDestroy {
	verifiedMonthlyIncomeAmount: string;
	statedMonthlyIncomeAmount: string;
	selectedIncome: string;
	incomes: IGetIncomeResult[];
	sectionFields: any;
	constructor(
		public dialogRef: MatDialogRef<VerifyIncomeComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private metadataService: MetadataService,
		private translocoService: TranslocoService
	) {
		this.createForm(this.formBuilder);
	}

	private subscription = new Subscription();
	formGroup: FormGroup;

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$.subscribe({
			next: (loanApp) => {
				this.verifiedMonthlyIncomeAmount = this.loanAppService.getCurrentApplicant()?.verifiedMonthlyIncomeAmount;
				this.statedMonthlyIncomeAmount = this.loanAppService.getCurrentApplicant()?.statedMonthlyIncomeAmount;
			}
		});
		this.subscription.add(loanAppSub);

		const sub = combineLatest([
			this.mobileService.getIncome(this.loanAppService.loanApplicationId).pipe(catchError((error) => of(error))),
			this.mobileService.getFinances(this.loanAppService.loanApplicationId).pipe(catchError((error) => of(error))),
			this.metadataService.select(MetadataEnum.IncomeSourceType),
			this.metadataService.select(MetadataEnum.IncomeFrequency)
		]).subscribe({
			next: ([getIncome, getFinances, getIncomeSourceType, getIncomeFrequency]: any) => {
				this.sectionFields = this.getIncomeSection(
					getIncome,
					getFinances,
					getIncomeSourceType,
					getIncomeFrequency,
					this.translocoService.translate('SUMMARY.paid'),
					this.translocoService.translate('SUMMARY.started')
				);
			},
			error: (err) => {
				console.error(err);
			}
		});
		this.subscription.add(sub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			incomeSelection: [null, [Validators.required]]
		});
	}

	close(): void {
		this.dialogRef.close(false);
	}

	public get VerificationStatusEnum() {
		return VerificationStatusEnum;
	}

	onSubmit(post: any): void {
		this.mobileService
			.updateIncomeSelection(this.formGroup.get('incomeSelection').value, this.loanAppService.loanApplicationId)
			.subscribe({
				next: (res) => {
					if (this.data?.isDialog) {
						this.dialogRef.close(true);
					}
					this.routingService.routeFromLoanApp();
				},
				error: (err) => {
					if (this.data?.isDialog) {
						this.dialogRef.close(false);
					}
				}
			});
	}

	private getIncomeSection(
		incomes: IGetIncomeResult[],
		finances: IFinancesResult,
		incomeSourceType: IMetadata[],
		incomeFrequency: IMetadata[],
		paid: string,
		started: string
	): any {
		return incomes.map((income) => {
			const type = incomeSourceType.find((item) => item.key === income.incomeSourceType)?.text;
			const frequency = incomeFrequency.find((item) => item.key === income.paymentFrequency)?.text;
			return {
				title: `${type} - $${income.amount} ${paid} ${frequency}`,
				titleValue: true,
				value: `${started} ${formatDate(income.startDate, 'MMM yyy')}`
			};
		});
	}

	openInfoDialog(): void {
		const data = {
			icon: 'op-info',
			title: this.translocoService.translate('DIALOG_MESSAGE.message'),
			message: this.translocoService.translate('VERIFY_INCOME.moreInfo')
		};
		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		dialogRef.afterClosed().pipe(take(1)).subscribe();
	}
}
