import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty } from 'lodash';
import { iif, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import {
	AddressSelectionDialogComponent,
	IAddressSelectionDialogData
} from 'src/app/shared/components/dialogs/address-selection-dialog/address-selection-dialog.component';
import {
	IMessageDialogData,
	MessageDialogComponent
} from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { ICassAddress } from '../mobile-api';
import { Router } from '@angular/router';
import { RoutingPathsEnum } from '../routing/routing.service';

@Injectable({
	providedIn: 'root'
})
export class CassAddressService {
	constructor(
		private dialog: MatDialog,
		private mobileService: MobileApiService,
		private translocoService: TranslocoService,
		private router: Router
	) {}

	/**
	 * Call cass if not errors run search else confirm address
	 *
	 * @param {*} address
	 * @return {*}  {Observable<any>}
	 * @memberof SpecialOfferComponent
	 */
	verifyCassAddress(address: Partial<ICassAddress>): Observable<any> {
		return this.mobileService.getCassAddress(address).pipe(
			switchMap((cassResult) => {
				const suggestedAddress = Object.assign({}, address, cassResult.suggestedAddress);
				return Boolean(cassResult.returnCode === 'NO_ERRORS')
					? of(suggestedAddress) // suggestedAddress already has county in it to send to BE
					: this.confirmAddress(address, cassResult);
			})
		);
	}

	confirmAddress(address, cassResult): Observable<any> {
		const cassCodes = ['APT_NEEDED', 'FATAL_ERROR', 'SUGGEST_ADDRESS'];

		const originalAddress = Object.assign({}, address, cassResult?.originalAddress);
		const suggestedAddress = Object.assign({}, address, cassResult?.suggestedAddress);
		const cassError = cassCodes.some((s) => s === cassResult?.returnCode)
			? this.translocoService.translate('ADDRESS.cass.' + cassResult?.returnCode)
			: null;

		if (cassResult?.returnCode === 'SUGGEST_ADDRESS') {
			if (['city', 'state', 'streetAddress1'].every((prop) => !isEmpty(suggestedAddress[prop]))) {
				return this.openAddressModal(suggestedAddress, originalAddress);
			} else {
				return this.openConfirmModal(this.translocoService.translate('ADDRESS.cass.FATAL_ERROR'), originalAddress);
			}
		} else {
			if (cassError) {
				return this.openConfirmModal(cassError, originalAddress);
			} else {
				return this.openConfirmModal(this.translocoService.translate('ADDRESS.cass.FATAL_ERROR'), originalAddress);
			}
		}
	}

	openConfirmModal(message, originalAddress): Observable<any> {
		const data = {
			panelClass: this.router.url == `/${RoutingPathsEnum.preQualifyAddress}` ? 'pre-qualify address-verification' : '',
			title: this.translocoService.translate('ADDRESS.cass.addressVerification'),
			message,
			confirmText: this.translocoService.translate('ADDRESS.cass.useThisAddress'),
			cancelText: this.translocoService.translate('ADDRESS.cass.changeAddress')
		};
		return this.openMessageDialog(
			data,
			() => of(originalAddress),
			() => of()
		);
	}

	openAddressModal(suggestedAddress, originalAddress): Observable<any> {
		const data = {
			title: this.translocoService.translate('ADDRESS.cass.addressVerification'),
			suggestedAddress,
			originalAddress
		};
		return this.openAddressSelectionDialog(
			data,
			(address: string) => iif(() => address === 'original', of(originalAddress), of(suggestedAddress)),
			() => of()
		);
	}

	openMessageDialog(
		data: IMessageDialogData,
		confirm: (address: string) => Observable<any>,
		cancel: () => Observable<any>
	): Observable<any> {
		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(switchMap((rsp) => iif(() => rsp, confirm(rsp), cancel())));
	}

	openAddressSelectionDialog(
		data: IAddressSelectionDialogData,
		confirm: (address: string) => Observable<any>,
		cancel: () => Observable<any>
	): Observable<any> {
		const dialogRef = this.dialog.open(AddressSelectionDialogComponent, { data });
		return dialogRef.afterClosed().pipe(switchMap((rsp) => iif(() => rsp, confirm(rsp), cancel())));
	}
}
