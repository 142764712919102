import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { SharedModule } from '../shared/shared.module';
import { EsignRedirectLandingPageComponent } from './esign-redirect-landing-page/esign-redirect-landing-page.component';
import { EsignRoutingModule } from './esign-routing.module';
import { EsignComponent } from './esign/esign.component';
import { EsignEventSigningCanceledComponent } from './esign/event/signing-canceled/esign-event-signing-canceled.component';
import { EsignEventSigningCompletedComponent } from './esign/event/signing-completed/signing-completed.component';
import { EsignEventSigningDeclinedComponent } from './esign/event/signing-declined/esign-event-signing-declined.component';
import { EsignEventSigningFailedComponent } from './esign/event/signing-failed/esign-event-signing-failed.component';
import { EsignEventSigningTimeoutComponent } from './esign/event/signing-timeout/esign-event-signing-timeout.component';
import { EsignEventViewingCompletedComponent } from './esign/event/viewing-completed/esign-event-viewing-completed.component';
import { EsignHowToPrintSaveLoanComponent } from './esign/how-to-print-save-loan/esign-how-to-print-save-loan.component';
import { EsignPingListenerComponent } from './esign/ping-listener/esign-ping-listener.component';
import { EsignShowErrorComponent } from './esign/show-error/esign-show-error.component';

/**
 * Esign Pages
 *
 * @export
 * @class EsignModule
 */
@NgModule({
	declarations: [
		EsignComponent,
		EsignEventSigningCanceledComponent,
		EsignEventSigningCompletedComponent,
		EsignEventSigningDeclinedComponent,
		EsignEventSigningFailedComponent,
		EsignEventSigningTimeoutComponent,
		EsignEventViewingCompletedComponent,
		EsignHowToPrintSaveLoanComponent,
		EsignPingListenerComponent,
		EsignRedirectLandingPageComponent,
		EsignShowErrorComponent
	],
	imports: [CommonModule, EsignRoutingModule, TranslocoModule, SharedModule]
})
export class EsignModule {}
