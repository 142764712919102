import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { isFunction, noop } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { DisbursementService } from 'src/app/core/services/loan-application/disbursement/disbursement.service';
import { IAchBankAccountResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { AutoVerifyScenarioEnum, IAutoVerifyScenario } from 'src/app/core/services/plaid-link/plaid-link.model';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { MessageDialogComponent } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-bank',
	templateUrl: './section-bank.component.html',
	styleUrls: ['./section-bank.component.scss']
})
export class SectionBankComponent extends ExpandSectionComponent implements OnInit, OnDestroy {
	@Output()
	toggleDisbursement = new EventEmitter<boolean>();

	@Input()
	isVerified: boolean;

	@Input()
	isManual: string;

	@Input()
	autoVerifyScenario: IAutoVerifyScenario;

	@Output()
	checkVerificationStatus = new EventEmitter<any>();

	subscription: Subscription;
	disbursement$: Observable<DisbursementUtils>;
	isAchDisbursementSelected: boolean;

	selectAccount: boolean;
	bankConnectEvent: BankConnectEventTypeEnum;

	connectAccountError: string;
	showNoAccountsError: boolean;

	incomeError: string;
	isFromPlaid: boolean;
	showPlaid: boolean;
	verifyManually: boolean;

	constructor(
		private bankAccountService: AchBankAccountsService,
		private plaidLinkService: PlaidLinkService,
		private disbursementService: DisbursementService,
		private translocoService: TranslocoService,
		private dialog: MatDialog
	) {
		super();

		// Pass in???
		this.subscription = this.disbursementService.disbursement$.subscribe((disbursement) => {
			this.isAchDisbursementSelected = disbursement.isTypeAch();
		});
		this.showPlaid = this.getShowPlaidFlag();
		if (this.showPlaid) {
			const plaidSub = this.plaidLinkService.plaid$.subscribe({
				next: (rsp) => {
					this.onBankConnect(rsp);
				}
			});

			this.subscription.add(plaidSub);
		}
	}

	ngOnInit(): void {
		this.verifyManually = this.isManual === 'true';
	}

	toggleAchDisbursement(): void {
		this.toggleDisbursement.emit(!this.isAchDisbursementSelected);
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent = event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		if (event?.section === FileUploadType.bank) {
			this.showNoAccountsError = event.data.responseStatus !== BankConnectResponseStatusEnum.accountsFound;
			const accountsFound: boolean = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;
			const foundVerifiedIncome: boolean = event?.data?.foundVerifiedIncome;

			const showVerificationErrorModal = !accountsFound;

			if (showVerificationErrorModal) {
				this.showErrorDialog(accountsFound, foundVerifiedIncome).subscribe({
					next: () => {
						this.refreshBankAccountList(event);
					}
				});
			} else {
				this.refreshBankAccountList(event);
			}
		}
	}
	bankErrorCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = true;
	}

	bankFilterForNotVerified(item: IAchBankAccountResult): boolean {
		return item.verificationStatus !== AccountStatusEnum.verified && item.status === AccountStatusEnum.active;
	}

	bankFilterForVerified(item: IAchBankAccountResult): boolean {
		return item.verificationStatus === AccountStatusEnum.verified && item.status === AccountStatusEnum.active;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getShowPlaidFlag(): boolean {
		let autoVerifyScenario = this.autoVerifyScenario?.autoVerifyScenario;
		return autoVerifyScenario != AutoVerifyScenarioEnum.incomeOnly;
	}

	refreshBankAccountList(event: IBankConnectEvent): void {
		this.bankAccountService.refreshBankAccountList().subscribe({
			next: (rsp) => {
				if (event.data?.responseStatus !== BankConnectResponseStatusEnum.emptyAccountList && rsp.length) {
					this.selectAccount = true;
					this.isFromPlaid = true;
				}
			}
		});
	}

	showErrorDialog(accountsFound: boolean, foundVerifiedIncome: boolean): Observable<boolean> {
		let isAutoVerifyScenarioIncomeOnly: boolean =
			this.autoVerifyScenario?.autoVerifyScenario === AutoVerifyScenarioEnum.bankAndIncome &&
			!this.autoVerifyScenario?.hasBankAccountRequirement;
		let invalidAccountIncomeOnly = (accountsFound || isAutoVerifyScenarioIncomeOnly) && !foundVerifiedIncome;

		let messageKey = invalidAccountIncomeOnly
				? 'DOCUMENT_SUBMIT.proofOfIncome.provideAccountOrDocuments'
				: 'DOCUMENT_SUBMIT.proofOfBankAccount.provideAccountOrDocument',
			titleKey = invalidAccountIncomeOnly
				? 'DOCUMENT_SUBMIT.proofOfIncome.incomeNotFound'
				: 'DOCUMENT_SUBMIT.proofOfBankAccount.accountNotAccepted';

		const data = {
			icon: 'op-alert',
			title: titleKey
				? this.translocoService.translate(messageKey)
				: this.translocoService.translate('DIALOG_MESSAGE.message'),
			message: messageKey
				? this.translocoService.translate(messageKey)
				: this.translocoService.translate('DOCUMENT_SUBMIT.errorDialog')
		};

		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(take(1));
	}

	onBankVerificationComplete(event: any): void {
		this.checkVerificationStatus.emit(FileUploadType.bank);
	}
}
