import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { IFileUploadExtended } from '../../auto-verification.service';
import { FileUploadStatusEnum } from 'src/app/core/services/file-upload/file-upload.service';

@Component({
	selector: 'op-upload-display-processing',
	templateUrl: './upload-display-processing.component.html',
	styleUrls: ['./upload-display-processing.component.scss']
})
export class UploadDisplayProcessingComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	singleFile: IFileUploadExtended;

	@Input()
	uploadProgress: number;

	public fileUploadStatusEnum: typeof FileUploadStatusEnum = FileUploadStatusEnum;

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}
