import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';
import {
	firstNameValidator,
	lastNameValidator,
	maternalNameValidator,
	phoneValidator
} from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-additional-contact',
	templateUrl: './additional-contact.component.html',
	styleUrls: ['./additional-contact.component.scss']
})
export class AdditionalContactComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private metadataService: MetadataService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService
	) {
		this.createForm(this.formBuilder);
	}

	private subscription = new Subscription();

	formGroup: FormGroup;
	relationshipTypeList$: Observable<IMetadata[]>;
	toggleLanguageOptions$: Observable<IMetadata[]>;
	nameSuffixOptions$: Observable<IMetadata[]>;
	relationshipTypeList: IMetadata[];

	private readonly defaultAdditionalContact = {
		firstName: null,
		lastName: null,
		maternalName: null,
		phoneNumber: null,
		preferredLanguage: null,
		relationship: null
	};

	ngOnInit(): void {
		this.toggleLanguageOptions$ = this.metadataService.select(MetadataEnum.Language);
		this.relationshipTypeList$ = this.metadataService.select(MetadataEnum.RelationshipEmergency);
		this.nameSuffixOptions$ = this.metadataService.select(MetadataEnum.PersonalNameSuffix);

		const excludeRelationshipTypeKeys = [1531, 1534, 1536];

		const relationshipSub = this.relationshipTypeList$.subscribe({
			next: (relationshipTypes) =>
				(this.relationshipTypeList = relationshipTypes?.filter(
					(relationshipType) => !excludeRelationshipTypeKeys.includes(Number(relationshipType?.key))
				))
		});
		this.subscription.add(relationshipSub);

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				take(1),
				switchMap((rsp) => this.mobileService.getAdditionalContact(this.loanAppService.loanApplicationId))
			)
			.subscribe({
				next: (resp) => {
					const additionContact = _(resp)
						.pick(['firstName', 'lastName', 'maternalName', 'preferredLanguage', 'relationship', 'phoneNumber'])
						.defaults(this.defaultAdditionalContact)
						.value();
					this.formGroup.patchValue(additionContact);
				}
			});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			firstName: [null, [Validators.required, firstNameValidator()]],
			lastName: [null, [Validators.required, lastNameValidator()]],
			maternalName: [null, [maternalNameValidator()]],
			suffix: [null],
			preferredLanguage: [null, [Validators.required]],
			relationship: [null, [Validators.required]],
			phoneNumber: [null, [Validators.required, phoneValidator()]]
		});
	}

	onSubmit(post: any): void {
		const contact = { ...this.formGroup.value, phoneNumber: PhoneUtils.formatPhoneNumber(post.phoneNumber) };
		this.mobileService
			.updateAdditionalContact(contact, this.loanAppService.loanApplicationId)
			.pipe(
				switchMap((rsp) => this.loanAppService.updateLoanApplication()),
				switchMap((loanApp: ILoanApplication) => {
					const productCategorySelection = this.sessionStorageService.get('productCategorySelection');
					return forkJoin([of(loanApp), this.mobileService.getLoanTerms(productCategorySelection, loanApp.id)]);
				}),
				switchMap(([loanApp, loanTerms]) => {
					return this.routingService.getOnlineNotificationStep(loanApp, loanTerms);
				})
			)
			.subscribe({
				next: (route) => {
					this.routingService.route(route);
				},
				error: (err) => {
					this.dialogService.openErrorDialog(err).subscribe();
				}
			});
	}
}
