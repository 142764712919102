<div class="multifactor" *transloco="let t">
	<div *ngIf="sendCode">
		<op-masthead [title]="t('MULTIFACTOR.title')" [subTitle]="t('MULTIFACTOR.subTitle')"></op-masthead>
		<op-content-container class="content">
			<form class="op-left" [formGroup]="formGroupSend" (ngSubmit)="onSubmit(formGroupSend.value)">
				<mat-radio-group
					aria-labelledby="radio-group-label"
					class="radio-group"
					id="verifyType"
					formControlName="verifyType"
				>
					<div class="radio-button" *ngFor="let type of verifyTypes">
						<mat-radio-button
							*ngIf="(phoneType === 'CL' && type.key === 'sms') || type.key === 'call'"
							id="{{ 'verifyType_' + type.key }}"
							[value]="type.key"
						>
							<div class="op-body-1-bold-font">{{ t(type.text) }}</div>
							<div class="op-body-2-font">(***) ***-{{ phoneNumber }}</div>
						</mat-radio-button>
					</div>
				</mat-radio-group>
				<div class="op-body-2-font op-mt-20" *ngIf="verifyType === 'sms'">
					{{ t('MULTIFACTOR.smsAuthorization') }}
				</div>
				<div class="op-center">
					<button
						mat-flat-button
						color="primary"
						name="sendCodeSubmitBtn"
						id="sendCodeSubmitBtn"
						[disabled]="formGroupSend.invalid"
						type="submit"
					>
						{{ t('GLOBAL.submit') }}
					</button>
				</div>
			</form>
		</op-content-container>
	</div>

	<div *ngIf="!sendCode">
		<op-masthead [title]="t('MULTIFACTOR.title')"></op-masthead>
		<op-content-container class="content">
			<form class="op-left" [formGroup]="formGroupVerify" (ngSubmit)="onSubmit(formGroupVerify.value)">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('MULTIFACTOR.enterCode') }} </mat-label>
					<input
						matInput
						type="text"
						inputmode="numeric"
						id="verificationCode"
						formControlName="verificationCode"
						required
					/>
					<mat-error
						id="verificationCodeError"
						*ngIf="
							!formGroupVerify.controls['verificationCode'].valid &&
							formGroupVerify.controls['verificationCode'].touched
						"
					>
						{{ errorMessage }}
					</mat-error>
					<mat-hint id="multifactorHint">{{ t('MULTIFACTOR.enterCodeInfo', { last4: phoneNumber }) }}</mat-hint>
				</mat-form-field>

				<p></p>
				<div *ngIf="reSendSuccess" class="op-center fadeIn resend-success" id="send-success">
					<mat-icon svgIcon="op-checkMark"></mat-icon>
					{{ t('MULTIFACTOR.sendSuccess') }}
				</div>

				<div class="op-center">
					<button
						mat-flat-button
						color="primary"
						name="submitBtn"
						id="submitBtn"
						[disabled]="formGroupVerify.invalid"
						type="submit"
					>
						{{ t('MULTIFACTOR.verifyCode') }}
					</button>
				</div>
				<p></p>

				<div class="op-center op-label-font">
					<a href="" onclick="return false" (click)="resendCode()" id="sendCodeLink" aria-controls="send-success">{{
						t('MULTIFACTOR.resend')
					}}</a>
				</div>
			</form>
		</op-content-container>
	</div>

	<div class="op-mtb-40">
		<p class="op-body-2-font op-mb-12" [innerHTML]="t('MULTIFACTOR.runIntoIssues')"></p>
		<p class="op-label-font" *ngIf="sendCode">{{ t('MULTIFACTOR.rateMayApply') }}</p>
	</div>
</div>
