import { Component } from '@angular/core';

import { StepBaseComponent } from '../step-base/step-base.component';

@Component({
	selector: 'op-step-title',
	templateUrl: './step-title.component.html',
	styleUrls: ['./step-title.component.scss']
})
export class StepTitleComponent extends StepBaseComponent {
	constructor() {
		super();
	}
}
