import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { MobileApiService } from 'src/app/core/services/mobile-api';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';

import { ManuallyAddBankComponent } from '../manually-add-bank/manually-add-bank.component';

@Component({
	selector: 'op-manually-add-bank-ext',
	templateUrl: './manually-add-bank-ext.component.html',
	styleUrls: ['./manually-add-bank-ext.component.scss']
})
export class ManuallyAddBankExtComponent extends ManuallyAddBankComponent {
	constructor(
		protected formBuilder: FormBuilder,
		protected mobileService: MobileApiService,
		protected loanAppService: LoanApplicationService,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected sessionStorageService: SessionStorageService,
		protected issuingPartnerService: IssuingPartnerService
	) {
		super(
			formBuilder,
			mobileService,
			loanAppService,
			bankAccountService,
			tagDataService,
			sessionStorageService,
			issuingPartnerService
		);
	}
}
