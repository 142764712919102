import { Component, Input, HostListener } from '@angular/core';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { opRequired } from '../../decorators/required.decorator';

/**
 * information tip
 * When click on information is displayed below icon.
 *
 * @export
 * @class InfoTipComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-info-tip',
	templateUrl: './info-tip.component.html',
	styleUrls: ['./info-tip.component.scss']
})
export class InfoTipComponent {
	@Input()
	@opRequired()
	id: string;

	@Input() key: string;

	@Input() icon: string;

	@Input() fontSet: string;

	@Input() ariaLabel: string;

	show: boolean = false;

	constructor(private tagDataService: TagDataService) {}

	@HostListener('keydown.enter', ['$event'])
	@HostListener('keydown.space', ['$event'])
	toggle(): void {
		this.show = !this.show;
		this.tagDataService.link(
			{},
			{
				tealium_event: 'info_icon_click',
				event_label: 'rx_form',
				event_category: 'Form',
				event_action: 'submit'
			}
		);
	}
}
