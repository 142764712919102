<div class="add-bank-account" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="addBankRachBack">{{ t('ACCESSIBILITY.back') }}</op-back-link>
	<op-masthead [customClass]="'head'" [title]="t('ESIGN_AUTO_PAY.ADD_BANK.title')"></op-masthead>
	<op-content-container class="content">
		<op-add-rach-bank-account (rachBankAccountAdded)="rachBankAccountAdded($event)"></op-add-rach-bank-account>
		<!--div class="separator op-mtb-10">
            {{ t('GLOBAL.or') | uppercase }}
        </div>
        <div class="op-form-title op-body-1-bold-font">
            {{ t('ESIGN_AUTO_PAY.ADD_BANK.login') }}
        </div>
        <div class="op-body-1-font op-gray op-mb-16">
            {{ t('ESIGN_AUTO_PAY.ADD_BANK.enterCredentialViaPlaid') }}
        </div>
        <op-plaid-button-ext id="plaidButton" [bankOnly]="true" [showBenefits]="false"></op-plaid-button-ext-->
	</op-content-container>
</div>
