<div class="confirm-remove-document-dialog" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button
				mat-icon-button
				id="messageDialogCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-3-font">
			<span>{{ t('DOCUMENT_SUBMIT.removeDocumentUploads2.removeAllUploads') }}</span>
		</div>
	</div>
	<div mat-dialog-content class="op-center">
		<div
			class="op-mt-20 op-left"
			[innerHTML]="t('DOCUMENT_SUBMIT.removeDocumentUploads2.removeAllUploadsModalBody')"
		></div>
	</div>
	<div class="op-center op-mt-20" mat-dialog-actions>
		<button
			class="op-full-width confirm-btn"
			mat-flat-button
			color="primary-small"
			id="confirmDeleteBtn"
			(click)="onSubmit()"
		>
			{{ t('DOCUMENT_SUBMIT.removeDocumentUploads2.confirmButtonText') }}
		</button>
	</div>
	<div class="op-center op-mtb-20">
		<a mat-flat-button color="link-md" href="" onclick="return false" id="confirmDeleteCancelBtn" (click)="close()">
			{{ t('DOCUMENT_SUBMIT.removeDocumentUploads2.cancelButtonText') }}
		</a>
	</div>
</div>
