import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { opRequired } from '../../decorators/required.decorator';

@Component({
	selector: 'op-attention-item',
	templateUrl: './attention-item.component.html',
	styleUrls: ['./attention-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttentionItemComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	icon: string;

	@Input()
	itemText: string;

	@Input()
	itemSubText: string;

	@Input()
	attentionReasons: string;
}
