<div class="bounced-attention">
	<!-- Begin Attention Items -->
	<op-attention-item
		id="PhotoItem"
		*ngIf="showSection.showPhotoItem"
		itemText="STATUS.photo"
		[attentionReasons]="showSection.showPhotoItem"
	>
	</op-attention-item>

	<op-attention-item
		id="ReferenceItem"
		*ngIf="showSection.showReferencesItem"
		itemText="STATUS.references"
		[attentionReasons]="showSection.showReferencesItem"
	>
	</op-attention-item>

	<op-attention-item
		id="PhotoIdItem"
		*ngIf="showSection.showPhotoIdItem"
		itemText="STATUS.photoId"
		[attentionReasons]="showSection.showPhotoIdItem"
	>
	</op-attention-item>

	<op-attention-item
		id="AddressItem"
		*ngIf="showSection.showAddressItem"
		itemText="STATUS.proofOfHome"
		[attentionReasons]="showSection.showAddressItem"
	>
	</op-attention-item>

	<op-attention-item
		id="IncomeItem"
		*ngIf="showSection.showIncomeItem"
		itemText="STATUS.income"
		[attentionReasons]="showSection.showIncomeItem"
	>
	</op-attention-item>

	<op-attention-item
		id="BankAccountItem"
		*ngIf="showSection.showBankAccountItem"
		itemText="STATUS.bankAccount"
		[attentionReasons]="showSection.showBankAccountItem"
	>
	</op-attention-item>

	<op-attention-item
		id="VehicleItem"
		*ngIf="showSection.showVehicleItem"
		itemText="STATUS.vehicle"
		[attentionReasons]="showSection.showVehicleItem"
	>
	</op-attention-item>

	<op-attention-item
		id="VehiclePhotos"
		*ngIf="showSection.showVehiclePhotos"
		itemText="DOCUMENT_SUBMIT.vehiclePhotos.title"
		[attentionReasons]="showSection.showVehiclePhotos"
	>
	</op-attention-item>

	<op-attention-item
		id="VehicleRegistration"
		*ngIf="showSection.showVehicleRegistration || showSection.showDMVReqIdProof"
		itemText="DOCUMENT_SUBMIT.vehicleRegistration.title"
		[attentionReasons]="showSection.showVehicleRegistration || showSection.showDMVReqIdProof"
	>
	</op-attention-item>

	<op-attention-item
		id="VehicleLicense"
		*ngIf="showSection.showVehicleDriversLicense"
		itemText="DOCUMENT_SUBMIT.vehicleDriversLicense.title"
		[attentionReasons]="showSection.showVehicleDriversLicense"
	>
	</op-attention-item>

	<!-- End Attention Items -->
</div>
