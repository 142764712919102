<div class="check-delivery-notice-ach-disbursement" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button mat-icon-button id="denyAchCloseBtn" [mat-dialog-close]="false" aria-label="{{ t('GLOBAL.close') }}">
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
	</div>
	<div mat-dialog-content>
		<p innerHTML="{{ 'DENY_ACH_DISBURSEMENT_MODAL.confirmMessage' | transloco }}"></p>
		<p>{{ 'DENY_ACH_DISBURSEMENT_MODAL.description' | transloco }}</p>
	</div>
	<div mat-dialog-actions class="footer">
		<button mat-flat-button color="primary-small" id="denyAchConfirmBtn" (click)="onClick()">
			{{ 'DENY_ACH_DISBURSEMENT_MODAL.confirm' | transloco }}
		</button>
		<button mat-flat-button color="secondary-small" id="denyAchCancelBtn" (click)="onNoClick()">
			{{ 'DENY_ACH_DISBURSEMENT_MODAL.cancel' | transloco }}
		</button>
	</div>
</div>
