import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'op-switch-offer',
	templateUrl: './switch-offer.component.html',
	styleUrls: ['./switch-offer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchOfferComponent {
	@Input()
	isSPL: boolean;

	@Input()
	areAllProductsApproved: boolean;

	@Output()
	switchOffer = new EventEmitter<any>();

	switchToOtherOffer(): void {
		this.switchOffer.emit();
	}
}
