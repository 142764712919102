import { Component, EventEmitter, Input, Output } from '@angular/core';
import IProduct from 'src/app/core/services/loan-application/product-offer/product/product.model';

import { ProductOfferCardComponent } from '../product-offer-card/product-offer-card.component';

@Component({
	selector: 'op-unsecured-personal-loan-pre-approved',
	templateUrl: './unsecured-personal-loan-pre-approved.component.html',
	styleUrls: ['./unsecured-personal-loan-pre-approved.component.scss']
})
export class UnsecuredPersonalLoanPreApprovedComponent extends ProductOfferCardComponent {
	@Input() product: IProduct;
	@Input() showBankConnectedMessage: boolean = false;

	@Output()
	continue = new EventEmitter<any>();

	constructor() {
		super();
	}

	onContinue(method: string): void {
		this.continue.emit({ method, productCategory: this.product.productCategory });
	}
}
