<div class="document-submit">
	<div [ngSwitch]="autoVerificationEligible$ | async">
		<div *ngSwitchCase="ocrEligibilityEnum.newExperience">
			<op-auto-verification-original></op-auto-verification-original>
		</div>

		<div *ngSwitchCase="ocrEligibilityEnum.newExperienceDelay">
			<op-auto-verification></op-auto-verification>
		</div>

		<div *ngSwitchDefault>
			<op-manual-verification></op-manual-verification>
		</div>
	</div>
</div>
