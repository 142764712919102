import { Component, Input } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-income',
	templateUrl: './section-income.component.html',
	styleUrls: ['./section-income.component.scss']
})
export class SectionIncomeComponent extends ExpandSectionComponent {
	@Input()
	isVerified: boolean;

	constructor() {
		super();
	}
}
