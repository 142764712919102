export interface IFTRSectionFields {
	title: string;
	titleValue?: boolean;
	value: string;
	route?: string;
	header?: string;
}

export interface IFTRSection {
	sectionTitle: string;
	sectionSubTitle: string;
	sectionNote: string;
	section: FTRSectionNameEnum;
	header: string;
	route: string;
	fields: IFTRSectionFields[];
	hide?: boolean;
	mode?: FTRSectionModeEnum;
}

export enum FTRSectionModeEnum {
	editable = 'Editable',
	nonEditable = 'NonEditable',
	newAddition = 'NewAddition'
}

export enum FTRSectionNameEnum {
	yourInformation = 'yourInformation',
	loanPurpose = 'LoanPurpose',
	address = 'address',
	identification = 'identification',
	finances = 'finances',
	debt = 'debt',
	income = 'income',
	legalDisclosure = 'LegalDisclosure'
}

export const FTRSectionRoute = new Map([
	['loan-purpose', FTRSectionNameEnum.loanPurpose],
	['address', FTRSectionNameEnum.address],
	['personal-info', FTRSectionNameEnum.yourInformation],
	['identity-confirm', FTRSectionNameEnum.identification],
	['identification', FTRSectionNameEnum.identification],
	['credit-auth', FTRSectionNameEnum.debt],
	['finances', FTRSectionNameEnum.finances],
	['income', FTRSectionNameEnum.income]
]);
