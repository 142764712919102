import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors
} from '@angular/forms';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-adjust-payment-frequency',
	templateUrl: './payment-frequency.component.html',
	styleUrls: ['./payment-frequency.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PaymentFrequencyComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: PaymentFrequencyComponent
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFrequencyComponent implements OnInit {
	private _viewablePayFreq: IMetadata[];
	public viewablePayFreqKeys: number[];
	@Input()
	set viewablePayFreq(freq: IMetadata[]) {
		this._viewablePayFreq = freq;
		this.viewablePayFreqKeys = freq?.map((item) => Number(item.key));
	}
	get viewablePayFreq(): IMetadata[] {
		return this._viewablePayFreq;
	}

	@Input()
	@opRequired()
	id: string;

	formGroup: FormGroup;

	payFreqOptions: IMetadata[] = [
		{ key: 30, text: 'ADJUST_TERMS.onceMonth' },
		{ key: 14, text: 'ADJUST_TERMS.every2Weeks' },
		{ key: 15, text: 'ADJUST_TERMS.twiceMonthly' }
	];

	constructor(private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({
			paymentFrequency: [null]
		});
		this.formGroup.get('paymentFrequency').valueChanges.subscribe({
			next: (val) => {
				this.onChange(val);
			}
		});
	}

	get value(): number | null {
		return this.formGroup.get('paymentFrequency').value;
	}
	set value(paymentFrequency: number | null) {
		this.formGroup.setValue({ paymentFrequency });
		this.onChange(paymentFrequency);
	}

	onChange = (change) => {
		// This is intentional empty
	};
	onTouched = () => {
		// This is intentional empty
	};
	touched = false;
	disabled = false;

	ngOnInit(): void {
		// This is intentional empty
	}

	// Begin ControlValueAccessor interface
	writeValue(value: number): void {
		this.value = value;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	// End ControlValueAccessor interface

	// Begin Validator interface
	validate(control: AbstractControl): ValidationErrors | null {
		return null;
	}
	// End Validator interface
}
