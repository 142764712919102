import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';

import { RoutingPathsEnum } from '../services/routing/routing.service';
import { LoanApplicationService } from '../services/loan-application/loan-application.service';
import { ConfigApiService, IRDGFeatureFlagResult } from '../services/mobile-api';
import { ILoanApplication } from '../services/loan-application/loan-application.model';

@Injectable()
export class DisbursementRoutingGuard implements CanActivate {
	routingMappings = null;
	originationSource: string = '';

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private configApiService: ConfigApiService,
		private loanAppService: LoanApplicationService
	) {
		this.routingMappings = [
			{ from: RoutingPathsEnum.bankVerification, to: RoutingPathsEnum.fundingOptions },
			{ from: RoutingPathsEnum.funds, to: RoutingPathsEnum.fundingOptions },
			{ from: RoutingPathsEnum.receiveFunds, to: RoutingPathsEnum.fundingOptions }
		];
	}

	canActivate(
		route: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.updateLoanApplication().pipe(
			filter(Boolean),
			switchMap((loanApp: ILoanApplication) => {
				this.originationSource = loanApp.originationSource?.toUpperCase();
				return this.configApiService.showNewFundingDesign(loanApp.id);
			}),
			map((configResult: IRDGFeatureFlagResult) => {
				if (configResult?.channelRDGEligible?.[this.originationSource || ''] === true) {
					const routingMapping = this.routingMappings.find((mapping) => mapping.from === route.routeConfig.path);
					if (routingMapping?.to) {
						this.router.navigate([routingMapping?.to], {
							queryParams: {},
							queryParamsHandling: 'merge',
							relativeTo: this.activatedRoute,
							replaceUrl: true
						});
						return false;
					} else {
						return true;
					}
				} else {
					return true;
				}
			})
		);
	}
}
