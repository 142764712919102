import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IStoreEntity } from 'src/app/core/services/mobile-api/mobile-api.model';

/**
 * store item to be displayed in a list.
 *
 * @export
 * @class FindStoreItemComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-find-store-item',
	templateUrl: './find-store-item.component.html',
	styleUrls: ['./find-store-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindStoreItemComponent {
	@Input()
	store: IStoreEntity;
}
