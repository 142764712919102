<div class="document-review" *transloco="let t">
	<op-content-container>
		<div class="review-image">
			<img src="/assets/images/op-document-review.svg" alt="" />
		</div>
		<div class="progress-wrapper">
			<div class="op-right op-label-font op-dark-grey">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reviewingSecondsRemaining', { time: duration }) }}
			</div>

			<mat-progress-bar class="submit-progress op-mt-5" mode="determinate" [value]="progress"> </mat-progress-bar>
		</div>
		<div class="review-header op-left op-mt-0">
			<div class="op-display-3-font review-app-title">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reviewingDocuments') }}
			</div>
			<div class="op-body-1-font review-app-subtitle op-dark-grey">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reviewingDocumentsInfo') }}
			</div>
		</div>

		<ng-lottie [options]="options"></ng-lottie>
		<div class="op-center op-body-1-font op-dark-grey">
			<span>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reviewingPoweredBy') }}</span>
			<span><img class="trustpilot" src="/assets/images/trustpilot.svg" alt="" /></span>
		</div>
	</op-content-container>
</div>
