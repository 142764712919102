<div class="add-address-dialog pre-qualify" [formGroup]="formGroup" *transloco="let t">
	<div>
		<div mat-dialog-title>
			<div class="op-right op-mb-8">
				<button
					mat-icon-button
					id="addressSelectCloseBtn"
					[mat-dialog-close]="false"
					aria-label="{{ t('GLOBAL.close') }}"
				>
					<mat-icon svgIcon="op-close"></mat-icon>
				</button>
			</div>
			<div class="op-label-font">
				<span [innerHTML]="data.title" id="csd-label"></span>
			</div>
		</div>

		<div class="pre-qualify">
			<mat-form-field class="form-element op-full-width" appearance="outline" [hideRequiredMarker]="true">
				<mat-label>{{ t('ADDRESS.streetAddress') }}</mat-label>
				<input
					matInput
					formControlName="streetAddress1"
					name="streetAddress1"
					id="add-address-dialog-address1"
					opAddressAutoComplete
					placeholder="Street Address"
					[latLngLimiter]="latLngLimiter"
					(addressChange)="handleAddressChange($event)"
					aria-required="true"
				/>
				<mat-error
					id="address1Error"
					*ngIf="!formGroup.controls['streetAddress1'].valid && formGroup.controls['streetAddress1'].touched"
				>
					{{ t('ADDRESS.streetAddress1Error') }}
				</mat-error>
			</mat-form-field>

			<div *ngIf="expandAddress">
				<mat-form-field class="form-element op-full-width" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('ADDRESS.streetAddress2') }}</mat-label>
					<input matInput type="text" name="streetAddress2" id="streetAddress2" formControlName="streetAddress2" />
				</mat-form-field>

				<mat-form-field class="form-element op-full-width" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('ADDRESS.city') }} </mat-label>
					<input matInput type="text" name="city" id="city" formControlName="city" aria-required="true" />
				</mat-form-field>

				<mat-form-field class="form-element op-full-width" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('ADDRESS.state') }} </mat-label>
					<mat-select formControlName="state" name="state" id="state" #state aria-label="state" aria-required="true">
						<mat-option
							*ngFor="let state of stateList$ | async; let i = index"
							id="{{ '_state_' + state.key }}"
							[value]="state.key"
							>{{ state.text }}</mat-option
						>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="form-element op-full-width" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('ADDRESS.zipCodeFull') }} </mat-label>
					<input
						matInput
						type="text"
						inputmode="numeric"
						pattern="[0-9]*"
						name="postalCode"
						id="_postalCode"
						formControlName="postalCode"
						aria-required="true"
					/>
					<mat-error
						id="_zipCodeError"
						*ngIf="!formGroup.controls['postalCode'].valid && formGroup.controls['postalCode'].touched"
					>
						{{ t('ADDRESS.zipCodeError') }}
					</mat-error>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div>
		<div mat-dialog-actions>
			<button
				class="submit"
				mat-flat-button
				color="primary-small"
				id="addAddressSubmitBtn"
				type="submit"
				[disabled]="formGroup.invalid"
				(click)="onSubmit(formGroup.value)"
			>
				{{ 'GLOBAL.done' | transloco }}
			</button>
		</div>
	</div>
</div>
