import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	FTPApplicationType,
	IFindApplication,
	IFTPInprogressData,
	IFTPNewAppData,
	MobileApiService
} from 'src/app/core/services/mobile-api';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';

@Component({
	selector: 'op-find-prequal-application',
	template: ''
})
export class FindPrequalApplicationComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private landingService: LandingService,
		private dateUtilService: DateUtilsService,
		private authService: AuthenticationService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private mobileService: MobileApiService,
		private loanApplicationService: LoanApplicationService
	) {}

	ngOnInit(): void {
		const { lang, pq_rt: token } = this.route.snapshot.queryParams || {};
		if (lang) {
			this.translocoService.setActiveLang(lang === 'es' ? 'es' : 'en');
		}

		if (!token) {
			return this.openErrorDialog();
		}
		this.mobileService.decryptTokenForFastTrackPrequalified(token).subscribe({
			next: (res) => {
				this.processPrequalInfo(res.data);
			},
			error: (err) => {
				this.openErrorDialog();
			}
		});
	}

	private processPrequalInfo(ftpData: IFTPInprogressData | IFTPNewAppData): void {
		if (ftpData?.type === FTPApplicationType.inProgress) {
			this.handleInprogressApplication(ftpData as IFTPInprogressData);
		} else if (ftpData?.type === FTPApplicationType.new) {
			this.handleFastTrackApplication(ftpData as IFTPNewAppData);
		} else {
			this.routingService.route(RoutingPathsEnum.findYourApplication);
		}
	}

	private handleInprogressApplication(inProgressData: IFTPInprogressData): void {
		const searchData: IFindApplication = {
			lastName: inProgressData.lastName,
			zipCode: inProgressData.zipCode,
			phoneNumber: PhoneUtils.formatPhoneNumber(inProgressData.phoneNumber),
			dateOfBirth: this.dateUtilService.format(inProgressData.dateOfBirth, 'yyyy-MM-dd')
		};

		this.findApplication(searchData);
	}

	private handleFastTrackApplication(newAppData: IFTPNewAppData): void {
		this.authService.updateAuthorizationToken(newAppData.token);
		this.loanApplicationService
			.updateLoanApplication(newAppData.loanApplicationId, true)
			.pipe(
				switchMap(() => this.mobileService.getPhone(newAppData.loanApplicationId)),
				map((rsp) => PhoneUtils.fromPhoneResult(rsp))
			)
			.subscribe({
				next: (phoneInfo) => {
					const phoneNumber: string = phoneInfo.getPhoneNumber();
					if (!phoneNumber) {
						return this.openErrorDialog();
					} else {
						this.landingService.updateFastTrackData({
							loanAppId: newAppData.loanApplicationId,
							phoneNumber: phoneNumber?.slice(phoneNumber.length - 4),
							phoneType: phoneInfo.getPhoneType()
						});
						this.routingService.route(RoutingPathsEnum.multifactor);
					}
				},
				error: () => this.openErrorDialog()
			});
	}

	findApplication(searchData: IFindApplication): void {
		this.landingService.searchApplication(searchData).subscribe({
			next: (resp) => {
				if (
					resp.inProgressApplication === true ||
					resp.hasActiveLoan === true ||
					(resp.returningCustomer && resp.token)
				) {
					this.routingService.route(RoutingPathsEnum.multifactor);
				} else {
					this.openErrorDialog();
				}
			},
			error: () => {
				this.openErrorDialog();
			}
		});
	}

	openErrorDialog(): void {
		const data = {
			icon: 'op-alert',
			title: this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.title'),
			message: this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.message')
		};

		this.dialogService
			.openMessageDialog(
				data,
				() => of(this.routingService.route(RoutingPathsEnum.findYourApplication)),
				() => of(this.routingService.route(RoutingPathsEnum.findYourApplication))
			)
			.subscribe();
	}
}
