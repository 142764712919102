<div class="proof-of-bank-account" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead
		[customClass]="'head-bp-4'"
		[title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfBankAccount.bankAccountTitle')"
	>
	</op-masthead>
	<op-content-container class="content" *ngIf="documentClassificationList$ | async as classificationList">
		<op-document-classification
			*ngIf="proofState === proofStatusEnum.classificationSelection"
			id="bankAccountClassification"
			[documentClassificationList]="classificationList"
			(documentClassification)="saveDocumentClassification($event)"
			(cancel)="onCancel($event)"
		>
		</op-document-classification>

		<div *ngIf="proofState === proofStatusEnum.upload">
			<div>
				<div class="op-header-3-font op-mb-8">{{ stateStorage?.selectedDocumentClassification?.text }}</div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfBankAccount.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								)
							"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</div>

			<op-document-notification class="attention op-mtb-20" *ngIf="bounceReasons?.length" id="bankBounceReasons">
				<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification>

			<op-document-upload
				id="bankAccountUpload"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[showSingleFile]="false"
			>
				<div uploadIdleInfo>
					<div
						class="op-dark-grey op-mt-8 op-body-2-font"
						[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
					></div>
				</div>
				<div uploadIdleButtonText>
					<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
				</div>
			</op-document-upload>

			<div class="op-mt-40">
				<button
					mat-flat-button
					color="primary"
					name="bankAccountContinueBtn"
					id="bankAccountContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="'continue bank'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="bankAccountCancelBtn"
					id="bankAccountCancelBtn"
					(click)="onCancel()"
					[opTagClick]="'cancel bank'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>

		<div *ngIf="proofState === proofStatusEnum.status">
			<op-document-status
				id="bankAccountStatus"
				[categoryType]="categoryType"
				[classificationVerification]="autoVerificationMap"
				(done)="onDocStatus($event)"
				[documentClassificationList]="classificationList"
			>
				<div [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.additionDocInfo')"></div>

				<op-document-notification class="attention op-mtb-20" *ngIf="bounceReasons?.length" id="bankBounceReasons">
					<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
						<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
					</ul>
				</op-document-notification>
			</op-document-status>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="doneBtn"
					id="bankAccountStatusBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</div>
		<div class="op-bp-40"></div>
	</op-content-container>
</div>
