import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { ApplicantUtils } from '../loan-application/applicant/applicant-utils';
import { ApplicationStatusEnum, ILoanApplication } from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { IAchBankAccountResult, ISetBank, IUpdateBank } from '../mobile-api/mobile-api.model';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	PlaidLinkService
} from '../plaid-link/plaid-link.service';

export interface IAchBankAccount extends IAchBankAccountResult {
	selected?: boolean;
}

export enum AccountStatusEnum {
	verified = 'VERIFIED',
	active = 'ACTIVE',
	new = 'NEW'
}

@Injectable({
	providedIn: 'root'
})
export class AchBankAccountsService implements OnDestroy {
	// TODO: need to combine into one list of accounts, but it requires a larger change.
	private readonly bankAccountsSource = new BehaviorSubject<IAchBankAccount[]>([]);
	private readonly returningBankAccountsSource = new BehaviorSubject<IAchBankAccount[]>([]);

	// Exposed observable (read-only).
	readonly bankAccounts$ = this.bankAccountsSource.asObservable();
	readonly returningBankAccounts$ = this.returningBankAccountsSource.asObservable();

	private applicant: ApplicantUtils;

	private subscription = new Subscription();

	private validAppStatus = [
		ApplicationStatusEnum.started,
		ApplicationStatusEnum.approved,
		ApplicationStatusEnum.coAppOffered,
		ApplicationStatusEnum.prequalAccepted,
		ApplicationStatusEnum.preApproved,
		ApplicationStatusEnum.referenceCheck,
		ApplicationStatusEnum.referencesVerified,
		ApplicationStatusEnum.requiresAttention,
		ApplicationStatusEnum.securedAccepted,
		ApplicationStatusEnum.securedOffered,
		ApplicationStatusEnum.submitted,
		ApplicationStatusEnum.titleReview,
		ApplicationStatusEnum.termsConfirmed,
		ApplicationStatusEnum.futureProspect,
		ApplicationStatusEnum.documentsSigned
	];

	constructor(
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private authService: AuthenticationService,
		private plaidLinkService: PlaidLinkService
	) {
		this.loanAppService.loanApplication$
			.pipe(
				filter((loanApp) => Boolean(loanApp)),
				filter((loanApp) => this.validAppStatus.some((item) => item === loanApp.applicationStatus)),
				filter((loanApp) => Boolean(loanApp.applicants)),
				switchMap((loanApp: ILoanApplication) => {
					this.applicant = ApplicantUtils.fromLoanApp(loanApp);
					return !this.applicant.isCoApplicant()
						? this.refreshBankAccounts(loanApp.id)
						: of(this.bankAccountsSource.next([]));
				})
			)
			.subscribe();

		// Clear out data if no token
		const authSub = this.authService.authorization$
			.pipe(
				map((rsp) => rsp?.token),
				map((token) => !token && this.bankAccountsSource.next(null))
			)
			.subscribe();
		this.subscription.add(authSub);

		const plaidSub = this.plaidLinkService.plaid$
			.pipe(
				map((bankConnect) => {
					return (
						bankConnect?.type === BankConnectEventTypeEnum.complete &&
						bankConnect?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound
					);
				}),
				filter(Boolean),
				switchMap(() => this.refreshBankAccounts())
			)
			.subscribe();
		this.subscription.add(plaidSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	refreshBankAccounts(loanId: number = null): Observable<IAchBankAccount[]> {
		const loanAppId = loanId || this.loanAppService.loanApplicationId;
		if (!Boolean(loanAppId)) {
			return of([]);
		}

		return forkJoin({
			accounts: this.mobileService.getAchBankAccounts(loanAppId),
			account: this.mobileService.getAchBankAccount(loanAppId),
			returningAccounts: this.loanAppService.isGCPOrReturnApplicant()
				? this.mobileService.getAchBankAccountsForApplicant(loanAppId)
				: of([])
		}).pipe(
			map(({ accounts, account, returningAccounts }) => {
				return {
					accounts: accounts.map((i) => (i.id == account.id ? { ...i, selected: true } : i)),
					returningAccounts: returningAccounts.map((k) => (k.id == account.id ? { ...k, selected: true } : k))
				};
			}),
			tap(({ accounts, returningAccounts }) => {
				this.bankAccountsSource.next(accounts);
				this.returningBankAccountsSource.next(returningAccounts);
			}),
			map(({ accounts }) => accounts)
		);
	}

	refreshBankAccountList(loanId: number = null): Observable<IAchBankAccount[]> {
		const loanAppId = loanId || this.loanAppService.loanApplicationId;
		if (!Boolean(loanId)) {
			return of([]);
		}

		return this.mobileService.getAchBankAccounts(loanAppId).pipe(
			map((accounts) => {
				return accounts;
			}),
			tap((accounts) => this.bankAccountsSource.next(accounts))
		);
	}

	addBankAccount(bankAccount: ISetBank): Observable<IAchBankAccount[]> {
		return this.mobileService
			.setAchBankAccount(bankAccount, this.loanAppService.loanApplicationId)
			.pipe(switchMap(() => this.refreshBankAccounts(this.loanAppService.loanApplicationId)));
	}

	selectBankAccount(bank: IUpdateBank) {
		return this.mobileService
			.updateAchBankAccount(bank, this.loanAppService.loanApplicationId)
			.pipe(switchMap(() => this.refreshBankAccounts(this.loanAppService.loanApplicationId)));
	}

	updateBankAccountPostApproval(bankAccountId: number): Observable<IAchBankAccount[]> {
		return this.mobileService
			.updatePostApprovalAchBankAccount(bankAccountId, this.loanAppService.loanApplicationId)
			.pipe(switchMap(() => this.refreshBankAccounts(this.loanAppService.loanApplicationId)));
	}

	isBankVerified(): boolean {
		return Boolean(
			this.bankAccountsSource.getValue()?.filter((b) => b.verificationStatus === AccountStatusEnum.verified).length
		);
	}

	isBankConnected(): boolean {
		return Boolean(this.bankAccountsSource.getValue()?.length);
	}

	isReturningBankVerified(): boolean {
		return Boolean(
			this.returningBankAccountsSource.getValue()?.filter((b) => b.verificationStatus === AccountStatusEnum.verified)
				.length
		);
	}

	isReturningBankConnected(): boolean {
		return Boolean(this.returningBankAccountsSource.getValue()?.length);
	}
}
