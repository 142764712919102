<div class="check-delivery-confirm" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button mat-icon-button id="denyAchCloseBtn" [mat-dialog-close]="false" aria-label="{{ t('GLOBAL.close') }}">
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<p class="check-delivery-title" innerHTML="{{ 'DISBURSEMENT_SELECTION.CHECK.DELIVERY.message' | transloco }}"></p>
	</div>
	<div mat-dialog-content>
		<div class="op-center op-mtb-10"><img src="assets/images/icon_debit_cards.svg" /></div>
		<p>{{ 'DISBURSEMENT_SELECTION.CHECK.DELIVERY.description' | transloco }}</p>
	</div>
	<div mat-dialog-actions class="footer">
		<button mat-flat-button color="primary-small" id="denyAchConfirmBtn" (click)="onClick()">
			{{ 'DISBURSEMENT_SELECTION.CHECK.DELIVERY.confirm' | transloco }}
		</button>
	</div>
	<div mat-dialog-actions class="footer">
		<button mat-flat-button color="secondary-small" id="denyAchCancelBtn" (click)="onNoClick()">
			{{ 'DISBURSEMENT_SELECTION.CHECK.DELIVERY.goBack' | transloco }}
		</button>
	</div>
</div>
