<div class="spousal-contact" *transloco="let t">
	<op-masthead [title]="t('SPOUSAL_CONTACT.title')"></op-masthead>
	<op-content-container id="spousalContact" class="content">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<p>
				<span id="wiLaw">{{ 'SPOUSAL_CONTACT.wiLaw' | transloco }} </span>
			</p>

			<div class="op-form-group">
				<mat-label>{{ t('SPOUSAL_CONTACT.married') }}</mat-label>
				<mat-radio-group
					class="op-mt-10"
					name="maritalStatus"
					formControlName="maritalStatus"
					data-test-id="maritalStatus"
					id="maritalStatus"
				>
					<mat-radio-button id="marrried" [value]="true">{{ 'SPOUSAL_CONTACT.yes' | transloco }}</mat-radio-button>
					<mat-radio-button id="unmarried" [value]="false">{{ 'SPOUSAL_CONTACT.no' | transloco }}</mat-radio-button>
				</mat-radio-group>
			</div>

			<div [hidden]="!formGroup.controls['maritalStatus'].value">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('SPOUSAL_CONTACT.firstName') }}</mat-label>
					<input matInput formControlName="firstName" id="firstName" name="firstName" data-test-id="firstName" />
					<mat-error
						id="firstNameError"
						*ngIf="!formGroup.controls['firstName'].valid && formGroup.controls['firstName'].touched"
					>
						{{ t('SPOUSAL_CONTACT.firstNameError') }}
					</mat-error>
				</mat-form-field>

				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('SPOUSAL_CONTACT.lastName') }} </mat-label>
					<input
						matInput
						type="text"
						formControlName="lastName"
						id="lastName"
						name="lastName"
						data-test-id="lastName"
					/>
					<mat-error
						id="lastNameError"
						*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
					>
						{{ t('SPOUSAL_CONTACT.lastNameError') }}
					</mat-error>
				</mat-form-field>

				<mat-radio-group
					id="addressCategory"
					class="addresstype-radio-group"
					name="addressType"
					formControlName="addressType"
					data-test-id="addressType"
				>
					<mat-radio-button id="mailAddress" value="mailAddress">
						{{ 'SPOUSAL_CONTACT.sameMailing' | transloco }}
					</mat-radio-button>
					<div class="address-info" *ngIf="formGroup.get('addressType').value === 'mailAddress'">
						<div id="streetAddress1" class="block">{{ mailingAddress?.streetAddress1 }}</div>
						<div id="streetAddress2" class="block" *ngIf="mailingAddress?.streetAddress2">
							{{ mailingAddress?.streetAddress2 }}
						</div>
						<div id="cityState" class="block">
							{{ mailingAddress?.city }}, {{ mailingAddress?.state }} {{ mailingAddress?.postalCode }}
						</div>
					</div>
					<mat-radio-button id="differentAddress" value="differentAddress">
						{{ 'SPOUSAL_CONTACT.differentMailing' | transloco }}</mat-radio-button
					>
					<div class="address-detail" [hidden]="formGroup.get('addressType').value !== 'differentAddress'">
						<op-address-detail-form
							[homeAddress]="true"
							id="spousal"
							[setValidator]="
								!formGroup.controls['maritalStatus'].value || formGroup.get('addressType').value !== 'differentAddress'
							"
							formControlName="addressDetail"
						></op-address-detail-form>
					</div>
				</mat-radio-group>
			</div>
			<div class="left">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					data-test-id="submitBtn"
					id="submitBtn"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
