<div
	id="{{ id }}"
	class="document-section"
	*transloco="let t"
	[ngClass]="{
		attention: badge === docBadgeEnum.attention,
		needs: badge === docBadgeEnum.needs,
		idle: badge === docBadgeEnum.idle,
		verified: badge === docBadgeEnum.verified
	}"
	type="button"
>
	<div>
		<div class="section-tags op-mb-8">
			<op-document-badge
				*ngIf="badge === docBadgeEnum.idle && !bounceAttention"
				id="{{ id + '_no_attachments' }}"
				class="idle"
				[small]="true"
			>
			</op-document-badge>
			<op-document-badge
				*ngIf="badge === docBadgeEnum.verified && !bounceAttention"
				id="{{ id + '_verified' }}"
				class="ready"
				icon="op-checkMark-green"
				[small]="true"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.verified') }}
			</op-document-badge>
			<op-document-badge
				*ngIf="badge === docBadgeEnum.ready && !bounceAttention"
				id="{{ id + '_attachments' }}"
				class="ready small"
				icon="op-checkMark-green"
				[small]="true"
			>
				{{
					count
						| opSingularPlural
							: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachment')
							: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachments')
				}}
			</op-document-badge>
			<op-document-badge
				*ngIf="badge === docBadgeEnum.attention || bounceAttention"
				id="{{ id + '_needsAttention' }}"
				class="attention"
				icon="op-attention"
				[small]="true"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
			</op-document-badge>
			<op-document-badge
				*ngIf="badge === docBadgeEnum.needs && !bounceAttention"
				id="{{ id + '_needsUpload' }}"
				class="needs"
				icon="op-required"
				[small]="true"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.required') }}
			</op-document-badge>
			<op-document-badge
				*ngIf="badge === docBadgeEnum.processing"
				id="{{ id + '_processing' }}"
				icon="op-processing"
				class="processing"
				[small]="true"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processing') }}
			</op-document-badge>
		</div>

		<div class="section-wrapper">
			<div>
				<div class="section-header op-header-3-font">
					<div class="header-icon">
						<mat-icon *ngIf="icon" svgIcon="{{ icon }}"></mat-icon>
					</div>
					<div class="header">{{ title }}</div>
				</div>
				<div
					*ngIf="description && badge !== docBadgeEnum.verified"
					class="section-description op-body-1-font op-gray op-mt-8"
				>
					{{ description }}
				</div>
			</div>
			<div>
				<mat-icon *ngIf="badge !== docBadgeEnum.verified" svgIcon="op-caretRightSmall"></mat-icon>
			</div>
		</div>
	</div>
</div>
