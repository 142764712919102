<div class="op-header-commons-pro" *transloco="let t">
	<op-masthead [title]="t('PRE_QUALIFY.Identification.title')"> </op-masthead>

	<op-content-container class="content">
		<div class="op-header-2-font">{{ t('PRE_QUALIFY.Identification.header') }}</div>
		<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<p></p>
			<mat-form-field class="form-element mat-commons" appearance="outline" [hideRequiredMarker]="true">
				<mat-label>{{ t('PRE_QUALIFY.Identification.select') }} </mat-label>
				<mat-select
					formControlName="idType"
					id="idType"
					#idType
					aria-label="Id Type"
					(focus)="onFocus(formGroup.controls['idType'])"
					required
				>
					<mat-option
						*ngFor="let state of idTypeList$ | async; let i = index"
						id="{{ 'idType_' + state.key }}"
						[value]="state.key"
						>{{ state.text }}</mat-option
					>
				</mat-select>
				<mat-error id="idTypeError" *ngIf="!formGroup.controls['idType'].valid && formGroup.controls['idType'].touched">
					{{ t('PRE_QUALIFY.Identification.idTypeError') }}
				</mat-error>
			</mat-form-field>

			<div *ngIf="idType.value | showIdentificationField: 'showIssuingState'">
				<mat-form-field class="form-element mat-commons" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('PRE_QUALIFY.Identification.issuingState') }} </mat-label>
					<mat-select
						formControlName="issuingState"
						id="issuingState"
						#state
						aria-label="state"
						(focus)="onFocus(formGroup.controls['issuingState'])"
					>
						<div *ngIf="idType.value !== 2218; else restricted">
							<mat-option
								*ngFor="let state of stateList$ | async; let i = index"
								id="{{ 'issuingState_' + state.key }}"
								[value]="state.key"
							>
								{{ state.text }}
							</mat-option>
						</div>

						<ng-template #restricted>
							<mat-option
								*ngFor="let state of stateListRestricted$ | async; let i = index"
								id="{{ 'issuingState_' + state.key }}"
								[value]="state.key"
							>
								{{ state.text }}
							</mat-option>
						</ng-template>
					</mat-select>
				</mat-form-field>
			</div>

			<div *ngIf="idType.value !== 2218 && idType.value | showIdentificationField: 'showIssuingCountry'">
				<div class="op-header-2-font">{{ t('PRE_QUALIFY.Identification.issuingCountry') }}</div>
				<p></p>
				<mat-form-field class="form-element mat-commons" appearance="outline" [hideRequiredMarker]="true">
					<mat-label>{{ t('PRE_QUALIFY.Identification.select') }} </mat-label>
					<mat-select
						formControlName="issuingCountry"
						id="issuingCountry"
						#issuingCountry
						aria-label="issuingCountry"
						(focus)="onFocus(formGroup.controls['issuingCountry'])"
					>
						<mat-option
							*ngFor="let country of countryList$ | async; let i = index"
							id="{{ 'issuingCountry_' + country.key }}"
							[value]="country.key"
							>{{ country.text }}</mat-option
						>
					</mat-select>
				</mat-form-field>
				<div class="op-label-font op-gray op-mb-20" id="issuingCountryTip" *ngIf="idType.value === 2202">
					{{ t('PRE_QUALIFY.Identification.issuingCountryTip') }}
				</div>
			</div>
			<div *ngIf="(idType.value | showIdentificationField: 'showTaxFilingQuestion':currentState) || isNewJerseyState">
				<div class="op-header-2-font">{{ t('PRE_QUALIFY.Identification.taxes') }}</div>
				<p></p>
				<div class="op-label-font op-gray op-mtb-20" id="taxFilingTip">
					{{ t('PRE_QUALIFY.Identification.taxNotRequired') }}
				</div>
				<mat-radio-group name="taxFilingQuestion" id="taxFilingQuestion" formControlName="taxFilingQuestion">
					<mat-radio-button
						labelPosition="before"
						class="primary"
						id="{{ 'taxFiling_' + toggleTaxFilingQuestionOptions[0].key }}"
						[value]="toggleTaxFilingQuestionOptions[0].key"
					>
						{{ t(toggleTaxFilingQuestionOptions[0].text) }}
					</mat-radio-button>
					<hr class="seperator" />
					<mat-radio-button
						labelPosition="before"
						class="mat-commons"
						id="{{ 'taxFiling_' + toggleTaxFilingQuestionOptions[1].key }}"
						[value]="toggleTaxFilingQuestionOptions[1].key"
					>
						{{ t(toggleTaxFilingQuestionOptions[1].text) }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<p></p>

			<div
				*ngIf="
					(idType.value | showIdentificationField: 'showSsnOrITIN') &&
					currentIdentification &&
					!currentIdentification.last4Ssn &&
					!currentIdentification.last4Itin
				"
			>
				<div class="op-header-2-font" *ngIf="idType.value === 2203">
					{{ t('PRE_QUALIFY.Identification.showSsn') }}
				</div>
				<div class="op-header-2-font" *ngIf="idType.value !== 2203">
					{{ t('PRE_QUALIFY.Identification.showSsnOrITIN') }}
				</div>
				<p></p>
				<div class="op-body-1">{{ t('PRE_QUALIFY.Identification.ssnDesc') }}</div>
				<div class="op-body-1" *ngIf="idType.value !== 2203">{{ t('PRE_QUALIFY.Identification.ITINDesc') }}</div>
				<p></p>
				<mat-form-field class="form-element identity-mb-20" appearance="outline" [hideRequiredMarker]="true">
					<input
						matInput
						name="ssnOrItin"
						id="ssnItinNumber"
						type="text"
						mask="BBB-BB-BBBB"
						[patterns]="customSsnItinPatterns"
						placeholder="###-##-####"
						[hiddenInput]="!showText"
						formControlName="ssnItinNumber"
						required
					/>
					<mat-icon
						matSuffix
						class="visible-eye"
						svgIcon="{{ showText ? 'op-viewOn' : 'op-viewOff' }}"
						(click)="toggleShowText()"
					>
					</mat-icon>
				</mat-form-field>
				<section class="ssl-section" *ngIf="ssnOrItin === 'ssn' || ssnOrItin === 'itin'">
					<mat-icon class="check-mark-icon" svgIcon="op-checkMark-outline"></mat-icon>
					<div class="ssl-info op-ml-10" *ngIf="ssnOrItin === 'ssn'">
						{{ t('PRE_QUALIFY.Identification.ssnEntered') }}
					</div>
					<div class="ssl-info op-ml-10" *ngIf="ssnOrItin === 'itin'">
						{{ t('PRE_QUALIFY.Identification.itinEntered') }}
					</div>
				</section>
			</div>
			<p></p>

			<section class="ssl-section op-mt-30">
				<mat-icon class="ssl-icon" svgIcon="op-protection"></mat-icon>
				<div class="ssl-info op-ml-10">{{ t('PRE_QUALIFY.Identification.ssl') }}</div>
			</section>
			<p></p>
			<div>
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					[opTagClick]="'Identification next'"
				>
					{{ t('PRE_QUALIFY.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
