import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { LoanExamplesService } from 'src/app/core/services/loan-examples/loan-examples.service';
import { IConfigResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { ElectronicTitleEnabledStates } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { isEmpty } from 'lodash';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Component({
	selector: 'op-vehicle-eligibility',
	templateUrl: './vehicle-eligibility.component.html',
	styleUrls: ['./vehicle-eligibility.component.scss']
})
export class VehicleEligibilityComponent implements OnInit, OnDestroy {
	constructor(
		private softPullService: SoftPullService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private loanExampleService: LoanExamplesService,
		private tagDataService: TagDataService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService,
		private dialogService: DialogService,
		private issuingPartnerService: IssuingPartnerService
	) {}

	isSoftPullEnabled: boolean;
	isPreScreened: boolean;

	hasMultipleValidProductOffers: boolean;
	isEligibleForSPL: boolean;
	hasUnsecuredOption: boolean;

	distance: number;
	isOverDistanceCap: boolean;
	maxApprovedAmount: number;

	customerState: string;
	showLoanExamplesLink: boolean;
	title: string;
	isElectronicTitleEnabledState: boolean;

	issuingOrganization: string;
	isMetaOrganization: boolean;

	private subscription = new Subscription();

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$.pipe(filter(Boolean)).subscribe({
			next: (softPull: IConfigResult) => {
				this.isSoftPullEnabled = softPull.value;
				this.isPreScreened = softPull.preScreened;
			}
		});
		this.subscription.add(softPullSub);

		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApp: ILoanApplication) => {
				this.issuingOrganization = this.issuingPartnerService.lender;
				this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingOrganization);

				const productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp);
				const splObject = productOfferDetailUtilities.getSecuredPersonalLoanProduct();

				this.isEligibleForSPL = splObject ? productOfferDetailUtilities.hasValidProductStatus(splObject) : false;
				this.hasMultipleValidProductOffers = productOfferDetailUtilities.hasMultipleValidProductOffers();

				this.hasUnsecuredOption = productOfferDetailUtilities.getUnsecuredPersonalLoanProduct() != null;

				if (this.isEligibleForSPL) {
					this.distance = Math.round(
						Number(isEmpty(loanApp?.fundingInfo?.distance) ? 0 : loanApp?.fundingInfo?.distance)
					);
					this.isOverDistanceCap = loanApp?.fundingInfo?.isOver50miles;
					this.maxApprovedAmount = loanApp?.preApprovedTerms[0].maxApprovedAmount;
					this.title = 'VEHICLE_ELIGIBILITY.ELIGIBLE.congrats';
				} else {
					this.title = 'VEHICLE_ELIGIBILITY.OFFER_STATUS.heading';
				}

				this.tagDataService.view(
					{},
					{
						tealium_event: this.isEligibleForSPL ? 'secure_loan_vehicle_eligible' : 'secure_loan_vehicle_not_eligible',
						product_sub_category: this.sessionStorageService.get('productCategorySelection'),
						product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(loanApp.productOfferDetails)
					}
				);

				this.mobileService.getAddresses(this.loanAppService.loanApplicationId).subscribe({
					next: (addresses) => {
						this.customerState = AddressUtils.getHomeAddress(addresses)?.state;
						this.showLoanExamplesLink = this.loanExampleService.getShowLoanExamplesLink(this.customerState);
						this.isElectronicTitleEnabledState = ElectronicTitleEnabledStates.includes(
							this.customerState?.toUpperCase()
						);
					}
				});
			}
		});
		this.subscription.add(loanAppSub);
	}

	openLoanExamplesModal(): void {
		this.loanExampleService.openLoanExamplesModal(this.customerState).subscribe();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	returnToOffers(): void {
		this.routingService.route(RoutingPathsEnum.offerStatus);
	}

	continue(): void {
		if (this.loanAppService.isBtmEligible()) {
			this.routingService.routeBtm();
		} else if (this.loanAppService.isIncomeVerificationEligible()) {
			this.routingService.route(RoutingPathsEnum.autoVerifyIncome);
		} else {
			this.routingService.route(RoutingPathsEnum.receiveFunds); // TODO should goto refrences (check for skipRefrencesFlag)
		}
	}

	triggerCallDialog(): void {
		this.dialogService.openContactUsDialog(true).subscribe();
	}
}
