import { chain, every, isEmpty } from 'lodash';
import { StatesEnum } from 'src/app/core/utils/state-utils';

import { MetaIdentificationType } from '../../metadata/metadata.model';
import { IAddressResult, IFinancesResult } from '../../mobile-api';
import { ILoanApplication } from '../loan-application.model';
import { preferNotAnswerVehicleStatus } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import {
	IAddress,
	IApplicant,
	IIdentificationDocument,
	IPhone,
	IRequirements,
	PlaidAssetRefreshEnum
} from './applicant.model';

export class ApplicantUtils implements IApplicant {
	static fromLoanApp(loanApp: ILoanApplication, applicantIndex: number = 0): ApplicantUtils {
		const applicant = (!isEmpty(loanApp?.applicants) && loanApp?.applicants[applicantIndex]) || <IApplicant>{};
		return new this(applicant);
	}

	constructor(readonly applicant: IApplicant) {
		Object.assign(this, applicant);
	}

	/**
	 * returns credit run information
	 *
	 * @returns {boolean}
	 * @memberof Applicant
	 */
	isCreditRunSuccess(): boolean {
		return Boolean(this.applicant.creditRunSuccess);
	}

	/**
	 * Check if all requirements are false
	 *
	 * @return {*}  {boolean}
	 * @memberof Applicant
	 */
	isAutoVerified(): boolean {
		const required = [
			'identificationRequired',
			'incomeRequired',
			'residenceRequired',
			'bankAccountRequired',
			'vehiclePhotosRequired',
			'vehicleRegistrationRequired',
			'vehicleDriversLicenseRequired'
		];
		const req = this.applicant?.requirements || [];
		return every(required, (item) => req?.[item] === false);
	}

	isCoApplicant(): boolean {
		return this.applicant.applicantIndex > 0;
	}

	isPlaidOneClickEligible(): boolean {
		return Boolean(this.applicant.plaidOneClickEligible);
	}

	getEditableSections(): any {
		return this.applicant.editableSections;
	}

	getFirstName(): string {
		return this.applicant.firstName;
	}

	getCurrentAddressState(): StatesEnum {
		return StatesEnum[this.applicant.currentAddress?.state];
	}

	/**
	 * Returns the Bounce Reasons as an objects.
	 * Each 'EditableSections' is a key, the value is an array of reasons.
	 *
	 * @returns {Object}
	 * @memberof Applicant
	 */
	getBounceReasons(): any {
		return chain(this.applicant.bounceReasons)
			.groupBy('section')
			.mapValues((reasons) => chain(reasons).map('reason').flatten().value())
			.value();
	}

	/**
	 * Get the Identification Document information required from applicant.
	 *
	 * @return {*}  {IIdentificationDocument}
	 * @memberof Applicant
	 */
	getIdentificationDocument(): IIdentificationDocument {
		return this.applicant.identificationDocument;
	}

	/**
	 * Check if the required id type is other
	 *
	 * @return {*}  {boolean}
	 * @memberof Applicant
	 */
	isIdTypeOther(): boolean {
		return this.getIdentificationDocument().type === String(MetaIdentificationType.Other);
	}

	/**
	 * Check to see if a document is required.
	 *
	 * @param {string} requirement
	 * @return {*}  {boolean}
	 * @memberof Applicant
	 */
	hasRequirement(requirement: string): boolean {
		return this.applicant?.requirements?.[requirement] || false;
	}

	/**
	 * Check to see if applicant has a previous loan
	 *
	 * @return {*}  {boolean}
	 * @memberof ApplicantUtils
	 */
	isReturning(): boolean {
		return this.applicant?.previousLoanCount >= 1;
	}

	/**
	 * Check to see if a document is required and not uploaded
	 *
	 * @param {string} section
	 * @return {*}  {boolean}
	 * @memberof Applicant
	 */
	isDocumentRequired(section: string): boolean {
		const sectionRequired = this.applicant?.requirements?.[section + 'Required'] || false;
		const sectionUploaded = this.applicant?.requirements?.[section + 'Uploaded'] || false;
		return sectionRequired && !sectionUploaded;
	}

	isDocumentRequiredV2(section: string): boolean {
		return this.applicant?.requirements?.[section + 'Required'] || false;
	}

	hasSecuredPauseEligible(): boolean {
		return this.applicant?.isSecuredPauseEligible;
	}

	getIncomeSourceSelected(): string {
		return this.applicant?.incomeSourceSelected;
	}

	getIncomeSourceOptionsOffered(): boolean {
		return this.applicant?.incomeSourceOptionsOffered;
	}

	getBankAccountConnectionSource(): string {
		return this.applicant?.bankAccountConnectionSource;
	}

	isPlaidAssetRefresh(): boolean {
		return (
			this.applicant?.plaidAssetRefreshStatus === PlaidAssetRefreshEnum.success ||
			this.applicant?.plaidAssetRefreshStatus === PlaidAssetRefreshEnum.partialSuccess
		);
	}

	isBankAccountConnectionSourcePlaid(): boolean {
		return this.getBankAccountConnectionSource() === 'PLAID' || this.isPlaidAssetRefresh();
	}

	/**
	 * Check regular loan app if incomeVerificationEligible else check self-service
	 *
	 * @returns
	 */
	isIncomeVerificationEligible(): boolean {
		return this.applicant?.incomeVerificationEligible;
	}

	/**
	 * check regular loan app if bankAccountVerificationEligible else check self-service
	 *
	 * @returns
	 */
	isBankAccountVerificationEligible(): boolean {
		return this.applicant?.bankAccountVerificationEligible;
	}

	getCurrentApplicantIndex(): number {
		return this.applicant?.applicantIndex || 0;
	}

	getVehicleOwnershipStatus(): string {
		return this.applicant?.vehicleOwnershipStatus;
	}

	/**
	 * User is Bank Transaction Model eligible or should be displayed Plaid Bank Connect.
	 *
	 * @return {*}  {boolean}
	 * @memberof ApplicantUtils
	 */
	isBtmEligible(): boolean {
		return this.applicant?.bankTransactionModelEligible;
	}

	isSkipVehicleForNow(): boolean {
		return this.applicant?.vehicleOwnershipStatus === preferNotAnswerVehicleStatus;
	}

	// IApplicant interface
	id: number;
	loanApplicationId: number;
	applicantIndex: number;
	clientId: string;
	firstName: string;
	middleName: string;
	lastName: string;
	maternalName: string;
	suffix: string;
	title: string;
	leadId: number;
	dateOfBirth: string;
	itin: string;
	ssn: string;
	last4Ssn: string;
	last4Itin: string;
	inUSSince: string;
	emailAddress: string;
	editableSections: any;
	preferredLanguage: string;
	smsAuthorization: boolean;
	applicantDocumentsId: string;
	previousLoanCount: number;
	referencesRequired: number;
	skipReferences: string;
	creditRunSuccess: boolean;
	bureauInquiryType: string;
	quovoAccountsAdded: boolean;
	quovoIncomeEligible: boolean;
	quovoBankAccountEligible: boolean;
	preScreened: boolean;
	statedMonthlyIncomeAmount: string;
	verifiedMonthlyIncomeAmount: string;
	incomeSourceOptionsOffered: boolean;
	incomeSourceSelected: string;
	verifiedMonthlyIncomeDate: string;
	initialTakeHomePay: string;
	currentAddress: IAddress;
	previousAddress: IAddress;
	phones: IPhone[];
	debts: any[];
	incomes: any[];
	references: any[];
	requirements: IRequirements;
	applicantStatus: string;
	nameProcessComplete: boolean;
	identificationProcessComplete: boolean;
	phoneProcessComplete: boolean;
	addressProcessComplete: boolean;
	creditAuthProcessComplete: boolean;
	financeProcessComplete: boolean;
	incomeProcessComplete: boolean;
	dataCompleteProcessComplete: boolean;
	referenceProcessComplete: boolean;
	disbursementInfoProcessComplete: boolean;
	bounceReasons: any[];
	declineCodes: any[];
	nonProcessableCodes: any[];
	referralCode: string;
	referralCodeConsent: string;
	referralCodeSource: string;
	bankAccountVerificationService: string;
	bankAccountVerificationEligible: boolean;
	incomeVerificationEligible: boolean;
	bankAccountVerificationAdded: boolean;
	homePostalCode: string;
	identificationDocument: IIdentificationDocument;
	plaidIncentiveApplied?: boolean;
	isSecuredPauseEligible: boolean;
	verifiedMonthlyIncomeSource: string;
	isMFARequired?: boolean;
	homeAddress?: IAddressResult;
	mailingAddress?: IAddressResult;
	finances?: IFinancesResult;
	vehicleOwnershipStatus?: string;
	bankTransactionModelEligible?: boolean;
	bankAccountConnectionSource?: string;
	plaidOneClickConsent?: boolean;
	plaidOneClickEligible?: boolean;
}
