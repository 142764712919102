export class DigitUtils {
	static utm_campaign(isMetaBank: boolean, suffix: string): string {
		return isMetaBank ? `PL_pathward_${suffix}` : `PL_core_${suffix}`;
	}

	static getDigitUrl(url: string, tealium_vid: string, isMetaBank: boolean): string {
		const utm_campaign = this.utm_campaign(isMetaBank, 'denied');
		return `${url}&tvid=${tealium_vid}&utm_source=Originations&utm_campaign=${utm_campaign}&utm_medium=web&anonymous_id=${tealium_vid}`;
	}

	static getDigitUrlApproved(url: string, tealium_vid: string, isMetaBank: boolean): string {
		const utm_campaign = this.utm_campaign(isMetaBank, 'approved');
		const completeUrl = `${url}?utm_source=Originations&utm_campaign=${utm_campaign}&utm_medium=web&anonymous_id=${tealium_vid}`;
		return tealium_vid ? `${completeUrl}&tvid=${tealium_vid}` : completeUrl;
	}
}
