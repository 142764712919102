import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * basic message dialog takes in icon, title and message.
 *
 * @export
 * @class DebitCardFaqDialogComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-debit-card-faq-dialog',
	templateUrl: './debit-card-faq-dialog.component.html',
	styleUrls: ['./debit-card-faq-dialog.component.scss']
})
export class DebitCardFaqDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<DebitCardFaqDialogComponent>) {}

	ngOnInit(): void {}

	close(): void {
		this.dialogRef.close(false);
	}

	onSubmit(): void {
		this.dialogRef.close(true);
	}
}
