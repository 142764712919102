<div class="contact-us-dialog" id="contactUsDialog" aria-describedby="full-hours" *transloco="let t">
	<div mat-dialog-title>
		<div id="contactUsCloseBtn" class="op-right op-mb-8">
			<button mat-icon-button id="contactUsCloseBtn" [mat-dialog-close]="false" aria-label="{{ t('GLOBAL.close') }}">
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-1-font" id="contactUsHeading">
			<strong>{{ 'CALL_US.contactUs' | transloco }}</strong>
		</div>
	</div>
	<div id="contactUsPhoneNumber" mat-dialog-content>
		<p>
			<a
				mat-flat-button
				color="op-link"
				id="contactUsPhoneBtn"
				class="phone-link op-body-1-font"
				[href]="'tel:' + '{{ data?.phoneNumber | transloco }}'"
				aria-label="Call us"
			>
				<strong>{{ data?.phoneNumber | transloco | opPhoneNumber }}</strong>
			</a>
		</p>
		<p id="full-hours" innerHTML="{{ data?.fullHours | transloco }}"></p>
	</div>

	<div mat-dialog-actions>
		<button
			class="confirm"
			mat-flat-button
			color="primary-small"
			id="contactUsSubmitBtn"
			(click)="onSubmit()"
			[opTagClick]="'contact us'"
			aria-label="Call us"
		>
			{{ 'GLOBAL.ok' | transloco }}
		</button>
	</div>
</div>
