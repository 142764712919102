<div *ngIf="showNewDesign === true">
	<op-funding-method></op-funding-method>
</div>

<div *ngIf="showNewDesign === false">
	<div class="receive-funds" *transloco="let t">
		<op-masthead [title]="t('RECEIVE_FUNDS.title')"></op-masthead>
		<op-content-container class="content">
			<op-plaid-bank-account
				id="previousLoan"
				[expanded]="isGCPOrReturn"
				*ngIf="showPreviousLoanBank"
				(bankVerificationComplete)="onBankVerificationComplete($event, true)"
				[isGCPOrReturn]="isGCPOrReturn"
			></op-plaid-bank-account>

			<div *ngIf="!isPlaidBankAccountExists">
				<op-expansion-panel
					[expanded]="!expandFlag && !showPreviousLoanBank"
					id="receiveFundPanel"
					[titleBold]="true"
					[title]="t('PLAID_BANK_ACCOUNT.useConnectedAccount')"
				>
					<div class="content">
						<op-plaid-extended id="receiveFundPlaid" [showIcon]="showIcon" [sectionType]="fileType" [bankOnly]="true">
							<div *ngIf="bankConnectEvent === 'WAITING'">
								{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.loadingAccounts') }}
							</div>

							<op-alert level="WARNING" *ngIf="connectAccountError && bankConnectEvent !== 'WAITING'">
								{{ connectAccountError }}
							</op-alert>
							<op-alert level="ERROR" *ngIf="showNoAccountsError && bankConnectEvent !== 'WAITING'">
								{{ 'DOCUMENT_SUBMIT.proofOfBankAccount.noAccountsError' | transloco }}
							</op-alert>

							<p></p>
						</op-plaid-extended>
					</div>
				</op-expansion-panel>
			</div>

			<op-plaid-bank-account
				id="currentLoan"
				[expanded]="expandFlag"
				*ngIf="isPlaidBankAccountExists"
				(bankVerificationComplete)="onBankVerificationComplete($event)"
			></op-plaid-bank-account>

			<div class="op-mt-10 op-mb-40">
				<op-microbilt-bank-account (notSaved)="notSaved($event)" (saved)="saved($event)"> </op-microbilt-bank-account>
			</div>

			<op-info-tip id="cutOffTip" [key]="t('ACH_DISBURSEMENT_SELECTION.fundingCutOffInfoTip')">
				{{ 'ACH_DISBURSEMENT_SELECTION.fundingCutOffDisclaimer' | transloco }}
			</op-info-tip>
			<p></p>
			<div class="op-header-2-font">
				{{ t('RECEIVE_FUNDS.otherWays') }}
			</div>
			<div id="fundingInfo" class="op-body-1-font op-mb-5">
				{{ t(alternateFundingKey, { partner: partnerBrand }) }}
			</div>
			<p></p>

			<button mat-flat-button color="secondary" id="skipBank" (click)="rejectsAchAction()">
				{{ t('RECEIVE_FUNDS.skipBank') }}
			</button>
			<op-website-terms class="op-display-block op-mt-20"></op-website-terms>
		</op-content-container>
	</div>
</div>
