<div class="identification-other" *transloco="let t">
	<div class="other-wrapper" [@changeDivSize]="currentState">
		<div class="other-body op-micro-font op-full-width" [ngClass]="{ gradient: currentState === 'initial' }">
			<div class="other-body-item">
				<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypeTable.type') | uppercase }}</div>
				<div class="front">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypeTable.front') | uppercase }}
				</div>
				<div class="back">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypeTable.back') | uppercase }}
				</div>
			</div>
			<div *ngFor="let type of otherType" class="other-body-item">
				<div>{{ t(type.name) }}</div>
				<div class="front"><mat-icon *ngIf="type.front" svgIcon="op-checkMark"></mat-icon></div>
				<div class="back"><mat-icon *ngIf="type.back" svgIcon="op-checkMark"></mat-icon></div>
			</div>
		</div>
		<br />
		<button
			class="toggle-button"
			mat-flat-button
			color="link-text-micro"
			(click)="toggle()"
			[opTagClick]="'toggle other id list'"
		>
			{{
				currentState === 'initial'
					? t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypeTable.viewMore')
					: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.otherTypeTable.viewLess')
			}}
		</button>
	</div>
</div>
