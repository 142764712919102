import { Component } from '@angular/core';

import { mergeMap } from 'rxjs';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { BankConnectEventTypeEnum, PlaidLinkService } from 'src/app/core/services/plaid-link/plaid-link.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { AddBankAccountComponent } from './add-bank-account.component';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { MobileApiService } from 'src/app/core/services/mobile-api';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';

@Component({
	selector: 'op-add-bank-account-terms-confirmed',
	templateUrl: './add-bank-account.component.html',
	styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountTermsConfirmedComponent extends AddBankAccountComponent {
	bankConnectEvent: BankConnectEventTypeEnum = BankConnectEventTypeEnum.idle;

	nextRoute = RoutingPathsEnum.esignSignDocument;

	manualBankAccountRoute = RoutingPathsEnum.termsConfirmedAddManually;

	showAddButton: boolean = false;

	showBenefits: boolean = false;

	loaded = false;

	constructor(
		protected routingService: RoutingService,
		protected dialogService: DialogService,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected loanAppService: LoanApplicationService,
		protected plaidLinkService: PlaidLinkService,
		protected sessionStorageService: SessionStorageService,
		private mobileApiService: MobileApiService
	) {
		super(
			routingService,
			dialogService,
			bankAccountService,
			tagDataService,
			loanAppService,
			plaidLinkService,
			sessionStorageService
		);
	}

	ngOnInit() {
		super.ngOnInit();
		const loanAppSub = this.loanAppService.loanApplication$.subscribe({
			next: (loanApp) => {
				const disbursement = DisbursementUtils.fromLoanApp(loanApp);
				this.nextRoute = disbursement.isNotificationAutoPayCompleted()
					? RoutingPathsEnum.esignSignDocument
					: RoutingPathsEnum.esignAutoPay;
			}
		});
	}

	onBankSelected($event: IAchBankAccount): void {
		this.mobileApiService
			.updateDisbursementChannel(
				{
					disbursementChannel: DisbursementChannelEnum.online,
					disbursementType: DisbursementType.ach
				},
				this.loanAppService.loanApplicationId
			)
			.pipe(
				mergeMap((result) => {
					return this.dialogService.openSimpleDialog(FundingMethodConfirmComponent, {
						data: {
							fundingOptions: DisbursementType.ach,
							account: {
								bankName: $event.bankName,
								accountType: $event.accountType,
								accountNumber: $event.accountNumber
							}
						}
					});
				})
			)
			.subscribe({
				next: (result) => {
					this.routingService.route(this.nextRoute);
				}
			});
	}
}
