import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { CreateAccountComponent } from './create-account/create-account.component';
import { LoanCompletedComponent } from './loan-completed/loan-completed.component';
import { ReferralComponent } from './referral/referral.component';

const routes: Routes = [
	{
		path: RoutingPathsEnum.createAccount,
		component: CreateAccountComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.referral,
		component: ReferralComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.loanCompleted,
		component: LoanCompletedComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DisbursedRoutingModule {}
