<div class="find-location-dialog" id="findLocationDialog" aria-labelledby="fasLabel" *transloco="let t">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button mat-icon-button id="findLocationCloseBtn" aria-label="{{ t('GLOBAL.close') }}" [mat-dialog-close]="false">
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>

		<div class="op-header-1-font" id="findLocationHeading">
			<strong id="fasLabel">{{ 'FIND_STORE.initial' | transloco }}</strong>
		</div>
	</div>
	<div mat-dialog-content>
		<div *ngIf="!selectedStore" aria-labelledby="fasStoresMessage">
			<form class="personal-content" [formGroup]="formGroup" (keyup.enter)="onSubmit(formGroup.value)">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label id="fasStoresMessage">{{ 'FIND_STORE.label' | transloco }}</mat-label>
					<input matInput type="text" id="findLocationZipCode" name="zipCode" formControlName="zipCode" />
					<mat-hint>{{ 'FIND_STORE.locationDisclaimer' | transloco }}</mat-hint>
				</mat-form-field>
				<p></p>
				<div class="op-label-font" *ngIf="storeless" id="storeless-status" role="status" aria-live="assertive">
					{{ 'FIND_STORE.storeless' | transloco }}
				</div>
				<div class="op-label-font" *ngIf="noneFound" id="none-found-status" role="status" aria-live="assertive">
					{{ 'FIND_STORE.noneFound' | transloco }}
				</div>
			</form>
			<div *ngFor="let store of stores; let i = index">
				<op-find-store-item
					[store]="store"
					id="{{ 'findStore_' + i }}"
					(click)="onStoreSelected(store)"
				></op-find-store-item>
			</div>
		</div>

		<op-find-store-details
			*ngIf="selectedStore"
			[googleMapsAPIKey]="googleMapsAPIKey"
			[store]="selectedStore"
			id="findStoreDetails"
			(back)="onBack()"
			aria-labelledby="fasHoursMessage"
		></op-find-store-details>
	</div>
	<div mat-dialog-actions>
		<button
			class="confirm"
			*ngIf="!selectedStore"
			mat-flat-button
			color="primary-small"
			[disabled]="formGroup.invalid"
			type="submit"
			id="findStoreSearchBtn"
			(click)="onSubmit(formGroup.value)"
		>
			{{ 'FIND_STORE.search' | transloco }}
		</button>
	</div>
</div>
