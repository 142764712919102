<div class="approved-loan-additional-info-panels" *transloco="let t">
	<op-expansion-panel
		id="calculatedAmountPanel"
		[titleBold]="false"
		[title]="t('LOAN_BENEFITS.howToCalculateQ', { loanAmount: loanAmount })"
		*ngIf="loanAmount"
	>
		<p>{{ t('LOAN_BENEFITS.howToCalculateA') }}</p>
	</op-expansion-panel>

	<op-expansion-panel
		id="calculatedAmountPanel"
		[titleBold]="false"
		[title]="t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.howWeCalculatedLoanAmountTitle')"
		*ngIf="!loanAmount"
	>
		<p>{{ t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.howWeCalculatedLoanAmountDescription') }}</p>
	</op-expansion-panel>

	<op-expansion-panel
		id="establishCreditPanel"
		[titleBold]="false"
		[title]="t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.establishCreditHistoryTitle')"
	>
		<p>{{ t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.establishCreditHistoryDescription') }}</p>
		<br />
		<p>{{ t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.establishCreditHistoryDescription_2') }}</p>
	</op-expansion-panel>

	<op-expansion-panel
		id="increaseLoanPanel"
		[titleBold]="false"
		[title]="t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.opportunityToIncreaseLoanTitle')"
	>
		<p>{{ t('OFFER_STATUS.APPROVED_LOAN_ADDITIONAL_INFO.opportunityToIncreaseLoanDescription') }}</p>
	</op-expansion-panel>
</div>
