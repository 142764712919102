<div class="op-header-commons-pro" *transloco="let t">
	<op-masthead *ngIf="!returnToFTR" [title]="t('PRE_QUALIFY.AdditionalInformation.title')"></op-masthead>
	<op-content-container class="content">
		<form class="" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="form-element" appearance="outline" [hideRequiredMarker]="true">
				<mat-label>{{ t('PRE_QUALIFY.AdditionalInformation.purpose') }}</mat-label>
				<mat-select
					class="input-element"
					name="loanPurpose"
					id="loanPurpose"
					formControlName="loanPurpose"
					#purpose
					aria-label="purpose"
					required
					(focus)="onFocus()"
				>
					<mat-option
						*ngFor="let purpose of purposeList$ | async; let i = index"
						id="{{ 'purpose_' + purpose.key }}"
						[value]="purpose.key"
						>{{ purpose.text }}</mat-option
					>
				</mat-select>
			</mat-form-field>

			<div>
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid || formGroup.pending"
					type="submit"
					[opTagClick]="'Loan-purpose next'"
				>
					{{ t('PRE_QUALIFY.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
