import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentStatusCriteriaEnum } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

import { IBankCriteriaMet } from '../proof-of-income.component';

@Component({
	selector: 'op-bank-criteria-met',
	templateUrl: './bank-criteria-met.component.html',
	styleUrls: ['./bank-criteria-met.component.scss']
})
export class BankCriteriaMetComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	public criteriaEnum: typeof DocumentStatusCriteriaEnum = DocumentStatusCriteriaEnum;

	@Input()
	criteria: IBankCriteriaMet;

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}
