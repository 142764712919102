<div class="finances" *transloco="let t">
	<op-masthead *ngIf="!returnToFTR" [ngClass]="{ 'op-left': !isPrequal }" [title]="getTitleText()">
		<div *ngIf="!isPrequal" id="preQualifiedDiv">
			<div class="op-mb-12"></div>
			<div class="op-header-1-font op-mb-20" [innerHTML]="t('FINANCES.preQualified')"></div>
		</div>
		<div class="op-left op-micro-font op-mb-20">
			<span>{{ t('FINANCES.hereAreSome') }}</span>
			<a href="" onclick="return false" (click)="openLoanExamplesModal()" id="loanExamplesModalLink">
				{{ t('FINANCES.loanExamples') }}
			</a>
			.
			<span>
				{{ t('FINANCES.individualLoanTerms') }}
			</span>
			.
		</div>
		<hr class="op-light-gray" />
	</op-masthead>

	<op-content-container class="content">
		<div class="op-label-font op-gray op-mb-16">{{ t('FINANCES.requiredFields') }}</div>

		<div class="op-header-3-font op-mb-16">{{ t('FINANCES.personalFinance') }}</div>

		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<op-info-tip id="housingTip">
				{{ t('FINANCES.housing') | opRequired }}
			</op-info-tip>
			<p></p>
			<mat-form-field id="amount" class="form-element amount" appearance="outline">
				<mat-label>{{ t('FINANCES.amount') }} </mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					placeholder="$"
					mask="separator"
					thousandSeparator=","
					prefix="$"
					name="monthlyPayment"
					id="monthlyPayment"
					formControlName="monthlyPayment"
					aria-describedby="housingTip"
					required
				/>
				<mat-hint [innerHTML]="t('FINANCES.housingInfo')"></mat-hint>
			</mat-form-field>

			<div class="op-header-3-font op-mtb-20">{{ t('FINANCES.additionalQuestions') }}</div>

			<div class="op-form-group">
				<op-info-tip class="op-mb-16" id="bankAccountTip" [key]="t('FINANCES.bankAccountTip')">
					<span>{{ t('FINANCES.bankAccount') | opRequired }} </span>
				</op-info-tip>
				<mat-radio-group name="hasBankAccount" id="hasBankAccount" formControlName="hasBankAccount">
					<mat-radio-button *ngFor="let item of bankAccountOptions" id="{{ 'bank_' + item.key }}" [value]="item.key">
						{{ t(item.text) }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div *ngIf="doesLiveInCA && !isMetaOrganization">
				<div class="op-form-group">
					<op-info-tip id="checkCashingTip" [key]="t('FINANCES.checkCashingFeeTip')">
						<span>{{ t('FINANCES.checkCashingFee') | opRequired }} </span>
					</op-info-tip>
					<p></p>
					<mat-radio-group name="checkCashingFee" id="checkCashingFee" formControlName="checkCashingFee">
						<mat-radio-button
							*ngFor="let item of checkCashingFeeList$ | async"
							id="{{ 'checkCashing_' + item.key }}"
							[value]="item.key"
						>
							{{ item.text }}
						</mat-radio-button>
					</mat-radio-group>
				</div>

				<div class="op-form-group">
					<op-info-tip id="paydayServicesTitle">
						<span>{{ t('FINANCES.payday') | opRequired }}</span>
					</op-info-tip>
					<p></p>
					<mat-radio-group name="usedPaydayService" id="usedPaydayService" formControlName="usedPaydayService">
						<mat-radio-button
							*ngFor="let item of paydayLendingServiceOptions"
							id="{{ 'payDay_' + item.key }}"
							[value]="item.key"
						>
							{{ t(item.text) }}
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</div>

			<div class="op-center">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					[opTagClick]="'Finances next'"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
		<div class="op-pb-40"></div>
	</op-content-container>
</div>
