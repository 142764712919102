import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { has, includes, size } from 'lodash';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountStatusEnum } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { VerificationStatusEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { BounceReasonsEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import IProduct, {
	ProductCategoriesEnum,
	ProductStatusesEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { ApplicationFlowEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { AutoVerifyScenarioEnum, IAutoVerifyScenario } from 'src/app/core/services/plaid-link/plaid-link.model';
import { PlaidLinkService } from 'src/app/core/services/plaid-link/plaid-link.service';

import {
	DmvIdSectionKey,
	DocumentTypeEnum,
	RequiredDocumentTypeEnum,
	SplBounceSectionList,
	UplBounceSectionList,
	ValidSPLStatusListForSubmit
} from './document-submit.model';

@Injectable({
	providedIn: 'root'
})
export class DocumentSubmitService implements OnDestroy {
	private readonly DocumentSubmitSource = new ReplaySubject<boolean>(1);
	readonly DocumentSubmitInitialized$ = this.DocumentSubmitSource.asObservable();

	private applicant: ApplicantUtils;
	private isBouncedApplication: boolean;
	private productOfferDetailUtilities: ProductOfferDetailsUtils;
	private splObject: IProduct;
	private uplObject: IProduct;
	private isBouncedUplProduct: boolean;
	private isBouncedSplProduct: boolean;
	private isPrequalApplicationFlow: boolean = false;
	private subscription = new Subscription();
	private disbursement: DisbursementUtils;

	constructor(private loanAppService: LoanApplicationService, private plaidLinkService: PlaidLinkService) {
		const loanAppStub = loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApplication: ILoanApplication) => {
				this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApplication);
				this.disbursement = DisbursementUtils.fromLoanApp(loanApplication);
				this.isBouncedApplication = loanAppService.isApplicationStatusBounced();
				this.isPrequalApplicationFlow = loanApplication.applicationFlow === ApplicationFlowEnum.oportunPrequal;
				this.applicant = new ApplicantUtils(this.loanAppService.getCurrentApplicant());
				this.splObject = this.productOfferDetailUtilities.getSecuredPersonalLoanProduct();
				this.uplObject = this.productOfferDetailUtilities.getUnsecuredPersonalLoanProduct();
				this.isBouncedSplProduct = this.productOfferDetailUtilities.isBouncedProduct(
					ProductCategoriesEnum.securedPersonalLoan
				);
				this.isBouncedUplProduct = this.productOfferDetailUtilities.isBouncedProduct(
					ProductCategoriesEnum.unsecuredPersonalLoan
				);
				this.DocumentSubmitSource.next(true);
			}
		});
		this.subscription.add(loanAppStub);
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public getOcrEligibility(): Observable<any> {
		return of(false);
	}

	public canDisplaySPLSection(): boolean {
		const onlySpl = this.productOfferDetailUtilities.hasOnlySPL();
		const preApprovedSpl =
			this.productOfferDetailUtilities.isPreApprovedProduct(ProductCategoriesEnum.securedPersonalLoan) &&
			!this.productOfferDetailUtilities.isBouncedProduct(ProductCategoriesEnum.unsecuredPersonalLoan);
		const eligible =
			this.productOfferDetailUtilities.isBouncedProduct(ProductCategoriesEnum.securedPersonalLoan) &&
			this.applicant.hasSecuredPauseEligible();

		if (onlySpl || preApprovedSpl || eligible) {
			return true;
		}
		return false;
	}

	public showIncome(): boolean {
		let showIncome: boolean = false;
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		if (this.loanAppService.isApplicationStatusBounced()) {
			showIncome = this.isBounceDocSubmissionAllowed(BounceReasonsEnum.income);
		} else {
			showIncome = applicant.isDocumentRequired(DocumentTypeEnum.income) || this.isAutoVerifiedIncome();
		}
		return showIncome;
	}

	public showIncomeV2(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return this.loanAppService.isApplicationStatusBounced()
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.income)
			: applicant.isDocumentRequiredV2(DocumentTypeEnum.income);
	}

	public isAutoVerifiedIncome(): boolean {
		const incomeSourceOptionsOffered = this.loanAppService.getCurrentApplicantUtils().getIncomeSourceSelected();
		if (incomeSourceOptionsOffered) {
			return (
				VerificationStatusEnum.autoVerified === incomeSourceOptionsOffered ||
				VerificationStatusEnum.verified === incomeSourceOptionsOffered
			);
		}
		return false;
	}

	public isIncomeVerifiedOrSelected(): boolean {
		const incomeSourceOptionsOffered = this.applicant.getIncomeSourceSelected();
		if (incomeSourceOptionsOffered) {
			return (
				VerificationStatusEnum.autoVerified === incomeSourceOptionsOffered ||
				VerificationStatusEnum.verified === incomeSourceOptionsOffered ||
				VerificationStatusEnum.stated === incomeSourceOptionsOffered
			);
		}
		return false;
	}

	public isIncomeSelectionRequired(): boolean {
		const incomeSourceOptionsOffered = this.applicant.getIncomeSourceOptionsOffered();
		return !this.isIncomeVerifiedOrSelected() && incomeSourceOptionsOffered;
	}

	public showBank(route: ActivatedRoute, isBankConnected: boolean): boolean {
		let showBank: boolean = false;
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		const disbursement = this.loanAppService.getDisbursementUtils();
		if (this.loanAppService.isApplicationStatusBounced()) {
			showBank =
				this.isBounceDocSubmissionAllowed(BounceReasonsEnum.disbursement) &&
				applicant.hasRequirement(RequiredDocumentTypeEnum.bankAccountRequired);
		} else {
			showBank =
				(applicant.isDocumentRequired(DocumentTypeEnum.bankAccount) && disbursement.isTypeAch()) ||
				isBankConnected ||
				route.snapshot.queryParams?.addBank === 'true' ||
				route.snapshot.queryParams?.select;
		}
		return showBank;
	}

	public showBankV2(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return this.loanAppService.isApplicationStatusBounced()
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.disbursement) &&
					applicant.hasRequirement(RequiredDocumentTypeEnum.bankAccountRequired)
			: applicant.isDocumentRequiredV2(DocumentTypeEnum.bankAccount);
	}

	isBounceDocSubmissionAllowedForDmvId(sectionName: BounceReasonsEnum, bounceKey): boolean {
		const editableSections = this.applicant.getEditableSections();
		const bounceReasons = this.applicant.getBounceReasons();
		return includes(editableSections, sectionName) && has(bounceReasons, bounceKey);
	}

	public showDmvReqIdProof(): boolean {
		return this.isBouncedApplication
			? this.isBounceDocSubmissionAllowedForDmvId(BounceReasonsEnum.vehicleRegistration, DmvIdSectionKey)
			: false;
	}
	public showAddress(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return this.loanAppService.isApplicationStatusBounced()
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.residence)
			: applicant.isDocumentRequired(DocumentTypeEnum.residence);
	}

	public showAddressV2(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return this.loanAppService.isApplicationStatusBounced()
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.residence)
			: applicant.isDocumentRequiredV2(DocumentTypeEnum.residence);
	}

	public showIdentification(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return this.loanAppService.isApplicationStatusBounced()
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.identification)
			: applicant.isDocumentRequired(DocumentTypeEnum.identification);
	}

	public showIdentificationV2(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return this.loanAppService.isApplicationStatusBounced()
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.identification)
			: applicant.isDocumentRequiredV2(DocumentTypeEnum.identification);
	}

	public showVehicleDriversLicense(): boolean {
		return this.isBouncedApplication
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.vehicleDriverLicence)
			: this.applicant.isDocumentRequired(DocumentTypeEnum.vehicleDriversLicense);
	}
	public showPhoto(): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		return (
			applicant.hasRequirement(RequiredDocumentTypeEnum.applicantPhotoRequired) ||
			this.isBounceDocSubmissionAllowed(BounceReasonsEnum.photo)
		);
	}

	public showReferencesItem(): boolean {
		return this.isBounceDocSubmissionAllowed ? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.references) : false;
	}

	public showVehiclePhotos(): boolean {
		const showVehiclePhotosDoc = this.isBouncedApplication
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.vehiclePhoto)
			: this.applicant.isDocumentRequired(DocumentTypeEnum.vehiclePhotos);

		return showVehiclePhotosDoc && this.canDisplayUploadOption(this.splObject, BounceReasonsEnum.vehiclePhoto);
	}

	public showChangeAchDisbursement(): boolean {
		return this.isRejectAchDisbursementAllowed(this.disbursement) && !this.applicant.isCoApplicant();
	}

	public hideChangeAchDisbursementLink(): boolean {
		return (
			(this.loanAppService.isApplicationStatusPreApproved() &&
				this.loanAppService.getDisbursementUtils().isTypeAch()) ||
			this.loanAppService.getCurrentApplicantUtils().isCoApplicant() || // TODO this logic needs to be refactored
			this.loanAppService.isApplicationStatusBounced()
		);
	}

	public showVehicleRegistration(): boolean {
		return this.isBouncedApplication
			? this.isBounceDocSubmissionAllowed(BounceReasonsEnum.vehicleRegistration) &&
					!this.isBounceOnlyForTheReason(DmvIdSectionKey)
			: this.applicant.isDocumentRequired(DocumentTypeEnum.vehicleRegistration);
	}

	isBounceOnlyForTheReason = (editableKey: string): boolean => {
		const editableSections = this.applicant.getEditableSections();
		const bounceReasonList = this.applicant.getBounceReasons();

		return bounceReasonList.length === 1 && includes(editableSections, editableKey);
	};

	canDisplayUploadOption = (splObject: IProduct, bounceReason: string): boolean =>
		splObject
			? this.productOfferDetailUtilities.isPreApprovedProduct(ProductCategoriesEnum.securedPersonalLoan) ||
			  (this.productOfferDetailUtilities.isBouncedProduct(ProductCategoriesEnum.securedPersonalLoan) &&
					this.isExistsInBounceReason(bounceReason))
			: false;

	isExistsInBounceReason(bounceReason: string): boolean {
		const bounceReasonList = this.applicant.getBounceReasons();
		return has(bounceReasonList, bounceReason);
	}

	private isBounceDocSubmissionAllowed(sectionName): boolean {
		const applicant = this.loanAppService.getCurrentApplicantUtils();
		const editableSections = applicant.getEditableSections();
		const bounceReasons = applicant.getBounceReasons();
		return includes(editableSections, sectionName) && has(bounceReasons, sectionName);
	}

	public isJustOneDocSubmissionGroupAllowed = () => size(this.applicant.getEditableSections()) === 1;

	private getApplicantIdentificationMeta(meta: IMetadata[], applicant: ApplicantUtils): IMetadata {
		const idDocs = applicant.getIdentificationDocument();
		return meta.find((item) => item.key === idDocs.type);
	}

	public getIdentificationType(idTypes: IMetadata[]): IMetadata {
		return this.applicant ? this.getApplicantIdentificationMeta(idTypes, this.applicant) : null;
	}

	isRejectAchDisbursementAllowed(disbursement: DisbursementUtils): boolean {
		return this.loanAppService.isApplicationStatusPreApproved() && !disbursement.isTypeAch();
	}

	public showSplSection(): boolean {
		return this.splObject ? this.canDisplaySPLSection() : false;
	}

	public showReturnToOffers(): boolean {
		return (
			!this.loanAppService.getCurrentApplicantUtils().isCoApplicant() &&
			!this.loanAppService.isApplicationStatusBounced() &&
			!this.isPrequalApplicationFlow
		);
	}

	public canShowSkipSPLButton(): boolean {
		const productOfferDetailUtilities = this.loanAppService.getProductOfferDetailsUtils();
		if (productOfferDetailUtilities.hasMultipleProductsOffered()) {
			const isSplPreApproved = productOfferDetailUtilities.isPreApprovedProduct(
				ProductCategoriesEnum.securedPersonalLoan
			);
			const isUplApproved = productOfferDetailUtilities.isApprovedProduct(ProductCategoriesEnum.unsecuredPersonalLoan);

			if (!(isSplPreApproved && isUplApproved)) {
				return true;
			}
		}
		return false;
	}

	public getSplMaxLoanAmount(): number {
		return this.splObject?.maxApprovedAmount;
	}

	shouldWithdrawSPL(): boolean {
		const isBouncedSplAndUplApproved =
			this.productOfferDetailUtilities.isApprovedProduct(ProductCategoriesEnum.unsecuredPersonalLoan) &&
			this.productOfferDetailUtilities.isBouncedProduct(ProductCategoriesEnum.securedPersonalLoan);
		const isBouncedLoanWithValidSplBounce =
			this.areAllBouncedProducts && this.isSplBounceValid() && !this.isUplBounceValid();

		return isBouncedSplAndUplApproved || isBouncedLoanWithValidSplBounce;
	}

	public isSplBounceValid(): boolean {
		const bounceReasonList = this.applicant.getBounceReasons();
		return SplBounceSectionList.some((validBounce) => has(bounceReasonList, validBounce));
	}

	public isUplBounceValid(): boolean {
		const bounceReasonList = this.applicant.getBounceReasons();
		return UplBounceSectionList.some((validBounce) => has(bounceReasonList, validBounce));
	}

	checkIfValidStatusForSubmit(): boolean {
		return ValidSPLStatusListForSubmit[this.uplObject?.productStatus]?.includes(this.splObject?.productStatus);
	}

	areAllBouncedProducts(): boolean {
		return this.isBouncedSplProduct && this.isBouncedUplProduct;
	}

	getSubmitInstructionsDescription(
		confirmSubmitTranslations: Array<any>,
		applicantConsent: boolean,
		isWI: boolean
	): string {
		const submitInstructions = confirmSubmitTranslations['submitInstructions'] + ' ';
		const authorization = applicantConsent
			? confirmSubmitTranslations['authorization'] +
			  confirmSubmitTranslations['loanAppAuthorization'] +
			  ' ' +
			  confirmSubmitTranslations['and'] +
			  ' ' +
			  confirmSubmitTranslations['wirelessAuthorization'] +
			  '. ' +
			  confirmSubmitTranslations['wirelessOperationsAuthMsg'] +
			  ' '
			: '';
		const propertyStatement = isWI ? confirmSubmitTranslations['wiMaritalPropertyStatement'] : '';
		return applicantConsent ? '' : submitInstructions + authorization + propertyStatement;
	}

	canShowContinueToUPLButton(showSplDocSection: boolean): boolean {
		if (this.productOfferDetailUtilities.hasMultipleProductsOffered()) {
			const isSplInValidStatus = this.isBouncedSplProduct && this.isSplBounceValid();
			const isInValidUplBounceProduct = this.isBouncedUplProduct && !this.isUplBounceValid(); // Santhosh TODO this should not be !
			const isUplApproved = this.productOfferDetailUtilities.isApprovedProduct(
				ProductCategoriesEnum.unsecuredPersonalLoan
			);
			if (!showSplDocSection && isSplInValidStatus && (isInValidUplBounceProduct || isUplApproved)) {
				return true;
			}
		}
		return false;
	}

	isOauthFlow(): boolean {
		return this.plaidLinkService.getPlaidSessionData()?.isOauthFlow;
	}

	getOauthExpandSection(): string {
		return this.plaidLinkService.getPlaidSessionData()?.sectionType;
	}

	isBankConnected(bankAccounts: any): boolean {
		return bankAccounts?.some(
			(bankAccount) =>
				bankAccount.id === this.disbursement.getDisbursementAccountId() &&
				bankAccount.verificationStatus === AccountStatusEnum.verified
		);
	}

	private getAutoVerifyFromBounce(): any {
		let isBankBounced = this.isBounceDocSubmissionAllowed(BounceReasonsEnum.disbursement);
		let isIncomeBounced = this.isBounceDocSubmissionAllowed(BounceReasonsEnum.income);

		if (isBankBounced && isIncomeBounced) {
			return AutoVerifyScenarioEnum.bankAndIncome;
		} else if (isBankBounced && !isIncomeBounced) {
			return AutoVerifyScenarioEnum.bankOnly;
		} else if (!isBankBounced && isIncomeBounced) {
			return AutoVerifyScenarioEnum.incomeOnly;
		}
	}

	getAutoVerifyScenario(): IAutoVerifyScenario {
		let autoVerifyScenario: string = null;
		const hasBankAccountRequirement: boolean = this.applicant?.hasRequirement(
			RequiredDocumentTypeEnum.bankAccountRequired
		);
		if (this.isBouncedApplication) {
			autoVerifyScenario = this.getAutoVerifyFromBounce();
		} else {
			if (this.applicant) {
				if (!this.applicant.isBankAccountVerificationEligible() && this.applicant.isIncomeVerificationEligible()) {
					autoVerifyScenario = AutoVerifyScenarioEnum.incomeOnly;
				} else if (
					this.applicant.isBankAccountVerificationEligible() &&
					this.applicant.isIncomeVerificationEligible()
				) {
					autoVerifyScenario = AutoVerifyScenarioEnum.bankAndIncome;
				} else if (this.applicant.isBankAccountVerificationEligible()) {
					autoVerifyScenario = AutoVerifyScenarioEnum.bankOnly;
				}
			}
		}

		if (autoVerifyScenario) {
			return {
				autoVerifyScenario: autoVerifyScenario,
				hasBankAccountRequirement: hasBankAccountRequirement
			} as IAutoVerifyScenario;
		}

		return null;
	}

	showDeleteDocumentButton(documentDeleteEnabled: boolean, hasUploadedDocuments: boolean): boolean {
		return (
			!this.isBouncedApplication &&
			documentDeleteEnabled &&
			hasUploadedDocuments &&
			!this.productOfferDetailUtilities.isAnyProductInStatus(ProductStatusesEnum.approved)
		);
	}
}
