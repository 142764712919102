<div class="vehicle-eligibility" *transloco="let t">
	<op-masthead [title]="t(title)">
		<div *ngIf="isEligibleForSPL" class="op-header-3-font">{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.congratsSubText') }}</div>
	</op-masthead>

	<op-content-container class="content">
		<div *ngIf="isEligibleForSPL">
			<div>
				<p class="op-micro-font op-gray">{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.basedUpon') }}</p>
				<p class="op-center op-header-3-font op-black">{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.preApprovedFor') }}</p>
			</div>
			<mat-card class="loan-details op-mt-20">
				<mat-card-content>
					<div class="op-header-3-font">{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.securedPersonalLoan') }}</div>
					<op-expansion-panel
						[expanded]="false"
						id="vehicleEligibilitySplPreApprvalPanel"
						[titleBold]="true"
						[applyColor]="true"
						[title]="t('PRODUCT.PRODUCT_STATUSES.preApproved')"
					>
						<p>
							{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.securedLoanNextStep') }}
						</p>
					</op-expansion-panel>

					<div class="loan-amount">
						<div class="op-header-4-font">{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.upTo') }}</div>
						<div class="op-display-2-font">
							{{ maxApprovedAmount | currency: 'USD':'symbol':'0.0' }}
							<sup>*</sup>
						</div>
					</div>
				</mat-card-content>
			</mat-card>

			<div class="op-mt-20 op-icon-align-body-1" id="overDistanceCap" *ngIf="isOverDistanceCap">
				<mat-icon svgIcon="op-info"></mat-icon>
				<span> {{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.outOfRangeNote', { distance: distance }) }}</span>
			</div>

			<div class="op-mt-40 op-left op-black">
				<op-learn-more
					iconSize="small"
					icon="op-checkMark"
					iconAlign="top"
					[title]="t('STATUS.PRE_APPROVED.almostDoneTitle')"
				>
					{{ t('STATUS.PRE_APPROVED.verifyInfo') }}&nbsp;
					<span id="final-review" *ngIf="isSoftPullEnabled">{{ t('SOFT_PULL.finalReview') }}</span>
					<span id="almost-done" *ngIf="!isSoftPullEnabled">{{ t('STATUS.PRE_APPROVED.almostDone') }}</span>
					<P></P>
					<div *ngIf="isElectronicTitleEnabledState" id="verify-info">
						{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.visitNoteWithElectronicTitle') }}
					</div>
					<div *ngIf="!isElectronicTitleEnabledState" id="verify-info">
						{{ t('VEHICLE_ELIGIBILITY.ELIGIBLE.visitNote') }}
					</div>
				</op-learn-more>

				<div *ngIf="showLoanExamplesLink && (!isSoftPullEnabled || (isSoftPullEnabled && isPreScreened))">
					<button mat-flat-button color="primary" id="loanExampleBtn" (click)="openLoanExamplesModal()">
						{{ t('SOFT_PULL.loanExamples') }}
					</button>
				</div>
			</div>
		</div>
		<div class="op-center" *ngIf="isEligibleForSPL === false && !hasUnsecuredOption && !isMetaOrganization">
			<mat-card>
				<mat-card-content>
					<div class="op-center">
						<h4 [innerHTML]="t('VEHICLE_ELIGIBILITY.INELIGIBLE.subheading')"></h4>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card class="op-mt-20">
				<mat-card-content>
					<div class="op-center">
						<h4 [innerHTML]="t('VEHICLE_ELIGIBILITY.INELIGIBLE.mistakeNote')"></h4>
					</div>
					<div id="callNowDiv" class="op-mtb-20">
						<button id="callNowBtn" mat-flat-button color="primary" name="submitBtn" (click)="triggerCallDialog()">
							{{ t('VEHICLE_ELIGIBILITY.INELIGIBLE.callNow') }}
						</button>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="op-center" *ngIf="!isEligibleForSPL && hasUnsecuredOption">
			<mat-card>
				<mat-card-content>
					<div class="op-center">
						<h4 [innerHTML]="t('VEHICLE_ELIGIBILITY.OFFER_STATUS.subheading')"></h4>
						<button mat-flat-button color="primary" type="button" id="returnToOfferBtn2" (click)="returnToOffers()">
							{{ t('GLOBAL.continue') }}
						</button>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card class="op-mt-20" *ngIf="!isMetaOrganization">
				<mat-card-content>
					<div class="op-center">
						<h4 [innerHTML]="t('VEHICLE_ELIGIBILITY.INELIGIBLE.mistakeNote')"></h4>
					</div>
					<div id="callNowDiv" class="op-mtb-20">
						<button id="callNowBtn" mat-flat-button color="primary" name="submitBtn" (click)="triggerCallDialog()">
							{{ t('VEHICLE_ELIGIBILITY.INELIGIBLE.callNow') }}
						</button>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<p></p>
		<div *ngIf="isEligibleForSPL" class="op-center">
			<button
				mat-flat-button
				color="primary"
				type="button"
				id="continueBtn"
				(click)="continue()"
				[opTagClick]="'Continue'"
			>
				{{ t('GLOBAL.continue') }}
			</button>
			<br />
			<button mat-flat-button color="secondary" type="button" id="returnToOfferBtn" (click)="returnToOffers()">
				{{ t('VEHICLE_ELIGIBILITY.OFFER_STATUS.returnToOffers') }}
			</button>
		</div>
		<div id="disqualification" class="op-mt-40" *ngIf="isEligibleForSPL === false">
			<op-expansion-panel
				[expanded]="false"
				id="expansionPanel"
				[titleBold]="true"
				[title]="t('VEHICLE_ELIGIBILITY.INELIGIBLE.DISQUALIFICATION.title')"
				[opTagClick]="'Why did not my vehicle qualify'"
			>
				<ul>
					<li>{{ t('VEHICLE_ELIGIBILITY.INELIGIBLE.DISQUALIFICATION.COMMON_REASONS.one') }}</li>
					<li>{{ t('VEHICLE_ELIGIBILITY.INELIGIBLE.DISQUALIFICATION.COMMON_REASONS.two') }}</li>
					<li>{{ t('VEHICLE_ELIGIBILITY.INELIGIBLE.DISQUALIFICATION.COMMON_REASONS.three') }}</li>
					<li>{{ t('VEHICLE_ELIGIBILITY.INELIGIBLE.DISQUALIFICATION.COMMON_REASONS.four') }}</li>
				</ul>
			</op-expansion-panel>
		</div>
	</op-content-container>
</div>
