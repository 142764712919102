<div class="date-input" *transloco="let t">
	<div class="form-group-parts" [ngClass]="showCount" [formGroup]="parts">
		<mat-form-field
			*ngIf="showMonth"
			class="form-element"
			appearance="outline"
			[hideRequiredMarker]="hideRequiredMarker"
		>
			<mat-label>{{ t('DATE_INPUT.month') }}</mat-label>
			<mat-select
				name="mm"
				id="{{ id + '_mm' }}"
				formControlName="month"
				(selectionChange)="doSomething($event, parts.controls.month, dayElement)"
				#monthElement
				aria-label="month"
				(focus)="onFocus(parts.controls.month)"
				(blur)="onBlur(parts.controls.month)"
				required
			>
				<mat-option *ngFor="let month of monthList; let i = index" [value]="i" id="{{ id + '_mm_' + month }}">
					{{ month }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field *ngIf="showDay" class="form-element" appearance="outline" [hideRequiredMarker]="hideRequiredMarker">
			<mat-label>{{ t('DATE_INPUT.day') }}</mat-label>
			<mat-select
				name="dd"
				id="{{ id + '_dd' }}"
				formControlName="day"
				(selectionChange)="doSomething($event, parts.controls.day, yearElement)"
				#dayElement
				aria-label="day"
				(focus)="onFocus(parts.controls.day)"
				(blur)="onBlur(parts.controls.day)"
				required
			>
				<mat-option *ngFor="let day of dayList; let i = index" [value]="i + 1" id="{{ id + '_dd_' + (i + 1) }}">
					{{ i + 1 }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field
			*ngIf="showYear"
			class="form-element"
			appearance="outline"
			[hideRequiredMarker]="hideRequiredMarker"
		>
			<mat-label>{{ t('DATE_INPUT.year') }}</mat-label>
			<mat-select
				name="yy"
				id="{{ id + '_yy' }}"
				formControlName="year"
				(selectionChange)="doSomething($event, parts.controls.year)"
				#yearElement
				aria-label="year"
				(focus)="onFocus(parts.controls.year)"
				(blur)="onBlur(parts.controls.year)"
				required
			>
				<mat-option *ngFor="let year of yearList" [value]="year" id="{{ id + '_yy_' + year }}">
					{{ year }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>
