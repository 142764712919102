import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep, Dictionary, isEmpty, pickBy } from 'lodash';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	first,
	map,
	of,
	Subject,
	Subscription,
	takeUntil,
	timeout,
	timer
} from 'rxjs';
import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ConfigApiService, FileStatusEnum } from 'src/app/core/services/mobile-api';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import AutoVerificationUtils, { frontBackList, singleFileList } from './auto-verification-utils';
import { bounceOcrMap, ocrBounceMap } from './auto-verification.component';

interface IProgressData {
	id: string;
	progress: number;
	startTime: number;
	fileProgress: number;
	takingToLong: boolean;
	continueAvailable: boolean;
	endTime: number;
	timerSub: Subscription;
	endTimer$?: Subject<void>;
}

interface IProgressDataMap {
	[type: string]: IProgressData;
}

interface ICategoryUploads {
	[type: string]: IFileUploadExtended[];
}

/**
 * Order is set for sorting.
 *
 * @export
 * @enum {number}
 */
export enum DocBadgeEnum {
	verified = 'verified',
	attention = 'attention',
	required = 'required',
	needs = 'needs',
	processing = 'processing',
	ready = 'ready',
	idle = 'idle'
}

export interface IFileUploadExtended extends IFileUpload {
	badge?: DocBadgeEnum;
	progressData?: IProgressData;
}

export interface IClassificationBadge {
	count: number;
	badge: DocBadgeEnum;
	files: IFileUploadExtended[];
}

export interface IClassificationVerification {
	[key: string]: IClassificationBadge;
}

export interface ICategoryTypeBadge {
	count: number;
	badge: DocBadgeEnum;
	bounce: boolean;
	classification: IClassificationVerification;
}

export interface IDocVerification {
	[key: string]: ICategoryTypeBadge;
}

@Injectable({
	providedIn: 'root'
})
export class AutoVerificationService implements OnDestroy {
	private subscription = new Subscription();

	private readonly autoVerificationSource = new BehaviorSubject<IDocVerification>(null);
	readonly autoVerification$ = this.autoVerificationSource.asObservable();
	private readonly storageKey = 'docVerify';

	private categoryUploads: ICategoryUploads = {};

	private defaultDocVerification: ICategoryTypeBadge = {
		count: 0,
		badge: DocBadgeEnum.idle,
		bounce: false,
		classification: {}
	};

	private uploadDelay: number = 20;
	frequency = 0.5;
	stallingPercent = 95;
	textTime = () => this.uploadDelay / 2;
	buttonTime = () => this.uploadDelay / 1.5;

	frequencyTimer = () => this.frequency * 1000;
	totalTimer = () => this.uploadDelay * 1000;
	textTimer = () => this.textTime() * 1000;
	buttonTimer = () => this.buttonTime() * 1000;

	readonly progressBarSplitMap = {
		'500000': [10, 90],
		'1000000': [20, 80],
		'5000000': [40, 60],
		'10000000': [70, 30]
	};
	getProgressSplit = (size: number) =>
		this.progressBarSplitMap[Object.keys(this.progressBarSplitMap).find((k) => size <= Number(k)) || '10000000'];

	readonly downloadSplit = (size: number) => this.getProgressSplit(size)[0] / 100;
	readonly processingSplit = (size: number) => this.getProgressSplit(size)[1] / 100;

	readonly defaultFileProgress = {
		id: '',
		progress: 0,
		startTime: 0,
		fileProgress: 0,
		takingToLong: false,
		continueAvailable: false,
		endTime: 0,
		timerSub: null
	};

	filesProgress: IProgressDataMap = {};

	private docVerification: IDocVerification = {
		[FileUploadType.identification]: cloneDeep(this.defaultDocVerification),
		[FileUploadType.income]: cloneDeep(this.defaultDocVerification),
		[FileUploadType.addresses]: cloneDeep(this.defaultDocVerification),
		[FileUploadType.bank]: cloneDeep(this.defaultDocVerification),
		[FileUploadType.selfie]: cloneDeep(this.defaultDocVerification)
	};

	constructor(
		private loanAppService: LoanApplicationService,
		private fileUploadService: FileUploadService,
		private configApiService: ConfigApiService,
		private sessionStorageService: SessionStorageService,
		private tagDataService: TagDataService
	) {
		// const sessionData = this.sessionStorageService.get(this.storageKey);
		// this.autoVerificationSource.next(sessionData);
		const configSub = this.configApiService.configPostUploadDelay().subscribe({
			next: (rsp) => {
				this.uploadDelay = Number(rsp?.value) || 20;
			}
		});
		this.subscription.add(configSub);

		const fileUploadObserve$ = this.fileUploadService.fileUploads$;
		const currentUploadTypes$ = this.fileUploadService.currentFileTypeUploads$;
		const loanApp$ = this.loanAppService.loanApplication$;
		const submitAttempt$ = this.sessionStorageService.select('documentSubmit');
		const fileObserve$ = combineLatest([loanApp$, fileUploadObserve$, currentUploadTypes$, submitAttempt$]);

		const fileSub = fileObserve$.subscribe({
			next: ([loanApp, fileUploads, currentUploadTypes, submitAttempt]) => {
				const bounceReasons = pickBy(this.loanAppService.getFilteredBounceReasons(), (value, key) => {
					return !currentUploadTypes.includes(bounceOcrMap[key]);
				});

				Object.keys(this.docVerification).forEach((key) => {
					const files = fileUploads[key];
					const fileList = AutoVerificationUtils.modifyOCRAttention(files);
					this.categoryUploads[key] = this.updateAllFileProgress(fileList);

					this.docVerification[key].count = this.getTypeCount(this.categoryUploads[key], FileUploadType[key]) || 0;
					this.docVerification[key].badge = this.getCategoryTypeBadge(this.categoryUploads[key], submitAttempt);
					this.docVerification[key].bounce = this.getCategoryTypeBounce(bounceReasons, FileUploadType[key]);
					this.docVerification[key].classification = this.getClassificationBadge(
						this.categoryUploads[key],
						FileUploadType[key]
					);
				});

				this.autoVerificationSource.next(this.docVerification);
			}
		});
		this.subscription.add(fileSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public getUploadDelay(): number {
		return this.uploadDelay;
	}

	public getMissingDocumentSection(list: { categoryType: FileUploadType; badge: DocBadgeEnum }[]): FileUploadType[] {
		const filteredList = list.filter((item) => {
			return (
				!this.categoryUploads[item.categoryType]?.some((file) => file.status === FileUploadStatusEnum.finished) &&
				item.badge !== DocBadgeEnum.verified
			);
		});
		return filteredList.map((item) => item.categoryType);
	}

	private findPreviousFile(file: IFileUploadExtended): IFileUploadExtended {
		return this.docVerification[file?.type]?.classification[file?.classification]?.files?.find((f) => f.id === file.id);
	}

	private getFileBadge(file: IFileUpload): DocBadgeEnum {
		const isReview = file?.ocrInfo?.documentStatus === FileStatusEnum.review;
		const isProcessing = file?.ocrInfo?.documentStatus === FileStatusEnum.pending;
		const isComplete = file?.ocrInfo?.documentStatus === FileStatusEnum.complete;
		const isUploading = file?.status === FileUploadStatusEnum.uploading;
		let badge = DocBadgeEnum.idle;

		if (isReview) {
			badge = DocBadgeEnum.attention;
		}

		if (isProcessing || isUploading) {
			badge = DocBadgeEnum.processing;
		}

		if (isComplete) {
			if (
				file.type === FileUploadType.income &&
				file.classification === 'BANK_STATEMENT' &&
				AutoVerificationUtils.isIncomeBankStatementCriteriaMetForSingleFile(file)
			) {
				badge = DocBadgeEnum.verified;
			} else {
				badge = DocBadgeEnum.ready;
			}
		}

		const prevFile = this.findPreviousFile(file);
		if (prevFile && prevFile?.badge !== badge) {
			this.tag(`upload_document_badge_${badge}`);
		}

		return badge;
	}

	private getFileUploadBadge(file: IFileUpload): IFileUploadExtended {
		return {
			...file,
			badge: this.getFileBadge(file)
		};
	}

	private getTypeCount(files: IFileUpload[], type: FileUploadType): number {
		const filtered = files?.filter((f) => f.type === type);
		if (type === FileUploadType.identification) {
			return this.fixTypeFrontBackCount(filtered);
		} else {
			return filtered.length;
		}
	}

	/**
	 * fix the file count for identification files
	 *
	 * @private
	 * @param {IFileUpload[]} files
	 * @return {*}  {number}
	 * @memberof AutoVerificationService
	 */
	private fixTypeFrontBackCount(files: IFileUpload[]): number {
		let filteredFiles: IFileUpload[];
		const frontBackFiles = frontBackList.reduce((acc, curr) => {
			const filtered = files?.filter((f) => String(f.classification) === curr);
			return { ...acc, [curr]: filtered };
		}, {});
		const singleFiles = files?.filter((f) => singleFileList.includes(String(f.classification)));
		const frontBackAndSingleList = frontBackList.concat(singleFileList);
		const otherFiles = files?.filter((f) => !frontBackAndSingleList.includes(String(f.classification)));

		if (Object.keys(frontBackFiles).some((key) => frontBackFiles[key]?.length)) {
			filteredFiles = Object.keys(frontBackFiles).reduce((acc, curr) => {
				return [...acc, ...frontBackFiles[curr]?.slice(-2)];
			}, []);
			filteredFiles.push(...otherFiles);
			return isEmpty(singleFiles) ? filteredFiles?.length : filteredFiles?.length + 1;
		} else {
			return isEmpty(singleFiles) ? files?.length : otherFiles?.length + 1;
		}
	}

	private getClassificationCount(files: IFileUpload[], type: FileUploadType, classification: string): number {
		const filtered = files?.filter((f) => f.type === type && f.classification === classification) || [];
		if (type === FileUploadType.identification) {
			return this.fixIdentificationItems(filtered);
		} else {
			return filtered.length;
		}
	}

	private fixIdentificationItems(files: IFileUpload[]): number {
		if (files?.some((f) => frontBackList.includes(String(f.classification)))) {
			return 2;
		} else if (files.some((f) => singleFileList.includes(String(f.classification)))) {
			return 1;
		} else {
			return files?.length;
		}
	}

	private getClassificationBadge(files: IFileUpload[], type: FileUploadType): IClassificationVerification {
		const classification = files?.reduce((acc, item: IFileUpload) => {
			acc[item.classification] = {
				files: [...(acc[item.classification]?.files || []), this.getFileUploadBadge(item)]
			};
			return acc;
		}, {});

		if (classification) {
			Object.keys(classification).forEach((key) => {
				classification[key].count = this.getClassificationCount(classification[key].files, type, key);
				classification[key].badge = this.getCategoryTypeBadge(classification[key].files, false);
			});
		}

		return classification || {};
	}

	private isCriteriaMet(files: IFileUpload[]): boolean {
		const isIncome = files?.find(Boolean)?.type === FileUploadType.income;
		return isIncome ? AutoVerificationUtils.isIncomeBankStatementCriteriaMet(files) : true;
	}

	private isCriteriaPartiallyMet(files: IFileUpload[]): boolean {
		const isIncome = files?.find(Boolean)?.type === FileUploadType.income;
		return isIncome ? AutoVerificationUtils.isIncomeBankStatementCriteriaPartiallyMet(files) : true;
	}

	private getCategoryTypeBounce(bounceReasons: Dictionary<string[]>, type: FileUploadType): boolean {
		const bounceKeys = Object.keys(bounceReasons);
		const bounceAttention = bounceKeys?.includes(ocrBounceMap[type]);
		return Boolean(bounceAttention);
	}

	private hasCriteria(files: IFileUpload[]): boolean {
		return AutoVerificationUtils.hasIncomeBankStatement(files);
	}

	private getCategoryTypeBadge(files: IFileUpload[], submitAttempted: boolean): DocBadgeEnum {
		const isSomeInReview = files?.some((f) => f?.ocrInfo?.documentStatus === FileStatusEnum.review);
		const criteriaMet = this.isCriteriaMet(files);
		const criteriaPartiallyMet = criteriaMet || this.isCriteriaPartiallyMet(files);

		const attention = isSomeInReview || (!criteriaMet && criteriaPartiallyMet);

		const processing = files?.some(
			(f) => f?.ocrInfo?.documentStatus === FileStatusEnum.pending || f?.status === FileUploadStatusEnum.uploading
		);

		if (submitAttempted && !files?.length) {
			return DocBadgeEnum.needs;
		}

		if (criteriaMet && this.hasCriteria(files)) {
			return DocBadgeEnum.ready;
		}

		if (processing) {
			return DocBadgeEnum.processing;
		}

		if (attention) {
			return DocBadgeEnum.attention;
		}

		const isFinished = files?.every((f) => f.status === FileUploadStatusEnum.finished);
		const hasOcr = files?.every((f) => f.ocrInfo);
		const isOcrComplete = files?.every((f) => f.ocrInfo?.documentStatus === FileStatusEnum.complete);
		const isComplete = !isEmpty(files) && ((hasOcr && isOcrComplete) || isFinished);

		if (isComplete) {
			return DocBadgeEnum.ready;
		}

		return DocBadgeEnum.idle;
	}

	private tag(tag: string, action: string = 'progress'): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: tag,
				event_action: action,
				event_label: 'upload',
				event_category: 'rx_form'
			}
		);
	}
	////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////

	private getFile(fileId: string): IFileUploadExtended {
		return Object.values(this.categoryUploads)
			.flat()
			.find((f) => f.id === fileId);
	}

	private getOcrInfoStatus(file: IFileUpload): FileStatusEnum {
		return file?.ocrInfo?.documentStatus;
	}

	private isOcrPending(file: IFileUpload): boolean {
		return this.getOcrInfoStatus(file) === FileStatusEnum.pending;
	}

	private isFileUploading(file: IFileUpload): boolean {
		return file?.status === FileUploadStatusEnum.uploading;
	}

	private isFileProgress(fileId: string): boolean {
		return this.filesProgress[fileId]?.fileProgress > 0 && this.filesProgress[fileId]?.fileProgress < 100;
	}

	private isPassedEndTime(progress: IProgressData): boolean {
		return progress?.endTime ? progress?.endTime < Date.now() : false;
	}

	private updateFileProgress(file: IFileUpload): IFileUploadExtended {
		let f = this.filesProgress[file.id];
		if ((f?.endTimer$ && !this.isFileUploading(file) && !this.isOcrPending(file)) || this.isPassedEndTime(f)) {
			f?.endTimer$?.next();
		}

		if (file.status !== FileUploadStatusEnum.uploading) {
			if (
				(file.ocrInfo?.documentStatus === FileStatusEnum.pending ||
					file.ocrInfo?.documentStatus === FileStatusEnum.complete ||
					file.ocrInfo?.documentStatus === FileStatusEnum.review) &&
				!f
			) {
				f = { ...this.defaultFileProgress };
				f.id = file.id;
				f.progress = file.progress;
				f.fileProgress = this.stallingPercent;
				f.continueAvailable = true;
				this.filesProgress[f.id] = f;
			} else if (f && !f?.continueAvailable) {
				const timeElapsed = Date.now() - f.startTime;
				if (
					(timeElapsed > this.buttonTimer() && file.status === FileUploadStatusEnum.finished) ||
					file.ocrInfo?.documentStatus !== FileStatusEnum.pending
				) {
					f.continueAvailable = true;
					this.filesProgress[f.id] = f;
				}
			}
			if (f?.progress !== 100 && file?.progress === 100) {
				this.tag('upload_progress_end');
			}
			return { ...file, progressData: f };
		}

		if (!f) {
			f = { ...this.defaultFileProgress };
			f.id = file.id;
			f.endTimer$ = new Subject();
			f.startTime = f.startTime || Date.now();
			f.endTime = f.startTime + this.totalTimer();
			f.progress = file.progress;
			this.filesProgress[f.id] = f;
			this.tag('upload_progress_start');
		}

		if (!f.timerSub) {
			f.timerSub = timer(0, this.frequencyTimer())
				.pipe(
					takeUntil(this.filesProgress[file.id]?.endTimer$),
					map(() => this.filesProgress[file.id]),
					map((progressInfo) => {
						const timerFile = this.getFile(progressInfo.id);
						if (!timerFile) {
							throw new Error('file not found');
						}

						if (progressInfo.progress === 100) {
							this.tag('upload_progress_extended_wait_start');
							const initial = progressInfo.progress * this.downloadSplit(timerFile?.file?.size);
							const timeElapsed = Date.now() - progressInfo.startTime;
							if (timeElapsed > this.textTimer()) {
								progressInfo.takingToLong = true;
							}
							if (timeElapsed > this.buttonTimer()) {
								progressInfo.continueAvailable = true;
							}
							const remainder = this.processingSplit(timerFile?.file?.size) / this.totalTimer();
							const increment = remainder * timeElapsed * 100;
							progressInfo.fileProgress = Math.min(this.stallingPercent, initial + (increment || 0));
							this.filesProgress[progressInfo.id] = progressInfo;
							timerFile.progressData = progressInfo;
							this.autoVerificationSource.next(this.docVerification);
						}
						return { progressInfo, file: timerFile };
					}),

					first(
						({ progressInfo, file }) =>
							progressInfo.endTime < Date.now() || (!this.isFileUploading(file) && !this.isOcrPending(file))
					),
					map(({ progressInfo, file }) => {
						return progressInfo;
					}),
					timeout(this.filesProgress[file.id]?.endTime - this.filesProgress[file.id]?.startTime),
					catchError((err) => {
						let progressInfo = this.filesProgress[file.id];
						return of(progressInfo);
					})
				)
				.subscribe({
					complete: () => {
						let progressInfo = this.filesProgress[file.id];
						if (progressInfo?.progress == 100) {
							const file = this.getFile(progressInfo.id);
							if (
								file?.status === FileUploadStatusEnum.finished &&
								file?.ocrInfo?.documentStatus === FileStatusEnum.pending
							) {
								progressInfo.fileProgress = this.stallingPercent;
								progressInfo.continueAvailable = true;
								progressInfo.takingToLong = true;
							}
							this.filesProgress[progressInfo.id] = progressInfo;
							file.progressData = progressInfo;
							this.autoVerificationSource.next(this.docVerification);
							this.tag('upload_progress_extended_wait_end');
						}
					}
				});
			this.subscription.add(f.timerSub);
			this.filesProgress[f.id] = f;
		} else {
			const progressInfo = this.filesProgress[file.id];
			progressInfo.progress = file.progress;

			if (progressInfo.progress !== 100 && file.progress === 100) {
				this.tag('upload_progress_end');
			}

			if (progressInfo.endTimer$?.observed) {
				const fp = progressInfo.progress * this.downloadSplit(file?.file?.size);
				progressInfo.fileProgress = Math.max(progressInfo.fileProgress, fp);
			} else {
				progressInfo.fileProgress = Math.max(progressInfo.fileProgress, progressInfo.progress);
			}

			this.filesProgress[progressInfo.id] = progressInfo;
		}

		return { ...file, progressData: f };
	}

	private updateAllFileProgress(files: IFileUpload[]): IFileUploadExtended[] {
		return files?.map((f) => this.updateFileProgress(f));
	}
}
