import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { LoggingService } from '../services/logging/logging.service';

/**
 * Guard to check that the user is authenticated before showing pages.
 *
 * @export
 * @class AuthenticationGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {
	constructor(private router: Router, private auth: AuthenticationService, private loggingService: LoggingService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.auth.getAuthorizationToken()) {
			return this.router.parseUrl('/home');
		}
		return true;
	}
}
