<div class="status-non-processable" *transloco="let t">
	<op-masthead [title]="t(title)" [subTitle]="t(subTitle)"></op-masthead>
	<op-content-container>
		<div class="op-ml-10 op-mt-20">
			<p class="op-body-2-font">
				<strong>{{ t('STATUS.NON_PROCESSABLE.mailReason') }}</strong>
			</p>

			<div class="op-icon-align-body-1 reapply op-mtb-20">
				<mat-icon svgIcon="op-calendar"></mat-icon>
				<span class="op-body-2-font">
					<strong>
						{{ t('STATUS.NON_PROCESSABLE.reApplyDate', { newApplicationStartDate: newApplicationStartDate | date }) }}
					</strong>
				</span>
			</div>

			<hr />

			<div class="op-mtb-20">
				<p class="op-header-2-font">{{ t('STATUS.NON_PROCESSABLE.wantToHelp') }}</p>
			</div>

			<op-digit-offer-for-not-approved
				[digitUrl]="digitUrl"
				(OnClickTriggerAction)="trackEligibilityEvents($event)"
				id="digitOffer"
				[isPathward]="issuingOrganizationIsMeta"
				*ngIf="digitUrl"
			>
			</op-digit-offer-for-not-approved>

			<op-unidos-us-information *ngIf="issuingOrganizationIsOportun && digitUrl === null"></op-unidos-us-information>
		</div>
	</op-content-container>
</div>
