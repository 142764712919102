<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="bankPanel"
		title="{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.title') }}"
		[fileList]="fileList"
		[expanded]="expanded"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
		[isVerified]="isVerified"
	>
		<div *ngIf="!isVerified">
			<button
				id="bankVerifyManuallyBtn"
				*ngIf="verifyManually"
				mat-flat-button
				color="secondary"
				(click)="verifyManually = !verifyManually"
			>
				{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.verifyManually') }}
			</button>

			<div id="bankAutoVerifySection" class="panel-content" [hidden]="verifyManually">
				<div class="op-header-3-font">{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.subtitleUpload') }}</div>
				<ul>
					<li>{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.requirements.accountOwner') }}</li>
					<li class="account-number-tip">
						<op-info-tip
							id="bankAccountTip"
							[key]="t('DOCUMENT_SUBMIT.proofOfBankAccount.requirements.accountNumberTip')"
						>
							{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.requirements.accountNumber') }}
						</op-info-tip>
					</li>
					<li>{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.requirements.noPrepaidCards') }}</li>
				</ul>
				<op-add-bank
					id="bankAddBank"
					[filter]="bankFilterForNotVerified"
					[selectBank]="false"
					[hideAddBank]="true"
				></op-add-bank>
				<op-file-upload
					id="bankFileUpload"
					[categoryType]="fileType"
					requiredFileType="application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/bmp"
				>
				</op-file-upload>
			</div>
			<p></p>
		</div>
	</op-document-expansion-panel>
</div>
