import { Pipe, PipeTransform } from '@angular/core';
import { some } from 'lodash';
import { MetaIdentificationType } from 'src/app/core/services/metadata/metadata.model';
import { StatesEnum } from 'src/app/core/utils/state-utils';

// {key: 2201, text: "US Driver's License"}
// {key: 2203, text: "US Passport"}
// {key: 2218, text: "Limited Use (Resident) Driver’s License"}
// {key: 2206, text: "US State/City ID Card"}
// {key: 2210, text: "US Dept of State Driver's License/ID"}
// {key: 2204, text: "US Permanent Residence Card"}
// {key: 2213, text: "US Employment Authorization Card"}
// {key: 2215, text: "US Border Crossing Card"}
// {key: 2216, text: "US Non-immigrant Visa"}
// {key: 2217, text: "Foreign Passport"}
// {key: 2202, text: "Foreign Matricula Consular"}
// {key: 2211, text: "Foreign Driver's License"}
// {key: 2212, text: "Foreign Identity Card"}
// {key: 2205, text: "National Electoral Card"}
// {key: 2207, text: "Other"}

/**
 * Lists of values that should be shown or hidden in the Identification page.
 *
 * @export
 * @class ShowIdentificationFieldPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'showIdentificationField' })
export class ShowIdentificationFieldPipe implements PipeTransform {
	readonly DEFAULT_COUNTRY = 'US';

	readonly showFieldCondition = {
		showIssuingState: [2201, 2218, 2206],
		showNoneOpt: [2218, 2217, 2202, 2207], // none, ssn, itin
		showNoneOptForIL: [2217, 2202], // none, ssn, itin
		showTaxFilingQuestion: [2218, 2217, 2202, 2207],
		showTaxFilingQuestionForIL: [2217, 2202],
		showUSStatesRestrictedStates: [],
		showIssuingCountry: [2218, 2217, 2202, 2207], // issuing country?
		showUSStatesRestrictedCountries: [2218],
		showSsnOrITIN: [2201, 2203, 2206, 2218],
		showSsn: [], // ssnOrItin === ssn
		showItin: [] // ssnOrItin === itin
	};

	readonly setFieldsRequired = {
		2201: ['issuingState'],
		2202: ['taxFilingQuestion', 'issuingCountry'],
		2207: ['taxFilingQuestion', 'issuingCountry'],
		2217: ['taxFilingQuestion', 'issuingCountry'],
		2218: ['issuingState', 'taxFilingQuestion', 'issuingCountry'],
		2206: ['issuingState']
	};

	readonly setFieldsRequiredIl = {
		2207: ['issuingCountry'],
		2218: ['issuingState', 'issuingCountry']
	};

	getFieldsRequired(key: number, state: StatesEnum): Array<string> {
		if (state === StatesEnum.IL && (key === 2207 || key === 2218)) {
			return this.setFieldsRequiredIl[key];
		}
		return this.setFieldsRequired[key];
	}

	transform(idType: number, ...args: string[]): boolean {
		let field = args[0];
		const state = args[1];
		const country = args[2];
		if (state === StatesEnum.IL) {
			const fields = ['showNoneOpt', 'showTaxFilingQuestion'];
			field = some(fields, (item) => item === field) ? field.concat('ForIL') : field;
		}

		if (idType === MetaIdentificationType.Other && field === 'showNoneOpt' && country === this.DEFAULT_COUNTRY) {
			return false;
		}

		return this.showFieldCondition[field].includes(idType);
	}
}
