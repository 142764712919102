<div class="samples" *transloco="let t">
	<op-masthead [title]="'SAMPLES.title'"></op-masthead>
	<op-content-container>
		<op-expansion-panel id="typographyId" [title]="'Typography'" [expanded]="false">
			<div class="typography">
				<div class="op-header-commons">
					<div>TT Commons Pro</div>
					<hr />
					<div class="op-display-1-font">Display-1</div>
					<div class="op-display-2-font">Display-2</div>
					<div class="op-display-3-font">Display-3</div>
					<div class="op-header-1-font">Header 1</div>
					<div class="op-header-2-font">Header 2</div>
					<div class="op-header-3-font">Header 3</div>
					<div class="op-header-4-font">Header 4</div>

					<div class="op-body-1-font">body-1</div>
					<div class="op-body-1-bold-font">body-1 emphasis</div>

					<div class="op-body-2-font">op-body-2</div>
					<div class="op-body-2-bold-font">op-body-2 emphasis</div>

					<div class="op-label-font">label</div>

					<div class="op-micro-font">micro</div>
					<div class="op-micro-bold-font">micro emphasis</div>
				</div>

				<hr />
				<div>
					<h1>H1 tag</h1>
					<h2>H2 tag</h2>
					<h3>H3 tag</h3>
					<h4>H4 tag</h4>
					<h5>H5 tag</h5>
					<h6>H6 tag</h6>
					Body Text<br />
					<strong>Strong</strong><br />
					<caption>
						caption
					</caption>
					<small>small</small><br />
				</div>
			</div>
		</op-expansion-panel>

		<op-expansion-panel id="displayId" [title]="'Display elements'" [expanded]="false">
			<op-alert level="ERROR">Error alert component</op-alert>
			<op-alert level="WARNING">Warning alert component</op-alert>
			<op-alert level="SUCCESS">Success alert component</op-alert>

			<p></p>
			<div class="op-icon-align-body-2">
				<mat-icon svgIcon="op-alert"></mat-icon>op-icon-align-body-2
				{{ t('REFERENCES.pleaseProvide') }}
			</div>

			<div class="op-icon-align-body-2-green">
				<mat-icon svgIcon="op-checkMark-outline"></mat-icon>op-icon-align-body-2-green
				{{ t('REFERENCES.totalProvided') }}
			</div>

			<p></p>
			<div class="op-icon-align-body-1">
				<mat-icon svgIcon="op-info"></mat-icon>op-icon-align-body-1
				{{ t('REFERENCES.pleaseProvide') }}
			</div>

			<div class="op-icon-align-body-1-green">
				<mat-icon svgIcon="op-cloud"></mat-icon>op-icon-align-body-1-green
				{{ t('REFERENCES.totalProvided') }}
			</div>

			<p></p>
			<div class="op-icon-align-header-2">
				<mat-icon svgIcon="op-alert"></mat-icon>op-icon-align-header-2
				{{ t('REFERENCES.pleaseProvide') }}
			</div>

			<div class="op-icon-align-header-2-green">
				<mat-icon svgIcon="op-checkMark"></mat-icon>op-icon-align-header-2-green
				{{ t('REFERENCES.totalProvided') }}
			</div>

			<hr />
			<p></p>
			<div id="{{ id + '_verified' }}" class="op-icon-align-body-2 op-mt-5 verified">
				<mat-icon class="verified-icon" svgIcon="op-checkMark-outline"></mat-icon>
				{{ t('STATUS.verified') }}
			</div>
			<p></p>
			<div class="upload-count">
				<span id="{{ id + '_expansionPanel_fileCount' }}" class="attachment op-body-2">
					{{ (fileList | opFinishedFileUploads)?.length || 0 }} {{ t('DOCUMENT_SUBMIT.attachment') }}
				</span>
			</div>
			<p></p>

			<op-info-tip id="hasBankTip" class="op-mb-20" [key]="'op-info-tip description'">
				<span id="funds">op-info-tip</span>
			</op-info-tip>
			<op-info-tip id="aprNoteTip" [key]="'help description'" icon="op-help">
				<span>op-info-tip changed icon</span>
			</op-info-tip>
			<p></p>

			<!-- Learn More Component -->
			<op-learn-more id="sampleLearnMore" src="/assets/images/offers-handMoney.svg" [title]="'op-learn-more'">
				<div [innerHTML]="t('SECURED_PERSONAL.LEARN_MORE.whatSecuredPersonalLoanAnswer')"></div>
			</op-learn-more>
			<p></p>
			<op-learn-more id="sampleLearnMoreIconSmall" iconSize="small" icon="op-smiley" [title]="'op-learn-more small'">
				<p>{{ t('ESIGN_SIGN_DOCUMENT.viewElectronically') }}</p>
			</op-learn-more>

			<!-- <op-document-badge class="ready op-mb-5">ready</op-document-badge>
			<op-document-badge class="attention op-mb-5">attention</op-document-badge>
			<op-document-badge class="needs op-mb-5">needs attention</op-document-badge>
			<op-document-badge class="idle op-mb-5">idle</op-document-badge>
			<op-document-badge class="processing op-mb-5">Processing</op-document-badge> -->

			<op-horizontal-rule [text]="t('GLOBAL.or') | uppercase"></op-horizontal-rule>
			<p></p>
		</op-expansion-panel>

		<op-expansion-panel id="formId" [title]="'Form elements'" [expanded]="false">
			<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
				<!-- INPUT -->
				<div class="op-input-field">
					<mat-form-field class="form-element" appearance="outline">
						<mat-label>{{ t('PHONE.number') }}</mat-label>
						<input
							matInput
							id="sampleInput"
							type="text"
							placeholder="(___) ___-____"
							mask="(000) 000-0000"
							name="telephone"
							formControlName="phoneNumber"
						/>
						<mat-hint class="op-hint">{{ t('PHONE.numberInfo') }}</mat-hint>
					</mat-form-field>
				</div>

				<!-- DROPDOWN -->
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('IDENTIFICATION.issuingState') }} </mat-label>
					<mat-select formControlName="issuingState" #state id="sampleSelect" aria-label="state" required>
						<mat-option
							*ngFor="let state of stateList; let i = index"
							[value]="state.key"
							id="{{ 'issuingState_' + state.text }}"
							>{{ state.text }}</mat-option
						>
					</mat-select>
				</mat-form-field>

				<!-- RADIO -->
				<div class="op-form-group">
					<div class="op-form-title">{{ t('DEBT.haveLoan') }}</div>
					<mat-radio-group name="ssnOrItin" id="sampleRadio" formControlName="ssnOrItin">
						<mat-radio-button [value]="toggleSsnItinOptions[0].key">
							{{ toggleSsnItinOptions[0].text }}
						</mat-radio-button>
						<mat-radio-button [value]="toggleSsnItinOptions[1].key">
							{{ toggleSsnItinOptions[1].text }}
						</mat-radio-button>
						<mat-radio-button [value]="toggleSsnItinOptions[2].key">
							{{ toggleSsnItinOptions[2].text }}
						</mat-radio-button>
					</mat-radio-group>
					<div class="op-form-info">{{ t('DEBT.haveLoanInfo') }}</div>
				</div>

				<!-- CHECKBOX -->
				<mat-checkbox formControlName="mailSameAsHome" id="sampleCheckbox" color="primary">
					Sample Check box
				</mat-checkbox>
				<p></p>

				<!-- SLIDE TOGGLE -->
				<mat-label>slide-toggle</mat-label>
				<op-slide-toggle id="sampleSlideToggle"></op-slide-toggle>

				<p></p>
				<!-- DATE -->
				<div class="op-form-group">
					<div class="op-form-title">{{ t('PERSONAL_INFO.dateOfBirth') | opRequired }}</div>
					<op-date-input id="sampleDateInpute" formControlName="date" showFormat="ddmmyyyy" required></op-date-input>
					<div class="op-form-info">{{ t('PERSONAL_INFO.mustBe18') }}</div>
					<mat-error *ngIf="!formGroup.controls['date'].valid && formGroup.controls['date'].touched">
						required
					</mat-error>
				</div>

				<p></p>
				<op-increment-decrement
					[maxAmount]="110"
					[minAmount]="100"
					(increment)="increment()"
					(decrement)="decrement()"
					formControlName="amount"
				></op-increment-decrement>
			</form>
		</op-expansion-panel>

		<op-expansion-panel id="expansionId" [title]="'Expansion panel'" [expanded]="false">
			<div style="border: 1px solid grey; padding: 5px">
				<!-- op expansion panel (title) -->
				<div id="op-expansion-panel_title" class="op-mtb-20">
					<op-expansion-panel
						id="sampleOPExpansionPanelTitle"
						[title]="'op-expansion-panel (title) bold'"
						[titleBold]="true"
						[expanded]="false"
					>
						<span
							>Webservices solutions, "bleeding-edge e-commerce benchmark e-business weblogs; solutions virtual
							envisioneer customized e-commerce," communities. Synergize, back-end extend widgets: e-tailers widgets
							integrate whiteboard models leading-edge integrated proactive; deliverables infomediaries e-business
							aggregate e-markets engage bleeding-edge revolutionize enhance.</span
						>
					</op-expansion-panel>

					<op-expansion-panel
						id="sampleOPExpansionPanelTitle"
						[title]="'op-expansion-panel (title)'"
						[titleBold]="false"
						[expanded]="false"
					>
						<span
							>Webservices solutions, "bleeding-edge e-commerce benchmark e-business weblogs; solutions virtual
							envisioneer customized e-commerce," communities. Synergize, back-end extend widgets: e-tailers widgets
							integrate whiteboard models leading-edge integrated proactive; deliverables infomediaries e-business
							aggregate e-markets engage bleeding-edge revolutionize enhance.</span
						>
					</op-expansion-panel>
				</div>

				<!-- op expansion panel (no title) -->
				<div id="op-expansion-panel_no-title" class="op-mtb-20">
					<h4>op-expansion-panel (no title)</h4>
					<op-expansion-panel id="sampleOPExpansionPanelNoTitle">
						<span inline>
							Webservices solutions, "bleeding-edge e-commerce benchmark e-business weblogs; solutions virtual
							envisioneer customized e-commerce," communities. Synergize, back-end extend widgets: e-tailers widgets
							integrate whiteboard models leading-edge integrated proactive; deliverables infomediaries e-business
							aggregate e-markets engage bleeding-edge revolutionize enhance.
						</span>
					</op-expansion-panel>
				</div>
			</div>
		</op-expansion-panel>

		<op-expansion-panel id="typographyId" [title]="'Buttons'" [expanded]="false">
			<div class="buttons">
				<button mat-flat-button color="primary" type="submit">primary</button>
				<button mat-flat-button color="primary" name="submitBtn" [disabled]="formGroup.invalid" type="submit">
					primary disabled
				</button>

				<button mat-flat-button color="secondary" type="submit">secondary</button>
				<button mat-flat-button color="secondary" name="submitBtn" [disabled]="formGroup.invalid" type="submit">
					Secondary disabled
				</button>

				<div>
					<button mat-flat-button color="primary-small" type="submit">primary-small</button>
					<p></p>
					<button mat-flat-button color="primary-small" name="submitBtn" [disabled]="formGroup.invalid" type="submit">
						primary-small disabled
					</button>
					<p></p>

					<button mat-flat-button color="secondary-small" type="submit">secondary-small</button>
					<p></p>
					<button mat-flat-button color="secondary-small" name="submitBtn" [disabled]="formGroup.invalid" type="submit">
						Secondary-small disabled
					</button>
				</div>
			</div>
		</op-expansion-panel>

		<op-expansion-panel id="linkId" [title]="'Links'" [expanded]="true">
			<div class="buttons op-left">
				<button mat-flat-button color="op-link" type="button" [routerLink]="['/logIn']">op-link</button>

				<button mat-flat-button color="link" type="button" (click)="addIncome()">link</button>

				<button mat-flat-button color="link-bold" type="button" [routerLink]="['/logIn']">link-bold</button>

				<button mat-flat-button color="link-md" type="button">link-md</button>

				<button mat-flat-button color="link-no-dec" type="button" [routerLink]="['/logIn']">link-no-dec</button>

				<button mat-flat-button color="link-text" type="button">link-text</button>
				<button mat-flat-button color="link-text-b1" class="op-body-1-font" type="button">link-text-b1</button>
				<button mat-flat-button color="link-text-h3" class="op-header-3-font" type="button">link-text-h3</button>

				<op-horizontal-rule [text]="'or' | uppercase"></op-horizontal-rule>

				<a href="" onclick="return false" (click)="resendCode()">default</a>
				<a class="op-body-2" href="" onclick="return false">default op-body-2</a>
			</div>
		</op-expansion-panel>

		<op-expansion-panel id="colorId" [title]="'Colors'" [expanded]="false">
			<mat-grid-list class="op-label-font" cols="2">
				<div class="primary-black op-white">op-primary-black: #000000</div>
				<div class="primary-grey">op-primary-grey: #949494;</div>
				<div class="disabled-grey">op-disabled-grey: #E8E8E8;</div>
				<div class="disabled-dark-grey">op-disabled-dark-grey: #BFBFBF;</div>
				<div class="primary-green">op-primary-green: #6CDB8B;</div>
				<div class="green">op-green</div>
				<div class="secondary-green">op-secondary-green: #00C859;</div>
				<div class="dark-green">op-dark-green: #009337;</div>
				<div class="darker-green">op-darker-green: #007324;</div>
				<div class="light-green">op-light-green: #E6F9EB;</div>
				<div class="orange">op-orange: #FF7300;</div>
				<div class="red">op-red: #C63724;</div>
				<div class="light-blue">op-light-blue: #F5FAFF;</div>
				<div class="black op-white">op-black: #1A1A1A;</div>
				<div class="dark-grey">op-dark-grey: #6C6C6C;</div>
				<div class="medium-grey">op-medium-grey: #D8D8D8;</div>
				<div class="light-grey">op-light-grey: #F2F2F2;</div>
				<div class="blue">op-blue: #219BED;</div>
				<div class="purple op-white">op-purple: #2F167C;</div>
				<div class="violet op-white">op-violet: #070540;</div>
				<div class="medium-blue">op-medium-blue: #B9D1E3;</div>
			</mat-grid-list>
		</op-expansion-panel>

		<op-expansion-panel id="colorId" [title]="'Dialogs'" [expanded]="false">
			<mat-grid-list class="op-body-2-font" cols="1">
				<a mat-flat-button color="link-text" (click)="openDialog(1)"> message dialog </a>
				<p></p>
				<a mat-flat-button color="link-text" (click)="openDialog(2)"> error dialog </a>
				<p></p>
				<a mat-flat-button color="link-text" (click)="openDialog(3)"> find location </a>
				<p></p>
				<a mat-flat-button color="link-text" (click)="openDialog(4)"> contact us </a>
				<p></p>
			</mat-grid-list>
		</op-expansion-panel>
		<op-expansion-panel id="colorId" [title]="'Icons'" [expanded]="false">
			<div *ngFor="let icon of appIcons">
				<mat-icon svgIcon="{{ icon }}"></mat-icon>
				{{ icon }}
			</div>
		</op-expansion-panel>
	</op-content-container>
</div>
