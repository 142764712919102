<div class="op-expansion-panel op-mb-40" *transloco="let t">
	<op-expansion-panel
		[expanded]="expanded"
		id="{{ id + '_plaidBankFunds' }}"
		[titleBold]="true"
		[title]="t(isGCPOrReturn ? 'SELECT_BANK_ACCOUNT.usePrevBankAccount' : 'PLAID_BANK_ACCOUNT.useConnectedAccount')"
	>
		<div class="content">
			<op-select-bank-account
				id="{{ id + '_bankSelectAccount' }}"
				*ngIf="true"
				[filter]="bankFilterForVerified"
				[selectAndContinue]="true"
				(checkVerificationStatus)="bankVerificationComplete.emit($event)"
				[isGCPOrReturn]="isGCPOrReturn"
			>
			</op-select-bank-account>
		</div>
	</op-expansion-panel>
</div>
