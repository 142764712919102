import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty } from 'lodash';
import { startCase } from 'lodash/fp';
import { combineLatest, of, Subscription, zip } from 'rxjs';
import { catchError, filter, map, retry } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import {
	EventDataService,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { ApplicantStepCompleteEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IdentificationApiService } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';

import { addressSection } from './sections/address';
import { debtSection } from './sections/debt';
import { financesSection } from './sections/finances';
import { identificationSection } from './sections/identification';
import { incomeSection } from './sections/income';
import { loanPurposeSection } from './sections/loanPurpose';
import { personalInformationSection } from './sections/personalInformation';
import { SummarySection } from './sections/section.model';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Component({
	selector: 'op-summary',
	templateUrl: './summary.component.html',
	styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private metadataService: MetadataService,
		private routingService: RoutingService,
		private identificationApiService: IdentificationApiService,
		private tagDataService: TagDataService,
		private dateUtilService: DateUtilsService,
		private sessionStorageService: SessionStorageService,
		private eventDataService: EventDataService,
		private issuingPartnerService: IssuingPartnerService
	) {}

	summarySections: SummarySection[];

	initialized = false;

	private subscription = new Subscription();

	dropOffBeforeOffers: boolean = true;

	issuingOrganization: string;
	isMetaOrganization: boolean;

	ngOnInit(): void {
		const loanApp = this.loanAppService.getLoanApp();
		const fragment = this.route.snapshot.fragment;
		const vehicleOwnershipStatus = ApplicantUtils.fromLoanApp(loanApp)?.getVehicleOwnershipStatus();
		const hasVehicleOwnershipStatusValue = !isEmpty(vehicleOwnershipStatus);

		this.issuingOrganization = this.issuingPartnerService.lender;
		this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingOrganization);

		const hasVehicleOwnershipStatusValue$ = hasVehicleOwnershipStatusValue
			? this.metadataService.select(MetadataEnum.VehicleOwnershipType)
			: of(null);

		const sub = combineLatest([
			this.loanAppService.loanApplication$.pipe(
				filter(Boolean),
				catchError((error) => of({}))
			),
			this.mobileService.getIncome(loanApp.id).pipe(
				retry(1),
				catchError((error) => of([]))
			),
			this.identificationApiService.getIdentification(loanApp.id).pipe(
				retry(1),
				catchError((error) => of({}))
			),
			this.mobileService.getApplicant(loanApp.id).pipe(
				retry(1),
				catchError((error) => of({}))
			),
			this.mobileService.getAddresses(loanApp.id).pipe(
				retry(1),
				catchError((error) => of([]))
			),
			this.mobileService.getPhone(loanApp.id).pipe(
				retry(1),
				catchError((error) => of({}))
			),
			this.mobileService.getFinances(loanApp.id).pipe(
				retry(1),
				catchError((error) => of({}))
			),
			this.mobileService.getDebt(loanApp.id).pipe(
				retry(1),
				catchError((error) => of([]))
			),
			zip(
				this.metadataService.select(MetadataEnum.Language),
				this.metadataService.select(MetadataEnum.IdentificationType),
				this.metadataService.select(MetadataEnum.USState),
				this.metadataService.select(MetadataEnum.Country),
				this.metadataService.select(MetadataEnum.PhoneType),
				this.metadataService.select(MetadataEnum.DateRange),
				this.metadataService.select(MetadataEnum.LoanPurpose),
				this.metadataService.select(MetadataEnum.HousingType),
				this.metadataService.select(MetadataEnum.CheckCashingFee),
				this.metadataService.select(MetadataEnum.IndustryType),
				this.metadataService.select(MetadataEnum.IncomeSourceType),
				this.metadataService.select(MetadataEnum.IncomeFrequency),
				this.metadataService.select(MetadataEnum.DebtTypeWithCreditHit),
				this.metadataService.select(MetadataEnum.DebtType),
				this.metadataService.select(MetadataEnum.PersonalNameSuffix)
			),
			hasVehicleOwnershipStatusValue$
		]).subscribe({
			next: ([
				getLoan,
				getIncome,
				getId,
				getApplicant,
				getAddresses,
				getPhone,
				getFinances,
				getDebt,
				[
					getLanguage,
					getIdType,
					getUSState,
					getCountry,
					getPhoneType,
					getDataRange,
					getLoanPurpose,
					getHousingType,
					getCheckCashingFee,
					getIndustryType,
					getIncomeSourceType,
					getIncomeFrequency,
					getDebtTypeWithCreditHit,
					getDebtType,
					getPersonalNameSuffix
				],
				getVehicleOwnershipType
			]: any) => {
				const applicant = ApplicantUtils.fromLoanApp(getLoan);
				const debtType = applicant.isCreditRunSuccess() ? getDebtTypeWithCreditHit : getDebtType;

				const yes = startCase(this.translocoService.translate('GLOBAL.yes'));
				const no = startCase(this.translocoService.translate('GLOBAL.no'));

				this.summarySections = [
					personalInformationSection(
						getApplicant,
						getId,
						getLanguage,
						PhoneUtils.fromPhoneResult(getPhone),
						getPhoneType,
						getDataRange,
						yes,
						no
					),
					loanPurposeSection(getLoan, getLoanPurpose),
					addressSection(
						AddressUtils.getHomeAddress(getAddresses),
						AddressUtils.getMailAddress(getAddresses),
						this.translocoService.translate('SUMMARY.sameAsAbove')
					),
					financesSection(getFinances, getCheckCashingFee, yes, no),
					identificationSection(
						getApplicant,
						getId,
						getIdType,
						getCountry,
						getUSState,
						this.translocoService.translate('IDENTIFICATION.dontHave') /* cspell: disable-line */
					),

					debtSection(
						getAddresses,
						getDebt,
						debtType,
						this.translocoService.translate('SUMMARY.amount'),
						this.translocoService.translate('SUMMARY.monthlyPayment'),
						this.isMetaOrganization
					),
					incomeSection(
						getIncome,
						getFinances,
						getIncomeSourceType,
						getIncomeFrequency,
						this.translocoService.translate('SUMMARY.paid'),
						this.translocoService.translate('SUMMARY.started'),
						this.dateUtilService.getCurrentLocale()
					)
				];

				if (fragment) {
					setTimeout(() => {
						this.scroll(fragment);
					}, 250);
				}
				this.tagDataService.view({
					tealium_event: 'summary',
					product_sub_category: this.sessionStorageService.get('productCategorySelection'),
					product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
						this.loanAppService.getLoanApp().productOfferDetails
					)
				});
				this.initialized = true;
			}
		});
		this.subscription.add(sub);
	}

	ngOnDestroy(): void {
		if (this.dropOffBeforeOffers) {
			const plaidEvent = {
				eventType: EventDataTagTypeEnum.offerEventType,
				value1: EventDataTagValueEnum.offerValue
			};
			const tagEvent = {
				tealium_event: EventDataTagTypeEnum.offerEventType,
				event_action: EventDataTagValueEnum.offerValue
			};
			this.tagDataService.link({}, tagEvent);
			this.eventDataService.logEventDataToLoanApplication(plaidEvent);
		}
		this.subscription.unsubscribe();
	}

	updateDropUserStatus(): void {
		this.dropOffBeforeOffers = false;
	}

	scroll(id: string): void {
		const element = document.getElementById(id);
		element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}

	private tagProcessComplete() {
		this.tagDataService.link(
			{},
			{
				tealium_event: 'pre_approval_process_complete',
				event_action: 'submit',
				event_label: 'Form',
				event_category: 'rx_form',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			}
		);
	}

	onSubmit(): void {
		if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification)) {
			this.dropOffBeforeOffers = false;
			this.routingService.route(RoutingPathsEnum.identityConfirm, { queryParams: { returnToSummary: true } });
		} else {
			this.mobileService
				.updateDataComplete(this.loanAppService.loanApplicationId)
				.pipe(map((updatedLoanApp: ILoanApplication) => updatedLoanApp?.applicationStatus))
				.subscribe({
					next: (applicationStatus) => {
						if (applicationStatus) {
							this.sessionStorageService.remove(STORAGE_KEYS.PARTNER_REFERRAL);
							this.tagProcessComplete();
							this.dropOffBeforeOffers = false;
							if (
								ApplicationStatusEnum.started === applicationStatus ||
								ApplicationStatusEnum.notApproved === applicationStatus
							) {
								this.routingService.routeByStatus({ queryParams: { returnToSummary: true } });
							} else {
								this.routingService.route(RoutingPathsEnum.offerStatus);
							}
						}
					}
				});
		}
	}
}
