import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-reference-add',
	templateUrl: './reference-add.component.html',
	styleUrls: ['./reference-add.component.scss']
})
export class ReferenceAddComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	parent: FormGroup;

	@Input()
	index: number;

	@Input()
	relationshipTypeList: Array<IMetadata>;

	@Input()
	languageList: Array<IMetadata>;

	@Input()
	suffixList: Array<IMetadata>;

	@Output()
	save = new EventEmitter<any>();

	@Output()
	delete = new EventEmitter<any>();

	@Output()
	opened = new EventEmitter<any>();

	@Output()
	closed = new EventEmitter<any>();

	originalValue;

	ngOnInit(): void {
		this.originalValue = Object.assign({}, this.parent.value);
	}

	restoreOriginalValue(restore: boolean): void {
		if (!restore) {
			this.originalValue = Object.assign({}, this.parent.value);
		} else {
			this.parent.setValue(this.originalValue);
		}
	}

	saveReference(reference: FormGroup): void {
		this.save.emit(reference);
	}

	deleteReference(reference: FormGroup, index: number): void {
		this.delete.emit({ reference, index });
	}

	panelOpened(reference: FormGroup): void {
		this.opened.emit(reference);
	}

	panelClosed(reference: FormGroup): void {
		this.closed.emit(reference);
	}
}
