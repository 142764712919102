<div class="vin-search" *transloco="let t">
	<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<div class="op-input-field op-mb-20">
			<div class="op-body-1-bold-font op-mb-10">{{ t('VEHICLE.vinLabelText') }}</div>
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('VEHICLE.vin') }}</mat-label>
				<input matInput type="text" name="vin" id="vin" formControlName="vin" maxlength="17" minlength="17" />
				<mat-error id="vinError" *ngIf="!formGroup.controls['vin'].valid && formGroup.controls['vin'].touched">
					{{ getVinError() }}
				</mat-error>
				<mat-hint>{{ t('VEHICLE.vinMsgText') }}</mat-hint>
			</mat-form-field>
		</div>
		<button
			mat-flat-button
			color="primary"
			type="submit"
			id="vinSearchBtn"
			[disabled]="formGroup.invalid || !enableVinSearch || formGroup.controls['vin'].value === null"
			[opTagClick]="'Search VIN'"
		>
			{{ t('VEHICLE.searchVin') }}
		</button>
	</form>
</div>
