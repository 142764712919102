<div class="processing-display" *transloco="let t">
	<div class="single-file-container progress">
		<div></div>
		<div>
			<div class="file-name op-light-black progress op-micro-font">
				{{ singleFile?.name }}
			</div>

			<div class="op-mt-10" id="{{ id + '_progress_bar' }}">
				<mat-progress-bar class="file-progress" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
				<div class="op-mt-10 op-label-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processingDocument') }}</div>
			</div>
		</div>
	</div>
</div>
