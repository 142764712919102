import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { VerificationStatusEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ISetAchBankAccountResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-change-disbursement-option',
	templateUrl: './change-disbursement-option.component.html',
	styleUrls: ['./change-disbursement-option.component.scss']
})
export class ChangeDisbursementOptionComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	isOnlineACHOption: boolean;

	@Input()
	isOnlineCheckOption: boolean;

	@Input()
	isPartnerCashDisbursement: boolean;

	@Input()
	isPartnerApplication: boolean;

	@Input()
	showMetaBankMessage: boolean;

	@Input()
	isCheckOnly: boolean;

	@Input()
	partnerBrand: string;

	@Output()
	saved = new EventEmitter<ISetAchBankAccountResult>();

	@Output()
	notSaved = new EventEmitter<any>();

	@Output()
	findLocation = new EventEmitter<any>();

	constructor(
		private formBuilder: FormBuilder,
		private bankAccountService: AchBankAccountsService,
		private mobileApiService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {
		this.createForm(formBuilder);
	}
	formGroup: FormGroup;

	get formControls(): any {
		return this.formGroup.controls;
	}
	get accountList(): FormArray {
		return this.formControls.accounts as FormArray;
	}

	private subscription = new Subscription();
	currentlySelection: number;
	isAccountSelected: boolean;
	showAccountList: boolean;
	showBankSection: boolean;
	showAddBank: boolean;

	ngOnInit(): void {
		const bankSub = this.bankAccountService.bankAccounts$.pipe(filter((r) => Boolean(r))).subscribe({
			next: (bankAccounts: IAchBankAccount[]) => {
				this.accountList.clear();

				const filteredBankAccounts = bankAccounts.filter(
					(bankAccount) =>
						bankAccount.verificationStatus === VerificationStatusEnum.verified &&
						bankAccount.status === AccountStatusEnum.active
				);

				if (filteredBankAccounts?.length > 0) {
					this.showAccountList = true;
					filteredBankAccounts.forEach((item) => {
						if (item.selected) {
							this.currentlySelection = item.id;
							this.formGroup.get('selected').setValue(item.id);
							this.isAccountSelected = true;
						}
						this.increaseAccount(item);
					});
					if (!this.isAccountSelected) {
						this.currentlySelection = filteredBankAccounts[0].id;
						this.formGroup.get('selected').setValue(this.currentlySelection);
					}
				}
			}
		});
		this.subscription.add(bankSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			selected: [null, []],
			accounts: new FormArray([])
		});
	}

	completeOnline(): void {
		this.showBankSection = true;
		this.showAddBank = true;
	}

	verified(post: any): void {
		this.saved.emit(post);
	}

	notVerified(post: any): void {
		this.notSaved.emit(post);
	}

	onFindLocation(): void {
		this.findLocation.emit();
	}

	createFormAccount(account: IAchBankAccount): FormGroup {
		return this.formBuilder.group({
			id: [account.id],
			selected: [account.selected || false],
			accountType: [account.accountType],
			bankName: [account.bankName],
			details: [this.getAccountDetails(account)],
			verificationStatus: [account.verificationStatus],
			routingNumber: [account.routingNumber],
			accountNumber: [account.accountNumber],
			status: [account.status]
		});
	}

	increaseAccount(account: IAchBankAccount): void {
		this.accountList.push(this.createFormAccount(account));
	}

	getAccountDetails(account: IAchBankAccount): string {
		return `${account.accountType} ${account.accountNumber}`;
	}
}
