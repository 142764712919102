<div class="esign" *transloco="let t" [ngSwitch]="page">
	<op-esign-how-to-print-save-loan *ngSwitchCase="'how-to-print-save-loan'"> </op-esign-how-to-print-save-loan>

	<op-esign-show-error *ngSwitchCase="'show-error'"> </op-esign-show-error>

	<op-esign-ping-listener *ngSwitchCase="'ping-listener'"> </op-esign-ping-listener>

	<div *ngSwitchCase="'event'" [ngSwitch]="event">
		<op-esign-event-signing-canceled *ngSwitchCase="'signing-canceled'"> </op-esign-event-signing-canceled>

		<op-esign-event-signing-declined *ngSwitchCase="'signing-declined'"> </op-esign-event-signing-declined>

		<op-esign-event-signing-failed *ngSwitchCase="'signing-failed'"> </op-esign-event-signing-failed>

		<op-esign-event-signing-timeout *ngSwitchCase="'signing-timeout'"> </op-esign-event-signing-timeout>

		<op-esign-event-signing-completed *ngSwitchCase="'signing-completed'"> </op-esign-event-signing-completed>

		<op-esign-event-viewing-completed *ngSwitchCase="'viewing-completed'"> </op-esign-event-viewing-completed>
	</div>
</div>
