<div class="create-account" *transloco="let t">
	<op-masthead *ngIf="accountCreated && initialized" [title]="t('CREATE_ACCOUNT.accountCreated')"></op-masthead>
	<op-masthead *ngIf="!accountCreated && initialized" [title]="t('CREATE_ACCOUNT.provideEmail')"></op-masthead>

	<op-content-container *ngIf="initialized" class="content">
		<form *ngIf="!accountCreated" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="op-full-width" appearance="outline">
				<mat-label>{{ t('CREATE_ACCOUNT.provideEmail') }} </mat-label>
				<input
					matInput
					type="text"
					name="email"
					id="email"
					formControlName="email"
					[required]="!accountCreated"
					email
				/>
				<mat-error id="emailError" *ngIf="!formGroup.controls['email'].valid && formGroup.controls['email'].touched">
					{{ getEmailError() }}
				</mat-error>
			</mat-form-field>

			<div *ngIf="!isEmailRequired && ccasUser?.emailAddress">
				<p [innerHTML]="emailMask"></p>
				<p [innerHTML]="t('CREATE_ACCOUNT.accountCreateReminder')"></p>
			</div>

			<button
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="submitBtn"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('GLOBAL.create') }}
			</button>
		</form>

		<div *ngIf="accountCreated">
			<p [innerHTML]="t('CREATE_ACCOUNT.accountCreatedNote', { email: emailMask })"></p>
			<p id="passwordResetLink" [innerHTML]="t('CREATE_ACCOUNT.resetPassword', { url: passwordResetLink })"></p>
		</div>
	</op-content-container>
</div>
