import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-funds-disbursement-selection',
	templateUrl: './funds-disbursement-selection.component.html',
	styleUrls: ['./funds-disbursement-selection.component.scss']
})
export class FundsDisbursementSelectionComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	checkAtStore: boolean = false;

	@Input()
	issuingOrganization: string = '';

	@Output() next = new EventEmitter();

	formGroup: FormGroup;
	private subscription = new Subscription();
	bankAccountList$: Observable<IAchBankAccount[]>;
	returningBankAccountList$: Observable<IAchBankAccount[]>;

	verifiedAccounts: IAchBankAccount[];
	activeAccounts: IAchBankAccount[];

	constructor(private formBuilder: FormBuilder, private bankAccountService: AchBankAccountsService) {
		this.createForm(formBuilder);
	}

	ngOnInit(): void {
		this.bankAccountList$ = this.bankAccountService.bankAccounts$;
		this.returningBankAccountList$ = this.bankAccountService.returningBankAccounts$;
		const bankSub = combineLatest([this.bankAccountList$, this.returningBankAccountList$])
			.pipe(map(([rsp, returningRsp]) => rsp?.concat(returningRsp) || []))
			.subscribe({
				next: (accountList) => {
					this.accountList.clear();
					accountList.forEach((account) => {
						if (account.selected) {
							this.formGroup.get('selectedId').setValue(account.id);
						}
						this.accountList.push(this.createFormAccount(account));
					});
					this.verifiedAccounts = accountList.filter((a) => a?.verificationStatus === AccountStatusEnum.verified);
					this.activeAccounts = accountList.filter((a) => a?.verificationStatus !== AccountStatusEnum.verified);
				}
			});
		this.subscription.add(bankSub);
	}

	get formControls(): any {
		return this.formGroup.controls;
	}
	get accountList(): FormArray {
		return this.formControls.accounts as FormArray;
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			selectedId: [null, [Validators.required]],
			accounts: new FormArray([])
		});
	}

	createFormAccount(account: IAchBankAccount): FormGroup {
		return this.formBuilder.group({
			id: [account.id],
			selected: [account.selected || false],
			accountType: [account.accountType],
			bankName: [account.bankName],
			details: [this.getAccountDetails(account)],
			verificationStatus: [account.verificationStatus],
			routingNumber: [account.routingNumber],
			accountNumber: [account.accountNumber],
			status: [account.status]
		});
	}

	getAccountDetails(account: IAchBankAccount): string {
		return `${account.accountType} ${account.accountNumber}`;
	}

	getSelectedAccount(post: any): IAchBankAccount {
		return post.accounts.find((item) => item.id === post.selectedId);
	}

	onSubmit(post: any): void {
		const selectedId = post.selectedId;
		if (selectedId !== 'check') {
			const selectedAccount = this.getSelectedAccount(post);
			this.next.emit(selectedAccount);
		} else {
			this.next.emit({ id: 'check' });
		}
	}
}
