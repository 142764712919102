import { Component, Input } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-identification',
	templateUrl: './section-identification.component.html',
	styleUrls: ['./section-identification.component.scss']
})
export class SectionIdentificationComponent extends ExpandSectionComponent {
	constructor() {
		super();
	}

	@Input()
	idType: any;
}
