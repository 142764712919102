<div class="how-it-works" *transloco="let t">
	<op-masthead [title]="t('HOW_IT_WORKS.title')"></op-masthead>
	<op-content-container class="content">
		<div class="steps" *ngFor="let step of steps; let i = index">
			<op-steps-headline
				id="{{ 'step_' + i }}"
				[stepIcon]="step.icon"
				[headline]="step.headlines"
				[info]="step.info"
				[listOfIds]="step.moreInfo"
				[idTypes]="idTypes$ | async"
				[showInfo]="step.showInfo"
				[showMoreInfo]="step.showMoreInfo"
				(expanded)="step.showInfo = $event"
				(expandedShowMore)="step.showMoreInfo = $event"
				aria-labelledby="step-headline-label steps-info"
			>
			</op-steps-headline>
		</div>

		<button mat-flat-button routerLink="/home" id="applyBtn" color="primary" name="applyBtn">
			{{ t('HOW_IT_WORKS.apply') }}
		</button>
		<p></p>

		<div aria-hidden="true">
			<p>
				<small><sup>1</sup> {{ t('HOW_IT_WORKS.super1') }}</small>
			</p>
			<p>
				<small><sup>2</sup> {{ t('HOW_IT_WORKS.super2') }}</small>
			</p>
		</div>
		<p></p>

		<div>
			<div class="op-center" aria-hidden="true">
				<h4>
					<strong>{{ t('HOW_IT_WORKS.dataProtected') | uppercase }}</strong>
				</h4>
				<p>{{ t('HOW_IT_WORKS.encrypted') }}</p>
			</div>
		</div>
	</op-content-container>
</div>
