import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { SharedModule } from '../shared/shared.module';
import { ApprovedLoanAdditionalInfoPanelsComponent } from './offer-status/approved-loan-additional-info-panels/approved-loan-additional-info-panels.component';
import { OfferStatusComponent } from './offer-status/offer-status.component';

import { ProductOfferCardComponent } from './offer-status/product-offer-card/product-offer-card.component';
import { SecuredPersonalLoanApprovedComponent } from './offer-status/secured-personal-loan-approved/secured-personal-loan-approved.component';
import { SecuredPersonalLoanEligibleComponent } from './offer-status/secured-personal-loan-eligible/secured-personal-loan-eligible.component';
import { SecuredPersonalLoanPreApprovedComponent } from './offer-status/secured-personal-loan-pre-approved/secured-personal-loan-pre-approved.component';
import { UnsecuredPersonalLoanApprovedComponent } from './offer-status/unsecured-personal-loan-approved/unsecured-personal-loan-approved.component';
import { UnsecuredPersonalLoanPreApprovedComponent } from './offer-status/unsecured-personal-loan-pre-approved/unsecured-personal-loan-pre-approved.component';
import { LoanBenefitsComponent } from './loan-benefits/loan-benefits.component';
import { StatusRoutingModule } from './status-routing.module';
import { ApprovedComponent } from './status/approved/approved.component';
import { CoAppOfferedComponent } from './status/co-app-offered/co-app-offered.component';
import { NotApprovedComponent } from './status/not-approved/not-approved.component';
import { OtherComponent } from './status/other/other.component';
import { PreApprovedComponent } from './status/pre-approved/pre-approved.component';
import { RequiresAttentionComponent } from './status/requires-attention/requires-attention.component';
import { StatusComponent } from './status/status.component';
import { SubmittedComponent } from './status/submitted/submitted.component';
import { SecuredPersonalLoanEligiblePWComponent } from './offer-status/secured-personal-loan-eligible-pw/secured-personal-loan-eligible-pw.component';
import { NonProcessableComponent } from './status/non-processable/non-processable.component';

/**
 * Status Pages
 *
 * @export
 * @class StatusModule
 */
@NgModule({
	declarations: [
		ApprovedComponent,
		ApprovedLoanAdditionalInfoPanelsComponent,
		CoAppOfferedComponent,
		OfferStatusComponent,
		LoanBenefitsComponent,
		NotApprovedComponent,
		NonProcessableComponent,
		OtherComponent,
		PreApprovedComponent,
		ProductOfferCardComponent,
		RequiresAttentionComponent,
		SecuredPersonalLoanApprovedComponent,
		SecuredPersonalLoanEligibleComponent,
		SecuredPersonalLoanEligiblePWComponent,
		SecuredPersonalLoanPreApprovedComponent,
		StatusComponent,
		SubmittedComponent,
		UnsecuredPersonalLoanApprovedComponent,
		UnsecuredPersonalLoanPreApprovedComponent
	],
	imports: [CommonModule, SharedModule, TranslocoModule, StatusRoutingModule]
})
export class StatusModule {}
