import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { th } from 'date-fns/locale';
import { find, findIndex, size } from 'lodash';
import { catchError, first, of, tap, map, timeout, timer, Subscription, takeUntil, Subject } from 'rxjs';
import {
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { FileStatusEnum } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { AutoVerificationComponent } from '../auto-verification.component';
import { AutoVerificationService, IFileUploadExtended } from '../auto-verification.service';

interface IProgressData {
	id: string;
	progress: number;
	startTime: number;
	fileProgress: number;
	takingToLong: boolean;
	continueAvailable: boolean;
	endTime: number;
	timerSub: Subscription;
	endTimer$?: Subject<void>;
}

interface IProgressDataMap {
	[type: string]: IProgressData;
}

@Component({
	selector: 'op-document-v-thumbnails',
	templateUrl: './document-thumbnails.component.html',
	styleUrls: ['./document-thumbnails.component.scss']
})
export class DocumentVThumbnailsComponent implements OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	_files: Array<IFileUploadExtended>;

	@Input()
	get files(): Array<IFileUploadExtended> {
		return this._files;
	}
	set files(value: Array<IFileUploadExtended>) {
		this._files = this.sortList(value);
	}

	private readonly subscription = new Subscription();

	public fileStatusEnum: typeof FileStatusEnum = FileStatusEnum;
	public fileUploadType: typeof FileUploadType = FileUploadType;
	public fileUploadStatusEnum: typeof FileUploadStatusEnum = FileUploadStatusEnum;

	constructor(private autoVerificationService: AutoVerificationService) {}

	trackBy(index: number, item: IFileUploadExtended): string {
		return item?.ocrInfo?.documentUploadId;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private sortList(list: IFileUploadExtended[]): IFileUploadExtended[] {
		const isReview = (f) => f.ocrInfo?.documentStatus === FileStatusEnum.review;
		list?.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1)) || [];
		// list?.sort((a, b) => {
		// 	if (isReview(a) && !isReview(b)) {
		// 		return -1;
		// 	} else if (!isReview(a) && isReview(b)) {
		// 		return 1;
		// 	} else {
		// 		return 0;
		// 	}
		// });
		return list;
	}
}
