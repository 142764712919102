import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ApplicationStatusEnum } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { SecuredOfferResponseEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-secured-personal',
	templateUrl: './secured-personal.component.html',
	styleUrls: ['./secured-personal.component.scss']
})
export class SecuredPersonalComponent {
	constructor(
		private mobileService: MobileApiService,
		private authenticationService: AuthenticationService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private dialogService: DialogService
	) {}

	setSecuredOffer(action: string, canProceedOnError: boolean, next: () => {}): void {
		const offerAction = {
			securedAppOfferAction: action
		};
		this.mobileService.setSecuredAppOffer(offerAction, this.loanAppService.loanApplicationId).subscribe({
			complete: () => {
				next();
			},
			error: (err) => {
				canProceedOnError ? next() : this.dialogService.openErrorDialog(err).subscribe();
			}
		});
	}

	acceptSecuredOffer(): void {
		if (this.loanAppService.applicationStatus === ApplicationStatusEnum.securedAccepted) {
			this.routeToNextRoute(RoutingPathsEnum.vehicle);
		} else {
			this.setSecuredOffer(
				SecuredOfferResponseEnum.accept,
				false,
				this.routeToNextRoute.bind(this, RoutingPathsEnum.vehicle)
			);
		}
	}

	talkToAgent(): void {
		this.setSecuredOffer(SecuredOfferResponseEnum.undecided, true, this.callUs.bind(this));
	}

	callUs(): void {
		this.dialogService.openContactUsDialog(Boolean(this.loanAppService.loanApplicationId)).subscribe();
	}

	routeToNextRoute(nextRoute: RoutingPathsEnum): void {
		this.routingService.route(nextRoute);
	}
}
