import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

/**
 * expansion panel for document-submit sections.
 *
 * @export
 * @class DocumentExpansionPanelComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-document-expansion-panel',
	templateUrl: './document-expansion-panel.component.html',
	styleUrls: ['./document-expansion-panel.component.scss']
})
export class DocumentExpansionPanelComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	title: string;

	@Input()
	notDisplayCount: boolean;

	@Input()
	expanded: boolean;

	@Input()
	fileList: IFileUpload[];

	@Input()
	isVerified: boolean;

	@Output()
	opened = new EventEmitter<FileUploadType>();

	@Output()
	closed = new EventEmitter<FileUploadType>();

	isExpansionDisabled(): string {
		if (this.isVerified) {
			return 'disabled-pointer';
		}
		return '';
	}
}
