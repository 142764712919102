import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { format } from 'date-fns';
import { upperFirst } from 'lodash';
import { take } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { IFindApplicationResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';
import { MessageDialogComponent } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { lastNameValidator, phoneValidator, zipCodeValidator } from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-find-your-application',
	templateUrl: './find-your-application.component.html',
	styleUrls: ['./find-your-application.component.scss']
})
export class FindYourApplicationComponent {
	formGroup: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private landingService: LandingService,
		private dateUtilService: DateUtilsService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private nidService: NeuroIdService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService
	) {
		this.createForm(this.formBuilder);
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10), zipCodeValidator()]],
			lastName: ['', [Validators.required, lastNameValidator(), Validators.minLength(2), Validators.maxLength(32)]],
			date: [null, Validators.required],
			phoneNumber: ['', [Validators.required, phoneValidator()]]
		});
	}

	openErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.title'),
				this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.message')
			)
			.subscribe();
	}

	processRecentNotApproved(findResult: IFindApplicationResult): void {
		const formatted_updatedAt = upperFirst(
			this.dateUtilService.format(this.dateUtilService.parseISODateTime(findResult.updatedAt), 'MMM dd, yyyy')
		);
		const formatted_newApplicationStartDate = upperFirst(
			this.dateUtilService.format(
				this.dateUtilService.parseISODateTime(findResult.newApplicationStartDate),
				'MMM dd, yyyy'
			)
		);
		const title = this.translocoService.translate('FIND_YOUR_APPLICATION.endOfFlowPromptTitle', {
			date: formatted_updatedAt
		});
		const footerMessage = this.translocoService.translate('FIND_YOUR_APPLICATION.endOfFlowFooterMsg');
		const message =
			this.translocoService.translate('FIND_YOUR_APPLICATION.reApplyAfter', {
				date: formatted_newApplicationStartDate
			}) +
			'<p><p>' +
			footerMessage;
		const data = {
			title,
			message
		};
		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		dialogRef.afterClosed().pipe(take(1)).subscribe();
	}

	onSubmit(post: any): void {
		const { date, phoneNumber, ...body } = post;
		body.dateOfBirth = format(date, 'yyyy-MM-dd');
		body.phoneNumber = PhoneUtils.formatPhoneNumber(phoneNumber);
		let eventLabel = 'not_found';

		this.landingService.searchApplication(body).subscribe({
			next: (resp) => {
				if (resp.hasRecentNotApprovedApp) {
					this.processRecentNotApproved(resp);
					eventLabel = 'recent_not_approved';
				} else if (
					resp.inProgressApplication === true ||
					resp.hasActiveLoan === true ||
					(resp.returningCustomer && resp.token)
				) {
					this.sessionStorageService.set('returning', resp);
					this.sessionStorageService.set('applicationFlow', resp.applicationFlow);
					this.routingService.route(RoutingPathsEnum.multifactor);
					if (resp.inProgressApplication) {
						eventLabel = 'in_progress_loanapp';
					} else if (resp.hasActiveLoan) {
						eventLabel = 'has_active_loan';
					} else {
						eventLabel = 'returning_customer';
					}

					this.nidService.setNid('setVariable', 'loan_type', resp.returningCustomer ? 'returning' : 'new');
				} else {
					this.openErrorMessage();
				}

				this.tagDataService.link(
					{
						phoneNumber: resp.phoneNumber,
						finder_number_zip_code: body.zipCode
					},
					{
						tealium_event: 'find_your_application',
						event_category: 'CONSUMER_INSTALLMENT_LOAN',
						event_label: eventLabel
					}
				);
			},
			error: () => {
				this.openErrorMessage();
			}
		});
	}
}
