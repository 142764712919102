<div class="references" *transloco="let t">
	<op-masthead [title]="t('REFERENCES.title')">
		<div class="op-body-2-bold-font" [innerHTML]="t('REFERENCES.pleaseProvideToHelp')"></div>
		<p></p>
		<div class="op-label-font" [innerHTML]="t('REFERENCES.inTheFuture')"></div>
	</op-masthead>

	<div class="op-label-font op-gray op-mt-20 op-mb-20" *ngIf="editing">{{ t('REFERENCES.requiredFields') }}</div>
	<p></p>
	<op-content-container class="content">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-accordion>
				<div *ngFor="let reference of referenceList.controls; let i = index">
					<div [formGroup]="reference">
						<op-reference-add
							[parent]="reference"
							[index]="i + 1"
							id="{{ 'reference_' + i }}"
							[relationshipTypeList]="relationshipTypeList"
							[languageList]="toggleLanguageOptions"
							[suffixList]="suffixList"
							(save)="saveReference($event)"
							(delete)="deleteReference($event)"
							(opened)="panelOpened($event)"
							(closed)="panelClosed($event)"
						></op-reference-add>
					</div>
				</div>
			</mat-accordion>

			<p></p>
			<div
				id="referencesRequired"
				class="op-icon-align-body-2"
				*ngIf="this.referencesRequired > getSavedReferenceCount()"
			>
				<mat-icon svgIcon="op-alert"></mat-icon>
				{{ t('REFERENCES.pleaseProvide', { count: this.referencesRequired }) }}
			</div>

			<div
				id="referenceCount"
				class="op-icon-align-body-2-green"
				*ngIf="this.referencesRequired <= getSavedReferenceCount()"
			>
				<mat-icon svgIcon="op-checkMark-outline"></mat-icon>
				{{ t('REFERENCES.totalProvided', { count: this.referencesRequired }) }}
			</div>

			<button
				class="buttons"
				*ngIf="!editing && hasReferences()"
				mat-flat-button
				color="secondary"
				name="addReference"
				id="addReferenceBtn"
				type="button"
				(click)="addReference()"
			>
				{{ t('REFERENCES.add') }}
			</button>
			<button
				class="buttons"
				*ngIf="editing"
				mat-flat-button
				color="primary"
				name="saveReference"
				id="saveReferenceBtn"
				type="button"
				[disabled]="getExpandedReference().invalid"
				(click)="saveReference()"
			>
				{{ t('REFERENCES.save') }}
			</button>
			<button
				class="buttons"
				*ngIf="!editing"
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="submitBtn"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('GLOBAL.next') }}
			</button>
		</form>
	</op-content-container>
</div>
