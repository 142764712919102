import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface INavObject {
	icon?: string;
	title: string;
	link?: string;
	tagClick?: string;
	fn?: () => void;
}

/**
 * Side nav display.
 *
 * @export
 * @class SideNavComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-side-nav',
	templateUrl: './side-nav.component.html',
	styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
	@Output() sidenavClose = new EventEmitter();
	@Input() navItems: INavObject;

	@Input() navFooterItems: INavObject;

	public onSidenavClose(fn: () => void = null): void {
		fn && fn();
		this.sidenavClose.emit();
	}
}
