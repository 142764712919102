<div class="status-pre-approved" *transloco="let t">
	<div id="congratsBannerDiv" *ngIf="!showPreApproveAmount">
		<div id="congratsCenterBannerDiv" class="center">
			<h1>
				<div id="pre-approved-label" *ngIf="!isSoftPullEnabled">{{ t('STATUS.PRE_APPROVED.congrats') }}</div>
				<div id="pre-approved-label" *ngIf="isSoftPullEnabled">{{ t('SOFT_PULL.congratulations') }}</div>
			</h1>

			<h3>
				<div id="pre-approved-message" *ngIf="!isSoftPullEnabled">{{ t('STATUS.PRE_APPROVED.youArePreApproved') }}</div>
				<div id="pre-approved-message" *ngIf="isSoftPullEnabled">{{ t('SOFT_PULL.preApproved') }}</div>
			</h3>

			<button
				mat-flat-button
				color="primary"
				name="referenceBtn"
				id="preApprovedAddRefBtn"
				*ngIf="!isReferenceComplete"
				[opTagClick]="'status_preApproved addReferences'"
				(click)="addReferences()"
			>
				{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.addReferences') }}
			</button>

			<button
				mat-flat-button
				color="blue"
				name="submitBtn"
				id="preApprovedSubmitBtn"
				*ngIf="isReferenceComplete"
				[opTagClick]="'status_approved nextSteps'"
				(click)="submit()"
			>
				{{ t('GLOBAL.nextSteps') }}
			</button>

			<h5 id="credit-score-notice" *ngIf="isSoftPullEnabled">
				{{ t('SOFT_PULL.notImpactCreditScore') }}
			</h5>

			<img id="softpullIcon" src="/assets/images/icon-soft-pull.png" alt="" *ngIf="isSoftPullEnabled" />
		</div>
	</div>
	<div id="verifyOuterDiv" class="almost-done max-width-container" *ngIf="!showPreApproveAmount">
		<div id="verifyInnerDiv" class="max-width-content text-left">
			<h3>
				<strong>{{ t('STATUS.PRE_APPROVED.almostDoneTitle') }}</strong>
			</h3>

			<div id="verify-info">{{ t('STATUS.PRE_APPROVED.verifyInfo') }}</div>
			<div id="final-review" *ngIf="isSoftPullEnabled">
				{{ t('SOFT_PULL.finalReview') }}
			</div>
			<div id="almost-done" *ngIf="!isSoftPullEnabled">
				{{ t('STATUS.PRE_APPROVED.almostDone') }}
			</div>
			<p></p>
		</div>
	</div>
	<!---------------------------------------------------------------------------------->
	<div id="preApprovedAmountDiv" *ngIf="showPreApproveAmount" id="pre-approved-new">
		<div id="preApprovedAmountCongratsDiv" class="center">
			<img alt="" src="/assets/images/prescreen_getPrequalified.svg" />
			<h1>
				<div id="pre-approved-label">{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.congrats') }}</div>
				{{ applicant?.firstName }} !
				<p class="op-green-divider"></p>
			</h1>
		</div>

		<h2 class="center">
			<div id="pre-approved-message" [innerHTML]="t('STATUS.PRE_APPROVED_SHOW_AMOUNT.youArePreApproved')"></div>
		</h2>

		<mat-card id="preApprovedUptoAmountCard">
			<mat-card-content id="preApprovedUptoAmountCardContent">
				<h4>
					<strong>{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.upTo') }}</strong>
				</h4>
				<h1 class="center">{{ maxApprovedAmount | currency: 'USD':'symbol':'0.0' }}<sup>*</sup></h1>
			</mat-card-content>
		</mat-card>

		<h5 id="pre-approved-message">{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.amountMayChange') }}</h5>

		<button
			mat-flat-button
			color="primary"
			name="referenceBtn"
			id="preApprovedAddRefBtn"
			*ngIf="!isReferenceComplete"
			(click)="addReferences()"
		>
			{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.addReferences') }}
		</button>

		<button
			mat-flat-button
			color="primary"
			name="submitBtn"
			id="preApprovedNextStepBtn"
			*ngIf="isReferenceComplete"
			(click)="submit()"
		>
			{{ t('GLOBAL.nextSteps') }}
		</button>

		<p></p>
		<op-learn-more
			id="learnMoreVerifyInfo"
			src="/assets/images/almost-done.svg"
			[title]="t('STATUS.PRE_APPROVED_SHOW_AMOUNT.almostDoneTitle')"
		>
			<div>{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.verifyInfo') }}</div>
			&nbsp;
			<div id="final-review" *ngIf="isSoftPullEnabled">{{ t('SOFT_PULL.finalReview') }}</div>
			<div id="almost-done" *ngIf="!isSoftPullEnabled">{{ t('STATUS.PRE_APPROVED.almostDone') }}</div>
			<hr />
		</op-learn-more>
		<p></p>
		<op-learn-more
			id="learnMoreDesc1"
			src="/assets/images/calculate.svg"
			[title]="t('STATUS.PRE_APPROVED_SHOW_AMOUNT.subTitle1')"
		>
			<div>{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.description1') }}</div>
			<hr />
		</op-learn-more>
		<p></p>
		<op-learn-more
			id="learnMoreDesc2"
			src="/assets/images/credit.svg"
			[title]="t('STATUS.PRE_APPROVED_SHOW_AMOUNT.subTitle2')"
		>
			<div>{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.description2') }}</div>
			<br /><br />
			<div>{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.description2_2') }}</div>
			<hr />
		</op-learn-more>
		<p></p>
		<op-learn-more
			sid="learnMoreDesc3"
			src="/assets/images/piggy.svg"
			[title]="t('STATUS.PRE_APPROVED_SHOW_AMOUNT.subTitle3')"
		>
			<div>{{ t('STATUS.PRE_APPROVED_SHOW_AMOUNT.description3') }}</div>
		</op-learn-more>
		<p></p>
	</div>
</div>
