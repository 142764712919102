import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

/**
 * Adds the auth token to http requests.
 *
 * @export
 * @class AuthInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthenticationService, private router: Router) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Get the auth token from the service.
		const authToken = this.authService.getAuthorizationToken() || '';

		// Clone the request and replace the original headers with
		// cloned headers, updated with the authorization.
		const authReq = request.clone({
			// withCredentials: true,
			headers: request.headers?.get('Authorization') ? request.headers : request.headers.set('Authorization', authToken)
		});
		return next.handle(authReq);
	}
}
