<div class="unidos-us-information" *transloco="let t">
	<p [innerHTML]="t('STATUS.UNIDOS.exploreMore')"></p>

	<div class="op-mt-20 op-label-font">
		<div class="align-icon">
			<mat-icon svgIcon="op-checkMark"></mat-icon>
			<div class="info" [innerHTML]="t('STATUS.UNIDOS.financialGoals')"></div>
		</div>
		<p></p>
		<div class="align-icon">
			<mat-icon svgIcon="op-checkMark"></mat-icon>
			<div class="info" [innerHTML]="t('STATUS.UNIDOS.creditScores')"></div>
		</div>
		<p></p>
		<div class="align-icon">
			<mat-icon svgIcon="op-checkMark"></mat-icon>
			<div class="info" [innerHTML]="t('STATUS.UNIDOS.debtRelief')"></div>
		</div>
		<p></p>
	</div>

	<img alt="" class="partner op-mtb-20" src="/assets/images/UnidosUS.svg" />

	<p>
		<a
			href="https://www.uushfe.org/index.cfm?fuseaction=Page.ViewPage&pageId=1271"
			title="{{ t('STATUS.UNIDOS.visitUnidosUs') }}"
			aria-label="{{ t('STATUS.UNIDOS.visitUnidosUs') }}"
		>
			{{ t('STATUS.UNIDOS.visitUnidosUs') }}
		</a>
	</p>

	<div class="note op-mb-10">
		<span [innerHTML]="t('STATUS.UNIDOS.furtherAssistance_1')"></span>
		<span class="op-ml-5 op-green"><strong>877-338-4020</strong></span>
		<span [innerHTML]="t('STATUS.UNIDOS.furtherAssistance_2')"></span>
	</div>

	<p class="op-mb-20">{{ t('STATUS.UNIDOS.3rdPartyDisclosure') }}</p>
</div>
