import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	ValidatorFn
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { differenceInCalendarYears, differenceInYears, format, getDaysInMonth, sub } from 'date-fns';
import { isNull } from 'lodash';
import { Subscription } from 'rxjs';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';

import { opRequired } from '../../decorators/required.decorator';

/**
 *  Data structure for holding date.
 *
 * @class EDate
 * @extends {Date}
 */
class EDate extends Date {
	get month(): number {
		return this.getMonth();
	}
	get day(): number {
		return this.getDate();
	}
	get year(): number {
		return this.getFullYear();
	}
}

@Component({
	selector: 'op-date-input',
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: DateInputComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: DateInputComponent
		}
	]
})
export class DateInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	@Input()
	styleClasses: string;

	@Input()
	minAge: number;

	private _showFormat: string;
	@Input()
	get showFormat(): string {
		return this._showFormat;
	}
	set showFormat(value: string) {
		this._showFormat = value;
	}

	@Input()
	@opRequired()
	id: string;

	@Input()
	hideRequiredMarker: boolean = false;

	showDay: number = 0;
	showMonth: number = 0;
	showYear: number = 0;
	showCount: string;

	monthList: Array<string>;
	dayList: Array<string>;
	yearList: Array<string>;

	parts: FormGroup;

	private subscription = new Subscription();

	constructor(
		private formBuilder: FormBuilder,
		private dateUtilService: DateUtilsService,
		private translocoService: TranslocoService,
		private focusMonitor: FocusMonitor
	) {
		this.parts = this.formBuilder.group(
			{
				month: [null, [this.customRequiredCheck(this.showMonth)]],
				day: [null, [this.customRequiredCheck(this.showDay)]],
				year: [null, [this.customRequiredCheck(this.showYear)]]
			},
			{ validators: this.minAgeValidation() }
		);

		const langSub = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.monthList = this.dateUtilService.getMonthNames(null, 'wide');
				this.dayList = this.getDayList();
				this.yearList = this.getYearList();
			}
		});
		this.subscription.add(langSub);
	}

	private minAgeValidation(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			if (!this.minAge) {
				return null;
			}
			const age = differenceInYears(new Date(), this.value);
			const error = age < this.minAge ? { minAge: true } : null;
			if (error) {
				this.parts.get('month').setErrors(error);
				this.parts.get('day').setErrors(error);
				this.parts.get('year').setErrors(error);
			} else {
				this.parts.get('month').setErrors(null);
				this.parts.get('day').setErrors(null);
				this.parts.get('year').setErrors(null);
			}
			return error;
		};
	}

	isMonthValid(month: number): boolean {
		return (this.showMonth && !isNull(month)) || !this.showMonth;
	}

	isDayValid(day: number): boolean {
		return (this.showDay && !isNull(day)) || !this.showDay;
	}

	isYearValid(year: number): boolean {
		return (this.showYear && !isNull(year)) || !this.showYear;
	}

	isPartsValid(): boolean {
		if (this.parts?.valid) {
			const {
				value: { month, day, year }
			} = this.parts;
			return this.isMonthValid(month) && this.isDayValid(day) && this.isYearValid(year);
		}
		return false;
	}

	get value(): EDate | null {
		if (this.isPartsValid()) {
			const {
				value: { month, day, year }
			} = this.parts;
			const date = new EDate();
			date.setFullYear(year, isNull(month) ? '0' : month, isNull(day) ? '1' : day);
			return date;
		}
		return null;
	}
	set value(date: EDate | null) {
		if (date != null) {
			const setDate = new EDate(date);
			const { month, day, year } = setDate || new EDate(null, null, null);
			this.parts.setValue({ month, day, year });
			this.dayList = this.getDayList();
			this.onChange(date);
			this.onTouched();
		} else {
			this.parts.setValue({ month: null, day: null, year: null });
		}
	}

	onFocus(control: AbstractControl): void {
		control['focus'] = true;
		const focus = Object.keys(this.parts.controls).some((key) => this.parts.controls[key]['focus']);
		if (focus) {
			this.parts.markAsUntouched();
		}
	}

	onBlur(control: AbstractControl): void {
		control['focus'] = false;
		const focus = Object.keys(this.parts.controls).some((key) => this.parts.controls[key]['focus']);
		if (!focus) {
			this.parts.markAllAsTouched();
		}
	}

	onChange = (change) => {
		// This is intentionally empty
	};
	onTouched = () => {
		// This is intentionally empty
	};
	touched = false;
	disabled = false;

	ngOnInit(): void {
		this.showDay = this.getShowDay();
		this.showMonth = this.getShowMonth();
		this.showYear = this.getShowYear();
		this.showCount = this.getShowCount();

		const valueChangeSub = this.parts.valueChanges.subscribe((val) => {
			Object.keys(this.parts.controls).forEach((field, i) => {
				this.markAsTouched();
			});
			this.onChange(this.value);
		});
		this.subscription.add(valueChangeSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	isMonthDirty(): boolean {
		return (this.showMonth && this.parts.controls['month'].dirty) || !this.showMonth;
	}

	isDayDirty(): boolean {
		return (this.showDay && this.parts.controls['day'].dirty) || !this.showDay;
	}

	isYearDirty(): boolean {
		return (this.showYear && this.parts.controls['year'].dirty) || !this.showYear;
	}

	isAllPartsDirty(): boolean {
		return this.isMonthDirty() && this.isDayDirty() && this.isYearDirty();
	}

	markAsTouched(): void {
		if (!this.touched && this.isAllPartsDirty()) {
			this.onTouched();
			this.touched = true;
		}
	}

	// Begin ControlValueAccessor interface
	writeValue(date: Date): void {
		this.value = date ? new EDate(date) : null;
	}
	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}
	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (this.disabled) {
			this.parts.disable();
		} else {
			this.parts.enable();
		}
	}
	// End ControlValueAccessor interface

	// Begin Validator interface
	validate(control: AbstractControl): ValidationErrors | null {
		if (this.parts?.valid) {
			return null;
		}
		let errors: any = {};

		errors = this.addControlErrors(errors, 'month');
		errors = this.addControlErrors(errors, 'day');
		errors = this.addControlErrors(errors, 'year');

		return errors;
	}

	private addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };
		const controlErrors = this.parts.controls[controlName].errors;
		if (controlErrors) {
			errors[controlName] = controlErrors;
		}
		return errors;
	}
	// End Validator interface

	customRequiredCheck(show: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			return show ? { required: { value: true } } : null;
		};
	}

	getShowDay(): number {
		return this.showFormat?.includes('dd') ? 1 : 0;
	}

	getShowMonth(): number {
		return this.showFormat?.includes('mm') ? 1 : 0;
	}

	getShowYear(): number {
		return this.showFormat?.includes('yyyy') ? 1 : 0;
	}

	getShowCount(): string {
		const count = this.showDay + this.showMonth + this.showYear;
		const isTwoFields = count === 2 ? 'two-fields' : 'three-fields';
		return count === 1 ? 'one-field' : isTwoFields;
	}

	getYearList(): Array<string> {
		const years = [];
		let dateStart = new Date();
		const dateEnd = sub(dateStart, { years: 120 });
		while (differenceInCalendarYears(dateStart, dateEnd) >= 0) {
			years.push(Number(format(dateStart, 'yyyy')));
			dateStart = sub(dateStart, { years: 1 });
		}

		return years;
	}

	getDayList(): Array<string> {
		const month = this.parts.controls['month'].value;
		const thirtyDays = [3, 5, 8, 10]; // 0based April, June, September, November.
		let maxDays = month === 1 ? 29 : thirtyDays.some((m) => m === month) ? 30 : 31;
		const year = this.parts.controls['year'].value;
		maxDays = year && month !== null ? getDaysInMonth(new Date(+year, month)) : maxDays;
		return new Array(maxDays);
	}

	autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
		if (!control.errors && nextElement) {
			this.focusMonitor.focusVia(nextElement, 'program');
		}
	}

	doSomething(selection: any, control: AbstractControl, nextElement?: HTMLInputElement): void {
		this.dayList = this.getDayList();
		this.autoFocusNext(control, nextElement);
	}
}
