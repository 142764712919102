import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload,
	IFileUploads
} from '../../../core/services/file-upload/file-upload.service';
import { opRequired } from '../../decorators/required.decorator';

/**
 * Shows button to upload documents.  Displays thumbnail of doc.
 *
 * @export
 * @class SelfieUploadComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
	selector: 'op-selfie-upload',
	templateUrl: './selfie-upload.component.html',
	styleUrls: ['./selfie-upload.component.scss']
})
export class SelfieUploadComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.selfie;

	requiredFileType = 'image/jpg,image/jpeg,image/gif,image/png,image/bmp';

	@ViewChild('fileUpload', { static: false })
	fileUploadElement: ElementRef;

	@Input()
	@opRequired()
	id: string;

	uploadSub: Subscription;

	files$: Observable<IFileUploads>;
	filesList: IFileUpload[];
	subscription: Subscription;

	constructor(
		private dialog: MatDialog,
		private translocoService: TranslocoService,
		private fileUploadService: FileUploadService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		this.subscription = this.fileUploadService.fileUploads$.pipe(map((rsp) => rsp[this.categoryType])).subscribe({
			next: (rsp) => {
				this.filesList = rsp;
				this.selfieInfo = rsp?.find(Boolean);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	selfieInfo: IFileUpload;

	onFileSelected(event) {
		const file: File = event.target.files[0];
		if (file) {
			this.fileUploadService
				.saveThumbnail(this.categoryType, file, { id: this.selfieInfo?.id })
				.subscribe((fileInfo) => {
					this.selfieInfo = fileInfo;
				});
		}
	}

	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openErrorDialog(errorMessage ? errorMessage : this.translocoService.translate('DOCUMENT_SUBMIT.errorDialog'))
			.subscribe();
	}

	uploadSelfie() {
		const upload$ = this.fileUploadService.uploadFile(this.selfieInfo).pipe(finalize(() => this.reset()));
		this.uploadSub = upload$.subscribe((event) => {
			if (event.status == FileUploadStatusEnum.uploading) {
				// this.uploadProgress = event.progress;
			}

			if (event.status == FileUploadStatusEnum.finished) {
			}

			if (event.status === FileUploadStatusEnum.error) {
				this.showErrorMessage(event.error?.msg);
			}
		});
	}

	removeThumbnail() {
		this.fileUploadService.removeThumbnail(this.selfieInfo);
		this.fileUploadElement.nativeElement.value = '';
	}

	reset() {
		this.uploadSub = null;
	}
}
