import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-loan-benefits',
	templateUrl: './loan-benefits.component.html',
	styleUrls: ['./loan-benefits.component.scss']
})
export class LoanBenefitsComponent implements OnInit, OnDestroy {
	constructor(private loanAppService: LoanApplicationService, private routingService: RoutingService) {}

	private subscription = new Subscription();
	applicant: IApplicant;

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$.subscribe({
			next: (loanApp) => {
				this.applicant = this.loanAppService.getCurrentApplicant();
			}
		});
		this.subscription.add(loanAppSub);
	}

	get applicantName(): string {
		return `${this.applicant.firstName} ${this.applicant.lastName}`;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onSubmit(): void {
		this.routingService.route(RoutingPathsEnum.approved);
	}
}
