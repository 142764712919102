import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

const DEFAULT_DURATION = 200;

@Component({
	selector: 'op-document-classification',
	templateUrl: './document-classification.component.html',
	styleUrls: ['./document-classification.component.scss'],
	animations: [
		trigger('collapse', [
			state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
			state('true', style({ height: '0', visibility: 'hidden' })),
			transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
			transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
		])
	]
})
export class DocumentClassificationComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	@Input()
	documentClassificationList: IMetadata[];

	@Output()
	documentClassification = new EventEmitter<IMetadata>();

	@Output()
	cancel = new EventEmitter<any>();

	formGroup: FormGroup;
	collapsed = true;

	public fileUploadType: typeof FileUploadType = FileUploadType;

	constructor(private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({
			documentClassification: []
		});
	}

	ngOnInit(): void {
		this.formGroup.valueChanges.subscribe((value) => {
			if (value.documentClassification === 'OTHER' && this.categoryType === FileUploadType.identification) {
				this.expand();
			} else {
				this.collapse();
			}
		});
	}

	onSubmit(event): void {
		this.documentClassification.emit(
			this.documentClassificationList.find((item) => item.key === this.formGroup.value.documentClassification)
		);
	}

	onCancel(): void {
		this.cancel.emit();
	}

	toggle() {
		this.collapsed = !this.collapsed;
	}

	expand() {
		this.collapsed = false;
	}

	collapse() {
		this.collapsed = true;
	}
}
