import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PreQualifyService {
	private _skipIdentityScreen: boolean;

	set skipIdentityScreen(skipIdentityScreen: boolean) {
		this._skipIdentityScreen = skipIdentityScreen;
	}

	get skipIdentityScreen() {
		return this._skipIdentityScreen;
	}
}
