import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../core/services/dialog/dialog.service';
import { MessageDialogComponent } from '../shared/components/dialogs/message-dialog/message-dialog.component';
import { icons } from '../app-icons';

/**
 * Sample component to show the various stylings.
 *
 * @export
 * @class SamplesComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-samples',
	templateUrl: './samples.component.html',
	styleUrls: ['./samples.component.scss']
})
export class SamplesComponent implements OnInit {
	formGroup: FormGroup;

	appIcons = icons;

	constructor(formBuilder: FormBuilder, private dialogService: DialogService) {
		this.createForm(formBuilder);
	}

	stateList = [
		{ key: 'CA', text: 'California' },
		{ key: 'TX', text: 'Texas' },
		{ key: 'FL', text: 'Florida' }
	];

	toggleSsnItinOptions = [
		{ key: 'ssn', text: 'SSN' },
		{ key: 'itin', text: 'ITIN' },
		{ key: 'none', text: 'NONE' }
	];

	ngOnInit(): void {
		this.formGroup.valueChanges.subscribe((val) => {
			Object.keys(this.formGroup.controls).forEach((field, i) => {
				const control = this.formGroup.get(field);
			});
		});
		this.formGroup.statusChanges.subscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			phoneNumber: [null, Validators.required],
			issuingState: [null, [Validators.required]],
			ssnOrItin: [null, Validators.required],
			mailSameAsHome: [null],

			taxFilingQuestion: [null],
			ssnItinNumber: [null],
			date: [null],
			amount: [105]
		});
	}

	increment() {
		this.formGroup.get('amount').setValue(this.formGroup.controls['amount'].value + 1);
	}

	decrement() {
		this.formGroup.get('amount').setValue(this.formGroup.controls['amount'].value - 1);
	}

	onSubmit(post: any): void {
		// This is intentional empty
	}

	addIncome(): void {
		// This is intentional empty
	}

	panelOpened(): void {
		// This is intentional empty
	}

	resendCode(): void {
		// This is intentional empty
	}

	openDialog(value: number): void {
		switch (value) {
			case 1: {
				const data = {
					icon: 'op-info',
					title: 'title',
					message: 'message to be displayed'
				};
				this.dialogService.open(MessageDialogComponent, { data });
				break;
			}
			case 2: {
				const data = {
					icon: 'op-alert',
					title: 'Error title',
					message: 'error message to be displayed'
				};
				this.dialogService.open(MessageDialogComponent, { data });
				break;
			}
			case 3: {
				this.dialogService.openFindLocationDialog();
				break;
			}
			case 4: {
				this.dialogService.openContactUsDialog(true);
				break;
			}
		}
	}
}
