import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AgentStoreResolver } from '../core/guards/agent-store.resolver';
import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { LandingQueryTrackingGuard } from '../core/guards/landing-query-tracking.guard';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { AcceptEsignComponent } from './accept-esign-disclosures/accept-esign.component';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { BankConnectComponent } from './bank-connect/bank-connect.component';
import { BankConnectGuard } from './bank-connect/bank-connect.guard';
import { CaFinderPartnerDisclosureComponent } from './ca-finder-partner-disclosure/ca-finder-partner-disclosure.component';
import { EsignDisclosuresComponent } from './esign-disclosures/esign-disclosures.component';
import { FindPrequalApplicationComponent } from './find-prequal-application/find-prequal-application.component';
import { FindYourApplicationComponent } from './find-your-application/find-your-application.component';
import { FindYourLetterComponent } from './find-your-letter/find-your-letter.component';
import { GetOfferComponent } from './get-offer/get-offer.component';
import { HandlePrescreenApplicationComponent } from './handle-prescreen-application/handle-prescreen-application.component';
import { HomeComponent } from './home/home.component';
import { PartnerLeadGuard } from './home/partner-lead.guard';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LoanDisclosuresComponent } from './loan-disclosures/loan-disclosures.component';
import { MultifactorComponent } from './multifactor/multifactor.component';
import { MultifactorGuard } from './multifactor/multifactor.guard';
import { OfferAvailableComponent } from './offer-available/offer-available.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { PersonalInfoGuard } from './personal-info/personal-info.guard';
import { PreQualifiedComponent } from './pre-qualified/pre-qualified.component';
import { SpecialOfferComponent } from './special-offer/special-offer.component';
import { SpecialOfferVariantsComponent } from './special-offer/variants/special-offer-variants.component';
import { SsoGuard } from './sso/sso.guard';
import { TermsComponent } from './terms/terms.component';
import { TokenGuard } from './token/token.guard';
import { VendorGeneralInfoComponent } from './vendor-general-info/vendor-general-info.component';
import { VendorPersonalInfoGuard } from './vendor-general-info/vendor-personal-info.guard';

const routes: Routes = [
	{ path: RoutingPathsEnum.accessibility, component: AccessibilityComponent },
	{
		path: RoutingPathsEnum.findYourApplication,
		component: FindYourApplicationComponent,
		canActivate: [LandingQueryTrackingGuard]
	},
	{ path: RoutingPathsEnum.findYourLetter, component: FindYourLetterComponent },
	{ path: RoutingPathsEnum.esignDisclosures, component: EsignDisclosuresComponent },
	{ path: RoutingPathsEnum.acceptEsign, component: AcceptEsignComponent },
	{ path: RoutingPathsEnum.findPrequalApplication, component: FindPrequalApplicationComponent },
	{ path: RoutingPathsEnum.getOffer, component: GetOfferComponent },
	{
		path: RoutingPathsEnum.home,
		component: HomeComponent,
		canActivate: [LandingQueryTrackingGuard, PartnerLeadGuard]
	},
	{ path: RoutingPathsEnum.howItWorks, component: HowItWorksComponent },
	{ path: RoutingPathsEnum.loanDisclosures, component: LoanDisclosuresComponent },
	{ path: RoutingPathsEnum.multifactor, component: MultifactorComponent, canActivate: [MultifactorGuard] },
	{ path: RoutingPathsEnum.offerAvailable, component: OfferAvailableComponent },
	{
		path: RoutingPathsEnum.personalInfo,
		component: PersonalInfoComponent,
		canActivate: [PersonalInfoGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{ path: RoutingPathsEnum.preQualified, component: PreQualifiedComponent },
	{ path: RoutingPathsEnum.specialOffer, component: SpecialOfferComponent },
	{ path: RoutingPathsEnum.specialOffer1, component: SpecialOfferVariantsComponent },
	{ path: RoutingPathsEnum.specialOffer2, component: SpecialOfferVariantsComponent },
	{ path: RoutingPathsEnum.terms, component: TermsComponent },
	{ path: RoutingPathsEnum.handlePrescreenApplication, component: HandlePrescreenApplicationComponent },
	{ path: RoutingPathsEnum.vendorGeneralInfo, component: VendorGeneralInfoComponent },
	{
		path: RoutingPathsEnum.vendorPersonalInfo,
		component: PersonalInfoComponent,
		canActivate: [VendorPersonalInfoGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{ path: RoutingPathsEnum.landing, redirectTo: RoutingPathsEnum.home, pathMatch: 'full' },
	{ path: RoutingPathsEnum.landingForwardSlash, redirectTo: RoutingPathsEnum.home, pathMatch: 'full' },
	{
		path: RoutingPathsEnum.sso,
		canActivate: [SsoGuard],
		component: HomeComponent
	},
	{
		path: RoutingPathsEnum.token,
		canActivate: [TokenGuard],
		component: HomeComponent
	},
	{ path: RoutingPathsEnum.bankConnect, component: BankConnectComponent, canActivate: [BankConnectGuard] },
	{
		path: RoutingPathsEnum.caFindPartnerDisclosure,
		component: CaFinderPartnerDisclosureComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: RoutingPathsEnum.agentStore,
		component: HomeComponent,
		resolve: { agent: AgentStoreResolver }
	},
	{
		path: `${RoutingPathsEnum.agentStore}/:id`,
		component: HomeComponent,
		resolve: { agent: AgentStoreResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LandingRoutingModule {}
