import { Component, Input } from '@angular/core';
import { IShowBounceAttention } from 'src/app/core/services/loan-application/loan-application.model';

@Component({
	selector: 'op-bounced-attention',
	templateUrl: './bounced-attention.component.html',
	styleUrls: ['./bounced-attention.component.scss']
})
export class BouncedAttentionComponent {
	_showSection: IShowBounceAttention;
	@Input()
	set showSection(value) {
		this._showSection = value;
	}
	get showSection(): IShowBounceAttention {
		return this._showSection;
	}
}
