<ng-container *ngIf="!accountConnected">
	<div class="manually-add-bank" *transloco="let t">
		<div class="op-header-1-font">{{ t('MANUAL_ADD_BANK.addBankAccount') }}</div>
		<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div id="requiredFields" class="op-label-font op-gray op-mtb-20">
				{{ t('MANUAL_ADD_BANK.requiredFields') }}
			</div>

			<p></p>
			<div class="op-form-group">
				<div class="op-form-title">{{ t('MANUAL_ADD_BANK.accountType') | opRequired }}</div>
				<mat-radio-group name="accountType" id="accountType" formControlName="accountType">
					<mat-radio-button value="CHECKING" id="accountType_checking">
						{{ t('MANUAL_ADD_BANK.checking') }}
					</mat-radio-button>
					<mat-radio-button value="SAVING" id="accountType_saving">
						{{ t('MANUAL_ADD_BANK.savings') }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<op-info-tip id="routingInfoTip" [key]="t('MANUAL_ADD_BANK.routingNumberA')">
				{{ t('MANUAL_ADD_BANK.routingNumberQ') }}
			</op-info-tip>

			<mat-form-field class="form-element op-mt-10" appearance="outline">
				<mat-label>{{ t('MANUAL_ADD_BANK.routingNumber') }}</mat-label>
				<input matInput type="text" name="routingNumber" id="routingNumber" formControlName="routingNumber" />
				<mat-hint>{{ bankName }}</mat-hint>
				<mat-error
					id="routingNumberError"
					*ngIf="formGroup.controls['routingNumber'].invalid && formGroup.controls['routingNumber'].touched"
				>
					{{ t('MANUAL_ADD_BANK.errorMsgRouting') }}
				</mat-error>
			</mat-form-field>

			<op-info-tip id="accountInfoTip" [key]="t('MANUAL_ADD_BANK.accountNumberA')">
				{{ t('MANUAL_ADD_BANK.accountNumberQ') }}
			</op-info-tip>

			<mat-form-field class="form-element op-mt-3" appearance="outline">
				<mat-label>{{ t('MANUAL_ADD_BANK.accountNumber') }}</mat-label>
				<input matInput type="text" name="accountNumber" id="accountNumber" formControlName="accountNumber" />
				<mat-error
					id="accountNumberError"
					*ngIf="formGroup.controls['accountNumber'].invalid && formGroup.controls['accountNumber'].touched"
				>
					{{ t('MANUAL_ADD_BANK.errorMsgAccount') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element op-mt-3" appearance="outline">
				<mat-label>{{ t('MANUAL_ADD_BANK.confirmAccountNumber') }}</mat-label>
				<input
					matInput
					type="text"
					name="accountNumberConfirmed"
					id="accountNumberConfirmed"
					formControlName="accountNumberConfirmed"
				/>
				<mat-error
					id="accNumberConfirmedError"
					*ngIf="
						formGroup.controls['accountNumberConfirmed'].invalid && formGroup.controls['accountNumberConfirmed'].touched
					"
				>
					{{ t('MANUAL_ADD_BANK.errorMsgAccountMatch') }}
				</mat-error>
			</mat-form-field>

			<div class="op-mt-16">
				<mat-checkbox name="customerConfirms" id="customerConfirms" formControlName="customerConfirms" color="primary">
					{{ t('MANUAL_ADD_BANK.certifyAuthorizeSignTerms') }}
				</mat-checkbox>
			</div>

			<div class="error" *ngIf="showError">{{ errorMessage }}</div>
			<div class="op-mt-20">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="manualAddBankSubmitBtn"
					[disabled]="formGroup.invalid || inProgress"
					type="submit"
				>
					{{ 'MANUAL_ADD_BANK.connectBankAccount' | transloco }}
				</button>
			</div>
			<div class="op-mt-20">
				<button
					mat-flat-button
					color="primary"
					class="op-mt-20"
					name="cancelButton"
					id="manualAddBankCancel"
					(click)="cancelAutoPay()"
					type="button"
				>
					{{ 'MANUAL_ADD_BANK.cancel' | transloco }}
				</button>
			</div>
		</form>
		<div class="op-pt-40"></div>
	</div>
</ng-container>
<ng-container *ngIf="accountConnected">
	<div class="bank-info-wrapper" *transloco="let t">
		<div class="logo">
			<img class="socialImg" src="/assets/images/bank-logo.png" width="48" height="48" alt="Social proof" />
		</div>
		<div class="content">
			<h2>{{ 'ESIGN_AUTO_PAY.autoPayBankConnected' | transloco }}</h2>
			<p>{{ 'ESIGN_AUTO_PAY.autoPayBankConnectedDescription' | transloco }}</p>

			<div>{{ bankDetails?.bankName }} {{ bankDetails?.accountNumber }}</div>

			<p>{{ bankDetails?.accountType }} account</p>
		</div>
	</div>
	<div class="error" *ngIf="showSkipButton">{{ 'ESIGN_AUTO_PAY.errorActivationAutoPay' | transloco }}</div>
	<p></p>
	<button
		mat-flat-button
		color="primary"
		name="submitBtn"
		id="manualAddBankSubmitBtn"
		[disabled]="showSkipButton"
		(click)="activateAutoPay()"
		type="submit"
	>
		{{ 'ESIGN_AUTO_PAY.activateAutoPay' | transloco }}
	</button>
	<p></p>
	<button
		*ngIf="showSkipButton"
		mat-flat-button
		color="primary"
		name="submitBtn"
		id="manualAddBankSkip"
		(click)="navigate()"
		type="submit"
	>
		{{ 'ESIGN_AUTO_PAY.skip' | transloco }}
	</button>
</ng-container>
