import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { includes } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApplicationStatusEnum } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable({
	providedIn: 'root'
})
export class SecuredPersonalGuard implements CanActivate {
	constructor(private loanAppService: LoanApplicationService, private routingService: RoutingService) {}

	private readonly SecuredOfferStatusesList = [
		ApplicationStatusEnum.securedAccepted,
		ApplicationStatusEnum.securedDeclined,
		ApplicationStatusEnum.securedOffered
	];
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.loanApplication$.pipe(
			filter(Boolean),
			map(() => {
				return !includes(this.SecuredOfferStatusesList, this.loanAppService.applicationStatus)
					? Boolean(this.routingService.routeByStatus())
					: true;
			})
		);
	}
}
