import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ICcasUser } from 'src/app/core/services/ccas/ccas.model';
import { CcasService } from 'src/app/core/services/ccas/ccas.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { emailValidator } from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-create-account',
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, OnDestroy {
	accountCreated: boolean = false;
	ccasUser: ICcasUser;
	emailMask: string = '';
	isEmailRequired: boolean = false;
	isEmailTaken: boolean = false;
	passwordResetLink: string = '';

	formGroup: FormGroup;

	constructor(
		private ccasService: CcasService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private translocoService: TranslocoService,
		private routingService: RoutingService
	) {
		this.createForm(this.formBuilder);
	}
	private subscription = new Subscription();
	created: boolean;
	initialized: boolean;

	ngOnInit(): void {
		this.created = this.route.snapshot.queryParams?.created;
		const cassUserSub = this.ccasService.cassUser$.pipe(filter(Boolean)).subscribe({
			next: () => {
				if (this.ccasService.accountCreated && !this.created) {
					this.routingService.route(RoutingPathsEnum.loanCompleted);
				} else {
					this.accountCreated = this.ccasService.accountCreated;
					this.ccasUser = this.ccasService.ccasUser;
					this.emailMask = this.getEmailMask();
					this.isEmailRequired = this.ccasService.isEmailRequired();
					this.isEmailTaken = this.ccasService.isEmailTaken();
					this.passwordResetLink = this.getPasswordResetLink();
					this.updateEmailValidator();
					this.initialized = true;
				}
			}
		});
		this.subscription.add(cassUserSub);

		this.formGroup.controls.email.valueChanges.subscribe({
			next: (email) => {
				this.ccasService.setCcasUserEmail(email);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group(
			{
				email: [null, [], this.emailAlreadyInUseValidator()]
			},
			{ updateOn: 'blur' }
		);
	}

	getEmailError(): string {
		if (this.formGroup.get('email').hasError('required')) {
			return this.translocoService.translate('VALIDATION.required');
		}

		if (this.formGroup.get('email').hasError('pattern')) {
			return this.translocoService.translate('CREATE_ACCOUNT.invalidEmail');
		}

		if (this.formGroup.get('email').hasError('alreadyInUse')) {
			return this.translocoService.translate('CREATE_ACCOUNT.emailInUse');
		}

		return '';
	}

	getEmailMask(): string {
		return this.ccasUser?.emailAddress?.replace(
			/^(.)(.*)(.@.)(.*)(\.)([A-Za-z]{2,})$/,
			(_, a, b, c, d, e, f) => a + b.replace(/./g, '*') + c + d.replace(/./g, '*') + e + f
		);
	}

	emailAlreadyInUseValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const email = control.value;

			if (!email) {
				return of(null);
			}

			return this.ccasService.validateClient(email).pipe(
				map((val) => {
					if (this.ccasService.isEmailTaken()) {
						return { alreadyInUse: true };
					}
					return null;
				})
			);
		};
	}

	getPasswordResetLink(): string {
		return `${this.ccasService.getCcasHost()}/reset-password-service`;
	}

	updateEmailValidator(): void {
		const emailValidators = [emailValidator()];

		if (this.isEmailRequired) {
			emailValidators.push(Validators.required);
		}

		this.formGroup.get('email').setValidators(emailValidators);
		this.formGroup.get('email').updateValueAndValidity();
	}

	onSubmit(post: any): void {
		const { email } = post;

		this.ccasService.validateClient(email).subscribe({
			next: () => {
				if (this.ccasService.canCreateAccount()) {
					this.ccasService.createAccount(email).subscribe({
						next: () => {
							this.accountCreated = true;
						}
					});
				}
			}
		});
	}
}
