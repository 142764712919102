<div class="digit-decline-offer">
	<div class="op-center">
		<div
			tabindex="0"
			id="offerButton"
			role="button"
			(click)="goToDigit()"
			attr.aria-label="{{ 'DIGIT_OFFER_FOR_NOT_APPROVED.description' | transloco }}"
		>
			<img [src]="image" alt="app offer" />
			<div class="disclaimer op-label-font">
				<div id="esDisclaimer" class="op-mt-10 op-left op-dark-grey">
					{{ 'DIGIT_OFFER_FOR_NOT_APPROVED.disclaimer' | transloco }}
				</div>
			</div>
		</div>
	</div>
</div>
