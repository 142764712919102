<div class="payment-date">
	<div class="op-mb-5" [formGroup]="formGroup">
		<div class="op-mt-20 op-mb-16 op-left op-body-1-bold-font">{{ 'ADJUST_TERMS.chooseDayToPay' | transloco }}</div>

		<!-- paymentDay selection for bi_weekly payment frequency 14-->
		<div *ngIf="paymentFrequency === paymentFrequencyEnum.BI_WEEKLY">
			<mat-form-field class="form-element" appearance="outline">
				<mat-select formControlName="biWeeklyDate" id="{{ id + '_biWeeklyDate' }}" aria-label="state">
					<mat-option
						*ngFor="let item of weekdays; let i = index"
						id="{{ id + '_biWeeklyDate_' + item.key }}"
						[value]="item.key"
					>
						{{ item.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<!-- paymentDate selection for semi_monthly payment frequency 15-->
		<div *ngIf="paymentFrequency === paymentFrequencyEnum.SEMI_MONTHLY">
			<mat-form-field class="form-element" appearance="outline">
				<mat-select
					formControlName="semiMonthlyPaymentDate"
					id="{{ id + '_semiMonthlyPaymentDate' }}"
					aria-label="state"
				>
					<mat-option
						*ngFor="let moDay of monthDays; let i = index"
						id="{{ id + '_semiMonthlyPaymentDate_' + i }}"
						[value]="i + 1"
					>
						{{ moDay.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<!-- paymentDate selection for monthly payment frequency start 30-->
		<div *ngIf="paymentFrequency === paymentFrequencyEnum.MONTHLY">
			<mat-form-field class="form-element" appearance="outline">
				<mat-select formControlName="monthlyDate" id="{{ id + '_monthlyDate' }}" aria-label="state">
					<mat-option
						*ngFor="let moDay of monthlyPaymentDates; let i = index"
						id="{{ id + '_moDay_' + i }}"
						[value]="moDay"
					>
						{{ moDay }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</div>
