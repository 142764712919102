import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

import { SessionStorageService } from '../../../../core/services/storage/session-storage.service';

@Component({
	selector: 'op-esign-event-signing-canceled',
	templateUrl: './esign-event-signing-canceled.component.html',
	styleUrls: ['./esign-event-signing-canceled.component.scss']
})
export class EsignEventSigningCanceledComponent {
	constructor(
		private authenticationService: AuthenticationService,
		private routingService: RoutingService,
		private sessionStorageService: SessionStorageService
	) {}

	public signOut(): void {
		this.authenticationService.signOut();
		this.sessionStorageService.clear();
		this.routingService.route(RoutingPathsEnum.home);
	}
}
