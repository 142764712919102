import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

/**
 * Adds the active language to the http request.
 *
 * @export
 * @class LanguageInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
	constructor(private translocoService: TranslocoService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const lang = this.translocoService.getActiveLang();
		const appendHeaders = {
			'Preferred-Language': lang,
			language: lang
		};
		return next.handle(request.clone({ setHeaders: appendHeaders }));
	}
}
