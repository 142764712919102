import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IConfigResult, IMetadata } from 'src/app/core/services/mobile-api';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';

export interface IStepHeadlines {
	icon: string;
	headlines: string;
	info: string;
	moreInfo?: string;
	showInfo: boolean;
	showMoreInfo: boolean;
}
@Component({
	selector: 'op-how-it-works',
	templateUrl: './how-it-works.component.html',
	styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit, OnDestroy {
	isSoftPullEnabled = false;
	readonly stepsLength = 3;

	steps: Array<IStepHeadlines>;
	idTypes$: Observable<IMetadata[]>;

	private subscription = new Subscription();

	constructor(
		private softPullService: SoftPullService,
		private translocoService: TranslocoService,
		private metadataService: MetadataService
	) {}

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$
			.pipe(
				filter(Boolean),
				tap((softPull: IConfigResult) => (this.isSoftPullEnabled = softPull.value))
			)
			.subscribe();
		this.subscription.add(softPullSub);

		this.initializeSteps();

		this.idTypes$ = this.metadataService.select(MetadataEnum.IdentificationType).pipe(share());
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	initializeSteps() {
		const keyParams = {
			HOW_IT_WORKS: null
		};
		const stepIcons = ['get-prequalified.svg', 'complete.svg', 'offers-handMoney.svg'];
		const translocoSub = this.translocoService
			.selectTranslateObject(keyParams)
			.pipe(
				map((result) => {
					const hiw = result[0];
					return Array.from(Array(this.stepsLength), (_, index) => {
						const i = index + 1;
						const steps = this.steps || [];
						return {
							icon: `/assets/images/${stepIcons[i - 1]}`,
							headlines: this.isSoftPullEnabled && i != 3 ? hiw[`softStep${i}Headline`] : hiw[`step${i}Headline`],
							info: this.isSoftPullEnabled && i != 3 ? hiw[`softStep${i}`] : hiw[`step${i}`],
							moreInfo: i === 1 ? hiw.listOfIds : undefined,
							showInfo: steps[index]?.showInfo || false,
							showMoreInfo: steps[index]?.showMoreInfo || false
						};
					});
				})
			)
			.subscribe({
				next: (result) => {
					this.steps = result;
				}
			});

		this.subscription.add(translocoSub);
	}
}
