import { Injectable, OnDestroy } from '@angular/core';
import { isNil } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ILoanApplication } from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { ConfigApiService, IConfigResult } from '../mobile-api';
import { SessionStorageService } from '../storage/session-storage.service';

/**
 * Monitor softPull results
 * Stores the soft pull data.
 *
 * @export
 * @class SoftPullService
 */
@Injectable({
	providedIn: 'root'
})
export class SoftPullService implements OnDestroy {
	private readonly softPullSource = new BehaviorSubject<IConfigResult>(null);

	// Exposed observable (read-only).
	readonly softPull$ = this.softPullSource.asObservable();
	private readonly storageKey = 'softPull';
	private readonly subscription = new Subscription();

	constructor(
		private configApiService: ConfigApiService,
		private sessionStorageService: SessionStorageService,
		private loanAppService: LoanApplicationService
	) {
		const storageKeySub = this.sessionStorageService.select(this.storageKey).subscribe({
			next: (value) => {
				if (isNil(value)) {
					this.requestSoftPull();
				} else {
					this.softPullSource.next(value);
				}
			},
			complete: () => {
				this.softPullSource.next(null);
			}
		});
		this.subscription.add(storageKeySub);

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter((loanApp: ILoanApplication) => Boolean(loanApp?.applicants)),
				map((loanApp: ILoanApplication) => {
					const { preScreened, bureauInquiryType } = loanApp.applicants.find(Boolean);
					return { preScreened, bureauInquiryType };
				}),
				distinctUntilChanged((curr, prev) => {
					return curr.preScreened === prev.preScreened && curr.bureauInquiryType === prev.bureauInquiryType;
				})
			)
			.subscribe({
				next: ({ preScreened, bureauInquiryType }) => {
					const data = {
						result: 'success',
						preScreened: Boolean(preScreened),
						value: bureauInquiryType === 'SOFT'
					};
					this.setSoftPullSource(data);
				}
			});
		this.subscription.add(loanAppSub);

		this.requestSoftPull();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	 * request soft pull information and save the results.
	 *
	 * @memberof SoftPullService
	 */
	requestSoftPull(): void {
		this.configApiService.configSoftPull().subscribe({
			next: (value) => {
				this.setSoftPullSource(value);
			}
		});
	}

	private setSoftPullSource(data: IConfigResult) {
		const combined = Object.assign({}, this.softPullSource.getValue(), data);
		data
			? this.sessionStorageService.set(this.storageKey, combined)
			: this.sessionStorageService.remove(this.storageKey);
	}

	/**
	 * Get last value of the softPull request
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof SoftPullService
	 */
	get isSoftPullEnabled(): boolean {
		return this.softPullSource.getValue().value;
	}

	/**
	 * Get prescreen value
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof SoftPullService
	 */
	get isPreScreened(): boolean {
		return this.softPullSource.getValue().preScreened;
	}
}
