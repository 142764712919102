import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IBankVerificationDialogData {
	title: string;
	message: string;
	confirmText?: string;
	retryText?: string;
	manualText?: string;
	manualDisclaimer?: string;
}
@Component({
	selector: 'op-bank-verification-dialog',
	templateUrl: './bank-verification-dialog.component.html',
	styleUrls: ['./bank-verification-dialog.component.scss']
})
export class BankVerificationDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<BankVerificationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IBankVerificationDialogData
	) {}

	close(): void {
		this.dialogRef.close(false);
	}

	onSubmit(): void {
		this.dialogRef.close(true);
	}
}
