import { Component } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-vehicle-drivers-license',
	templateUrl: './section-vehicle-drivers-license.component.html',
	styleUrls: ['./section-vehicle-drivers-license.component.scss']
})
export class SectionVehicleDriversLicenseComponent extends ExpandSectionComponent {
	constructor() {
		super();
	}
}
