import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent, IMessageDialogData } from '../message-dialog/message-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/**
 * basic message dialog takes in icon, title and message.
 *
 * @export
 * @class MessageConfirmDialogComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-message-confirm-dialog',
	templateUrl: './message-confirm-dialog.component.html',
	styleUrls: ['./message-confirm-dialog.component.scss']
})
export class MessageConfirmDialogComponent extends MessageDialogComponent {
	confirmForm: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<MessageConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IMessageDialogData,
		protected formBuilder: FormBuilder
	) {
		super(dialogRef, data);
		this.createForm(formBuilder);
	}

	createForm(fb: FormBuilder): void {
		this.confirmForm = fb.group({
			confirm: [, [Validators.required]]
		});
	}

	get formValid() {
		return this.confirmForm.value.confirm === true;
	}
}
