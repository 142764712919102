<div class="special-offer-variants" *transloco="let t">
	<op-content-container class="content">
		<div class="pageContainer">
			<div id="content" class="gray-bg" aria-labelledby="title">
				<div class="contentContainer" *ngIf="!isVariant2">
					<div class="container variant-1-header">
						<div class="title">
							<img class="robotImg" src="/assets/images/green-robot.png" alt="Green robot" width="37" />
							<span
								class="op-header-2-font op-mb-0"
								[innerHtml]="t('SPECIAL_OFFER.VARIANTS.titleStep' + currentStep)"
							></span>
						</div>
						<div class="op-body-1-font" [innerHtml]="t('SPECIAL_OFFER.VARIANTS.subtitleStep' + currentStep)"></div>
					</div>
				</div>
				<div class="contentContainer" *ngIf="isVariant2">
					<div class="container">
						<div class="title text-left hidden-md">
							<div class="container variant-1-header">
								<div class="title">
									<span
										class="op-header-2-font"
										[innerHtml]="t('SPECIAL_OFFER.VARIANTS.titleStep' + currentStep)"
									></span>
								</div>
								<div class="op-body-1-font" [innerHtml]="t('SPECIAL_OFFER.VARIANTS.subtitleStep' + currentStep)"></div>
							</div>
						</div>
						<div class="title circle text-center">
							<img
								class="greenLines"
								src="/assets/images/green-lines.png"
								width="30"
								height="20"
								alt="background-image"
							/>
							<div class="socialCard" [ngSwitch]="currentStep">
								<img
									class="socialImg"
									*ngSwitchCase="1"
									src="/assets/images/special-offer/step1.png"
									width="48"
									height="48"
									alt="Social proof"
								/>
								<img
									class="socialImg"
									*ngSwitchCase="2"
									src="/assets/images/special-offer/step2.png"
									width="48"
									height="48"
									alt="Social proof"
								/>
								<img
									class="socialImg"
									*ngSwitchCase="3"
									src="/assets/images/special-offer/step3.png"
									width="48"
									height="48"
									alt="Social proof"
								/>
								<span class="imgText" [innerHtml]="t('SPECIAL_OFFER.VARIANTS.socialCard.step' + currentStep)"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="content" class="gray-bg wizardContainer" aria-labelledby="title">
				<div class="contentContainer wizard gray-bg">
					<div class="container text-center wizardHeader">
						<button
							mat-flat-button
							color="link-text-b1"
							id="prevWizardStep"
							type="button"
							[disabled]="currentStep === 1"
							[ngClass]="{ visible: currentStep > 1 }"
							(click)="prevWizardPage()"
						>
							<mat-icon aria-hidden="true" svgIcon="op-arrowLeft"></mat-icon>
						</button>
						<span class="page-number">{{ currentStep }}/3</span>
						<a
							id="nextWizardStep"
							mat-flat-button
							color="link-bold"
							(click)="nextWizardPage()"
							[ngClass]="{ visible: currentStep !== 3 }"
						>
							{{ t('SPECIAL_OFFER.VARIANTS.next') }}&nbsp;<img
								alt=""
								src="/assets/images/icons/op-caretRightSmall.svg"
							/>
						</a>
					</div>
					<hr />
					<ng-container [ngSwitch]="currentStep">
						<div class="wizardBody">
							<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
								<ng-container *ngSwitchCase="1">
									<div class="op-body-1-font op-mb-10">
										<strong>{{ t('SPECIAL_OFFER.VARIANTS.whatsYourFirstName') }}</strong>
									</div>
									<mat-form-field class="form-element" appearance="outline">
										<mat-label>{{ t('SPECIAL_OFFER.LANDING.firstName') }}</mat-label>
										<input matInput formControlName="firstName" name="firstName" required id="firstName" />
										<mat-error
											id="firstNameError"
											*ngIf="!formGroup.controls['firstName'].valid && formGroup.controls['firstName'].touched"
										>
											{{ t('SPECIAL_OFFER.LANDING.firstNameError') }}
										</mat-error>
									</mat-form-field>
									<div class="op-body-1-font op-mb-10">
										<strong>{{ t('SPECIAL_OFFER.VARIANTS.whatsYourLastName') }}</strong>
									</div>
									<mat-form-field class="form-element" appearance="outline">
										<mat-label>{{ t('SPECIAL_OFFER.LANDING.lastName') }} </mat-label>
										<input matInput type="text" formControlName="lastName" name="lastName" required id="lastName" />
										<mat-error
											id="lastNameError"
											*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
										>
											{{ t('SPECIAL_OFFER.LANDING.lastNameError') }}
										</mat-error>
									</mat-form-field>
								</ng-container>

								<ng-container *ngSwitchCase="2">
									<div class="op-body-1-font op-mb-10">
										<strong>{{ t('SPECIAL_OFFER.VARIANTS.whatsYourAddress') }}</strong>
									</div>
									<op-address-detail-form
										[homeAddress]="true"
										id="address"
										formControlName="address"
									></op-address-detail-form>
								</ng-container>

								<ng-container *ngSwitchCase="3">
									<div class="emailInput">
										<div class="op-body-1-font op-mb-10">
											<strong>{{ t('SPECIAL_OFFER.VARIANTS.whatsYourEmail') }}</strong>
										</div>
										<mat-form-field class="form-element" appearance="outline">
											<mat-label>{{ t('PERSONAL_INFO.emailAddress') }} (Optional) </mat-label>
											<input matInput type="text" formControlName="email" id="email" name="email" />
											<mat-hint>{{ t('PERSONAL_INFO.emailAddressInfo') }}</mat-hint>
											<mat-error id="emailError" *ngIf="!formGroup.controls['email'].valid">
												{{ t('PERSONAL_INFO.emailError') }}
											</mat-error>
										</mat-form-field>
									</div>

									<div class="op-body-1-font op-mb-20">{{ t('SPECIAL_OFFER.VARIANTS.getInformation') }}</div>
									<div class="op-icon-align-body-1-green op-mb-10">
										<mat-icon svgIcon="op-checkMark"></mat-icon>
										{{ t('SPECIAL_OFFER.VARIANTS.specialPromotions') }}
									</div>
									<div class="op-icon-align-body-1-green op-mb-10">
										<mat-icon svgIcon="op-checkMark"></mat-icon>
										{{ t('SPECIAL_OFFER.VARIANTS.learnAboutNewProducts') }}
									</div>
									<div class="op-icon-align-body-1-green op-mb-10">
										<mat-icon svgIcon="op-checkMark"></mat-icon>
										{{ t('SPECIAL_OFFER.VARIANTS.stayUpToDate') }}
									</div>

									<div class="submit op-mt-20">
										<button
											mat-flat-button
											color="primary"
											[disabled]="!formGroup.valid"
											name="submitBtn"
											type="submit"
											id="submitBtn"
										>
											{{ t('SPECIAL_OFFER.LANDING.submitBtn') }}
										</button>
									</div>
								</ng-container>
							</form>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</op-content-container>
</div>
