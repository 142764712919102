<div class="agent-referral-dialog" id="contactUsDialog" aria-describedby="full-hours" *transloco="let t">
	<div mat-dialog-title>
		<div id="agentReferralClose" class="op-right op-mb-8">
			<button
				mat-icon-button
				id="agentReferralCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ t('GLOBAL.close') }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>

		<div *ngIf="agentState === agentStateEnum.store">
			<div class="agent-warning icon-align">
				<mat-icon svgIcon="op-info-orange-fill"></mat-icon>
				<div class="op-body-2-font">
					{{ t('HOME.AGENT_REFERRAL.dialog.warning') }}
				</div>
			</div>
			<div class="op-header-1-font" id="agentReferralHeading">
				<strong>{{ t('HOME.AGENT_REFERRAL.dialog.storeTitle', { user: userName }) }}</strong>
			</div>
		</div>
	</div>

	<div [ngSwitch]="agentState" id="agentReferralBody">
		<div *ngSwitchCase="agentStateEnum.agent" class="op-mb-20">
			<div class="agent-warning icon-align">
				<mat-icon svgIcon="op-info-orange-fill"></mat-icon>
				<div class="op-body-2-font">
					{{ t('HOME.AGENT_REFERRAL.dialog.warning') }}
				</div>
			</div>
			<div class="op-mb-20">{{ t('HOME.AGENT_REFERRAL.dialog.description') }}</div>
			<form [formGroup]="agentFormGroup" (ngSubmit)="accept(agentFormGroup.value)">
				<div class="agent-entry">
					<mat-form-field class="agent-code op-full-width" appearance="outline">
						<mat-label>{{ t('HOME.AGENT_REFERRAL.dialog.inputLabel') }}</mat-label>
						<input
							matInput
							name="agentId"
							id="agentId"
							type="text"
							inputmode="text"
							[dropSpecialCharacters]="false"
							formControlName="agentId"
							#agentStoreField
						/>
						<mat-error
							id="agentReferralError"
							class="op-mb-10"
							*ngIf="agentFormGroup.controls['agentId'].invalid && agentFormGroup.controls['agentId'].touched"
							>{{ t('HOME.AGENT_REFERRAL.dialog.error') }}</mat-error
						>
					</mat-form-field>
				</div>
				<button
					class="confirm op-mt-20"
					mat-flat-button
					color="primary-small"
					id="agentIdButton"
					[disabled]="!agentFormGroup.valid"
					type="submit"
					[opTagClick]="'Agent Id'"
					aria-label="Agent Id"
				>
					{{ t('HOME.AGENT_REFERRAL.dialog.next') }}
				</button>
			</form>
		</div>
		<div *ngSwitchCase="agentStateEnum.store" class="op-mb-20">
			<div class="op-mb-20">{{ t('HOME.AGENT_REFERRAL.dialog.storeDescription') }}</div>
			<form [formGroup]="storeFormGroup" (ngSubmit)="accept(storeFormGroup.value)">
				<div class="agent-entry">
					<mat-form-field class="agent-code op-full-width" appearance="outline">
						<mat-label>{{ t('HOME.AGENT_REFERRAL.dialog.storeDropdown') }}</mat-label>
						<mat-select
							class="input-element"
							name="storeDropdown"
							id="storeDropdown"
							formControlName="storeId"
							aria-label="store list"
							required
							(focus)="onFocus()"
						>
							<mat-option
								*ngFor="let store of storeList; let i = index"
								id="{{ 'store_' + store.id }}"
								[value]="store.id"
								>{{ store.name }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</div>
				<button
					class="confirm op-mt-20"
					mat-flat-button
					color="primary-small"
					id="storeSelectionButton"
					[disabled]="!storeFormGroup.valid"
					type="submit"
					[opTagClick]="'Store Selection'"
					aria-label="Store Selection"
				>
					{{ t('HOME.AGENT_REFERRAL.dialog.confirm') }}
				</button>
				<div class="op-mt-10"></div>
				<button
					class="confirm"
					mat-flat-button
					color="secondary-small"
					id="agentIdBack"
					[disabled]="!agentFormGroup.valid"
					(click)="back()"
					[opTagClick]="'Agent back'"
					aria-label="Agent back"
				>
					{{ t('HOME.AGENT_REFERRAL.dialog.back') }}
				</button>
			</form>
		</div>
		<div *ngSwitchCase="agentStateEnum.success" class="op-mb-20">
			<div class="op-mb-20">{{ t('HOME.AGENT_REFERRAL.successfully') }}</div>
			<button
				class="confirm op-mt-20"
				mat-flat-button
				color="primary-small"
				id="agentIdCompleteButton"
				(click)="closeDialog()"
			>
				{{ t('GLOBAL.ok') }}
			</button>
		</div>
	</div>
</div>
