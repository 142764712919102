export enum MetadataEnum {
	LoanPurpose = 'LoanPurpose',
	USState = 'USState',
	AddressType = 'AddressType',
	Language = 'Language',
	PersonalNameSuffix = 'PersonalNameSuffix',
	PhoneType = 'PhoneType',
	DateRange = 'DateRange',
	FileTaxQuestion = 'FileTaxQuestion',
	IdentificationType = 'IdentificationType',
	SsnItinNone = 'SsnItinNone',
	USStatesRestrictedIDs = 'USStatesRestrictedIDs',
	Country = 'Country',
	Country2 = 'Country2',
	HousingType = 'HousingType',
	CheckCashingFee = 'CheckCashingFee',
	DebtType = 'DebtType',
	DebtTypeWithCreditHit = 'DebtTypeWithCreditHit',
	IndustryType = 'IndustryType',
	IncomeSourceType = 'IncomeSourceType',
	PaymentFrequency = 'PaymentFrequency',
	IncomeFrequency = 'IncomeFrequency',
	Relationship = 'Relationship',
	RelationshipEmergency = 'RelationshipEmergency',
	Weekday = 'Weekday',
	VehicleOwnershipType = 'VehicleOwnershipType',
	IDDocumentClassification = 'IDDocumentClassification',
	IncomeDocumentClassification = 'IncomeDocumentClassification',
	ResidenceDocumentClassification = 'ResidenceDocumentClassification',
	BankAccountDocumentClassification = 'BankAccountDocumentClassification'
}

export enum MetaIdentificationType {
	UsDriversLicense = 2201,
	Other = 2207
}
