<div class="summary" *transloco="let t">
	<op-masthead [title]="t('SUMMARY.title')"></op-masthead>
	<op-content-container class="content" *ngIf="initialized">
		<div class="icon-align op-mb-20">
			<mat-icon class="op-black" svgIcon="op-alert"></mat-icon>
			<span class="op-body-2-font">{{ t('SUMMARY.review') }}</span>
		</div>

		<div class="op-mb-20" *ngFor="let section of summarySections; let i = index">
			<div *ngIf="!section.hide" id="{{ 'sectionPanel_' + i }}">
				<hr />
				<div class="op-mt-20 sectionTitle" [attr.id]="section.section">
					<div class="op-header-3-font">{{ t(section.header) }}</div>
					<a
						mat-flat-button
						color="link-md"
						(click)="updateDropUserStatus()"
						aria-label="Edit"
						id="{{ 'sectionPanelEdit_' + section.section }}"
						[opTagClick]="'Summary edit ' + section.section"
						[routerLink]="[section.route]"
						[queryParams]="{ returnToSummary: section.section }"
					>
						{{ t('SUMMARY.edit') }}
					</a>
				</div>
				<div *ngFor="let field of section.fields; let j = index">
					<div *ngIf="field.value" class="op-full-width">
						<div
							class="fieldTitle op-body-2-font op-gray"
							id="{{ 'sectionPanel_' + i + '_title_' + j }}"
							*ngIf="field.titleValue"
						>
							{{ field.title }}
						</div>
						<div
							class="fieldTitle op-body-2-font op-gray"
							id="{{ 'sectionPanel_' + i + '_LocalizedTitle_' + j }}"
							*ngIf="!field.titleValue"
						>
							{{ t(field.title) }}
						</div>
						<div class="fieldValue op-mb-12" id="{{ 'sectionPanel_' + i + '_field_' + j }}">{{ field.value }}</div>
					</div>
				</div>
			</div>
		</div>

		<div>
			<hr />
			<div class="icon-align op-mb-20">
				<mat-icon class="op-black" svgIcon="op-alert"></mat-icon>
				<span class="op-body-2-font">{{ t('SUMMARY.review') }}</span>
			</div>
		</div>

		<div class="op-center">
			<button
				class="op-full-width"
				mat-flat-button
				color="primary"
				name="submitBtn2"
				id="submitBtn2"
				type="button"
				[opTagClick]="'Summary submit'"
				(click)="onSubmit()"
			>
				{{ t('GLOBAL.next') }}
			</button>
		</div>
		<div class="op-pb-40"></div>
	</op-content-container>
</div>
