import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';

import { ILoanApplication } from '../services/loan-application/loan-application.model';
import { LoanApplicationService } from '../services/loan-application/loan-application.service';

@Injectable({
	providedIn: 'root'
})
export class LoanApplicationResolver implements Resolve<ILoanApplication> {
	constructor(private loanAppService: LoanApplicationService) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILoanApplication> {
		return this.loanAppService.updateLoanApplication().pipe(catchError((error) => of(null)));
	}
}
