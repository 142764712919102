<div class="loan-purpose" *transloco="let t">
	<op-masthead *ngIf="!returnToFTR" [title]="t('LOAN_PURPOSE.title')"></op-masthead>
	<op-content-container class="content">
		<div class="op-label-font op-gray op-mb-20">{{ t('LOAN_PURPOSE.requiredFields') }}</div>

		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('LOAN_PURPOSE.purpose') }}</mat-label>
				<mat-select
					class="input-element"
					name="loanPurpose"
					id="loanPurpose"
					formControlName="loanPurpose"
					#purpose
					aria-label="purpose"
					required
					(focus)="onFocus()"
				>
					<mat-option
						*ngFor="let purpose of purposeList$ | async; let i = index"
						id="{{ 'purpose_' + purpose.key }}"
						[value]="purpose.key"
						>{{ purpose.text }}</mat-option
					>
				</mat-select>
			</mat-form-field>
			<div *ngIf="!isPartnerReferral && !returnToFTR">
				<op-info-tip
					id="referralInfoTip"
					[key]="t('LOAN_PURPOSE.referralCodeInfo')"
					ariaLabel="{{ t('LOAN_PURPOSE.referralCodeMoreInfo') }}"
				>
					<span class="op-body-1-bold-font">{{ t('LOAN_PURPOSE.haveReferralCode') }}</span>
				</op-info-tip>
				<p></p>

				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('LOAN_PURPOSE.referralCode') }}</mat-label>
					<input matInput type="text" name="referralCode" id="referralCode" formControlName="referralCode" />
					<mat-hint>
						{{ t('LOAN_PURPOSE.referralCodeLastStep') }}
					</mat-hint>
					<mat-error
						id="referralCodeError"
						*ngIf="formGroup.controls['referralCode'].invalid && formGroup.controls['referralCode'].touched"
					>
						{{ t('LOAN_PURPOSE.referralCodeError') }}
					</mat-error>
				</mat-form-field>

				<mat-checkbox
					*ngIf="formGroup.controls['referralCode'].value"
					class="op-mt-30"
					name="referralCodeConsent"
					id="referralCodeConsent"
					formControlName="referralCodeConsent"
					color="primary"
				>
					<span class="op-micro-font">{{ t('LOAN_PURPOSE.referralCodeCb') }}</span>
				</mat-checkbox>
			</div>

			<div class="op-center">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid || formGroup.pending"
					type="submit"
					[opTagClick]="'Loan-purpose next'"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
			<div class="op-pt-40"></div>
		</form>
	</op-content-container>
</div>
