import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProgressBarService } from '../../shared/services/progress-bar.service';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import {
	AddressTypeEnum,
	IAddressResult,
	ICassAddress,
	IFinancesResult,
	IMetadata,
	ISetAddressResult
} from 'src/app/core/services/mobile-api';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { AddAddressDialogComponent } from 'src/app/shared/components/add-address-dialog/add-address-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarAlertComponent } from 'src/app/shared/components/snack-bar-alert/snack-bar-alert.component';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { isAfter } from 'date-fns';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { LoggingService } from 'src/app/core/services/logging/logging.service';
import { CassAddressService } from 'src/app/core/services/ccas/cass-address.service';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { clone } from 'lodash';
import { PreQualifyService } from 'src/app/pre-qualify/pre-qualify.service';

@Component({
	selector: 'op-prequalify-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();
	private finances: IFinancesResult;
	address: IAddressResult;
	public addressForm: FormGroup;

	paydayLendingServiceOptions: Array<IMetadata> = [
		{ key: true, text: 'GLOBAL.yes' },
		{ key: false, text: 'GLOBAL.no' }
	];

	samePrimaryAddressOptions: Array<IMetadata> = [
		{ key: true, text: 'GLOBAL.yes' },
		{ key: false, text: 'GLOBAL.no' }
	];
	primaryAddressId: number;
	maillingAddressId: number;

	constructor(
		private progressBarService: ProgressBarService,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private _snackBar: MatSnackBar,
		private dateUtilService: DateUtilsService,
		private routingService: RoutingService,
		private metadataService: MetadataService,
		private logService: LoggingService,
		private cassAddressService: CassAddressService,
		private originationPartnerService: OriginationPartnerService,
		private authService: AuthenticationService,
		private preQualifyService: PreQualifyService
	) {}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {
		this.progressBarService.setProgressBarValue(this.route.snapshot.data?.step);
		this.addressForm = this.fb.group({
			sameMailingAddressAsPrimary: [null, [Validators.required]],
			firstMoveDate: [null, [Validators.required, this.moveInDateValidation()]],
			mailingAddress: [null, [Validators.required]],
			primaryAddress: [null, [Validators.required]]
		});

		this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				take(1),
				switchMap((loanApp: ILoanApplication) =>
					combineLatest([this.mobileService.getAddresses(loanApp.id), this.mobileService.getFinances(loanApp.id)])
				)
			)
			.subscribe({
				next: ([addresses, finances]: [addresses: IAddressResult[], finances: IFinancesResult]) => {
					this.setAddress(addresses);
					this.setFinances(finances);
				}
			});
	}

	get formControls(): any {
		return this.addressForm.controls;
	}

	public getFormattedAddress(address: IAddressResult): string {
		return AddressUtils.getFormattedAddress(address);
	}

	public onChangesameMailingAddressAsPrimary(value: boolean): void {
		value ? this.setMailingAddress(this.formControls.primaryAddress.value) : this.openAddAddressModal();
	}

	private setFinances(finances: IFinancesResult): void {
		this.finances = finances;
	}

	private setAddress(addresses: IAddressResult[]): void {
		const primaryAddress = AddressUtils.getHomeAddress(addresses);
		const mailingAddress = AddressUtils.getMailAddress(addresses);
		this.formControls.primaryAddress.patchValue({
			streetAddress1: primaryAddress.streetAddress1,
			streetAddress2: primaryAddress.streetAddress2 || '',
			city: primaryAddress.city,
			state: primaryAddress.state,
			postalCode: primaryAddress.postalCode
		});
		this.primaryAddressId = primaryAddress?.id;

		if (primaryAddress.moveInDate) {
			this.formControls.firstMoveDate.setValue(this.dateUtilService.parseISODate(primaryAddress.moveInDate));
		}

		if (mailingAddress) {
			this.setMailingAddress(mailingAddress);
			this.formControls.sameMailingAddressAsPrimary.patchValue(
				AddressUtils.addressesAreEqual(primaryAddress, mailingAddress)
			);
		}
	}

	private setMailingAddress(mailingAddress: IAddressResult): void {
		this.formControls.mailingAddress.patchValue({
			streetAddress1: mailingAddress.streetAddress1,
			streetAddress2: mailingAddress.streetAddress2 || '',
			city: mailingAddress.city,
			state: mailingAddress.state,
			postalCode: mailingAddress.postalCode,
			id: mailingAddress.id
		});
		this.maillingAddressId = mailingAddress?.id;
	}

	private moveInDateValidation(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const birthDate = this.loanAppService.getCurrentApplicant().dateOfBirth;
			const priorBirth = isAfter(this.dateUtilService.parseISODate(birthDate), control.value);
			const afterToday = isAfter(control.value, new Date());
			return priorBirth ? { priorToBirthDate: priorBirth } : afterToday ? { futureDate: true } : null;
		};
	}

	private saveAddress(address: IAddressResult, type: AddressTypeEnum): Observable<ISetAddressResult> {
		const addressId = type === AddressTypeEnum.home ? this.primaryAddressId : this.maillingAddressId;
		address.type = type;
		address.country = 'US';
		address.currentAddress = type === AddressTypeEnum.home;
		address.moveInDate = this.dateUtilService.format(this.formControls.firstMoveDate.value, 'yyyy-MM-dd');
		return addressId
			? this.mobileService.updateAddresses(address, addressId.toString(), this.loanAppService.loanApplicationId)
			: this.mobileService.setAddresses(address, this.loanAppService.loanApplicationId);
	}

	protected openAddAddressModal(): void {
		const data = {
			title: this.translocoService.translate('ADDRESS.mailAddress'),
			selectedAddress: this.formControls.mailingAddress.value
		};
		const dialogRef = this.dialogService.open(AddAddressDialogComponent, {
			data,
			minWidth: '350px',
			minHeight: '600px'
		});

		dialogRef
			.afterClosed()
			.pipe(map((rsp) => (Boolean(rsp) ? this.confirm(rsp) : this.cancel())))
			.subscribe();
	}

	confirm(address: IAddressResult): void {
		this.addressForm.get('mailingAddress').patchValue(address);
		this.formControls.sameMailingAddressAsPrimary.patchValue(
			AddressUtils.addressesAreEqual(this.formControls.primaryAddress.value, this.formControls.mailingAddress.value)
		);
		this._snackBar.openFromComponent(SnackBarAlertComponent, {
			data: {
				message: this.translocoService.translate('PRE_QUALIFY.ADDRESS.mailAddressUpdated'),
				icon: 'op-checkMark-outline'
			},
			panelClass: 'pre-qualify',
			duration: 1000,
			verticalPosition: 'bottom'
		});
	}

	cancel(): void {
		this.formControls.sameMailingAddressAsPrimary.patchValue(true);
		this.setMailingAddress(this.formControls.primaryAddress.value);
	}

	createAddressObject(addressType: AddressTypeEnum): ICassAddress {
		const address =
			addressType === AddressTypeEnum.home
				? this.formControls.primaryAddress.value
				: this.formControls.mailingAddress.value;
		return {
			streetAddress1: address?.streetAddress1,
			streetAddress2: address?.streetAddress2 || '',
			city: address?.city,
			state: address?.state,
			postalCode: address?.postalCode,
			country: 'US',
			type: addressType,
			currentAddress: addressType === AddressTypeEnum.home ? String(true) : String(false),
			originationCode: this.originationPartnerService.getOriginationCode() || null
		};
	}

	onSubmit(): void {
		const primaryAddress = this.formControls.primaryAddress.value;
		const save$ = [this.saveAddress(primaryAddress, AddressTypeEnum.home)];
		if (this.addressForm.get('sameMailingAddressAsPrimary').value) {
			const mailAddress = this.formControls.mailingAddress.value;
			save$.push(this.saveAddress(mailAddress, AddressTypeEnum.mail));
		}

		forkJoin(save$)
			.pipe(
				switchMap((values) => {
					if (!this.addressForm.get('sameMailingAddressAsPrimary').value) {
						return this.cassAddressService
							.verifyCassAddress(this.createAddressObject(AddressTypeEnum.mail))
							.pipe(switchMap((addr) => this.saveAddress(addr, AddressTypeEnum.mail)));
					} else {
						return of(values);
					}
				}),
				switchMap(() => this.loanAppService.updateLoanApplication())
			)
			.subscribe({
				next: (loanApp: ILoanApplication) => {
					this.preQualifyService.skipIdentityScreen
						? this.routingService.route(RoutingPathsEnum.preQualifyAdditionalInformation)
						: this.routingService.route(RoutingPathsEnum.preQualifyIdentification);
				},
				error: (err) => {
					this.dialogService
						.openErrorDialog(err)
						.pipe(
							filter(() => Array.isArray(err?.error) && err.error.some((e) => e?.id === 'appError1')),
							switchMap(() => this.authService.signOut()),
							tap(() => this.routingService.route(RoutingPathsEnum.findYourApplication))
						)
						.subscribe();
				}
			});
	}
}
