import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { AuthenticationService } from '../authentication/authentication.service';
import { SessionStorageService } from '../storage/session-storage.service';

export enum InstrumentEnum {
	opUuid = 'opUuid', // Required | UUID to indicate the unique identifier for the client-side event
	opPageId = 'opPageId', // Required | Text to describe the page from which the event was triggered
	opFeatureId = 'opFeatureId', // Required | Text to describe the feature that triggered the event
	opEventType = 'opEventType', // Required | Text to describe the specific event that was triggered
	opSessionId = 'opSessionId', // Required | UUID to indicate the client-side session associated with the event
	opProductId = 'opProductId', // Optional | Number to indicate the loan application Id
	opRefUuid = 'opRefUuid' // Optional | UUID to reference the previous connected unique identifier for the client-side event
}

export interface IInstrumentation {
	opUuid: string;
	opPageId: string;
	opFeatureId: string;
	opEventType: string;
	opSessionId: string;
	opProductId: string;
	opRefUuid: string;
}

@Injectable({
	providedIn: 'root'
})
export class InstrumentationService implements OnDestroy {
	private readonly instrumentSource = new BehaviorSubject<IInstrumentation>(null);

	// Exposed observable (read-only).
	readonly instrumentation$ = this.instrumentSource.asObservable();
	private readonly storageKey = 'instrumentation';

	private subscription = new Subscription();

	// NOTE: depending on loanAppService causes a circular dependency from the interceptor.
	// Get the loanAppId from sessionStorage in the interceptor.
	constructor(private authService: AuthenticationService, private sessionStorageService: SessionStorageService) {
		const sessionData = this.sessionStorageService.get(this.storageKey);
		const instrument = sessionData ? sessionData : {};

		if (instrument.opUuid) {
			instrument.opRefUuid = instrument.opUuid;
		} else {
			instrument.opSessionId = this.getUuid();
		}

		this.instrumentSource.next(instrument);

		// Clear out data if no token
		const authSub = this.authService.authorization$
			.pipe(
				map((rsp) => rsp?.token),
				map((token) => !token && this.sessionStorageService.remove(this.storageKey))
			)
			.subscribe();
		this.subscription.add(authSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public getInstrumentationValues(): IInstrumentation {
		return this.instrumentSource.getValue();
	}

	public setKeys(instrument: Partial<IInstrumentation>): void {
		this.updateSource(instrument);
	}

	private updateSource(instrument: Partial<IInstrumentation>): void {
		const values = this.instrumentSource.getValue();
		const update = { ...values, ...instrument };
		this.sessionStorageService.set(this.storageKey, update);
		this.instrumentSource.next(update);
	}

	public setKey(key: InstrumentEnum, value: string): void {
		const update = {};
		update[key] = value;
		this.updateSource(update);
	}

	public getUuid(): string {
		return uuidv4();
	}
}
