import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { EligibilityRoutingGuard } from '../core/guards/eligibility-routing.guard';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { EsignAutoPayComponent } from './esign-auto-pay/esign-auto-pay.component';
import { AutoPayAddBankComponent } from './esign-auto-pay/auto-pay-add-bank/auto-pay-add-bank.component';
import { EsignConfirmTermComponent } from './esign-confirm-term/esign-confirm-term.component';
import { EsignSignDocumentComponent } from './esign-sign-document/esign-sign-document.component';
import { ManuallyAddBankForteComponent } from '../shared/components/manually-add-bank-forte/manually-add-bank-forte.component';
import { FeatureNameEnum } from '../core/services/mobile-api';

const routes: Routes = [
	{
		path: RoutingPathsEnum.esignConfirmTerm,
		component: EsignConfirmTermComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.esignAutoPay,
		component: EsignAutoPayComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.esignAutoPayAddBank,
		component: AutoPayAddBankComponent,
		canActivate: [EligibilityRoutingGuard],
		data: { feature: FeatureNameEnum.instantDisbursementRachEnroll, fallBackPath: RoutingPathsEnum.esignAutoPay },
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.manuallyAddBankAccount,
		component: ManuallyAddBankForteComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.esignSignDocument,
		component: EsignSignDocumentComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TermsRoutingModule {}
