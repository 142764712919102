<div class="income" *transloco="let t">
	<op-masthead [title]="t('INCOME.title')"></op-masthead>
	<op-content-container class="content" *ngIf="ready">
		<div class="op-mb-16">{{ t('INCOME.details', { initialTakeHomePay: initialTakeHomePay | currency }) }}</div>
		<hr />
		<div class="op-label-font op-gray op-mt-16 op-mb-16" *ngIf="editing">{{ t('FINANCES.requiredFields') }}</div>

		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-accordion>
				<div *ngFor="let income of incomeList.controls; let i = index">
					<div [formGroup]="income">
						<op-income-add
							[parent]="income"
							[count]="incomeList.length"
							[index]="i + 1"
							id="{{ 'income_' + i }}"
							[newSourceText]="newSourceText"
							[incomeTypeList]="incomeSourceTypeList"
							[industryTypeList]="industryTypeList"
							[incomeFrequencyList]="incomeFrequencyList"
							(save)="saveIncome($event)"
							(delete)="deleteIncome($event)"
							(opened)="panelOpened($event)"
							(closed)="panelClosed($event)"
						>
						</op-income-add>
					</div>
				</div>
			</mat-accordion>

			<div class="op-center op-mt-40">
				<button
					class="op-full-width"
					*ngIf="!editing && hasIncomes()"
					mat-flat-button
					color="secondary"
					name="addIncome"
					id="addIncomeBtn"
					type="button"
					[opTagClick]="'Income add'"
					(click)="addIncome()"
				>
					{{ t('INCOME.add') }}
				</button>
				<div class="op-mb-20"></div>
				<button
					class="op-full-width"
					*ngIf="!editing"
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[opTagClick]="'Income next'"
					[disabled]="formGroup.invalid"
					type="submit"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>

		<div class="op-pb-40"></div>
	</op-content-container>
</div>
