import { Component } from '@angular/core';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-auto-pay-add-bank',
	templateUrl: './auto-pay-add-bank.component.html',
	styleUrls: ['./auto-pay-add-bank.component.scss']
})
export class AutoPayAddBankComponent {
	constructor(private routingService: RoutingService) {}

	rachBankAccountAdded(bankInfo) {
		if (bankInfo?.status === 'ACTIVE') {
			this.routingService.route(RoutingPathsEnum.esignAutoPay);
		}
	}
}
