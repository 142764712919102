import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { ProductCategoriesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import { LoanApplicationService } from '../../../core/services/loan-application/loan-application.service';

// FIXME: Add the PAYMENT_FREQUENCIES enum here

@Component({
	selector: 'op-status-approved',
	templateUrl: './approved.component.html',
	styleUrls: ['./approved.component.scss']
})
export class ApprovedComponent {
	private _loanApp: ILoanApplication;

	hasMultipleValidProductOffers: boolean;
	isSPL: boolean;
	applicant: IApplicant;
	plaidIncentiveApplied: boolean;
	productOfferDetailUtils: ProductOfferDetailsUtils;

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
		this.productOfferDetailUtils = ProductOfferDetailsUtils.fromLoanApp(value);
		this.hasMultipleValidProductOffers = this.getHasMultipleValidProductOffers();
		this.isSPL = this.getIsSecuredPersonalLoan(value);
		this.applicant = this.loanAppService.getCurrentApplicant();
		this.plaidIncentiveApplied = this.applicant.plaidIncentiveApplied;
	}

	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Output()
	next = new EventEmitter<any>();

	constructor(private loanAppService: LoanApplicationService, private sessionStorageService: SessionStorageService) {}

	get applicantName(): string {
		const firstName = this.applicant?.firstName || '';
		const lastName = this.applicant?.lastName || '';
		return `${firstName} ${lastName}`;
	}

	getHasMultipleValidProductOffers(): boolean {
		return this.productOfferDetailUtils.hasMultipleValidProductOffers();
	}

	getIsSecuredPersonalLoan = (loanApplication: ILoanApplication): boolean =>
		loanApplication.productCategory === ProductCategoriesEnum.unsecuredPersonalLoan;

	submit(): void {
		this.next.emit({ next: 'adjustterms' });
	}
}
