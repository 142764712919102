import { Pipe, PipeTransform } from '@angular/core';
import { IMetadata } from 'src/app/core/services/mobile-api';

/**
 * Get text from metadata array.
 *
 * @export
 * @class GetMetadataTextPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opGetMetadataText'
})
export class GetMetadataTextPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		const metadata = args[0] as IMetadata[];
		return metadata?.find((item) => item.key === value).text;
	}
}
