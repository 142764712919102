import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { isFunction } from 'lodash';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

import { SelectBankAccountComponent } from '../select-bank-account/select-bank-account.component';

@Component({
	selector: 'op-select-bank-account-ext',
	templateUrl: './select-bank-account-ext.component.html',
	styleUrls: ['./select-bank-account-ext.component.scss']
})
export class SelectBankAccountExtComponent extends SelectBankAccountComponent {
	@Output()
	addAnotherBankAccount = new EventEmitter<boolean>();

	private groupedAccountList: Map<string, IAchBankAccount[]>;

	constructor(
		protected formBuilder: FormBuilder,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected loanAppService: LoanApplicationService,
		protected softPull: SoftPullService,
		protected sessionStorageService: SessionStorageService,
		protected mobileService: MobileApiService,
		protected routingService: RoutingService
	) {
		super(
			formBuilder,
			bankAccountService,
			tagDataService,
			loanAppService,
			softPull,
			sessionStorageService,
			mobileService
		);
	}

	ngOnInit(): void {
		this.isSoftPullEnabled$ = this.softPull.softPull$.pipe(map((rsp) => rsp?.value));

		if (this.isGCPOrReturn) {
			this.bankAccountList$ = this.mobileService.getAchBankAccountsForApplicant(this.loanAppService.loanApplicationId);
		} else {
			this.bankAccountList$ = this.bankAccountService.bankAccounts$;
		}

		const bankSub = combineLatest([
			this.bankAccountList$,
			this.loanAppService.isGCPOrReturnApplicant() ? this.bankAccountService.returningBankAccounts$ : of([])
		])
			.pipe(
				map(([rsp, returningRsp]) => rsp?.concat(returningRsp) || []),
				filter((r) => Boolean(r)),
				tap(() => this.accountList.clear()),
				map((rsp) => (!this.areAllAccountsVerified(rsp) && isFunction(this.filter) ? rsp.filter(this.filter) : rsp))
			)
			.subscribe({
				next: (accounts) => {
					if (accounts.length) {
						this.updateFormData(accounts);

						if (accounts?.length > 1 && !this.isGCPOrReturn) {
							this.createTealiumEvent();
						}
					}
					if (this.groupedAccountList) {
						this.groupedAccountList = new Map();
					}

					this.groupedAccountList = accounts.reduce(
						(entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]),
						new Map(this.groupedAccountList)
					);
					this.showAccountList = Boolean(accounts.length);
				}
			});
		this.subscription.add(bankSub);

		this.selectAccountTitle = this.getSelectAccountTitle();
	}

	updateSelectedValidator(selected: number): void {
		let validators = [Validators.required];
		this.formGroup.get('selectedId').setValidators(validators);
		this.formGroup.get('selectedId').updateValueAndValidity();
	}

	addNewBankAccount() {
		this.addAnotherBankAccount.emit(true);
	}
}
