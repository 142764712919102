<div class="product-offer-card" *transloco="let t">
	<mat-card id="splApproved">
		<mat-card-content>
			<div class="op-header-2-font">{{ t('ADJUST_TERMS.LOAN_TYPES.securedPersonalLoan') }}</div>
			<div class="op-body-1-bold-font productStatus op-ptb-8">{{ t('PRODUCT.PRODUCT_STATUSES.approved') }}</div>
			<div class="loan-amount">
				<div class="op-header-4-font">{{ t('OFFER_STATUS.upTo') }}</div>
				<div class="op-display-2-font">
					{{ product.maxApprovedAmount | currency: 'USD':'symbol':'0.0' }}
					<sup>*</sup>
				</div>
			</div>
			<div class="continue-button op-full-width">
				<button
					class="op-full-width"
					mat-flat-button
					color="primary-small"
					id="securedPersonalLoanContinueBtn"
					(click)="onContinue('adjustTerms')"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
