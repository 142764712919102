import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { LoanExamplesService } from 'src/app/core/services/loan-examples/loan-examples.service';

import { EN_PRIVACY_LINK, ES_PRIVACY_LINK } from '../../../shared/constants/language-const';
import { STATE_CA, STATE_OH } from '../../../shared/constants/states-const';

@Component({
	selector: 'op-legal-disclosures',
	templateUrl: './legal-disclosures.component.html',
	styleUrls: ['./legal-disclosures.component.scss']
})
export class LegalDisclosuresComponent implements OnInit, OnDestroy {
	@Input() customerState: string;
	@Input() isMetaOrganization: boolean;
	@Output()
	saveToFTR = new EventEmitter<any>();
	formGroup: FormGroup;
	showWirelessOperationsAuth = false;
	privacyLink: string;
	private subscription: Subscription;
	isCA: boolean;
	isOH: boolean;

	constructor(
		private loanExampleService: LoanExamplesService,
		private formBuilder: FormBuilder,
		private languageService: LanguageService
	) {
		this.createForm(this.formBuilder);
	}

	ngOnInit(): void {
		this.isCA = this.isCustomerStateCA();
		this.isOH = this.isCustomerStateOH();

		this.subscription = this.languageService.langChanges$.subscribe({
			next: (lang) => {
				this.privacyLink = this.languageService.isSpanish ? ES_PRIVACY_LINK : EN_PRIVACY_LINK;
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	openLoanExamplesModal(): void {
		this.loanExampleService.openLoanExamplesModal(this.customerState).subscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			acceptLegalDisclosure: [false, Validators.requiredTrue]
		});
	}

	onSubmit(): void {
		this.saveToFTR.emit();
	}
	toggleWirelessOperationsAuth(): void {
		this.showWirelessOperationsAuth = !this.showWirelessOperationsAuth;
	}
	isCustomerStateCA(): boolean {
		return this.customerState === STATE_CA;
	}

	isCustomerStateOH(): boolean {
		return this.customerState === STATE_OH;
	}
}
