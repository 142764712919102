import {
	HttpContextToken,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

import { DialogService } from '../services/dialog/dialog.service';
import { LoggingService } from '../services/logging/logging.service';

export const SKIP_ERROR_RETRY = new HttpContextToken<boolean>(() => false);

/**
 * handle http errors
 *
 * @export
 * @class HttpErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthenticationService,
		private router: Router,
		private translocoService: TranslocoService,
		private dialogService: DialogService,
		private loggingService: LoggingService
	) {}
	readonly retryStatus = [0, 504, 502];
	readonly maxRetry = 1;

	isRetryMaxed(count: number): boolean {
		return count > this.maxRetry;
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const skipRetry = request.context.get(SKIP_ERROR_RETRY);
		return next.handle(request).pipe(
			retry({
				delay: (error: any, retryCount: number) => {
					if (error instanceof HttpErrorResponse && error.status === 401) {
						this.authService.clearAuthorizationToken();
						// NOTE: Can not use routing service because of a circular dependency.
						this.router.navigate(['find-your-application']);
					} else if (this.retryStatus.some((s) => s === error.status) && !this.isRetryMaxed(retryCount) && !skipRetry) {
						this.loggingService.error(`network retry count:${retryCount} :${error.status}`, error);
						return timer(500);
					} else if (this.isRetryMaxed(retryCount)) {
						this.dialogService
							.openErrorDialog(this.translocoService.translate('ERROR_MESSAGE.networkError'))
							.subscribe();
						this.loggingService.error(`max networkError encountered: ${error.status}`, error);
						this.router.navigate(['find-your-application']);
					}
					throw error;
				}
			})
		);
	}
}
