<div class="offer-status-information" *transloco="let t">
	<op-masthead>
		<a
			mat-flat-button
			color="link-no-dec"
			href=""
			id="backToOtherOffer"
			class="back-to-other-offer"
			onclick="return false"
			(click)="declineSecuredOffer()"
		>
			<mat-icon svgIcon="op-caretLeft"></mat-icon>
			{{ t('SECURED_PERSONAL_LOAN.LEARN_MORE.backToOffers') }}
		</a>
	</op-masthead>
	<op-content-container>
		<op-learn-more
			iconSize="small"
			icon="op-accountingBills"
			[title]="t('SECURED_PERSONAL.LEARN_MORE.whatSecuredPersonalLoan')"
		>
			<p [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.whatSecuredPersonalLoanAnswerDual')"></p>
			<p [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.whatSecuredPersonalLoanAnswerDual2')"></p>
			<p>
				<span [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.whatSecuredPersonalLoanAnswerDual3')"></span>
				<a
					mat-flat-button
					color="link-md"
					[href]="whatIsSecuredPersonalLoanLink"
					target="_blank"
					[opTagClick]="'What is secured personal loan'"
					id="whatIsSecuredPersonalLoanLink"
					rel="noopener"
				>
					<span [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.whatSecuredPersonalLoanAnswerDual4')"></span>
				</a>
			</p>
		</op-learn-more>

		<div class="op-center op-mt-20">
			<button
				mat-flat-button
				color="primary"
				class="op-full-width"
				id="checkVehicleBtn"
				(click)="checkVehicleEligibility()"
				[opTagClick]="'See if my vehicle qualifies'"
			>
				{{ t('SECURED_PERSONAL_LOAN.LEARN_MORE.seeIfVehicleQualifies') }}
			</button>
			<p class="credit-score-text op-label-font">
				{{ t('SECURED_PERSONAL_LOAN.LEARN_MORE.doesNotEffectCreditDual') }}
			</p>
		</div>

		<div class="op-mt-20">
			<div class="op-header-3-font op-mb-12" [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.letUsHelp')"></div>
			<div class="op-mb-20" [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.letUsHelpAnswerDual')"></div>

			<div>
				<button
					mat-flat-button
					color="primary"
					class="op-full-width"
					id="talkToAgentBtn"
					(click)="talkToAgent()"
					[opTagClick]="'Talk to an agent'"
				>
					<mat-icon svgIcon="op-phoneCall"></mat-icon>
					{{ t('SECURED_PERSONAL_LOAN.LEARN_MORE.talkToAnAgent') }}
				</button>
			</div>

			<op-horizontal-rule [text]="t('GLOBAL.or')"></op-horizontal-rule>

			<!-- <h3 [innerHTML]="t('SECURED_PERSONAL_LOAN.LEARN_MORE.notInterestedQuestion')"></h3> -->

			<div class="center">
				<button
					mat-flat-button
					color="secondary"
					id="declineOfferBtn"
					class="op-full-width"
					(click)="declineSecuredOffer()"
					[opTagClick]="'Not interested'"
				>
					{{ t('SECURED_PERSONAL_LOAN.LEARN_MORE.returnToOffers') }}
				</button>
			</div>
		</div>
	</op-content-container>
</div>
