import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IAddressSelectionDialogData {
	title: string;
	suggestedAddress: any;
	originalAddress: any;
}

@Component({
	selector: 'op-address-selection-dialog',
	templateUrl: './address-selection-dialog.component.html',
	styleUrls: ['./address-selection-dialog.component.scss']
})
export class AddressSelectionDialogComponent {
	constructor(
		private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<AddressSelectionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IAddressSelectionDialogData
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			address: ['', [Validators.required]]
		});
	}

	onSubmit(post: any): void {
		this.dialogRef.close(`${post.address}`);
	}
}
