import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-offer-available',
	templateUrl: './offer-available.component.html',
	styleUrls: ['./offer-available.component.scss']
})
export class OfferAvailableComponent implements OnInit, OnDestroy {
	constructor(
		private landingService: LandingService,
		private routingService: RoutingService,
		private tagDataService: TagDataService
	) {}

	private subscription = new Subscription();
	user;
	loanAmount;

	ngOnInit(): void {
		const specialOfferSub = this.landingService.landing$.subscribe({
			next: (rsp) => {
				this.user = rsp.specialOfferLead;
				this.loanAmount = rsp.specialOfferLeadInformation?.amount;
			}
		});
		this.subscription.add(specialOfferSub);
		this.tagDataService.view(
			{},
			{
				opEventId: 'view',
				tealium_event: 'dp-offer-found-landing'
			}
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	startApplicationWithOffer(): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: 'dp-offer-found-apply'
			}
		);
		this.routingService.route(RoutingPathsEnum.personalInfo);
	}
}
