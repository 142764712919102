<div class="offer-status-information" *transloco="let t">
	<op-masthead [title]="t(title)">
		<div id="vehicleEligibilityQuestionTitle" class="op-header-1-font op-mb-0">
			{{ t('VEHICLE_ELIGIBILITY_QUESTION.title') }}
		</div>
	</op-masthead>

	<op-content-container>
		<div>
			<p class="op-black">{{ t('VEHICLE_ELIGIBILITY_QUESTION.subTitle') }}</p>
		</div>
		<div class="op-mt-20 op-icon-align-body-2">
			<mat-icon svgIcon="op-info-grey"></mat-icon>
			<span class="op-ml-2">{{ t('VEHICLE_ELIGIBILITY_QUESTION.note1') }}</span>
		</div>
		<div class="op-mt-16 op-icon-align-body-2">
			<mat-icon svgIcon="op-car-eligibility"></mat-icon>
			<span>{{ t('VEHICLE_ELIGIBILITY_QUESTION.note2') }}</span>
		</div>

		<div class="op-center op-mt-20">
			<button
				mat-flat-button
				color="primary"
				class="op-full-width"
				id="ownVehicleBtn"
				(click)="forwardToVehiclePage()"
				[opTagClick]="'See if my vehicle qualifies'"
			>
				{{ t('VEHICLE_ELIGIBILITY_QUESTION.btn_own_vehicle') }}
			</button>
		</div>
		<div class="op-center op-mt-20">
			<button
				mat-flat-button
				color="secondary"
				class="op-full-width"
				id="exploreLaterBtn"
				(click)="confirmToMoveToOfferStatus()"
				[opTagClick]="'See if my vehicle qualifies'"
			>
				{{ t('VEHICLE_ELIGIBILITY_QUESTION.btn_explore_later') }}
			</button>
		</div>

		<div class="op-mt-40">
			<div id="faqSubtitle" class="op-header-3-font op-mb-20">
				{{ t('VEHICLE_ELIGIBILITY_QUESTION.faq') }}
			</div>
			<op-expansion-panel
				id="askAboutMyCarPanel"
				[titleBold]="false"
				[title]="t('VEHICLE_ELIGIBILITY_QUESTION.askAboutMyVehicle')"
				[opTagClick]="'What are the differences of UPL and SPL?'"
			>
				<p>{{ t('VEHICLE_ELIGIBILITY_QUESTION.askAboutMyVehicleText') }}</p>
			</op-expansion-panel>

			<op-expansion-panel
				id="whatAreVehRequirementPanel"
				[titleBold]="false"
				[title]="t('VEHICLE_ELIGIBILITY_QUESTION.whatAreRequirementsQ')"
				[opTagClick]="'What are the vehicle requirements'"
			>
				<ul>
					<li>{{ t('VEHICLE_ELIGIBILITY_QUESTION.whatAreRequirementsA1') }}</li>
					<li class="op-mt-20">{{ t('VEHICLE_ELIGIBILITY_QUESTION.whatAreRequirementsA2') }}</li>
					<li class="op-mt-20">
						{{ t('VEHICLE_ELIGIBILITY_QUESTION.whatAreRequirementsA3', { customerStateName: customerStateName }) }}
					</li>
					<li class="op-mt-20">
						{{
							t('VEHICLE_ELIGIBILITY_QUESTION.whatAreRequirementsA4', {
								numOfDaysToRenewRegistration: numOfDaysToRenewRegistration
							})
						}}
					</li>
					<li class="op-mt-20">{{ t('VEHICLE_ELIGIBILITY_QUESTION.whatAreRequirementsA5') }}</li>
				</ul>
			</op-expansion-panel>
		</div>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
