<div class="legalDisclosure" *transloco="let t">
	<h5 class="op-left">
		<span>{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.hereAreSome') }}</span>
		<a href="" onclick="return false" (click)="openLoanExamplesModal()" id="loanExamplesModalLink">
			{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.loanExamples') }}
		</a>
		<span>
			{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.individualLoanTerms') }}
		</span>
	</h5>
	<form class="legal-disclosures-form" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
		<h5 class="op-left">
			<div>
				<mat-checkbox
					name="acceptLegalDisclosure"
					id="acceptLegalDisclosure"
					formControlName="acceptLegalDisclosure"
					color="primary"
				>
					<span>{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.byAccepting') }}</span>
				</mat-checkbox>
			</div>
			<br />
			<div>
				<span>
					{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure1') }}
				</span>
				<a [routerLink]="['/terms']" target="_blank" id="termsOfServiceLink" rel="noopener">{{
					t('PERSONAL_INFO.tos2')
				}}</a>
				<a [href]="privacyLink" target="_blank" id="privacyPolicyLink" rel="noopener"> {{ t('PERSONAL_INFO.tos5') }}</a
				>,
				<span ngClass="isMetaOrganization ? 'op-body-1-font' : 'op-label-font'">
					<a [routerLink]="['/loan-disclosures']" id="loanDisclosuresLink">{{ t('SOFT_PULL.disclosures2') }}</a>
					{{ t('SOFT_PULL.disclosures3') }},
					<a href="" onclick="return false" (click)="toggleWirelessOperationsAuth()" id="wirelessOperationsAuthLink">{{
						t('SOFT_PULL.wirelessOperationsAuth')
					}}</a
					>,
				</span>

				<div class="op-mt-20" *ngIf="showWirelessOperationsAuth" id="wirelessOperationsAuth">
					{{ t('CREDIT_AUTH.wirelessOperationsAuthMsg') }}
				</div>
			</div>
			<br />
			<div *ngIf="!isMetaOrganization; else metaDisclosures">
				<div>{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure2') }}</div>
				<br />
				<div>
					<span>
						{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure3') }}
					</span>
				</div>
			</div>
			<ng-template #metaDisclosures>
				<div>{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure2_meta') }}</div>
				<br />
				<div>
					<span> {{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure3_meta') }} </span>
				</div>
			</ng-template>
			<br />
			<div *ngIf="isCA" class="op-label-font op-gray op-mt-10">
				<span>
					{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure4') }}
				</span>
			</div>
			<div *ngIf="isOH" class="op-label-font op-gray op-mt-10">
				<span>
					{{ t('FAST_TRACK.FTR_LEGAL_DISCLOSURE.disclosure4_OH') }}
				</span>
			</div>
		</h5>
		<div class="left">
			<button
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="submitBtn"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('GLOBAL.submit') }}
			</button>
		</div>
	</form>
</div>
