import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
	selector: 'op-pre-qualified',
	templateUrl: './pre-qualified.component.html',
	styleUrls: ['./pre-qualified.component.scss']
})
export class PreQualifiedComponent implements OnInit, OnDestroy {
	language: string;
	constructor(private translocoService: TranslocoService) {}

	subscription: Subscription;

	ngOnInit(): void {
		this.subscription = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.language = lang;
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
