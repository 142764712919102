import { formatDate } from 'src/app/core/services/date-utils/date-utils';
import { IAddressResult } from 'src/app/core/services/mobile-api';
import { AddressUtils } from 'src/app/core/utils/address-utils';

import { SummarySection } from './section.model';

/**
 * Stores address summary information.
 *
 * @param {IAddress} address
 * @returns {SummarySection}
 */
export const addressSection = (
	address: IAddressResult,
	mailingAddress: IAddressResult,
	sameString: string
): SummarySection => {
	const sameAddress = AddressUtils.addressesAreEqual(address, mailingAddress);
	return {
		section: 'address',
		header: 'SUMMARY.yourAddress',
		route: '/address',
		fields: [
			{
				title: 'SUMMARY.address',
				value: address
					? ['streetAddress1', 'streetAddress2', 'city'].map((key) => address[key]).join(' ') +
					  `, ${address.state} ${address.postalCode}`
					: ' '
			},
			{
				title: 'SUMMARY.movedToAddress',
				value: address ? formatDate(address.moveInDate, 'MMM yyyy') : ' '
			},
			{
				title: 'SUMMARY.mailingAddress',
				value: sameAddress
					? `${sameString}`
					: mailingAddress
					? ['streetAddress1', 'streetAddress2', 'city'].map((key) => mailingAddress[key]).join(' ') +
					  `, ${mailingAddress.state} ${mailingAddress.postalCode}`
					: ' '
			}
		]
	};
};
