import { Component, EventEmitter, Input, Output } from '@angular/core';

import { opRequired } from '../../decorators/required.decorator';

@Component({
	selector: 'op-expansion-panel',
	templateUrl: './expansion-panel.component.html',
	styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent {
	@Input()
	expanded: boolean = false;

	@Input()
	@opRequired()
	id: string;

	@Input()
	titleBold: boolean = true;

	@Input()
	applyColor: boolean = false;

	@Input() title: string;

	@Output()
	opened = new EventEmitter<void>();

	@Output()
	closed = new EventEmitter<void>();
}
