<footer class="op-footer">
	<div class="text-center op-micro-font">
		<a class="footer-link" [routerLink]="['/terms']" [state]="{ back: true }" id="footerTermsLink">
			{{ 'FOOTER.terms' | transloco }}
		</a>
		|
		<a class="footer-link" href="{{ privacyLink }}" id="footerPrivacyPolicyLink">{{ 'FOOTER.privacy' | transloco }}</a>
		|
		<a class="footer-link" href="{{ disclosureLink }}" target="_blank" id="footerDisclosuresLink" rel="noopener">
			{{ 'FOOTER.disclosures' | transloco }}
		</a>
		|
		<a
			class="footer-link"
			href=""
			onclick="return false"
			[opTagClick]="'change_language'"
			(click)="switchLanguage()"
			title="language"
			aria-label="Language"
			id="footerLanguageToggleLink"
			>{{ 'FOOTER.language' | transloco }}</a
		>
		|
		<a
			class="footer-link"
			[routerLink]="['/accessibility']"
			title="accessibility"
			aria-label="accessibility"
			id="footerAccessibilityLink"
			>{{ 'FOOTER.accessibility' | transloco }}</a
		>
		|
		<a class="footer-link" id="footerPreQualifyLink" [routerLink]="['/pre-qualified']">
			{{ 'FOOTER.preQual' | transloco }}
		</a>
	</div>
</footer>
