<div class="esign-how-to-print-save-loan" *transloco="let t">
	<op-masthead [title]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.title')"></op-masthead>
	<op-content-container class="content">
		<p [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.message1')"></p>
		<p [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.message2')"></p>

		<h3 [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.android')"></h3>
		<p [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.android_message1')"></p>

		<h3 [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.ios')"></h3>
		<p [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.ios_message1')"></p>

		<h3 [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.desktop')"></h3>
		<p [innerHTML]="t('ESIGN.HOW_TO_PRINT_SAVE_LOAN.desktop_message1')"></p>
	</op-content-container>
</div>
