<div class="proof-of-selfie" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead [customClass]="'head-bp-4'" [title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.selfieTitle')">
	</op-masthead>
	<op-content-container class="content">
		<div *ngIf="proofState === proofStatusEnum.upload">
			<div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								)
							"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</div>

			<op-document-notification *ngIf="bounceReasons?.length" id="selfieBounceReasons" class="attention op-mtb-20">
				<ul>
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification>

			<op-document-upload
				id="selfieUpload"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[showSingleFile]="true"
				[delayUpload]="true"
				[fileSide]="'front'"
				#docUpload
			>
				<div uploadIdleInfo>
					<div *ngIf="!showProgress">
						<img alt="" src="/assets/images/icons/op-selfiePlaceholder.svg" [opTagClick]="id + 'selfie' + '_upload'" />
					</div>
					<div
						class="op-dark-grey op-mt-8 op-body-2-font"
						[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
					></div>
				</div>

				<div uploadIdleButtonText>
					<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.takeSelfie') }}</div>
				</div>
			</op-document-upload>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="selfieContinueBtn"
					id="selfieContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.saveAndContinue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="selfieCancelBtn"
					id="selfieCancelBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Cancel'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>
		<div class="op-pb-40"></div>
	</op-content-container>
</div>
