<div class="status-submitted" *transloco="let t">
	<op-content-container *ngIf="!vwoWidget">
		<op-masthead [title]="t(subTitle)"></op-masthead>
		<div id="submittedStatusCheck" class="op-center submitted-image">
			<img
				alt=""
				class="op-center"
				src="/assets/images/submitted-status-check.svg"
				role="presentation"
				height="100"
				width="200"
			/>
		</div>
		<div id="submittedStatusMessage" class="op-body-1-font op-mb-20" [innerHTML]="t(body)"></div>
		<hr />

		<div id="supportContactDays" class="op-mt-20 op-body-1-font">
			<div id="supportContactDayText">{{ t('SUPPORT_CONTACT.topText') }}</div>
			<div id="supportContactDay1">{{ t('SUPPORT_CONTACT.supportDays1') }}</div>
			<div id="supportContactDay2">{{ t('SUPPORT_CONTACT.supportDays2') }}</div>
		</div>

		<div id="supportContactNumber" class="op-mt-20">
			{{ t('SUPPORT_CONTACT.callUsText') }}
			<a mat-flat-button color="link-md" (click)="contactUs()" id="callUsLink" [opTagClick]="'888-564-0084'">
				{{ t('SUPPORT_CONTACT.customerSupportNumber') }}
			</a>
		</div>

		<div id="howItWorksPanel" class="how-it-works-panel">
			<op-how-it-works-panel></op-how-it-works-panel>
		</div>
	</op-content-container>

	<op-content-container *ngIf="vwoWidget">
		<op-masthead class="op-black" [title]="t('STATUS.SUBMITTED.vwoInitial')"></op-masthead>

		<div *transloco="let t">
			<div class="status-submitted">
				<mat-card id="vwoStatus" class="vwoSubmitStatus">
					<mat-card-content>
						<h4 class="content-title">{{ t('SUPPORT_CONTACT.vwoNextStep') }}</h4>
						<ul>
							<li>
								<span id="status_1" class="op-black"> {{ t('SUPPORT_CONTACT.vwoStep1') }}</span>
							</li>
							<li class="op-mt-10">
								<span id="status_2" class="op-black">
									{{ t('SUPPORT_CONTACT.vwoStep2') }}
								</span>
							</li>
						</ul>
						<div class="statusInfo">
							{{ t('SUPPORT_CONTACT.vwoSupport') }}
						</div>
					</mat-card-content>
				</mat-card>
				<div class="op-mt-20">
					<div id="supportContactNumberVWO" class="statusInfo">
						{{ t('SUPPORT_CONTACT.vwoCallUs') }}
						<a class="green-underline" id="callUsLinkVWO" (click)="contactUs()" [opTagClick]="'1-888-564-0084'"
							>Call {{ t('SUPPORT_CONTACT.customerSupportNumber') }}
						</a>
					</div>
					<div class="statusInfo op-mt-10">
						{{ t('SUPPORT_CONTACT.vwoSupportDays1') + '  -  ' }} {{ t('SUPPORT_CONTACT.vwoSupportDays2') }}
					</div>
				</div>
			</div>
		</div>
	</op-content-container>
</div>
