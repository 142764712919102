<div class="status-approved" *transloco="let t">
	<div id="approvedCongratsDiv">
		<h1>
			<span class="congratulations">{{ t('STATUS.APPROVED.congratulations') }}</span>
			<span class="applicant-name">{{ applicantName }}</span>
		</h1>
		<img alt="" src="/assets/images/loan-complete.svg" />
	</div>

	<div id="approvedFor" class="approved-for">
		<h3>
			<span>{{ t('STATUS.APPROVED.approvedFor') }}</span>
		</h3>
	</div>

	<div id="loanAmount" class="loan-amount">
		<div id="statusCardSection" class="status-card-section">
			<div id="statusCard" class="status-card">
				{{ loanApp.loanTerms.loanAmount | currency }}
			</div>
		</div>
	</div>

	<div id="loanTermsInfo" class="loan-terms-info">
		<span id="personalize" class="personalize">{{ t('STATUS.APPROVED.personalize') }}</span>
		<span id="financialInfoSpan"> {{ t('STATUS.APPROVED.financialInfo') }}</span>
	</div>

	<div id="buttonContainer" class="button-container">
		<button
			id="startBtn"
			mat-flat-button
			color="primary"
			name="submitBtn"
			type="submit"
			[opTagClick]="'status_approved start'"
			(click)="submit()"
		>
			{{ t('GLOBAL.start') }}
		</button>
	</div>

	<div class="op-icon-align-body-1 op-mt-20 bank-connected" *ngIf="plaidIncentiveApplied">
		<mat-icon svgIcon="op-tagsDouble"></mat-icon>
		<span [innerHTML]="t('PRODUCT.PRODUCT_STATUSES.bankAccountConnected')"></span>
	</div>

	<div class="op-mt-20 op-left op-ml-10">
		<op-approved-loan-additional-info-panels [loanAmount]="loanApp?.loanTerms?.loanAmount | currency">
		</op-approved-loan-additional-info-panels>
	</div>
</div>
