import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { mergeMap } from 'rxjs';

import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ISetAchBankAccountDetail, MobileApiService } from 'src/app/core/services/mobile-api';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';
import { BankVerificationDialogComponent } from 'src/app/shared/components/dialogs/bank-verification-dialog/bank-verification-dialog.component';
import { AddManuallyComponent } from './add-manually.component';

@Component({
	selector: 'op-add-manually-terms-confirmed',
	templateUrl: './add-manually.component.html',
	styleUrls: ['./add-manually.component.scss']
})
export class AddManuallyTermsConfirmComponent extends AddManuallyComponent {
	protected titleKey: string = 'TERMS_CONFIRMED.ONLINE_STORE_OPTIONS.ONLINE.addBankAccountToDirectDeposit';

	protected nextRoute = RoutingPathsEnum.esignAutoPay;

	constructor(
		protected dialogService: DialogService,
		protected routingService: RoutingService,
		protected translocoService: TranslocoService,
		private mobileApiService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {
		super(dialogService, routingService, translocoService);
	}

	bankDetailsSaved($event: ISetAchBankAccountDetail): void {
		if ($event?.verified) {
			this.mobileApiService
				.updateAchBankAccount(
					{
						bankAccountId: $event.id,
						customerConfirms: $event.customerConfirms
					},
					this.loanAppService.loanApplicationId
				)
				.pipe(
					mergeMap((result) => {
						return this.mobileApiService.updateDisbursementChannel(
							{
								disbursementChannel: DisbursementChannelEnum.online,
								disbursementType: DisbursementType.ach
							},
							this.loanAppService.loanApplicationId
						);
					}),
					mergeMap(() => {
						return this.dialogService.openSimpleDialog(FundingMethodConfirmComponent, {
							data: {
								fundingOptions: DisbursementType.ach,
								account: {
									bankName: $event.bankName,
									accountType: $event.accountType,
									accountNumber: $event.accountNumber
								}
							}
						});
					})
				)
				.subscribe({
					next: (result) => {
						this.routingService.route(this.nextRoute);
					},
					error: () => {}
				});
		} else {
			const data = {
				title: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailed'),
				message: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailMessage'),
				retryText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.retry')
			};
			this.dialogService.openSimpleDialog(BankVerificationDialogComponent, { data }).subscribe();
		}
	}
}
