import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ILoanApplication } from '../core/services/loan-application/loan-application.model';
import { LoanApplicationService } from '../core/services/loan-application/loan-application.service';
import { AuthenticationService } from '../core/services/authentication/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class PreQualifyResolver implements Resolve<ILoanApplication> {
	constructor(private loanAppService: LoanApplicationService, private authService: AuthenticationService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<ILoanApplication> {
		const loanId = route.params?.loanId;
		const token = route.params?.token;
		this.authService.updateAuthorizationToken(`SJWT ${token}`);
		return this.loanAppService.updateLoanApplication(loanId);
	}
}
