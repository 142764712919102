import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { OfferStatusComponent } from './offer-status/offer-status.component';
import { OfferStatusGuard } from './offer-status/offer-status.guard';
import { LoanBenefitsComponent } from './loan-benefits/loan-benefits.component';
import { StatusComponent } from './status/status.component';

const routes: Routes = [
	{
		path: RoutingPathsEnum.status,
		component: StatusComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.approved,
		component: StatusComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.offerStatus,
		component: OfferStatusComponent,
		canActivate: [AuthenticationGuard, OfferStatusGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.loanBenefits,
		component: LoanBenefitsComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class StatusRoutingModule {}
