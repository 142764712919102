import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';

@Component({
	template: ''
})
export class LandingComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {
		this.router.navigate([RoutingPathsEnum.preQualifyAddress]);
	}
}
