import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { compose, sortBy, uniqBy } from 'lodash/fp';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IAddressResult, IZipCodeLookUpResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';

import { IAutoCompleteAddress, ILatLngLimiter } from '../../directives/address-auto-complete.directive';
import { homeStreetAddress1NoSpecialCharactersValidator, zipCodeValidator } from '../../validators/form-validators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defer } from 'lodash';

export interface IAddAddressDialogData {
	title: string;
	selectedAddress: IAddressResult;
}

@Component({
	selector: 'op-add-address-dialog',
	templateUrl: './add-address-dialog.component.html',
	styleUrls: ['./add-address-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AddAddressDialogComponent implements OnInit, OnDestroy {
	formGroup: FormGroup;
	stateList$: Observable<IMetadata[]>;
	private subscription = new Subscription();
	latLngLimiter: ILatLngLimiter;
	expandAddress: boolean;

	get value(): IAddressResult {
		return this.formGroup.value;
	}
	set value(value: IAddressResult) {
		this.formGroup.patchValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(
		private formBuilder: FormBuilder,
		private metadataService: MetadataService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		public dialogRef: MatDialogRef<AddAddressDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IAddAddressDialogData
	) {
		this.createForm(this.formBuilder);
		// any time the inner form changes update the parent of any change
		const formValueChangeSub = this.formGroup.valueChanges.subscribe((value) => {
			this.onChange(value);
			this.onTouched();
		});
		this.subscription.add(formValueChangeSub);
		this.onZipCodeChange();
		const selectedAddress = data.selectedAddress;
		if (selectedAddress) {
			this.writeValue(selectedAddress);
		}

		// work around to address the problem of Google Places autocomplete options not displaying in relation to the text field
		defer(() => (document.querySelector<HTMLElement>('html').style.top = '20px'));
	}

	ngOnInit(): void {
		this.stateList$ = this.metadataService
			.select(MetadataEnum.USState)
			.pipe(map((val) => compose(sortBy('text'), uniqBy('text'))(val as any) as any[]));
	}

	createForm(fb: FormBuilder): FormGroup {
		return (this.formGroup = fb.group({
			streetAddress1: [
				'',
				[Validators.required, Validators.maxLength(30), homeStreetAddress1NoSpecialCharactersValidator()]
			],
			streetAddress2: [],
			city: [null, Validators.required],
			state: [null, Validators.required],
			postalCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10), zipCodeValidator()]]
		}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onChange: any = () => {
		// This is intentional empty
	};

	onZipCodeChange: any = () => {
		// update home zip lookup if it changes
		const zipSub = this.formGroup
			.get('postalCode')
			.valueChanges.pipe(
				skip(1),
				filter((zip) => zip?.length === 5),
				distinctUntilChanged(),
				switchMap((zip) => this.updateFromZipLookUp(zip))
			)
			.subscribe();
		this.subscription.add(zipSub);
	};

	onTouched: any = () => {
		// This is intentional empty
	};

	/**
	 * Partially update the form from a zipCode look up.
	 *
	 * @returns {Observable<IZipCodeLookUpResult>}
	 * @memberof AddressComponent
	 */
	updateFromZipLookUp(zipCode: string = null): Observable<IZipCodeLookUpResult> {
		const zip = zipCode || this.loanAppService.getCurrentApplicant()?.homePostalCode;
		return this.mobileService.zipCodeLookUp(zip).pipe(
			tap((resp) => {
				this.formGroup.get('postalCode').setValue(zip);
				this.formGroup.get('city').setValue(resp?.populateAddress?.city[0]);
				this.formGroup.get('state').setValue(resp?.populateAddress?.state[0]);
				if (resp.populateAddress) {
					this.latLngLimiter = {
						lat: parseFloat(resp.populateAddress.latitude[0]),
						lng: parseFloat(resp.populateAddress.longitude[0])
					};
				}
			})
		);
	}

	registerOnChange(fn): void {
		this.onChange = fn;
	}

	registerOnTouched(fn): void {
		this.onTouched = fn;
	}

	handleAddressChange(event: IAutoCompleteAddress): void {
		this.expandAddress = true;
		this.formGroup.patchValue({
			streetAddress1: event?.address,
			streetAddress2: '',
			city: event?.city,
			state: event?.state,
			postalCode: event?.postalCode
		});
	}

	writeValue(value): void {
		if (value) {
			this.value = value;
			this.expandAddress = true;
		}
		if (value === null) {
			this.formGroup.reset();
		}
	}

	onSubmit() {
		this.dialogRef.close(this.formGroup.value);
	}
}
