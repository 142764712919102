import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to notify when the busy spinner is shown.
 *
 * @export
 * @class MobileApiBusyNotifierService
 */
@Injectable({
	providedIn: 'root'
})
export class MobileApiBusyNotifierService {
	private readonly busySource = new BehaviorSubject<boolean>(null);
	readonly busy$ = this.busySource.asObservable();

	beginRequest(): void {
		this.busySource.next(true);
	}

	endRequest(): void {
		this.busySource.next(false);
	}
}
