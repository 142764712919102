<div class="steps-headline" *transloco="let t">
	<div class="steps-title" *ngIf="!showInfo">
		<div class="op-right">
			<img [src]="stepIcon" class="img-responsive" alt="" role="presentation" />
		</div>
		<div class="headline">
			<div class="title" (click)="expandToggle()" id="{{ id + '_howItWorksMoreInfoTitle' }}">
				<div>{{ headline }}</div>
			</div>
			<a mat-flat-button color="op-link" (click)="expandToggle()" id="{{ id + '_howItWorksMoreInfo' }}">
				<strong>{{ t('HOW_IT_WORKS.moreInfo') | uppercase }}</strong>
				<mat-icon svgIcon="op-caretRightSmall"></mat-icon>
			</a>
		</div>
	</div>

	<div class="steps-info" *ngIf="showInfo">
		<div class="info-title" (click)="expandToggle()" id="{{ id + '_howItWorksMoreInfoClose' }}">
			<h3>
				<strong id="step-headline-label">{{ headline }}</strong>
			</h3>
			<mat-icon svgIcon="op-close"></mat-icon>
		</div>
		<p id="steps-info" [innerHTML]="info"></p>
		<a
			class="wrap"
			mat-flat-button
			color="op-link"
			*ngIf="listOfIds"
			(click)="showMoreInfoToggle()"
			id="{{ id + '_moreInfoListOfIds' }}"
		>
			{{ listOfIds }}
		</a>
		<div class="types" *ngIf="showMoreInfo" id="{{ id + '_howItWorksIdTypes' }}">
			<ul>
				<li *ngFor="let type of idTypes">{{ type.text }}</li>
			</ul>
		</div>
	</div>
</div>
