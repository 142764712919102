import { Component } from '@angular/core';

import { StepBaseComponent } from '../step-base/step-base.component';

@Component({
	selector: 'op-step-info',
	templateUrl: './step-info.component.html',
	styleUrls: ['./step-info.component.scss']
})
export class StepInfoComponent extends StepBaseComponent {
	constructor() {
		super();
	}
}
