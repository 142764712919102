import { Component, Input } from '@angular/core';

import { ProductOfferCardComponent } from '../product-offer-card/product-offer-card.component';

@Component({
	selector: 'op-unsecured-personal-loan-approved',
	templateUrl: './unsecured-personal-loan-approved.component.html',
	styleUrls: ['./unsecured-personal-loan-approved.component.scss']
})
export class UnsecuredPersonalLoanApprovedComponent extends ProductOfferCardComponent {
	@Input()
	plaidIncentiveApplied: boolean;

	constructor() {
		super();
	}

	onContinue(method: string): void {
		this.continue.emit({ method, productCategory: this.product.productCategory });
	}
}
