import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { CreateAccountComponent } from './create-account/create-account.component';
import { DisbursedRoutingModule } from './disbursed-routing.module';
import { HowToManageLoanComponent } from './loan-completed/how-to-manage-loan/how-to-manage-loan.component';
import { LoanCompletedComponent } from './loan-completed/loan-completed.component';
import { ReferralComponent } from './referral/referral.component';

/**
 * Disbursed status components.
 *
 * @export
 * @class DisbursedModule
 */
@NgModule({
	declarations: [CreateAccountComponent, LoanCompletedComponent, ReferralComponent, HowToManageLoanComponent],
	imports: [CommonModule, SharedModule, TranslocoModule, DisbursedRoutingModule, NgxMaskModule.forChild()],
	exports: []
})
export class DisbursedModule {}
