import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'op-sign-online',
	templateUrl: './sign-online.component.html',
	styleUrls: ['./sign-online.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignOnlineComponent {
	@Input() showAddBankDesc = false;
}
