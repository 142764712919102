<div class="esign-event-signing-failed" *transloco="let t">
	<op-masthead [title]="t('ESIGN.EVENT.SIGNING_FAILED.title')"></op-masthead>
	<op-content-container class="content">
		<p [innerHTML]="t('ESIGN.EVENT.SIGNING_FAILED.message')"></p>
		<p [innerHTML]="t('ESIGN.EVENT.SIGNING_FAILED.message2')"></p>

		<button mat-flat-button color="primary" id="closeBtn" (click)="signOut()">
			{{ t('GLOBAL.close') }}
		</button>
	</op-content-container>
</div>
