<div class="proof-of-address" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead [customClass]="'head-bp-4'" [title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfAddress.addressTitle')">
	</op-masthead>
	<op-content-container class="content" *ngIf="documentClassificationList$ | async as classificationList">
		<op-document-classification
			*ngIf="proofState === proofStatusEnum.classificationSelection"
			id="addressClassification"
			[categoryType]="categoryType"
			[documentClassificationList]="classificationList"
			(documentClassification)="saveDocumentClassification($event)"
			(cancel)="onCancel($event)"
		>
		</op-document-classification>

		<div *ngIf="proofState === proofStatusEnum.upload">
			<div
				*ngIf="
					stateStorage?.selectedDocumentClassification?.key === 'UTILITY_BILL' ||
					stateStorage?.selectedDocumentClassification?.key === 'PHONE_BILL' ||
					stateStorage?.selectedDocumentClassification?.key === 'CABLE_BILL'
				"
				class="icon-align op-mb-20"
				id="criteria0_30"
			>
				<mat-icon svgIcon="op-info-green"></mat-icon>
				<span
					class="icon-text"
					[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfAddress.provideBillDate')"
				></span>
			</div>
			<div>
				<div class="op-header-3-font op-mb-8">{{ stateStorage?.selectedDocumentClassification?.text }}</div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfAddress.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								)
							"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</div>

			<op-document-notification class="attention op-mtb-20" *ngIf="bounceReasons?.length" id="addressBounceReasons">
				<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification>

			<op-document-upload
				id="addressUpload"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[classificationVerification]="autoVerificationMap"
				[showSingleFile]="false"
			>
				<div uploadIdleInfo>
					<div
						class="op-dark-grey op-mt-8 op-body-2-font"
						[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentFormats')"
					></div>
				</div>
				<div uploadIdleButtonText>
					<div>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
				</div>
			</op-document-upload>

			<div class="op-mt-40">
				<button
					mat-flat-button
					color="primary"
					name="addressContinueBtn"
					id="addressContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="getPageId() + Continue"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="addressCancelBtn"
					id="addressCancelBtn"
					(click)="onCancel()"
					[opTagClick]="'cancel address'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>

		<div *ngIf="proofState === proofStatusEnum.status">
			<op-document-status
				id="addressStatus"
				[categoryType]="categoryType"
				[classificationVerification]="autoVerificationMap"
				(done)="onDocStatus($event)"
				[documentClassificationList]="classificationList"
			>
				<div [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.additionDocInfo')"></div>

				<op-document-notification class="attention op-mtb-20" *ngIf="bounceReasons?.length" id="addressBounceReasons">
					<ul [ngClass]="{ 'no-bullets': bounceReasons.length === 1 }">
						<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
					</ul>
				</op-document-notification>
			</op-document-status>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="doneBtn"
					id="addressStatusBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</div>
		<div class="op-pb-40"></div>
	</op-content-container>
</div>
