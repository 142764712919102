import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPartnerResponse } from '../mobile-api/partner-api/partner-api.model';
import { PartnerApiService } from '../mobile-api/partner-api/partner-api.service';
import { SessionStorageService } from '../storage/session-storage.service';

const DEFAULT_MAPPING = { code: 'OPORTUN', brand: 'Oportun' } as IPartnerResponse;

@Injectable({
	providedIn: 'root'
})
export class OriginationPartnerService {
	brandMappings = [DEFAULT_MAPPING] as IPartnerResponse[];

	constructor(private partnerApiService: PartnerApiService, private sessionStorageService: SessionStorageService) {}

	saveOriginationCodeIfEligible(originationCode: string): void {
		this.partnerApiService.getOriginationCodeEligibility(originationCode).subscribe({
			next: (value) => {
				if (value === true) {
					this.sessionStorageService.set('originationCode', originationCode);
				} else {
					this.sessionStorageService.remove('originationCode');
				}
			}
		});
	}

	getOriginationCode(): string {
		return this.sessionStorageService.get('originationCode');
	}

	getPartnerBrand(code: string): Observable<string> {
		return this.getPartner(code).pipe(
			map((partner) => {
				return partner?.brand || DEFAULT_MAPPING.brand;
			})
		);
	}

	getPartner(code: string): Observable<IPartnerResponse> {
		if (isEmpty(code) || code === DEFAULT_MAPPING.code) {
			return of(DEFAULT_MAPPING);
		} else {
			const mapping = this.brandMappings.find((item) => item.code === code);
			if (!isEmpty(mapping?.brand)) {
				return of(mapping);
			} else {
				return this.partnerApiService.getPartnerBrand(code).pipe(
					map((brandMapping) => {
						this.brandMappings.push(brandMapping);
						return brandMapping;
					})
				);
			}
		}
	}
}
