import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * Load a remote script when needed
 *
 * @export
 * @class ScriptLoaderService
 */
@Injectable({
	providedIn: 'root'
})
export class ScriptLoaderService {
	private libraries: { [url: string]: ReplaySubject<any> } = {};

	constructor(@Inject(DOCUMENT) private readonly document: any) {}

	loadScript(url: string, async: boolean = false): Observable<any> {
		if (this.libraries[url]) {
			return this.libraries[url].asObservable();
		}

		this.libraries[url] = new ReplaySubject();

		const script = this.document.createElement('script');
		script.async = async;
		script.type = 'text/javascript';
		script.src = url;
		script.onerror = (e: any) => this.libraries[url].error(e);
		script.onload = () => {
			this.libraries[url].next(url);
			this.libraries[url].complete();
		};
		this.document.body.appendChild(script);

		return this.libraries[url].asObservable();
	}
}
