import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isFunction, noop } from 'lodash';
import { catchError, filter, map, of, Subscription } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	EventDataService,
	EventDataTagActionEnum,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import { ApplicationStatusEnum } from 'src/app/core/services/loan-application/loan-application.model';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { BankVerificationDialogComponent } from 'src/app/shared/components/dialogs/bank-verification-dialog/bank-verification-dialog.component';
import { ApplicationFlowEnum, ConfigApiService } from 'src/app/core/services/mobile-api';

@Component({
	selector: 'op-bank-verification',
	templateUrl: './bank-verification.component.html',
	styleUrls: ['./bank-verification.component.scss']
})
export class BankVerificationComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();
	manualBankExpanded = false;
	hidePlaid = false;
	isPrequalApplication: boolean = false;
	showNewDesign = undefined;

	constructor(
		private router: Router,
		private routingService: RoutingService,
		private eventDataService: EventDataService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private plaidLinkService: PlaidLinkService,
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private configService: ConfigApiService
	) {
		const extras = this.router.getCurrentNavigation().extras;
		this.manualBankExpanded = this.hidePlaid = extras?.state?.hidePlaid;
	}

	ngOnInit(): void {
		const loanSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApp: ILoanApplication) => {
				this.isPrequalApplication =
					loanApp.applicationFlow === ApplicationFlowEnum.oportunPrequal &&
					loanApp.applicationStatus === ApplicationStatusEnum.prequalAccepted;
			}
		});
		this.subscription.add(loanSub);

		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);
		this.logEvents();
		this.getConfigValue();
	}

	getConfigValue(): void {
		this.configService
			.showNewFundingDesign(this.loanAppService.loanApplicationId)
			.pipe(
				map((res) => res.result),
				catchError((err) => {
					return of(false);
				})
			)
			.subscribe((value) => {
				this.showNewDesign = value;
				const tagEvent = {
					event_action: 'on_page_load',
					applicant_id: this.loanAppService.loanApplicationId,
					issuing_org: this.loanAppService.getLoanApp()?.issuingOrganization,
					clientId: this.loanAppService.getCurrentApplicant()?.clientId,
					show_new_design: String(this.showNewDesign)
				};
				this.tagDataService.view({}, tagEvent);
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onBankConnect(event: IBankConnectEvent): void {
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		const accountsFound = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;
		const msgData = this.plaidLinkService.getBankDialogMessage(accountsFound);
		if (msgData) {
			this.dialogService
				.openMessageDialog(
					msgData,
					() => of(),
					() => of()
				)
				.subscribe({
					next: () => {
						this.tagDataService.link(
							{},
							{
								tealium_event: 'bank_not_found'
							}
						);
					}
				});
		}
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.dialogService
			.openMessageDialog(
				this.plaidLinkService.getBankDialogMessage(false),
				() => of(),
				() => of()
			)
			.subscribe();
	}

	skipPlaid(): void {
		this.next();
		this.logEvents(EventDataTagActionEnum.skipEventAction);
	}

	skip(): void {
		this.next();
	}

	notSaved(event): void {
		this.notVerified();
	}

	saved(event): void {
		if (!this.hidePlaid) {
			this.manualBankExpanded = false;
		}

		if (event?.verified === false) {
			this.notVerified();
		} else {
			this.hidePlaid && this.next();
		}
	}

	onNext(): void {
		this.next();
	}

	notVerified(): void {
		const data = {
			title: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailed'),
			message: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verificationFailMessage'),
			retryText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.retry'),
			manualText: this.translocoService.translate('BANK_VERIFICATION_DIALOG.verifyManually'),
			manualDisclaimer: this.translocoService.translate('BANK_VERIFICATION_DIALOG.submitDocuments')
		};

		this.dialogService
			.openDialog(
				BankVerificationDialogComponent,
				{ data },
				() => of(true),
				() => of(false)
			)
			.subscribe({
				next: (manually) => manually && this.next()
			});
	}

	logEvents(event?): void {
		const plaidEvent = {
			eventType:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagTypeEnum.skipEventType
					: EventDataTagTypeEnum.bankPageEventType,
			value1:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagActionEnum.skipEventAction + '-' + this.routingService.getCurrentRoute()
					: EventDataTagValueEnum.bankPageValue
		};
		const tagEvent = {
			tealium_event:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagTypeEnum.skipEventType
					: EventDataTagTypeEnum.bankPageEventType,
			event_action:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagActionEnum.skipEventAction + '-' + this.routingService.getCurrentRoute()
					: EventDataTagValueEnum.bankPageValue
		};
		this.tagDataService.link({}, tagEvent);
		this.eventDataService.logEventDataToLoanApplication(plaidEvent);
	}

	next(): void {
		if (this.isPrequalApplication) {
			this.verifyIdentity();
		} else {
			this.routingService.routeBtm();
		}
	}

	verifyIdentity(): void {
		this.mobileService.updateIdentity(this.loanAppService.loanApplicationId).subscribe({
			next: (loanApp: ILoanApplication) => {
				const route =
					loanApp.applicationStatus === ApplicationStatusEnum.preApproved
						? RoutingPathsEnum.funds
						: RoutingPathsEnum.offerStatus;
				this.routingService.route(route);
			}
		});
	}
}
