import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { isFunction } from 'lodash';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { IOfferCodeResult, IZipCodeResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { nameValidator } from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-offer-code',
	templateUrl: './offer-code.component.html',
	styleUrls: ['./offer-code.component.scss'],
	animations: [
		trigger('insertRemoveTrigger', [
			transition(':enter', [style({ opacity: 0 }), animate('800ms', style({ opacity: 1 }))])
		]),
		trigger('openClose', [
			state(
				'open',
				style({
					height: '*',
					opacity: 1
				})
			),
			state(
				'closed',
				style({
					height: '0',
					opacity: 0
				})
			),
			transition('open => closed', [animate('0.2s')]),
			transition('closed => open', [animate('0.2s')])
		])
	]
})
export class OfferCodeComponent implements OnInit {
	@Output()
	promoCodeInfo = new EventEmitter<IOfferCodeResult>();

	@ViewChild('toggleOpen') toggleOpen: ElementRef;
	@ViewChild('toggleClose') toggleClose: ElementRef;

	showOfferCode: boolean = false;
	formGroup: FormGroup;
	promoResult: IZipCodeResult | IOfferCodeResult;
	helpMenuOpen: string;
	showError = false;

	constructor(
		private formBuilder: FormBuilder,
		private landingService: LandingService,
		private translocoService: TranslocoService
	) {
		this.formGroup = this.formBuilder.group({
			offerNumber: [null, [Validators.required, Validators.maxLength(15)]],
			lastName: [null, [Validators.required, nameValidator()]]
		});
	}

	ngOnInit(): void {
		this.translocoService.langChanges$.subscribe({
			next: () => {
				this.showError = false;
			}
		});
	}

	expandOfferCode(): void {
		this.showOfferCode = !this.showOfferCode;

		// set timeout to allow time for hidden elements to show.
		setTimeout(() => {
			this.showOfferCode ? this.toggleClose.nativeElement.focus() : this.toggleOpen.nativeElement.focus();
		}, 25);
	}

	clear(): void {
		this.formGroup.reset();
	}

	onSubmit(form: any): void {
		const zip = this.landingService.getZipCode();
		const promo = form.offerNumber;
		const lastName = form.lastName;

		this.landingService.searchZipCode(zip, promo, lastName).subscribe({
			next: (result) => {
				this.promoResult = result;
				this.showError = 'errorMessage' in result ? true : false;
				if ('first_name' in result) {
					this.promoCodeInfo.emit(result);
				}
			}
		});
	}

	@HostListener('keydown.enter', ['$event.target'])
	@HostListener('keydown.space', ['$event.target'])
	onClick(target: any): void {
		const targetElement = target?.id;
		const targetForm = target?.form;
		if (targetElement === 'clickForOfferCode') {
			this.expandOfferCode();
		} else if (targetElement === 'offerCodeBtn') {
			this.onSubmit(targetForm);
		} else if (targetElement === 'offerClearLink') {
			this.clear();
		}
		isFunction(target?.preventDefault) && target?.preventDefault();
	}
}
