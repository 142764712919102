import { formatDate } from 'src/app/core/services/date-utils/date-utils';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IIdentificationResult } from 'src/app/core/services/mobile-api/identification-api/identification-api.model';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';

import { SummarySection } from './section.model';

/**
 * Stores general summary information.
 *
 * @param {IApplicant} applicant
 * @param {IIdentificationResult} id
 * @param {IMetadata[]} language
 * @param {IMetadata[]} loanPurpose
 * @returns {SummarySection}
 */
export const personalInformationSection = (
	applicant: IApplicant,
	id: IIdentificationResult,
	language: IMetadata[],
	phones: PhoneUtils,
	phoneType: IMetadata[],
	phoneDuration: IMetadata[],
	yes: string,
	no: string
): SummarySection => {
	const phone = phones.phoneResults.phones?.find(Boolean);
	return {
		section: 'yourInformation',
		header: 'SUMMARY.yourInformation',
		route: '/personal-info',
		fields: [
			{
				title: 'SUMMARY.name',
				value: `${applicant?.firstName || ''} ${applicant?.middleName || ''} ${applicant?.lastName || ''}
				${applicant?.maternalName || ''} ${applicant?.suffix || ''}`
			},
			{
				title: 'SUMMARY.dateOfBirth',
				value: id?.dateOfBirth ? formatDate(id.dateOfBirth, 'MM/dd/yyyy') : ' '
			},
			{
				title: 'SUMMARY.phoneNumber',
				value: phone?.phoneNumber
			},
			{
				title: 'SUMMARY.phoneType',
				value: phoneType?.find((item) => item.key === phone?.phoneType)?.text
			},
			{
				title: 'SUMMARY.howLongPhone',
				value: phoneDuration?.find((item) => item.key === phone?.ownerDateRange)?.text
			},
			{
				title: 'SUMMARY.smsNotifications',
				value: phones?.isSmsAuthorization ? yes : no
			},
			{
				title: 'SUMMARY.email',
				value: applicant?.emailAddress || ' '
			},
			{
				title: 'PERSONAL_INFO.preferredLanguage',
				value: language?.find((item) => item.key === applicant?.preferredLanguage)?.text || ' '
			}
		]
	};
};
