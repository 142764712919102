export const SORRY_SOMETHING_WENT_WRONG = 'Sorry, something went wrong';

export const LOAN_TERMS_MISMATCH_ERROR = 'termsMismatch1';

// cspell:disable-next-line
export const USER_INFORMATION_STR = 'userinformation';

export const REQUEST_PARAM_APP_SOURCE = 'app-source';

export const EXISTING_LOAN_MISSING = 'existingLoanMissing';

// cspell:disable-next-line
export const EXISTING_LOAN_PAIDOFF = 'existingLoanPaidOff';

export const EXISTING_LOAN_MISMATCH = 'existingLoanMismatch';

export const REFERRAL_PARTNER = {
	WESTERN_UNION: 'WESTERN_UNION'
};
