import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-how-to-manage-loan',
	templateUrl: './how-to-manage-loan.component.html',
	styleUrls: ['./how-to-manage-loan.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HowToManageLoanComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	showCreateButton: boolean = false;

	@Input()
	isMobile: boolean = false;

	@Output()
	mobileService = new EventEmitter<void>();

	@Output()
	createAccount = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}
}
