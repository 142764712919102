import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ILeadByNameAddressInformation, LandingService } from 'src/app/core/services/landing/landing.service';
import { ILeadsByNameAddress, MobileApiService } from 'src/app/core/services/mobile-api';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-handle-prescreen-application',
	template: ''
})
export class HandlePrescreenApplicationComponent implements OnInit {
	constructor(
		private activatedRoute: ActivatedRoute,
		private landingService: LandingService,
		private routingService: RoutingService,
		private mobileService: MobileApiService
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(({ ps_rt }) => {
			if (!ps_rt) {
				return this.redirectToHome();
			}
			this.mobileService.setDecryptPrescreenToken(ps_rt).subscribe({
				next: (res) => this.processPrescreenOfferInfo(res.data),
				error: () => this.redirectToHome()
			});
		});
	}

	redirectToHome(): void {
		this.routingService.route(RoutingPathsEnum.home);
	}

	private processPrescreenOfferInfo(leadInfo): void {
		if (!leadInfo) {
			return this.redirectToHome();
		}

		const {
			firstName,
			lastName,
			streetAddress1,
			streetAddress2,
			city,
			state,
			zipCode,
			partnerSource,
			status,
			issuingOrganization,
			finderNumber
		} = leadInfo;

		const specialOfferData: ILeadsByNameAddress = {
			firstName,
			lastName,
			streetAddress1,
			streetAddress2,
			city,
			state,
			zipCode
		};
		const specialOfferResult: ILeadByNameAddressInformation = {
			partnerSource,
			status,
			issuingOrganization,
			finderNumber
		};

		this.landingService.updatePrescreenOfferDetails(specialOfferData, specialOfferResult);
		this.routingService.route(RoutingPathsEnum.personalInfo);
	}
}
