<div id="coAppOfferedOuterDiv" class="status-co-app-offered" *transloco="let t">
	<op-masthead
		[title]="t('STATUS.CO_APP_OFFERED.heading')"
		[subTitle]="t('STATUS.CO_APP_OFFERED.subheading')"
	></op-masthead>
	<op-content-container>
		<div id="helpWithCoAppDiv" class="op-body-1-font op-mb-20">
			<strong>
				{{ t('STATUS.CO_APP_OFFERED.helpWithCoApp') }}
			</strong>
		</div>
		<div id="callToContinueDiv" class="op-body-1-font op-mb-20">
			<strong>
				{{ t('STATUS.CO_APP_OFFERED.callToContinue') }}
			</strong>
		</div>

		<div id="callNowDiv" class="op-mtb-20">
			<button id="callNowBtn" mat-flat-button color="primary" name="submitBtn" (click)="triggerCallDialog()">
				{{ t('STATUS.CO_APP_OFFERED.callNow') }}
			</button>
		</div>
		<hr />

		<div id="newApplicationMsgDiv" class="op-icon-align-body-1 reapply op-mtb-20">
			<mat-icon id="calendarIcon" svgIcon="op-calendar"></mat-icon>
			<span class="op-body-2-font">
				<strong>
					{{
						t('STATUS.CO_APP_OFFERED.reApplyDisclaimer', { newApplicationStartDate: newApplicationStartDate | date })
					}}
				</strong>
			</span>
		</div>

		<div id="withQualifiedCoAppDiv" class="op-body-2-font op-mb-20">
			{{ t('STATUS.CO_APP_OFFERED.withQualifiedCoApp') }}
		</div>

		<div id="unableToContinueDiv" class="op-body-2-font op-mb-20">
			{{ t('STATUS.CO_APP_OFFERED.unableToContinue') }}
		</div>

		<hr />

		<div class="op-mtb-20" *ngIf="issuingOrganizationIsOportun">
			<p class="op-header-2-font">{{ t('STATUS.NOT_APPROVED.wantToHelp') }}</p>
		</div>

		<op-unidos-us-information *ngIf="issuingOrganizationIsOportun"></op-unidos-us-information>
	</op-content-container>
</div>
