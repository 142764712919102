export const icons = [
	'op-accountingBills',
	'op-add',
	'op-add2',
	'op-alert',
	'op-alert2',
	'op-alert3',
	'op-alert4',
	'op-ascend',
	'op-arrowLeft',
	'op-atmCard',
	'op-attention',
	'op-attention-orange',
	'op-backgroundCheck',
	'op-bank',
	'op-calendar',
	'op-caretLeft',
	'op-caretRightSmall',
	'op-checkMark-dark-green',
	'op-checkMark-green',
	'op-checkMark-outline',
	'op-checkMark-solid',
	'op-checkMark',
	'op-chevron-right',
	'op-circle-green',
	'op-close',
	'op-cloud',
	'op-cloudUpload',
	'op-connected',
	'op-delete-active',
	'op-delete-focus',
	'op-delete-hover',
	'op-delete',
	'op-document',
	'op-dropdown',
	'op-earth',
	'op-email',
	'op-graphAscend',
	'op-hamburgerMenu',
	'op-help',
	'op-history',
	'op-house',
	'op-info-blue-fill',
	'op-info-green',
	'op-info-grey',
	'op-info',
	'op-location',
	'op-messageBubble',
	'op-mobilePhone',
	'op-moneyBag',
	'op-notifications',
	'op-paperCheck',
	'op-payByCheck',
	'op-pencil',
	'op-phone',
	'op-phoneCall',
	'op-photoCamera',
	'op-plus',
	'op-plus-gray',
	'op-plus-dark-gray',
	'op-popout',
	'op-processing',
	'op-processingInfo',
	'op-protection',
	'op-ready',
	'op-refreshDouble',
	'op-required',
	'op-share',
	'op-smiley',
	'op-star',
	'op-subtract',
	'op-tagCash',
	'op-tagsDouble',
	'op-trash',
	'op-viewOff',
	'op-viewOn',
	'op-clipBoard',
	'op-autoPay',
	'op-car-eligibility'
];
