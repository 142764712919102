import { Component, Input } from '@angular/core';

@Component({
	selector: 'op-approved-loan-additional-info-panels',
	templateUrl: './approved-loan-additional-info-panels.component.html',
	styleUrls: ['./approved-loan-additional-info-panels.component.scss']
})
export class ApprovedLoanAdditionalInfoPanelsComponent {
	@Input()
	loanAmount: string;
}
