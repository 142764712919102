import { Component } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-selfie-panel',
	templateUrl: './section-selfie-panel.component.html',
	styleUrls: ['./section-selfie-panel.component.scss']
})
export class SectionSelfiePanelComponent extends ExpandSectionComponent {
	constructor() {
		super();
	}
}
