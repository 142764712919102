<div class="document-section" *transloco="let t">
	<op-document-expansion-panel
		id="vehiclePhotoPanel"
		title="{{ t('DOCUMENT_SUBMIT.vehiclePhotos.title') }}"
		[fileList]="fileList"
		[expanded]="expanded"
		(opened)="opened.emit(fileType, $event)"
		(closed)="closed.emit(fileType, $event)"
	>
		<div class="panel-content">
			<ul>
				<li>{{ t('DOCUMENT_SUBMIT.vehiclePhotos.requirements.driverSide') }}</li>
				<li>{{ t('DOCUMENT_SUBMIT.vehiclePhotos.requirements.vin') }}</li>
				<li>{{ t('DOCUMENT_SUBMIT.vehiclePhotos.requirements.odometer') }}</li>
				<li>
					<a
						href=""
						onclick="return false"
						(click)="openVehicleReferenceGuideModal()"
						id="vehicleReferenceGuideModalLink"
					>
						{{ t('DOCUMENT_SUBMIT.vehiclePhotos.requirements.referenceGuide') }}
					</a>
				</li>
			</ul>
			<op-file-upload
				id="vehiclePhotoFileUpload"
				[categoryType]="fileType"
				requiredFileType="image/jpg,image/jpeg,image/gif,image/png,image/bmp"
			>
			</op-file-upload>
		</div>
	</op-document-expansion-panel>
</div>
