import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	AbstractControlOptions,
	AsyncValidatorFn,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { iif, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, map, tap, switchMap, take } from 'rxjs/operators';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductSubStatusesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { IAchBankAccountResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { IMessageDialogData, MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'op-manually-add-bank-forte',
	templateUrl: './manually-add-bank-forte.component.html',
	styleUrls: ['./manually-add-bank-forte.component.scss']
})
export class ManuallyAddBankForteComponent implements OnInit, OnDestroy {
	accountConnected = false;

	constructor(
		private formBuilder: FormBuilder,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private tagDataService: TagDataService,
		private sessionStorageService: SessionStorageService,
		private dialogService: DialogService,
		private routingService: RoutingService
	) {
		this.createForm(this.formBuilder);
	}
	formGroup: FormGroup;

	POST_APPROVAL_STATUSES = ['APPROVED', 'TERMS_CONFIRMED'];

	private subscription = new Subscription();

	showRequiredDocsMessage: boolean;
	bankName: string;
	inProgress: boolean;
	bankDetails: IAchBankAccountResult;
	bankDetailsPayLoad: any;
	showSkipButton = false;
	showError = false;
	errorMessage: string;

	ngOnInit(): void {
		this.formGroup.valueChanges.subscribe((val) => {
			if (val?.routingNumber === null) {
				this.bankName = null;
			}
		});

		// prevent user to land on this page if flag is not true
		if (!this.loanAppService.isRachWithoutAchOptInEligible()) {
			this.navigate();
		}

		this.tagDataService.view(
			{
				loan_application_id: this.loanAppService.loanApplicationId,
				application_type: 'CONSUMER_INSTALLMENT_LOAN'
			},
			{
				tealium_event: 'meta_rch_add_bank_account'
			}
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		const accountNumberRegex = '^[0-9]{4,17}$'; // TODO: move to validator utility function
		this.formGroup = fb.group(
			{
				accountType: [null, [Validators.required]],
				routingNumber: [null, [Validators.required], [this.routingNumberValidator()]],
				accountNumber: [null, [Validators.required, Validators.pattern(accountNumberRegex)]],
				accountNumberConfirmed: [null, [Validators.required, Validators.pattern(accountNumberRegex)]],
				customerConfirms: [false, [Validators.requiredTrue]]
			},
			{
				validator: [this.mustMatch('accountNumber', 'accountNumberConfirmed')]
			} as AbstractControlOptions
		);
	}

	mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
			const ctrl = formGroup.get(controlName);
			const matchingCtrl = formGroup.get(matchingControlName);

			if (matchingCtrl.errors && !matchingCtrl.errors.mustMatch) {
				return;
			}

			if (ctrl.value !== matchingCtrl.value) {
				matchingCtrl.setErrors({ mustMatch: true });
			} else {
				matchingCtrl.setErrors(null);
			}
		};
	}

	routingNumberValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const debounceTime = 500; //milliseconds
			return timer(debounceTime).pipe(
				switchMap(() =>
					this.mobileService.updateRoutingNumberValidation(control.value, this.loanAppService.loanApplicationId)
				),
				map((routing) => {
					this.bankName = routing.bankName;
					return null;
				}),
				catchError(() =>
					of({
						invalidRoutingNumber: true
					})
				)
			);
		};
	}

	confirmSkipAutoPay(data: IMessageDialogData): Observable<boolean> {
		const dialogRef = this.dialogService.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(take(1));
	}

	cancelAutoPay(): void {
		const data: IMessageDialogData = {
			icon: 'op-info',
			title: this.translocoService.translate('ESIGN_AUTO_PAY.skipAutoPayDialogHeading'),
			message: this.translocoService.translate('ESIGN_AUTO_PAY.skipAutoPayDialogContent'),
			confirmText: this.translocoService.translate('ESIGN_AUTO_PAY.setupAutoPay'),
			cancelText: this.translocoService.translate('ESIGN_AUTO_PAY.skip'),
			closeAction: true
		};
		this.confirmSkipAutoPay(data)
			.pipe(tap((rsp) => Boolean(rsp) || this.navigate()))
			.subscribe();
	}

	navigate(): void {
		const disbursement = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
		if (
			disbursement.onlineNotificationEnabled &&
			disbursement.isChannelAnyStore() &&
			!this.routingService.isSplStorelessEligibleWithStatus(ProductSubStatusesEnum.complete)
		) {
			this.routingService.route(RoutingPathsEnum.termsConfirmed);
		} else {
			this.routingService.route(RoutingPathsEnum.esignSignDocument);
		}
	}

	activateAutoPay() {
		this.logToTealium('rch_meta_manual_bank_account_activate_click', 'click');
		this.mobileService.setRachBankAccount(this.bankDetails, this.loanAppService.loanApplicationId).subscribe({
			next: (rsp) => {
				if (rsp.result === 'success') {
					this.logToTealium('rch_meta_manual_bank_account_activation_autopay_success', 'submit');

					this.navigate();
				} else {
					this.showSkipButton = true;
				}
			},
			error: (err) => {
				this.showSkipButton = true;
				this.formGroup.reset();
				this.logToTealium('rch_meta_manual_bank_account_activation_fail', 'submit');
			}
		});
	}

	onSubmit(post: any): void {
		this.showError = false;
		const bank = { ...post, routingNumberConfirmed: post.routingNumber };
		this.bankDetailsPayLoad = bank;
		// here we will call api to do verification from forte
		this.mobileService.addRachBankAccount(bank, this.loanAppService.loanApplicationId).subscribe({
			next: (rsp) => {
				if (rsp.status === 'ACTIVE') {
					this.accountConnected = true;
					this.bankDetails = rsp;
				}
				this.logToTealium('rch_meta_manual_bank_account_added', 'submit');
			},
			error: (err: HttpErrorResponse) => {
				this.accountConnected = false;
				this.showError = true;
				this.errorMessage = err.error[0] ? err.error[0].msg : '';
				this.logToTealium('rch_meta_manual_bank_account_add_fail', 'submit');
				this.formGroup.reset();
			}
		});
	}

	private logToTealium(eventName: string, eventAction: string) {
		this.tagDataService.link(
			{},
			{
				tealium_event: eventName,
				event_action: eventAction,
				event_label: 'autopay',
				event_category: 'META_RCH_AUTOPAY',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			}
		);
	}
}
