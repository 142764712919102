import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IDebitCard } from 'src/app/core/services/mobile-api';
import { IDisbursementOptions } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { FindLocationDialogComponent } from 'src/app/shared/components/dialogs/find-location-dialog/find-location-dialog.component';
import { LOAN_TERMS_MISMATCH_ERROR, SORRY_SOMETHING_WENT_WRONG } from 'src/app/shared/constants/common-const';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';


@Component({
	selector: 'op-esign-sign-document',
	templateUrl: './esign-sign-document.component.html',
	styleUrls: ['./esign-sign-document.component.scss']
})
export class EsignSignDocumentComponent implements OnInit, OnDestroy {
	fundsAvailability: string;
	cardType: string;
	institutionName: string;
	last4Debit: string;
	fundingInfo: string;
	hasDebit: boolean;
	disbursement: DisbursementUtils;
	constructor(
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private translocoService: TranslocoService,
		private originationPartnerService: OriginationPartnerService,
		private routingService: RoutingService,
		private dialog: MatDialog,
		private dialogService: DialogService
	) {}

	disbursementChannel: string;
	disbursementType: string;
	partnerBrand: string;
	showMetaMessage: boolean;
	isOnlineCheck: boolean;
	isPartnerApplication: boolean;
	showGoToStoreInfo: boolean;
	hasPartnerDisbursementChannel: boolean;
	private subscription = new Subscription();

	ngOnInit(): void {
		const loanApp = this.loanAppService.getLoanApp();
		const disbursement = DisbursementUtils.fromLoanApp(loanApp);
		this.disbursementType = disbursement.disbursementType;
		this.disbursementChannel = disbursement.disbursementChannel;
		this.showMetaMessage = OrganizationUtils.isMetaBank(loanApp?.issuingOrganization);
		this.isOnlineCheck = disbursement.isOnlineCheck();
		const fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
		this.isPartnerApplication = this.loanAppService.isPartnerApplication();
		this.hasPartnerDisbursementChannel = !!fundingInfo.getPartnerDisbursementChannel();
		this.showGoToStoreInfo = this.canShowGoToStoreInfo(disbursement, fundingInfo);
		this.disbursement = DisbursementUtils.fromLoanApp(loanApp);
		this.hasDebit = this.disbursement.isDebitCard();
		const partnerSub = this.originationPartnerService
			.getPartnerBrand(fundingInfo.getPartnerDisbursementChannel())
			.subscribe({
				next: (brand) => {
					this.partnerBrand = brand;
				}
			});
		this.subscription.add(partnerSub);
		if (this.hasDebit) {
			this.mobileService.getDebitCard(this.loanAppService.loanApplicationId).subscribe({
				next: (debitCard) => {
					this.fundsAvailability = debitCard.fundsAvailability;
					this.cardType = debitCard.cardType;
					this.institutionName = debitCard.institutionName;
					this.last4Debit = debitCard.last4;
					this.fundingInfo = 'ESIGN_CONFIRM_TERM.' + this.fundsAvailability;
				},
				error: (error) => {
					console.error(error);
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private showErrorPage(data): void {
		const error = Array.isArray(data.error)
			? data.error?.find(Boolean) || {}
			: Array.isArray(data)
			? data.find(Boolean)
			: {};
		if (error.id === 'device.verify.failed') {
			return;
		}
		this.routingService.route(RoutingPathsEnum.esignShowError, { queryParams: { errorMsg: error.msg } });
	}

	onFindLocation(): void {
		const dialogRef = this.dialog.open(FindLocationDialogComponent, {});
		dialogRef.afterClosed().pipe(take(1)).subscribe();
	}

	onSubmit(): void {
		this.mobileService
			.getLoanDocument(
				this.translocoService.getActiveLang().toUpperCase(),
				this.loanAppService.loanApplicationId,
				this.loanAppService.getCurrentApplicant().applicantIndex
			)
			.subscribe({
				next: (data) => {
					if (!data.signingUrl) {
						this.showErrorPage(data);
						return;
					}

					const failedSecurityCheck = data.signingUrl.includes('RECIPIENT_HAS_FAILED_SECURITY_CHECK');
					if (data.envelopeId != 'error' && !failedSecurityCheck) {
						window.location.href = data.signingUrl;
					} else if (failedSecurityCheck) {
						this.routingService.route(RoutingPathsEnum.esignEventSigningFailed, {
							queryParams: { id_check_failed: true }
						});
					} else {
						try {
							const error = JSON.parse(data.signingUrl);
							this.showErrorPage(error);
						} catch (e) {
							this.showErrorPage(data);
						}
					}
				},
				error: (err) => {
					const disbursementMismatch = err.error[0]?.msg === SORRY_SOMETHING_WENT_WRONG;
					if (disbursementMismatch) {
						this.openDisbursementMismatchErrorMessage();
					} else {
						const loanTermsMismatch = err.error[0]?.id === LOAN_TERMS_MISMATCH_ERROR;
						if (loanTermsMismatch) {
							this.openLoanTermsMismatchErrorMessage();
						} else {
							this.showErrorPage(err);
						}
					}
				}
			});
	}

	canShowGoToStoreInfo(disbursement: DisbursementUtils, fundingInfo: FundingInfoUtils): boolean {
		return (
			(this.isPartnerApplication && !this.showMetaMessage) ||
			(!disbursement?.isOnlineAch && !fundingInfo?.isCoverageAreaOutsideRadius() && !fundingInfo?.isStorelessState())
		);
	}

	openDisbursementMismatchErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('ESIGN_SIGN_DOCUMENT.ERROR_MODAL.title'),
				this.translocoService.translate('ESIGN_SIGN_DOCUMENT.ERROR_MODAL.message')
			)
			.subscribe();
	}

	openLoanTermsMismatchErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('LOAN_TERMS_MISMATCH_ERROR_MODAL.title'),
				this.translocoService.translate('LOAN_TERMS_MISMATCH_ERROR_MODAL.message')
			)
			.subscribe({
				next: () => {
					this.routingService.route(RoutingPathsEnum.adjustTerms);
				}
			});
	}
}
