<div class="collation-selection" *transloco="let t">
	<input
		type="file"
		class="file-input"
		id="{{ id + '_file' }}"
		[accept]="requiredFileType"
		(change)="onFileSelected($event)"
		#fileUpload
	/>
	<div class="file-list">
		<div *ngFor="let file of fileList$ | async; let i = index">
			<div class="thumbnail">
				<mat-icon
					class="delete-icon"
					svgIcon="op-trash"
					(click)="onRemoveFile(i)"
					(keydown)="onKeyDownRemove($event, i)"
				></mat-icon>
				<img id="{{ id + '_' + i + '_image' }}" alt="" [src]="file.thumbnail" />
			</div>
		</div>
		<div
			class="add-more op-micro-font"
			[ngClass]="{ 'disabled-more': disabled }"
			(click)="onAddMore()"
			(keydown)="onKeyDown($event)"
		>
			<div class="op-center">
				<mat-icon svgIcon="op-plus"></mat-icon>
				<div class="op-mt-10">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.addMore') }}</div>
			</div>
		</div>
	</div>
</div>
