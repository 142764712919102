import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'op-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
	showBackButton: boolean = true;
	ngOnInit(): void {
		this.showBackButton = history.state?.back;
	}
}
