import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IMetadataResult, IMetadataSelect } from './metadata-api.model';

@Injectable({
	providedIn: 'root'
})
export class MetadataApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	metadataSelect(metadata: IMetadataSelect): Observable<IMetadataResult> {
		const params = new HttpParams({ fromObject: { ...metadata } });
		return this.http.get<IMetadataResult>(`${this.API_MOBILE_METADATA_URL}/select`, { params });
	}

	selectLoanPurpose(lang: string = 'en', random: boolean = true): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'LoanPurpose', lang: lang, random: Boolean(random).toString() });
	}

	selectUSState(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'USState', lang: lang, random: Boolean(random).toString() });
	}

	selectAddressType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'AddressType', lang: lang, random: Boolean(random).toString() });
	}

	selectLanguage(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'Language', lang: lang, random: Boolean(random).toString() });
	}

	selectPersonalNameSuffix(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'PersonalNameSuffix', lang: lang, random: Boolean(random).toString() });
	}

	selectPhoneType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'PhoneType', lang: lang, random: Boolean(random).toString() });
	}

	selectDateRange(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'DateRange', lang: lang, random: Boolean(random).toString() });
	}

	selectFileTaxQuestion(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'FileTaxQuestion', lang: lang, random: Boolean(random).toString() });
	}

	selectIdentificationType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'IdentificationType', lang: lang, random: Boolean(random).toString() });
	}

	selectSsnItinNone(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'SsnItinNone', lang: lang, random: Boolean(random).toString() });
	}

	selectUSStatesRestrictedIDs(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'USStatesRestrictedIDs', lang: lang, random: Boolean(random).toString() });
	}

	selectCountry(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'Country', lang: lang, random: Boolean(random).toString() });
	}

	selectHousingType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'HousingType', lang: lang, random: Boolean(random).toString() });
	}

	selectCheckCashingFee(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'CheckCashingFee', lang: lang, random: Boolean(random).toString() });
	}

	selectDebtType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'DebtType', lang: lang, random: Boolean(random).toString() });
	}

	selectDebtTypeWithCreditHit(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'DebtTypeWithCreditHit', lang: lang, random: Boolean(random).toString() });
	}

	selectIndustryType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'IndustryType', lang: lang, random: Boolean(random).toString() });
	}

	selectIncomeSourceType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'IncomeSourceType', lang: lang, random: Boolean(random).toString() });
	}

	selectIncomeFrequency(
		lang: string = 'en',
		state: string = null,
		random: boolean = false
	): Observable<IMetadataResult> {
		return state
			? this.metadataSelect({
					type: 'IncomeFrequency',
					state: state,
					lang: lang
			  })
			: this.metadataSelect({
					type: 'IncomeFrequency',
					lang: lang,
					random: Boolean(random).toString()
			  });
	}

	selectPaymentFrequency(
		lang: string = 'en',
		state: string = null,
		loanApplicationId: number = null,
		random: boolean = false
	): Observable<IMetadataResult> {
		return state
			? this.metadataSelect({
					type: 'PaymentFrequency',
					state: state,
					lang: lang,
					loanApplicationId: loanApplicationId
			  })
			: this.metadataSelect({
					type: 'PaymentFrequency',
					lang: lang,
					random: Boolean(random).toString(),
					loanApplicationId: loanApplicationId
			  });
	}

	selectRelationship(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'Relationship', lang: lang, random: Boolean(random).toString() });
	}

	selectWeekday(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'Weekday', lang: lang, random: Boolean(random).toString() });
	}

	selectRelationshipEmergency(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'RelationshipEmergency', lang: lang, random: Boolean(random).toString() });
	}

	selectVehicleOwnershipType(lang: string = 'en', random: boolean = false): Observable<IMetadataResult> {
		return this.metadataSelect({ type: 'VehicleOwnershipType', lang: lang, random: Boolean(random).toString() });
	}
}
