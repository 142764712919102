import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { MobileApiService } from 'src/app/core/services/mobile-api';
import { IAgentStoreChannel, IZipCodeResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import { zipCodeValidator } from 'src/app/shared/validators/form-validators';

import { REQUEST_PARAM_APP_SOURCE } from '../../shared/constants/common-const';
import { AgentStoreDialogComponent } from './agent-store-dialog/agent-store-dialog.component';

@Component({
	selector: 'op-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private dialogService: DialogService,
		private landingService: LandingService,
		private authService: AuthenticationService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private originationPartnerService: OriginationPartnerService,
		private sessionStorageService: SessionStorageService,
		private mobileApiService: MobileApiService
	) {
		this.createForm(this.formBuilder);
		// NOTE: clearing sessionStorage here may break sso page.

		const extras = this.router.getCurrentNavigation().extras;
		this.agentStoreEnabled = extras?.state?.enabled;
	}

	formGroup: FormGroup;

	zipResult$: Observable<IZipCodeResult>;
	isReferralCodePresent: boolean;
	referralCode: string;

	agentStoreEnabled: boolean = false;

	ngAfterViewInit(): void {
		const sso = this.activatedRoute.snapshot.queryParams?.sso;
		if (!sso) {
			this.authService.clearAuthorizationToken();
		}

		// check if the refId is there in the query-params
		const refId = this.activatedRoute.snapshot.queryParams?.refId;
		if (refId) {
			this.sessionStorageService.set(STORAGE_KEYS.REF_ID, refId);
		}

		// check if the app-source is present in the query-params
		const appSource = this.activatedRoute.snapshot.queryParams[REQUEST_PARAM_APP_SOURCE];
		if (appSource) {
			this.sessionStorageService.set(STORAGE_KEYS.APP_SOURCE, appSource);
		}
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params: Params) => {
			if (!isEmpty(params.p)) {
				this.originationPartnerService.saveOriginationCodeIfEligible(params.p);
				this.router.navigate([]);
			}
		});
		this.referralCode = this.activatedRoute.snapshot.queryParams?.referral_code_p;
		if (!isEmpty(this.referralCode)) {
			this.isReferralCodePresent = true;
			this.sessionStorageService.set('referralCode', this.referralCode);
		} else {
			this.sessionStorageService.remove('referralCode');
		}

		if (this.agentStoreEnabled) {
			this.dialogService.openDialog(AgentStoreDialogComponent, {}, this.confirm, this.confirm).subscribe({
				next: (channel: IAgentStoreChannel) => {
					channel && this.saveAgentStoreCode(channel);
				}
			});
		}
	}

	private confirm(channel: IAgentStoreChannel): Observable<IAgentStoreChannel> {
		return of(channel);
	}

	private saveAgentStoreCode(channel: IAgentStoreChannel): void {
		this.sessionStorageService.set(STORAGE_KEYS.AGENT_STORE_CHANNEL, channel);
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			zipCode: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(10), zipCodeValidator()]]
		});
	}

	showErrorMessage(result: IZipCodeResult): Observable<IZipCodeResult> {
		return isEmpty(result.errorMessage)
			? of(result)
			: this.dialogService
					.openMessageDialog(
						{
							icon: 'op-alert',
							title: this.translocoService.translate('DIALOG_MESSAGE.message'),
							message: result.errorMessage
						},
						() => of(result),
						() => of(result)
					)
					.pipe(tap(() => this.formGroup.get('zipCode').setValue('')));
	}

	onSubmit(): void {
		this.landingService
			.searchZipCode(this.formGroup.get('zipCode').value)
			.pipe(
				switchMap((rsp: IZipCodeResult) => this.showErrorMessage(rsp)),
				filter((rsp: IZipCodeResult) => Boolean(rsp.serviceArea) && !Boolean(rsp.errorMessage))
			)
			.subscribe({
				next: () => {
					this.routingService.route(
						RoutingPathsEnum.personalInfo,
						this.isReferralCodePresent ? { queryParams: { referral_code_p: this.referralCode } } : null
					);
				}
			});
	}

	findYourApplication(): void {
		this.routingService.route(RoutingPathsEnum.findYourApplication);
	}
}
