<div class="digit-offer op-mt-40" *transloco="let t">
	<div
		tabindex="0"
		id="offerButton"
		role="button"
		class="op-center op-mb-20"
		(click)="goToDigit()"
		attr.aria-label="{{ 'DIGIT_OFFER_FOR_APPROVED.description' | transloco }}"
	>
		<img [src]="image" class="op-full-width" alt="app offer" />
		<div class="disclaimer op-label-font">
			<div id="oportunDisclaimer" class="op-mt-10 op-left op-dark-grey">
				{{ t('LOAN_COMPLETED.disclaimer') }}
			</div>
		</div>
	</div>

	<op-learn-more iconSize="small" icon="op-pencil" [title]="t('DIGIT_OFFER_FOR_APPROVED.howToManageLoan')">
		<div>{{ t('DIGIT_OFFER_FOR_APPROVED.createOnlineAccount') }}</div>
		<ul>
			<li [innerHTML]="t('DIGIT_OFFER_FOR_APPROVED.onlineServingFeature0')"></li>
			<li [innerHTML]="t('DIGIT_OFFER_FOR_APPROVED.onlineServingFeature1')"></li>
			<li [innerHTML]="t('DIGIT_OFFER_FOR_APPROVED.onlineServingFeature2')"></li>
			<li [innerHTML]="t('DIGIT_OFFER_FOR_APPROVED.onlineServingFeature3')"></li>
			<li [innerHTML]="t('DIGIT_OFFER_FOR_APPROVED.onlineServingFeature4')"></li>
			<li [innerHTML]="t('DIGIT_OFFER_FOR_APPROVED.onlineServingFeature5')"></li>
		</ul>
		<button
			mat-flat-button
			color="secondary"
			id="gotoServicingBtn"
			*ngIf="isMobile && !showCreateButton"
			(click)="mobileService.emit()"
		>
			{{ t('LOAN_COMPLETED.gotoServicing') }}
		</button>

		<button
			mat-flat-button
			color="secondary"
			id="gotoServicesBtn"
			*ngIf="!isMobile && !showCreateButton"
			(click)="mobileService.emit()"
		>
			{{ t('LOAN_COMPLETED.gotoServices') }}
		</button>

		<button
			mat-flat-button
			color="secondary"
			id="gotoCreateAccountBtn"
			*ngIf="showCreateButton"
			(click)="createAccount.emit()"
		>
			{{ t('LOAN_COMPLETED.setUpAccount') }}
		</button>
	</op-learn-more>
</div>
