<div class="esign-disclosures" *transloco="let t">
	<op-masthead
		><b class="client-name"> {{ t('ESIGN_DISCLOSURE.title', { clientName: clientName }) }} </b></op-masthead
	>
	<p class="op-mb-20">
		{{ t('ESIGN_DISCLOSURE.msg') }}
	</p>
	<op-content-container class="content">

		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-checkbox formControlName="accept" id="accept" color="primary">
				{{ t('ESIGN_DISCLOSURE.accept') }}
				<a class="text-decoration-underline">
					<b>{{ t('ESIGN_DISCLOSURE.disclosures') }}</b>
				</a></mat-checkbox
			>
			<div class="left">
				<button
					mat-flat-button
					color="primary"
					[disabled]="!formGroup.value.accept"
					name="findLetterBtn"
					id="submitBtn"
					type="submit"
				>
					{{ t('ESIGN_DISCLOSURE.submit') }}
			</button>
			</div>
		</form>
	</op-content-container>

	<div class="op-mt-20">
		<p></p>
	</div>
</div>
