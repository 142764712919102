import { differenceInDays, format, parseISO } from 'date-fns';

/**
 * Parse an ISO formatted string
 * Removes the time stamp before parsing
 *
 * @export
 * @param {string} date
 * @return {*}  {Date}
 */
export function parseISODate(date: string): Date {
	return parseISO(date?.substr(0, 10));
}

/**
 * Parse an ISO formatted string including time
 *
 * @export
 * @param {string} date
 * @return {*}  {Date}
 */
export function parseISODateTime(date: string): Date {
	return parseISO(date);
}

/**
 * Format a Date.
 * String should be a ISO formatted string.
 * Removes time stamp before parsing.
 *
 * @export
 * @param {(Date | string)} date
 * @param {string} formatStr
 * @param {*} [locale=null]
 * @return {*}  {string}
 */
export function formatDate(date: Date | string, formatStr: string, locale: any = null): string {
	let d: Date;
	if (date instanceof Date) {
		d = date;
	} else {
		d = parseISODate(date);
	}
	return locale ? format(d, formatStr, { locale }) : format(d, formatStr);
}

/**
 * Get difference in days
 *
 * @param {(Date | string)} dateLeft
 * @param {(Date | string)} dateRight
 * @return {*}  {number}
 */
export function getDifferenceInDays(dateLeft: Date | string, dateRight: Date | string): number {
	let dLeft: Date;
	let dRight: Date;
	if (dateLeft instanceof Date) {
		dLeft = dateLeft;
	} else {
		dLeft = parseISODate(dateLeft);
	}
	if (dateRight instanceof Date) {
		dRight = dateRight;
	} else {
		dRight = parseISODate(dateRight);
	}
	return differenceInDays(dLeft, dRight);
}
