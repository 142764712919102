<div class="additional-contact" *transloco="let t">
	<div class="op-mt-20">
		<a id="adjustTermsLink" class="adjust-terms" mat-flat-button color="link-md" [routerLink]="['/adjust-terms']">
			{{ t('ADDITIONAL_CONTACT.adjustTerms') }}
		</a>
	</div>
	<op-masthead [title]="t('ADDITIONAL_CONTACT.title')"></op-masthead>
	<op-content-container class="content">
		<div id="pageMessage" class="op-body-1-font op-mb-20">
			{{ t('ADDITIONAL_CONTACT.pageMessage') }}
		</div>

		<div id="requiredFields" class="op-label-font op-gray op-mtb-20">
			{{ t('ADDITIONAL_CONTACT.requiredFields') }}
		</div>

		<form id="additionalContactForm" [formGroup]="formGroup" #ngForm="ngForm" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field id="firstNameFormField" class="form-element" appearance="outline">
				<mat-label>{{ t('ADDITIONAL_CONTACT.firstName') }}</mat-label>
				<input matInput formControlName="firstName" name="firstName" id="firstName" required />
				<mat-error
					id="firstNameError"
					*ngIf="!formGroup.controls['firstName'].valid && formGroup.controls['firstName'].touched"
				>
					{{ t('ADDITIONAL_CONTACT.firstNameError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field id="lastNameFormField" class="form-element" appearance="outline">
				<mat-label>{{ t('ADDITIONAL_CONTACT.lastName') }} </mat-label>
				<input matInput type="text" formControlName="lastName" name="lastName" id="lastName" required />
				<mat-error
					id="lastNameError"
					*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
				>
					{{ t('ADDITIONAL_CONTACT.lastNameError') }}
				</mat-error>
			</mat-form-field>

			<div class="wrapper">
				<mat-form-field id="maternalNameFormField" class="form-element" appearance="outline">
					<mat-label>{{ t('ADDITIONAL_CONTACT.maternalName') }} </mat-label>
					<input matInput type="text" formControlName="maternalName" name="maternalName" id="maternalName" />
					<mat-error
						id="maternalNameError"
						*ngIf="!formGroup.controls['maternalName'].valid && formGroup.controls['maternalName'].touched"
					>
						{{ t('ADDITIONAL_CONTACT.maternalNameError') }}
					</mat-error>
				</mat-form-field>

				<mat-form-field id="suffixFormField" class="form-element" appearance="outline">
					<mat-label>{{ t('ADDITIONAL_CONTACT.suffix') }}</mat-label>
					<mat-select name="suffix" formControlName="suffix" id="suffix">
						<mat-option
							*ngFor="let suffix of nameSuffixOptions$ | async; let i = index"
							id="{{ 'suffix' + suffix.key }}"
							[value]="suffix.key"
						>
							{{ suffix.text }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<mat-form-field id="relationshipFormField" class="form-element" appearance="outline">
				<mat-label>{{ t('ADDITIONAL_CONTACT.relationship') }}</mat-label>
				<mat-select name="relationship" id="relationship" formControlName="relationship">
					<mat-option
						*ngFor="let relationshipType of relationshipTypeList; let i = index"
						[value]="relationshipType.key"
						id="{{ 'relationshipType_' + relationshipType.key }}"
					>
						{{ relationshipType.text }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field id="phoneNumberFormField" class="form-element" appearance="outline">
				<mat-label>{{ t('ADDITIONAL_CONTACT.phoneNumber') }}</mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					placeholder="+1 (US)(___) ___-____"
					mask="(000) 000-0000"
					name="telephone"
					id="phoneNumber"
					formControlName="phoneNumber"
					[dropSpecialCharacters]="false"
				/>
			</mat-form-field>

			<div class="op-form-group">
				<div class="op-form-title">
					<op-info-tip id="preferedLangTip" [key]="t('ADDITIONAL_CONTACT.preferredLanguageTip')">
						<span>{{ t('ADDITIONAL_CONTACT.preferredLanguage') | opRequired }}</span>
					</op-info-tip>
				</div>
				<mat-radio-group [value]="selectedValue" name="preferredLanguage" formControlName="preferredLanguage">
					<mat-radio-button
						*ngFor="let item of toggleLanguageOptions$ | async; let i = index"
						id="{{ 'preferredLanguage_' + i }}"
						[value]="item.key"
					>
						{{ item.text }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<button
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="submitBtn"
				[disabled]="formGroup.invalid"
				type="submit"
			>
				{{ t('GLOBAL.next') }}
			</button>
		</form>
	</op-content-container>
</div>
