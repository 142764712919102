import { IAddressResult } from '../services/mobile-api/mobile-api.model';

export class VehicleUtils {
	private customerState: string;

	public getCustomerState(addresses: IAddressResult[], addressType: string): string {
		return addresses.find((address) => address.type === addressType)?.state;
	}

	public getCustomerStateName(state: string): string {
		return stateVehicleReqMap[state]?.state;
	}

	public getNumOfDaysToRenewRegistration(state: string): number {
		return stateVehicleReqMap[state]?.numOfDaysToRenewRegistration;
	}
}
export const stateVehicleReqMap = {
	CA: {
		state: 'California',
		numOfDaysToRenewRegistration: 90
	},
	TX: {
		state: 'Texas',
		numOfDaysToRenewRegistration: 60
	},
	FL: {
		state: 'Florida',
		numOfDaysToRenewRegistration: 10
	},
	NJ: {
		state: 'New Jersey',
		numOfDaysToRenewRegistration: 10
	},
	AZ: {
		state: 'Arizona',
		numOfDaysToRenewRegistration: 10
	},
	IL: {
		state: 'Illinois',
		numOfDaysToRenewRegistration: 10
	}
};
