import { Component, EventEmitter, Input, Output } from '@angular/core';
import IProduct from 'src/app/core/services/loan-application/product-offer/product/product.model';

@Component({
	selector: 'op-product-offer-card',
	templateUrl: './product-offer-card.component.html',
	styleUrls: ['./product-offer-card.component.scss']
})
export class ProductOfferCardComponent {
	@Input()
	product: IProduct;

	@Output()
	continue = new EventEmitter<any>();
}
