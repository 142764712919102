<div class="identification" *transloco="let t">
	<op-masthead [title]="t('IDENTIFICATION.title')">
		<div *ngIf="isPrequal">{{ t('IDENTIFICATION.verifyIdentity') }}</div>
	</op-masthead>

	<op-content-container class="content">
		<div class="op-label-font op-gray op-mb-20">{{ t('IDENTIFICATION.requiredFields') }}</div>
		<form class="personal-content" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div class="op-mb-12">{{ t('IDENTIFICATION.idType') | opRequired }}</div>
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('IDENTIFICATION.select') }} </mat-label>
				<mat-select
					formControlName="idType"
					id="idType"
					#idType
					aria-label="Id Type"
					(focus)="onFocus(formGroup.controls['idType'])"
					required
				>
					<mat-option
						*ngFor="let state of idTypeList$ | async; let i = index"
						id="{{ 'idType_' + state.key }}"
						[value]="state.key"
						>{{ state.text }}</mat-option
					>
				</mat-select>
				<mat-error id="idTypeError" *ngIf="!formGroup.controls['idType'].valid && formGroup.controls['idType'].touched">
					{{ t('IDENTIFICATION.idTypeError') }}
				</mat-error>
			</mat-form-field>

			<div *ngIf="idType.value | showIdentificationField: 'showIssuingState'">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('IDENTIFICATION.issuingState') }} </mat-label>
					<mat-select
						formControlName="issuingState"
						id="issuingState"
						#state
						aria-label="state"
						(focus)="onFocus(formGroup.controls['issuingState'])"
					>
						<div *ngIf="idType.value !== 2218; else restricted">
							<mat-option
								*ngFor="let state of stateList$ | async; let i = index"
								id="{{ 'issuingState_' + state.key }}"
								[value]="state.key"
							>
								{{ state.text }}
							</mat-option>
						</div>

						<ng-template #restricted>
							<mat-option
								*ngFor="let state of stateListRestricted$ | async; let i = index"
								id="{{ 'issuingState_' + state.key }}"
								[value]="state.key"
							>
								{{ state.text }}
							</mat-option>
						</ng-template>
					</mat-select>
				</mat-form-field>
			</div>

			<div *ngIf="idType.value | showIdentificationField: 'showIssuingCountry'">
				<op-info-tip id="issuingCountryTip" [key]="t('IDENTIFICATION.issuingCountryTip')">
					{{ t('IDENTIFICATION.issuingCountry') | opRequired }}
				</op-info-tip>
				<p></p>
				<mat-form-field class="form-element" appearance="outline">
					<mat-select
						formControlName="issuingCountry"
						id="issuingCountry"
						#issuingCountry
						aria-label="issuingCountry"
						[disabled]="idType.value === 2218"
						(focus)="onFocus(formGroup.controls['issuingCountry'])"
					>
						<mat-option
							*ngFor="let country of countryList$ | async; let i = index"
							id="{{ 'issuingCountry_' + country.key }}"
							[value]="country.key"
							>{{ country.text }}</mat-option
						>
					</mat-select>
				</mat-form-field>
			</div>
			<div *ngIf="(idType.value | showIdentificationField: 'showTaxFilingQuestion':currentState) || isNewJerseyState">
				<op-info-tip id="taxFilingTip" [key]="t('IDENTIFICATION.taxFilingQuestionTip')">
					{{ t('IDENTIFICATION.taxFilingQuestion') }}
				</op-info-tip>
				<p></p>
				<div class="op-form-group">
					<mat-radio-group name="taxFilingQuestion" id="taxFilingQuestion" formControlName="taxFilingQuestion">
						<mat-radio-button
							*ngFor="let item of toggleTaxFilingQuestionOptions"
							id="{{ 'taxFiling_' + item.key }}"
							[value]="item.key"
						>
							{{ t(item.text) }}
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</div>

			{{ t('IDENTIFICATION.SsnOrItin') | opRequired }}
			<p></p>
			<div class="op-form-group">
				<mat-radio-group name="ssnOrItin" id="ssnOrItin" formControlName="ssnOrItin">
					<mat-radio-button [value]="toggleSsnItinOptions[0].key" id="{{ 'ssnOrItin_' + toggleSsnItinOptions[0].key }}">
						{{ t(toggleSsnItinOptions[0].text) }}
					</mat-radio-button>
					<mat-radio-button
						[value]="toggleSsnItinOptions[1].key"
						id="{{ 'ssnOrItin_' + toggleSsnItinOptions[1].key }}"
						[disabled]="isSSNorITINReadonly"
					>
						{{ t(toggleSsnItinOptions[1].text) }}
					</mat-radio-button>
					<mat-radio-button
						*ngIf="
							(isNewJerseyState && !formGroup.controls['taxFilingQuestion'].value) ||
							(idType.value
								| showIdentificationField: 'showNoneOpt':currentState:formGroup.controls['issuingCountry'].value)
						"
						[value]="toggleSsnItinOptions[2].key"
						id="{{ 'ssnOrItin_' + toggleSsnItinOptions[2].key }}"
					>
						{{ t(toggleSsnItinOptions[2].text) }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div *ngIf="formGroup.controls['ssnOrItin'].value !== null && formGroup.controls['ssnOrItin'].value !== 'none'">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label *ngIf="formGroup.controls['ssnOrItin'].value === 'ssn'"
						>{{ t('IDENTIFICATION.ssn') | opRequired }}
					</mat-label>
					<mat-label *ngIf="formGroup.controls['ssnOrItin'].value === 'itin'"
						>{{ t('IDENTIFICATION.itin') | opRequired }}
					</mat-label>
					<input
						matInput
						name="ssnItinNumber"
						id="ssnItinNumber"
						type="text"
						mask="BBB-BB-BBBB"
						[showMaskTyped]="true"
						[patterns]="customSsnItinPatterns"
						placeHolderCharacter="_"
						formControlName="ssnItinNumber"
						required
					/>
					<mat-error
						id="ssnItinNumberError"
						*ngIf="!formGroup.controls['ssnItinNumber'].valid && formGroup.controls['ssnItinNumber'].touched"
					>
						{{ getSsnOrItinError() | transloco }}
					</mat-error>
				</mat-form-field>
			</div>

			<div class="op-header-3-font op-mt-10 op-mb-16">
				{{ t('IDENTIFICATION.yourIncome') }}
			</div>

			<op-info-tip
				class="op-mb-8"
				id="takeHomeTip"
				[key]="t('IDENTIFICATION.takeHomePayTip')"
				ariaLabel="{{ t('IDENTIFICATION.toolTipMoreInfo') }}"
			>
				{{ t('IDENTIFICATION.takeHomePay') }}
			</op-info-tip>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('IDENTIFICATION.amount') }} </mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					name="totalHomePay"
					id="totalHomePay"
					mask="separator"
					thousandSeparator=","
					prefix="$"
					mask="separator.0"
					placeholder=""
					formControlName="totalHomePay"
					required
				/>
				<mat-hint>{{ t('IDENTIFICATION.takeHomePayInfo') }}</mat-hint>
				<mat-error
					id="totalHomePayError"
					*ngIf="!formGroup.controls['totalHomePay'].valid && formGroup.controls['totalHomePay'].touched"
				>
					{{ t('IDENTIFICATION.takeHomePayError') }}
				</mat-error>
			</mat-form-field>

			<div class="op-center op-mt-20">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					type="submit"
					[opTagClick]="'Identification next'"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
