import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

/**
 * Guard to check if trkcid and is available for personal-info page.
 *
 * @export
 * @class VendorPersonalInfoGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class VendorPersonalInfoGuard implements CanActivate {
	constructor(private routingService: RoutingService) {}

	canActivate(
		route: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.routingService.hasValidReferralParams(route.queryParams)) {
			this.routingService.route(RoutingPathsEnum.home);
			return false;
		}
		return true;
	}
}
