import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { GoogleApiPlacesService } from '../../core/services/google-api-places/google-api-places.service';

export interface ILatLngLimiter {
	lat: number;
	lng: number;
}

export interface IAutoCompleteAddress {
	address: string;
	city: string;
	state: string;
	postalCode: string;
}

/**
 * get Address listings from google places api and return the used parts when selected.
 *
 * @export
 * @class AddressAutoCompleteDirective
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Directive({
	selector: '[opAddressAutoComplete]'
})
export class AddressAutoCompleteDirective implements AfterViewInit, OnDestroy {
	private _latLng: any;

	@Input()
	set latLngLimiter(value: ILatLngLimiter) {
		this._latLng = value;

		if (window['google'] && this.autoComplete) {
			const circle = new window['google'].maps.Circle({ center: value, radius: 1000 });
			this.autoComplete.setBounds(circle.getBounds());
		}
	}
	get latLngLimiter(): ILatLngLimiter {
		return this._latLng;
	}

	@Output()
	addressChange: EventEmitter<any> = new EventEmitter();

	autoComplete;
	eventListener;
	place;

	private subscription = new Subscription();

	constructor(private el: ElementRef, private ngZone: NgZone, private googleApiService: GoogleApiPlacesService) {}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.initialize();
	}

	/**
	 * Sut up subscription to google api service and listen for address selection.
	 *
	 * @private
	 * @memberof AddressAutoCompleteDirective
	 */
	private initialize(): void {
		const options = {
			componentRestrictions: { country: 'US' },
			types: ['geocode']
		};
		const googleSub = this.googleApiService.googleApi$.subscribe({
			next: () => {
				this.autoComplete = this.googleApiService.getAutoComplete(this.el.nativeElement, options);

				if (!this.autoComplete.addListener != null) {
					this.eventListener = this.autoComplete.addListener('place_changed', () => {
						this.handleChangeEvent();
					});
				}
			}
		});
		this.subscription.add(googleSub);
	}

	/**
	 * Emit an address when it is selected.
	 *
	 * @private
	 * @memberof AddressAutoCompleteDirective
	 */
	private handleChangeEvent(): void {
		this.ngZone.run(() => {
			this.place = this.autoComplete.getPlace();
			if (this.place) {
				this.addressChange.emit(this.fillInAddress(this.place));
			}
		});
	}

	/**
	 * transform the google api structure to a simplified interface
	 *
	 * @param {*} place
	 * @return {*}  {IAutoCompleteAddress}
	 * @memberof AddressAutoCompleteDirective
	 */
	fillInAddress(place): IAutoCompleteAddress {
		const autoComplete: any = {};
		let address = '';

		if (!Array.isArray(place?.address_components)) {
			return autoComplete;
		}

		// Get each component of the address from the place details,
		// and then fill-in the corresponding field on the form.
		// place.address_components are google.maps.GeocoderAddressComponent objects
		// which are documented at http://goo.gle/3l5i5Mr
		for (const component of place.address_components) {
			const componentType = component.types[0];
			switch (componentType) {
				case 'street_number': {
					address = `${component.long_name} ${address}`;
					break;
				}

				case 'route': {
					address += component.long_name;
					break;
				}

				case 'postal_code': {
					autoComplete.postalCode = component.long_name;
					break;
				}

				case 'locality':
					autoComplete.city = component.long_name;
					break;

				case 'administrative_area_level_1': {
					autoComplete.state = component.short_name;
					break;
				}
			}
		}

		autoComplete.address = address;

		return autoComplete;
	}
}
