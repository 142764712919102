<div class="info-tip-wrapper" [class.empty-key]="!key" *transloco="let t">
	<ng-content id="{{ id + '_infoTip' }}"></ng-content>
	<button mat-icon-button *ngIf="key" id="{{ id + '_infoTipBtn' }}" type="button" [attr.aria-label]="ariaLabel">
		<mat-icon
			#tooltip="matTooltip"
			[matTooltipShowDelay]="1000"
			matTooltip="{{ key }}"
			(click)="toggle()"
			id="{{ id + '_infoTipIcon' }}"
			svgIcon="{{ icon ? icon : 'op-info' }}"
		>
		</mat-icon>
	</button>
	<div role="status" class="info-tip op-micro-font" *ngIf="show">
		<span id="{{ id + '_infoTipContent' }}" [innerHTML]="key"></span>
	</div>
</div>
