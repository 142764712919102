import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import { ILoanApplication } from '../loan-application.model';
import { LoanApplicationService } from '../loan-application.service';
import { DisbursementUtils } from './disbursement-utils';
import { IDisbursement } from './disbursement.model';

/**
 * monitor disbursement property on the current loan application.
 *
 * @export
 * @class DisbursementService
 */
@Injectable({
	providedIn: 'root'
})
export class DisbursementService {
	private loanApp$: Observable<ILoanApplication>;

	readonly disbursement$: Observable<DisbursementUtils>;

	constructor(private loanAppService: LoanApplicationService) {
		this.loanApp$ = this.loanAppService.loanApplication$.pipe(share());
		this.disbursement$ = this.loanApp$.pipe(
			filter(Boolean),
			map((rsp) => rsp?.disbursement),
			map((rsp: IDisbursement) => new DisbursementUtils(rsp))
		);
	}
}
