import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';

@Component({
	selector: 'op-esign-ping-listener',
	templateUrl: './esign-ping-listener.component.html',
	styles: ['']
})
export class EsignPingListenerComponent implements OnInit, OnDestroy {
	private subscription: Subscription = new Subscription();

	constructor(private activatedRoute: ActivatedRoute, private mobileApiService: MobileApiService) {}

	ngOnInit(): void {
		const queryParameters$ = this.activatedRoute.queryParams
			.pipe(
				concatMap((queryParameters: Params) =>
					this.mobileApiService.getEsignTokenRenewal(
						queryParameters.loanApplicationId,
						queryParameters.applicantId,
						queryParameters.token,
						queryParameters.event
					)
				)
			)
			.subscribe();

		this.subscription.add(queryParameters$);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
