import { Pipe, PipeTransform } from '@angular/core';

/**
 * Add * to text to indicate required.
 * TODO: may change to directive and make reactive to form...
 *
 * @export
 * @class OpRequiredPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opRequired'
})
export class OpRequiredPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): unknown {
		return `${value} *`;
	}
}
