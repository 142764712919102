import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { fadeIn, pulse } from 'ng-animate';

import { IStep } from '../stepper.component';

@Component({
	selector: 'op-step-base',
	template: ``,
	styles: [],
	animations: [
		trigger('fadeIn', [
			transition(
				':enter',
				useAnimation(fadeIn, {
					params: { timing: 1, delay: 2 }
				})
			)
		]),
		trigger('fadeInNow', [
			transition(
				'* => *',
				useAnimation(fadeIn, {
					params: { timing: 0.5, delay: 0.1 }
				})
			)
		]),
		trigger('pulse', [
			transition(
				':enter',
				useAnimation(pulse, {
					params: { timing: 2, delay: 0.1, scale: 2 }
				})
			)
		])
	]
})
export class StepBaseComponent {
	@Input()
	title: string;

	@Input()
	end: boolean;

	@Input()
	begin: boolean;

	_state: IStep;
	@Input()
	set state(state: IStep) {
		this._state = state;
		if (state?.checked) {
			this.onBurst();
		} else {
			this.checked = Boolean(state?.checked);
		}
	}
	get state(): IStep {
		return this._state;
	}

	burst = false;
	checked = false;

	onBurst(): void {
		this.burst = true;
		setTimeout(() => {
			this.burst = false;
		}, 2000);
		this.checked = true;
	}
}
