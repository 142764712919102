import { IMetadata } from 'src/app/core/services/mobile-api';
import { IDebtResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { AddressUtils } from 'src/app/core/utils/address-utils';

import { SectionFields, SummarySection } from './section.model';

/**
 *	Stores debt summary information.
 *
 * @param {*} addresses
 * @param {IDebtResult[]} debts
 * @param {IMetadata[]} debtType
 * @param {string} amount
 * @param {string} monthlyPayment
 * @returns {SummarySection}
 */
export const debtSection = (
	addresses: any,
	debts: IDebtResult[],
	debtType: IMetadata[],
	amount: string,
	monthlyPayment: string,
	isMetaOrganization: boolean
): SummarySection => {
	const PAYDAY_LOAN = 9100;

	let fields: SectionFields[] = debts.map((debt) => {
		const label = debtType.find((item) => item.key === debt.type)?.text;

		if (debt.type === PAYDAY_LOAN) {
			return {
				title: `${label} ${amount}`,
				titleValue: true,
				value: `$${debt.originalBalance}`
			};
		} else {
			return {
				title: `${label}  ${monthlyPayment}`,
				titleValue: true,
				value: `$${debt.monthlyPayment}`
			};
		}
	});

	fields = fields.length ? fields : [{ title: 'SUMMARY.noDebts', value: '  ' }];

	return {
		section: 'debt',
		header: 'SUMMARY.yourDebts',
		route: '/debt',
		hide: AddressUtils.getHomeAddress(addresses)?.state !== 'CA' || isMetaOrganization,
		fields
	};
};
