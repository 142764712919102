import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

/**
 * Footer for site.
 *
 * @export
 * @class FooterComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
	disclosureLink: string;
	privacyLink: string;
	private subscription: Subscription;

	constructor(private translocoService: TranslocoService) {}

	switchLanguage(): void {
		this.translocoService.setActiveLang(this.translocoService.getActiveLang() === 'en' ? 'es' : 'en');
	}

	ngOnInit(): void {
		this.subscription = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.disclosureLink = lang === 'es' ? 'https://oportun.com/es/licenses' : 'https://oportun.com/licenses';
				this.privacyLink = lang === 'es' ? 'https://oportun.com/es/privacy' : 'https://oportun.com/privacy';
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
