import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { IStoreEntity } from 'src/app/core/services/mobile-api/mobile-api.model';

/**
 * Detailed view of store information.
 *
 * @export
 * @class FindStoreDetailsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
	selector: 'op-find-store-details',
	templateUrl: './find-store-details.component.html',
	styleUrls: ['./find-store-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindStoreDetailsComponent implements OnInit, OnDestroy {
	_store: IStoreEntity;

	@Input()
	googleMapsAPIKey: string;

	@Input()
	set store(store: IStoreEntity) {
		this._store = store;
		this.mapImageUrl = this.getMapImageUrl();
	}
	get store(): IStoreEntity {
		return this._store;
	}

	@Output()
	back = new EventEmitter<any>();

	mapImageUrl: string;
	weekdays: string[];
	weekdaysWide: string[];
	private subscription = new Subscription();

	constructor(private translocoService: TranslocoService, private dateService: DateUtilsService) {}

	ngOnInit(): void {
		const translocoSub = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.weekdays = this.dateService.getWeekDays(lang);
				this.weekdaysWide = this.dateService.getWeekDays('en', 'wide'); // Used as an index must be 'en'
			}
		});
		this.subscription.add(translocoSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getMapImageUrl(): string {
		const address = `${this.store.address},${this.store.city}`;
		const key = this.googleMapsAPIKey;
		return encodeURI(
			`https://maps.googleapis.com/maps/api/staticmap?markers=color:0x52C727|${address}&size=300x150&zoom=13&key=${key}`
		);
	}

	getAriaLabelForAddress() {
		return `${this.store.address} ${this.store.city} ${this.store.state} ${this.store.postal_code}`;
	}

	formatHour(hourString): string {
		const hours = Math.floor(parseInt(hourString) / 100);
		const minutes = parseInt(hourString) - hours * 100;
		const date = new Date();
		date.setHours(hours);
		date.setMinutes(minutes);
		return this.dateService.format(date, 'h:mm aaa');
	}

	isOpenOnDay(dayOfWeek): boolean {
		const open = this.store[this.weekdaysWide[dayOfWeek].toLowerCase() + '_open'];
		const close = this.store[this.weekdaysWide[dayOfWeek].toLowerCase() + '_close'];
		return open !== close;
	}

	hoursForDay(dayOfWeek): string {
		const openHour = this.store[this.weekdaysWide[dayOfWeek].toLowerCase() + '_open'];
		const closeHour = this.store[this.weekdaysWide[dayOfWeek].toLowerCase() + '_close'];
		return `${this.formatHour(openHour)} - ${this.formatHour(closeHour)}`;
	}

	mapClick(): void {
		const address = `${this.store.address}, ${this.store.city} ${this.store.state} ${this.store.postal_code}`;
		let url;
		if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPod') !== -1) {
			url = `maps://maps.apple.com/maps?q=${address}`;
		} else {
			url = `http://maps.google.com/maps?q=${this.store.address}`;
		}
		window.location.href = url;
	}

	onBack(): void {
		this.back.emit();
	}
}
