<div class="confirm-submit">
	<div mat-dialog-title>
		<div class="op-right op-mb-8">
			<button
				mat-icon-button
				id="messageDialogCloseBtn"
				[mat-dialog-close]="false"
				aria-label="{{ 'GLOBAL.close' | transloco }}"
			>
				<mat-icon svgIcon="op-close"></mat-icon>
			</button>
		</div>
		<div class="op-header-3-font">
			<span>{{ 'CONFIRM_SUBMIT_MODAL.readyToSubmit' | transloco }}</span>
		</div>
	</div>
	<div mat-dialog-content>
		<div class="op-center op-mb-20">
			<img alt="" src="assets/images/icon_ready_to_submit.svg" />
		</div>
		<span id="submit-instructions-message">{{ data.instructions }}</span>
		<div aria-hidden="true">
			<p *ngIf="data.earlyRenewalEligible">
				<strong>
					{{ 'CONFIRM_SUBMIT_MODAL.earlyRenewalMessage' | transloco }}
				</strong>
			</p>
			<div *ngIf="!data.hasConsent">
				<span>
					{{ 'CONFIRM_SUBMIT_MODAL.authorization' | transloco }}
					<a
						mat-flat-button
						color="link-md"
						[routerLink]="['/loan-disclosures']"
						id="confirmSubmitDisclosureLink"
						(click)="close()"
					>
						{{ 'CONFIRM_SUBMIT_MODAL.loanAppAuthorization' | transloco }}
					</a>
					{{ 'CONFIRM_SUBMIT_MODAL.and' | transloco }}
					<a
						mat-flat-button
						color="link-md"
						href=""
						id="confirmSubmitConsentLink"
						(click)="wirelessOperationsConsentToggle()"
						aria-controls="wireless-message"
						onclick="return false"
						role="button"
					>
						{{ 'CONFIRM_SUBMIT_MODAL.wirelessAuthorization' | transloco }}
					</a> </span
				>.
				<div *ngIf="showWirelessConsentDisclaimer">
					{{ 'CONFIRM_SUBMIT_MODAL.wirelessOperationsAuthMsg' | transloco }}
				</div>
			</div>
			<div class="op-mt-20">
				<div *ngIf="data.isSoftPullEnabled && issuingOrganization">
					<p
						*ngIf="!data.noDocumentsRequired"
						innerHTML="{{
							'CONFIRM_SUBMIT_MODAL.submittingApplicationOportunPermission.' + issuingOrganization + '.documents'
								| transloco
						}}"
					></p>
					<p
						innerHTML="{{
							'CONFIRM_SUBMIT_MODAL.submittingApplicationOportunPermission.' + issuingOrganization + '.submitting'
								| transloco
						}}"
					></p>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="data.isSoftPullEnabled" class="op-mt-20 op-icon-align-body-1">
		<mat-icon svgIcon="op-alert"></mat-icon>

		<span innerHTML="{{ 'CONFIRM_SUBMIT_MODAL.mayImpactCreditScore' | transloco }}"></span>
	</div>
	<div class="op-center" mat-dialog-actions>
		<button class="confirm" mat-flat-button color="primary-small" id="confirmSubmitBtn" (click)="onSubmit()">
			{{ 'CONFIRM_SUBMIT_MODAL.submitFinalReview' | transloco }}
		</button>
	</div>
	<div class="op-center op-mtb-20">
		<a
			mat-flat-button
			color="link-md"
			href=""
			onclick="return false"
			id="confirmSubmitUploadMoreLink"
			(click)="close()"
		>
			{{ 'CONFIRM_SUBMIT_MODAL.uploadMoreDocuments' | transloco }}
		</a>
	</div>

	<div class="op-mt-20" *ngIf="data.isWI">
		<small>{{ 'CONFIRM_SUBMIT_MODAL.wiMaritalPropertyStatement' | transloco }}</small>
	</div>
</div>
