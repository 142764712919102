import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './authentication.interceptor';
import { CastleApiInterceptor } from './castle-api.interceptor';
import { HttpErrorInterceptor } from './error.interceptor';
import { IdleTimeoutInterceptor } from './idle-timeout.interceptor';
import { InstrumentationInterceptor } from './instrumentation.interceptor';
import { LanguageInterceptor } from './language.interceptor';
import { MobileApiBusyInterceptor } from './mobile-api-busy.interceptor';
import { TransactionIdInterceptor } from './transaction-id.interceptor';

export const interceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: TransactionIdInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: LanguageInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: IdleTimeoutInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: MobileApiBusyInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: InstrumentationInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: CastleApiInterceptor,
		multi: true
	}
];
