import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import {
	ProductCategoriesEnum,
	ProductSubStatusesEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { IClosesStoreResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { SessionStorageService } from '../../core/services/storage/session-storage.service';

@Component({
	selector: 'op-store-less-disbursement-info',
	templateUrl: './store-less-disbursement-info.component.html',
	styleUrls: ['./store-less-disbursement-info.component.scss']
})
export class StoreLessDisbursementInfoComponent implements OnInit, OnDestroy {
	productOfferDetailUtilities: ProductOfferDetailsUtils;
	private subscription = new Subscription();
	showBackToOffers: boolean;
	isSubstatusNullFlag: boolean;
	initialize: boolean;

	storeDistance: string;
	constructor(
		private tagDataService: TagDataService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private mobileService: MobileApiService
	) {}

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApp: ILoanApplication) => {
				this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp);
				const splProductDetails = this.productOfferDetailUtilities.getSecuredPersonalLoanProduct();
				this.showBackToOffers =
					ProductSubStatusesEnum.initiated != splProductDetails?.productSubStatus &&
					this.productOfferDetailUtilities.hasMultipleProductsOffered();
				this.isSubstatusNullFlag = this.isSubstatusNull();
			}
		});
		this.subscription.add(loanAppSub);

		const homePostalCode = this.loanAppService.getCurrentApplicant().homePostalCode;
		const closestStoreSub = this.mobileService
			.getClosesStore(homePostalCode, 1, this.loanAppService.loanApplicationId)
			.subscribe({
				next: (rsp: IClosesStoreResult) => {
					if (rsp.stores && rsp.stores?.length > 0) {
						let closestStore = rsp.stores[0];
						this.storeDistance = closestStore.distance;
					}
				},
				complete: () => (this.initialize = true)
			});
		this.subscription.add(closestStoreSub);

		this.tagDataService.view(
			{
				loan_application_id: this.loanAppService.loanApplicationId,
				application_type: 'CONSUMER_INSTALLMENT_LOAN'
			},
			{
				tealium_event: 'storeless_disbursment_info'
			}
		);
	}

	isSubstatusNull(): boolean {
		let loanApp = this.loanAppService.getLoanApp();
		let nullProductSubstatus = this.productOfferDetailUtilities.findProductSubStatus(
			ProductCategoriesEnum.securedPersonalLoan,
			null
		);
		return loanApp.isSplStorelessEligible && Boolean(nullProductSubstatus);
	}

	returnToOffers(): void {
		this.loanAppService.updateLoanApplication().subscribe({
			next: () => {
				const isSubstatusInitiatedFlag = this.productOfferDetailUtilities.findProductSubStatus(
					ProductCategoriesEnum.securedPersonalLoan,
					ProductSubStatusesEnum.initiated
				);
				if (!isSubstatusInitiatedFlag) {
					this.routingService.route(RoutingPathsEnum.offerStatus);
				}
			}
		});

		this.tagDataService.link(
			{
				loan_application_id: this.loanAppService.loanApplicationId
			},
			{
				tealium_event: 'spl_storeless-disbursement',
				event_category: 'CONSUMER_INSTALLMENT_LOAN', // CHECk THIS
				event_label: 'return_offers_clicked'
			}
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	triggerCallDialog(): void {
		this.tagDataService.link(
			{
				loan_application_id: this.loanAppService.loanApplicationId
			},
			{
				tealium_event: 'spl_storeless-disbursement',
				event_category: 'CONSUMER_INSTALLMENT_LOAN', // CHECk THIS
				event_label: 'call_now'
			}
		);

		this.sessionStorageService.set('isSplStoreless', true);
		this.dialogService.openContactUsDialog(false).subscribe();
	}
}
