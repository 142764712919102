import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { every } from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { IAchBankAccountResult } from 'src/app/core/services/mobile-api';

@Component({
	selector: 'op-add-bank',
	templateUrl: './add-bank.component.html',
	styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit, OnDestroy {
	@Input()
	filter: Function;

	@Input()
	selectBank: boolean;

	@Input()
	hideAddBank: boolean;

	@Input()
	selectAndContinue: boolean;

	@Output()
	bankSelected = new EventEmitter<IAchBankAccount>();

	constructor(protected bankAccountService: AchBankAccountsService) {}

	showAccountList: boolean;
	subscription = new Subscription();

	ngOnInit(): void {
		const bankSub = this.bankAccountService.bankAccounts$.pipe(filter((r) => Boolean(r))).subscribe({
			next: (rsp) => {
				this.showAccountList = Boolean(rsp.length);
				if (!this.areAllAccountsVerified(rsp)) {
					this.selectBank = true;
				}
			}
		});
		this.subscription.add(bankSub);
	}

	areAllAccountsVerified(bankAccounts: IAchBankAccountResult[]): boolean {
		return every(bankAccounts, (bankAccount) => bankAccount?.verificationStatus == AccountStatusEnum.verified);
	}

	onBankSelected(event: IAchBankAccount): void {
		this.bankSelected.emit(event);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
