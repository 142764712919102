<div class="cc-offer">
	<div class="cc-offer-header" *ngIf="productCategory === 'cashback'">
		{{ 'MULTI_PRODUCT.preferredTitle' | transloco }}
	</div>
	<div class="cc-offer-header" *ngIf="productCategory !== 'cashback'">
		{{ 'MULTI_PRODUCT.preferredTitle' | transloco }}
	</div>
	<div class="info-section op-header-2-font">
		<img src="/assets/images/cc/star.svg" alt="cc offer" />
		<span class="text" *ngIf="multiProductSegmentNode !== 'mp2'">{{ 'MULTI_PRODUCT.infoContent1' | transloco }}</span>
		<span class="text" *ngIf="multiProductSegmentNode === 'mp2'">{{ 'MULTI_PRODUCT.infoContent2' | transloco }}</span>
	</div>
	<div class="offer-header" *ngIf="productCategory === 'cashback'">
		<div class="op-header-1-font">
			<span [innerHTML]="'MULTI_PRODUCT.cashback_title' | transloco"></span>
		</div>
	</div>
	<div class="offer-header" *ngIf="productCategory !== 'cashback'">
		<div class="op-header-1-font">
			{{ 'MULTI_PRODUCT.title' | transloco }}
		</div>
	</div>

	<div class="description">
		<ul>
			<li *ngIf="productCategory !== 'cashback'">
				${{ creditCardAdditionalData.creditLine ? creditCardAdditionalData.creditLine : 1000 }}
				{{ 'MULTI_PRODUCT.subTitle' | transloco }}
			</li>
			<li>{{ 'MULTI_PRODUCT.getResponseInSecond' | transloco }}</li>
			<li>{{ 'MULTI_PRODUCT.noAnnualFee' | transloco }}</li>
		</ul>
	</div>

	<div class="offer">
		<div class="offer_button">
			<div *ngIf="productCategory === 'cashback'">
				<img src="/assets/images/cc/cashback_offer.svg" id="cashback_offer_image" alt="cc offer cashback" />
			</div>
			<button
				mat-flat-button
				color="primary"
				class="op-full-width applyCCOfferBtn"
				name="applyCCOffer"
				id="applyCCOfferBtn"
				type="button"
				(click)="goToCCService()"
			>
				{{ 'MULTI_PRODUCT.applyNowBtn' | transloco }}
			</button>
			<div class="remainder" *ngIf="showOfferDays">
				<span
					class="op-body-2"
					[innerHTML]="'MULTI_PRODUCT.offerExpiredInfo' | transloco: { eligibleOfferDays: eligibleOfferDays }"
				>
				</span>
			</div>
		</div>
		<div class="cc-img">
			<op-credit-card-img class="cc-logo" [productCategory]="productCategory"></op-credit-card-img>
		</div>
	</div>
	<p class="mat-caption">
		<span *ngIf="productCategory === 'cashback'" [innerHTML]="'MULTI_PRODUCT.cashback_terms' | transloco"></span>
		<span [innerHTML]="'MULTI_PRODUCT.terms' | transloco"></span>
		<span>{{ 'MULTI_PRODUCT.terms_prefix' | transloco }}</span>
		<a href="" onclick="return false" (click)="openDisclosureDialog()" id="disclosureLink" class="disclosure-link">
			{{ 'MULTI_PRODUCT.terms_center' | transloco }}</a
		>
		<span>{{ 'MULTI_PRODUCT.terms_suffix' | transloco }}</span>
	</p>
	<p
		*ngIf="productCategory !== 'cashback'"
		class="mat-caption"
		[innerHTML]="'MULTI_PRODUCT.webBankMessage' | transloco"
	></p>
	<p
		*ngIf="productCategory === 'cashback'"
		class="mat-caption"
		[innerHTML]="'MULTI_PRODUCT.cashback_webBankMessage' | transloco"
	></p>
</div>
