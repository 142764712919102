<div class="find-store-details">
	<hr />
	<button class="back-button" mat-icon-button id="findStoreBackBtn" (click)="onBack()">
		<mat-icon svgIcon="op-caretLeft"></mat-icon>
		{{ 'FIND_STORE.back' | transloco }}
	</button>
	<hr />
	<h3 class="store-name" id="fasHoursMessage">{{ store.name }}</h3>
	<h5>
		{{ store.distance }}
		{{ 'FIND_STORE.miles' | transloco }}
	</h5>
	<div class="store-map-link">
		<a href="" onclick="return false;" role="button" tabindex="0" id="findStoreMapLink" (click)="mapClick()">
			<img class="store-map" src="{{ mapImageUrl }}" alt="{{ getAriaLabelForAddress() }}" />
		</a>
	</div>
	<a
		class="address-link"
		href=""
		onclick="return false;"
		role="button"
		tabindex="0"
		id="findStoreAddressLink"
		(click)="mapClick()"
	>
		<h5>
			<div class="store-address">{{ store.address }}</div>
			<div class="store-address2">{{ store.city }}, {{ store.state }} {{ store.postal_code }}</div>
		</h5>
	</a>
	<hr />
	<div class="full">
		<h3>Hours:</h3>
		<ul class="store-hours">
			<li *ngFor="let day of weekdays; let i = index">
				<span role="presentation" class="day">{{ day }}:</span>
				<span role="presentation" class="hours" *ngIf="isOpenOnDay(i)">
					{{ hoursForDay(i) }}
				</span>
				<span role="presentation" class="hours" *ngIf="!isOpenOnDay(i)">
					{{ 'FIND_STORE.closed' | transloco }}
				</span>
			</li>
		</ul>
	</div>
</div>
