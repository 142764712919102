import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { IFindApplicationResult, IPartnerLead } from 'src/app/core/services/mobile-api/mobile-api.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { PartnerLeadService } from 'src/app/core/services/partner/partner-lead.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { LanguageEnum, LanguageService } from 'src/app/core/services/language/language.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Injectable({
	providedIn: 'root'
})
export class PartnerLeadGuard implements CanActivate {
	constructor(
		private routingService: RoutingService,
		private landingService: LandingService,
		private partnerLeadService: PartnerLeadService,
		private languageService: LanguageService,
		private tagDataService: TagDataService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return of(route.queryParams?.leadId).pipe(
			switchMap((leadId: number) => {
				return leadId
					? this.partnerLeadService.checkPartnerLead(leadId).pipe(
							tap((partnerLead: IPartnerLead) => {
								this.logTealiumEvent(leadId, partnerLead);
							}),
							map((partnerLead: IPartnerLead) => {
								if (partnerLead.validLead) {
									this.languageService.language =
										partnerLead.preferredLanguage?.toLowerCase() === 'es' ? LanguageEnum.spanish : LanguageEnum.english;

									if (partnerLead.loanApplicationExists) {
										this.routingService.route(RoutingPathsEnum.findYourApplication);
										return false;
									}
									if (partnerLead.phoneType && partnerLead.phoneNumber && partnerLead.leadId) {
										this.landingService.updateFindApp({
											token: partnerLead.token,
											phoneType: partnerLead.phoneType,
											phoneNumber: partnerLead.phoneNumber
										} as IFindApplicationResult);
										const nextRoute =
											partnerLead.finderPartner && partnerLead.state === 'CA'
												? 'caFindPartnerDisclosure'
												: 'personalInfo';
										this.routingService.route(RoutingPathsEnum.multifactor, {
											queryParams: { returnPage: nextRoute, action: 'leadToApplication' }
										});
										return false;
									}
								}
								this.routingService.route(RoutingPathsEnum.home);
								return true;
							}),
							catchError((error) => {
								this.routingService.route(RoutingPathsEnum.home);
								return of(true);
							})
					  )
					: of(true);
			})
		);
	}
	logTealiumEvent(leadId: number, partnerLead: IPartnerLead): void {
		if (leadId > 0) {
			const eventData = { leadId: leadId, tealium_event: 'invalid lead' };
			if (partnerLead?.validLead) {
				if (partnerLead.loanApplicationExists) {
					eventData.tealium_event = 'loan app exists for lead';
				} else if (partnerLead.leadId) {
					eventData.tealium_event = 'eligible for new loan app for lead';
				}
			}
			this.tagDataService.link({}, eventData);
		}
	}
}
