import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isEmpty } from 'lodash';
import { catchError, map, Observable, of } from 'rxjs';
import { LoggingService } from 'src/app/core/services/logging/logging.service';
import { PlaidLinkService } from 'src/app/core/services/plaid-link/plaid-link.service';
import { IRoutingInfo, RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

/**
 * Guard to check the wildcard routes and redirect accordingly.
 *
 * @export
 * @class WildCardGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class WildCardGuard implements CanActivate {
	constructor(
		private router: Router,
		private plaidLinkService: PlaidLinkService,
		private routingService: RoutingService,
		private loggingService: LoggingService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url = route.url?.find(Boolean)?.path;
		const fragment = route.fragment;

		const queryParams = route.queryParams || {};
		if (queryParams[`oauth_state_id`]) {
			return this.plaidLinkService.getRouteAfterOauthRedirect().pipe(
				map((rd: IRoutingInfo) => {
					const extra = { ...rd.extra, replaceUrl: true };
					this.routingService.route(rd.route, extra);
					return false;
				}),
				catchError((err) => {
					this.loggingService.error('plaid oauth failed', err);
					this.routingService.route(RoutingPathsEnum.home, { replaceUrl: true });
					return of(false);
				})
			);
		} else {
			const queryString = !isEmpty(queryParams)
				? Object.keys(queryParams)
						?.map((queryParamKey) => queryParamKey + '=' + queryParams[queryParamKey])
						.join('&')
				: null;
			let page = Object.keys(RoutingPathsEnum).filter((x) => RoutingPathsEnum[x] === url)[0];
			let navigateUrl = (url && RoutingPathsEnum[page]) || fragment || RoutingPathsEnum.home;
			let query: string = '';
			if (!isEmpty(queryString)) {
				query = `?${queryString}`;
			}

			this.router.navigateByUrl(`${navigateUrl}${query}`).catch((err) => {
				return this.router.navigateByUrl(`/${RoutingPathsEnum.home}${query}`);
			});
		}
		return false;
	}
}
