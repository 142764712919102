import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { licenseTypes } from './identification-other.model';

interface IncomeSources {
	source: string;
	amount: string;
	frequency: string;
}

const DEFAULT_DURATION = 200;

@Component({
	selector: 'op-identification-other',
	templateUrl: './identification-other.component.html',
	styleUrls: ['./identification-other.component.scss'],
	animations: [
		trigger('changeDivSize', [
			state(
				'initial',
				style({
					width: '100%',
					height: '110px'
				})
			),
			state(
				'final',
				style({
					width: '100%',
					height: '311px'
				})
			),
			transition('initial=>final', animate('300ms')),
			transition('final=>initial', animate('300ms'))
		])
	]
})
export class IdentificationOtherComponent implements OnInit, OnDestroy {
	constructor() {}

	currentState = 'initial';
	otherType = licenseTypes;

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	toggle(): void {
		this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
	}
}
