export interface IDisbursement {
	loanApplicationId: number;
	disbursementChannel?: null;
	disbursementType?: null;
	originationDisbursementChannel?: null;
	onlineDisbursementUnavailable: boolean;
	onlineNotificationEnabled: boolean;
	notificationTermsAdjusted: boolean;
	notificationTermsAdjustedSource?: null;
	notificationAdditionalContactRequired: boolean;
	notificationAdditionalContactCompleted: boolean;
	notificationAdditionalContactCompletedSource?: null;
	additionalContactId?: null;
	notificationSpouseContactRequired: boolean;
	notificationSpouseContactCompleted: boolean;
	notificationSpouseContactCompletedSource?: null;
	spouseContactId?: null;
	notificationAutoPayCompleted: boolean;
	notificationAutoPayCompletedSource?: null;
	notificationCompleteSource?: null;
	rachAccountId?: null /* cspell: disable-line */;
	disbursementDate?: null;
	disbursedAtStoreId?: null;
	disburserId?: null;
	legalDocumentId?: null;
	envelopeId?: null;
	disbursementBankAccountId?: number;
	disbursementTarjetaAccountId?: null /* cspell: disable-line */;
	disbursementTarjetaOrderKey?: null /* cspell: disable-line */;
	debitCardPlan?: null;
	debitCardSmsOptOut?: null;
	replacementDebitCardPrn?: null;
	issueReplacementDebitCard?: null;
	disbursementCheckAmount?: null;
	disbursementCheckNumber?: null;
	documentSigningChannel?: null;
	kioskEsignCode?: null /* cspell: disable-line */;
	originationDisbursementType?: null;
}

export enum DisbursementType {
	ach = 'ACH',
	check = 'CHECK',
	cash = 'CASH',
	debit = 'PUSH_TO_DEBIT'
}

export enum DisbursementChannelEnum {
	online = 'ONLINE',
	store = 'STORE',
	partnerStore = 'PARTNER_STORE',
	mobileTelesales = 'MOBILE_TELESALES'
}
