import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { AddressComponent } from './address/address.component';
import { CreditAuthComponent } from './credit-auth/credit-auth.component';
import { DebtAddComponent } from './debt/debt-add/debt-add.component';
import { DebtComponent } from './debt/debt.component';
import { OfferStatusInformationComponent } from './offer-status-information/offer-status-information.component';
import { FastTrackComponent } from './fast-track/fast-track.component';
import { LegalDisclosuresComponent } from './fast-track/legal-disclosures/legal-disclosures.component';
import { FinancesComponent } from './finances/finances.component';
import { IdentificationComponent } from './identification/identification.component';
import { IdentityConfirmComponent } from './identity-confirm/identity-confirm.component';
import { IncomeAddComponent } from './income/income-add/income-add.component';
import { IncomeComponent } from './income/income.component';
import { LoanPurposeComponent } from './loan-purpose/loan-purpose.component';
import { PlaidConnectComponent } from './plaid-connect/plaid-connect.component';
import { PreApprovalRoutingModule } from './pre-approval-routing.module';
import { ReferenceAddComponent } from './references/reference-add/reference-add.component';
import { ReferencesComponent } from './references/references.component';
import { SecuredPersonalComponent } from './secured-personal/secured-personal.component';
import { SpousalContactComponent } from './spousal-contact/spousal-contact.component';
import { SummaryComponent } from './summary/summary.component';
import { VehicleEligibilityComponent } from './vehicle-eligibility/vehicle-eligibility.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { VinSearchComponent } from './vehicle/vin-search/vin-search.component';
import { PlaidRefreshComponent } from './plaid-refresh/plaid-refresh.component';
import { PlaidRefreshInitiateComponent } from './plaid-refresh/plaid-refresh-initiate/plaid-refresh-initiate.component';
import { PlaidRefreshReconnectComponent } from './plaid-refresh/plaid-refresh-reconnect/plaid-refresh-reconnect.component';
import { VehicleEligibilityQuestionComponent } from './vehicle-eligibility-question/vehicle-eligibility-question.component';

/**
 * pre-approval status components.
 *
 * @export
 * @class PreApprovalModule
 */
@NgModule({
	declarations: [
		AddressComponent,
		CreditAuthComponent,
		DebtAddComponent,
		DebtComponent,
		OfferStatusInformationComponent,
		FastTrackComponent,
		FinancesComponent,
		IdentificationComponent,
		IdentityConfirmComponent,
		IdentityConfirmComponent,
		IncomeAddComponent,
		IncomeComponent,
		LegalDisclosuresComponent,
		LoanPurposeComponent,
		PlaidConnectComponent,
		PlaidRefreshComponent,
		PlaidRefreshInitiateComponent,
		PlaidRefreshReconnectComponent,
		ReferenceAddComponent,
		ReferencesComponent,
		SecuredPersonalComponent,
		SpousalContactComponent,
		SummaryComponent,
		VehicleComponent,
		VehicleEligibilityComponent,
		VinSearchComponent,
		VehicleEligibilityQuestionComponent
	],
	imports: [CommonModule, SharedModule, TranslocoModule, PreApprovalRoutingModule, NgxMaskModule.forChild()]
})
export class PreApprovalModule {}
