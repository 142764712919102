<div class="personal-info" *transloco="let t">
	<op-masthead *ngIf="showTitle" [title]="t('PERSONAL_INFO.serveYourArea')"></op-masthead>
	<op-masthead *ngIf="isCKReferral">
		<div class="op-display-3-font">
			<p>
				<span>{{ t('PERSONAL_INFO.PARTNERS.welcome1') }}</span
				><span><img class="credit-karma-logo" alt="" src="/assets/images/ck-logo-upswing-rgb.png" /></span
				><span>{{ t('PERSONAL_INFO.PARTNERS.welcome2') }}</span>
			</p>
		</div>

		<p translate>{{ t('PERSONAL_INFO.PARTNERS.opNumbers') }}</p>
		<div class="op-header-1-font op-mt-20">{{ t('PERSONAL_INFO.PARTNERS.tellUsMore') }}</div>
	</op-masthead>
	<op-masthead *ngIf="isWUReferral">
		<div class="op-header-1-font op-mt-20">{{ t('PERSONAL_INFO.PARTNERS.tellUsMore') }}</div>
	</op-masthead>
	<op-content-container class="content">
		<div class="op-center">
			<img src="/assets/images/serve-you.svg" alt="" />
		</div>

		<op-offer-code
			class="op-mt-20"
			*ngIf="showOfferCodeInfo"
			id="offerCode"
			(promoCodeInfo)="updateFromPromoCode($event)"
		></op-offer-code>

		<div class="op-label-font op-gray op-mtb-20">{{ t('PERSONAL_INFO.requiredFields') }}</div>

		<div class="op-body-1-bold-font">{{ t('PERSONAL_INFO.yourInformation') }}</div>

		<form class="personal-content op-mt-20" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<mat-form-field *ngIf="isWUReferral || isCKReferral" class="form-element" appearance="outline">
				<mat-label>{{ t('PERSONAL_INFO.PARTNERS.zipCode') }}</mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					formControlName="zipCode"
					id="zipCode"
					name="zipCode"
					required
				/>
				<mat-error
					id="personalInfoZipCode"
					*ngIf="!formGroup.controls['zipCode'].valid && formGroup.controls['zipCode'].touched"
				>
					{{ t('PERSONAL_INFO.PARTNERS.zipCodeError') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('PERSONAL_INFO.firstName') }}</mat-label>
				<input
					matInput
					type="text"
					formControlName="firstName"
					id="firstName"
					name="firstName"
					[readonly]="isNameReadOnly"
					required
				/>
				<mat-error
					id="firstNameError"
					*ngIf="!formGroup.controls['firstName'].valid && formGroup.controls['firstName'].touched"
				>
					{{ t('PERSONAL_INFO.firstNameError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('PERSONAL_INFO.middleName') }}</mat-label>
				<input
					matInput
					type="text"
					formControlName="middleName"
					id="middleName"
					name="middleName"
					[readonly]="isNameReadOnly"
				/>
				<mat-error
					id="middleNameError"
					*ngIf="!formGroup.controls['middleName'].valid && formGroup.controls['middleName'].touched"
				>
					{{ t('PERSONAL_INFO.middleNameError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="form-element" appearance="outline">
				<mat-label>{{ t('PERSONAL_INFO.lastName') }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="lastName"
					id="lastName"
					name="lastName"
					[readonly]="isNameReadOnly"
					required
				/>
				<mat-error
					id="lastNameError"
					*ngIf="!formGroup.controls['lastName'].valid && formGroup.controls['lastName'].touched"
				>
					{{ t('PERSONAL_INFO.lastNameError') }}
				</mat-error>
			</mat-form-field>

			<div class="wrapper">
				<mat-form-field class="form-element" appearance="outline">
					<mat-label>{{ t('PERSONAL_INFO.maternalName') }} </mat-label>
					<input
						matInput
						type="text"
						formControlName="maternalName"
						id="maternalName"
						name="maternalName"
						[readonly]="isNameReadOnly"
					/>
					<mat-error *ngIf="!formGroup.controls['maternalName'].valid && formGroup.controls['maternalName'].touched">
						{{ t('PERSONAL_INFO.maternalNameError') }}
					</mat-error>
				</mat-form-field>

				<mat-form-field class="form-element" appearance="outline" *ngIf="!isCKReferral">
					<mat-label>{{ t('PERSONAL_INFO.suffix') }}</mat-label>
					<mat-select name="suffix" formControlName="suffix" id="suffix">
						<mat-option
							*ngFor="let suffix of nameSuffixOptions$ | async; let i = index"
							id="{{ 'suffix' + suffix.key }}"
							[value]="suffix.key"
						>
							{{ suffix.text }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="op-form-group" role="group" aria-live="polite">
				<div id="dateOfBirthTitle" class="form-title op-body-1-bold-font">
					{{ t('PERSONAL_INFO.dateOfBirth') | opRequired }}
				</div>
				<div class="op-form-info">{{ t('PERSONAL_INFO.mustBe18') }}</div>
				<op-date-input
					name="dateOfBirth"
					id="dateOfBirth"
					formControlName="date"
					showFormat="ddmmyyyy"
					minAge="18"
					required
				></op-date-input>
				<mat-error
					id="dateOfBirthError"
					class="op-label-font op-ml-5"
					aria-live="polite"
					*ngIf="!formGroup.controls['date'].valid && formGroup.controls['date'].touched"
				>
					{{ t('PERSONAL_INFO.dateOfBirthError') }}
				</mat-error>
			</div>

			<div role="radiogroup" aria-labelledby="preferredLangTitle" class="op-form-group">
				<div class="op-form-title">
					<op-info-tip
						id="refLangTip"
						[key]="t('PERSONAL_INFO.preferredLanguageTip')"
						ariaLabel="{{ t('TOOLTIP.moreInfo') }}"
					>
						<span id="preferredLangTitle">{{ t('PERSONAL_INFO.preferredLanguage') | opRequired }}</span>
					</op-info-tip>
				</div>
				<mat-radio-group
					[value]="selectedValue"
					name="preferredLanguage"
					id="preferredLanguage"
					formControlName="preferredLanguage"
					role="group"
					aria-labelledby="preferredLangTitle"
					required
				>
					<mat-radio-button
						*ngFor="let item of toggleLanguageOptions$ | async; let i = index"
						id="{{ 'language_' + item.key }}"
						[value]="item.key"
					>
						{{ item.text }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div class="op-body-1-bold-font op-mt-40">{{ t('PERSONAL_INFO.contactInformation') }}</div>

			<mat-form-field class="form-element op-mt-20" appearance="outline">
				<mat-label>{{ t('PERSONAL_INFO.emailAddress') }} </mat-label>
				<input matInput type="text" formControlName="email" id="email" name="email" opMaxLength="100" />
				<mat-hint>{{ t('PERSONAL_INFO.emailAddressInfo') }}</mat-hint>
				<mat-error id="emailError" *ngIf="!formGroup.controls['email'].valid && formGroup.controls['email'].touched">
					{{ t('PERSONAL_INFO.emailError') }}
				</mat-error>
			</mat-form-field>

			<mat-form-field class="op-mt-10 form-element" appearance="outline">
				<mat-label>{{ t('PERSONAL_INFO.phoneNumber') }}</mat-label>
				<input
					matInput
					type="text"
					inputmode="numeric"
					placeholder="+1 (US)(___) ___-____"
					mask="(000) 000-0000"
					required
					name="telephone"
					id="telephone"
					formControlName="phoneNumber"
					[dropSpecialCharacters]="false"
				/>
				<mat-hint>
					{{ isMetaOrganization ? t('PERSONAL_INFO.phoneNumberInfoPathward') : t('PERSONAL_INFO.phoneNumberInfo') }}
					{{ t('PERSONAL_INFO.standardRates') }}
				</mat-hint>
				<mat-error
					id="telephoneError"
					*ngIf="!formGroup.controls['phoneNumber'].valid && formGroup.controls['phoneNumber'].touched"
				>
					{{ t('PERSONAL_INFO.phoneNumberError') }}
				</mat-error>
			</mat-form-field>

			<div role="radiogroup" aria-labelledby="phoneTypeLabel" class="op-form-group op-mt-20">
				<div id="phoneTypeLabel" class="op-form-title">{{ t('PERSONAL_INFO.phoneType') | opRequired }}</div>
				<mat-radio-group
					[value]="selectedValue"
					name="phoneOptions"
					id="phoneOptions"
					formControlName="phoneType"
					#phoneType
					required
				>
					<mat-radio-button
						*ngFor="let item of togglePhoneOptions$ | async"
						id="{{ 'phone_' + item.key }}"
						[value]="item.key"
					>
						{{ item.text }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<div class="phone-type-home op-label-font op-left" *ngIf="formGroup.controls['phoneType'].value === 'HM'">
				<mat-icon svgIcon="op-info"></mat-icon>
				<div class="op-ml-10" id="phone_HM_Msg">{{ t('PERSONAL_INFO.selectHome') }}</div>
			</div>

			<div role="radiogroup" aria-labelledby="dateRangeLabel" class="op-form-group date-range op-mt-10">
				<div id="dateRangeLabel" class="op-form-title">{{ t('PHONE.numberTime') | opRequired }}</div>
				<mat-radio-group name="dateRange" id="dateRange" formControlName="ownerDateRange" required>
					<mat-radio-button
						*ngFor="let item of toggleDateRangeOptions$ | async"
						[value]="item.key"
						id="{{ 'date_' + item.key }}"
					>
						{{ item.text }}
					</mat-radio-button>
				</mat-radio-group>
			</div>
			<p></p>

			<div class="op-left op-mtb-40" *ngIf="!this.loanAppId">
				<mat-checkbox id="acceptTerms" formControlName="acceptTerms" color="primary" required>
					<div class="accept-terms-text">
						{{ t('PERSONAL_INFO.tos1') }}
						<a [routerLink]="['/terms']" target="_blank" id="termsOfServiceLink" rel="noopener">{{
							t('PERSONAL_INFO.tos2')
						}}</a>
						<a [href]="esignLink" target="_blank" id="esignDisclosureLink" rel="noopener">{{
							t('PERSONAL_INFO.tos3')
						}}</a>
						{{ t('PERSONAL_INFO.tos4') }}
						<a [href]="privacyLink" target="_blank" id="privacyPolicyLink" rel="noopener">{{
							t('PERSONAL_INFO.tos5')
						}}</a>
					</div>
				</mat-checkbox>
			</div>

			<div class="op-center">
				<button
					mat-flat-button
					color="primary"
					name="submitBtn"
					id="submitBtn"
					[disabled]="formGroup.invalid"
					[opTagClick]="'Personal-info next'"
					type="submit"
				>
					{{ t('GLOBAL.next') }}
				</button>
			</div>
		</form>
	</op-content-container>
</div>
