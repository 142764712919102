import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-get-offer',
	templateUrl: './get-offer.component.html',
	styleUrls: ['./get-offer.component.scss']
})
export class GetOfferComponent implements OnInit {
	constructor(private router: Router, private tagDataService: TagDataService) {}

	ngOnInit(): void {
		this.tagDataService.view(
			{},
			{
				opEventId: 'view',
				tealium_event: 'dp-offer-not-found-landing'
			}
		);
	}

	trackTealiumEvent(eventName: string): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: eventName
			}
		);
	}
}
