<div class="promo-card icon-align-selfie" *ngIf="promo || promoKey" [opTagClick]="'debit card promo faq'">
	<div class="op-icon-align-body-1-green" *transloco="let t" [ngClass]="{ clickable: showRightArrow }">
		<mat-icon [svgIcon]="icon" class="op-orange"></mat-icon>
		<ng-container *ngIf="promoKey; then promoKeyTemplate; else promoTemplate"></ng-container>
		<mat-icon svgIcon="op-chevron-right" *ngIf="showRightArrow" class="chevron-right"></mat-icon>
	</div>
</div>

<ng-template #promoTemplate>
	<span *ngIf="promo">{{ promo }}</span>
</ng-template>

<ng-template #promoKeyTemplate>
	<span *transloco="let t">{{ t(promoKey) }}</span>
</ng-template>
