import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductCategoriesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Component({
	selector: 'op-funding-alternative',
	templateUrl: './funding-alternative.component.html',
	styleUrls: ['./funding-alternative.component.scss']
})
export class FundingAlternativeComponent implements OnInit, OnDestroy {
	alternateFundingKey: string;
	partnerBrand: string;

	private subscription = new Subscription();

	constructor(
		private loanAppService: LoanApplicationService,
		private originationPartnerService: OriginationPartnerService,
		private sessionStorageService: SessionStorageService
	) {}

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				switchMap((loanApp: ILoanApplication) => {
					const fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
					return forkJoin({
						brand: this.originationPartnerService.getPartnerBrand(fundingInfo.getPartnerDisbursementChannel()),
						fundingInfo: of(fundingInfo)
					});
				})
			)
			.subscribe({
				next: ({ brand, fundingInfo }) => {
					this.partnerBrand = brand;
					this.alternateFundingKey = this.getAlternateFundingTranslationKey(fundingInfo);
				}
			});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getAlternateFundingTranslationKey(fundingInfo: FundingInfoUtils): string {
		const issuingOrganization = this.loanAppService.getLoanApp().issuingOrganization;
		const isCashDisbursementAvail = fundingInfo.hasPartnerCashOption();
		const isCheckAtStoreDisbursementAvail =
			fundingInfo.isCoverageAreaWithinRadius() && !OrganizationUtils.isMetaBank(issuingOrganization);

		const isSPL =
			this.sessionStorageService.get('productCategorySelection') === ProductCategoriesEnum.securedPersonalLoan;

		if ((!isCashDisbursementAvail && isCheckAtStoreDisbursementAvail) || isSPL) {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCheckAtOportun';
		} else if (isCashDisbursementAvail && isCheckAtStoreDisbursementAvail) {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCashAtPartnerOrByCheckAtOportun';
		} else if (isCheckAtStoreDisbursementAvail) {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCashAtPartner';
		} else {
			return 'ACH_DISBURSEMENT_SELECTION.FALLBACK_DISBURSEMENT_OPTIONS.byCheckByMail';
		}
	}
}
