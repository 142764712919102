import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'op-account-not-connected',
	templateUrl: './account-not-connected.component.html',
	styleUrls: ['./account-not-connected.component.scss']
})
export class AccountNotConnectedComponent {
	constructor(
		public dialogRef: MatDialogRef<AccountNotConnectedComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	onAddManuallyClick(): void {
		this.dialogRef.close({ addManually: true });
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onUsingPlaidClick(): void {
		this.dialogRef.close({ addUsingPlaid: true });
	}
}
