import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { map, mergeMap, take } from 'rxjs/operators';

import { IRachBankAccount, MobileApiService } from 'src/app/core/services/mobile-api';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';

import { ManuallyAddBankComponent } from '../manually-add-bank/manually-add-bank.component';
import { firstNameValidator, lastNameValidator } from 'src/app/shared/validators/form-validators';

import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';

@Component({
	selector: 'op-add-rach-bank-account',
	templateUrl: './add-rach-bank-account.component.html',
	styleUrls: ['./add-rach-bank-account.component.scss']
})
export class AddRachBankAccountComponent extends ManuallyAddBankComponent {
	@Output()
	rachBankAccountAdded: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		protected formBuilder: FormBuilder,
		protected mobileService: MobileApiService,
		protected loanAppService: LoanApplicationService,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected sessionStorageService: SessionStorageService,
		protected issuingPartnerService: IssuingPartnerService,
		protected dialogService: DialogService,
		protected translocoService: TranslocoService
	) {
		super(
			formBuilder,
			mobileService,
			loanAppService,
			bankAccountService,
			tagDataService,
			sessionStorageService,
			issuingPartnerService
		);

		this.formGroup.addControl(
			'firstName',
			new FormControl('', [
				Validators.required,
				firstNameValidator(),
				Validators.minLength(2),
				Validators.maxLength(32)
			])
		);
		this.formGroup.addControl(
			'lastName',
			new FormControl('', [Validators.required, lastNameValidator(), Validators.minLength(2), Validators.maxLength(32)])
		);
	}

	onSubmit(): void {
		const post = this.formGroup.value;
		const bank = { ...post, routingNumberConfirmed: post.routingNumber };
		this.mobileService
			.verifyRachBankAccount(bank, this.loanAppService.loanApplicationId)
			.pipe(
				mergeMap((verified: boolean) => {
					if (verified) {
						return this.mobileService.setRachAccount(this.loanAppService.loanApplicationId, bank);
					} else {
						return this.dialogService
							.openErrorDialog(this.translocoService.translate('ESIGN_AUTO_PAY.ADD_BANK.bankAccountInvalid'))
							.pipe(
								map((result) => {
									return null;
								})
							);
					}
				})
			)
			.subscribe({
				next: (result: IRachBankAccount | null) => {
					this.rachBankAccountAdded.emit(result);
				},
				error: (err) => {
					this.dialogService.openErrorDialog(err).subscribe();
				}
			});
	}

	showAccountDetailsPop() {
		const message = `<p/>${this.translocoService.translate('ESIGN_AUTO_PAY.ADD_BANK.DETAILS.routingNumber')}
								<p/>${this.translocoService.translate('ESIGN_AUTO_PAY.ADD_BANK.DETAILS.accountNumber')}`;
		const dialogRef = this.dialogService.open(MessageDialogComponent, {
			data: {
				title: this.translocoService.translate('ESIGN_AUTO_PAY.ADD_BANK.DETAILS.title'),
				message,
				image: '/assets/images/check-routing-account.svg'
			}
		});
		return dialogRef.afterClosed().pipe(take(1));
	}
}
