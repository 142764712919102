<div class="almost-done" *transloco="let t">
	<op-masthead [title]="t('ALMOST_DONE.almostDone')"></op-masthead>
	<op-content-container class="content">
		<span>{{ t('ALMOST_DONE.thanksForInfo') }}</span>
		<a mat-flat-button color="link-md" [routerLink]="['/document-submit']" id="documentSubmitLink">{{
			t('ALMOST_DONE.moreInfo')
		}}</a>

		<hr class="op-mtb-20" />

		<div class="op-header-2-font">{{ t('ALMOST_DONE.lastStep') }}</div>

		<strong>
			<span>{{ t('ALMOST_DONE.pleaseCallUs') }}</span>
		</strong>
		<span>{{ t('ALMOST_DONE.callUsToSubmit') }}</span>

		<div class="op-mt-20 align-phone">
			<img alt="phone" src="assets/images/phone-icon.svg" role="presentation" />
			<a mat-flat-button color="link-bold" href="tel:18887768509" id="callCustomerSupportLink"> 1-888-776-8509 </a>
		</div>

		<hr class="op-mtb-20" />

		<span>{{ t('ALMOST_DONE.alsoVisitNearby') }}</span>
		<a
			mat-flat-button
			color="link-md"
			href=""
			onclick="return false"
			id="findLocationLink"
			(click)="findLocation()"
			[opTagClick]="'Find Locations'"
		>
			{{ t('ALMOST_DONE.oportunLocation') }}
		</a>
		<span>{{ t('ALMOST_DONE.reviewInPerson') }}</span>
	</op-content-container>
</div>
