import { Component, Input, OnInit } from '@angular/core';

/**
 * Bassi alert styled text.
 *
 * @export
 * @class AlertComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
	@Input()
	level: string;

	icon: string;
	class: string;

	typeMap = {
		DANGER: { class: 'op-alert-danger', icon: 'op-alert' },
		ERROR: { class: 'op-alert-error', icon: 'op-alert' },
		INFO: { class: '', icon: 'op-info' },
		SUCCESS: { class: 'op-alert-success', icon: 'op-checkMark-solid' },
		WARNING: { class: 'op-alert-warning', icon: 'op-processingInfo' },
		TOASTER: { class: '', icon: '' }
	};

	ngOnInit(): void {
		this.icon = this.typeMap[this.level].icon;
		this.class = this.typeMap[this.level].class;
	}
}
