<header>
	<mat-toolbar color="accent" *transloco="let t">
		<div [ngSwitch]="logo">
			<a
				*ngSwitchCase="'metabank_en'"
				[routerLink]="['/home']"
				(click)="clearSessionStorage()"
				id="headerLogoLink"
				[attr.aria-label]="t('HEADER.title')"
			>
				<img
					src="assets/images/logo-en-pathward.svg"
					class="headerLogoImagePathward"
					alt="{{ t('HEADER.title') }}"
					role="presentation"
				/>
			</a>
			<a
				*ngSwitchCase="'metabank_es'"
				[routerLink]="['/home']"
				(click)="clearSessionStorage()"
				id="headerLogoLink"
				[attr.aria-label]="t('HEADER.title')"
			>
				<img
					src="assets/images/logo-es-pathward.svg"
					class="headerLogoImagePathward"
					alt="{{ t('HEADER.title') }}"
					role="presentation"
				/>
			</a>
			<a
				*ngSwitchDefault
				[routerLink]="['/home']"
				(click)="clearSessionStorage()"
				id="headerLogoLink"
				[attr.aria-label]="t('HEADER.title')"
			>
				<img src="assets/images/logo.svg" alt="{{ t('HEADER.title') }}" role="presentation" />
			</a>
		</div>

		<span class="spacer"></span>
		<button mat-icon-button class="menu-button" id="headerMenuBtn" (click)="onToggleSidenav()" aria-label="Menu Button">
			<mat-icon svgIcon="op-hamburgerMenu"></mat-icon>
		</button>
	</mat-toolbar>
</header>
