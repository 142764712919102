import { Component, EventEmitter, Input, Output } from '@angular/core';
import { find } from 'lodash';
import {
	ApplicantStepCompleteEnum,
	IApplicant
} from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { ProductCategoriesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { LoanExamplesService } from 'src/app/core/services/loan-examples/loan-examples.service';
import { IConfigResult } from 'src/app/core/services/mobile-api';

import { DisbursementChannelEnum } from '../../../core/services/loan-application/disbursement/disbursement.model';
import { LoanApplicationService } from '../../../core/services/loan-application/loan-application.service';
import { IAddressResult } from '../../../core/services/mobile-api/mobile-api.model';

enum IncomeThresholdEnum {
	gcpIncomeThreshold = 3000,
	newIncomeThreshold = 1300
}

@Component({
	selector: 'op-status-pre-approved',
	templateUrl: './pre-approved.component.html',
	styleUrls: ['./pre-approved.component.scss']
})
// TODO: create a parent class that can be inherited???
export class PreApprovedComponent {
	private _loanApp: ILoanApplication;
	private _softPull: IConfigResult;
	private _homeAddress: IAddressResult;

	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;

		this.applicant = this.loanAppService.getCurrentApplicant();
		this.isReferenceComplete = this.loanAppService.isApplicantStepComplete(ApplicantStepCompleteEnum.reference);
		this.maxApprovedAmount = this.getMaxApprovedAmount(this._loanApp);
		this.showPreApproveAmount = this.getShowPreApproveAmount(this._loanApp);
		this.isOnlineDisbursement = this.getOnlineDisbursement(this._loanApp);
	}

	get softPull(): IConfigResult {
		return this._softPull;
	}

	@Input()
	set softPull(value: IConfigResult) {
		this._softPull = value;

		this.isSoftPullEnabled = this._softPull.value;
		this.isPreScreened = this._softPull.preScreened;
	}

	get homeAddress(): IAddressResult {
		return this._homeAddress;
	}

	@Input()
	set homeAddress(value: IAddressResult) {
		this._homeAddress = value;

		this.customerState = this._homeAddress?.state;
		this.showLoanExamplesLink = this.loanExampleService.getShowLoanExamplesLink(this._homeAddress);
	}

	applicant: IApplicant;
	isSoftPullEnabled: boolean;
	isPreScreened: boolean;
	isReferenceComplete: boolean;
	maxApprovedAmount: number;
	showPreApproveAmount: boolean;
	isOnlineDisbursement: boolean;
	showLoanExamplesLink: boolean;
	customerState: string;

	@Output()
	next = new EventEmitter<any>();

	constructor(private loanAppService: LoanApplicationService, private loanExampleService: LoanExamplesService) {}

	addReferences(): void {
		this.next.emit({ next: 'references' });
	}

	submit(): void {
		this.next.emit({ next: 'submit' });
	}

	openLoanExamplesModal(): void {
		this.loanExampleService.openLoanExamplesModal(this.customerState).subscribe();
	}

	getMaxApprovedAmount(loanApp: ILoanApplication): number {
		if (loanApp.preApprovedTerms.length === 1) {
			return loanApp.preApprovedTerms[0].maxApprovedAmount;
		} else if (loanApp.preApprovedTerms.length === 0) {
			return 0;
		} else {
			const productCategoryForMaxApproved =
				loanApp.productCategory === ProductCategoriesEnum.securedPersonalLoan
					? ProductCategoriesEnum.securedPersonalLoan
					: ProductCategoriesEnum.unsecuredPersonalLoan;
			return (
				find(loanApp.preApprovedTerms, { productCategory: productCategoryForMaxApproved } || {})?.maxApprovedAmount || 0
			);
		}
	}

	getShowPreApproveAmount(loanApp: ILoanApplication): boolean {
		return this.loanAppService.isGCP()
			? this.maxApprovedAmount >= IncomeThresholdEnum.gcpIncomeThreshold
			: this.maxApprovedAmount >= IncomeThresholdEnum.newIncomeThreshold;
	}

	getOnlineDisbursement(loanApp: ILoanApplication): boolean {
		return find(loanApp.fundingInfo?.disbursementOptions, { channel: DisbursementChannelEnum.online }) ? true : false;
	}
}
