import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';

@Component({
	selector: 'op-esign',
	templateUrl: './esign.component.html',
	styles: ['']
})
export class EsignComponent implements OnDestroy, OnInit {
	public event: string;
	public page: string;

	private subscription = new Subscription();

	constructor(private activatedRoute: ActivatedRoute, private mobileApiService: MobileApiService) {}

	ngOnInit(): void {
		const urlParametersSub = this.activatedRoute.params.subscribe(({ event, page }) => {
			this.event = event;
			this.page = page;
		});
		this.subscription.add(urlParametersSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
