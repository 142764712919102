import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-upload-display-idle',
	templateUrl: './upload-display-idle.component.html',
	styleUrls: ['./upload-display-idle.component.scss']
})
export class UploadDisplayIdleComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input() disabled: boolean;

	@Output() fileDropped = new EventEmitter<any>();
	@Output() opClick = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}
