export interface IPlaidSuccessMetadata {
	link_session_id: string;
	institution: IPlaidInstitution;
	account: IPlaidAccount;
	accounts: Array<IPlaidAccount>;
	account_id: string;
	public_token: string;
}

export interface IPlaidOnSuccessArgs {
	token: string;
	metadata: IPlaidSuccessMetadata;
}

export interface IPlaidInstitution {
	name: string;
	institution_id: string;
}

export interface IPlaidAccount {
	id: string;
	name: string;
	mask: string;
	type: string;
	subtype: string;
}

export interface IPlaidError {
	display_message: string;
	error_code: string;
	error_message: string;
	error_type: string;
}

export interface IPlaidErrorMetadata {
	link_session_id: string;
	institution: IPlaidInstitution;
	status: string;
}

export interface IPlaidOnExitArgs {
	error: IPlaidError;
	metadata: IPlaidErrorMetadata;
}

export interface IPlaidOnEventArgs {
	eventName: string;
	metadata: IPlaidEventMetadata;
}

export interface IPlaidInstitution {
	institution_id: string;
	name: string;
}
export interface IPlaidEventMetadata {
	error_code: string;
	error_message: string;
	error_type: string;
	exit_status: string;
	institution?: IPlaidInstitution;
	institution_id: string;
	institution_name: string;
	institution_search_query: string;
	request_id: string;
	link_session_id: string;
	mfa_type: string;
	status?: string;
	view_name: string;
	timestamp: string;
}

export interface IPlaidConfig {
	apiVersion?: string;
	clientName?: string;
	env?: string;
	key?: string;
	onLoad: Function;
	onSuccess: Function;
	onExit: Function;
	onEvent: Function;
	product?: Array<string>;
	selectAccount?: boolean;
	token?: string;
	webhook?: string;
	countryCodes?: string[];
	receivedRedirectUri?: string;
	isWebview?: boolean;
}

export enum SectionTypeEnum {
	bank = 'bank',
	income = 'income'
}

export enum AccountListDisplayModeEnum {
	select = 'select',
	readonly = 'readonly'
}

export interface IPlaidSessionData {
	token?: string;
	expiration?: string;
	sectionType?: SectionTypeEnum;
	receivedRedirectUri?: string;
	forwardedUri?: string;
	isOauthFlow?: boolean;
}

export interface IAutoVerifyScenario {
	autoVerifyScenario?: string;
	verifyDisclaimerKey?: string;
	hasBankAccountRequirement?: boolean;
}

export enum AutoVerifyScenarioEnum {
	bankOnly = 'BANK_ONLY',
	bankAndIncome = 'BANK_AND_INCOME',
	incomeOnly = 'INCOME_ONLY'
}
