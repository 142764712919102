import { Injectable } from '@angular/core';
import { Event, NavigationEnd } from '@angular/router';
import { isFunction } from 'lodash';

import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class NewRelicLoggerService {
	constructor(private windowService: WindowRefService) {}

	public handleError(error: any) {
		isFunction(this.windowService.window?.['NREUM'].noticeError) &&
			this.windowService.window?.['NREUM'].noticeError(error);
	}

	public logRouterEvents(routerEvent: Event) {
		if (routerEvent instanceof NavigationEnd) {
			if (isFunction(this.windowService.window?.['NREUM'].setCurrentRouteName)) {
				this.windowService.window?.['NREUM'].setCurrentRouteName(routerEvent.url);
			}
		}
	}
}
