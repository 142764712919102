<div class="product-offer-card" *transloco="let t">
	<mat-card id="splEligible">
		<mat-card-content>
			<div class="op-header-2-font">{{ t('ADJUST_TERMS.LOAN_TYPES.securedPersonalLoan') }}</div>
			<op-expansion-panel
				[applyColor]="true"
				[expanded]="false"
				id="splNewOfferPanel"
				[titleBold]="true"
				[title]="t('PRODUCT.PRODUCT_STATUSES.newOffer')"
			>
				<p>
					{{ t('OFFER_STATUS.securedLoanNextStep') }}
				</p>
				<hr class="productDetailHorizontalLine" />
			</op-expansion-panel>
			<div class="loan-amount">
				<div class="op-header-4-font">{{ t('OFFER_STATUS.upTo') }}</div>
				<div class="op-display-2-font">
					{{ product.maxApprovedAmount | currency: 'USD':'symbol':'0.0' }}
					<sup>*</sup>
				</div>
			</div>

			<div>
				<div *ngIf="!isSplOnlyLoan; else splOnlyLoanText">
					<ul class="spl-loan-advantage op-body-2-font">
						<li>{{ t('OFFER_STATUS.LOAN_ADVANTAGE.lowerInterestRate') }}</li>
						<li>{{ t('OFFER_STATUS.LOAN_ADVANTAGE.betterChanceText') }}</li>
						<li>{{ t('OFFER_STATUS.LOAN_ADVANTAGE.personalLoanStillAvailable') }}</li>
					</ul>
				</div>
				<ng-template #splOnlyLoanText>
					<ul class="spl-loan-advantage op-body-2-font">
						<li>{{ t('OFFER_STATUS.SECURED_OFFERED.fixedPayments') }}</li>
						<li>{{ t('OFFER_STATUS.SECURED_OFFERED.establishCredit') }}</li>
						<li>{{ t('OFFER_STATUS.SECURED_OFFERED.lowerAPR') }}</li>
					</ul>
				</ng-template>
			</div>

			<div>
				<button
					mat-flat-button
					color="primary-small"
					class="confirm-button padding-for-long-title op-full-width"
					id="splCheckVehicleBtn"
					(click)="onContinue('vehicleEligibilityQuestion')"
				>
					{{ t('OFFER_STATUS.checkVehicleEligibility') }}
				</button>
			</div>
			<div class="offer-status-info-url">
				<a mat-flat-button color="link-md" id="splOfferStatusInfoLink" (click)="goToOfferStatusInformation()">
					{{ t('OFFER_STATUS.SECURED_OFFERED.customerNotSureAboutOffer') }}
				</a>
			</div>
			<div class="op-mt-20 op-form-info op-gray" *ngIf="isSplOnlyLoan">
				{{ t('OFFER_STATUS.SECURED_OFFERED.qualificationStatement') }}
			</div>
		</mat-card-content>
	</mat-card>
</div>
