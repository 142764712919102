import { Pipe, PipeTransform } from '@angular/core';
import { flatten } from 'lodash';
import { IMetadata } from 'src/app/core/services/mobile-api';

/**
 * Filter metadata array based on key list.
 *
 * @export
 * @class MetadataFilterPipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'opMetadataFilter'
})
export class MetadataFilterPipe implements PipeTransform {
	transform(meta: IMetadata[], ...args: unknown[]): unknown {
		const fReq = flatten(args);
		return meta
			.filter((item) => fReq.some((freq) => freq === item.key))
			.sort((a, b) => (fReq.indexOf(a.key) > fReq.indexOf(b.key) ? 1 : -1));
	}
}
