import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-steps-headline',
	templateUrl: './steps-headline.component.html',
	styleUrls: ['./steps-headline.component.scss']
})
export class StepsHeadlineComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	stepIcon: string;

	@Input()
	headline: string;

	@Input()
	info: string;

	@Input()
	listOfIds: string;

	@Input()
	idTypes: Array<IMetadata>;

	@Input()
	showInfo: boolean;

	@Input()
	showMoreInfo: boolean;

	@Output()
	expanded = new EventEmitter<boolean>();

	@Output()
	expandedShowMore = new EventEmitter<boolean>();

	expandToggle(): void {
		this.showInfo = !this.showInfo;
		this.expanded.emit(this.showInfo);
	}

	showMoreInfoToggle(): void {
		this.showMoreInfo = !this.showMoreInfo;
		this.expandedShowMore.emit(this.showMoreInfo);
	}
}
