import { IApplicant, IError, IPhone } from '../loan-application/applicant/applicant.model';

export interface IZipCodeResult {
	serviceArea: boolean;
	finderNumberRequired: boolean;
	over50miles: boolean;
	storeless: boolean /* cspell: disable-line */;
	storeState: string;
	errorMessage: string;
	softPullEnabled: boolean;
	issuingOrganization: string;
}

export interface IOfferCodeResult {
	first_name: string;
	last_name: string;
	middle_name: string;
	maternal_name?: null;
	email_address: string;
	street_addresss1: string;
	street_addresss2: string;
	city: string;
	state: string;
	postal_code: string;
	country?: null;
	valid_promo_code_only?: null;
}

export interface IFindApplication {
	zipCode: string;
	lastName: string;
	dateOfBirth: string;
	phoneNumber: string;
}

export interface IFindApplicationResult {
	firstName: string;
	phoneType: string;
	inProgressApplication: boolean;
	returningCustomer: boolean;
	newApplicationStartDate: string;
	hasRecentNotApprovedApp: boolean;
	issuingOrganization: string;
	updatedAt: string;
	applicationStatus: string;
	hasActiveLoan: boolean;
	lastName: string;
	token: string;
	timeoutInMinutes: string;
	phoneNumber: string;
	applicationFlow?: ApplicationFlowEnum;
}

export interface IFindLetter {
	dateOfBirth: string;
	phoneNumber: string;
}

export interface IFindLetterResult {
	firstName: string;
	phoneType: string;
	inProgressApplication: boolean;
	returningCustomer: boolean;
	newApplicationStartDate: string;
	hasRecentNotApprovedApp: boolean;
	issuingOrganization: string;
	updatedAt: string;
	applicationStatus: string;
	hasActiveLoan: boolean;
	lastName: string;
	token: string;
	timeoutInMinutes: string;
	phoneNumber: string;
	applicationFlow?: ApplicationFlowEnum;
}

export interface INewApplication {
	firstName: string;
	middleName: string;
	lastName: string;
	maternalName: string;
	emailAddress: string;
	phoneNumber: string;
	phoneType: string;
	dateOfBirth: string;
	zipCode: string;
	preferredLanguage: string;
	tosAgree: boolean;
	marketingSource: string;
	partnerReferralPartnerSource: string;
	finderNumber: string;
	ownerDateRange: number;
	originationCode: string;
	smsAuthorization: boolean;
	priorDataAgree: boolean;
}

export interface INewApplicationResult {
	loanApplicationId: number;
	applicantId: number;
	firstName: string;
	phoneNumber: string;
	phoneType: string;
	preferredLanguage: string;
	hasRecentNotApprovedApp: boolean;
	newApplicationStartDate: string;
	updatedAt: string;
	hasActiveLoan: boolean;
	inProgressApplication: boolean;
	returningCustomer: boolean;
	token: string;
	timeoutInMinutes: string;
	applicationFlow?: ApplicationFlowEnum;
}

export enum ApplicationFlowEnum {
	standard = 'STANDARD',
	fastTrackReturning = 'FAST_TRACK_RETURNING',
	fastTrackPrequal = 'FAST_TRACK_PREQUAL',
	oportunPrequal = 'OPORTUN_PREQUAL'
}

export interface ILasso {
	blackBox: string;
	captureDate: string;
	applicantId: string;
	loanApplicationId: string;
	page: string;
}

export interface ILassoResult {
	id: string;
	successful: boolean;
}

export interface IMultiFactor {
	phoneNumber: string;
	verifyType: string;
	mfaVerification: boolean;
	action: string;
}

export interface IMultiFactorResult {
	success: boolean;
}

export interface IVerifyFactor {
	phoneNumber: string;
	verifyType: string;
	verificationCode: string;
	mfaVerification: boolean;
	rememberDevice: boolean;
	action: string;
}

export interface IVerifyFactorResult {
	success: boolean;
	applicationId: number;
	applicantId: number;
	applicantIndex: number;
}

export interface IFastTrackData {
	loanAppId: number;
	token?: string;
	phoneNumber: string;
	phoneType: string;
}

export enum FTPApplicationType {
	new = 'newApplication',
	inProgress = 'inProgressApplication'
}

export interface IFTPDecryptTokenResult {
	success: boolean;
	data: IFindPrequalData;
}
export interface IFindPrequalData extends IFTPInprogressData, IFTPNewAppData {}

export interface IFTPInprogressData {
	zipCode: string;
	lastName: string;
	dateOfBirth: string;
	phoneNumber: string;
	type: FTPApplicationType;
}

export interface IFTPNewAppData {
	loanApplicationId: number;
	token: string;
	deviceId: string;
	type: FTPApplicationType;
}

export interface IBankAccountDecryptTokenResult {
	success: boolean;
	data: IBankAccountData;
}

export interface IBankAccountData {
	loanApplicationId: number;
	applicantId: number;
	applicantIndex: number;
	phoneNumber: string;
	phoneType: string;
	preferredLanguage: string;
	token: string;
}

export interface IZipCodeLookUpResult {
	populateAddress: {
		city: Array<string>;
		state: Array<string>;
		country: string;
		latitude: Array<string>;
		longitude: Array<string>;
	};
}

export interface IClosesStoreResult {
	storeless: boolean;
	stores?: IStoreEntity[] | null;
}
export interface IStoreEntity {
	distance: string;
	city: string;
	latitude: string;
	disburse_loan: string;
	sunday_close: number;
	thursday_open: number;
	wednesday_close: number;
	saturday_close: number;
	wednesday_open: number;
	sunday_open: number;
	state: string;
	longitude: string;
	store_id: number;
	saturday_open: number;
	monday_open: number;
	address: string;
	ventiva_deposit: string;
	region_id: string;
	friday_open: number;
	tuesday_open: number;
	location_description: string;
	tuesday_close: number;
	thursday_close: number;
	monday_close: number;
	check_printing: string;
	name: string;
	friday_close: number;
	postal_code: string;
}

export enum AddressTypeEnum {
	home = 'H',
	mail = 'M'
}

export interface ICassAddress {
	city: string;
	country?: string;
	currentAddress: string | boolean;
	moveInDate?: string;
	postalCode: string;
	state: string;
	streetAddress1: string;
	streetAddress2?: string;
	type: string;
	originationCode?: string;
	county?: string;
}

export interface ICassAddressResult {
	differenceBetweenAddress: any[];
	originalAddress: {
		cassVerified: boolean;
		city: string;
		postalCode: string;
		state: string;
		streetAddress1: string;
		streetAddress2: string;
		county?: string;
	};
	returnCode: string;
	suggestedAddress: {
		cassDate: string;
		cassErrorCode: string;
		cassErrorDescription: string;
		cassGeoAddonEnd: string;
		cassGeoAddonStart: string;
		cassGeoBlock: string;
		cassGeoErrCodes: string;
		cassGeoMatchFlag: string;
		cassGeoRetCode: string;
		cassGeoTld: string;
		cassGeoToLatitude: string;
		cassGeoToLongitude: string;
		cassGeoTract: string;
		cassVerified: boolean;
		city: string;
		postalCode: string;
		state: string;
		streetAddress1: string;
		streetAddress2: string;
		zipPlus4: string;
	};
}

export interface IAddressResult {
	type: string;
	streetAddress1: string;
	streetAddress2?: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	county?: string;
	currentAddress: boolean;
	moveInDate: string;
	outsideRadius: boolean;
	cassVerified: boolean;
	cassGeoMatchFlag: string;
	cassGeoTract: string;
	cassGeoBlock: string;
	cassGeoToLatitude: string;
	cassGeoToLongitude: string;
	errors?: null[] | null;
	id: number;
}

export interface ISetAddress {
	type: string;
	streetAddress1: string;
	streetAddress2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	county?: string;
	currentAddress: boolean;
	moveInDate: string;
	cassDate: string;
	cassErrorCode: string;
	cassErrorDescription: string;
	cassGeoAddonEnd: string;
	cassGeoAddonStart: string;
	cassGeoBlock: string;
	cassGeoErrCodes: string;
	cassGeoMatchFlag: string;
	cassGeoRetCode: string;
	cassGeoTld: string;
	cassGeoToLatitude: string;
	cassGeoToLongitude: string;
	cassGeoTract: string;
	cassVerified: boolean;
	zipPlus4: string;
}

export interface ISetAddressResult {
	type: string;
	streetAddress1: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	county?: string;
	currentAddress: boolean;
	moveInDate: string;
	outsideRadius: string;
	cassVerified: boolean;
	cassGeoTld: string;
	cassGeoMatchFlag: string;
	cassGeoTract: string;
	cassGeoBlock: string;
	cassGeoToLatitude: string;
	cassGeoToLongitude: string;
	errors: Array<IError>;
	id: number;
	creatorId: number;
	dateCreated: string;
	createdAt: string;
	updaterId: number;
	dateUpdated: string;
	updatedAt: string;
	lastUpdated: string;
	userId: number;
	storeId: number;
	channelId: number;
}

export interface IPhoneResult {
	applicantId: number;
	phones: IPhone[];
	emailAddress: string;
	preferredLanguage: string;
	smsAuthorization: boolean;
	errors: Array<IError>;
}

export interface ISetPhone {
	phones: [
		{
			phoneNumber: string;
			phoneType: string;
			ownerDateRange: number;
		}
	];
	smsAuthorization: boolean;
}

export interface ISetPhoneResult {
	phones: [
		{
			id: number;
			applicantId: number;
			phoneNumber: string;
			phoneType: string;
			ownerDateRange: number;
			isActive: boolean;
			userId: number;
			updatedAt: string;
			lastSmsValidated: string;
			lastVoiceValidated: string;
			verificationSource: string;
			verificationResult: string;
			verificationIsVerified: string;
			verificationDateValidated: string;
		}
	];
	smsAuthorization: boolean;
	isAgentConfirmedSIPMatch: string;
	isAgentDeclinedSIPMatch: string;
}

export interface IReferralCodeResult {
	clientId: string;
	referralCode: string;
	referralLink: string;
	referralProgramType: string;
}
export interface IReferralCodeStatusResult {
	data: IReferralCodeStatusData;
	success: boolean;
}
export interface IReferralCodeStatusData {
	isValid: number;
}

export interface IAdditionalInfo {
	loanPurpose: number;
	referralCode: string;
	referralCodeConsent: boolean;
}

export type IApplicantResult = IApplicant;

export interface ICreditAuthorization {
	customerAgrees: boolean;
}

export interface ICreditAuthorizationResult {
	acceptedOn: string;
	customerAgrees: boolean;
	errors: Array<IError>;
	id: number;
	creatorId: number;
	dateCreated: string;
	createdAt: string;
	updaterId: number;
	dateUpdated: string;
	updatedAt: string;
	lastUpdated: string;
	userId: number;
	storeId: number;
	channelId: number;
}

export interface IFinances {
	monthlyPayment: string;
	numberOfDependents: number;
	hasBankAccount: boolean;
	checkCashingFee: number;
	usedPaydayService: boolean;
}

export interface IFinancesResult extends IFinances {
	id: number;
	housingType?: string;
	paycheckAction?: string;
	haveOtherDebt?: boolean;
	bankAccountInvoluntarilyClosed?: string;
}

export type IFinancesUpdate = IFinancesResult;

export interface IDebt {
	type: number;
	originalBalance?: string;
	id?: number;
	monthlyPayment?: string;
}

export interface IDebtResult extends IDebt {
	addedOn?: string;
	errors?: Array<IError>;
	createdAt?: string;
	dateUpdated?: string;
	updatedAt?: string;
	lastUpdated?: string;
	lastUpdatedChannelId?: number;
}

export interface IIncome {
	incomeSourceType: number;
	incomeDescription?: null;
	company: string;
	industryType: number;
	paymentFrequency: number;
	amount: number;
	startDate: string;
	hasIncomeDocuments: boolean;
}

export interface IUpdateIncome extends IIncome {
	id: number;
}

export interface IGetIncomeResult extends IUpdateIncome {
	errors?: Array<IError>;
}

export interface IIncomeResult extends IIncome {
	errors?: Array<IError>;
	id: number;
	creatorId: number;
	dateCreated: string;
	createdAt: string;
	updaterId: number;
	dateUpdated: string;
	updatedAt: string;
	lastUpdated: string;
	userId: number;
	storeId: number;
	channelId: number;
}

export enum RelationshipTypes {}

export enum Suffixes {
	II = 'II',
	III = 'III',
	IV = 'IV',
	V = 'V',
	VI = 'VI',
	VII = 'VII',
	VIII = 'VIII',
	JR = 'JR',
	SR = 'SR'
}

export enum RefreshStatus {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export interface IReference {
	id?: number;
	firstName: string;
	middleName?: string;
	lastName: string;
	maternalName?: string;
	position: number;
	suffix?: Suffixes;
	phoneNumber: string;
	preferredLanguage: 'EN' | 'ES';
	relationship: RelationshipTypes | any;
}

export interface IReferenceResult extends IReference {
	applicationIncomeId?: number;
	companyName?: string;
	monthsSinceRelationship?: number;
	phoneType?: string;
	relationship: number;
	status: string;
	subStatus?: string;
}

export interface IDebitCard {
	cardLast4: string;
	cardValid: boolean;
}

export interface IDebitCardDetails {
	cardType: string;
	institutionName: string;
	last4: string;
	applicationId: number;
	applicantId: number;
	fundsAvailability: string;
}

export interface IResult {
	success: boolean;
}

export interface IStringResult {
	result: string;
}

export type IDisbursementAchResult = IResult;

export type IDisbursementResult = IResult;

export type IIncomeSelectionResult = IResult;

export enum FileTypeEnum {
	bank = 'BANK_ACCOUNT',
	income = 'INCOME',
	addresses = 'RESIDENCE',
	identification = 'IDENTIFICATION'
}

export enum FileStatusEnum {
	pending = 'PENDING',
	review = 'REVIEW',
	complete = 'COMPLETE'
}

export enum DocumentSideEnum {
	front = 'FRONT',
	back = 'BACK',
	none = 'NONE'
}

export interface IUploadDocumentQueryParams {
	opRefUuid: string;
	opUuid: string;
}

export enum DocumentStatusCriteriaEnum {
	zeroToThirtyDays = '0_TO_30_DAYS',
	thirtyOneToSixtyFiveDays = '31_TO_65_DAYS'
}

export interface IDocumentStatus {
	country?: string;
	createdDate?: number;
	updatedDate?: number;
	criteriaMet?: DocumentStatusCriteriaEnum[];
	documentClassification?: string;
	documentId?: string;
	documentSide?: DocumentSideEnum;
	documentType?: FileTypeEnum;
	fileName?: string;
	messages?: string[];
	status: FileStatusEnum;
	pair?: boolean;
}

export interface IUploadDocument {
	documentType: FileTypeEnum;
	documentClassification: string;
	idCountry?: string;
	fileFront: string;
	fileBack?: string;
}

export interface IUploadedFiles extends IResult {
	createdDate?: number;
	criteriaMet?: DocumentStatusCriteriaEnum[];
	documentSide: DocumentSideEnum;
	documentStatus: FileStatusEnum;
	documentUploadId: string;
	fileName?: string;
	messages?: string[];
}

export interface IUploadDocumentV2Result {
	uploadedFiles: IUploadedFiles[];
}

export enum FeatureNameEnum {
	newUploadExperience = 'NEW_UPLOAD_EXPERIENCE',
	plaidCraFeature = 'PLAID_CRA_FEATURE',
	instantDisbursementRachEnroll = 'INSTANT_DISBURSEMENT_RACH_ENROLL_FEATURE'
}

export enum ProductCategorySelectionEnum {
	unsecuredPersonalLoan = 'UNSECURED_PERSONAL_LOAN',
	securedPersonalLoan = 'SECURED_PERSONAL_LOAN'
}

export enum EligibilityExperienceEnum {
	v1 = 'V1',
	v2 = 'V2',
	v3 = 'V3'
}

export interface IEligibilityResult extends IResult {
	featureName: FeatureNameEnum;
	eligible: boolean;
	experience: EligibilityExperienceEnum;
}

export type IUploadDocumentResult = IResult;

export interface ISubmitResult extends IResult {
	applicationStatus: string;
}

export interface IValidateRoutingNumberResult {
	bankName: string;
}

export interface ISetAchBankAccountResult {
	result: string;
	verified: boolean;
	id: number;
}

export interface ISetAchBankAccountDetail extends ISetAchBankAccountResult {
	bankName?: string;
	accountType?: string;
	customerConfirms?: boolean;
	accountNumber?: string;
}

export interface ISetBank {
	accountType: string;
	routingNumber: string;
	routingNumberConfirmed: string;
	accountNumber: string;
	accountNumberConfirmed: string;
	customerConfirms: string;
}

export enum AssetRefreshEnum {
	plaidAssetRefresh = 'PLAID_ASSET_REFRESH',
	plaidAssetRefreshConsentFeature = 'PLAID_ASSET_REFRESH_CONSENT_FEATURE',
	plaidAssetRefreshConsent = 'PLAID_ASSET_REFRESH_CONSENT'
}

export interface IAchBankAccountResult {
	bankName: string;
	routingNumber: string;
	accountNumber: string;
	status: string;
	accountType: string;
	id: number;
	accountNumberLastFour: string;
	applicantName: string;
	verificationStatus: string;
	vendorRequestType: AssetRefreshEnum;
}

export interface IRachBankAccount extends IAchBankAccountResult {
	firstName?: string;
	lastName?: string;
}

export interface IRchAutopayActiveStatus {
	result: string;
}

export interface IUpdateBank {
	bankAccountId: number;
	customerConfirms: boolean;
}

export interface IApplicantConsentResult {
	id: number;
	applicantId: number;
	consentTransactionId: string;
	consentCollectedDate: string;
	loanAppAuthorization: boolean;
	applicantWirelessConsent: boolean;
	consentDescription: string;
	createdTransactionId: string;
	createdDate: string;
	updatedDate: string;
	createdUserId: number;
	createdChannelId: number;
	updatedUserId: number;
	updatedChannelId: number;
	updatedTransactionId: string;
}

export interface IPlaidLinkTokenResult {
	cached: boolean;
	mongoId: number;
	linkToken: string;
	expiration: string;
	successful: boolean;
}

export interface IPlaidConnectionComplete {
	publicToken: string;
	institutionId: string;
	institutionName: string;
}

export interface IPlaidConnectionCompleteResult {
	result: string;
	responseStatus: string;
	foundVerifiedIncome: boolean;
	applicantId: number;
}

export interface IPaymentOptions {
	expectedDisbursementDate: string;
	loanAmount: string;
	paymentDay?: string;
	paymentDate1?: string;
	paymentDate2?: string;
	paymentFrequency: string;
	productCategory?: string;
	firstPaymentDate?: string;
}

export interface IFirstPaymentDateOptions {
	monthlyPaymentDate?: string;
	firstPaymentDateString?: string;
	firstPaymentDate?: string;
}

export interface ISaveLoanTerms {
	paymentAmount: number;
	paymentTerm: number;
	loanAmount: number;
	paymentFrequency: string;
	paymentDay?: string;
	paymentDate1?: number;
	paymentDate2?: number;
	productCategory: string;
	firstPaymentDate?: string;
}

export interface IAdditionalContact {
	firstName: string;
	lastName: string;
	maternalName: string;
	phoneNumber: string;
	preferredLanguage: string;
	relationship: number;
}

export interface IAdditionalContactResult {
	id: number;
	applicantId: number;
	type: string;
	firstName: string;
	lastName: string;
	maternalName?: null;
	phoneNumber: string;
	phoneType?: null;
	preferredLanguage: string;
	relationship: number;
	streetAddress1?: null;
	streetAddress2?: null;
	postalCode?: null;
	city?: null;
	state?: null;
	country?: null;
	creatorId: number;
	updaterId?: null;
	lastUpdated: string;
	dateCreated: string;
}

export interface INotificationCompleteResult {
	id: number;
	productType: string;
	productCategory: string;
	applicationType: string;
	applicationTypeAcknowledged: boolean;
	createdAt: string;
	updatedAt: string;
	originationSource: string;
	active?: null;
	leadOfferId?: null;
	applicationStatus: string;
	partnerId?: null;
	finderNumber?: null;
	finderNumberZipCode?: null;
	loanPurpose: number;
	hearAboutUs?: null;
	hearAboutUsOther?: null;
	applicants?: null[] | null;
	isCanceledByClient: boolean;
	cancelReason?: null;
	sipPfLoan?: null;
	documents?: null[] | null;
	preApprovalProcessComplete: boolean;
	verificationProcessComplete: boolean;
	referenceCheckProcessComplete: boolean;
	notificationProcessComplete: boolean;
	disbursementProcessComplete: boolean;
	bounceReasons?: null[] | null;
	preApprovedTerms?: null[] | null;
	marketingSource: string;
	seedNumber: string;
	productOfferDetails?: null[] | null;
	issuingOrganization: string;
}
export interface ISecuredOfferAction {
	securedAppOfferAction: string;
}

export interface ILeadsByNameAddress {
	firstName: string;
	lastName: string;
	streetAddress1: string;
	streetAddress2?: string;
	city?: string;
	state?: string;
	zipCode: string;
}

export interface ILeadsByNameAddressResult {
	status: boolean;
	finderNumber?: string;
	leadId?: string;
	amount?: string;
	issuingOrganization?: string;
}

export interface IUpdateEsignSignerStatus {
	applicantId: string; // number?
	clientId: string;
	envelopeId: string;
	esignPreferredLanguage: string;
	loanApplicationId: string; // number?
	token: string;
}

export interface IUpdateEsignSignerStatusResult {
	signingUrl: string;
	envelopeId: string;
	savedEnvelopeId: string;
}

export interface IDisbursementOptions {
	disbursementChannel: string;
	disbursementType: string;
}

export interface IProductStatusAction {
	productCategory: string;
	productAction: string;
}

export interface IProductAction {
	productAction: string;
}

export enum PaymentRemindersEnum {
	rachRemindersSms = 'RACH_REMINDERS_SMS',
	rachRemindersPhoneCall = 'RACH_REMINDERS_PHONE_CALL',
	rachRemindersNone = 'RACH_REMINDERS_NONE'
}

export enum ReceiptsEnum {
	rachRReceiptsSms = 'RACH_RECEIPTS_SMS',
	rachRReceiptsMail = 'RACH_RECEIPTS_MAIL',
	rachRReceiptsNone = 'RACH_RECEIPTS_NONE'
}

export interface IPaymentRemindersOptionsResult {
	homePhoneNumber?: string;
	mobilePhoneNumber?: string;
	paymentReminders?: PaymentRemindersEnum[] | null;
	receipts?: ReceiptsEnum[] | null;
}

export interface IPaymentReminders {
	mobilePhoneNumber: string;
	paymentReminders: PaymentRemindersEnum;
	receipts: ReceiptsEnum;
}

export interface IPaymentRemindersResult extends IPaymentReminders {
	rachEnabled?: boolean;
}

export interface IProductAction {
	productAction: string;
}

export interface IActiveProduct {
	productType: string;
	productCategory: string;
	pfLoanId: number;
	applicationId: number;
	issuingOrganization: string;
	owningOrganization: string;
}
export interface IEligibleProducts {
	additionalData: IAdditionalData;
	productType: string;
	productCategory: string;
	eligibilityEndDate: string;
	multiProductSegmentNode: string;
	multiProductPricingCode: string;
	redirectUrl: string;
}

export interface IAdditionalData {
	annualFee: string;
	apr: string;
	creditLine: string;
}

export interface IMultiProductResult {
	clientId: number;
	success: string;
	multiProductEligible: boolean;
	redirectCode: string;
	activeProducts: IActiveProduct[];
	eligibleProducts: IEligibleProducts[];
}

export interface IAccountsMeResult {
	userId: string;
	username: string;
	token: string;
	firstName: string;
	lastName: string;
	middleName: string;
	maternalName: string;
	email: string;
	mobilePhone: string;
	homePhone: string;
	workPhone: string;
	preferredLanguage: string;
	phoneVerificationNeeded: string;
	status: string;
	timeoutInMinutes: number;
	timeoutInSeconds: number;
	additionalInfo: IAdditionalInfo;
}

export interface IAdditionalInfo {}

export interface IHomeSummaryResult {
	clientInfo: IClientInfo;
	eligibilityInfo?: IEligibilityInfoEntity[] | null;
	currentLoans?: ICurrentLoansEntity[] | null;
	multiProductEligibilityInfo: IMultiProductEligibilityInfo;
}
export interface IClaimOfferResult {
	firstName: string;
	middleName: string;
	maternalName: string;
	lastName: string;
	emailAddress: string;
	preferredLanguage: string;
	ableToReapply: boolean;
	applicationId: string;
	oktaUserId: string;
}
export interface IClientInfo {
	firstName: string;
	lastName: string;
	fullName: string;
	middleName: string;
}
export interface IEligibilityInfoEntity {
	productType: string;
	ableToReapply: boolean;
	nextApplicationType: string;
	nextApplicationStartDate: string;
	openApplication: IOpenApplication;
	waitingPeriod: number;
}
export interface IOpenApplication {
	applicationId: string;
	applicationStatus: string;
}
export interface ICurrentLoansEntity {
	loanNumber: string;
	productType: string;
	currentBalance: string;
	nextPaymentAmount: string;
	nextPaymentDueDate: string;
	daysDelinquent: number;
	status: string;
	nextPaymentDueDisplayText: string;
	productCategory: string;
	paymentAmount: string;
	recommendedPaymentAmount: string;
	amountToBringLoanToCurrent: string;
}
export interface IMultiProductEligibilityInfo {
	multiProductEligible: boolean;
	activeProducts?: IActiveProductsEntity[] | null;
}
export interface IActiveProductsEntity {
	productType: string;
	productCategory: string;
	pfLoanId: number;
	applicationId: number;
	issuingOrganization: string;
	owningOrganization: string;
}

export interface ILoanTermsResult {
	loanApplicationId: number;
	purchaseAmount?: null;
	loanAmount: number;
	downPayment?: null;
	totalTax: number;
	paymentFrequency: string;
	paymentAmount: number;
	paymentTerm: number;
	paymentDay?: null;
	paymentDate1: number;
	paymentDate2: number;
	printedAt?: null;
	esignedAt?: null;
	disbursementDate: string;
	expectedDisbursementDate: string;
	firstPaymentDate?: null;
	apr: number;
	realApr: number;
	createdAt: string;
	updatedAt: string;
	termsExpired: boolean;
	numberOfPayments: number;
	feeType: string;
	originationFee: number;
	feeDescription1?: null;
	feeAmount1?: null;
	previousPfLoan?: null;
	payoffAmount?: null;
	disbursementAmount: number;
	product: string;
	productCategory: string;
}

export interface IPaymentOptionsResult {
	id: string;
	index: number;
	amount: number;
	additionalTax: number;
	actualMonths: number;
	realApr: number;
	annualPercentageRate: number;
	apr: number;
	interestRate: number;
	apr360: number;
	interestRate360: number;
	disbursementDate: string;
	paymentFrequency: string;
	numberOfPayments: number;
	firstPaymentDate: string;
	paymentAmount: number;
	loanRules: string;
	mlaCovered: boolean;
	product: string;
	productCategory: string;
	transactionId: string;
	paymentTerm: number;
	fee?: FeeEntity[] | null;
}
export interface FeeEntity {
	amount: number;
	type: string;
}

export interface ICheckAddressChange {
	value: string;
	result: string;
}

export enum PartnerLeadConsentEnum {
	partnerFinderAcknowledgement = 'PARTNER_FINDER_ACKNOWLEDGEMENT'
}

export interface IPartnerLead {
	validLead: boolean;
	leadId: number;
	phoneType: string;
	phoneNumber: string;
	loanApplicationExists: boolean;
	token: string;
	tokenExpiryInMinutes: number;
	postalCode?: string;
	firstName: string;
	lastName: string;
	middleName: string;
	maternalName: string;
	suffix: string;
	email: string;
	preferredLanguage: string;
	dateOfBirth: string;
	partnerShortName: string;
	partnerAgentId: number;
	partnerChannelId: number;

	address1: string;
	address2: string;
	city: string;
	state: string;
	addressType: AddressTypeEnum;
	finderPartner: boolean;
	consentRequired: boolean;
}

export interface IPartnerLeadConsent {
	consentAccepted: boolean;
	consentType: PartnerLeadConsentEnum;
	consentCollectedDate: number;
	leadId: number;
	id: number;
	partnerShortName?: string;
}

export interface IFindELetters {
	token: string;
	phoneNumber: string;
	dateOfBirth: string;
}

export interface IFindELettersResult {
	success: boolean;
	data: IFindELettersDataResult;
}

export interface IFindELettersDataResult {
	applicantId: number;
	applicantIndex: number;
	letterMetadataId: string;
	letterType: string;
	loanApplicationId: number;
	completePhoneNumber: string;
	phoneNumber: string;
	phoneType: string;
	token: string;
}

export interface IEsignDisclosuresDecryptTokenResult {
	success: boolean;
	data: IEsignDisclosuresDataResult;
	consent: IEsignDisclosuresConsentResult;
}

export interface IEsignDisclosuresDataResult {
	applicantId: string;
	applicantIndex: number;
	clientName: string;
	loanApplicationId: string;
	token: string;
}

export interface IEsignDisclosuresConsentResult {
	applicantId: number;
	applicantIndex: number;
	clientName: string;
	loanApplicationId: number;
	token: string;
}

export interface IFeatureEligibilityResult {
	eligible: boolean;
	featureEligibility: boolean;
	featureName: string;
	success: boolean;
}

export interface IPlaidConnectedInstitutions {
	institutions: IInstitution[];
	plaidConsent: string;
	readOnly: boolean;
	success?: boolean;
}

export interface IInstitution {
	institutionId: string;
	refreshStatus: RefreshStatus;
	name: string;
	select: boolean;
	accounts: IAccounts[];
}

export interface IAccounts {
	accountType: string;
	accountNumberLastFour: string;
}

export interface IConsentRequest {
	consentDescription: string;
	consentStatus: string;
	organization: string;
	institutions: Partial<IInstitution>[];
}

export interface IPlaidRefreshRequest {
	institutions: string[];
}

export interface IPlaidCreateUserResult {
	successful: boolean;
}

export interface IPlaidCraDetailsResult {
	vendorTokenCreated: boolean;
}

export interface IAgentStoreChannel {
	agentId: number;
	storeId: number;
}

export interface IAgentChannel {
	id: number;
	name: string;
}

export interface IAgentData {
	firstName: string;
	agentId: number;
	channels: IAgentChannel[];
}

export interface IAgentResult {
	success: boolean;
	data: IAgentData;
}
