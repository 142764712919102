import { Component } from '@angular/core';

import { ExpandSectionComponent } from '../expand-section/expand-section.component';

@Component({
	selector: 'op-section-address',
	templateUrl: './section-address.component.html',
	styleUrls: ['./section-address.component.scss']
})
export class SectionAddressComponent extends ExpandSectionComponent {
	constructor() {
		super();
	}
}
